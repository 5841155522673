import { useEffect, useState } from "react";
import { customerEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useParams } from "react-router-dom";
import { isValidJson } from "../../../utilities/jsonvalidation";
import QRCode from "react-qr-code";

export default function ViewCustomer() {

    const { id } = useParams();

    const [currentCustomer, setCurrentCustomer] = useState({
        is_married: false,
        upi_id: null
    });

    useEffect(() => {
        getCustomerDetails()
    }, [])

    const getCustomerDetails = async () => {
        var response = await getAxios({
            url: customerEndPoint + id + "/"
        })

        if (response !== null) {
            var tempDetails = {
                customer_name: response.data?.customer_name,
                email: response.data?.email,
                phone: response.data?.phone,
                customer_group: response.data?.customer_group_name,
                dob: response.data?.dob,
                door_no: response.data?.door_no,
                street_name: response.data?.street_name,
                area: response.data?.area,
                taluk: response.data?.taluk,
                postal: response.data?.postal,
                district: response.data?.district,
                state: response.data?.state,
                country: response.data?.country,
                pincode: response.data?.pincode,
                aadhar_card: response.data?.aadhar_card,
                pan_card: response.data?.pan_card,
                gst_no: response.data?.gst_no,
                is_married: response.data?.is_married,
                marriage_date: response.data.marriage_date,
                branch: response.data.branch,
                upi_id: response.data.upi_id
            }

            setCurrentCustomer(tempDetails)
        }
    }

    return (
        <div className="mb-[50px]">
            <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Customer Details</p>
                <div className="lg:flex gap-3 w-full justify-between">
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5 w-full">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Customer Name</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.customer_name}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[16px]">Customer Group</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.customer_group}</p>
                        </div>
                        <div className="my-[5px]">

                            <p className="mb-[5px] text-[16px]">Email</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.email}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Phone no</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.phone}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Branch</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.branch}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">DOB</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.dob}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Married</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.is_married ? 'Yes' : 'No'}</p>
                        </div>
                        {currentCustomer.is_married && <div className="my-[5px]">

                            <p className="mb-[5px] text-[16px]">Marriage Date</p>
                            <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.marriage_date}</p>
                        </div>}

                    </div>

                    {currentCustomer.upi_id && <div className="my-[5px]">

                        <p className="mb-[5px] text-[16px]">UPI</p>
                        <QRCode value={'upi://pay?pa='+currentCustomer.upi_id} />
                    </div>}
                </div>


            </div>
            <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Door No</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.door_no}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Street Name</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.street_name}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Area</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.area}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Taluk</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.taluk}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Postal</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.postal}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">District</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.district}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Country </p>
                        <p className="text-primary-btn font-[400] text-[16px]">{isValidJson(currentCustomer.country)?.name}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">State</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{isValidJson(currentCustomer.state)?.name}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Pincode</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.pincode}</p>
                    </div>
                </div>

            </div>
            <div className="border p-[15px] rounded-xl shadow-lg my-[25px]  bg-menu-head-bg">
                <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Other Details</p>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">GSTIN</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.gst_no}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Pan no</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.pan_card}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Aadhar no</p>
                        <p className="text-primary-btn font-[400] text-[16px]">{currentCustomer.aadhar_card}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}