import { useEffect, useState } from "react";
import { goldTagEndPoint, silverTagEndPoint, tagdetailsEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import Popup from "../../../components/Popup";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { cararWeightType, caratRateType, fixedCalcId, grossWeightType, nonTagStockType, packetStockType, pergramCalId, tagStockType, weightCalId } from "../../../constant";
import toast from "react-hot-toast";
import StoneForm from "./stoneform";
import DiamondForm from "./diamondform";

export default function ParticularsForm({ gst, setGst, formType, setDataList, dataList,metaltype }) {

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const [stonePopupOpen, setStonePopupOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [diamondPopupOpen, setDiamondPopupOpen] = useState(false);

    const [stoneList, setStoneList] = useState([]);
    const [diamondList, setDiamondList] = useState([]);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
        setIsLoading(null)
    }

    function closeStoneModal() {
        setStonePopupOpen(false);
    }

    function closeDiamondModal() {
        setDiamondPopupOpen(false)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('additem').click()
        } else if (event.key === 'G' && event.ctrlKey) {
            event.preventDefault();
            setGst(!gst)
        }
    };

    useKeyPress(['Enter', 'G'], onKeyPress);

    useEffect(() => {
        if (formType === 'create') {
            addValue()
        }
    }, [])

    useEffect(() => {
        if (dataList.length > 0) {
            for (var i in dataList) {
                var stoneWeight = 0.0
                var diamondWeight = 0.0

                var totalStoneRate = 0.0
                var totalDiamondRate = 0.0

                var tagWgt = dataList[i].tag_weight
                var coverWt = dataList[i].cover_weight
                var loopWgt = dataList[i].loop_weight
                var otherWgt = dataList[i].other_weight
                var grossWgt = dataList[i].gross_weight

                if (dataList[i].stone_details) {
                    if (dataList[i].stone_details.length !== 0) {
                        for (var j of dataList[i].stone_details) {
                            if (j.include_stone_weight === true) {
                                if (String(j.stone_weight_type) === String(cararWeightType)) {
                                    stoneWeight = stoneWeight + (parseFloat(j.stone_weight) / 5)
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                                    }
                                } else {
                                    stoneWeight = stoneWeight + parseFloat(j.stone_weight)
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                                    }
                                }
                            } else {
                                if (String(j.stone_weight_type) === String(cararWeightType)) {
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                                    }
                                } else {
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                                    }
                                }
                            }
                        }
                    }
                }

                if (dataList[i].diamond_details) {
                    if (dataList[i].diamond_details.length !== 0) {
                        for (var j of dataList[i].diamond_details) {
                            if (j.include_diamond_weight === true) {
                                if (String(j.diamond_weight_type) === String(cararWeightType)) {
                                    diamondWeight = diamondWeight + (parseFloat(j.diamond_weight) / 5)
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                                    }
                                } else {
                                    diamondWeight = diamondWeight + parseFloat(j.diamond_weight)
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                                    }
                                }
                            } else {
                                if (String(j.diamond_weight_type) === String(cararWeightType)) {
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                                    }
                                } else {
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                                    }
                                }
                            }
                        }
                    }
                }

                var netWgt = parseFloat(grossWgt) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)

                let totalPrice = 0.0
                if (dataList[i].calculation_type == weightCalId) {
                    let wastageValue = dataList[i].wastage_calculation_type?.toString() === grossWeightType.toString() ? grossWgt * (dataList[i].wastage_percent / 100) * dataList[i].metal_rate : netWgt * (dataList[i].wastage_percent / 100) * dataList[i].metal_rate
                    let makingChargeValue = dataList[i].making_charge_calculation_type?.toString() === grossWeightType.toString() ? grossWgt * dataList[i].making_charge : netWgt * dataList[i].making_charge
                    let metalValue = netWgt * dataList[i].metal_rate
                    totalPrice = parseFloat(wastageValue) + parseFloat(dataList[i].flat_wastage) + parseFloat(makingChargeValue) + parseFloat(dataList[i].flat_making_charge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue)
                } else if (dataList[i].calculation_type == fixedCalcId) {
                    totalPrice = parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(dataList[i].metal_rate)
                } else if (dataList[i].calculation_type == pergramCalId) {
                    let weightType = dataList[i].per_gram_weight_type == grossWeightType ? dataList[i].gross_weight : dataList[i].net_weight
                    let metalRateValue = weightType * parseFloat(dataList[i].metal_rate)
                    totalPrice = parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalRateValue)
                }

                var rate = totalPrice + parseFloat(dataList[i].item_huid_rate)

                let data = [...dataList];

                if (gst) {
                    var gstValue = (((parseFloat(dataList[i].sales_tax_igst) + parseFloat(dataList[i].sales_tax_cgst) + parseFloat(dataList[i].sales_tax_sgst) + parseFloat(dataList[i].sales_surcharge_percent)) / 100) * rate) + parseFloat(dataList[i].sales_additional_charges)
                    data[i]['gst'] = gstValue
                    data[i]['rate'] = rate
                    data[i]['with_gst_rate'] = rate + gstValue
                } else {
                    data[i]['gst'] = 0
                    data[i]['rate'] = rate
                    data[i]['with_gst_rate'] = rate
                }

                setDataList(data)
            }
        }
    }, [gst])

    useEffect(() => {
        if (!stonePopupOpen) {
            for (var i in dataList) {
                if (currentIndex == i) {
                    let stoneWeight = 0.0
                    let diamondWeight = 0.0

                    var totalStoneRate = 0.0
                    var totalDiamondRate = 0.0

                    var tagWgt = dataList[i].tag_weight
                    var coverWt = dataList[i].cover_weight
                    var loopWgt = dataList[i].loop_weight
                    var otherWgt = dataList[i].other_weight
                    var grossWgt = dataList[i].gross_weight


                    if (stoneList.length !== 0) {
                        for (var j of stoneList) {
                            if (j.include_stone_weight === true) {
                                if (String(j.stone_weight_type) === String(cararWeightType)) {
                                    stoneWeight = stoneWeight + (parseFloat(j.stone_weight) / 5)
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                                    }
                                } else {
                                    stoneWeight = stoneWeight + parseFloat(j.stone_weight)
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                                    }
                                }
                            } else {
                                if (String(j.stone_weight_type) === String(cararWeightType)) {
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                                    }
                                } else {
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                                    }
                                }
                            }
                        }
                    }

                    if (dataList[i].diamond_details.length !== 0) {
                        for (var j of dataList[i].diamond_details) {
                            if (j.include_diamond_weight === true) {
                                if (String(j.diamond_weight_type) === String(cararWeightType)) {
                                    diamondWeight = diamondWeight + (parseFloat(j.diamond_weight) / 5)
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                                    }
                                } else {
                                    diamondWeight = diamondWeight + parseFloat(j.diamond_weight)
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                                    }
                                }
                            } else {
                                if (String(j.diamond_weight_type) === String(cararWeightType)) {
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                                    }
                                } else {
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                                    }
                                }
                            }
                        }
                    }

                    var netWgt = parseFloat(grossWgt) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)

                    let totalPrice = 0.0
                    if (dataList[i].calculation_type == weightCalId) {

                        let wastageValue = dataList[i].wastage_calculation_type?.toString() === grossWeightType.toString() ? grossWgt * (dataList[i].wastage_percent / 100) * dataList[i].metal_rate : netWgt * (dataList[i].wastage_percent / 100) * dataList[i].metal_rate
                        let makingChargeValue = dataList[i].making_charge_calculation_type?.toString() === grossWeightType.toString() ? grossWgt * dataList[i].making_charge : netWgt * dataList[i].making_charge
                        let metalValue = netWgt * dataList[i].metal_rate
                        totalPrice = parseFloat(wastageValue) + parseFloat(dataList[i].flat_wastage) + parseFloat(makingChargeValue) + parseFloat(dataList[i].flat_making_charge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue)
                    } else if (dataList[i].calculation_type == fixedCalcId) {
                        totalPrice = parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(dataList[i].metal_rate)
                    } else if (dataList[i].calculation_type == pergramCalId) {
                        let weightType = dataList[i].per_gram_weight_type == grossWeightType ? dataList[i].gross_weight : dataList[i].net_weight
                        let metalRateValue = weightType * parseFloat(dataList[i].metal_rate)
                        totalPrice = parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalRateValue)
                    }


                    let data = [...dataList];

                    var rate = totalPrice + parseFloat(data[i].item_huid_rate)
                    var gstValue = (((parseFloat(data[i].sales_tax_igst) + parseFloat(data[i].sales_tax_cgst) + parseFloat(data[i].sales_tax_sgst) + parseFloat(data[i].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[i].sales_additional_charges)

                    data[currentIndex]['net_weight'] = netWgt
                    data[currentIndex]['stone_details'] = stoneList
                    data[currentIndex]['total_stone_weight'] = stoneWeight
                    data[currentIndex]['stone_rate'] = totalStoneRate
                    data[currentIndex]['gst'] = gst ? gstValue : 0
                    data[currentIndex]['rate'] = rate
                    data[currentIndex]['with_gst_rate'] = gst ? rate + gstValue : rate
                    setDataList(data)
                }
            }

        }
    }, [stonePopupOpen])

    useEffect(() => {
        if (!diamondPopupOpen) {
            for (var i in dataList) {
                if (currentIndex == i) {
                    var stoneWeight = 0.0
                    var diamondWeight = 0.0

                    var totalStoneRate = 0.0
                    var totalDiamondRate = 0.0

                    var tagWgt = dataList[i].tag_weight
                    var coverWt = dataList[i].cover_weight
                    var loopWgt = dataList[i].loop_weight
                    var otherWgt = dataList[i].other_weight
                    var grossWgt = dataList[i].gross_weight


                    if (dataList[i].stone_details.length !== 0) {
                        for (var j of dataList[i].stone_details) {
                            if (j.include_stone_weight === true) {
                                if (String(j.stone_weight_type) === String(cararWeightType)) {
                                    stoneWeight = stoneWeight + (parseFloat(j.stone_weight) / 5)
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                                    }
                                } else {
                                    stoneWeight = stoneWeight + parseFloat(j.stone_weight)
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                                    }
                                }
                            } else {
                                if (String(j.stone_weight_type) === String(cararWeightType)) {
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                                    }
                                } else {
                                    if (String(j.stone_rate_type) === String(caratRateType)) {
                                        totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                                    } else {
                                        totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                                    }
                                }
                            }
                        }
                    }

                    if (diamondList.length !== 0) {
                        for (var j of diamondList) {
                            if (j.include_diamond_weight === true) {
                                if (String(j.diamond_weight_type) === String(cararWeightType)) {
                                    diamondWeight = diamondWeight + (parseFloat(j.diamond_weight) / 5)
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                                    }
                                } else {
                                    diamondWeight = diamondWeight + parseFloat(j.diamond_weight)
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                                    }
                                }
                            } else {
                                if (String(j.diamond_weight_type) === String(cararWeightType)) {
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                                    }
                                } else {
                                    if (String(j.diamond_rate_type) === String(caratRateType)) {
                                        totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                                    } else {
                                        totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                                    }
                                }
                            }
                        }
                    }

                    var netWgt = parseFloat(grossWgt) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)

                    let totalPrice = 0.0
                    if (dataList[i].calculation_type === weightCalId) {

                        let wastageValue = dataList[i].wastage_calculation_type?.toString() === grossWeightType.toString() ? grossWgt * (dataList[i].wastage_percent / 100) * dataList[i].metal_rate : netWgt * (dataList[i].wastage_percent / 100) * dataList[i].metal_rate
                        let makingChargeValue = dataList[i].making_charge_calculation_type?.toString() === grossWeightType.toString() ? grossWgt * dataList[i].making_charge : netWgt * dataList[i].making_charge
                        let metalValue = netWgt * dataList[i].metal_rate
                        totalPrice = parseFloat(wastageValue) + parseFloat(dataList[i].flat_wastage) + parseFloat(makingChargeValue) + parseFloat(dataList[i].flat_making_charge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue)
                    } else if (dataList[i].calculation_type == fixedCalcId) {
                        totalPrice = parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(dataList[i].metal_rate)
                    } else if (dataList[i].calculation_type == pergramCalId) {
                        let weightType = dataList[i].per_gram_weight_type == grossWeightType ? dataList[i].gross_weight : dataList[i].net_weight
                        let metalRateValue = weightType * parseFloat(dataList[i].metal_rate)
                        totalPrice = parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalRateValue)
                    }

                    let data = [...dataList];

                    var rate = totalPrice + parseFloat(data[i].item_huid_rate)
                    var gstValue = (((parseFloat(data[i].sales_tax_igst) + parseFloat(data[i].sales_tax_cgst) + parseFloat(data[i].sales_tax_sgst) + parseFloat(data[i].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[i].sales_additional_charges)

                    data[currentIndex]['net_weight'] = netWgt
                    data[currentIndex]['diamond_details'] = diamondList
                    data[currentIndex]['total_diamond_weight'] = diamondWeight
                    data[currentIndex]['diamond_rate'] = totalDiamondRate
                    data[currentIndex]['gst'] = gst ? gstValue : 0
                    data[currentIndex]['rate'] = rate
                    data[currentIndex]['with_gst_rate'] = gst ? rate + gstValue : rate
                    setDataList(data)
                }
            }

        }
    }, [diamondPopupOpen])

    const addValue = () => {
        let firstData = {
            tag_number: "",
            item: "",
            jewel_type: "",
            net_weight: "",
            wastage_percent: "",
            flat_wastage: "",
            making_charge: "",
            flat_making_charge: "",
            discount_percent: "",
            rate: ""
        }
        setDataList([...dataList, firstData])
    }

    const getTag = async (index) => {
        var tagNumber = document.getElementById('tagnumber' + index).value
        console.log(tagNumber)
        if (tagNumber.length == 7) {
            var response = await getAxios({
                url: tagdetailsEndPoint + tagNumber + '/'
            })
        }
    }




    const getTagDetails = async (tagNumber, index) => {

        if (metaltype == "1") {

        let data = [...dataList];

            const existingTagIndex = dataList.findIndex((item, i) => item.tag_number === tagNumber);
 
        if (existingTagIndex !== -1 && existingTagIndex !== index) {
            toast.error('Tag Number already exists in another row.');
            return;
        }
        data[index]['tag_number'] = tagNumber

        if (tagNumber.length == 7) {
            var response = await getAxios({
                url: goldTagEndPoint + tagNumber + '/'
            })

            if (response !== null) {
                data[index]['tag_item_id'] = response.data.id
                data[index]['item'] = response.data.item_name
                data[index]['sub_item_name'] = response.data.sub_item_name
                data[index]['item_details'] = response.data.item_details
                data[index]['sub_item_details'] = response.data.sub_item_details
                data[index]['stock_type'] = response.data.stock_type
                data[index]['jewel_type'] = response.data.sub_item_metal_name
                data[index]['metal'] = response.data.sub_item_metal_id
                data[index]['net_weight'] = response.data.net_weight
                data[index]['gross_weight'] = response.data.gross_weight
                data[index]['metal_weight'] = response.data.gross_weight
                data[index]['pieces'] = response.data.pieces
                data[index]['total_pieces'] = response.data.pieces
                data[index]['min_metal_rate'] = response.data.calculation_type == pergramCalId ? response.data.min_pergram_rate : response.data.calculation_type == fixedCalcId ? response.data.min_fixed_rate : response.data.metal_rate
                data[index]['max_metal_rate'] = response.data.calculation_type == pergramCalId ? response.data.max_pergram_rate : response.data.calculation_type == fixedCalcId ? response.data.max_fixed_rate : response.data.metal_rate
                data[index]['metal_rate'] = response.data.calculation_type == pergramCalId ? response.data.max_pergram_rate : response.data.calculation_type == fixedCalcId ? response.data.max_fixed_rate : response.data.metal_rate
                data[index]['stone_rate'] = response.data.stone_rate
                data[index]['diamond_rate'] = response.data.diamond_rate
                data[index]['stone_details'] = response.data.stone_details
                data[index]['diamond_details'] = response.data.diamond_details
                data[index]['tag_weight'] = response.data.tag_weight
                data[index]['cover_weight'] = response.data.cover_weight
                data[index]['loop_weight'] = response.data.loop_weight
                data[index]['other_weight'] = response.data.other_weight
                data[index]['calculation_type'] = response.data.calculation_type
                data[index]['making_charge_calculation_type'] = response.data.making_charge_calculation_type
                data[index]['wastage_calculation_type'] = response.data.wastage_calculation_type
                data[index]['tax_percent'] = response.data.tax_percent
                data[index]['additional_charges'] = response.data.additional_charges
                data[index]['total_stone_weight'] = response.data.total_stone_weight
                data[index]['total_diamond_weight'] = response.data.total_diamond_weight
                data[index]['per_gram_weight_type'] = response.data.calculation_type == pergramCalId ? response.data.per_gram_weight_type : null
                data[index]['item_huid_rate'] = response.data.item_huid_rate

                if (response.data.calculation_type.toString() === weightCalId.toString()) {
                    data[index]['min_wastage_percent'] = response.data.min_wastage_percent
                    data[index]['min_flat_wastage'] = response.data.min_flat_wastage
                    data[index]['min_making_charge'] = response.data.min_making_charge_gram
                    data[index]['min_flat_making_charge'] = response.data.min_flat_making_charge
                    data[index]['max_wastage_percent'] = response.data.max_wastage_percent
                    data[index]['max_flat_wastage'] = response.data.max_flat_wastage
                    data[index]['max_making_charge'] = response.data.max_making_charge_gram
                    data[index]['max_flat_making_charge'] = response.data.max_flat_making_charge
                    data[index]['wastage_percent'] = response.data.max_wastage_percent
                    data[index]['flat_wastage'] = response.data.max_flat_wastage
                    data[index]['making_charge'] = response.data.max_making_charge_gram
                    data[index]['flat_making_charge'] = response.data.max_flat_making_charge
                } else {
                    data[index]['min_wastage_percent'] = 0
                    data[index]['min_flat_wastage'] = 0
                    data[index]['min_making_charge'] = 0
                    data[index]['min_flat_making_charge'] = 0
                    data[index]['max_wastage_percent'] = 0
                    data[index]['max_flat_wastage'] = 0
                    data[index]['max_making_charge'] = 0
                    data[index]['max_flat_making_charge'] = 0
                    data[index]['wastage_percent'] = 0
                    data[index]['flat_wastage'] = 0
                    data[index]['making_charge'] = 0
                    data[index]['flat_making_charge'] = 0
                }

                var rateValue = response.data.max_sale_value + parseFloat(response.data.item_huid_rate)
                var gstValue = (((parseFloat(response.data.sales_tax_igst) + parseFloat(response.data.sales_tax_cgst) + parseFloat(response.data.sales_tax_sgst) + parseFloat(response.data.sales_surcharge_percent)) / 100) * rateValue) + parseFloat(response.data.sales_additional_charges)

                data[index]['min_rate'] = response.data.min_sale_value
                data[index]['sales_tax_igst'] = parseFloat(response.data.sales_tax_igst)
                data[index]['sales_tax_cgst'] = parseFloat(response.data.sales_tax_cgst)
                data[index]['sales_tax_sgst'] = parseFloat(response.data.sales_tax_sgst)
                data[index]['sales_surcharge_percent'] = parseFloat(response.data.sales_surcharge_percent)
                data[index]['sales_additional_charges'] = parseFloat(response.data.sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rateValue
                data[index]['with_gst_rate'] = gst ? rateValue + gstValue : rateValue
            }
        } else {
            data[index]['tag_item_id'] = ""
            data[index]['item'] = ""
            data[index]['sub_item_name'] = ""
            data[index]['item_details'] = ""
            data[index]['sub_item_details'] = ""
            data[index]['stock_type'] = ""
            data[index]['jewel_type'] = ""
            data[index]['metal'] = ""
            data[index]['gross_weight'] = ""
            data[index]['net_weight'] = ""
            data[index]['wastage_percent'] = ""
            data[index]['flat_wastage'] = ""
            data[index]['making_charge'] = ""
            data[index]['flat_making_charge'] = ""
            data[index]['min_rate'] = ""
            data[index]['max_metal_rate'] = ""
            data[index]['rate'] = ""
            data[index]['pieces'] = ""
            data[index]['min_metal_rate'] = ""
            data[index]['metal_rate'] = ""
            data[index]['stone_rate'] = ""
            data[index]['diamond_rate'] = ""
            data[index]['stone_details'] = ""
            data[index]['diamond_details'] = ""
            data[index]['tag_weight'] = ""
            data[index]['cover_weight'] = ""
            data[index]['loop_weight'] = ""
            data[index]['other_weight'] = ""
            data[index]['calculation_type'] = ""
            data[index]['making_charge_calculation_type'] = ""
            data[index]['wastage_calculation_type'] = ""
            data[index]['tax_percent'] = ""
            data[index]['additional_charges'] = ""
            data[index]['gst'] = ""
            data[index]['total_stone_weight'] = ""
            data[index]['total_diamond_weight'] = ""
            data[index]['per_gram_weight_type'] = ""
            data[index]['min_wastage_percent'] = ""
            data[index]['min_flat_wastage'] = ""
            data[index]['min_making_charge'] = ""
            data[index]['min_flat_making_charge'] = ""
            data[index]['max_wastage_percent'] = ""
            data[index]['max_flat_wastage'] = ""
            data[index]['max_making_charge'] = ""
            data[index]['max_flat_making_charge'] = ""
            data[index]['min_metal_rate'] = ""
            data[index]['max_metal_rate'] = ""
            data[index]['item_huid_rate'] = ""
            data[index]['sales_tax_igst'] = ""
            data[index]['sales_tax_cgst'] = ""
            data[index]['sales_tax_sgst'] = ""
            data[index]['sales_surcharge_percent'] = ""
            data[index]['sales_additional_charges'] = ""
            data[index]['with_gst_rate'] = ""
        }

        setDataList(data)

    } else if(metaltype == "2"){

        let data = [...dataList];

        const existingTagIndex = dataList.findIndex((item, i) => item.tag_number === tagNumber);

    if (existingTagIndex !== -1 && existingTagIndex !== index) {
        toast.error('Tag Number already exists in another row.');
        return;
    }
    data[index]['tag_number'] = tagNumber

    if (tagNumber.length == 7) {
        var response = await getAxios({
            url: silverTagEndPoint + tagNumber + '/'
        })

        if (response !== null) {
            data[index]['tag_item_id'] = response.data.id
            data[index]['item'] = response.data.item_name
            data[index]['sub_item_name'] = response.data.sub_item_name
            data[index]['item_details'] = response.data.item_details
            data[index]['sub_item_details'] = response.data.sub_item_details
            data[index]['stock_type'] = response.data.stock_type
            data[index]['jewel_type'] = response.data.sub_item_metal_name
            data[index]['metal'] = response.data.sub_item_metal_id
            data[index]['net_weight'] = response.data.net_weight
            data[index]['gross_weight'] = response.data.gross_weight
            data[index]['metal_weight'] = response.data.gross_weight
            data[index]['pieces'] = response.data.pieces
            data[index]['total_pieces'] = response.data.pieces
            data[index]['min_metal_rate'] = response.data.calculation_type == pergramCalId ? response.data.min_pergram_rate : response.data.calculation_type == fixedCalcId ? response.data.min_fixed_rate : response.data.metal_rate
            data[index]['max_metal_rate'] = response.data.calculation_type == pergramCalId ? response.data.max_pergram_rate : response.data.calculation_type == fixedCalcId ? response.data.max_fixed_rate : response.data.metal_rate
            data[index]['metal_rate'] = response.data.calculation_type == pergramCalId ? response.data.max_pergram_rate : response.data.calculation_type == fixedCalcId ? response.data.max_fixed_rate : response.data.metal_rate
            data[index]['stone_rate'] = response.data.stone_rate
            data[index]['diamond_rate'] = response.data.diamond_rate
            data[index]['stone_details'] = response.data.stone_details
            data[index]['diamond_details'] = response.data.diamond_details
            data[index]['tag_weight'] = response.data.tag_weight
            data[index]['cover_weight'] = response.data.cover_weight
            data[index]['loop_weight'] = response.data.loop_weight
            data[index]['other_weight'] = response.data.other_weight
            data[index]['calculation_type'] = response.data.calculation_type
            data[index]['making_charge_calculation_type'] = response.data.making_charge_calculation_type
            data[index]['wastage_calculation_type'] = response.data.wastage_calculation_type
            data[index]['tax_percent'] = response.data.tax_percent
            data[index]['additional_charges'] = response.data.additional_charges
            data[index]['total_stone_weight'] = response.data.total_stone_weight
            data[index]['total_diamond_weight'] = response.data.total_diamond_weight
            data[index]['per_gram_weight_type'] = response.data.calculation_type == pergramCalId ? response.data.per_gram_weight_type : null
            data[index]['item_huid_rate'] = response.data.item_huid_rate

            if (response.data.calculation_type.toString() === weightCalId.toString()) {
                data[index]['min_wastage_percent'] = response.data.min_wastage_percent
                data[index]['min_flat_wastage'] = response.data.min_flat_wastage
                data[index]['min_making_charge'] = response.data.min_making_charge_gram
                data[index]['min_flat_making_charge'] = response.data.min_flat_making_charge
                data[index]['max_wastage_percent'] = response.data.max_wastage_percent
                data[index]['max_flat_wastage'] = response.data.max_flat_wastage
                data[index]['max_making_charge'] = response.data.max_making_charge_gram
                data[index]['max_flat_making_charge'] = response.data.max_flat_making_charge
                data[index]['wastage_percent'] = response.data.max_wastage_percent
                data[index]['flat_wastage'] = response.data.max_flat_wastage
                data[index]['making_charge'] = response.data.max_making_charge_gram
                data[index]['flat_making_charge'] = response.data.max_flat_making_charge
            } else {
                data[index]['min_wastage_percent'] = 0
                data[index]['min_flat_wastage'] = 0
                data[index]['min_making_charge'] = 0
                data[index]['min_flat_making_charge'] = 0
                data[index]['max_wastage_percent'] = 0
                data[index]['max_flat_wastage'] = 0
                data[index]['max_making_charge'] = 0
                data[index]['max_flat_making_charge'] = 0
                data[index]['wastage_percent'] = 0
                data[index]['flat_wastage'] = 0
                data[index]['making_charge'] = 0
                data[index]['flat_making_charge'] = 0
            }

            var rateValue = response.data.max_sale_value + parseFloat(response.data.item_huid_rate)
            var gstValue = (((parseFloat(response.data.sales_tax_igst) + parseFloat(response.data.sales_tax_cgst) + parseFloat(response.data.sales_tax_sgst) + parseFloat(response.data.sales_surcharge_percent)) / 100) * rateValue) + parseFloat(response.data.sales_additional_charges)

            data[index]['min_rate'] = response.data.min_sale_value
            data[index]['sales_tax_igst'] = parseFloat(response.data.sales_tax_igst)
            data[index]['sales_tax_cgst'] = parseFloat(response.data.sales_tax_cgst)
            data[index]['sales_tax_sgst'] = parseFloat(response.data.sales_tax_sgst)
            data[index]['sales_surcharge_percent'] = parseFloat(response.data.sales_surcharge_percent)
            data[index]['sales_additional_charges'] = parseFloat(response.data.sales_additional_charges)
            data[index]['gst'] = gst ? gstValue : 0
            data[index]['rate'] = rateValue
            data[index]['with_gst_rate'] = gst ? rateValue + gstValue : rateValue
        }
    } else {
        data[index]['tag_item_id'] = ""
        data[index]['item'] = ""
        data[index]['sub_item_name'] = ""
        data[index]['item_details'] = ""
        data[index]['sub_item_details'] = ""
        data[index]['stock_type'] = ""
        data[index]['jewel_type'] = ""
        data[index]['metal'] = ""
        data[index]['gross_weight'] = ""
        data[index]['net_weight'] = ""
        data[index]['wastage_percent'] = ""
        data[index]['flat_wastage'] = ""
        data[index]['making_charge'] = ""
        data[index]['flat_making_charge'] = ""
        data[index]['min_rate'] = ""
        data[index]['max_metal_rate'] = ""
        data[index]['rate'] = ""
        data[index]['pieces'] = ""
        data[index]['min_metal_rate'] = ""
        data[index]['metal_rate'] = ""
        data[index]['stone_rate'] = ""
        data[index]['diamond_rate'] = ""
        data[index]['stone_details'] = ""
        data[index]['diamond_details'] = ""
        data[index]['tag_weight'] = ""
        data[index]['cover_weight'] = ""
        data[index]['loop_weight'] = ""
        data[index]['other_weight'] = ""
        data[index]['calculation_type'] = ""
        data[index]['making_charge_calculation_type'] = ""
        data[index]['wastage_calculation_type'] = ""
        data[index]['tax_percent'] = ""
        data[index]['additional_charges'] = ""
        data[index]['gst'] = ""
        data[index]['total_stone_weight'] = ""
        data[index]['total_diamond_weight'] = ""
        data[index]['per_gram_weight_type'] = ""
        data[index]['min_wastage_percent'] = ""
        data[index]['min_flat_wastage'] = ""
        data[index]['min_making_charge'] = ""
        data[index]['min_flat_making_charge'] = ""
        data[index]['max_wastage_percent'] = ""
        data[index]['max_flat_wastage'] = ""
        data[index]['max_making_charge'] = ""
        data[index]['max_flat_making_charge'] = ""
        data[index]['min_metal_rate'] = ""
        data[index]['max_metal_rate'] = ""
        data[index]['item_huid_rate'] = ""
        data[index]['sales_tax_igst'] = ""
        data[index]['sales_tax_cgst'] = ""
        data[index]['sales_tax_sgst'] = ""
        data[index]['sales_surcharge_percent'] = ""
        data[index]['sales_additional_charges'] = ""
        data[index]['with_gst_rate'] = ""
    }

    setDataList(data)

    }
    
    }


   



    

    const removeValue = () => {
        let data = [...dataList];
        data.splice(deleteIndex, 1);
        setDataList(data);
        setIsDeleteOpen(false)
        setIsLoading(null)
        setDeleteIndex(null)
    }

    const onValueUpdate = (input, index) => {
        const { name, value } = input;
        let data = [...dataList];
        data[index][name] = value;
        setDataList(data);
    }

    const metalOnChage = (input, index) => {
        const { name, value } = input;
        let data = [...dataList];


        if (value.length > 0) {
            data[index][name] = value;
        }

        if (data[index].calculation_type.toString() === pergramCalId.toString()) {
            if (data[index].stock_type.toString() === nonTagStockType.toString() || data[index].stock_type.toString() === tagStockType.toString()) {
                let metalRate = parseFloat(value)
                let weightType = data[index].per_gram_weight_type == grossWeightType ? data[index].gross_weight : data[index].net_weight
                let rate = (parseFloat(weightType) * metalRate) + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        } else if (data[index].calculation_type.toString() === fixedCalcId.toString()) {
            if (data[index].stock_type.toString() === packetStockType.toString()) {
                let metalRate = parseFloat(value)
                let perPcRate = metalRate / parseInt(data[index].total_pieces)
                let rate = parseInt(data[index].pieces) * parseFloat(perPcRate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            } else if (data[index].stock_type.toString() === nonTagStockType.toString() || data[index].stock_type.toString() === tagStockType.toString()) {
                let rate = parseFloat(value) + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        } else if (data[index].calculation_type.toString() === weightCalId.toString()) {
            if (data[index].stock_type.toString() === nonTagStockType.toString() || data[index].stock_type.toString() === tagStockType.toString()) {

                var grossWeight = parseFloat(data[index].gross_weight)
                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let wasWeight = data[index].wastage_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let mcWeight = data[index].making_charge_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let weightCalcAmt = weightCalculation(value, data[index].wastage_percent, data[index].flat_wastage, data[index].making_charge, data[index].flat_making_charge, mcWeight, wasWeight)

                let metalAmt = parseFloat(netWgt) * parseFloat(value);
                let rate = metalAmt + weightCalcAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        }
        setDataList(data);
    }

    const grossWeightOnChange = (input, index) => {
        const { name, value } = input;
        let data = [...dataList];

        if (value.length > 0) {
            data[index][name] = value;
        }


        if (data[index].calculation_type.toString() === pergramCalId.toString()) {
            if (data[index].stock_type.toString() === nonTagStockType.toString()) {
                let grossWeight = parseFloat(value)
                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let weightType = data[index].per_gram_weight_type == grossWeightType ? grossWeight : netWgt
                let rate = (weightType * parseFloat(data[index].metal_rate)) + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        } else if (data[index].calculation_type.toString() === weightCalId.toString()) {
            if (data[index].stock_type.toString() === nonTagStockType.toString()) {
                let grossWeight = parseFloat(value)

                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let wasWeight = data[index].wastage_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let mcWeight = data[index].making_charge_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let weightCalcAmt = weightCalculation(data[index].metal_rate, data[index].wastage_percent, data[index].flat_wastage, data[index].making_charge, data[index].flat_making_charge, mcWeight, wasWeight)

                let metalAmt = parseFloat(netWgt) * parseFloat(data[index].metal_rate);
                let rate = metalAmt + weightCalcAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        } else if (data[index].calculation_type.toString() === fixedCalcId.toString()) {
            if (data[index].stock_type.toString() === nonTagStockType.toString()) {
                let grossWeight = parseFloat(value)

                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let metalAmt = parseFloat(netWgt) * (parseFloat(data[index].metal_rate) / data[index].metal_weight);
                let rate = metalAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        }
        setDataList(data);
    }

    const weightCalculation = (metalRate, was, fWas, mCharge, fmCharge, mcWeight, wasWeight) => {
        let wasPercent = (parseFloat(was) / 100) * parseFloat(wasWeight);
        let wasPercentAmt = parseFloat(metalRate) * parseFloat(wasPercent);

        let makChargeGramAmt = parseFloat(mCharge) * parseFloat(mcWeight);

        return wasPercentAmt + makChargeGramAmt + parseFloat(fWas) + parseFloat(fmCharge)

    }

    const onPiecesChange = (input, index) => {
        const { name, value } = input;
        let data = [...dataList];

        if (value.length > 0) {
            data[index][name] = value;
        }

        if (data[index].calculation_type.toString() === fixedCalcId.toString()) {
            if (data[index].stock_type.toString() === packetStockType.toString()) {
                let metalRate = data[index].metal_rate
                let perPcRate = metalRate / parseInt(data[index].total_pieces)
                let rate = parseInt(value) * parseFloat(perPcRate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        }

        setDataList(data);
    }

    const onWeightCalcChange = (input, index) => {
        const { name, value } = input;
        let data = [...dataList];
        data[index][name] = value;

        if (data[index].stock_type.toString() === nonTagStockType.toString() || data[index].stock_type.toString() === tagStockType.toString()) {
            if (name === 'wastage_percent') {
                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var grossWeight = parseFloat(data[index].gross_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let wasWeight = data[index].wastage_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let mcWeight = data[index].making_charge_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let weightCalcAmt = weightCalculation(data[index].metal_rate, value, data[index].flat_wastage, data[index].making_charge, data[index].flat_making_charge, mcWeight, wasWeight)

                let metalAmt = parseFloat(netWgt) * parseFloat(data[index].metal_rate);

                let rate = metalAmt + weightCalcAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            } else if (name === 'flat_wastage') {
                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var grossWeight = parseFloat(data[index].gross_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let wasWeight = data[index].wastage_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let mcWeight = data[index].making_charge_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let weightCalcAmt = weightCalculation(data[index].metal_rate, data[index].wastage_percent, value, data[index].making_charge, data[index].flat_making_charge, mcWeight, wasWeight)

                let metalAmt = parseFloat(netWgt) * parseFloat(data[index].metal_rate);
                let rate = metalAmt + weightCalcAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate

            } else if (name === 'making_charge') {
                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var grossWeight = parseFloat(data[index].gross_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let wasWeight = data[index].wastage_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let mcWeight = data[index].making_charge_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let weightCalcAmt = weightCalculation(data[index].metal_rate, data[index].wastage_percent, data[index].flat_wastage, value, data[index].flat_making_charge, mcWeight, wasWeight)

                let metalAmt = parseFloat(netWgt) * parseFloat(data[index].metal_rate);
                let rate = metalAmt + weightCalcAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate

            } else if (name === 'flat_making_charge') {
                var tagWgt = parseFloat(data[index].tag_weight)
                var coverWt = parseFloat(data[index].cover_weight)
                var loopWgt = parseFloat(data[index].loop_weight)
                var otherWgt = parseFloat(data[index].other_weight)
                var grossWeight = parseFloat(data[index].gross_weight)
                var stoneWeight = parseFloat(data[index].total_stone_weight)
                var diamondWeight = parseFloat(data[index].total_diamond_weight)

                var netWgt = parseFloat(grossWeight) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt)
                data[index]['net_weight'] = netWgt

                let wasWeight = data[index].wastage_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let mcWeight = data[index].making_charge_calculation_type.toString() === grossWeightType.toString() ? grossWeight : data[index].net_weight
                let weightCalcAmt = weightCalculation(data[index].metal_rate, data[index].wastage_percent, data[index].flat_wastage, data[index].making_charge, value, mcWeight, wasWeight)

                let metalAmt = parseFloat(netWgt) * parseFloat(data[index].metal_rate);
                let rate = metalAmt + weightCalcAmt + parseFloat(data[index].stone_rate) + parseFloat(data[index].diamond_rate) + parseFloat(data[index].item_huid_rate)
                var gstValue = (((parseFloat(data[index].sales_tax_igst) + parseFloat(data[index].sales_tax_cgst) + parseFloat(data[index].sales_tax_sgst) + parseFloat(data[index].sales_surcharge_percent)) / 100) * rate) + parseFloat(data[index].sales_additional_charges)
                data[index]['gst'] = gst ? gstValue : 0
                data[index]['rate'] = rate
                data[index]['with_gst_rate'] = gst ? rate + gstValue : rate
            }
        }

        setDataList(data);
    }

    const onTotalAmountUpdate = (input, index) => {
        const { name, value } = input;
        let data = [...dataList];
        data[index][name] = value;

        let rate = (data[index].with_gst_rate * 100) / (100 + 3)

        data[index]['rate'] = rate
        data[index]['gst'] = parseFloat(data[index].with_gst_rate) - rate
        let without_huid = rate - parseFloat(data[index]['item_huid_rate']);
        let without_mc_g = without_huid - parseFloat(data[index]['making_charge_calculation_type'] == grossWeightType ? (parseFloat(data[index].gross_weight) * parseFloat(data[index].making_charge)) : (parseFloat(data[index].net_weight) * parseFloat(data[index].making_charge)))
        let without_fmc = without_mc_g - parseFloat(data[index].flat_making_charge)
        let without_fw = without_fmc - parseFloat(data[index].flat_wastage)
        let actualRate = parseFloat(data[index].metal_rate) * parseFloat(data[index].net_weight)
        let wastage_amt = without_fw - actualRate

        let wastage_percent = (wastage_amt * 100) / actualRate
        data[index]['wastage_percent'] = wastage_percent

        setDataList(data);
    }

    return (
        <div>
            <div className="flex items-center justify-between ">
                <div>Estimate Details</div>

                <div>
                    <p></p>
                </div>
                <button
                    id="additem"
                    onClick={(e) => { e.preventDefault(); addValue() }}
                    className="capitalize bg-primary-btn text-white w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >Add Item<span className="lowercase">(enter)</span></button>
            </div>
            <div className="w-full my-[10px] overflow-scroll max-h-[185px]">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="py-[10px] border text-center text-[10px]">S.No</td>
                            <td className="py-[10px] border text-center text-[10px]">Tag No</td>
                            <td className="py-[10px] border text-center text-[10px]">Sub Item</td>
                            <td className="py-[10px] border text-center text-[10px]">Rate</td>
                            <td className="py-[10px] border text-center text-[10px]">Pcs</td>
                            <td className="py-[10px] border text-center text-[10px]">Gross wt</td>
                            <td className="py-[10px] border text-center text-[10px]">Net wt</td>
                            <td className="py-[10px] border text-center text-[10px]">Wastage %</td>
                            <td className="py-[10px] border text-center text-[10px]">F.Wastage</td>
                            {/* <td className="py-[10px] border text-center text-[10px]">MC/g</td> */}
                            {/* <td className="py-[10px] border text-center text-[10px]">F.MC</td> */}
                            <td className="py-[10px] border text-center text-[10px]">Stone amt</td>
                            <td className="py-[10px] border text-center text-[10px]">Diamond amt</td>
                            <td className="py-[10px] border text-center text-[10px]">HUID Rate</td>
                            <td className="py-[10px] border text-center text-[10px]">GST</td>
                            <td className="py-[10px] border text-center text-[10px]">Total</td>
                            <td className="py-[10px] border text-center text-[10px]">Actions</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            dataList.map((item, index) => (
                                <tr>
                                    <td className="py-[10px] border text-center">{index + 1}</td>
                                    <td className="py-[10px] border text-center" onClick={(e) => { e.preventDefault(); getTag(index) }}>
                                        <input
                                            type="text"
                                            name="tag_number"
                                            className="border outline-none w-[100px]"
                                            value={item.tag_number}
                                            onChange={(e) => getTagDetails(e.target.value, index)}
                                            id={"tagnumber" + index}
                                        />
                                    </td>
                                    <td className="py-[10px] border text-center max-w-[100px]">{item.item}</td>
                                    <td className="py-[10px] border text-center"> {item.metal_rate ? <div>
                                        <input
                                            type="text"
                                            name="metal_rate"
                                            className="border outline-none w-[75px]"
                                            value={item.metal_rate}
                                            onChange={(e) => metalOnChage(e.target, index)}
                                            readOnly={item.calculation_type?.toString() !== weightCalId.toString() ? false : true}
                                        />
                                        {item.calculation_type?.toString() !== weightCalId.toString() && <div> {(parseFloat(item.metal_rate) >= item.min_metal_rate && parseFloat(item.metal_rate) <= item.max_metal_rate) ? "" : <p className='text-red-600 text-[9px]'>
                                            Rate is between {item.min_metal_rate} and {item.max_metal_rate}
                                        </p>}</div>}
                                    </div> : "-"}</td>
                                    <td className="py-[10px] border text-center">{item.pieces ? <input
                                        type="text"
                                        name="pieces"
                                        className="border outline-none w-[50px]"
                                        value={item.pieces}
                                        onChange={(e) => onPiecesChange(e.target, index)}
                                        readOnly={item.stock_type?.toString() === packetStockType.toString() ? false : true}
                                    /> : "-"}</td>
                                    <td className="py-[10px] border text-center">{item.gross_weight ? <input
                                        type="text"
                                        name="gross_weight"
                                        className="border outline-none w-[75px]"
                                        value={item.gross_weight}
                                        onChange={(e) => grossWeightOnChange(e.target, index)}
                                        readOnly={item.stock_type?.toString() === nonTagStockType.toString() ? false : true}
                                    /> : "-"}</td>
                                    <td className="py-[10px] border text-center">{item.net_weight ? <input
                                        type="text"
                                        name="net_weight"
                                        className="cursor-not-allowed border outline-none w-[75px]"
                                        value={item.net_weight}
                                        onChange={(e) => onValueUpdate(e.target, index)}
                                        readOnly
                                    /> : "-"}</td>
                                    <td className="py-[10px] border text-center">{
                                        item.calculation_type == weightCalId ? <div>
                                            <input
                                                type="text"
                                                name="wastage_percent"
                                                className="border outline-none w-[50px]"
                                                value={item.wastage_percent}
                                                onChange={(e) => onWeightCalcChange(e.target, index)}
                                                readOnly={item.calculation_type?.toString() === weightCalId.toString() ? false : true}
                                            />
                                            {(parseFloat(item.wastage_percent) >= item.min_wastage_percent && parseFloat(item.wastage_percent) <= item.max_wastage_percent) ? "" : <p className='text-red-600 text-[9px]'>
                                                Percent is between {item.min_wastage_percent} and {item.max_wastage_percent}
                                            </p>}
                                        </div> : "-"}</td>
                                    <td className="py-[10px] border text-center">{
                                        item.calculation_type == weightCalId ? <div> <input
                                            type="text"
                                            name="flat_wastage"
                                            className="border outline-none w-[75px]"
                                            value={item.flat_wastage}
                                            onChange={(e) => onWeightCalcChange(e.target, index)}
                                            readOnly={item.calculation_type?.toString() === weightCalId.toString() ? false : true}
                                        />
                                            {(parseFloat(item.flat_wastage) >= item.min_flat_wastage && parseFloat(item.flat_wastage) <= item.max_flat_wastage) ? "" : <p className='text-red-600 text-[9px]'>
                                                Wastage is between {item.min_flat_wastage} and {item.max_flat_wastage}
                                            </p>}
                                        </div> : "-"}</td>
                                    {/* <td className="py-[10px] border text-center">{
                                        item.calculation_type == weightCalId ? <div> <input
                                            type="text"
                                            name="making_charge"
                                            className="border outline-none w-[75px]"
                                            value={item.making_charge}
                                            onChange={(e) => onWeightCalcChange(e.target, index)}
                                            readOnly={item.calculation_type?.toString() === weightCalId.toString() ? false : true}
                                        />
                                            {(parseFloat(item.making_charge) >= item.min_making_charge && parseFloat(item.making_charge) <= item.max_making_charge) ? "" : <p className='text-red-600 text-[9px]'>
                                                Charges is between {item.min_making_charge} and {item.max_making_charge}
                                            </p>}
                                        </div> : "-"}</td>
                                    <td className="py-[10px] border text-center">{
                                        item.calculation_type == weightCalId ? <div><input
                                            type="text"
                                            name="flat_making_charge"
                                            className="border outline-none w-[75px]"
                                            value={item.flat_making_charge}
                                            onChange={(e) => onWeightCalcChange(e.target, index)}
                                            readOnly={item.calculation_type?.toString() === weightCalId.toString() ? false : true}
                                        />
                                            {(parseFloat(item.flat_making_charge) >= item.min_flat_making_charge && parseFloat(item.flat_making_charge) <= item.max_flat_making_charge) ? "" : <p className='text-red-600 text-[9px]'>
                                                Charges is between {item.min_flat_making_charge} and {item.max_flat_making_charge}
                                            </p>}
                                        </div> : "-"}</td> */}

                                    <td className="py-[10px] border text-center">
                                        <div className="flex gap-[5px] justify-center">
                                            <input
                                                type="text"
                                                name="stone_rate"
                                                className="border outline-none w-[75px]"
                                                value={item.stone_rate && item?.stone_rate.toFixed(2)}
                                                onChange={(e) => onValueUpdate(e.target, index)}
                                                readOnly
                                            />
                                            {/* {item.tag_number && <button onClick={(e) => {
                                                e.preventDefault();
                                                setStonePopupOpen(true);
                                                setCurrentIndex(index);
                                                setStoneList(item.stone_details);
                                            }} className="text-green-500 outline-none border-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M228 128a12 12 0 0 1-12 12h-76v76a12 12 0 0 1-24 0v-76H40a12 12 0 0 1 0-24h76V40a12 12 0 0 1 24 0v76h76a12 12 0 0 1 12 12" /></svg>
                                            </button>} */}
                                        </div>
                                    </td>
                                    <td className="py-[10px] border text-center">
                                        <div className="flex gap-[5px] justify-center">
                                            <input
                                                type="text"
                                                name="diamond_rate"
                                                className="border outline-none w-[75px]"
                                                value={item.diamond_rate && item.diamond_rate.toFixed(2)}
                                                onChange={(e) => onValueUpdate(e.target, index)}
                                                readOnly
                                            />
                                            {/* {item.tag_number && <button onClick={(e) => {
                                                e.preventDefault();
                                                setDiamondPopupOpen(true);
                                                setCurrentIndex(index);
                                                setDiamondList(item.diamond_details);
                                            }} className="text-green-500 outline-none border-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M228 128a12 12 0 0 1-12 12h-76v76a12 12 0 0 1-24 0v-76H40a12 12 0 0 1 0-24h76V40a12 12 0 0 1 24 0v76h76a12 12 0 0 1 12 12" /></svg>
                                            </button>} */}
                                        </div>
                                    </td>
                                    <td className="py-[10px] border text-center">{item.item_huid_rate}</td>
                                    <td className="py-[10px] border text-center">{item.gst && item.gst.toFixed(2)}</td>
                                    <td className="py-[10px] border text-center w-[75px]">
                                        <input
                                            type="text"
                                            name="with_gst_rate"
                                            className="border outline-none w-[75px]"
                                            value={item.with_gst_rate}
                                            onChange={(e) => onTotalAmountUpdate(e.target, index)}
                                        /></td>
                                    <td className="py-[10px] border text-center">
                                        <div onClick={() => { setIsDeleteOpen(true); setDeleteIndex(index); setIsLoading(index); }} className={`${String(isLoading) !== String(index) ? 'block' : 'hidden'} cursor-pointer hover:scale-125 hover:text-red-600 w-fit mx-auto`}>
                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Popup title={'Delete item'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to delete the diamond details?</p>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                        <button type="submit" onClick={() => { removeValue() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                    </div>
                </div>
            </Popup>

            <Popup extraClassName="min-w-full" title={"Add Stone Details"} setIsOpen={setStonePopupOpen} isOpen={stonePopupOpen} closeModal={closeStoneModal}>
                <StoneForm dataList={stoneList} setDataList={setStoneList} closeModal={closeStoneModal} />
            </Popup>

            <Popup extraClassName="min-w-full" title={"Add Diamond Details"} setIsOpen={setDiamondPopupOpen} isOpen={diamondPopupOpen} closeModal={closeDiamondModal}>
                <DiamondForm dataList={diamondList} setDataList={setDiamondList} closeModal={closeDiamondModal} />
            </Popup>
        </div>
    );
}