import { useEffect, useState } from "react";
import { baseUrl, genderTypeEndPoint, itemMasterListEndPoint, measurementTypeEndPoint, metalListEndPoint, purityListEndPoint, subitemListEndPoint, uploadOrderImagesEndPoint } from "../../../service/api";
import { getAxios, postAxiosFormWithToken } from "../../../service/apiservice";
import { goldMetal, silverMetal } from "../../../constant";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function OrderItemForm({ metalRate, setMetalRate, orderDetails, formValues, setFormValues, errors, setErrors, addStoneList, setAddStoneList, addDiamondList, setAddDiamondList, }) {

    const [formMode, setFormMode] = useState('create');

    const { todayGoldRate, todaySilverRate } = useSelector((state) => state.appConfig)

    const [metalTypeDropdown, setMetalTypeDropdown] = useState([]);
    const [purityTypeDropdown, setPurityTypeDropdown] = useState([]);
    const [itemDropdown, setItemDropdown] = useState([]);
    const [subItemDropdown, setSubItemDropdown] = useState([]);
    const [measurementDropdown, setMeasurementDropdown] = useState([]);
    const [genderDropdown, setGenderDropdown] = useState([]);

    const [grossWeight, setGrossWeight] = useState(0);
    const [totalStoneWeight, setTotalStoneWeight] = useState(0);
    const [totalDiamondWeight, setTotalDiamondWeight] = useState(0);

    const [imagesList, setImagesList] = useState([]);
    const [imageHoverIndex, setImageHoverIndex] = useState(null);
    const [selectedItemName, setSelectedItemName] = useState('')

    useEffect(() => {
        getMetalTypeDropDown();
        getItemDropDown();
        getMeasurementDropDownData();
        getGenderDropDownData();
    }, [])

    useEffect(() => {
        if (!addStoneList) {
            calculation()
        }
    }, [addStoneList])

    useEffect(() => {
        if (!addDiamondList) {
            calculation()
        }
    }, [addStoneList])

    useEffect(() => {
        calculation()
    }, [metalRate, grossWeight, totalStoneWeight, totalDiamondWeight])

    const calculation = () => {

        let grossWgt = grossWeight;
        let netWgt = grossWgt;

        if (addStoneList) {
            netWgt = parseFloat(netWgt) - parseFloat(totalStoneWeight)
        }

        if (addDiamondList) {
            netWgt = parseFloat(netWgt) - parseFloat(totalDiamondWeight)
        }

        setFormValues((prevState) => ({
            ...prevState,
            net_weight: netWgt
        }))

    }

    const getMetalTypeDropDown = async () => {
        var respose = await getAxios({
            url: metalListEndPoint
        })

        if (respose !== null) {
            setMetalTypeDropdown(respose.data.list)
        }
    }

    const getPurityListDropDown = async (id) => {
        var respose = await getAxios({
            url: purityListEndPoint + id + '/',
        })

        if (respose !== null) {
            setPurityTypeDropdown(respose.data.list)
        }
    }

    const getItemDropDown = async () => {
        var respose = await getAxios({
            url: itemMasterListEndPoint
        })

        if (respose !== null) {
            setItemDropdown(respose.data.list)
        }
    }

    const getSubItemDropDownData = async (id) => {
        var response = await getAxios({
            url: subitemListEndPoint + id + "/"
        })

        if (response !== null) {
            setSubItemDropdown(response.data.list)
        }
    }

    const getMeasurementDropDownData = async () => {
        var response = await getAxios({
            url: measurementTypeEndPoint
        })

        if (response !== null) {
            setMeasurementDropdown(response.data.list)
        }
    }

    const getGenderDropDownData = async () => {
        var response = await getAxios({
            url: genderTypeEndPoint
        })

        if (response !== null) {
            setGenderDropdown(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'metal') {
            if (value != "") {
                getPurityListDropDown(value)

                if (value == goldMetal) {
                    setMetalRate(todayGoldRate)
                } else if (value == silverMetal) {
                    setMetalRate(todaySilverRate)
                }

                for (var i of metalTypeDropdown) {
                    if (i.id == value) {
                        setFormValues((prevState) => ({
                            ...prevState,
                            metal_name: i.metal_name
                        }))
                        break
                    }
                }
            } else {
                setPurityTypeDropdown([])
            }
        } else if (name == 'purity') {
            for (var i of purityTypeDropdown) {
                if (i.id == value) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        purity_name: i.purity_name
                    }))
                    break
                }
            }
        } else if (name == 'item') {
            if (value != "") {
                getSubItemDropDownData(value)

                for (var i of itemDropdown) {
                    if (i.id == value) {
                        setFormValues((prevState) => ({
                            ...prevState,
                            item_name: i.item_name
                        }))
                        setSelectedItemName(i.item_name)
                        break
                    }
                }
            } else {
                setSubItemDropdown([])
                setSelectedItemName("")
            }
        } else if (name == 'measurement_type') {
            for (var i of measurementDropdown) {
                if (i.id == value) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        measurement_name: i.measurement_name
                    }))
                    break
                }
            }
        } else if (name == 'gross_weight') {
            setGrossWeight(value)
        } else if (name == 'total_stone_weight') {
            setTotalStoneWeight(value)
        } else if (name == 'total_diamond_weight') {
            setTotalDiamondWeight(value)
        } else if (name == 'gender') {
            for (var i of genderDropdown) {
                if (i.id == value) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        gendor_name: i.name
                    }))
                    break;
                }
            }
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const uploadImage = async (e) => {
        var uploadImageForm = new FormData(document.getElementById('imageupload'))

        var response = await postAxiosFormWithToken({
            url: uploadOrderImagesEndPoint,
            body: uploadImageForm
        })

        if (response != null) {
            setImagesList((prevState) => [...prevState, response.data.image])
            toast.success(response.message)
        }
    }

    return (
        <div className="">
            <form id="orderitem">
                <div className="grid  max-[639px]:grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-2">
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Metal <span className="text-red-500">*</span></p>
                        <select
                            placeholder="metal name"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none bg-white"
                            name="metal"
                            value={formValues.metal}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select metal--</option>
                            {
                                metalTypeDropdown.map((item, index) => (
                                    <option key={item.metal_name} value={item.id}>{item.metal_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Purity <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Purity"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none bg-white"
                            name="purity"
                            value={formValues.purity}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select Purity--</option>
                            {
                                purityTypeDropdown.map((item, index) => (
                                    <option key={item.purity_name} value={item.id}>{item.purity_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.purity}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Item Name <span className="text-red-500">*</span></p>
                        <select
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none bg-white"
                            name="item"
                            value={formValues.item}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select item--</option>
                            {
                                itemDropdown.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.item_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.item}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Sub Item Name <span className="text-red-500">*</span></p>
                        <select
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none bg-white"
                            name="sub_item"
                            value={formValues.sub_item}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select sub item--</option>
                            {
                                subItemDropdown.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.sub_item_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Gross Weight <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            placeholder="Gross Weight"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="gross_weight"
                            value={formValues.gross_weight}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.gross_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Net Weight <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            placeholder="Net Weight"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="net_weight"
                            value={isNaN(formValues.net_weight) ? 0 : formValues.net_weight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.net_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Quantity <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            placeholder="Quantity"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="quantity"
                            value={formValues.quantity}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.quantity}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Gender <span className="text-red-500">*</span></p>
                        <select
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none bg-white"
                            name="gender"
                            value={formValues.gender}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select gender--</option>
                            {
                                genderDropdown.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.gender}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Meas Type</p>
                        <select
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none bg-white"
                            name="measurement_type"
                            value={formValues.measurement_type}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select measurement--</option>
                            {
                                measurementDropdown.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.measurement_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    {formValues.measurement_type != "" && <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">{formValues.measurement_name}</p>
                        <input
                            type="text"
                            placeholder={formValues.measurement_name}
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="measurement_value"
                            value={formValues.measurement_value}
                            onChange={onValueUpdate}
                        />
                    </div>}
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Description</p>
                        <input
                            type="text"
                            placeholder="Description"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="description"
                            value={formValues.description}
                            onChange={onValueUpdate}
                        />
                    </div>
                    {selectedItemName.length > 0 && <div className="my-[2px] w-full">
                        <p className="mb-[2px] text-[13px]">Images</p>
                        <div className="px-2 w-full border border-gray-300 rounded-lg h-[28px] flex gap-3 items-center justify-between">
                            <div className="flex items-center gap-3 overflow-x-scroll">
                                {
                                    imagesList.map((item, index) => (
                                        <div onMouseEnter={(e) => {
                                            setImageHoverIndex(index)
                                        }} onMouseLeave={(e) => {
                                            setImageHoverIndex(null)
                                        }} className="w-[50px] relative overflow-hidden">
                                            <img onClick={() => {
                                                let data = [...imagesList];
                                                data.splice(index, 1);
                                                setImagesList(data);
                                            }} src={baseUrl + item} width={"100%"} />
                                            {index == imageHoverIndex && <div className="absolute top-1 left-3 flex items-center justify-center">
                                                <svg className="text-red-600" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                            </div>}
                                        </div>
                                    ))
                                }
                            </div>
                            <label
                                className=" w-fit text-[13px] outline-none"
                                htmlFor="image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M18.71 17.565a4.25 4.25 0 0 0 0-6.01l-6.54-6.54A1 1 0 0 1 13.584 3.6l6.54 6.54a6.25 6.25 0 1 1-8.838 8.84l-7.954-7.955A4.501 4.501 0 0 1 9.698 4.66l7.953 7.953a2.752 2.752 0 0 1-3.892 3.891L6.513 9.257a1 1 0 0 1 1.414-1.415l7.247 7.247a.751.751 0 0 0 1.063-1.062L8.284 6.074A2.501 2.501 0 0 0 4.746 9.61l7.954 7.954a4.25 4.25 0 0 0 6.01 0Z" /></g></svg>
                            </label>
                        </div>
                    </div>}
                </div>
            </form>

            <form id="imageupload">
                <input
                    type="file"
                    name="image"
                    id="image"
                    onChange={uploadImage}
                    hidden
                />
                <input
                    value={orderDetails.order_id}
                    name="order_id"
                    hidden
                />
                <input
                    value={selectedItemName}
                    name="order_item"
                    hidden
                />
            </form>

            <div>
                <div class="inline-flex items-center">
                    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                        <input
                            type="checkbox"
                            checked={addStoneList}
                            onChange={(e) => setAddStoneList(e.target.checked)}
                            class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                        />

                        <span
                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                stroke="currentColor" stroke-width="1">
                                <path fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </label>
                    <p>Add Stone</p>
                </div>
                <div class="inline-flex items-center">
                    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                        <input
                            type="checkbox"
                            checked={addDiamondList}
                            onChange={(e) => setAddDiamondList(e.target.checked)}
                            class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                        />

                        <span
                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                stroke="currentColor" stroke-width="1">
                                <path fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </label>
                    <p>Add Diamond</p>
                </div>
            </div>

            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2">
                {
                    addStoneList && <div className="grid max-[639px]:grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 gap-1">
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Total Sto Pcs</p>
                            <input
                                type="number"
                                placeholder="Total Stone Pieces"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="total_stone_pieces"
                                value={formValues.total_stone_pieces}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.total_stone_pieces}</p>
                        </div>
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Total Sto Wgt</p>
                            <input
                                type="number"
                                placeholder="Total Stone Weight"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="total_stone_weight"
                                value={formValues.total_stone_weight}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.total_stone_weight}</p>
                        </div>
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Total Sto Amt</p>
                            <input
                                type="number"
                                placeholder="Total Stone Amount"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="total_stone_amount"
                                value={formValues.total_stone_amount}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.total_stone_amount}</p>
                        </div>
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Stone Names</p>
                            <input
                                type="text"
                                placeholder="Stone Names"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="stone_description"
                                value={formValues.stone_description}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.stone_description}</p>
                        </div>
                    </div>
                }

                {
                    addDiamondList && <div className="grid max-[639px]:grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-4 gap-1">
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Total Dia Pcs</p>
                            <input
                                type="number"
                                placeholder="Total Diamond Pieces"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="total_diamond_pieces"
                                value={formValues.total_diamond_pieces}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.total_diamond_pieces}</p>
                        </div>
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Total Dia Wgt</p>
                            <input
                                type="number"
                                placeholder="Total Diamond Weight"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="total_diamond_weight"
                                value={formValues.total_diamond_weight}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.total_diamond_weight}</p>
                        </div>
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Total Dia Amt</p>
                            <input
                                type="number"
                                placeholder="Total Diamond Amount"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="total_diamond_amount"
                                value={formValues.total_diamond_amount}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.total_diamond_amount}</p>
                        </div>
                        <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Dia Names</p>
                            <input
                                type="text"
                                placeholder="Diamond Names"
                                className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="diamond_description"
                                value={formValues.diamond_description}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.diamond_description}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}