import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { SalesDetailsReportListEndPoint } from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { useKeyPress } from "../../../hooks/useKeyPress";
import SaleDetailsReportForm from "./salesdetailsreportform";
import SaleDetailsReportFilter from "./salesdetailsreportfilter";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";

export default function SaleDetailsReport() {

    const searchRef = useRef();
    const [salesdetailList, setSalesdetailList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Sales Detail Report"


    function closeExcelModal() {
        setExcelOpen(false)
    }


    useEffect(() => {
        let tempData = [];
        for (var i of salesdetailList) {
            tempData.push(i)
            tempData.push({
                is_total: true,
                item_list: ["hi"],
                total_pieces: i.total_pieces,
                total_gross_weight: i.total_gross_weight,
                total_net_weight: i.total_net_weight,
                hallmark: i.hallmark,
                total_amount: i.total_amount
            })
        }
        setData(tempData)
    }, [salesdetailList])


    let Totalquantity = 0;
    for (var i of salesdetailList) {
        if (i.quantity != "") {
            Totalquantity += parseFloat(i.quantity);
        }
    }


    let Totalweight = 0;
    for (var i of salesdetailList) {
        if (i.total_weight != "") {
            Totalweight += parseFloat(i.total_weight);
        }
    }

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        metal_type: null,
        item_name: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);


    useEffect(() => {
        if (salesdetailList.length > 0) {
            let firstData = Object.keys(salesdetailList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [salesdetailList])

    useEffect(() => {
        getSalesdetailsreportListData(tableFilters)
    }, [tableFilters])



    const getSalesdetailsreportListData = async (body) => {
        var response = await postAxiosWithToken({
            url: SalesDetailsReportListEndPoint,
            body: body
        })

        if (response !== null) {
            setSalesdetailList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                    Sales Detail Report
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={salesdetailList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={salesdetailList} fileName={fileName} />
                </div>
            </div>
            <SaleDetailsReportForm searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <SaleDetailsReportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }

                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">


                <table className="w-full ">
                    <thead className="border bg-table-head text-table-head-font text-[13px] uppercase font-[700]">
                        <tr>
                            <td></td>
                            <td className="p-[10px]">bill NO</td>
                            <td className="p-[10px]">dATE</td>
                            <td className="p-[10px]">pCS</td>
                            <td className="p-[10px]">G Wt</td>
                            <td className="p-[10px]">Net Wt</td>
                            <td className="p-[10px]">l Wt</td>
                            <td className="p-[10px]">w%</td>
                            <td className="p-[10px]">mc cHAG</td>
                            <td className="p-[10px]">aMOUNT</td>
                            <td className="p-[10px]">vendor</td>
                            <td className="p-[10px]">sales person</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item && index === 0 && (
                                        <tr className="border p-[10px] text-[20px]">
                                            <td className="border p-[10px]" colSpan={item.length}> {
                                                item.item_name
                                            }
                                            </td>
                                        </tr>
                                    )}
                                    {item.bill_details?.length > 0 &&
                                        item.bill_details.map((subItem, subIndex) => (
                                            <tr>
                                                <td className="p-[10px] border">{subItem.tag_number}</td>
                                                <td className="p-[10px] border">{subItem.bill_no}</td>
                                                <td className="p-[10px] border">{convertToDate(subItem.bill_date)}</td>
                                                {/* <td className="p-[10px] border">{subItem.bill_date}</td> */}
                                                <td className="p-[10px] border">{subItem.pieces}</td>
                                                <td className="p-[10px] border">{subItem.gross_weight}</td>
                                                <td className="p-[10px] border">{subItem.net_weight}</td>
                                                <td className="p-[10px] border">{subItem.loop_weight}</td>
                                                <td className="p-[10px] border">{subItem.wastage_percentage}</td>
                                                <td className="p-[10px] border">{subItem.making_charge}</td>
                                                <td className="p-[10px] border">{subItem.total_amount}</td>
                                                <td className="p-[10px] border">{subItem.vendor_name}</td>
                                                <td className="p-[10px] border">{subItem.sales_person}</td>
                                            </tr>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}