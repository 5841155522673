import { useEffect, useState } from 'react';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { branchListEndPoint } from '../../../service/api';
import { getAxios } from '../../../service/apiservice';

export default function OldPurchaseReportFilter({ searchRef, filters, setFilters, }) {

    const [branchList, setBranchList] = useState([]);

    useEffect(()=>{
        getBranchDropdownData();
    },[])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
          url: branchListEndPoint,
        });
    
        if (response !== null) {
            setBranchList(response.data.list);
        }
      };


    const handleBranchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            branch: value === "all" ? null : value
        }))
    }


    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full mt-3">

                {localStorage.getItem("branch") === "true" && 
                    <div className=''>
                        <select
                            placeholder="metal_type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[250px] p-[11px] text-[17px] outline-none"
                            name="metal_type"
                            // value={classification.classification_name} 
                            value={filters.branch} 
                            onChange={(e) => handleBranchFilter(e.target.value)}
                            >
                            <option value="all">All</option>
                            {branchList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                {option?.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>}
            </div>
        </>
    );
}