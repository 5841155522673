import React, { useState } from "react";
import {
  UpipaymentListEndPoint,
  accountheadListEndPoint,
  branchListEndPoint,
  customerListEndPoint,
  itemMasterListEndPoint,
  paymentEndPoint,
  paymentMethodEndPoint,
  paymentViewEndPoint,
  repairManagementcreateEndPoint,
  repairManagementdropdownEndPoint,
  repairMetalListEndPoint,
  repairOrderGetListEndPoint,
  repairOrdernoEndPoint,
  repairTypeDropdownEndPoint,
  tagStatusdropdownByStatusEndPoint,
} from "../../../service/api";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../../service/apiservice";
import { useEffect } from "react";
import { convertToDate } from "../../../utilities/datetime";
import { addDays, differenceInDays } from "date-fns";
import {
  isOptionalValidForm,
  isValidForm,
} from "../../../utilities/formvalidation";
import Popup from "../../../components/Popup";
import PaymentForm from "./paymentform";
import DiamondForm from "./diamondform";
import {
  bank,
  card,
  cash,
  cheque,
  gift_voucher,
  goldMetal,
  repairpayment,
  scheme,
  silverMetal,
  upi,
} from "../../../constant";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Addnewcustomer from "./addcustomerpopup";
import OldForm from "./oldform";
import Cardform from "./card";
import GiftForm from "./giftform";
import SchemeForm from "./schemeform";
import ChequeForm from "./cheque";
import { useKeyPress } from "../../../hooks/useKeyPress";
import PaymentDetails from "./widgets/paymentdetails";
import AddPayments from "./widgets/addpayments";
import OldMetalDetails from "./widgets/oldmetaldetails";

export default function Repaircreationform() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [repairfordropDown, setRepairfordropDown] = useState([]);
  const [customerdropDown, setCustomerdropDown] = useState([]);
  const [repairbropDown, setRepairdropDown] = useState([]);
  const [metalbropDown, setMetaldropDown] = useState([]);
  const [listitemdropDown, setListitemdropDown] = useState([]);
  const [PaymentdatadropDown, setPaymentdatadropDown] = useState([]);
  const [PaymentPopup, setPaymentPopup] = useState(false);
  const [formMode, setFormMode] = useState("create");
  const [OldPopup, setOldPopup] = useState(false);
  const [CardPopup, setCardPopup] = useState(false);
  const [GiftPopup, setGiftPopup] = useState(false);
  const [SchemePopup, setSchemePopup] = useState(false);
  const [ChequePopup, setChequePopup] = useState(false);
  const [newcustomerPopup, setNewcustomerPopup] = useState(false);

  const [OldgoldList, setOldgoldList] = useState([]);
  const [paymentmethod, setpaymentMethod] = useState([]);
  const [CardDetails, setCardDetails] = useState([]);
  const [GiftDetails, setGiftDetails] = useState([]);
  const [schemeDetails, setSchemeDetails] = useState([]);
  const [chequeDetails, setChequeDetails] = useState([]);
  const [oldgoldremoveds, setoldgoldremoveds] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [vendorlist, setVendorlist] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [totalamount, setTotalAMount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [chequeamount, setChequeAmount] = useState(0);

  const [errors, setErrors] = useState({});

  const [issueststusdrop, setIssueStatusList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const [advanceAmount, setAdvanceAmount] = useState(0.0);
  const [paymentScreen, setPaymentScreen] = useState(false);
  const [oldMetalOpen, setOldMetalOpen] = useState(false);
  const [oldMetalList, setOldMetalList] = useState([]);

  const [totalGrsWgt, setTotalGrsWgt] = useState(0);

  const [customerCharges, setCustomerCharges] = useState(0);
  const [vendorCharges, setVendorCharges] = useState(0);

  console.log("customerCharges", customerCharges)
  console.log("vendorCharges", vendorCharges)

  const [paymentMethodDropDown, setPaymentMethodDropDown] = useState([]);


  useEffect(() => {
    // Function to calculate charges
    const calculateCharges = () => {
      let totalCustomerCharges = 0;
      let totalVendorCharges = 0;
      tableData.forEach((item) => {
        totalCustomerCharges += item.customer_charges;
        totalVendorCharges += item.vendor_charges;
      });
      setCustomerCharges(totalCustomerCharges);
      setVendorCharges(totalVendorCharges);
    };

    // Recalculate charges whenever tableData changes
    calculateCharges();
  }, [tableData]);




  useEffect(() => {
    let TotalIssuedgrossweight = 0;
    for (var i of tableData) {
      if (i.issued_gross_weight != "") {
        TotalIssuedgrossweight += parseFloat(i.issued_gross_weight);
      }
    }

    setTotalGrsWgt(TotalIssuedgrossweight);

    for (var i of tableData) {
      var repairitemdetails = {
        repair_type: i.repair_type,
        item_details: i.item_details,
        metal_details: i.metal_details,
        issued_gross_weight: i.issued_gross_weight,
        issued_net_weight: i.issued_net_weight,
        old_stone: i.old_stone,
        old_diamond: i.old_diamond,
        total_pieces: i.total_pieces,
      };
    }
  }, [tableData]);

  const [repairdeatilsformvalue, setrepairdeatilsformvalue] = useState({
    repair_number: "",
    repair_for: "",
    customer_details: "",
    repair_recived_date: "",
    est_repair_delivery_days: "",
    est_repair_delivery_date: "",
    repair_delivery_days: "",
    repair_delivery_date: "",
    total_stone_rate: "",
    total_diamond_rate: "",
    estimate_repair_charge: "",
    sub_total: "",

    repair_type: "",
    item_details: "",
    metal_details: "",
    issued_gross_weight: "",
    issued_net_weight: "",
    old_stone: "",
    old_diamond: "",
    total_pieces: "",
    vendor_charges: "",
    customer_charges: "",

    vendor_name: "",
    issue_date: "",
    estimate_due_date: "",
    remainder_date: "",
    payment_status: "",
    advance_amount: "",
    grand_total: "",
    balance_amount: "",
    description: "",
    estimate_charge: 0.0,
    discount_percentage: 0.0,
    discount_amount: 0.0,
    round_off: 0.0,
    stone_amount: 0,
    diamond_amount: 0,
    estimate_repair_charge: 0.0,
    igst: 0.0,
    sgst: 0.0,
    cgst: 0.0,
    advance_old_gold_amount: 0.0,
    advance_old_silver_amount: 0.0,
  });

  // console.log(repairdeatilsformvalue, "repairdeatilsformvalue")
  // console.log(repairdeatilsformvalue.est_repair_delivery_days, "est_repair_delivery_days")

  const [repairitemsformvalue, setRepairitemsFormvalue] = useState({
    repair_type: "",
    item_details: "",
    metal_details: "",
    issued_gross_weight: "",
    issued_net_weight: "",
    old_stone: "",
    old_diamond: "",
    total_pieces: "",
    vendor_charges: "",
    customer_charges: "",
  });

  useEffect(() => {
    let silverRate = 0.0;
    let goldRate = 0.0;
    for (var i of oldMetalList) {
      if (i.metal == goldMetal) {
        goldRate = goldRate + parseFloat(i.total);
      } else if (i.metal == silverMetal) {
        silverRate = silverRate + parseFloat(i.total);
      }
    }
    setPaymentDetailValues((prevState) => ({
      ...prevState,
      advance_old_gold_amount: goldRate,
      advance_old_silver_amount: silverRate,
    }));
  }, [oldMetalList]);

  const [paymentDetailValues, setPaymentDetailValues] = useState({
    order_id: "",
    gst_type: 1,
    igst: 3,
    sgst: 1.5,
    cgst: 1.5,
  });

  const [gstAmtDetails, setGstAmtDetails] = useState(0);

  useEffect(() => {
    let otherAmt = parseFloat(paymentDetailValues.others || 0);

    let totalAmt = parseFloat(paymentDetailValues.estimate_charge || 0);
    let makingChargePerGramAmt =
      parseFloat(repairitemsformvalue.total_weight || 0) *
      parseFloat(paymentDetailValues.making_charge_pergram || 0);
    let flatMCAmt = parseFloat(paymentDetailValues.flat_making_charge || 0);
    let roundOffAmt = parseFloat(paymentDetailValues.round_off || 0);
    let oldGoldAmt = parseFloat(
      paymentDetailValues.advance_old_gold_amount || 0
    );
    let oldSilverAmt = parseFloat(
      paymentDetailValues.advance_old_silver_amount || 0
    );
    let discountAmt = parseFloat(paymentDetailValues.discount_amount || 0);

    let gstPercent = 0;
    if (String(paymentDetailValues.gst_type) == "1") {
      gstPercent = paymentDetailValues.cgst + paymentDetailValues.cgst;
    } else if (String(paymentDetailValues.gst_type) == "2") {
      gstPercent = paymentDetailValues.igst;
    }

    let gstAmount = totalAmt * (gstPercent / 100);
    setGstAmtDetails({
      igst_amt: String(paymentDetailValues.gst_type) == "2" ? gstAmount : 0,
      sgst_amt: String(paymentDetailValues.gst_type) == "1" ? gstAmount / 2 : 0,
      cgst_amt: String(paymentDetailValues.gst_type) == "1" ? gstAmount / 2 : 0,
    });
    setGrandTotal(
      totalAmt +
      gstAmount +
      otherAmt +
      makingChargePerGramAmt +
      flatMCAmt +
      roundOffAmt -
      (oldGoldAmt + oldSilverAmt + discountAmt)
    );
  }, [paymentDetailValues]);

  useEffect(() => {
    getRepairForDropDownData();
    getcustommerDropDownData();
    getRepairTypeDropDownData();
    getMetalListData();
    getItemListData();
    getRepairIdData();
    GetPaymentData();
    getBranchDropdownData();

    if (localStorage.getItem("repair_creation_form_mode") === "update") {
      getRepairDetails();
      setFormMode("update");
    }
  }, []);

  function closeOldMetalModal() {
    setOldMetalOpen(false);
  }

  const closePaymentModal = () => {
    setPaymentPopup(!PaymentPopup);
  };
  const closeOldModal = () => {
    setOldPopup(!OldPopup);
  };
  const closeCardModal = () => {
    setCardPopup(!CardPopup);
  };
  const closeGiftModal = () => {
    setGiftPopup(!GiftPopup);
  };
  const closeSchemeModal = () => {
    setSchemePopup(!SchemePopup);
  };
  const closeChequeModal = () => {
    setChequePopup(!ChequePopup);
  };

  const closeNewcustomer = () => {
    setNewcustomerPopup(!newcustomerPopup);
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchList(response.data.list);
    }
  };

  const getRepairIdData = async () => {
    var response = await getAxios({
      url: repairOrdernoEndPoint,
    });

    if (response !== null) {
      setrepairdeatilsformvalue((prevState) => ({
        ...prevState,
        repair_number: response.data,
      }));
    }
  };

  const getRepairForDropDownData = async () => {
    var response = await getAxios({
      url: repairManagementdropdownEndPoint,
    });

    if (response !== null) {
      setRepairfordropDown(response.data.list);
    }
  };

  const getcustommerDropDownData = async () => {
    var response = await getAxios({
      url: customerListEndPoint,
    });

    if (response !== null) {
      setCustomerdropDown(response.data.list);
    }
  };
  const getRepairTypeDropDownData = async () => {
    var response = await getAxios({
      url: repairTypeDropdownEndPoint,
    });

    if (response !== null) {
      setRepairdropDown(response.data.list);
    }
  };
  const getMetalListData = async () => {
    var response = await getAxios({
      url: repairMetalListEndPoint,
    });

    if (response !== null) {
      setMetaldropDown(response.data.list);
    }
  };

  const getItemListData = async () => {
    var response = await getAxios({
      url: itemMasterListEndPoint,
    });

    if (response !== null) {
      setListitemdropDown(response.data.list);
    }
  };

  const GetPaymentData = async () => {
    var response = await getAxios({
      url: paymentEndPoint,
    });

    if (response !== null) {
      setPaymentdatadropDown(response.data.list);
    }
  };

  const Onvalueupdate = (e) => {
    const { name, value } = e.target;

    if (name === "est_repair_delivery_days") {
      if (repairdeatilsformvalue.repair_recived_date) {
        setrepairdeatilsformvalue((prevState) => ({
          ...prevState,
          est_repair_delivery_date: convertToDate(
            addDays(new Date(prevState.repair_recived_date), value)
          ),
        }));
      }
    } else if (name === "repair_recived_date") {
      setrepairdeatilsformvalue((prevState) => ({
        ...prevState,
        est_repair_delivery_date: convertToDate(
          addDays(new Date(value), prevState.est_repair_delivery_days)
        ),
      }));
    } else if (name === "est_repair_delivery_date") {
      setrepairdeatilsformvalue((prevState) => ({
        ...prevState,
        est_repair_delivery_days: differenceInDays(
          new Date(value),
          new Date(prevState.repair_recived_date)
        ),
      }));
    } else if (name === "advance_amount") {
      const newBalanceAmount =
        (repairdeatilsformvalue?.total || 0) - parseFloat(value);
      setrepairdeatilsformvalue((prevState) => ({
        ...prevState,
        balance_amount: newBalanceAmount,
      }));
    }

    if (name == "no_of_days") {
      if (repairdeatilsformvalue.issue_date) {
        setrepairdeatilsformvalue((prevState) => ({
          ...prevState,
          remainder_date: convertToDate(
            addDays(new Date(prevState.issue_date), value)
          ),
        }));
      }
    } else if (name == "issue_date") {
      setrepairdeatilsformvalue((prevState) => ({
        ...prevState,
        remainder_date: convertToDate(
          addDays(new Date(value), prevState.no_of_days)
        ),
      }));
    } else if (name == "remainder_date") {
      setrepairdeatilsformvalue((prevState) => ({
        ...prevState,
        no_of_days: differenceInDays(
          new Date(value),
          new Date(prevState.issue_date)
        ),
      }));
    }

    setrepairdeatilsformvalue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length > 0 ? "" : `${name} field is required`,
    }));
  };

  const Ontablevalueupdate = (e) => {
    const { name, value } = e.target;

    setRepairitemsFormvalue((prevState) => ({
      ...prevState,
      [name]: value,
    }));




    if (name === "issued_gross_weight") {
      const grossWeight = parseFloat(value);
      const netWeight = grossWeight; // Example calculation, adjust as needed
      setRepairitemsFormvalue({
        ...repairitemsformvalue,
        issued_gross_weight: value,
        issued_net_weight: netWeight.toFixed(2),
      });
      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? "" : `${name} field is required`,
      }));
    } else if (name == "customer_charges") {
      if (
        parseFloat(value) <
        parseFloat(repairitemsformvalue.min_customer_charges)
      ) {
        console.log("cus", parseFloat(value));
        setErrors((prevState) => ({
          ...prevState,
          [name]: `Customer charges must be at least ${repairitemsformvalue.min_customer_charges}`,
        }));

      } else {
        setErrors((prevState) => ({
          ...prevState,
          [name]: ""
        }));
      }
    } else if (name == "vendor_charges") {
      if (
        parseFloat(value) < parseFloat(repairitemsformvalue.min_vendor_charges)
      ) {
        setErrors((prevState) => ({
          ...prevState,
          [name]: `Vendor charges must be at least ${repairitemsformvalue.min_vendor_charges}`,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [name]: ""
        }));
      }
    } else if (name == "repair_type") {
      let hasErrors = false;
      let data = repairitemsformvalue;

      for (var i of repairbropDown) {
        if (String(i.id) == String(e.target.value)) {
          data["vendor_charges"] = i.max_vendor_charges;
          data["customer_charges"] = i.max_customer_charges;
          data["max_vendor_charges"] = i.max_vendor_charges;
          data["max_vendor_charges"] = i.max_vendor_charges;
          data["min_vendor_charges"] = i.min_vendor_charges;
          data["min_customer_charges"] = i.min_customer_charges;
          data[name] = value;

          break; // Stop loop once found
        }
      }

      setRepairitemsFormvalue(data);

      if (!hasErrors) {
        setErrors((prevState) => ({
          ...prevState,
          vendor_charges: "",
          customer_charges: "",
        }));
      }
    } else {
      setRepairitemsFormvalue({ ...repairitemsformvalue, [name]: value });
      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? "" : `${name} field is required`,
      }));
    }


  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formFields = new FormData(e.target);
    let formDataObject = Object.fromEntries(formFields.entries());

    var err = {};

    for (var field of Object.entries(formDataObject)) {
      if (field[1].length === 0) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }

    setErrors(err);
    if (isValidForm(formDataObject)) {
      let data = repairitemsformvalue;

      for (var i of repairbropDown) {
        if (i.id == repairitemsformvalue.repair_type) {
          data["repair_type_name"] = i.repair_type_name;
          break; // Stop loop once found
        }
      }

      for (var i of metalbropDown) {
        if (i.id == repairitemsformvalue.metal_details) {
          data["metal_name"] = i.metal_name;
          break; // Stop loop once found
        }
      }
      for (var i of listitemdropDown) {
        if (i.id == repairitemsformvalue.item_details) {
          data["item_name"] = i.item_name;
          break; // Stop loop once found
        }
      }

      setTableData([...tableData, data]);

      let clearData = {

        repair_type: "",
        metal_details: "",
        item_details: "",
        issued_gross_weight: "",
        issued_net_weight: "",
        old_stone: "",
        old_diamond: "",
        total_pieces: "",
        customer_charges: "",
        vendor_charges: "",



      }

      setRepairitemsFormvalue(clearData);



    }
  };

  const handleRepairSubmit = async (e) => {
    e.preventDefault();

    let repairForm = document.getElementById("reapirdetails");
    let jewelrepairForm = document.getElementById("jewelrepairdetails");
    let vendordetailsForm = document.getElementById("vendordetails");
    let descriptionform = document.getElementById("descriptiondetails");

    var repairFormData = new FormData(repairForm);
    var jewelrepairFormData = new FormData(jewelrepairForm);
    var vendordetailsFormData = new FormData(vendordetailsForm);
    var descriptionFormData = new FormData(descriptionform);

    var err = {};

    for (var field of Object.entries(
      Object.fromEntries(repairFormData.entries())
    )) {
      if (field[1].length === 0) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }


    for (var field of Object.entries(
      Object.fromEntries(jewelrepairFormData.entries())
    )) {
      if (field[1].length === 0) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }
    for (var field of Object.entries(
      Object.fromEntries(vendordetailsFormData.entries())
    )) {
      if (field[1].length === 0) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }
    for (var field of Object.entries(
      Object.fromEntries(descriptionFormData.entries())
    )) {
      if (field[1].length === 0) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }


    setErrors(err);


    if (isValidForm(Object.fromEntries(repairFormData.entries()))) {
      let data = {
        ...Object.fromEntries(repairFormData.entries()),
        ...Object.fromEntries(jewelrepairFormData.entries()),
        ...Object.fromEntries(descriptionFormData.entries()),

        ...gstAmtDetails,
        repair_item_details: tableData,
        total_vendor_chargers: vendorCharges,
        total_customer_chargers: customerCharges,
        igst: paymentDetailValues.igst,
        estimate_charge: paymentDetailValues.estimate_charge,
        discount_percentage: paymentDetailValues.discount_percentage,
        discount_amount: paymentDetailValues.discount_amount,
        round_off: paymentDetailValues.round_off,
        advance_old_gold_amount: paymentDetailValues.advance_old_gold_amount,
        advance_old_silver_amount: paymentDetailValues.advance_old_silver_amount,
        grand_total: grandTotal,
        advance_amount: advanceAmount,
        balance_amount: balanceAmount,
      };

      if (isValidForm(Object.fromEntries(vendordetailsFormData.entries()))) {
        data["vendor_details"] = [
          {
            vendor_name: repairdeatilsformvalue.vendor_name,
            issue_date: repairdeatilsformvalue.issue_date,
            estimate_due_date: repairdeatilsformvalue.estimate_due_date,
            remainder_date: repairdeatilsformvalue.remainder_date,
            payment_status: repairdeatilsformvalue.payment_status,
          },
        ];
      }

      if (localStorage.getItem("repair_creation_form_mode") === "create") {
        var response = await postAxiosWithToken({
          url: repairOrderGetListEndPoint,
          body: data,
        });

        if (response !== null) {
          toast.success(response.message);
          setPaymentScreen(true);
        }
      } else if (
        localStorage.getItem("repair_creation_form_mode") === "update"
      ) {
        updaterepaircreation(data);
      }
    }
  };

  const handleRepairPaymentSubmit = async (e) => {
    e.preventDefault();

    let repairForm = document.getElementById("reapirdetails");

    var repairFormData = new FormData(repairForm);

    var err = {};

    for (var field of Object.entries(
      Object.fromEntries(repairFormData.entries())
    )) {
      if (field[1].length === 0) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }




    setErrors(err);


    if (isValidForm(Object.fromEntries(repairFormData.entries()))) {


      let paymentlist = []

      for (var i of paymentMethodDropDown) {


        var temdata = {
          "customer_details": repairdeatilsformvalue.customer_details,
          "payment_method": i.id,
          "payment_provider": i.id === cash ? null : document.getElementById(i.payment_method_name + "provider")?.value,
          "payment_module": repairpayment,
          "paid_amount": document.getElementById(i.payment_method_name)?.value || null,
          "payment_refference_number": document.getElementById(i.payment_method_name + "ref_number")?.value || null,
          "refference_number": repairdeatilsformvalue.repair_number
        }
        paymentlist.push(temdata)

      }

      let data = {
        // ...Object.fromEntries(repairFormData.entries()),

        ...gstAmtDetails,

        // repair_item_details: tableData,
        // total_vendor_chargers: vendorCharges,
        // total_customer_chargers: customerCharges,
        refference_number: repairdeatilsformvalue.repair_number,
        igst: paymentDetailValues.igst,
        sgst: paymentDetailValues.sgst,
        cgst: paymentDetailValues.cgst,
        gst_type: paymentDetailValues.gst_type,
        round_off: paymentDetailValues.round_off,
        estimate_charge: paymentDetailValues.estimate_charge,
        discount_percentage: paymentDetailValues.discount_percentage,
        discount_amount: paymentDetailValues.discount_amount,
        round_off: paymentDetailValues.round_off,
        advance_old_gold_amount: paymentDetailValues.advance_old_gold_amount,
        advance_old_silver_amount: paymentDetailValues.advance_old_silver_amount,
        grand_total: grandTotal,
        advance_amount: advanceAmount,
        balance_amount: balanceAmount,

        oldgold_particulars: oldMetalList,
        payment_particulars: paymentlist
      };



      if (localStorage.getItem("repair_creation_form_mode") === "create") {
        var response = await postAxiosWithToken({
          url: paymentViewEndPoint,
          body: data,
        });

        if (response !== null) {
          toast.success(response.message);
        }
      }
    }
  };

  const getRepairDetails = async () => {
    var response = await getAxios({
      url: repairManagementcreateEndPoint + id + "/",
    });

    if (response !== null) {
      var tempDetails = {
        repair_number: response.data?.repair_order_Details?.repair_number,
        branch: response.data?.repair_order_Details?.branch,
        description: response.data?.repair_order_Details?.description,
        estimate_charge: response.data?.repair_order_Details?.estimate_charge,
        discount_percentage:
          response.data?.repair_order_Details?.discount_percentage,
        discount_amount: response.data?.repair_order_Details?.discount_amount,
        round_off: response.data?.repair_order_Details?.round_off,
        igst: response.data?.repair_order_Details?.igst,
        cgst: response.data?.repair_order_Details?.cgst,
        sgst: response.data?.repair_order_Details?.sgst,
        advance_old_gold_amount:
          response.data?.repair_order_Details?.advance_old_gold_amount,
        advance_old_silver_amount:
          response.data?.repair_order_Details?.advance_old_silver_amount,
        advance_old_silver_amount:
          response.data?.repair_order_Details?.advance_old_silver_amount,
        advance_amount: response.data?.repair_order_Details?.advance_amount,

        repair_for: response.data?.repair_order_Details?.repair_for,
        customer_details: response.data?.repair_order_Details?.customer_details,
        repair_recived_date:
          response.data?.repair_order_Details?.repair_recived_date,
        est_repair_delivery_days:
          response.data?.repair_order_Details?.est_repair_delivery_days,
        est_repair_delivery_date:
          response.data?.repair_order_Details?.est_repair_delivery_date,
        status: response.data?.repair_order_Details?.status,

        repair_type:
          response.data?.repair_order_Details?.repair_item_details?.repair_type,
        metal_details:
          response.data?.repair_order_Details?.repair_item_details
            ?.metal_details,
        item_details:
          response.data?.repair_order_Details?.repair_item_details
            ?.item_details,
        issued_gross_weight:
          response.data?.repair_order_Details?.repair_item_details
            ?.issued_gross_weight,
        issued_net_weight:
          response.data?.repair_order_Details?.repair_item_details
            ?.issued_net_weight,
        old_stone:
          response.data?.repair_order_Details?.repair_item_details?.old_stone,
        old_diamond:
          response.data?.repair_order_Details?.repair_item_details?.old_diamond,
        total_pieces:
          response.data?.repair_order_Details?.repair_item_details
            ?.total_pieces,
        vendor_name:
          response.data?.repair_order_Details?.vendor_details?.vendor_name,
        issue_date:
          response.data?.repair_order_Details?.vendor_details?.issue_date,
        estimate_due_date:
          response.data?.repair_order_Details?.vendor_details
            ?.estimate_due_date,
        remainder_date:
          response.data?.repair_order_Details?.vendor_details?.remainder_date,
        payment_status:
          response.data?.repair_order_Details?.vendor_details?.payment_status,

        total_stone_rate: response.data?.repair_order_Details?.total_stone_rate,
        total_diamond_rate:
          response.data?.repair_order_Details?.total_diamond_rate,
        estimate_repair_charge:
          response.data?.repair_order_Details?.estimate_repair_charge,

        cash: response.data?.repair_order_Details?.cash,
        upi: response.data?.repair_order_Details?.upi,
        debit_card_amount:
          response.data?.repair_order_Details?.debit_card_amount,
        credit_card_amount:
          response.data?.repair_order_Details?.credit_card_amount,
        account_transfer: response.data?.repair_order_Details?.account_transfer,
      };

      setrepairdeatilsformvalue(tempDetails);
      setPaymentDetailValues(tempDetails);

      setTableData(response.data.repair_order_Details.repair_item_details);
    }
  };

  const updaterepaircreation = async (body) => {
    var response = await putAxiosWithToken({
      url: repairManagementcreateEndPoint + id + "/",
      body: body,
    });

    if (response != null) {
      localStorage.removeItem("reapir_creation_form_id");
      localStorage.setItem("reapir_creation_form_mode", "create");
      toast.success(response.message);
      setPaymentScreen(true);
    }
  };

  useEffect(() => {
    if (!OldPopup) {
      setRepairitemsFormvalue((prevState) => ({
        ...prevState,
        oldgold_particulars: OldgoldList,
      }));
    }
  }, [OldPopup]);

  //   if (!CardPopup) {
  //     setRepairitemsFormvalue((prevState) => ({
  //       ...prevState,
  //       card_details: OldgoldList,
  //     }));
  //   }
  // }, [CardPopup]);

  // useEffect(() => {
  //   if (!GiftPopup) {
  //     setRepairitemsFormvalue((prevState) => ({
  //       ...prevState,
  //       gift_voucher_details: GiftDetails,
  //     }));
  //   }
  // }, [GiftPopup]);

  // useEffect(() => {
  //   if (!SchemePopup) {
  //     setRepairitemsFormvalue((prevState) => ({
  //       ...prevState,
  //       scheme_details: schemeDetails,
  //     }));
  //   }
  // }, [SchemePopup]);

  // useEffect(() => {
  //   if (!ChequePopup) {
  //     setRepairitemsFormvalue((prevState) => ({
  //       ...prevState,
  //       cheque_details: chequeDetails,
  //     }));
  //   }
  // }, [ChequePopup]);

  useEffect(() => {
    getIssueStatusDropDownData();
    Venderlist();
  }, []);

  const getIssueStatusDropDownData = async () => {
    var response = await getAxios({
      url: tagStatusdropdownByStatusEndPoint + 1 + "/",
    });

    if (response !== null) {
      setIssueStatusList(response.data.list);
    }
  };

  const Venderlist = async () => {
    var response = await getAxios({
      url: accountheadListEndPoint,
    });

    if (response !== null) {
      setVendorlist(response.data.list);
    }
  };

  const onKeyPress = (event) => {
    if (event && event.key === "F1") {
      event.preventDefault();
      document.getElementById("payment")?.click();
    } else if (event && event.key === "F7") {
      event.preventDefault();
      document.getElementById("card")?.click();
    } else if (event && event.key === "F8") {
      event.preventDefault();
      document.getElementById("gift")?.click();
    } else if (event && event.key === "F9") {
      event.preventDefault();
      document.getElementById("scheme")?.click();
    } else if (event && event.key === "F10") {
      event.preventDefault();
      document.getElementById("cheque")?.click();
    }
  };

  useKeyPress(["F1", "F7", "F8", "F9", "F10"], onKeyPress);

  const [shouldRecalculate, setShouldRecalculate] = useState(false);

  useEffect(() => {
    const calculateTotal = () => {
      const {
        estimate_charge,
        discount_amount,
        round_off,
        stone_amount,
        diamond_amount,
        igst,
        sgst,
        cgst,
        advance_amount,
        discount_percentage, // Assuming this is the negative discount percentage
      } = repairdeatilsformvalue;

      const estimateCharge = parseFloat(estimate_charge) || 0;
      const discountAmount = parseFloat(discount_amount) || 0;
      const roundOff = parseFloat(round_off) || 0;
      const stoneAmount = parseFloat(stone_amount) || 0;
      const diamondAmount = parseFloat(diamond_amount) || 0;
      const igstAmount = parseFloat(igst) || 0;
      const sgstAmount = parseFloat(sgst) || 0;
      const cgstAmount = parseFloat(cgst) || 0;
      const advanceAmount = parseFloat(advance_amount) || 0;

      // Adjust discount based on negative percentage
      const adjustedDiscount =
        discountAmount +
        estimateCharge * (parseFloat(discount_percentage) / 100);

      const totalWithoutGST =
        estimateCharge -
        adjustedDiscount +
        roundOff +
        stoneAmount +
        diamondAmount;
      const totalGST =
        ((igstAmount + sgstAmount + cgstAmount) / 100) * totalWithoutGST;
      const total = totalWithoutGST + totalGST;
      const balanceAmount = total - advanceAmount;

      setrepairdeatilsformvalue((prev) => ({
        ...prev,
        total: total.toFixed(2),
        balance_amount: balanceAmount.toFixed(2),
      }));
    };

    if (shouldRecalculate) {
      calculateTotal();
      setShouldRecalculate(false); // Reset the flag after calculation
    }
  }, [shouldRecalculate, repairdeatilsformvalue]);

  useEffect(() => {
    paymentMethod();
  }, []);

  const paymentMethod = async (body) => {
    var response = await getAxios({
      url: paymentMethodEndPoint,
      body: body,
    });

    if (response !== null) {
      setpaymentMethod(response.data.list);
    }
  };

  useEffect(() => {
    setBalanceAmount(grandTotal - (advanceAmount || 0));
  }, [advanceAmount, grandTotal]);

  return (
    <div>
      <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">
        Jewel Repair Creation
      </p>

      <div className="">
        <div className="grid xl:grid-cols-1 sm:grid-cols-1  gap-4">
          <form id="reapirdetails">
            <div className="border p-[15px] rounded-xl shadow-lg">
              <p className="font-[600] text-[14px] pb-2">
                Repair For Details{" "}
              </p>

              <div className="grid max-[639px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-6 gap-3">
                <div className="my-[3px] w-full">
                  <p className="mb-[2px] text-[13px]">
                    Repair For <span className="text-red-500">*</span>
                  </p>
                  <select
                    type="text"
                    placeholder="Repair For "
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="repair_for"
                    value={repairdeatilsformvalue.repair_for}
                    onChange={Onvalueupdate}
                  >
                    <option value={""}>--select--</option>
                    {repairfordropDown?.map((option) => (
                      <option
                        className="capitalize"
                        key={"RepairFor" + option.repair_for}
                        value={option.id}
                      >
                        {option.repair_for}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.repair_for}
                  </p>
                </div>
                {localStorage.getItem("branch") === "true" && (
                  <div className="w-full sm:my-[2px]">
                    <p className="mb-[2px] text-[13px]">Branch</p>
                    <div className="">
                      <select
                        placeholder="branch"
                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none bg-white"
                        name="branch"
                        value={repairdeatilsformvalue.branch}
                        // onChange={(e) => handleBranchFilter(e.target.value)}
                        onChange={Onvalueupdate}
                      >
                        <option value="">-- Select Branch Type --</option>
                        {branchList.map((option) => (
                          <option key={option?.id} value={option.id}>
                            {option.branch_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {repairdeatilsformvalue.repair_for == 1 && (
                  <div className="sm:my-[2px] ">
                    <div className="flex justify-between">
                      <p className="mb-[2px] text-[13px]">
                        Mobile Number <span className="text-red-500">*</span>
                      </p>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setNewcustomerPopup(!newcustomerPopup);
                        }}
                        className="border-[3px] border-[#000000] text-[20px] rounded-full w-[20px] h-[20px] flex justify-center items-center mt-1 outline-none"
                      >
                        +
                      </button>
                      {/* <p className="border-[3px] border-[#000000] text-[20px] rounded-full w-[20px] h-[20px] flex justify-center items-center mt-1">+</p> */}
                    </div>
                    <select
                      placeholder="location"
                      className="sm:mb-[2px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="customer_details"
                      value={repairdeatilsformvalue.customer_details}
                      onChange={Onvalueupdate}
                    >
                      <option value={""}>--select--</option>
                      {customerdropDown.map((option) => (
                        <option
                          className="capitalize"
                          key={"phone" + option.phone}
                          value={option.id}
                        >
                          {option.phone}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.customer_details}
                    </p>
                  </div>
                )}

                {repairdeatilsformvalue.repair_for == 2 && (
                  <div className="sm:my-[2px] ">
                    <div className="flex justify-between mt-5">
                      <p className="mb-[2px] text-[13px]">
                        Mobile Number <span className="text-red-500">*</span>
                      </p>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setNewcustomerPopup(!newcustomerPopup);
                        }}
                        className="border-[3px] border-[#000000] text-[20px] rounded-full w-[20px] h-[20px] flex justify-center items-center mt-1 outline-none"
                      >
                        +
                      </button>
                      {/* <p className="border-[3px] border-[#000000] text-[20px] rounded-full w-[20px] h-[20px] flex justify-center items-center mt-1">+</p> */}
                    </div>
                    <select
                      placeholder="location"
                      className="sm:mb-[2px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="customer_details"
                      value={repairdeatilsformvalue.customer_details}
                      onChange={Onvalueupdate}
                    >
                      <option value={""}>--select--</option>
                      {customerdropDown.map((option) => (
                        <option
                          className="capitalize"
                          key={"phone" + option.phone}
                          value={option.id}
                        >
                          {option.customer_name}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.customer_details}
                    </p>
                  </div>
                )}

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Received Date <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="date"
                    placeholder="Received Date "
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[2px] text-[13px] outline-none"
                    name="repair_recived_date"
                    value={repairdeatilsformvalue.repair_recived_date}
                    onChange={Onvalueupdate}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.repair_recived_date}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px] ">
                    Est Due Date <span className="text-red-500">*</span>
                  </p>
                  <div className="flex gap-2">
                    <input
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-[60px] p-[2px] text-[13px] outline-none"
                      type="text"
                      name="est_repair_delivery_days"
                      value={repairdeatilsformvalue.est_repair_delivery_days}
                      onChange={Onvalueupdate}
                    />
                    <input
                      type="date"
                      placeholder="Received Date "
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[2px] text-[13px] outline-none"
                      name="est_repair_delivery_date"
                      value={repairdeatilsformvalue.est_repair_delivery_date}
                      onChange={Onvalueupdate}
                    />
                  </div>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.est_repair_delivery_date}
                  </p>
                </div>
                <div className="sm:my-[2px] ">
                  <p className="mb-[2px] text-[13px]">
                    {" "}
                    Status <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="payment_status"
                    className="sm:mb-[2px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="status"
                    value={repairdeatilsformvalue.status}
                  // onChange={onValueUpdate}
                  >
                    <option value={""}>--select--</option>
                    {issueststusdrop.map((option) => (
                      <option
                        className="capitalize"
                        key={"location" + option.status_name}
                        value={option.id}
                      >
                        {option.status_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.status}
                  </p>
                </div>
              </div>
            </div>
          </form>

          <form onSubmit={handleSubmit} id="jewelrepairdetails">
            <div className="border p-[15px] rounded-xl shadow-lg">
              <div className="flex justify-between sm:mt-0  ">
                <p className="font-[600] text-[14px] pb-2">
                  Jewel Repair For Details{" "}
                </p>
              </div>

              <div className="grid max-[639px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-10 gap-3">
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Repair Order No <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    name="repair_number"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    value={repairdeatilsformvalue.repair_number}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.repair_number}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Repair Type <span className="text-red-500">*</span>{" "}
                  </p>
                  <select
                    type="text"
                    placeholder="Repair Type"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="repair_type"
                    value={repairitemsformvalue.repair_type}
                    onChange={Ontablevalueupdate}
                  >
                    <option value={""}>--select--</option>
                    {repairbropDown?.map((option) => (
                      <option
                        className="capitalize"
                        key={"RepairType" + option.repair_type_name}
                        value={option.id}
                      >
                        {option.repair_type_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.repair_type}
                  </p>
                </div>

                {/* </div> */}

                {/* <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-3"> */}
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Metal type <span className="text-red-500">*</span>
                  </p>
                  <select
                    type="text"
                    placeholder="Metal type"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="metal_details"
                    value={repairitemsformvalue.metal_details}
                    onChange={Ontablevalueupdate}
                  >
                    <option value={""}>--select--</option>
                    {metalbropDown?.map((option) => (
                      <option
                        className="capitalize"
                        key={"Metaltype" + option.metal_name}
                        value={option.id}
                      >
                        {option.metal_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.metal_details}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Item Type <span className="text-red-500">*</span>
                  </p>
                  <select
                    type="text"
                    placeholder="Item List Type "
                    name="item_details"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.item_details}
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                  >
                    <option value={""}>--select--</option>
                    {listitemdropDown?.map((option) => (
                      <option
                        className="capitalize"
                        key={"ItemListType" + option.item_name}
                        value={option.id}
                      >
                        {option.item_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.item_details}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Issued G Wt <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    name="issued_gross_weight"
                    placeholder="Issued G Wt"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.issued_gross_weight}
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.issued_gross_weight}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Issued Nt Wt <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Issued Nt Wt "
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="issued_net_weight"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.issued_net_weight}
                    readOnly
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.issued_net_weight}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Old Stone</p>
                  <input
                    type="text"
                    placeholder="Old Stone"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="old_stone"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.old_stone}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.old_stone}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Old Diamond</p>
                  <input
                    type="text"
                    placeholder="Old Diamond"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="old_diamond"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.old_diamond}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.old_diamond}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Pieces</p>
                  <input
                    type="text"
                    placeholder="Pieces"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="total_pieces"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.total_pieces}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.total_pieces}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Customer Chrg</p>
                  <input
                    type="text"
                    placeholder="Customer Chargers"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="customer_charges"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.customer_charges}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.customer_charges}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Vendor Chrg</p>
                  <input
                    type="text"
                    placeholder="Vendor Chargers "
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="vendor_charges"
                    onChange={Ontablevalueupdate}
                    value={repairitemsformvalue.vendor_charges}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.vendor_charges}
                  </p>
                </div>

                <div className="text-center mt-3">
                  <button
                    type="submit"
                    className=" mx-auto border outline-none bg-secondary w-full mt-3 text-black  rounded mb-[3px] px-[3px] py-[1px]"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>

          <form onSubmit={handleSubmit} id="vendordetails">
            <div className="border p-[15px] rounded-xl shadow-lg">
              <p className="font-[600] text-[14px] pb-2">Vendor Details</p>

              <div className="grid max-[639px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-6 gap-3">

                <div className="sm:my-[2px] ">
                  <div className="flex justify-between">
                    <p className="mb-[2px] text-[13px]">
                      Vendor Name<span className="text-red-500">*</span>
                    </p>
                  </div>
                  <select
                    placeholder=""
                    className="sm:mb-[2px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="vendor_name"
                    value={repairdeatilsformvalue.vendor_name}
                    onChange={Onvalueupdate}
                  >
                    <option value={""}>--select--</option>
                    {vendorlist.map((option) => (
                      <option
                        className="capitalize"
                        key={"location" + option.account_head_name}
                        value={option.id}
                      >
                        {option.account_head_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.vendor_name?.message}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Issue to Vendor</p>
                  <input
                    type="date"
                    placeholder="issue_date"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="issue_date"
                    value={repairdeatilsformvalue.issue_date}
                    onChange={Onvalueupdate}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.issue_date?.message}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Est Due Date</p>
                  <input
                    type="date"
                    placeholder="estimate_due_date"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="estimate_due_date"
                    value={repairdeatilsformvalue.estimate_due_date}
                    onChange={Onvalueupdate}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.estimate_due_date?.message}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Remainder Days</p>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-[40px] p-[2px] text-[13px] outline-none"
                      name="no_of_days"
                      value={repairdeatilsformvalue.no_of_days}
                      onChange={Onvalueupdate}
                    />
                    <input
                      type="date"
                      placeholder="remainder_date"
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[2px] text-[13px] outline-none"
                      name="remainder_date"
                      value={repairdeatilsformvalue.remainder_date}
                      onChange={Onvalueupdate}
                    />
                  </div>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.remainder_date?.message}
                  </p>
                </div>

                <div className="sm:my-[2px] ">
                  <p className="mb-[2px] text-[13px]">
                    Payment Status<span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="payment_status"
                    className="sm:mb-[2px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="payment_status"
                    value={repairdeatilsformvalue.payment_status}
                    onChange={Onvalueupdate}
                  >
                    <option value={""}>--select--</option>
                    {PaymentdatadropDown.map((option) => (
                      <option
                        className="capitalize"
                        key={"location" + option.status_name}
                        value={option.id}
                      >
                        {option.status_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.payment_status?.message}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>

        <section className="mb-[200px]">
          <div className="w-full mt-[15px] mb-[50px] overflow-x-scroll border p-[15px] rounded-xl ">
            <table className="w-full overflow-y-scroll  h-[180px]">
              <thead className="bg-table-head text-table-head-font sticky top-0">
                <tr>
                  <td className="p-[5px] border" rowSpan={2}>
                    S.No
                  </td>
                  <td
                    className="p-[5px] border-[#E0E0E0] border min-w-[13px]"
                    rowSpan={2}
                  >
                    Item Name
                  </td>
                  <td
                    className="p-[5px] border-[#E0E0E0] border min-w-[150px]"
                    rowSpan={2}
                  >
                    Metal Type
                  </td>
                  <td
                    className="p-[5px] border-[#E0E0E0] border min-w-[150px]"
                    rowSpan={2}
                  >
                    Repair Type
                  </td>
                  <td
                    className="p-[5px] border-[#E0E0E0] border min-w-[150px]"
                    rowSpan={2}
                  >
                    Issued Gross Wt
                  </td>
                  <td
                    className="p-[5px] border-[#E0E0E0] border min-w-[150px]"
                    rowSpan={2}
                  >
                    Issued Net Wt
                  </td>
                  <td
                    className="p-[5px] border-[#E0E0E0] border text-center min-w-[150px]"
                    colSpan={2}
                  >
                    Old Stone
                  </td>

                  <td
                    className="p-[5px] text-center min-w-[150px] border"
                    rowSpan={2}
                  >
                    Customer Charges
                  </td>

                  <td
                    className="p-[5px] text-center min-w-[150px] border"
                    rowSpan={2}
                  >
                    Vendor Charges
                  </td>

                  <td
                    className="p-[5px] text-center min-w-[150px] border"
                    rowSpan={2}
                  >
                    Actions
                  </td>
                </tr>
                <tr>
                  <td className="p-[5px] border text-center">Stone</td>
                  <td className="p-[5px] border text-center">Diamond</td>
                </tr>
              </thead>
              <tbody className="text-primary-grey">
                {tableData.map((items, index) => (
                  <tr>
                    <td className="p-[5px] border text-center">{index + 1}</td>
                    <td className="p-[5px] border">{items?.item_name}</td>
                    <td className="p-[5px] border">{items?.metal_name}</td>
                    <td className="p-[5px] border">
                      {items?.repair_type_name}
                    </td>
                    <td className="p-[5px] border">
                      {items?.issued_gross_weight}
                    </td>
                    <td className="p-[5px] border">
                      {items?.issued_net_weight}
                    </td>
                    <td className="p-[5px] border text-center">
                      {items?.old_stone}
                    </td>
                    <td className="p-[5px] border text-center">
                      {items?.old_diamond}
                    </td>
                    <td className="p-[5px] border">
                      {items?.customer_charges}
                    </td>
                    <td className="p-[5px] border">
                      {items?.vendor_charges}
                    </td>

                    <td className="p-[5px] border text-center">
                      <div className="cursor-pointer hover:scale-125">
                        <svg
                          className="!text-[#94A3B8]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <form onSubmit={handleSubmit} id="descriptiondetails" action="">
            <div className="flex gap-5 justify-between">
              <div className=" w-[50%] ">
                <p className="mb-[5px] text-[12px]">Description</p>
                <input
                  type="text"
                  placeholder="Description"
                  className="sm:mb-[5px] bg-white border border-gray-300 w-full rounded-lg p-[5px] text-[14px] outline-none"
                  name="description"
                  id="description"
                  defaultValue={repairdeatilsformvalue.description}
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.description}
                </p>
              </div>
              <div className=" flex mt-5 gap-2 ">
                <p className="mt-2">Total Issued Weight: </p>
                <input
                  placeholder=""
                  name="total_issued_weight"
                  id="total_issued_weight"
                  className="mb-[5px] text-[14px] bg-[#E2E8F0] text-center p-2 rounded w-[100px]"
                  value={totalGrsWgt}
                  onChange={Onvalueupdate}
                />
              </div>
            </div>
          </form>
        </section>
      </div >

      <div
        className={`${paymentScreen ? "translate-y-0" : "translate-y-[83%]"
          } transition ease-in-out fixed bottom-[30px] z-[5] left-0 w-full p-5 bg-white border-t`}
      >
        <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
          <button
            id="backnav"
            onClick={(e) => {
              e.preventDefault();
              setPaymentScreen(false)
              // navigate(-1);
            }}
            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
          >
            close<span className="lowercase">(esc)</span>
          </button>
          {paymentScreen ? (
            <button
              onClick={handleRepairSubmit}
              className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              save<span className="lowercase">(ctrl+s)</span>
            </button>
          ) : (
            <button
              onClick={handleRepairPaymentSubmit}
              type="submit"
              id="formsubmit"
              className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              save<span className="lowercase">(ctrl+s)</span>
            </button>
          )}
        </div>
        <div className="flex gap-3 mt-4">
          <div className="w-[35%]">
            <button
              onClick={(e) => {
                e.preventDefault();
                setOldMetalOpen(true);
              }}
              className="mb-2 capitalize bg-primary-btn bg-opacity-50 hover:bg-opacity-100 text-black hover:text-white w-fit rounded p-[5px] px-[10px] h-fit text-[12px] outline-none border border-primary-btn"
            >
              Old metal
            </button>
            <PaymentDetails
              formValues={paymentDetailValues}
              setFormValues={setPaymentDetailValues}
              grandTotal={grandTotal}
              setGrandTotal={setGrandTotal}

            />
          </div>

          <div className="w-[15%] ">
            <div className="bg-[#E2E8F0] rounded-lg p-[15px] h-full">
              <div>
                <p>Grand Total</p>
                <p>₹ {grandTotal}</p>
              </div>
              <div className="mt-3">
                <p>Advance</p>
                <input
                  type="number"
                  placeholder="₹ 0.00"
                  name="advance_amount"
                  className="sm:mb-[5px] border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                  defaultValue={repairdeatilsformvalue.advance_amount}
                  onChange={(e) => setAdvanceAmount(parseFloat(e.target.value))}
                />
              </div>
              <div className="mt-3">
                <p>Balance Amount</p>
                <p>₹ {balanceAmount}</p>
              </div>
            </div>
          </div>

          <div className="w-[50%]">
            <AddPayments setPaymentMethodDropDown={setPaymentMethodDropDown}
              paymentMethodDropDown={paymentMethodDropDown} />
          </div>
        </div>
      </div>

      <div className="w-full">
        <Popup
          extraClassName="min-w-[800px] max-h-[500px]"
          title={"Old Metal"}
          setIsOpen={setOldMetalOpen}
          isOpen={oldMetalOpen}
          closeModal={closeOldMetalModal}
        >
          <OldMetalDetails
            particularsList={oldMetalList}
            setParticularsList={setOldMetalList}
            formType={formMode}
            closeModal={closeOldMetalModal}
          />
        </Popup>

        <Popup
          title={`Old Gold`}
          extraClassName="min-w-[1000px]"
          setIsOpen={setOldPopup}
          isOpen={OldPopup}
          closeModal={closeOldModal}
        >
          <OldForm
            closeModal={closeOldModal}
            dataList={OldgoldList}
            setDataList={setOldgoldList}
            formType={formMode}
            oldgoldremoved={oldgoldremoveds}
            setoldgoldremoved={setoldgoldremoveds}
            totalamount={totalamount}
            setTotalAMount={setTotalAMount}
          />
        </Popup>
        <Popup
          title={`Vendor`}
          extraClassName="min-w-md"
          setIsOpen={setPaymentPopup}
          isOpen={PaymentPopup}
          closeModal={closePaymentModal}
        >
          <PaymentForm
            closeModal={closePaymentModal}
            dataList={OldgoldList}
            setDataList={setOldgoldList}
            formType={formMode}
          />
        </Popup>

        <Popup
          title={`Card`}
          extraClassName="min-w-[1000px]"
          setIsOpen={setCardPopup}
          isOpen={CardPopup}
          closeModal={closeCardModal}
        >
          <Cardform
            closeModal={closeCardModal}
            dataList={CardDetails}
            setDataList={setCardDetails}
            formType={formMode}
          // removedStone={removedStones}
          // setRemovedStone={setRemovedStones}
          />
        </Popup>

        <Popup
          title={`Gift Voucher`}
          extraClassName="min-w-[1000px]"
          setIsOpen={setGiftPopup}
          isOpen={GiftPopup}
          closeModal={closeGiftModal}
        >
          <GiftForm
            closeModal={closeGiftModal}
            dataList={GiftDetails}
            setDataList={setGiftDetails}
            formType={formMode}
            // removedGift={removedGifts}
            // setRemovedGift={setRemovedGifts}
            amount={amount}
            setAmount={setAmount}
          />
        </Popup>
        <Popup
          title={`Scheme `}
          extraClassName="min-w-[1000px]"
          setIsOpen={setSchemePopup}
          isOpen={SchemePopup}
          closeModal={closeSchemeModal}
        >
          <SchemeForm
            closeModal={closeSchemeModal}
            dataList={schemeDetails}
            setDataList={setSchemeDetails}
            formType={formMode}
          // removedStone={removedStones}
          // setRemovedStone={setRemovedStones}
          />
        </Popup>
        <Popup
          title={`Cheque `}
          extraClassName="min-w-[1000px]"
          setIsOpen={setChequePopup}
          isOpen={ChequePopup}
          closeModal={closeChequeModal}
        >
          <ChequeForm
            closeModal={closeChequeModal}
            dataList={chequeDetails}
            setDataList={setChequeDetails}
            formType={formMode}
            chequeamount={chequeamount}
            setChequeAmount={setChequeAmount}
          />
        </Popup>
      </div>

      <div className="">
        <Popup
          title={`Add New Customer`}
          setIsOpen={setNewcustomerPopup}
          isOpen={newcustomerPopup}
          closeModal={closeNewcustomer}
        >
          <Addnewcustomer closeModal={closeNewcustomer} />
        </Popup>
      </div>
    </div >
  );
}
