import { useEffect, useState } from "react";
import { getAxios } from "../../../../service/apiservice";
import { paymentMethodEndPoint } from "../../../../service/api";
import { bankMethod, cashMethod, chequeMethod, creditCardMethod, debitCardMethod, giftVoucherMethod, schemeMethod, upiMethod } from "../../../../constant";
import PaymentProviderOptions from "./paymentprovideroption";

export default function AddPayments({setPaymentMethodDropDown,paymentMethodDropDown}) {


    useEffect(() => {
        getPaymentMethodList();
    }, [])

    const getPaymentMethodList = async () => {
        var response = await getAxios({
            url: paymentMethodEndPoint
        })

        if (response != null) {
            setPaymentMethodDropDown(response.data.list)
        }
    }

    return (
        <div >
            <div className="flex flex-col gap-2">
                {
                    paymentMethodDropDown?.map((item) => {
                        return (
                            <div className="flex gap-3 w-full">
                                <div style={{ backgroundColor: item.bg_color, color: item.color }} className="border px-1 py-1 rounded-lg text-[13px] min-w-[150px]">{item.payment_method_name}</div>
                                {
                                    item.id == cashMethod && <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-[30%] p-[3px] text-[13px] outline-none"
                                        id={item.payment_method_name}
                                    />
                                }

                                {
                                    item.id == debitCardMethod && <div className="flex items-center w-[30%]">
                                        <PaymentProviderOptions method={item.id} id={item.payment_method_name+"provider"} />
                                        <input
                                            type="number"
                                            placeholder="₹ 0.00"
                                            className="sm:mb-[2px] border border-gray-300 rounded-r-lg w-[60%] p-[3px] text-[13px] outline-none"
                                            id={item.payment_method_name}
                                        />
                                    </div>
                                }

                                {
                                    item.id == creditCardMethod && <div className="flex items-center w-[30%]">
                                        <PaymentProviderOptions method={item.id} id={item.payment_method_name+"provider"} />
                                        <input
                                            type="number"
                                            placeholder="₹ 0.00"
                                            className="sm:mb-[2px] border border-gray-300 rounded-r-lg w-[60%] p-[3px] text-[13px] outline-none"
                                            id={item.payment_method_name}
                                        />
                                    </div>
                                }

                                {
                                    item.id == upiMethod && <div className="flex items-center w-[30%]">
                                        <PaymentProviderOptions method={item.id} id={item.payment_method_name+"provider"} />
                                        <input
                                            type="number"
                                            placeholder="₹ 0.00"
                                            className="sm:mb-[2px] border border-gray-300 rounded-r-lg w-[60%] p-[3px] text-[13px] outline-none"
                                            id={item.payment_method_name}
                                            name={item.payment_method_name}
                                        />
                                    </div>
                                }

                                {
                                    item.id == bankMethod && <input
                                            type="number"
                                            placeholder="₹ 0.00"
                                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-[30%] p-[3px] text-[13px] outline-none"
                                            id={item.payment_method_name}
                                            name={item.payment_method_name}
                                        />
                                  
                                }

                                {
                                    item.id == giftVoucherMethod && <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-[30%] p-[3px] text-[13px] outline-none"
                                        id={item.payment_method_name}
                                        name={item.payment_method_name}
                                    />
                                }
                                {
                                    item.id == schemeMethod && <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-[30%] p-[3px] text-[13px] outline-none"
                                        id={item.payment_method_name}
                                        name={item.payment_method_name}
                                    />
                                }
                                {
                                    item.id == chequeMethod && <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-[30%] p-[3px] text-[13px] outline-none"
                                        id={item.payment_method_name}
                                        name={item.payment_method_name}
                                    />
                                }

                                {item.id != cashMethod && <div className="">
                                    <input
                                        type="text"
                                        placeholder="Enter reference id"
                                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                        name={item.payment_method_name+"ref_number"}
                                        id={item.payment_method_name+"ref_number"}
                                    />
                                </div>}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}