import { useEffect, useState } from "react";
import Spreadsheet from "react-spreadsheet";
import Popup from "../Popup";

export default function ExcelViewer({ data, headers, closeModal, enabled, setEnabled, title }) {
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            let tempList = [];
            for (var i of data) {
                let val = Object.values(i)
                let row = []
                for (var j of val) {
                    let dictValues = {
                        value: j
                    }
                    row.push(dictValues)
                }
                tempList.unshift(row)
            }
            setValues([headers, ...tempList])
        }
    }, [data, headers])

    return (
        <>
            <button
            onClick={(e) => {e.preventDefault(); setEnabled(true)}}
                className='flex items-center bg-primary-btn p-2 text-white rounded-md gap-4'>
                <p>View In Excel</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 15 15"><path fill="currentColor" d="M4.5 6.995H4v1h.5zm6 1h.5v-1h-.5zm-6 2.505H4v.5h.5zm6 0v.5h.5v-.5zm-6-6.503H4v1h.5zm6 1h.5v-1h-.5zm3-1.497h.5v-.207l-.146-.147zm-3-3l.354-.354L10.707 0H10.5zm-6 7.495h6v-1h-6zM4.5 11h6v-1h-6zm0-6.003h6v-1h-6zm8 9.003h-10v1h10zM2 13.5v-12H1v12zm11-10v10h1v-10zM2.5 1h8V0h-8zm7.646-.146l3 3l.708-.708l-3-3zM2.5 14a.5.5 0 0 1-.5-.5H1A1.5 1.5 0 0 0 2.5 15zm10 1a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5zM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5zm2 3v6h1v-6zm3 0v6h1v-6zm3 0v6h1v-6z" /></svg>
            </button>
            <Popup extraClassName="min-w-full" title={title} isOpen={enabled} setIsOpen={setEnabled} closeModal={closeModal}>
                <div className="w-full overflow-scroll">
                <Spreadsheet data={values} />
                </div>
            </Popup>
        </>);
}