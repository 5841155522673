import toast from "react-hot-toast";
import { recieveOrderItemsEndPoint } from "../../../../service/api";
import { postAxiosWithToken } from "../../../../service/apiservice";
import { useEffect, useState } from "react";
import { orderIssuedStatusType, pendingStatusType } from "../../../../constant";

export default function ViewParticulars({ particularsList, recievable, getData }) {

    const [recievedItems, setRecievedItems] = useState([]);
    const [unRecievedItems, setUnRecievedItems] = useState([]);
    const [PaymentDetails, setPaymentDetails] = useState([]);

    console.log(PaymentDetails, "PaymentDetails")

    useEffect(() => {
        let rItems = []
        let unRItems = []
        let payment = []
        for (var i of particularsList) {
            if (i.is_recieved) {
                rItems.push(i)
            } else {
                unRItems.push(i)
            }
            payment.push(i)
        }

        setRecievedItems(rItems);
        setUnRecievedItems(unRItems);
        setPaymentDetails(payment);
    }, [particularsList])

    const recieveItem = async (value, id) => {
        var response = await postAxiosWithToken({
            url: recieveOrderItemsEndPoint,
            body: {
                item_id: id,
                status: value
            }
        })

        if (response != null) {
            getData()
            toast.success(response.message)
        }
    }

    return (
        <div>
            <div className="overflow-x-scroll mb-3">
                <table className="w-full">
                    <thead className="bg-table-head text-table-head-font  sticky top-0 z-[3]">
                        <tr>
                            {recievable && <td className="p-[10px] border text-center" rowSpan={2}>#</td>}
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>S.No</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[150px]" rowSpan={2}>Item Name</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Metal</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Rate</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Gross Wt</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Net Wt</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Size</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>G</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[150px]" rowSpan={2}>Vendor</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[100px]" rowSpan={2}>Status</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Assigned By</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Stone</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Diamond</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Total Amount</td>
                        </tr>
                        <tr>
                            <td className="p-[10px] border text-[13px] text-center" >Type</td>
                            <td className="p-[10px] border text-[13px] text-center">Purity</td>
                            <td className="p-[10px] border text-[13px] text-center">Wt</td>
                            <td className="p-[10px] border text-[13px] text-center" >Amt</td>
                            <td className="p-[10px] border text-[13px] text-center">Wt</td>
                            <td className="p-[10px] border text-[13px] text-center">Amt</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            unRecievedItems.map((item, index) => (
                                <tr className={`${item.order_status == pendingStatusType ? "opacity-20" : ""}`}>
                                    {recievable && <td className="p-[10px] border text-center">
                                        <div class="inline-flex items-center">
                                            <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                                                <input type="checkbox"
                                                    class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                                                    id="check"
                                                    disabled={item.order_status == pendingStatusType ? true : false}
                                                    checked={item.is_recieved}
                                                    onChange={(e) => {
                                                        recieveItem(e.target.checked, item.id)
                                                    }} />

                                                <span
                                                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                        stroke="currentColor" stroke-width="1">
                                                        <path fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </td>}
                                    <td className="p-[10px] border text-center">{index + 1}</td>
                                    <td className="p-[10px] border">{item.item_name}</td>
                                    <td className="p-[10px] border">{item.metal_name}</td>
                                    <td className="p-[10px] border">{item.purity_name}</td>
                                    <td className="p-[10px] border">{item.metal_rate}</td>
                                    <td className="p-[10px] border">{parseFloat(item.gross_weight)?.toFixed(3)}</td>
                                    <td className="p-[10px] border">{item.net_weight?.toFixed(3)}</td>
                                    <td className="p-[10px] border text-center">{item.measurement_value}({item.measurement_name})</td>
                                    <td className="p-[10px] border text-center">{item.gender_name}</td>
                                    <td className="p-[10px] border text-center">{item.vendor}</td>
                                    <td className="p-[10px] border text-center">
                                        <div className={`${item.order_status == pendingStatusType ? "p-3 rounded-full" : ""} capitalize text-[15px] `} style={{ backgroundColor: item.order_status == pendingStatusType ? item.order_status_color : "", color: item.order_status != pendingStatusType ? item.order_status_color : "" }}>{item.order_status_name}</div>
                                    </td>
                                    <td className="p-[10px] border text-center">{item.assigned_by}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_amount}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_amount}</td>
                                    <td className="p-[10px] border text-center">{item.total_amount?.toFixed(2)}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <p className="mb-[5px] text-[16px] font-[500]">Recieved Particulars</p>

            <div className="overflow-x-scroll">
                <table className="w-full">
                    <thead className="bg-table-head text-table-head-font  sticky top-0 z-[3]">
                        <tr>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>S.No</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[150px]" rowSpan={2}>Item Name</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Metal</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Rate</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Gross Wt</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Net Wt</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Size</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>G</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[150px]" rowSpan={2}>Vendor</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[100px]" rowSpan={2}>Status</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Assigned By</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Stone</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Diamond</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Total Amount</td>
                        </tr>
                        <tr>
                            <td className="p-[10px] border text-[13px] text-center" >Type</td>
                            <td className="p-[10px] border text-[13px] text-center">Purity</td>
                            <td className="p-[10px] border text-[13px] text-center">Wt</td>
                            <td className="p-[10px] border text-[13px] text-center" >Amt</td>
                            <td className="p-[10px] border text-[13px] text-center">Wt</td>
                            <td className="p-[10px] border text-[13px] text-center">Amt</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            recievedItems.map((item, index) => (
                                <tr className={`${item.order_status == orderIssuedStatusType || item.order_status == pendingStatusType ? "opacity-20" : ""}`}>

                                    <td className="p-[10px] border text-center">{index + 1}</td>
                                    <td className="p-[10px] border">{item.item_name}</td>
                                    <td className="p-[10px] border">{item.metal_name}</td>
                                    <td className="p-[10px] border">{item.purity_name}</td>
                                    <td className="p-[10px] border">{item.metal_rate}</td>
                                    <td className="p-[10px] border">{parseFloat(item.gross_weight)?.toFixed(3)}</td>
                                    <td className="p-[10px] border">{item.net_weight?.toFixed(3)}</td>
                                    <td className="p-[10px] border text-center">{item.measurement_value}({item.measurement_name})</td>
                                    <td className="p-[10px] border text-center">{item.gender_name}</td>
                                    <td className="p-[10px] border text-center">{item.vendor}</td>
                                    <td className="p-[10px] border text-center">
                                        <div className={`${item.order_status == pendingStatusType ? "p-3 rounded-full" : ""} capitalize text-[15px] `} style={{ backgroundColor: item.order_status == pendingStatusType ? item.order_status_color : "", color: item.order_status != pendingStatusType ? item.order_status_color : "" }}>{item.order_status_name}</div>
                                    </td>
                                    <td className="p-[10px] border text-center">{item.assigned_by}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_amount}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_amount}</td>
                                    <td className="p-[10px] border text-center">{item.total_amount?.toFixed(2)}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>


            <p className="mb-[5px] text-[16px] font-[500]">Recent Payment History</p>

            <div className="overflow-x-scroll">
                <table className="w-full">
                    <thead className="bg-table-head text-table-head-font  sticky top-0 z-[3]">
                        <tr>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>S.No</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[150px]" rowSpan={2}>Order ID</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>date</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>REf.No</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Total Amount</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Paid Amount</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Adv In Grms</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Balance </td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[150px]" rowSpan={2}>Cash</td>
                            <td className="p-[10px] border text-[13px] text-center min-w-[100px]" rowSpan={2}>Card</td>
                            <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>UPI</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Bank</td>
                            <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Status</td>
                        </tr>

                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            PaymentDetails.map((item, index) => (
                                <tr >

                                    <td className="p-[10px] border text-center">{index + 1}</td>
                                    <td className="p-[10px] border">{item.order_id}</td>
                                    <td className="p-[10px] border">{item.metal_name}</td>
                                    <td className="p-[10px] border">{item.purity_name}</td>
                                    <td className="p-[10px] border">{item.metal_rate}</td>
                                    <td className="p-[10px] border">{parseFloat(item.gross_weight)?.toFixed(3)}</td>
                                    <td className="p-[10px] border">{item.net_weight?.toFixed(3)}</td>
                                    <td className="p-[10px] border text-center">{item.measurement_value}({item.measurement_name})</td>
                                    <td className="p-[10px] border text-center">{item.gender_name}</td>
                                    <td className="p-[10px] border text-center">{item.vendor}</td>
                                    <td className="p-[10px] border text-center">
                                        <div className={`${item.order_status == pendingStatusType ? "p-3 rounded-full" : ""} capitalize text-[15px] `} style={{ backgroundColor: item.order_status == pendingStatusType ? item.order_status_color : "", color: item.order_status != pendingStatusType ? item.order_status_color : "" }}>{item.order_status_name}</div>
                                    </td>
                                    <td className="p-[10px] border text-center">{item.assigned_by}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_amount}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_amount}</td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}