import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ActionButton from "./widgets/actionbutton";
import Pagination from "../../../components/Pagination";
import Popup from "../../../components/Popup";
import { postAxiosWithToken } from "../../../service/apiservice";
import { requisitionApprovalListEndPoint } from "../../../service/api";
import ReactToPrint from "react-to-print";
import RequisitionApprovalPrint from "../../../components/Printouts/RequisitionApprovalPrint";

export default function RequisitionApproval() {

    let componentRef = useRef();

    const navigate = useNavigate();

    const { actions, pagination } = useSelector((state) => state.appConfig);

    const [requisitionApprovalList, setRequitionApprovalList] = useState([]);
    const [requisitionApproval, setRequitionApproval] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        order_status: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const [printView, setPrintView] = useState(false)

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    function closePrintModal() {
        setPrintView(false)
    }

    useEffect(() => {
        getApprovalListData()
    },[tableFilters])

    const getApprovalListData = async () => {
        var response = await postAxiosWithToken({
            url: requisitionApprovalListEndPoint,
            body: tableFilters
        })

        if (response !== null) {
            setRequitionApprovalList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <p className="font-[500] text-[20px]">Jewellery Requisition Approval</p>
                <button
                    onClick={() => {
                        if (actions.addPermit) {
                            navigate('requisition-approval-create')
                        } else {
                            toast.error("You don't have permission for add")
                        }
                    }}
                    className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                >Add Requisition <span className="lowercase">(ctrl+m)</span></button>
            </div>
            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5 bg-white">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="p-[10px] min-w-[150px]">Requisition ID</td>
                            <td className="p-[10px]">Date</td>
                            <td className="p-[10px] min-w-[100px]">Requisition Shop</td>
                            <td className="p-[10px] min-w-[100px]">Issued By</td>
                            <td className="p-[10px] min-w-[100px]">Receiver Name</td>
                            <td className="p-[10px] min-w-[150px]">Gross Weight</td>
                            <td className="p-[10px] min-w-[150px]">Net Weight</td>
                            <td className="p-[10px] ">Branch</td>
                            <td className="p-[10px] min-w-[150px] text-center">Status</td>
                            <td className="p-[10px] text-center sticky right-0 bg-table-head">Action</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={15}>Loading...</td>
                            </tr> :
                                requisitionApprovalList.length === 0 ? <tr><td className="text-center border" colSpan={15}>No Data</td></tr> :
                                    requisitionApprovalList?.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px]">
                                                <Link to={'view-requisition-approval/' + item.id}>
                                                    <span className="text-blue-600">{item.approval_issue_id}</span>
                                                </Link>
                                            </td>
                                            <td className="p-[10px] capitalize ">{item.issue_date}</td>
                                            <td className="p-[10px] capitalize">{item.shop}</td>
                                            <td className="p-[10px] capitalize ">{item.issued_by}</td>
                                            <td className="p-[10px] capitalize ">{item.receiver_name}</td>
                                            <td className="p-[10px] capitalize ">{item.issued_gross_weight}</td>
                                            <td className="p-[10px] capitalize">{item.issued_net_weight}</td>
                                            <td className="p-[10px] ">{item.branch_name}</td>
                                            <td className="p-[10px] capitalize">
                                                <div style={{backgroundColor: item.status_color}} className="px-[15px] py-[5px] rounded-full text-white text-center">{item.status_name}</div>
                                            </td>
                                            <td className="p-[10px] text-center sticky right-0 bg-white">
                                                <ActionButton
                                                    item={item}
                                                    setIsDeleteOpen={setIsDeleteOpen}
                                                    setCurrentDetails={setRequitionApproval}
                                                    setPrintView={setPrintView}
                                                ></ActionButton>
                                            </td>
                                        </tr>
                                    ))
                        }
                    </tbody>
                </table>
            </div>

            {pagination === true &&
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div>}

            <Popup title={'Delete Requistion'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to cancel the {requisitionApproval.orderdetails_number} requistion?</p>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">No</button>
                        <button type="submit" onClick={() => { }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Yes</button>
                    </div>
                </div>
            </Popup>

            <Popup extraClassName="min-w-full h-[600px] overflow-y-scroll" title={'Order Details'} setIsOpen={setPrintView} isOpen={printView} closeModal={closePrintModal}>
                <div className="mb-[25px]" ref={(el) => (componentRef = el)}>
                <RequisitionApprovalPrint
                    particulars={requisitionApproval.issued_tag_details||[]}
                    detials={requisitionApproval}
                   
                    />
                </div>
                <ReactToPrint
                    trigger={() => <button id="print" className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none">
                        Print (ctrl+p)
                    </button>}
                    content={() => componentRef}
                />
            </Popup>
        </div>
    );
}