import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { customerissuerepairEndPoint, customerissueListEndPoint } from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { useKeyPress } from "../../../hooks/useKeyPress";
import IssueCustomerreportFilter from "./repairissuecustomerreportfilter";
import IssueCustomerreportform from "./repairissuecustomerreportform";

export default function IssueCustomerreport() {

    const searchRef = useRef();
    const [customerissueList, setVendorwisepurchaseList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Vendor Wise Purchase Report"

    function closeExcelModal() {
        setExcelOpen(false)
    }


    useEffect(() => {
        let tempData = [];
        for (var i of customerissueList) {
            tempData.push(i)
            tempData.push({
                is_total: true,
                repair_data: ["hi"],
                total_pieces: i.total_pieces,
                total_gross_weight: i.total_gross_weight,
                total_net_weight: i.total_net_weight,
                hallmark: i.hallmark,
                total_amount: i.total_amount
            })
        }
        setData(tempData)
    }, [customerissueList])

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        account_head_name: null,
        customer: '',
        status:'',

        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);


    useEffect(() => {
        if (customerissueList.length > 0) {
            let firstData = Object.keys(customerissueList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [customerissueList])

    useEffect(() => {
        getcustomerissuereportListData(tableFilters)
    }, [tableFilters])



    const getcustomerissuereportListData = async (body) => {
        var response = await postAxiosWithToken({
            url: customerissuerepairEndPoint,
            body: body
        })

        if (response !== null) {
            setVendorwisepurchaseList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                    Vendor Wise Purchase Report
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={customerissueList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={customerissueList} fileName={fileName} />
                </div>
            </div>
            <IssueCustomerreportform searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <IssueCustomerreportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }

                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">

                <table className="w-full ">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="border p-[10px]">Customer Name</td>
                            <td className="border p-[10px]">bill NO</td>
                            <td className="border p-[10px]">date</td>
                            <td className="border p-[10px]">metal tYPE</td>
                            <td className="border p-[10px]">item name</td>
                            <td className="border p-[10px]">Description</td>
                            <td className="border p-[10px]">pCS</td>
                            <td className="border p-[10px]">G Wt</td>
                            <td className="border p-[10px]">N Wt</td>
                            <td className="border p-[10px]">S Wt</td>
                            <td className="border p-[10px]">d Wt</td>
                            <td className="border p-[10px]">Vendor</td>
                            {/* <td className="border p-[10px]">Status</td>
                            <td className="border p-[10px]">AMOUNT</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, index) => (
                                item.repair_data?.length > 0 &&
                                item.repair_data.map((subItem, subIndex) => (
                                    item.is_total ?
                                        <tr className="border">
                                            <td className="p-[10px] border">Total</td>
                                            <td></td>
                                            <td></td>
                                            <td className="p-[10px] border">{item.total_pieces}</td>
                                            <td className="p-[10px] border">{item.total_gross_weight}</td>
                                            <td className="p-[10px] border">{item.total_net_weight}</td>
                                            <td></td>
                                            <td></td>
                                            <td className="p-[10px] border">{item.hallmark}</td>
                                            <td className="p-[10px] border">{item.total_amount}</td>
                                        </tr> : <tr >
                                            {subIndex === 0 && <td className="border p-[10px]" rowSpan={item.repair_data?.length ? item.repair_data?.length : 1}>{item.customer_name}</td>}
                                            {console.log(subIndex, "subIndex")}
                                            <td className="p-[10px] border">{subItem.repair_number}</td>
                                            <td className="p-[10px] border">{subItem.repair_date}</td>
                                            <td className="p-[10px] border">{subItem.metal_name}</td>
                                            <td className="p-[10px] border">{subItem.item_name}</td>
                                            <td className="p-[10px] border">{subItem.description}</td>
                                            <td className="p-[10px] border">{subItem.pieces}</td>
                                            <td className="p-[10px] border">{subItem.gross_weight}</td>
                                            <td className="p-[10px] border">{subItem.net_weight}</td>
                                            <td className="p-[10px] border">{subItem.stone_count}</td>
                                            <td className="p-[10px] border">{subItem.diamond_count}</td>
                                            <td className="p-[10px] border">{subItem.vendor_name}</td>
                                            {/* <td className="p-[10px] border">-</td>
                                            <td className="p-[10px] border">-</td> */}
                                        </tr>
                                ))

                            ))
                        }
                    </tbody>
                </table>

            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}