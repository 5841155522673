import { useSelector } from "react-redux";
import { orderForCustomer } from "../../constant";
import { useEffect, useState } from "react";

export default function OrderReceiptPrint({
    customerName,
    orderId,
    orderDate,
    orderFor,
    expDelDate,
    items,
    appAmt,
    totalItems,
    advAmt,
    advInGram,
    grandTotal,
    balAmt,
    amtInWords,
    printsize
}) {

    const { todayGoldRate, todaySilverRate } = useSelector((state) => state.appConfig)
    const [size, setSize] = useState([]);

    useEffect(() => {
        let Sizelist;
        for (var item of printsize) {
            Sizelist = item.order_is_a4
        }
        setSize(Sizelist)
    }, [printsize])

    return (
        <div className={` ${size === true ? "w-full" : "max-w-[500px]"} relative h-[1100px]`}>
            <div className="bg-white p-1">
                <div className="border">
                    <div className="w-fit mx-auto flex gap-10 items-center py-3">
                        <div className="bg-gray-200 w-[80px] h-[80px] rounded-full"></div>
                        <div className="text-center">
                            <p className="font-[600]">AuSales</p>
                            <address className="text-[12px]">
                                282, 4TH FLOOR, SAI AKSHAYAM, Marudhamalai Rd,<br />
                                Mullai Nagar, Coimbatore, Tamil Nadu 641041 <br />
                                Email:Test@gmail.com Mobile No:98765433210<br />
                                Web: www.testjewelleryshop.com
                            </address>

                        </div>
                    </div>
                    <div className="text-[16px] relative p-4 border-y">
                        <p className="font-[600] text-center">Customer Order Receipt</p>
                        <div className="absolute right-2 top-0">
                            <p>Gold Rate  : <span className="font-[600]">₹ {todayGoldRate}</span></p>
                            <p>Silver Rate : <span className="font-[600]">₹ {todaySilverRate}</span></p>
                        </div>
                    </div>
                    <div className={`${orderFor == orderForCustomer ? 'justify-between' : 'justify-end'} flex items-start p-3 border-b`}>
                        {orderFor == orderForCustomer && <div className="text-[14px]">
                            <p className="font-[600]">{customerName}</p>
                            <address className="text-[10px]">
                                282, 4TH FLOOR, SAI AKSHAYAM, Marudhamalai Rd,<br />
                                Mullai Nagar, Coimbatore, Tamil Nadu 641041 <br />
                                Email:Test@gmail.com Mobile No:98765433210<br />
                                Web: www.testjewelleryshop.com
                            </address>
                        </div>}
                        <div className="text-[12px]">
                            <p>ORE. No#: <span className="text-primary-btn">{orderId}</span></p>
                            <p>OR Date: <span className="text-primary-btn">{orderDate}</span></p>
                            <p>Expected Delivery: <span className="text-primary-btn">{expDelDate}</span></p>
                        </div>
                    </div>
                    <div className="h-7 w-full">

                    </div>
                    <div className="text-[12px]">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <td className="border p-1">#</td>
                                    <td className="border p-1">Item Name</td>
                                    <td className="border p-1">Metal</td>
                                    <td className="border p-1">Pcs</td>
                                    <td className="border p-1">Rate</td>
                                    <td className="border p-1">Grs Wt.</td>
                                    <td className="border p-1">Net Wt</td>
                                    <td className="border p-1">Purity</td>
                                    <td className="border p-1">St Wt</td>
                                    <td className="border p-1">St Amt</td>
                                    <td className="border p-1">Dia Wt</td>
                                    <td className="border p-1">Dia Amt</td>
                                    <td className="border p-1">Total Amt</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <tr>
                                            <td className="border p-1">#{index + 1}</td>
                                            <td className="border p-1">{item.item_name}</td>
                                            <td className="border p-1">{item.metal_name}</td>
                                            <td className="border p-1">1</td>
                                            <td className="border p-1">{item.metal_rate}</td>
                                            <td className="border p-1">{item.gross_weight}</td>
                                            <td className="border p-1">{item.net_weight}</td>
                                            <td className="border p-1">{item.purity_name}</td>
                                            <td className="border p-1">{item.total_stone_weight}</td>
                                            <td className="border p-1">{item.total_stone_amount}</td>
                                            <td className="border p-1">{item.total_diamond_weight}</td>
                                            <td className="border p-1">{item.total_diamond_amount}</td>
                                            <td className="border p-1">{item.total_amount}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="border-y flex h-44 text-[14px]">
                        <div className="w-1/2">
                            <div className="p-1">Total Items: <span>{totalItems}</span></div>
                            <div className="border-y p-1">Amount in words: <span>{amtInWords}</span></div>
                            <div className="h-7 w-full "></div>
                            <div className="w-full h-[70px] p-1 flex justify-between items-end border-t text-[12px]">
                                <div >Customer Sign</div>
                                <div className="h-full flex flex-col justify-between items-end">
                                    <p>For DEMO JEWELLERY,</p>
                                    <p>Authorised Signatory</p>
                                </div>
                            </div>
                        </div>
                        <div className="border-l flex flex-col gap-2 items-end w-1/2 p-1">
                            <p>Bill Amount <span>₹ {appAmt}</span></p>
                            <p>Advance Amount <span>₹ {advAmt}</span></p>
                            <p>Advance in Grm <span>(g) {advInGram}</span></p>
                            <p>Grand Total <span>₹ {grandTotal}</span></p>
                            <p>Balance Amount <span>₹ {balAmt}</span></p>
                        </div>
                    </div>
                    <div className="h-24 w-full p-1 mt-8">
                        <p>Notes:</p>
                    </div>
                </div>
            </div>
        </div>
    );
}