import { useEffect, useState } from "react";
import { centgroupListEndPoint, lotStoneDeleteEndPoint, rateTypeListEndPoint, stoneListEndPoint, taggingWeightTypeListEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios } from "../../../service/apiservice";
import { isValidForm } from "../../../utilities/formvalidation";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import Select from 'react-select';

export default function PaymentForm({ dataList = [], setDataList, formType, closeModal, setRemovedStone, removedStone }) {

    const [stoneDropDown, setStoneDropDown] = useState([]);
    const [weightDropDown, setWeightDropDown] = useState([]);
    const [rateDropDown, setRateDropDown] = useState([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
        setIsLoading(null)
    }

    useEffect(() => {

        if (formType === 'create') {
            addValue()
        }
        getStoneDropDownData();
        getWeightTypeDropDownData();
        getRateTypeDropDownData();
    }, [])

    const getStoneDropDownData = async () => {
        var response = await getAxios({
            url: stoneListEndPoint
        })

        if (response !== null) {
            setStoneDropDown(response.data.list)
        }
    }

    const getWeightTypeDropDownData = async () => {
        var response = await getAxios({
            url: taggingWeightTypeListEndPoint
        })

        if (response !== null) {
            setWeightDropDown(response.data.list)
        }
    }

    const getRateTypeDropDownData = async () => {
        var response = await getAxios({
            url: rateTypeListEndPoint
        })

        if (response !== null) {
            setRateDropDown(response.data.list)
        }
    }

    const addValue = () => {
        let firstData = {
            stone_name: "",
            stone_pieces: "",
            stone_weight: "",
            stone_weight_type: "",
            stone_rate: "",
            stone_rate_type: "",
            include_stone_weight: true
        }
        setDataList([...dataList, firstData])
    }

    const removeValue = () => {
        let data = [...dataList];

        if (formType === 'update') {
            if (data[deleteIndex].id) {
                setIsLoading(deleteIndex)
                setIsDeleteOpen(true)
                deleteStone(data[deleteIndex].id)
            } else {
                setIsLoading(null)
                setIsDeleteOpen(false)
            }
        }
        data.splice(deleteIndex, 1);
        setDataList(data);
    }

    const onValueUpdate = (text, index) => {
        const { name, value, checked } = text

        if (name === 'include_stone_weight') {
            let data = [...dataList];
            data[index][name] = checked
            setDataList(data)
        } else {
            let data = [...dataList];
            data[index][name] = value
            setDataList(data)
        }

    }

    const deleteStone = async (id) => {

        var response = await deleteAxiosWithToken({
            url: lotStoneDeleteEndPoint + id + '/'
        })

        if (response !== null) {
            setIsLoading(null)
            setIsDeleteOpen(false)
            toast.success(response.message)
        }
    }

    const [selectedSubItems, setSelectedSubItems] = useState([]);
    const [showUpiInput, setShowUpiInput] = useState(false);
    const [showCashInput, setShowCashInput] = useState(false);
    const [showCardInput, setShowCardInput] = useState(false);
    const [showAccountInput, setShowAccountInput] = useState(false);

    const subItemList = [
        { label: 'upi', value: 'upi' },
        { label: 'cash', value: 'cash' },
        { label: 'card', value: 'card' },
        { label: 'account', value: 'account' },
    ];

    const handleSubItemsChange = (selectedOptions) => {
        setSelectedSubItems(selectedOptions);
        setShowUpiInput(selectedOptions.some(option => option.value === 'upi'));
        setShowCashInput(selectedOptions.some(option => option.value === 'cash'));
        setShowCardInput(selectedOptions.some(option => option.value === 'card'));
        setShowAccountInput(selectedOptions.some(option => option.value === 'account'));
    };

    return (
        <div className="w-full mt-[15px]">
            {/* <button className="border-none outline-none bg-primary-btn text-white rounded float-right mb-[10px] px-[10px] py-[7px]" onClick={(e) => { e.preventDefault(); addValue() }}>Add Stone</button> */}
            <div className="w-full mb-10  rounded-md ">

                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Vendor Name<span className="text-red-500">*</span> </p>
                        <select
                            type="text"
                            placeholder="Repair Type"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="vendor_name"
                        // value={repairitemsformvalue.repair_type}
                        // onChange={Ontablevalueupdate}
                        >
                            <option value={""}>--select Order Details--</option>
                            {/* {
                                repairbropDown?.map((option) => (
                                    <option className="capitalize" key={"RepairType" + option.repair_type_name} value={option.id}>{option.repair_type_name}</option>
                                ))
                            } */}
                        </select>
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.repair_type}</p> */}
                    </div>
                            {/* <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3"> */}
                    <div className="flex gap-4">
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Reference <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            name="Reference"
                            placeholder="Received Date "
                            // onChange={Ontablevalueupdate}
                            // value={repairitemsformvalue.issued_gross_weight}

                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                        />
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.issued_gross_weight}</p> */}
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Vendor Charges (grm) <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            name="vendor_charges"
                            placeholder="Received Date "
                            // onChange={Ontablevalueupdate}
                            // value={repairitemsformvalue.issued_gross_weight}

                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                        />
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.issued_gross_weight}</p> */}
                    </div>
                    </div>
                   <div className="flex gap-4">
                   <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Vendor Payment ₹<span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            name="vendor_payment"
                            placeholder="Received Date "
                            // onChange={Ontablevalueupdate}
                            // value={repairitemsformvalue.issued_gross_weight}

                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                        />
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.issued_gross_weight}</p> */}
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Vendor Payment <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            name="vendor_payment"
                            placeholder="Received Date "
                            // onChange={Ontablevalueupdate}
                            // value={repairitemsformvalue.issued_gross_weight}

                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                        />
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.issued_gross_weight}</p> */}
                    </div>
                   </div>
                        {/* </div> */}
                   
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Vendor Charges ₹ <span className="text-red-500">*</span> </p>
                        <select
                            type="text"
                            placeholder="Repair Type"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="vendor_charges"
                        // value={repairitemsformvalue.repair_type}
                        // onChange={Ontablevalueupdate}
                        >
                            <option value={""}>--select Order Details--</option>
                            {/* {
                      repairbropDown?.map((option) => (
                        <option className="capitalize" key={"RepairType" + option.repair_type_name} value={option.id}>{option.repair_type_name}</option>
                      ))
                    } */}
                        </select>
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.repair_type}</p> */}
                    </div>
            </div>

            <div className="mt-[10px] flex gap-3 justify-end items-center">
                <button onClick={() => {

                    closeModal()
                }} className="border-[2px] outline-none px-[10px] py-[5px] w-fit rounded-lg hover:bg-primary-btn  hover:text-white">Reset</button>

                <button onClick={() => {
                    let tempDataList = []
                    for (var item of dataList) {
                        if (isValidForm(item)) {
                            tempDataList.push(item)
                        }
                    }
                    setDataList(tempDataList)
                    closeModal()
                }} className="border-[2px] outline-none px-[10px] py-[5px] w-fit rounded-lg border-secondary hover:bg-secondary hover:text-primary-grey">Add</button>
                {/* <button className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Save</button> */}
            </div>

            <div>
                <Popup title={'Delete Stone'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the stone details?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { removeValue() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
}