import { convertToDate } from "../../../utilities/datetime";

export default function ParticularList({ particularList, selectedItem, setSelectedItem }) {

    const onSelected = (item, index, check) => {
        let data = [...selectedItem]
        if (check) {
            let itemData = {
                "old_gold_id": item.id,
                "old_gold_type": item.old_gold_type,
                "old_gold_number": item.old_gold_no,
                "refference_number": item.refference_number,
                "old_metal": item.old_metal,
                "received_date": convertToDate(item.recieved_date),
                "gross_weight": item.old_gross_weight,
                "net_weight": item.old_net_weight,
                "dust_weight": item.dust_weight
            }
            data.push(itemData)
            setSelectedItem(data)
        } else {
            let tempList = [];
            for (var i of selectedItem) {
                if (item.id != i.old_gold_id) {
                    tempList.push(i)
                }
            }
            setSelectedItem(tempList)
        }
    }

    return (
        <div>
            <div className="overflow-x-scroll w-full">
                <table className="w-full">
                    <thead className="bg-table-head text-table-head-font">
                        <tr>
                            <td className="border px-5 py-1">#</td>
                            <td className="border px-5 py-1">S.No</td>
                            <td className="border px-5 py-1">Reference No</td>
                            <td className="border px-5 py-1">Metal Name</td>
                            <td className="border px-5 py-1">Bill No</td>
                            <td className="border px-5 py-1">Gross Weight</td>
                            <td className="border px-5 py-1">Net Weight</td>
                            <td className="border px-5 py-1">Dust Weight</td>
                            <td className="border px-5 py-1">Received Date</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            particularList?.map((item, index) => (
                                <tr>
                                    <td className="border px-5 py-1">
                                        <div class="inline-flex items-center">
                                            <label class="relative flex items-center rounded-full cursor-pointer" htmlFor="check">
                                                <input type="checkbox"
                                                    class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                                                    onChange={(e) => onSelected(item, index, e.target.checked)} />

                                                <span
                                                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                        stroke="currentColor" stroke-width="1">
                                                        <path fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </td>
                                    <td className="border px-5 py-1">{index + 1}</td>
                                    <td className="border px-5 py-1">{item.refference_number}</td>
                                    <td className="border px-5 py-1">{item.old_metal_name}</td>
                                    <td className="border px-5 py-1">{item.old_gold_no}</td>
                                    <td className="border px-5 py-1">{item.old_gross_weight}</td>
                                    <td className="border px-5 py-1">{item.old_net_weight}</td>
                                    <td className="border px-5 py-1">{item.dust_weight}</td>
                                    <td className="border px-5 py-1">{convertToDate(item.recieved_date)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}