import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import Arrow from "../../assets/images/arrowsymbol.svg"
import { totalpaymentEndPoint } from "../../service/api";
import { postAxiosWithToken } from "../../service/apiservice";
import { useEffect, useState } from "react";


const chartConfig = {
  type: "line",
  height: 240,
  series: [
    {
      name: "Sales",
      data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
    },
  ],
  options: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      show: "",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF5151"],
    stroke: {
      lineCap: "round",
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      borderColor: "#dddddd",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 5,
        right: 20,
      },
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 320,
        options: {
          chart: {
            height: 180,
          },
        },
      },
    ],
  },
};
export default function Linechart({tableFilters}) {

  const [totalpayment, setTotalpayment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const Branch = {branch : tableFilters.branch}


  useEffect(() => {
    getTotalpaymentData(tableFilters)
  }, [tableFilters])

  const getTotalpaymentData = async (body) => {
    var response = await postAxiosWithToken({
      url: totalpaymentEndPoint,
      body: body
    })

    if (response !== null) {
      setTotalpayment(response.data.list)
      setIsLoading(false)
    }
  }




  return (
    <Card className="w-[100%] border">
      <div className="flex justify-between p-5">
        <div className="font-[700]">
          Total Payment
        </div>
        <div className="p-2 rounded-full border">
          <img src={Arrow} alt="arrow" />
        </div>

      </div>
      {/* <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
            >
                
            </CardHeader> */}
      <CardBody className="px-2 pb-[20px] flex gap-8 mt-[-10]">
        <div >
          <div className="">

            <div className="ml-10 p-4 m text-[#FF5151] flex">+2% <span className="mt-1"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36"><path fill="currentColor" d="M27.66 15.61L18 6l-9.66 9.61A1 1 0 1 0 9.75 17L17 9.81v19.13a1 1 0 1 0 2 0V9.81L26.25 17a1 1 0 0 0 1.41-1.42Z" class="clr-i-outline clr-i-outline-path-1" /><path fill="none" d="M0 0h36v36H0z" /></svg></span></div>
          </div>
          <Chart {...chartConfig} />
        </div>

        <div className="mt-[-10px]">
          <div class="bg-[#FFFFFF] p-3 m-2 rounded-[10px] w-[150px] flex justify-between rounded   border border-[#E2E8F0] items-center  ">
            <div>
              <h3 class="font-[500] text-black text-[16px]">Today’s</h3>

              <p class="text-black">{(totalpayment?.today_amount)?.toFixed(2)}</p>

            </div>
 
          </div>
          <div class="bg-[#FFFFFF] p-3 m-2 rounded-[10px] flex justify-between rounded  border border-[#E2E8F0] items-center  ">
            <div>
              <h3 class="font-[500] text-black text-[16px]">This Month</h3>

              <p class="text-black">₹ {(totalpayment?.monthly_amount)?.toFixed(2)}</p>

            </div>

          </div>
          <div class="bg-[#FFFFFF] p-3 m-2 rounded-[10px] flex justify-between rounded  border border-[#E2E8F0] items-center  ">
            <div>
              <h3 class="font-[500] text-black text-[16px]">This Year</h3>

              <p class="text-black">₹ {(totalpayment?.yearwise_amount)?.toFixed(2)}</p>

            </div>

          </div>

        </div>
      </CardBody>
    </Card>
  );
}