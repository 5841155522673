import { companyEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { isValidJson } from "../../../utilities/jsonvalidation";

export default function CompanyDetails() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentCompany, setCurrentCompany] = useState({
        company_name: "",
        email_id: "",
        mobile_no: "",
        website: "",
        std_code: "",
        landline_no: "",
        door_no: "",
        street_name: "",
        area: "",
        taluk: "",
        postal: "",
        district: "",
        country: "",
        state: "",
        pincode: "",
        pan_no: "",
        gst_no: "",
        registered_name: "",
        gst_status: "",
        tax_payer_type: "",
        bussiness_type: ""
    });

    const [bankDetailsInit, setBankDetailsInit] = useState([]);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {

        getCompanyDetails()
    }, [])

    const getCompanyDetails = async () => {
        var response = await getAxios({
            url: companyEndPoint + localStorage.getItem('company_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                company_name: response.data?.company_details?.company_name,
                email_id: response.data?.company_details?.email_id,
                mobile_no: response.data?.company_details?.mobile_no,
                website: response.data?.company_details?.website,
                std_code: response.data?.company_details?.std_code,
                landline_no: response.data?.company_details?.landline_no,
                door_no: response.data?.address_details?.door_no,
                street_name: response.data?.address_details?.street_name,
                area: response.data?.address_details?.area,
                taluk: response.data?.address_details?.taluk,
                postal: response.data?.address_details?.postal,
                district: response.data?.address_details?.district,
                country: response.data?.address_details?.country,
                state: response.data?.address_details?.state,
                pincode: response.data?.address_details?.pincode,
                pan_no: response.data?.gst_details?.pan_no,
                gst_no: response.data?.gst_details?.gst_no,
                registered_name: response.data?.gst_details?.registered_name,
                gst_status: response.data?.gst_details?.gst_status,
                tax_payer_type: response.data?.gst_details?.tax_payer_type,
                bussiness_type: response.data?.gst_details?.bussiness_type
            }

            setBankDetailsInit(response.data?.bank_details)

            setCurrentCompany(tempDetails)
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-menu-head mb-4 capitalize">{formMode} Company</p>
                <form>

                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Company Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Company Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.company_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Email</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.email_id}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Phone no</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.mobile_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Website</p>
                                <p className="text-primary-btn font-[400] text-[15px] hover:underline"><Link to={currentCompany.website} target="_blank">{currentCompany.website}</Link></p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Landline</p>
                                <div className=" w-full">
                                    <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.std_code}-{currentCompany.landline_no}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Door No</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.door_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Street Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.street_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Area</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.area}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Taluk</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.taluk}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Postal</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.postal}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">District</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.district}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country </p>
                                <p className="text-primary-btn font-[400] text-[15px]">{isValidJson(currentCompany.country)?.name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{isValidJson(currentCompany.state)?.name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">GST Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pan Number</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.pan_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST NO</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.gst_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Status</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.gst_status}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Type</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.tax_payer_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bussiness Type</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.bussiness_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Registered Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentCompany.registered_name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                    <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Bank Details</p>
                        {
                            bankDetailsInit.map((bank) => (
                                <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5 border p-[30px] my-[10px] shadow-lg rounded-xl">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Account Holder Name</p>
                                        <p className="text-primary-btn font-[400] text-[15px]">{bank.acc_holder_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Account No</p>
                                        <p className="text-primary-btn font-[400] text-[15px]">{bank.account_no}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">IFSC Code</p>
                                        <p className="text-primary-btn font-[400] text-[15px]">{bank.ifsc_code}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Bank Name</p>
                                        <p className="text-primary-btn font-[400] text-[15px]">{bank.bank_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Branch Name</p>
                                        <p className="text-primary-btn font-[400] text-[15px]">{bank.branch_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">MICR No</p>
                                        <p className="text-primary-btn font-[400] text-[15px]">{bank.micr_code}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </form>
            </div>
        </>
    );
}