import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Addnewcustomer({ closeModal }) {

    const [formMode, setFormMode] = useState('create');
    const navigate = useNavigate();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    return (
        <div className="max-w-[400px] mt-[15px]">
            <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">Customer Name <span className="text-red-500">*</span></p>
                <input
                    type="number"
                    // step={0.01}
                    placeholder="Customer Name"
                    className="sm:mb-[5px] border border-gray-300  rounded-lg w-full p-[8px] text-[17px] outline-none"
                    name="max_fixed_rate"
                // onChange={onNewValueUpdate}
                // value={itemFormNewValues.max_fixed_rate}
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.max_fixed_rate}</p> */}
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">Mobile no <span className="text-red-500">*</span></p>
                <input
                    type="number"
                    // step={0.01}
                    placeholder="mobile no"
                    className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[8px] text-[17px] outline-none"
                    name="max_fixed_rate"
                // onChange={onNewValueUpdate}
                // value={itemFormNewValues.max_fixed_rate}
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.max_fixed_rate}</p> */}
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">Address</p>
                <textarea className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[8px] text-[17px] outline-none" name="" id="" cols="30" rows="4"></textarea>
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.max_fixed_rate}</p> */}
            </div>


            <div className="flex min-[423px]:flex-row flex-col-reverse gap-5 mt-5 p-[20px] rounded-md w-full">
                <button
                    id="backnav"
                    onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                    className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                >close<span className="lowercase">(esc)</span></button>
                <button
                    type="submit"
                    id="formsubmit"
                    className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >save<span className="lowercase">(ctrl+s)</span></button>
            </div>
        </div>
    )
}