import { customertypeListEndPoint, accounttypeListEndPoint, groupnameListEndPoint, accountheadEndPoint, accountheadBankMultiDeleteEndPoint, accountheadAddressMultiDeleteEndPoint, gstVerifyEndPoint, accountheadContactMultiDeleteEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import AccountHeadBankForm from "./accountheadbankform";
import AccountAddressForm from "./accountheadaddress";
import AccountHeadContactForm from "./accountheadcontactform";
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";

const optionFields = ['upi_id', 'acc_holder_name', 'account_no', 'ifsc_code', 'bank_name', 'branch_name', 'micr_code', 'email_id', 'website', 'pan_no', 'tin_no', 'gst_no', 'registered_name', 'gst_status', 'tax_payer_type', 'bussiness_type'];

export default function AccountHeadForm() {
    const [customerType, setCustomerType] = useState([]);
    const [accountType, setAccountType] = useState([]);
    const [groupName, setGroupName] = useState([]);
    const [removedAddressDetails, setRemovedAddressDetails] = useState([]);
    const [removedContactDetails, setRemovedContactDetails] = useState([]);


    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentAccounthead, setCurrentAccounthead] = useState({
        account_head_name: "",
        customer_type: "",
        account_head_code: "",
        is_buyer: true,
        account_type: "",
        group_name: "",
        is_diamond_dealer: true,
        upi_id: null,
        credit_balance_rupee: 0,
        credit_balance_gm: 0,
        debit_balance_rupee: 0,
        debit_balance_gm: 0,
        pan_no: "",
        tin_no: "",
        gst_no: "",
        registered_name: "",
        gst_status: "",
        tax_payer_type: "",
        bussiness_type: ""


    });
    const [bankDetailsInit, setBankDetailsInit] = useState([]);
    const [addressDetailsInit, setAddressDetailsInit] = useState([]);
    const [contactDetailsInit, setContactDetailsInit] = useState([]);
    const [currentAccountheadErrors, setCurrentAccountheadErrors] = useState({});
    const [removedBankDetails, setRemovedBankDetails] = useState([]);

    useEffect(() => {
        getCustomerTypeData()
        getAccountTypeData()
        getGroupNameData()

    }, [])

    // useKeyPress(['s', 'e'], onKeyPress);

    const getCustomerTypeData = async () => {
        var response = await getAxios({
            url: customertypeListEndPoint
        })
        if (response !== null) {
            setCustomerType(response.data.list)
        }
    }

    const getAccountTypeData = async () => {
        var response = await getAxios({
            url: accounttypeListEndPoint
        })

        if (response !== null) {
            setAccountType(response.data.list)
        }
    }
    const getGroupNameData = async () => {
        var response = await getAxios({
            url: groupnameListEndPoint
        })

        if (response !== null) {
            setGroupName(response.data.list)
        }
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            // reset()
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {

        setFormMode(localStorage.getItem('accounthead_form_mode'))

        if (localStorage.getItem('accounthead_form_mode') === 'update') {
            getAccountheadDetails()
        }
    }, [])

    const getAccountheadDetails = async () => {
        var response = await getAxios({
            url: accountheadEndPoint + localStorage.getItem('accounthead_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                account_head_name: response.data?.accounthead_details?.account_head_name,
                customer_type: response.data?.accounthead_details?.customer_type,
                is_buyer: response.data?.accounthead_details?.is_buyer,
                account_head_code: response.data?.accounthead_details?.account_head_code,
                account_type: response.data?.accounthead_details?.account_type,
                is_diamond_dealer: response.data?.accounthead_details?.is_diamond_dealer,
                group_name: response.data?.accounthead_details?.group_name,
                upi_id: response.data?.accounthead_details?.upi_id,
                credit_balance_rupee: response.data?.accounthead_details?.credit_balance_rupee,
                credit_balance_gm: response.data?.accounthead_details?.credit_balance_gm,
                debit_balance_rupee: response.data?.accounthead_details?.debit_balance_rupee,
                debit_balance_gm: response.data?.accounthead_details?.debit_balance_gm,
                pan_no: response.data?.gst_detail?.pan_no,
                tin_no: response.data?.gst_detail?.tin_no,
                gst_no: response.data?.gst_detail?.gst_no,
                registered_name: response.data?.gst_detail?.registered_name,
                gst_status: response.data?.gst_detail?.gst_status,
                tax_payer_type: response.data?.gst_detail?.tax_payer_type,
                bussiness_type: response.data?.gst_detail?.bussiness_type

            }

            // setSelectedCountry(JSON.parse(response.data?.address_detail?.country))

            // getStateDropdown(response.data?.address_detail?.country)
            // setSelectedState(JSON.parse(response.data?.address_detail?.state))


            var tempList = []
            for (var i of response.data?.address_detail) {
                i['stateDropDown'] = []
                State.getStatesOfCountry(JSON.parse(i.country.toString()).isoCode).map((item) => {
                    var dictData = {
                        name: item.name,
                        isoCode: item.isoCode
                    }
                    i['stateDropDown'].push(dictData)
                })
                tempList.push(i)
            }
            setAddressDetailsInit(tempList)


            setContactDetailsInit(response.data?.contact_detail)
            setBankDetailsInit(response.data?.bank_detail)

            setCurrentAccounthead(tempDetails)
        }
    }

    const getGstDetails = async (gstId) => {
        var response = await getAxios({
            url: gstVerifyEndPoint + gstId + "/",
        })

        if (response != null) {
            setCurrentAccounthead((state) => ({
                ...state,
                ['registered_name']: response.data.registered_name,
                ['gst_status']: response.data.gst_status,
                ['tax_payer_type']: response.data.tax_payer_type,
                ['bussiness_type']: response.data.bussiness_type
            }))
            setCurrentAccountheadErrors((state) => ({
                ...state,
                ['registered_name']: "",
                ['gst_status']: "",
                ['tax_payer_type']: "",
                ['bussiness_type']: ""
            }));
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setCurrentAccountheadErrors(err)

        if (isOptionalValidForm(formDataObject, optionFields)) {

            if (localStorage.getItem('accounthead_form_mode') === 'create') {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })

                let tempCreateBody = {}
                tempCreateBody['accounthead_details'] = {
                    account_head_name: formDataObject.account_head_name,
                    customer_type: formDataObject.customer_type,
                    account_head_code: formDataObject.account_head_code,
                    is_buyer: formDataObject.is_buyer,
                    account_type: formDataObject.account_type,
                    group_name: formDataObject.group_name,
                    upi_id: formDataObject.upi_id,
                    is_diamond_dealer: formDataObject.is_diamond_dealer,
                    credit_balance_rupee: formDataObject.credit_balance_rupee,
                    credit_balance_gm: formDataObject.credit_balance_gm,
                    debit_balance_rupee: formDataObject.debit_balance_rupee,
                    debit_balance_gm: formDataObject.debit_balance_gm

                }
                tempCreateBody['contact_details'] = contactDetailsInit

                // let tempAddressList = []

                // for ( var i of addressDetailsInit){
                //     delete i.stateDropDown
                //     tempAddressList.push(i)
                // }
                tempCreateBody['address_details'] = addressDetailsInit

                tempCreateBody['gst_details'] = {
                    pan_no: formDataObject.pan_no,
                    tin_no: formDataObject.tin_no,
                    gst_no: formDataObject.gst_no,
                    registered_name: formDataObject.registered_name,
                    gst_status: formDataObject.gst_status,
                    tax_payer_type: formDataObject.tax_payer_type,
                    bussiness_type: formDataObject.bussiness_type
                }

                tempCreateBody['bank_details'] = bankDetailsInit
                // console.log(tempCreateBody)
                createAccounthead(tempCreateBody)

            } else {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })

                let tempUpdateBody = {}
                tempUpdateBody['accounthead_details'] = {
                    account_head_name: formDataObject.account_head_name,
                    customer_type: formDataObject.customer_type,
                    account_head_code: formDataObject.account_head_code,
                    is_buyer: formDataObject.is_buyer,
                    account_type: formDataObject.account_type,
                    group_name: formDataObject.group_name,
                    is_diamond_dealer: formDataObject.is_diamond_dealer,
                    upi_id: formDataObject.upi_id,
                    credit_balance_rupee: formDataObject.credit_balance_rupee,
                    credit_balance_gm: formDataObject.credit_balance_gm,
                    debit_balance_rupee: formDataObject.debit_balance_rupee,
                    debit_balance_gm: formDataObject.debit_balance_gm

                }
                tempUpdateBody['contact_details'] = []

                for (var i of contactDetailsInit) {
                    var tempDict = {
                        mobile_number: i.mobile_number,
                        email_id: i.email_id,
                        website: i.website,
                        std_code: i.std_code,
                        landline_number: i.landline_number,
                    }
                    tempUpdateBody['contact_details'].push(tempDict)
                }

                if (removedContactDetails.length > 0) {
                    let deleteBody = {
                        id_list: removedContactDetails
                    }
                    deleteAccountheadContactDetails(deleteBody)
                }

                tempUpdateBody['address_details'] = []

                for (var i of addressDetailsInit) {
                    var tempDict = {
                        acc_holder_name: i.acc_holder_name,
                        account_no: i.account_no,
                        ifsc_code: i.ifsc_code,
                        bank_name: i.bank_name,
                        branch_name: i.branch_name,
                        micr_code: i.micr_code
                    }
                    tempUpdateBody['address_details'].push(tempDict)
                }

                if (removedAddressDetails.length > 0) {
                    let deleteBody = {
                        id_list: removedAddressDetails
                    }
                    deleteAccountheadBankDetails(deleteBody)
                }

                tempUpdateBody['bank_detail'] = []

                for (var i of bankDetailsInit) {
                    var tempDict = {
                        acc_holder_name: i.acc_holder_name,
                        account_no: i.account_no,
                        ifsc_code: i.ifsc_code,
                        bank_name: i.bank_name,
                        branch_name: i.branch_name,
                        micr_code: i.micr_code
                    }
                    tempUpdateBody['bank_detail'].push(tempDict)
                }


                if (removedBankDetails.length > 0) {
                    let deleteBody = {
                        id_list: removedBankDetails
                    }
                    deleteAccountheadAddressDetails(deleteBody)
                }

                tempUpdateBody['gst_details'] = {
                    pan_no: formDataObject.pan_no,
                    tin_no: formDataObject.tin_no,
                    gst_no: formDataObject.gst_no,
                    registered_name: formDataObject.registered_name,
                    gst_status: formDataObject.gst_status,
                    tax_payer_type: formDataObject.tax_payer_type,
                    bussiness_type: formDataObject.bussiness_type
                }
                updateAccounthead(tempUpdateBody)
            }

        }
    }

    const createAccounthead = async (body) => {
        var response = await postAxiosWithToken({
            url: accountheadEndPoint,
            body: body
        })
        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/books/account-head')
        }

    }

    const updateAccounthead = async (body) => {
        var response = await putAxiosWithToken({
            url: accountheadEndPoint + localStorage.getItem('accounthead_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('accounthead_form_id')
            localStorage.setItem('accounthead_form_mode', 'create')
            toast.success(response.message)
            navigate('/books/account-head')
        }
    }


    const deleteAccountheadBankDetails = async (body) => {
        var response = await postAxiosWithToken({
            url: accountheadBankMultiDeleteEndPoint,
            body: body
        })
    }
    const deleteAccountheadAddressDetails = async (body) => {
        var response = await postAxiosWithToken({
            url: accountheadAddressMultiDeleteEndPoint,
            body: body
        })
    }
    const deleteAccountheadContactDetails = async (body) => {
        var response = await postAxiosWithToken({
            url: accountheadContactMultiDeleteEndPoint,
            body: body
        })
    }


    const clearData = () => {
        setCurrentAccounthead({
            account_head_name: "",
            customer_type: "",
            account_head_code: "",
            is_buyer: false,
            account_type: "",
            group_name: "",
            is_diamond_dealer: false,
            upi_id: null,
            Credit_balance_RS: "",
            Credit_balance_G: "",
            Debit_balance_Rs: "",
            Debit_balance_G: "",
            pan_no: "",
            tin_no: "",
            gst_no: "",
            registered_name: "",
            gst_status: "",
            tax_payer_type: "",
            bussiness_type: ""

        })
        setBankDetailsInit([]);
    }

    const onValueUpdate = (e) => {
        const { name, value, checked } = e.target



        if (name === 'is_buyer' || name === 'is_diamond_dealer') {
            setCurrentAccounthead({
                ...currentAccounthead,
                [name]: checked,
            });
        } else {
            setCurrentAccounthead((prevState) => ({
                ...prevState,
                [name]: value,
            }));

            setCurrentAccountheadErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
        }


    }


    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Account head</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Account head Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-x-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Account Head Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="account head name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="account_head_name"
                                    id="forminit"
                                    value={currentAccounthead.account_head_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.account_head_name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Customer Type <span className="text-red-500">*</span> </p>
                                <select
                                    placeholder="customer type"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="customer_type"
                                    value={currentAccounthead.customer_type}
                                    onChange={onValueUpdate}
                                // onChange={(e) => {setSelectedCountry(JSON.parse(e.target.value)); getStateDropdown(e.target.value)}}

                                >
                                    <option value={""}>--select customer type--</option>
                                    {
                                        customerType?.map((option) => (
                                            <option key={option.id} value={option.id} className="capitalize">{option.customer_type_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.customer_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Account Head Code</p>
                                <input
                                    type="text"
                                    placeholder="Account Head Code"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="account_head_code"
                                    value={currentAccounthead.account_head_code}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.account_head_code}</p>
                            </div>

                            <div className="my-[5px] flex items-center gap-5 mt-8">
                                <p className="mb-[5px] text-[16px]">Buyer</p>
                                <input
                                    type="checkbox"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                                    name="is_buyer"
                                    checked={currentAccounthead.is_buyer}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.buyer}</p> */}
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Account Type <span className="text-red-500">*</span> </p>
                                <select
                                    placeholder="account type"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="account_type"
                                    value={currentAccounthead.account_type}
                                    onChange={onValueUpdate}
                                // onChange={(e) => {setSelectedCountry(JSON.parse(e.target.value)); getStateDropdown(e.target.value)}}

                                >
                                    <option value={""}>--select account type--</option>
                                    {
                                        accountType?.map((option) => (
                                            <option key={option.id} value={option.id} className="capitalize">{option.account_type_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.account_type}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Group Name <span className="text-red-500">*</span> </p>
                                <select
                                    placeholder="grooup name"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="group_name"
                                    value={currentAccounthead.group_name}
                                    onChange={onValueUpdate}
                                // onChange={(e) => {setSelectedCountry(JSON.parse(e.target.value)); getStateDropdown(e.target.value)}}

                                >
                                    <option value={""}>--select grooup name--</option>
                                    {
                                        groupName?.map((option) => (
                                            <option key={option.id} value={option.id} className="capitalize">{option.account_group_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.account_type}</p>
                            </div>
                            <div className="my-[5px] flex items-center gap-5 mt-8">
                                <p className="mb-[5px] text-[16px]">Diamond Seller</p>
                                <input
                                    type="checkbox"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                                    name="is_diamond_dealer"
                                    checked={currentAccounthead.is_diamond_dealer}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.diamond_seller}</p> */}
                            </div>

                        </div>
                    </div>
                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Credit/Debit Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">UPI ID</p>
                                <input
                                    type="text"
                                    placeholder="UPI ID"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none bg-transparent"
                                    name="upi_id"
                                    value={currentAccounthead.upi_id}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Credit Balance(RS)</p>
                                <input
                                    type="text"
                                    placeholder="credit balace(rs)"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="credit_balance_rupee"
                                    value={currentAccounthead.credit_balance_rupee}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.credit_balance_rupee}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Credit Balance(g)</p>
                                <input
                                    type="text"
                                    placeholder="credit balace(g)"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="credit_balance_gm"
                                    value={currentAccounthead.credit_balance_gm}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.credit_balance_gm}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Debit Balance(RS)</p>
                                <input
                                    type="text"
                                    placeholder="debit balace(rs)"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="debit_balance_rupee"
                                    value={currentAccounthead.debit_balance_rupee}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.debit_balance_rupee}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Debit Balance(g)</p>
                                <input
                                    type="text"
                                    placeholder="debit balace(g)"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="debit_balance_gm"
                                    value={currentAccounthead.debit_balance_gm}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.debit_balance_gm}</p>
                            </div>
                        </div>
                    </div>

                    <AccountAddressForm dataList={addressDetailsInit} setDataList={setAddressDetailsInit} removedItems={removedAddressDetails} setRemovedItems={setRemovedAddressDetails} />

                    <AccountHeadContactForm dataList={contactDetailsInit} setDataList={setContactDetailsInit} removedItems={removedContactDetails} setRemovedItems={setRemovedContactDetails} />

                    <AccountHeadBankForm dataList={bankDetailsInit} setDataList={setBankDetailsInit} removedItems={removedBankDetails} setRemovedItems={setRemovedBankDetails} />

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">GST Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pan Number</p>
                                <input
                                    type="text"
                                    placeholder="pan no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="pan_no"
                                    value={currentAccounthead.pan_no}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.pan_no}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">TIN</p>
                                <input
                                    type="text"
                                    placeholder="Tin"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="tin_no"
                                    value={currentAccounthead.tin_no}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.tin_no}</p> */}
                            </div>
                            {/* <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">GST NO</p>
                                <div className="bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] flex justify-between items-center">
                                    <input
                                        type="text"
                                        placeholder="gst no"
                                        className=" outline-none w-full"
                                        name="gst_no"
                                        id="gst_no"
                                        value={currentAccounthead.gst_no}
                                        onChange={onValueUpdate}
                                    />

                                    <div className="cursor-pointer text-secondary" onClick={() => getGstDetails(document.getElementById('gst_no')?.value)}>verify</div>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.gst_no}</p>
                            </div> */}


                            <div class=" items-center my-[5px] py-2 mt-[-5px]">
                                <p className="mb-[5px] text-[16px]">GST NO</p>
                                <div className="bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] flex justify-between items-center">
                                    <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                        type="text"
                                        placeholder="Jane Doe"
                                        name="gst_no"
                                        id="gst_no"
                                        value={currentAccounthead.gst_no}
                                        onChange={onValueUpdate} />
                                    <button class="flex-shrink-0 bg-blue-700    text-sm  text-white py-1 px-2 rounded" type="button" onClick={() => getGstDetails(document.getElementById('gst_no')?.value)}>
                                        verify
                                    </button>
                                </div>
                            </div>



                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Registered Name</p>
                                <input
                                    type="text"
                                    placeholder="registered name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="registered_name"
                                    value={currentAccounthead.registered_name}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.registered_name}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">GST Status</p>
                                <input
                                    type="text"
                                    placeholder="gst status"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="gst_status"
                                    value={currentAccounthead.gst_status}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.gst_status}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">GST Type</p>
                                <input
                                    type="text"
                                    placeholder="gst type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="tax_payer_type"
                                    value={currentAccounthead.tax_payer_type}
                                    onChange={onValueUpdate}
                                // {...register('tax_payer_type')}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.tax_payer_type}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Bussiness Type</p>
                                <input
                                    type="text"
                                    placeholder="bussiness type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                                    name="bussiness_type"
                                    value={currentAccounthead.bussiness_type}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.bussiness_type}</p> */}
                            </div>

                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}