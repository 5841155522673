import { itemMasterEndPoint, taggingEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { fixedCalcId, pergramCalId, weightCalId } from "../../../constant";
import { isValidJson } from "../../../utilities/jsonvalidation";

export default function ViewItemtagDetails() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('View');

    const [lotFormValues, setLotFormValues] = useState({
        lot_number: ""
    });
    console.log("err", lotFormValues)
    const [particularsList, setParticularsList] = useState([

    ]);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {

        getItemmasterDetails()
    }, [])

    const getItemmasterDetails = async () => {
        var response = await getAxios({
            url: taggingEndPoint + localStorage.getItem('itemtag_form_id') + "/"
        })

        if (response !== null) {
            setLotFormValues({
                lot_number: response.data.tag_entry_details?.lot_number,
                entry_type: response.data.tag_entry_details.entry_type,
                vendor_name: response.data.tag_entry_details.vendor_name,
                invoice_number: response.data.tag_entry_details.invoice_number,


            })

            let tempList = [];

            for (var item of response.data.tag_entry_details.item_details) {

                console.log("test", item)
            }


            for (var item of response.data.tag_entry_details.item_details) {
                var calcType = !item.calculation_type_name
                var dictItem = {
                    id: item.id,
                    tag_number: item.tag_number,
                    item_details: item.item_details.id,
                    item_details_name: item.item_details_name,
                    sub_item_details: item.is_subitem_detail ? item.subitem_details.id : null,
                    sub_item_details_name: item.sub_item_details_name ,
                    display_counter: item.is_subitem_detail ? item.sub_item_counter : item.item_counter,
                    is_subitem_detail: item.is_subitem_detail,
                    tag_pieces: item.tag_pieces,
                    gross_weight: item.gross_weight,
                    tag_type: item.tag_type,
                    net_weight: item.net_weight,
                    rough_sale_value: item.rough_sale_value,
                    calculation_type: !item.calculation_type_name,
                    min_fixed_rate: String(item.calculation_type) === String(fixedCalcId) ? item.min_fixed_rate : null,
                    wastage_calculation: String(item.calculation_type) === String(weightCalId) ? item.item_wastage_calculation : null,
                    min_wastage_percent: String(item.calculation_type) === String(weightCalId) ?  item.min_wastage_percent : null,
                    min_flat_wastage: String(item.calculation_type) === String(weightCalId) ?  item.min_flat_wastage : null,
                    min_making_charge_gram: String(item.calculation_type) === String(weightCalId) ? item.min_making_charge_gram : null,
                    min_flat_making_charge: String(item.calculation_type) === String(weightCalId) ? item.min_flat_making_charge : null,
                    min_flat_making_charge: String(item.calculation_type) === String(weightCalId) ? item.item_min_flat_makingcharge : null,
                    min_pergram_rate: String(item.calculation_type) === String(pergramCalId) ? item.item_min_pergram_rate : null,
                    max_fixed_rate: String(item.calculation_type) === String(fixedCalcId) ? item.max_fixed_rate : null,
                    wastage_calculation: String(item.calculation_type) === String(weightCalId) ? item.item_wastage_calculation : null,
                    max_wastage_percent: String(item.calculation_type) === String(weightCalId) ?  item.max_wastage_percent : null,
                    max_flat_wastage: String(item.calculation_type) === String(weightCalId) ?  item.max_flat_wastage : null,
                    max_making_charge_gram: String(item.calculation_type) === String(weightCalId) ? item.max_making_charge_gram : null,
                    max_flat_making_charge: String(item.calculation_type) === String(weightCalId) ? item.max_flat_making_charge : null,
                    max_flat_making_charge: String(item.calculation_type) === String(weightCalId) ? item.item_max_flat_makingcharge : null,
                    max_pergram_rate: String(item.calculation_type) === String(pergramCalId) ? item.max_pergram_rate : null,
                    metal_rate: item.is_subitem_detail ? item.sub_item_metalrate : item.item_metal_rate,
                    tagged_grossweight: item.tagged_grossweight,
                    tagged_pieces: item.tagged_pieces,
                    total_gross_weight: item.gross_weight,
                    total_pieces: item.pieces,
                    stone_details: item.stone_details,
                    diamond_details: item.diamond_details
                }
                tempList.push(dictItem)
            }


            setParticularsList(tempList)
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Item Details</p>
                <form>

                    <div className="w-full bg-white mb-[25px] border shadow-md rounded-md p-[15px]">
                        <p className="font-[500] text-[18px] text-menu-head">Lot Details </p>
                        <div className="w-full grid xl:grid-cols-4 sm:grid-cols-4 gap-5 mt-4">
                            <div className="w-full flex gap-2">
                                <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Lot Number :</p>
                                <p className="text-blue-500 font-[400] text-[14px]">{lotFormValues?.lot_number}</p>
                            </div>
                            <div className="w-full flex gap-2">
                                <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Net Wt :</p>
                                <p className="text-blue-500 font-[400] text-[14px]">{lotFormValues?.entry_type}</p>
                            </div>
                            <div className="w-full flex gap-2">
                                <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Pieces :</p>
                                <p className="text-blue-500 font-[400] text-[14px]">{lotFormValues?.vendor_name}</p>
                            </div>
                            <div className="w-full flex gap-2">
                                <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Stone Pieces :</p>
                                <p className="text-blue-500 font-[400] text-[14px]">{lotFormValues?.invoice_number}</p>
                            </div>

                        </div>

                    </div>




                </form>



                <div className="overflow-scroll">
                    <table className="w-full ">
                        <thead className="bg-table-head text-white  ">
                            <tr>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>S.No</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Tag Number</td>
                                <td className="p-[10px] border text-[13px] text-center min-w-[200px]" rowSpan={2}>Item Name</td>
                                <td className="p-[10px] border text-[13px] text-center min-w-[200px]" rowSpan={2}>SubItem name</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Pieces</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Gross Wt</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Net Wt</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Rough Sale Value</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Fixed Rate</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Wastage %</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Flat Wastage</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Making Charge <br /> (per g)</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Flat Making Charge</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Fixed Rate</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Wastage %</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Flat Wastage</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Making Charge <br /> (per g)</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Flat Making Charge</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Stone Rate</td>
                                <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Diamond Rate</td>
                                <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Stone</td>
                                <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Diamond</td>
                            </tr>
                            <tr>
                                <td className="p-[10px] border text-[13px] text-center" >pcs</td>
                                <td className="p-[10px] border text-[13px] text-center">wt</td>
                                <td className="p-[10px] border text-[13px] text-center">pcs</td>
                                <td className="p-[10px] border text-[13px] text-center">wt</td>
                            </tr>
                        </thead>

                        

                        <tbody className="text-primary-grey">
                            {console.log("fff", particularsList)}
                            {particularsList.map((item, index) => (
                                <tr key={index}>
                                    <td className="p-[10px] border text-center">{index + 1}</td>
                                    <td className="p-[10px] border">{item.tag_number}</td>
                                    <td className="p-[10px] border">{item.item_details_name}</td>
                                    <td className="p-[10px] border">{item.sub_item_details_name}</td>
                                    <td className="p-[10px] border">{item.tag_pieces}</td>
                                    <td className="p-[10px] border">{item.gross_weight}</td>
                                    <td className="p-[10px] border">{item.net_weight}</td>
                                    <td className="p-[10px] border text-center">{item.rough_sale_value}</td>
                                    <td className="p-[10px] border text-center">{item.min_fixed_rate ? item.min_fixed_rate : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.min_wastage_percent ? item.min_wastage_percent : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.min_flat_wastage ? item.min_flat_wastage : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.min_making_charge_gram ? item.min_making_charge_gram : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.min_flat_making_charge ? item.min_flat_making_charge : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.max_fixed_rate ? item.max_fixed_rate : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.max_wastage_percent ? item.max_wastage_percent : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.max_flat_wastage ? item.max_flat_wastage : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.max_making_charge_gram ? item.max_making_charge_gram : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.max_flat_making_charge ? item.max_flat_making_charge : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_rate ? item.total_stone_rate : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_rate ? item.total_diamond_rate : '-'}</td>
                                    <td className="p-[10px] border text-center">{item.total_stone_pieces}</td>
                                    <td className="p-[10px] border text-center">{item.stone_weight}</td>
                                    <td className="p-[10px] border text-center">{item.total_diamond_pieces}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>



            </div>

            <div className="flex items-center justify-end my-4">
            <button
                className="capitalize bg-primary-btn text-white w-fit rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                onClick={() => navigate(-1)}
            >Back <span className="lowercase">(Esc)</span></button>
            </div>
        </>
    );
}