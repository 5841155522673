import { useEffect, useState } from "react";
import LogoImage from "../../assets/images/logo.svg";
import { getAxios } from "../../service/apiservice";
import { activeUserPermissionEndPoint } from "../../service/api";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActions, setUnderMaintanance } from "../../redux/appconfigslice";
import { Icon } from "@iconify/react";
 
export default function MenuHeader({ isDark }) {

    const navigate = useNavigate();
 
    const { pathname } = useLocation();
 
    const [activeMenu, setActiveMenu] = useState('');
    const [activeMenuDropdown, setActiveMenuDropdown] = useState('');
 
    const [menuList, setMenuList] = useState([]);
    const [moreOption, setMoreOption] = useState(false);
 
    const dispatch = useDispatch();

    useEffect(() => {
        getMenuList()
    }, [])
 
    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })
 
        if (response !== null) {
            if (response.status === 200) {
                dispatch(setUnderMaintanance(response.data.maintanance))
                setMenuList(response.data)
            }
        }
    }
 
    useEffect(() => {
        highlightMenu()
        if (menuList.length > 0) {
            for (var i of menuList) {
                if (i.menu_group_name?.split(' ').join('-') === pathname.split('/')[1]) {
                    for (var j of i.menu_list) {
                        if (j.menu_path === pathname) {
                            dispatch(setActions({
                                viewPermit: j.view_permit,
                                addPermit: j.add_permit,
                                editPermit: j.edit_permit,
                                deletePermit: j.delete_permit
                            }))
                        }
                    }
                }
            }
        }
    }, [pathname, menuList])
 
    const highlightMenu = () => {
        for (var i of menuList) {
            if (i.menu_list !== undefined) {
                for (var j of i.menu_list) {
                    if (j.menu_path === pathname) {
                        setActiveMenuDropdown(i.id)
                        setActiveMenu('menu' + i.id)
                    }
                }
            }
        }
    }
 
 
    return (
        <>
            <div className={`${isDark ? "bg-dark-bg" : "bg-white md:border-b border-r"} md:max-h-[50px] h-[100vh]   w-[225px] md:w-full shadow-lg fixed z-[8] top-0 md:static`}>
                <div className="absolute top-0 -right-7 md:hidden"
                    onClick={() => {
                        document.getElementById('menubar').className = 'hidden md:block'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-6 -6 24 24">
                        <path fill="currentColor"
                            d="m7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485L2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535l3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z" />
                    </svg>
                </div>
                <div className="h-[55px] w-[125px] mx-auto md:hidden bg-white">
                    <img src={LogoImage} alt="no-logo" />
                </div>
                <div className='md:flex md:gap-12 md:overflow-x-scroll max-[768px]:overflow-y-scroll h-full px-[15px] py-[10px]'>
                    {
                        menuList
                            .map((item) => (
                                <div
                                    onMouseEnter={(e) => {
                                        var my_div = e.target;
                                        var rect = my_div.getBoundingClientRect();
                                        console.log("rect", rect);
                                        document.getElementById("menu" + item.id).style.display = 'block'
                                        document.getElementById("menu" + item.id).style.left = String(rect.x) + 'px'
                                        // document.getElementById("menu" + item.id).style.top = String(rect.top + 20) + 'px'
                                    }}
                                    onMouseLeave={() => {
                                        document.getElementById("menu" + item.id).style.display = 'none'
                                    }}
                                    key={item.id}
                                    className="my-3 md:my-0 md:pr-[50px]">
                                    <div className="flex items-center gap-1">
                                        <div
                                            className={`${activeMenuDropdown === item.id ? isDark ? "text-[#000000]" : "text-primary-btn" : ""} capitalize font-500 text-nowrap text-[15px] cursor-pointer`}
                                        >{item.menu_group_name}
                                        </div>
                                        <Icon className={`${activeMenuDropdown === item.id ? isDark ? "text-[#000000]" : "text-primary-btn" : ""} capitalize font-400 text-[12px] cursor-pointer`} icon="ep:arrow-down-bold" />
                                    </div>
                                    <div
                                        onClick={() => {
                                            document.getElementById('menubar').className = 'hidden md:block'
                                        }}
                                        id={"menu" + item.id}
                                        className={`${isDark ? "bg-[#041a37]" : "bg-white"} md:absolute p-[10px] border rounded-lg hidden z-[6]`}>
                                        {
                                            item.menu_list.map((subItem) => (
                                                <NavLink key={subItem.menu_path} to={subItem.menu_path}
                                                    className={({ isActive, isPending }) =>
                                                        isActive ? isDark ? "text-secondary" : "text-primary-btn" : ""
                                                    }>
                                                    <div
                                                        className="py-[5px] hover:text-primary-btn cursor-pointer capitalize"
                                                        key={subItem.id}
                                                    >
                                                        <p className="hover:bg-[#F1F5F9] rounded-lg !p-2">
                                                            {subItem.menu_name}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>
        </>
    );
}