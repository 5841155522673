import { useEffect, useState } from 'react';
import { branchListEndPoint } from '../../../service/api';
import { getAxios } from '../../../service/apiservice';

export default function PurificationRecieptFilters({ filters, setFilters, searchRef }) {

    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
        getBranchDropdownData();
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint,
        });

        if (response !== null) {
            setBranchList(response.data.list);
        }
    };

    const handleSearch = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            search: value
        }))
    }

    const handleBranchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            branch: value === "all" ? null : value
        }))
    }

    const handleFromDateFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            from_date: value
        }))
    }

    const handleToDateFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            to_date: value
        }))
    }

    const handleDateClear = (e) => {
        e.preventDefault();
        setFilters((prevState) => ({
            ...prevState,
            from_date: "",
            to_date: ""
        }))
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full">
                <div className='sm:flex justify-between lg:gap-3 w-full'>
                    <div class="flex gap-3 border rounded-xl px-[5px] overflow-hidden">
                        <div className="flex items-center pl-1 pointer-events-none">
                            <svg className="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            autoComplete="off"
                            id="searchfilter"
                            ref={searchRef}
                            className="py-[5px] outline-none w-[150px]"
                            placeholder="Search ..."
                            value={filters.search}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>

                {localStorage.getItem("branch") === "true" &&
                    <div className=''>
                        <select
                            placeholder="metal_type"
                            className="capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[150px] p-[6px] text-[14px] outline-none"
                            name="branch"
                            value={filters.branch}
                            onChange={(e) => handleBranchFilter(e.target.value)}
                        >
                            <option value="all">All</option>
                            {branchList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                    {option?.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>}

                <div className='w-full lg:w-[550px] border border-gray-300 rounded-lg flex gap-3 items-center overflow-hidden'>
                    <input
                        type="date"
                        placeholder="Melting Issue Id"
                        className="w-full p-[5px] text-[13px] outline-none"
                        name="from_date"
                        value={filters.from_date}
                        onChange={(e) => handleFromDateFilter(e.target.value)}
                    />
                    <input
                        type="date"
                        placeholder="Melting Issue Id"
                        className="w-full p-[5px] text-[13px] outline-none"
                        name="to_date"
                        value={filters.to_date}
                        onChange={(e) => handleToDateFilter(e.target.value)}
                    />
                    <button className='mr-[2px]' onClick={handleDateClear}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>
                    </button>
                </div>

            </div>
        </>
    );
}