import { customerEndPoint, customerGroupListEndPoint } from "../../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../../service/apiservice";
import { useEffect, useState } from "react";
import { useKeyPress } from "../../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { isOptionalValidForm } from "../../../../utilities/formvalidation";
import { defaultCustomerType } from "../../../../constant";

const optionalFields = ['gst_no', 'pan_card', 'aadhar_card', 'email', 'dob']

export default function AddCustomerForm({ mobile, branch, closeModal, setOrderDetails, getCustomerData }) {


    const [currentCustomer, setCurrentCustomer] = useState({
        phone: mobile,
        customer_group: defaultCustomerType
    });

    const [customerGroupDropDown, setCustomerGroupDropDown] = useState([]);

    const [errors, setErrors] = useState({});

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getCustomerGroupListData()
    }, [])

    const getCustomerGroupListData = async () => {
        var response = await getAxios({
            url: customerGroupListEndPoint
        })

        if (response !== null) {
            setCustomerGroupDropDown(response.data.list)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !optionalFields.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(formDataObject, optionalFields)) {
            let data = currentCustomer;
            data['branch'] = branch

            if (localStorage.getItem('branch') === 'true') {
                if (branch?.length > 0) {
                    createCustomer(currentCustomer)
                } else {
                    toast.error('branch is not selected')
                }
            } else {
                createCustomer(currentCustomer)
            }
            
        }
    }

    const createCustomer = async (body) => {
        var response = await postAxiosWithToken({
            url: customerEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            closeModal()
            setOrderDetails((state) => ({
                ...state,
                customer_details: response.data.id,
                customer_no: response.data.phone
            }))
            getCustomerData(currentCustomer.phone)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
        setCurrentCustomer((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <>
            <div className="h-[500px] overflow-y-scroll">
                <form onSubmit={onSubmit} className="">

                    <div className="p-[15px] mt-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Customer Details</p>
                        <div className="">
                            <div className="">
                                <p className="mb-[5px] text-[13px]">Customer Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="customer name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="customer_name"
                                    value={currentCustomer.customer_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.customer_name}</p>
                            </div>
                            <div className="">
                                <p className="mb-[5px] text-[13px]">Customer Group <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="customer group"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                                    name="customer_group"
                                    value={currentCustomer.customer_group}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select customer group--</option>
                                    {
                                        customerGroupDropDown.map((option) => (
                                            <option className="capitalize" key={"customer_group" + option.customer_group_name} value={option.id}>{option.customer_group_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.customer_group}</p>
                            </div>
                            <div className="">

                                <p className="mb-[5px] text-[13px]">Email</p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="email"
                                    value={currentCustomer.email}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="">
                                <p className="mb-[5px] text-[13px]">Phone No <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="phone"
                                    value={currentCustomer.phone}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.phone}</p>
                            </div>
                        </div>
                    </div>

                    <div className="p-[15px] my-[25px]  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Other Details</p>
                        <div className="">
                        <div className="">
                                <p className="mb-[5px] text-[13px]">GSTIN</p>
                                <input
                                    type="text"
                                    placeholder="gstin"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                                    name="gst_no"
                                    value={currentCustomer.gst_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); closeModal()}}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}