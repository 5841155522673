import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { branchListEndPoint, calculationtypeDropdownEndPoint, itemListByStockMetalEndPoint, metalListEndPoint, multiTagdetailsEndPoint, stocktypeDropdownEndPoint, subitemByMetalStockEndPoint, subitemListEndPoint, tagListEndPoint, tagdetailsEndPoint, valueAddtionCustomerEndPoint, weightTypeListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import Select from 'react-select';
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";
import { fixedCalcId, pergramCalId, weightCalId } from "../../../constant";
import toast from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

const fixedCalcTypeOptionalFields = ['wastage_calculation', 'making_charge_calculation', 'max_wastage_percent', 'max_flat_wastage', 'max_making_charge_gram', 'max_flat_making_charge', 'max_per_gram_rate'];
const pergramCalcTypeOptionalFields = ['wastage_calculation', 'making_charge_calculation', 'max_wastage_percent', 'max_flat_wastage', 'max_making_charge_gram', 'max_flat_making_charge', 'max_fixed_rate'];
const weightCalcTypeOptionalFields = ['max_fixed_rate', 'max_per_gram_rate'];

export default function ValueAdditionForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');

    const [metalList, setMetalList] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [calculationTypeList, setCalculationTypeList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [subItemList, setSubItemList] = useState([]);
    const [weightTypeList, setWeightTypeList] = useState([]);

    const [itemFormValues, setItemFormValues] = useState({});
    const [itemFormNewValues, setItemFormNewValues] = useState({});

    const [selectedMetal, setSelectedMetal] = useState('');
    const [selectedStockType, setSelectedStockType] = useState('');
    const [selectedCalculationType, setSelectedCalculationType] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const [branchDropdown, setBranchDropDown] = useState([]);

    const [selectedSubItems, setSelectedSubItems] = useState([]);

    const [errors, setErrors] = useState([]);

    const [filteredList, setFilteredList] = useState([]);

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formtwosubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        } else if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'e') {
            event.preventDefault();
            document.getElementById('resetone').click()
        } else if (event.key === 'z') {
            event.preventDefault();
            document.getElementById('resettwo').click()
        }
    };

    useKeyPress(['s', 'Escape', 'Enter', 'e', 'z'], onKeyPress);

    useEffect(() => {
        getMetalListData();
        getStockListData();
        getTagTypeListData();
        getCalculationTypeListData();
        getBranchDropdownData();

        setFormMode(localStorage.getItem("valueaddition_customer_form_mode"))

        if (localStorage.getItem("valueaddition_customer_form_mode") === 'update') {
            getDetails();
        }
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list)
        }
    }

    const getDetails = async () => {
        var response = await getAxios({
            url: valueAddtionCustomerEndPoint + localStorage.getItem('valueaddition_customer_form_id') + '/'
        })

        if (response !== null) {
            setItemFormValues((prevState) => ({
                ...prevState,
                metal: response.data.metal,
                metal_name: response.data.metal_name,
                stock_type: response.data.stock_type,
                stock_type_name: response.data.stock_type_name,
                calculation_type: response.data.calculation_type,
                calculation_type_name: response.data.calculation_type_name,
                tag_type: response.data.tag_type,
                tag_type_name: response.data.tag_type_name,
                sub_item_details: response.data.sub_item_details,
                from_weight: response.data.from_weight,
                to_weight: response.data.to_weight
            }))

            setSelectedMetal(response.data.metal)
            setSelectedStockType(response.data.stock_type)
            setSelectedCalculationType(response.data.calculation_type)

            setSelectedSubItems([{ value: response.data.sub_item_details, label: response.data.sub_item_details_name }])

            if (response.data.calculation_type.toString() === fixedCalcId.toString()) {
                setItemFormNewValues((prevState) => ({
                    ...prevState,
                    max_fixed_rate: response.data.max_fixed_rate
                }))
            } else if (response.data.calculation_type.toString() === weightCalId.toString()) {
                setItemFormNewValues((prevState) => ({
                    ...prevState,
                    max_wastage_percent: response.data.max_wastage_percent,
                    max_flat_wastage: response.data.max_flat_wastage,
                    max_making_charge_gram: response.data.max_making_charge_gram,
                    max_flat_making_charge: response.data.max_flat_making_charge
                }))
            } else if (response.data.calculation_type.toString() === pergramCalId.toString()) {
                setItemFormNewValues((prevState) => ({
                    ...prevState,
                    max_per_gram_rate: response.data.max_per_gram_rate
                }))
            }
        }
    }

    const getMetalListData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalList(response.data.list)
        }
    }

    const getStockListData = async () => {
        var response = await getAxios({
            url: stocktypeDropdownEndPoint
        })

        if (response !== null) {
            setStockList(response.data.list)
        }
    }

    const getCalculationTypeListData = async () => {
        var response = await getAxios({
            url: calculationtypeDropdownEndPoint

        })

        if (response !== null) {
            setCalculationTypeList(response.data.list)
        }
    }

    const getTagTypeListData = async () => {
        var response = await getAxios({
            url: tagListEndPoint
        })

        if (response !== null) {
            setTagList(response.data.list)
        }
    }

    const getSubItemListData = async () => {
        var response = await postAxiosWithToken({
            url: subitemByMetalStockEndPoint,
            body: {
                metal: selectedMetal,
                stock_type: selectedStockType,
                calculation_type: selectedCalculationType
            }
        })

        if (response !== null) {
            let tempList = [];
            for (var i of response.data.list) {
                var tempDict = {
                    value: i.id,
                    label: i.sub_item_name
                };
                tempList.push(tempDict);
            }
            setSubItemList(tempList)
        }
    }

    const getWeightTypeListData = async () => {
        var response = await getAxios({
            url: weightTypeListEndPoint

        })

        if (response !== null) {
            setWeightTypeList(response.data.list)
        }
    }

    useEffect(() => {

        if (selectedMetal.length !== 0 && selectedStockType.length !== 0 && selectedCalculationType.length !== 0) {
            getSubItemListData();
        }

    }, [selectedMetal, selectedStockType, selectedCalculationType])

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());
        formDataObject['sub_item_details'] = selectedSubItems
        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }


        setErrors(err)


        if (isValidForm(formDataObject)) {
            getTagDetails(formDataObject);
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setItemFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        if (name === 'metal') {
            setSelectedMetal(value)
        } else if (name === 'stock_type') {
            setSelectedStockType(value)
        } else if (name === 'calculation_type') {
            if (value.toString() === weightCalId.toString()) {
                getWeightTypeListData()
            }
            setSelectedCalculationType(value)
        } else if (name === 'item_details') {
            if (value !== "") {
                getSubItemListData(selectedMetal, value);
            } else {
                setSubItemList([])
            }
            setSelectedItem(value)
        } else if (name === 'sub_item_details') {
            setSelectedSubItems(value)
        }

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length !== 0 ? '' : `${name} filed is required`
        }))
    }

    const onNewValueUpdate = (e) => {
        const { name, value } = e.target;

        setItemFormNewValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length !== 0 ? '' : `${name} filed is required`
        }))
    }

    const getTagDetails = async () => {
        let body = itemFormValues
        var tempList = []
        for (var i of selectedSubItems) {
            tempList.push(i.value)
        }
        body['sub_item_details'] = tempList
        var response = await postAxiosWithToken({
            url: multiTagdetailsEndPoint,
            body: body
        })

        if (response !== null) {
            setFilteredList(response.data.list)
        }
    }

    const handleUpdate = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());
        formDataObject['sub_item_list'] = selectedSubItems
        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                if (selectedCalculationType.toString() === fixedCalcId.toString()) {
                    if (!fixedCalcTypeOptionalFields.includes(field[0])) {
                        err[field[0]] = `${field[0]} field is required`
                    }
                } else if (selectedCalculationType.toString() === weightCalId.toString()) {
                    if (!weightCalcTypeOptionalFields.includes(field[0])) {
                        err[field[0]] = `${field[0]} field is required`
                    }
                } else if (selectedCalculationType.toString() === pergramCalId.toString()) {
                    if (!pergramCalcTypeOptionalFields.includes(field[0])) {
                        err[field[0]] = `${field[0]} field is required`
                    }
                } else {
                    err[field[0]] = `${field[0]} field is required`
                }
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        let optionalFields = []

        if (selectedCalculationType.toString() === fixedCalcId.toString()) {
            optionalFields = fixedCalcTypeOptionalFields
        } else if (selectedCalculationType.toString() === weightCalId.toString()) {
            optionalFields = weightCalcTypeOptionalFields
        } else if (selectedCalculationType.toString() === pergramCalId.toString()) {
            optionalFields = pergramCalcTypeOptionalFields
        }

        let body = { ...itemFormValues, ...formDataObject }
        var tempList = []
        for (var i of selectedSubItems) {
            tempList.push(i.value)
        }
        body['sub_item_list'] = tempList

        if (isOptionalValidForm(formDataObject, optionalFields)) {
            if (formMode === 'create') {
                createValueAddition(body);
            } else {
                body['sub_item_details'] = body.sub_item_list[0]
                updateValueAddition(body);
            }
        }
    }

    const createValueAddition = async (body) => {
        var response = await postAxiosWithToken({
            url: valueAddtionCustomerEndPoint,
            body: body
        })

        if (response !== null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updateValueAddition = async (body) => {
        var response = await putAxiosWithToken({
            url: valueAddtionCustomerEndPoint + localStorage.getItem('valueaddition_customer_form_id') + '/',
            body: body
        })

        if (response !== null) {
            toast.success(response.message)
            navigate(-1)
        }
    }


    const resetFormOne = () => {
        setItemFormValues({
            metal: "",
            stock_type: "",
            calculation_type: "",
            tag_type: "",
            item_details: "",
            from_weight: "",
            to_weight: ""
        })
        setSelectedSubItems([])
    }

    const resetFormTwo = () => {
        setItemFormNewValues({
            max_fixed_rate: "",
            max_per_gram_rate: "",
            max_wastage_percent: "",
            max_flat_wastage: "",
            max_making_charge_gram: "",
            max_flat_making_charge: ""
        })
    }

    return (
        <div className="mb-[50px]">
            <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Valueaddition</p>

            <form onSubmit={handleSubmit}>
                <div className="border p-[15px] rounded-xl shadow-lg">
                    <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Valueaddition Details</p>
                    <div className="grid xl:grid-cols-5 sm:grid-cols-2 gap-5">

                        {formMode === 'create' ? localStorage.getItem("branch") === "true" && <div className="sm:my-[5px]">
                            <p className="mb-[5px] text-[15px]">Branch Name</p>
                            <select
                                placeholder="branch name"
                                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="branch"
                                value={itemFormValues.branch}
                                onChange={onValueUpdate}
                            >
                                <option value={""} className="capitalize">--select branch name--</option>
                                {
                                    branchDropdown.map((option) => (
                                        <option className="capitalize" key={option.id} value={option.id}>{option.branch_name}</option>
                                    ))
                                }
                            </select>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                        </div> : localStorage.getItem("branch") === "true" && <p>{itemFormValues.branch}</p>}
                        <div className="sm:my-[5px]">
                            <p className="mb-[5px] text-[15px]">Metal Name</p>
                            {formMode === 'create' ? <select
                                placeholder="metal name"
                                className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="metal"
                                onChange={onValueUpdate}
                                value={itemFormValues.metal}
                            >
                                <option value={""}>--select metal name--</option>
                                {
                                    metalList.map((option) => (
                                        <option key={option.id} value={option.id}>{option.metal_name}</option>
                                    ))
                                }
                            </select> : <p>{itemFormValues.metal_name}</p>}
                            <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                        </div>

                        <div className="sm:my-[5px]">
                            <p className="mb-[5px] text-[15px]">Stock Type</p>
                            {formMode === 'create' ? <select
                                placeholder="stock type"
                                className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="stock_type"
                                onChange={onValueUpdate}
                                value={itemFormValues.stock_type}
                            >
                                <option value={""}>--select Stock Type--</option>
                                {
                                    stockList.map((option) => (
                                        <option key={option.id} value={option.id}>{option.stock_type_name}</option>
                                    ))
                                }
                            </select> : <p>{itemFormValues.stock_type_name}</p>}
                            <p className="text-red-500 font-[400] text-[13px]">{errors.stock_type}</p>
                        </div>

                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Calculation Type</p>
                            {formMode === 'create' ? <select
                                placeholder="calculation type"
                                className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="calculation_type"
                                onChange={onValueUpdate}
                                value={itemFormValues.calculation_type}
                            >
                                <option value={""}>--select calculation type--</option>
                                {
                                    calculationTypeList?.map((option) => (
                                        <option key={option.id} value={option.id}>{option.calculation_name}</option>
                                    ))
                                }
                            </select> : <p>{itemFormValues.calculation_type_name}</p>}
                            <p className="text-red-500 font-[400] text-[13px]">{errors.calculation_type}</p>
                        </div>

                        <div className="sm:my-[5px]">
                            <p className="mb-[5px] text-[15px]">Tag Type</p>
                            {formMode === 'create' ? <select
                                placeholder="tag type"
                                className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="tag_type"
                                onChange={onValueUpdate}
                                value={itemFormValues.tag_type}
                            >
                                <option value={""}>--select Tag Type--</option>
                                {
                                    tagList.map((option) => (
                                        <option key={option.id} value={option.id}>{option.tag_name}</option>
                                    ))
                                }
                            </select> : <p>{itemFormValues.tag_type_name}</p>}
                            <p className="text-red-500 font-[400] text-[13px]">{errors.tag_type}</p>
                        </div>

                        {/* <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Item</p>
                            <select
                                placeholder="item name"
                                className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="item_details"
                                onChange={onValueUpdate}
                                value={itemFormValues.item_details}
                            >
                                <option value={""}>--select item--</option>
                                {
                                    itemList.map((option) => (
                                        <option key={option.id} value={option.id}>{option.item_name}</option>
                                    ))
                                }
                            </select>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.item_details}</p>
                        </div> */}
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Sub Item</p>
                        <Select
                            value={selectedSubItems}
                            onChange={(e) => { onValueUpdate({ target: { value: e, name: 'sub_item_details' } }) }}
                            options={subItemList}
                            isMulti
                            isSearchable
                            name="sub_item_details"
                            isDisabled={formMode === 'update' ? true : false}
                        />
                        {/* <select
                            placeholder="sub item"
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="sub_item_details"
                        >
                            <option value={""}>--select sub item--</option>
                            {
                                subItemList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.sub_item_name}</option>
                                ))
                            }
                        </select> */}
                        <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item_details}</p>
                    </div>

                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-5 mt-[15px]">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Weight from</p>
                            {formMode === 'create' ? <input
                                type="number"
                                min="0"
                                step={0.01}
                                placeholder="Weight from"
                                className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[8px] text-[17px] outline-none"
                                name="from_weight"
                                onChange={onValueUpdate}
                                value={itemFormValues.from_weight}
                            /> : <p>{itemFormValues.from_weight}</p>}
                            <p className="text-red-500 font-[400] text-[13px]">{errors.from_weight}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Weight To</p>
                            {formMode === 'create' ? <input
                                type="number"
                                min="0"
                                step={0.01}
                                placeholder="Weight To"
                                className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[8px] text-[17px] outline-none"
                                name="to_weight"
                                onChange={onValueUpdate}
                                value={itemFormValues.to_weight}
                            /> : <p>{itemFormValues.to_weight}</p>}
                            <p className="text-red-500 font-[400] text-[13px]">{errors.to_weight}</p>
                        </div>
                        <div className="min-[423px]:flex justify-end gap-4 sm:mt-0 lg:mt-[33px]">
                            <button
                                type="submit"
                                id="formsubmit"
                                className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >Get Details<span className="lowercase">(enter)</span></button>
                            <button
                                onClick={(e) => { e.preventDefault(); resetFormOne(); }}
                                id="resetone"
                                className="max-[423px]:mt-[10px] capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >Reset<span className="lowercase">(ctrl+e)</span></button>
                        </div>
                    </div>
                </div>
            </form>

            {itemFormValues.calculation_type && <form onSubmit={handleUpdate} className="mt-[15px]">
                <div className="border p-[15px] rounded-xl shadow-lg">
                    <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Edit Details</p>
                    <div className="grid xl:grid-cols-4 sm:grid-cols-3 gap-5">
                        <div className={`${itemFormValues.calculation_type?.toString() === fixedCalcId.toString() ? 'block' : 'hidden'} my-[5px]`}>
                            <p className="mb-[5px] text-[15px]">Fixed Rate</p>
                            <input
                                type="number"
                                min="0"
                                step={0.01}
                                placeholder="Fixed Rate"
                                className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[8px] text-[17px] outline-none"
                                name="max_fixed_rate"
                                onChange={onNewValueUpdate}
                                value={itemFormNewValues.max_fixed_rate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_fixed_rate}</p>
                        </div>

                        <div className={`${itemFormValues.calculation_type?.toString() === pergramCalId.toString() ? 'block' : 'hidden'} my-[5px]`}>
                            <p className="mb-[5px] text-[15px]">Per Gram Rate</p>
                            <input
                                type="number"
                                min="0"
                                step={0.01}
                                placeholder="Per Gram Rate"
                                className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[8px] text-[17px] outline-none"
                                name="max_per_gram_rate"
                                onChange={onNewValueUpdate}
                                value={itemFormNewValues.max_per_gram_rate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_per_gram_rate}</p>
                        </div>

                        <div className={`${itemFormValues.calculation_type?.toString() === weightCalId.toString() ? 'block' : 'hidden'} my-[5px]`}>
                            <p className="mb-[5px] text-[15px]">Wastage %</p>
                            <input
                                type="text"
                                placeholder="Wastage % "
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="max_wastage_percent"
                                onChange={onNewValueUpdate}
                                value={itemFormNewValues.max_wastage_percent}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_wastage_percent}</p>
                        </div>

                        <div className={`${itemFormValues.calculation_type?.toString() === weightCalId.toString() ? 'block' : 'hidden'} my-[5px]`}>
                            <p className="mb-[5px] text-[15px]">Flat Wastage (₹)</p>
                            <input
                                type="text"
                                placeholder="Flat Wastage (₹)"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="max_flat_wastage"
                                onChange={onNewValueUpdate}
                                value={itemFormNewValues.max_flat_wastage}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_flat_wastage}</p>
                        </div>

                        <div className={`${itemFormValues.calculation_type?.toString() === weightCalId.toString() ? 'block' : 'hidden'} my-[5px]`}>
                            <p className="mb-[5px] text-[15px]">MC / gram(₹)</p>
                            <input
                                type="text"
                                placeholder="MC / gram(₹)"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="max_making_charge_gram"
                                onChange={onNewValueUpdate}
                                value={itemFormNewValues.max_making_charge_gram}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_making_charge_gram}</p>
                        </div>

                        <div className={`${itemFormValues.calculation_type?.toString() === weightCalId.toString() ? 'block' : 'hidden'} my-[5px]`}>
                            <p className="mb-[5px] text-[15px]">Flac MC (₹)</p>
                            <input
                                type="text"
                                placeholder="Flac MC (₹)"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="max_flat_making_charge"
                                onChange={onNewValueUpdate}
                                value={itemFormNewValues.max_flat_making_charge}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_flat_making_charge}</p>
                        </div>

                        <div className="min-[423px]:flex justify-end gap-4 sm:mt-0 lg:mt-[33px]">
                            <button
                                type="submit"
                                id="formtwosubmit"
                                className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >Save <span className="lowercase">(ctrl+s)</span></button>
                            <button
                                onClick={(e) => { e.preventDefault(); resetFormTwo(); }}
                                id="resettwo"
                                className="max-[423px]:mt-[10px] capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >Reset<span className="lowercase">(ctrl+z)</span></button>
                        </div>
                    </div>
                </div>
            </form>
            }

            <div className="w-full mt-[15px] mb-[50px] overflow-x-scroll">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="p-[10px]">S.No</td>
                            <td className="p-[10px]">Tag Number</td>
                            <td className="p-[10px]">Metal</td>
                            <td className="p-[10px]">Stock Type</td>
                            <td className="p-[10px]">Tag Type</td>
                            <td className="p-[10px]">Item</td>
                            <td className="p-[10px]">Sub Item</td>
                            <td className="p-[10px]">Calculation Type</td>
                            <td className="p-[10px]">Gross Weight</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            filteredList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                filteredList.map((item, index) => (
                                    <tr key={item.id} className="border">
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px]">{item.tag_number}</td>
                                        <td className="p-[10px] capitalize">{item.metal_name}</td>
                                        <td className="p-[10px] capitalize">{item.stock_type_name}</td>
                                        <td className="p-[10px] capitalize">{item.tag_type_name}</td>
                                        <td className="p-[10px] capitalize">{item.item_details_name}</td>
                                        <td className="p-[10px] capitalize">{item.sub_item_details_name}</td>
                                        <td className="p-[10px]">{item.calculation_type_name}</td>
                                        <td className="p-[10px]">{item.gross_weight}</td>
                                    </tr>
                                )
                                )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}