import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { floorEndPoint, repairtypeEndPoint } from "../../../service/api";
import { postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function Repairtypeform({ getData, formMode, currentRepairtype, setFormMode, setCurrentRepairtype }) {


    const schema = yup
        .object({
            repair_type_name: yup.string().required(),
            min_customer_charges: yup.string().required(),
            max_customer_charges: yup.string().required(),
            min_vendor_charges: yup.string().required(),
            max_vendor_charges: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            repair_type_name: currentRepairtype.repair_type_name,
            min_customer_charges: currentRepairtype.min_customer_charges,
            max_customer_charges: currentRepairtype.max_customer_charges,
            min_vendor_charges: currentRepairtype.min_vendor_charges,
            max_vendor_charges: currentRepairtype.max_vendor_charges,
        } : {
            repair_type_name: "",
            min_customer_charges: "",
            max_customer_charges: "",
            min_vendor_charges: "",
            max_vendor_charges: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    useEffect(() => {
    }, [])

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createRepairType(data)
            } else {
                updateRepairType(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createRepairType = async (body) => {
        var response = await postAxiosWithToken({
            url: repairtypeEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateRepairType = async (body) => {
        var response = await putAxiosWithToken({
            url: repairtypeEndPoint + currentRepairtype?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentRepairtype({
            repair_type_name: "",
            min_customer_charges: "",
            max_customer_charges: "",
            min_vendor_charges: "",
            max_vendor_charges: "",
        })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg shadow border mt-5">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Repair Type</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-5 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Repair Type Name <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            placeholder="Repair type"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="repair_type_name"
                            id="forminit"
                            {...register('repair_type_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.repair_type_name?.message}</p>
                    </div>


                    <div className="col-span-3">
                        <p className="mb-[5px] text-[16px]">Repair Charges</p>
                        <div className="flex gap-5">
                        <p className="mb-[5px] text-[16px] w-[150px] flex items-center">Customer</p>
                        <div className="flex gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Minimum Charges</p>
                                <input
                                    type="text"
                                    placeholder="minimum charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="min_customer_charges"
                                    id="forminit"
                                    {...register('min_customer_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.min_customer_charges?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Maximum Charges</p>
                                <input
                                    type="text"
                                    placeholder="maximum charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="max_customer_charges"
                                    id="forminit"
                                    {...register('max_customer_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.max_customer_charges?.message}</p>
                            </div>
                            </div>
                        </div>
                        <div className="flex gap-5">
                        <p className="mb-[5px] text-[16px] w-[150px] flex items-center">Vendor</p>
                        <div className="flex gap-5">
                            <div className="my-[5px]">
                                <input
                                    type="text"
                                    placeholder="minimum charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="min_vendor_charges"
                                    id="forminit"
                                    {...register('min_vendor_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.min_vendor_charges?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <input
                                    type="text"
                                    placeholder="maximum charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="max_vendor_charges"
                                    id="forminit"
                                    {...register('max_vendor_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.max_vendor_charges?.message}</p>
                            </div>
                            </div>
                        </div>
                    </div>



                    <div className="min-[423px]:flex gap-4 sm:mt-0 xl:mt-[35px] items-center">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}