import AccountGroup from "../pages/books/accountgroup";
import AccountHead from "../pages/books/accounthead";
import AccountHeadForm from "../pages/books/accounthead/accountheadform";
import Company from "../pages/books/company";
import CompanyForm from "../pages/books/company/companyform";
import CompanyDetails from "../pages/books/company/companyview";
import Counter from "../pages/infrastructure/counter";
import Floor from "../pages/infrastructure/floor";
import Tag from "../pages/masters/Tag";
import Taxs from "../pages/masters/Taxs";
import TaxsForm from "../pages/masters/Taxs/Taxsform";
import Caratrate from "../pages/masters/Caratrate";
import CaratrateForm from "../pages/masters/Caratrate/Caratrateform";
import Metal from "../pages/masters/metal";
import MetalRates from "../pages/masters/metalrates";
import Purity from "../pages/masters/purity";
import Rangestock from "../pages/masters/rangestock";
import Standard from "../pages/masters/standard";
import Branch from "../pages/organization/branches";
import Department from "../pages/organization/department";
import Designation from "../pages/organization/designation";
import Locations from "../pages/organization/locations";
import Staff from "../pages/organization/staff";
import StaffForm from "../pages/organization/staff/staffform";
import Subitem from "../pages/productmaster/subitem";
import SubitemForm from "../pages/productmaster/subitem/subitemform";
import Itemmaster from "../pages/productmaster/itemmaster";
import ItemmasterForm from "../pages/productmaster/itemmaster/itemmasterform";
import User from "../pages/organization/user";
import UserForm from "../pages/organization/user/userform";
import Menu from "../pages/settings/menu";
import MenuGroup from "../pages/settings/menugroup";
import MenuPermission from "../pages/settings/menupermission";
import UserRole from "../pages/settings/userrole";
import AccountHeadDetails from "../pages/books/accounthead/accountheadview";
import Lot from "../pages/stock/lot";
import LotForm from "../pages/stock/lot/lotform";
import Itemtag from "../pages/stock/itemtag";
import Estimatebilling from "../pages/billing/estimatebilling";
import ItemTagForm from "../pages/stock/itemtag/itemtagform";
import Valueaddition from "../pages/valueaddition/valueadditioncustomer";
import ViewItemDetails from "../pages/productmaster/itemmaster/viewitemdetails";
import ViewSubItemDetails from "../pages/productmaster/subitem/viewsubitemdetails";
import Tagupdate from "../pages/stock/Tagupdate";
import TagdetailsForm from "../pages/stock/tagdetails";
import ValueAdditionForm from "../pages/valueaddition/valueadditioncustomer/valueadditioncusotmerform";
import { Dashboard } from "../pages/dashboard";
import ValueAdditionDesignForm from "../pages/valueaddition/valueadditiondesigner/valueadditiondesignerform";
import ValueadditionDesigner from "../pages/valueaddition/valueadditiondesigner";
import Customer from "../pages/customer/customer";
import CustomerForm from "../pages/customer/customer/customerform";
import Invoice from "../pages/billing/invoice";
import AccountpaymentForm from "../pages/books/advancepayment/advancepaymentform";
import Advancepayment from "../pages/books/advancepayment";
import AdvancepaymentForm from "../pages/books/advancepayment/advancepaymentform";
import CustomergroupForm from "../pages/customer/customergroup/customergroupform";
import Customergroup from "../pages/customer/customergroup";
import DublicatetagForm from "../pages/stock/dublicatetag/dublicatetagform";
import Dublicatetag from "../pages/stock/dublicatetag";
import Stocklist from "../pages/stock/stocklist";
import Approvaltype from "../pages/approval/approvaltype";
import Approvalrule from "../pages/approval/approvelrule";
import Orderdetails from "../pages/order/orderdetails";
import OrderdetailsForm from "../pages/order/orderdetails/orderdetailsform";
import Estimationapproval from "../pages/billing/estimationapproval";
import EstimationApprovalForm from "../pages/billing/estimationapproval/estimationapprovalform";
import StockTransfer from "../pages/stock/stocktransfer";
import StockTranferForm from "../pages/stock/stocktransfer/stocktransferform";
import ItemRecieverForm from "../pages/stock/stocktransfer/itemreciever/itemrecieverform";
import ItemReturnForm from "../pages/stock/stocktransfer/itemreciever/itemreturnform";
import AppConfiguration from "../pages/appconfig/settings";
import ViewLot from "../pages/stock/lot/viewlot";
import Salereport from "../pages/reports/salereport";
import Itemwisereport from "../pages/reports/itemstockreport";
import Tagreport from "../pages/reports/tagreport";
import SaleDetailreport from "../pages/reports/saledetailreport";
import ViewItemtagDetails from "../pages/stock/itemtag/itemtagdetails";
import PurchaseEntry from "../pages/purchase/oldpurchase";
import Purchaseentryform from "../pages/purchase/oldpurchase/purchaseentryform";
import Repairtype from "../pages/masters/repairtype";
import RepairCreationForm from "../pages/repairmanagement/repaircreation/repaircreationform";
import Repaircreation from "../pages/repairmanagement/repaircreation";
import ViewRangeStock from "../pages/masters/rangestock/viewrangestock";
import ViewCustomer from "../pages/customer/customer/viewcustomer";
import CounterTarget from "../pages/target/countertarget";
import ViewOrderDetails from "../pages/order/orderdetails/vieworderdetails";
import VendorPayments from "../pages/paymentss/vendorpayments";
import VendorPaymentsForm from "../pages/paymentss/vendorpayments/vendorpaymentform";
import TransferCreation from "../pages/refinerymanagement/transfercreation";
import MeltingIssue from "../pages/refinerymanagement/meltingissue";
import MeltingReceipt from "../pages/refinerymanagement/meltingreceipt";
import PurificationIssue from "../pages/refinerymanagement/purificationissue";
import PurificationReceipt from "../pages/refinerymanagement/purificationreceipt";
import NewPurchase from "../pages/purchase/newpurchase";
import NewPurchaseForm from "../pages/purchase/newpurchase/newpurchaseform";
import MiscIssue from "../pages/billing/miscissue";
import RequisitionApproval from "../pages/jewelleryrequisition/requisitionapproval";
import RequisitionApprovalForm from "../pages/jewelleryrequisition/requisitionapproval/requisitionapprovalform";
import ViewRequisitionAproval from "../pages/jewelleryrequisition/requisitionapproval/viewrequisitionapproval";
import SalesDailyReport from "../pages/reports/salesdailyreport";
import SalesByCustomerReport from "../pages/reports/salesbycustomer";
import ItemWiseSaleReport from "../pages/reports/itemwisesalereport";
import LOTReport from "../pages/reports/lotreport";
import VendorwiseReport from "../pages/reports/vendorwisereport";
import StockSummaryReport from "../pages/reports/stocksummaryreport";
import StockTagWiseReport from "../pages/reports/stocktagwisereport";
import PurchaseReport from "../pages/reports/purchasereport";
import ItemWisePurchaseReport from "../pages/reports/itemwisepurchasereport";
import VendorwisePurchaseReport from "../pages/reports/vendorwisepurchasereport";
import OldPurchaseReport from "../pages/reports/oldpurchasereport";
import SaleDetailsReport from "../pages/reports/salesdetailsreport";
import RequisitionReciept from "../pages/jewelleryrequisition/requisitionapproval/requisitionreceipt";
import IssueCustomerreport from "../pages/reports/repairissuecustomer";
import IssueVendorreport from "../pages/reports/repairissuevendor";
import CustomerSalereport from "../pages/reports/customersalereport";
import DemoForm from "../pages/stock/singletag/singletagform";
import Demotag from "../pages/stock/singletag";
import SingletagForm from "../pages/stock/singletag/singletagform";
import SingleTag from "../pages/stock/singletag";
import MainMenu from "../pages/settings/mainmenu";


export const AddedAuthenticatedRoutes = [
    {
        path: '/',
        component: <Dashboard />
    },
    {
        path: '/settings',
        component: <AppConfiguration />
    },
    {
        path: "/productmaster/itemmaster/itemmaster-form",
        component: <ItemmasterForm />
    },
    {
        path: "/productmaster/itemmaster/view-item-details",
        component: <ViewItemDetails />
    },
    {
        path: "/productmaster/subitem/subitem-form",
        component: <SubitemForm />
    },
    {
        path: "/productmaster/subitem/view-sub-item-details",
        component: <ViewSubItemDetails />
    },
    {
        path: "/organization/staff/staff-form",
        component: <StaffForm />
    },
    {
        path: "/organization/user/user-form",
        component: <UserForm />
    },
    {
        path: "/books/company/company-form",
        component: <CompanyForm />
    },
    {
        path: "/books/company/company-details",
        component: <CompanyDetails />
    },
    {
        path: "/books/account-head/account-head-form",
        component: <AccountHeadForm />
    },
    {
        path: "/books/account-head/accounthead-details",
        component: <AccountHeadDetails />
    },
    {
        path: "/masters/tax/tax-form",
        component: <TaxsForm />
    },
    {
        path: "/masters/caratrate/caratrate-form",
        component: <CaratrateForm />
    },
    {
        path: "/stock/lot/lot-form",
        component: <LotForm />
    },
    {
        path: "/stock/lot/lot-view",
        component: <ViewLot />
    },
    {
        path: "/stock/itemtag/itemtag-form",
        component: <ItemTagForm />
    },
    {
        path: "/valueaddition/valueaddition-customer/valueaddition-customer-form",
        component: <ValueAdditionForm />
    },
    {
        path: "/valueaddition/valueaddition-designer/valueaddition-designer-form",
        component: <ValueAdditionDesignForm />
    },
    {
        path: "/books/customer/customer-form",
        component: <CustomerForm />
    },
    {
        path: "/books/customer/view-customer/:id",
        component: <ViewCustomer />
    },
    {
        path: "/books/customergroup/customergroup-form",
        component: <CustomergroupForm />
    },
    {
        path: "/books/advance-payment/advancepayment-form",
        component: <AdvancepaymentForm />
    },
    {
        path: "/stock/duplicate-tag/duplicatetag-form",
        component: <DublicatetagForm />
    },
    {
        path: "/order/order-details/orderdetails-create",
        component: <OrderdetailsForm />
    },
    {
        path: "/order/order-details/orderdetails-update/:id",
        component: <OrderdetailsForm />
    },
    {
        path: "/order/order-details/view-orderdetails/:id",
        component: <ViewOrderDetails />
    },
    {
        path: "/billing/estimation-approval/estimationapproval-form/:id/",
        component: <EstimationApprovalForm />
    },
    {
        path: "/stock/stock-transfer/stock-transfer-form",
        component: <StockTranferForm />
    },
    {
        path: "/stock/stock-transfer/stock-reciever-form",
        component: <ItemRecieverForm />
    },
    {
        path: "/stock/stock-transfer/stock-return-form",
        component: <ItemReturnForm />
    },
    {
        path: "/stock/itemtag/view-itemtag-details",
        component: <ViewItemtagDetails />
    },
    {
        path: "/purchase/old-purchase/old-purchase-form",
        component: <Purchaseentryform />
    },
    {
        path: "/purchase/old-purchase",
        component: <PurchaseEntry />
    },
    {
        path: "/wholesale/new-purchase",
        component: <NewPurchase />
    },
    {
        path: "/wholesale/new-purchase/new-purchase-form",
        component: <NewPurchaseForm />
    },
    {
        path: "/repair-management/repair-creation/repair-creation-form/:id",
        component: <RepairCreationForm />
    },
    {
        path: "/repair-management/repair-creation/repair-creation-form/",
        component: <RepairCreationForm />
    },
    {
        path: "/masters/rangestock/view-range-stock/:id",
        component: <ViewRangeStock />
    },
    {
        path: "/wholesale/vendor-payment/vendor-payment-create",
        component: <VendorPaymentsForm />
    },
    {
        path: "/payment/vendor-payment/vendor-payment-update/:id",
        component: <VendorPaymentsForm />
    },
    {
        path: "/stock/approval-issue/requisition-approval-create",
        component: <RequisitionApprovalForm />
    },
    {
        path: "/stock/approval-issue/requisition-approval-update/:id",
        component: <RequisitionApprovalForm />
    },
    {
        path: "/stock/approval-issue/view-requisition-approval/:id",
        component: <ViewRequisitionAproval />
    },
    {
        path: "/stock/approval-issue/requisition-reciept/:id",
        component: <RequisitionReciept />
    },
    {
        path: "/stock/single-itemtag/demoitem-tag",
        component: <SingletagForm />
    },
]


export const AuthenticatedComponents =  [
    {
        path: "menu-permission",
        component: <MenuPermission />
    },
    {
        path: "user-role",
        component: <UserRole />
    },
    {
        path: "menu",
        component: <Menu />
    },
    // {
    //     path: "menu-group",
    //     component: <MenuGroup />
    // },
    {
        path: "menu-group",
        component: <MainMenu />
    },
    {
        path: "locations",
        component: <Locations />
    },
    {
        path: "branch",
        component: <Branch />
    },
    {
        path: "department",
        component: <Department />
    },
    {
        path: "designation",
        component: <Designation />
    },
    {
        path: "staff",
        component: <Staff />
    },
    {
        path: "staff/staff-form",
        component: <StaffForm />
    },
    {
        path: "subitem",
        component: <Subitem />
    },
    {
        path: "subitem/subitem-form",
        component: <SubitemForm />
    },
    
    {
        path: "itemmaster",
        component: <Itemmaster />
    },
    {
        path: "itemmaster/itemmaster-form",
        component: <ItemmasterForm />
    },
    {
        path: "user",
        component: <User />
    },
    {
        path: "user/user-form",
        component: <UserForm />
    },
    {
        path: "metal",
        component: <Metal />
    },
    {
        path: "purity",
        component: <Purity />
    },
    {
        path: "valueaddition-customer",
        component: <Valueaddition />
    },
    {
        path: "valueaddition-designer",
        component: <ValueadditionDesigner />
    },
    {
        path: "rangestock",
        component: <Rangestock />
    },
    {
        path: "metal-rates",
        component: <MetalRates />
    },
    {
        path: "floor",
        component: <Floor />
    },
    {
        path: "counter",
        component: <Counter />
    },
    {
        path: "company",
        component: <Company />
    },
    {
        path: "account-head",
        component: <AccountHead />
    },
    {
        path: "account-group",
        component: <AccountGroup />
    },
    {
        path: "tag",
        component: <Tag />
    },
    {
        path: "tax",
        component: <Taxs />
    },
    {
        path: "caratrate",
        component: <Caratrate />
    },
    {
        path: "standard",
        component: <Standard />
    },
    {
        path: "lot",
        component: <Lot />
    },
    {
        path: "itemtag",
        component: <Itemtag />
    },
    {
        path: "estimate-billing",
        component: <Estimatebilling />
    },
    {
        path: "billing",
        component: <Invoice />
    },
    {
        path: "misc-issue",
        component: <MiscIssue />
    },
    {
        path: "tagupdate",
        component: <Tagupdate />
    },
    {
        path: "tagdetails",
        component: <TagdetailsForm />
    },
    {
        path: "customer",
        component: <Customer />
    },
    {
        path: "customergroup",
        component: <Customergroup />
    },
    {
        path: "advance-payment",
        component: <Advancepayment />
    },
    {
        path: "duplicate-tag",
        component: <Dublicatetag />
    },

    {
        path: "stock-list",
        component: <Stocklist />
    },
    {
        path: "approval-type",
        component: <Approvaltype />
    },
    {
        path: "approval-rule",
        component: <Approvalrule />
    },
    {
        path: "order-details",
        component: <Orderdetails />
    },
    {
        path: "estimation-approval",
        component: <Estimationapproval />
    },
    {
        path: "stock-transfer",
        component: <StockTransfer />
    },

    {
        path: "sale-report",
        component: <Salereport />
    },
    {
        path: "itemwise-report",
        component: <Itemwisereport />
    },
    {
        path: "tag-report",
        component: <Tagreport />
    },
    {
        path: "repair-creation",
        component: <Repaircreation />
    },
    {
        path: "repair-type",
        component: <Repairtype />
    },
    {
        path: "counter-target",
        component: <CounterTarget />
    },
    {
        path: "vendor-payment",
        component: <VendorPayments />
    },
    {
        path: "transfer-creation",
        component: <TransferCreation />
    },
    {
        path: "melting-issue",
        component: <MeltingIssue />
    },
    {
        path: "melting-recipt",
        component: <MeltingReceipt />
    },
    {
        path: "purification-issue",
        component: <PurificationIssue />
    },
    {
        path: "purification-recipt",
        component: <PurificationReceipt />
    },
    {
        path: "approval-issue",
        component: <RequisitionApproval />
    },
    {
        path: "vendor-wise-lot-report",
        component: <VendorwiseReport />
    } ,
    {
        path: "sale-detail-report",
        component: <SaleDetailsReport />
    }
    ,
    {
        path: "sale-by-customer",
        component: <SalesByCustomerReport />
    }
    ,
    {
        path: "item-wise-sale-report",
        component: <ItemWiseSaleReport />
    }
    ,
    {
        path: "lot-entry-report",
        component: <LOTReport />
    }
    ,
    {
        path: "stock-summary-report",
        component: <StockSummaryReport />
    }
    ,
    {
        path: "stocke-report-tag-wise",
        component: <StockTagWiseReport />
    },
    {
        path: "purchase-report",
        component: <PurchaseReport />
    },
    {
        path: "item-wise-purchase-report",
        component: <ItemWisePurchaseReport />
    },
    {
        path: "Vendor-wise-purchase-report",
        component: <VendorwisePurchaseReport />
    },
    {
        path: "old-purchase-report",
        component: <OldPurchaseReport />
    },
    {
        path: "vendor-repair-report",
        component: <IssueVendorreport />
    },
    {
        path: "customer-repair-report",
        component: <IssueCustomerreport />
    },
    {
        path: "sales-daily-report",
        component: <SalesDailyReport />
    },
    {
        path: "customer-sale-report",
        component: <CustomerSalereport />
    }
    ,
    {
        path: "single-itemtag",
        component: <SingleTag />
    },
  
]    