import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { activeUserPermissionEndPoint } from "../service/api";
import { getAxios } from "../service/apiservice";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../redux/themeconfigslice";




export function Errorpage500() {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.themeConfig);

    useEffect(() => {
        getMenuList();
    },[])

    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })
        
        if (response !== null) {
            if (response === 'ERR_NETWORK') {
                dispatch(setIsLoading(false))
                navigate('/500')
            } else {
                navigate('/')
            }
        }
        
    }

    return (
        <>

            <main className="h-screen w-full flex flex-col justify-center items-center bg-primary-btn">
                <h1 className="text-9xl font-extrabold text-white tracking-widest">500</h1>
                <div className="bg-[#F9A61B] px-2 text-sm text-white rounded rotate-12 absolute">
                    Internal Server Error
                </div>
            </main>
        </>
    )

}