import React, { useEffect, useState } from 'react'
import { purchaseDashboardEndPoint } from '../../service/api'
import { getAxios } from '../../service/apiservice'

export default function Purchaseentrypopup() {

  const [currentPurchasedetails, setCurrentPurchasedetails] = useState({});

  useEffect(() => {
    getPurchaseDetails()
  }, [])

  const getPurchaseDetails = async () => {
    var response = await getAxios({
      url: purchaseDashboardEndPoint + localStorage.getItem('purchaseentry_form_id') + "/"
    })

    if (response !== null) {
      var tempDetails = {
        bill_no: response.data.purchase_list?.bill_no,
        person_name: response.data.purchase_list?.person_name,
        total_amount: response.data.purchase_list?.total_amount,
        sub_total: response.data.purchase_list?.sub_total,
        total_stone_amount: response.data.purchase_list?.total_stone_amount,
      }
      setCurrentPurchasedetails(tempDetails)
    }
  }
  return (
    <div>
      <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg ">
        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[500px]:grid-cols-2  gap-5">
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Bill No</p>
            <p className="text-primary-btn font-[400] text-[15px]">{currentPurchasedetails.bill_no}</p>
          </div>
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Customer Name</p>
            <p className="text-primary-btn font-[400] text-[15px]">{currentPurchasedetails.person_name}</p>
          </div>
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Total Amount</p>
            <p className="text-primary-btn font-[400] text-[15px]">{(currentPurchasedetails.total_amount)?.toFixed(2)}</p>
          </div>
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Sub Total</p>
            <p className="text-primary-btn font-[400] text-[15px]">{(currentPurchasedetails.sub_total)?.toFixed(2)}</p>
          </div>
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Total Stone Amount</p>
            <p className="text-primary-btn font-[400] text-[15px]">{(currentPurchasedetails.total_stone_amount)?.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
