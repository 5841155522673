import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { DuplicateTagEndPoint, OrderReciptEndPoint, OrderissueListEndPoint, dublicatetagEndPoint, getTagNumbersListEndPoint, staffListEndPoint, tagNumbertypeEndPoint, tagdetailsEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useEffect, useState, useRef } from "react";
import Barcode from 'react-barcode';
import ReactToPrint from "react-to-print";

export default function DublicatetagForm({ getData, formMode, setFormMode, setCurrentDublicatetag }) {

    const componentRef = useRef(null);

    const [tagNumerdropdown, setTagNumberDropdown] = useState([]);
    const [selectedTagNumber, setSelectedTagNumber] = useState('');
    const [tagDetails, setTagDetails] = useState();

    const [printvalue, setprintvalue] = useState([]);
    const [numberOfCopies, setNumberOfCopies] = useState('')

    console.log(selectedTagNumber)


    const schema = yup
        .object({
            number_copies: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: {
            tag_number: "",
            number_copies: "",
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };



    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {

        const { number_copies } = data;
        if (!number_copies || isNaN(number_copies) || number_copies <= 0) {
            alert('Please enter a valid number of copies.');
            return;
        }



        if (data !== '') {

            createDublicatetag(data)

        } else {
            errors.showMessages()
        }


    }

    useEffect(() => { console.log(printvalue) }, [printvalue])

    const createDuplicateBarcodes = (number_copies) => {
        let printValue = [];
        for (let i = 1; i <= number_copies; i++) {
            printValue.push(i);
        }

        setprintvalue(printValue)
    }

    useEffect(() => {
        getTagNumberDropdownData();
    }, [])

    const getTagNumberDropdownData = async () => {
        var response = await getAxios({
            url: tagNumbertypeEndPoint
        })

        if (response !== null) {
            setTagNumberDropdown(response.data.list);
        }
    }

    const getTagDetailsData = async (selectedTagNumber) => {
        try {
            const response = await getAxios({
                url: tagdetailsEndPoint + selectedTagNumber + '/'
            });

            if (response !== null) {
                setTagDetails(response.data);

            }
        } catch (error) {
            console.error('Error fetching tag details:', error);
        }
    };

    const handleTagNumberChange = (event) => {
        const selectedTag = event.target.value;
        setSelectedTagNumber(selectedTag);
        getTagDetailsData(selectedTag);
    };


    const Copies = []



    const createDublicatetag = async (body) => {
        body['tag_number'] = selectedTagNumber

        var response = await postAxiosWithToken({
            url: DuplicateTagEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            toast.success(response.message)

        }
    }




    const clearData = () => {
        setCurrentDublicatetag({
            tag_number: "",
            number_copies: "",
        })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg shadow">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Dublicatetag Name</p>
                <form className="grid 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Tag Number</p>
                        <select
                            type="text"
                            placeholder="Tag Number"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="tag_number"
                            id="forminit"
                            value={selectedTagNumber}
                            onChange={handleTagNumberChange}
                        >
                            <option value={""}>--select Order Details--</option>
                            {
                                tagNumerdropdown?.map((option) => (
                                    <option className="capitalize" key={"tagnumber" + option.tag_number} value={option.tag_number}>{option.tag_number}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.tag_number?.message}</p>
                    </div>

                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Number Copies</p>
                        <input
                            type="number"
min="0"
                            placeholder="Number Copies"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="number_copies"
                            {...register('number_copies', { required: 'Number of copies is required' })}
                            onChange={(e) => { createDuplicateBarcodes(parseInt(e.target.value)) }}
                        // ref={componentRef}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.number_copies?.message}</p>
                    </div>





                    <div className="hidden">
                        <div ref={componentRef} className="">
                            {
                                printvalue.map((item) => (
                                    <div className="flex items-center ml-[15px] w-[210px] justify-between">
                                        <div className="text-[9px]">
                                            <p>{tagDetails?.gross_weight} g</p>
                                            <p>{tagDetails?.item_name}</p>
                                        </div>
                                        <Barcode
                                            value={selectedTagNumber}
                                            width={1}
                                            height={15}
                                            format="CODE128"
                                            displayValue={true}
                                            fontSize={8}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <button
                                type="submit"
                                className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none mb-[5px] mt-8"

                            >
                                Print <span className="lowercase">(ctrl+p)</span>
                            </button>
                        )}
                        content={() => componentRef.current}
                    />



                </form >
            </div >
        </>
    );
}