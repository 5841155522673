export default function TotalPurchaseDetails({ formValues }) {
    return (
        <form>
            <div className="grid sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-3">
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Total Item</p>
                    <input
                        type="text"
                        placeholder="Total Item"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="total_items"
                        value={formValues.total_items}
                        readOnly
                    />
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Total Net Wt</p>
                    <input
                        type="text"
                        placeholder="Total Net Wt"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="total_net_weight"
                        value={formValues.total_net_weight}
                        readOnly
                    />
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Total Purity Wt</p>
                    <input
                        type="text"
                        placeholder="Total Purity Wt"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="total_purity_weight"
                        value={formValues.total_purity_weight}
                        readOnly
                    />
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Hallmark</p>
                    <input
                        type="text"
                        placeholder="Hallmark"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="hallmark"
                        value={formValues.hallmark}
                        readOnly
                    />
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Others</p>
                    <input
                        type="text"
                        placeholder="Others"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="others"
                        value={formValues.others}
                        readOnly
                    />
                </div>
                <div className="my-[5px] lg:col-span-2">
                    <p className="mb-[5px] text-[12px]">Amount</p>
                    <input
                        type="text"
                        placeholder="Amount"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="total_amount"
                        value={formValues.total_amount}
                        readOnly
                    />
                </div>
            </div>
        </form>
    );
}