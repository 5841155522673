import { Link } from "react-router-dom";




export function Errorpage404() {

    return (
        <>

            <main className="h-screen w-full flex flex-col justify-center items-center bg-primary-btn">
                <h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
                <div className="bg-[#D7B56D] px-2 text-sm text-white rounded rotate-12 absolute">
                    Page Not Found
                </div>
                <button className="mt-5">
                    <a
                        className="relative inline-block text-sm font-medium text-[#D7B56D] group active:text-orange-500 focus:outline-none focus:ring"
                    >


                        <span className="relative block px-8 py-3 bg-[#D7B56D] rounded text-black">
                            <Link to="/">Go Home</Link>
                        </span>
                    </a>
                </button>
            </main>
        </>
    )

}