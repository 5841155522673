import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderEndPoint } from "../../../../service/api";
import { getAxios } from "../../../../service/apiservice";

export default function ActionButton({ item, setIsDeleteOpen, setCurrentDetails, setPrintView ,setPaymentScreen ,setCurrentpymentDetails }) {

  const navigate = useNavigate();

  const { actions } = useSelector((state) => state.appConfig);

  const getOrderDetails = async (id) => {
    var response = await getAxios({
      url: orderEndPoint + id + '/'
    })

    if (response != null) {
      setCurrentDetails(response.data)
      setPrintView(true)
    }
  }


  const getOrderpaymentDetails = async (id) => {
    var response = await getAxios({
      url: orderEndPoint + id + '/'
    })

    if (response != null) {
      let tempdetails = {
        approximate_amount:response.data.approximate_amount,
        order_id:response.data.order_id,
        customer:response.data.customer,
        discount_percentage:response.data.payment_details.discount_percentage,
        discount_amount:response.data.payment_details.discount_amount,
        round_off:response.data.payment_details.round_off,
        gst_type:response.data.payment_details.gst_type,
        igst:response.data.payment_details.igst,
        sgst:response.data.payment_details.sgst,
        cgst:response.data.payment_details.cgst,
        others:response.data.payment_details.others,
        stone_amount:response.data.payment_details.stone_amount,
        diamond_amount:response.data.payment_details.diamond_amount,
        making_charge_per_gram:response.data.payment_details.making_charge_per_gram,
        flat_making_charge:response.data.payment_details.flat_making_charge,
        advance_old_gold_amount:response.data.payment_details.advance_old_gold_amount,
        advance_old_silver_amount:response.data.payment_details.advance_old_silver_amount,
        advance_amount:response.data.payment_details.advance_amount,
        balance_amount:response.data.payment_details.balance_amount,

      }
      


      setCurrentpymentDetails(tempdetails)

    }
  }

  

  return (
    <Popover
      placement="bottom-end"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0, y: 25 },
      }}
    >
      <PopoverHandler>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M6 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0m4 2a2 2 0 1 0 0-4a2 2 0 0 0 0 4" /></svg>
        </button>
      </PopoverHandler>
      <PopoverContent>
        <div className="h-[110px] flex flex-col gap-2">
          <div onClick={() => {
            if (actions.editPermit) {
              navigate('orderdetails-update/' + item.id.toString())
            } else {
              toast.error("You don't have permission for edit")
            }
          }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]">
            <div className="w-6">
              <svg className="!text-[#444444]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
            </div>
            <p className="text-md text-[#444444]">Edit</p>
          </div>
          <div onClick={() => {
            getOrderDetails(item.id);
          }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]">
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7 2a1 1 0 0 0-1 1v4H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2h2a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-2V3a1 1 0 0 0-1-1zm10 12H7a1 1 0 0 0-1 1v2H4V9h16v8h-2v-2a1 1 0 0 0-1-1m-1-7H8V4h8zM5 10v2h3v-2zm11 6v4H8v-4z" /></svg>
            </div>
            <p className="text-md text-[#444444]">Print</p>
          </div>
          <div onClick={() => {
            if (actions.deletePermit) {
              setPaymentScreen(true)
              getOrderpaymentDetails(item.id);
            } else {
              toast.error("You don't have permission for edit")
            }
          }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]" id="print">
            <div className="w-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-width="2" d="M16 16c0-1.105-3.134-2-7-2s-7 .895-7 2s3.134 2 7 2s7-.895 7-2ZM2 16v4.937C2 22.077 5.134 23 9 23s7-.924 7-2.063V16M9 5c-4.418 0-8 .895-8 2s3.582 2 8 2M1 7v5c0 1.013 3.582 2 8 2M23 4c0-1.105-3.1-2-6.923-2c-3.824 0-6.923.895-6.923 2s3.1 2 6.923 2S23 5.105 23 4Zm-7 12c3.824 0 7-.987 7-2V4M9.154 4v10.166M9 9c0 1.013 3.253 2 7.077 2C19.9 11 23 10.013 23 9"/></svg>
            </div>
            <p className="text-md text-[#444444]">Payment</p>
          </div>
          <div onClick={() => {
            if (actions.deletePermit) {
              setCurrentDetails(item)
              setIsDeleteOpen(true)
            } else {
              toast.error("You don't have permission for edit")
            }
          }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]">
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20m0-8" /></svg>
            </div>
            <p className="text-md text-[#444444]">Cancel</p>
          </div>

          
        </div>

      </PopoverContent>
    </Popover>
  );
}