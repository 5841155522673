import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SingleTagUpdateEndPoint, branchListEndPoint, calculationtypeDropdownEndPoint, singletagEndPoint, tagNumbertypeEndPoint, tagdetailsEndPoint } from "../../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../../hooks/useKeyPress";
import { useEffect, useState } from "react";
import { fixedCalcId, pergramCalId, phoneRegExp, weightCalId } from "../../../../constant";
import { datetimetodate } from "../../../../utilities/datetime";
import Select from 'react-select';
import Popup from "../../../../components/Popup";
import DiamondForm from "./diamondform";
import StoneForm from "./stoneform";

export default function SingletagForm({ tab, getData, formMode, currentSingletag, setFormMode, setCurrentSingletag }) {


    const [Getcalc, setGetCalc] = useState('');

    const [wighttype, setWightType] = useState([]);
    const [selectedCalc, setSelectedCalc] = useState('');
    const [calculationtypeDropdown, setCalculationTypeDropdown] = useState([]);

    const [tagdetails, setTagNumberDetails] = useState([])
    const [selectedTagNumber, setSelectedTagNumber] = useState('');
    const [tagNumberDropdown, setTagNumberDropdown] = useState([]);
    const [options, setOptions] = useState([]);
    const [tagdetailsdata, setAdditionalData] = useState({});
    const [selectedTag, setSelectedTag] = useState()
    const [stonePopup, setStonePopup] = useState(false);
    const [diamondPopup, setDiamondPopup] = useState(false);
    const [stoneList, setStoneList] = useState([]);
    const [diamondList, setDiamondList] = useState([]);
    const [removedStones, setRemovedStones] = useState([]);
    const [branchDropdown, setBranchDropDown] = useState([]);

    const handleButtonClick = (e) => {
        e.preventDefault();
        setGetCalc(true);
    };

    useEffect(() => {
        getCalculationTypeDropdownData();
        getTagNumberDtailsData()
        getBranchDropdownData()
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list)
        }
    }

    const closeStoneModal = () => {
        setStonePopup(!stonePopup)

    }

    const closeDiamondModal = () => {
        setDiamondPopup(!diamondPopup)
    }

    const getCalculationTypeDropdownData = async () => {
        var response = await getAxios({
            url: calculationtypeDropdownEndPoint
        })

        if (response !== null) {
            setCalculationTypeDropdown(response.data.list)
        }
    }
    const getTagNumberDtailsData = async () => {
        var response = await getAxios({
            url: tagNumbertypeEndPoint
        })

        if (response !== null) {
            setTagNumberDropdown(response.data.list)

            let tempList = [];
            for (var i of response.data.list) {
                var tempDict = {
                    value: i.tag_number,
                    label: i.tag_number
                };
                tempList.push(tempDict);
            }
            setOptions(tempList)
        }
    }



    const getTagNumberDropdownData = async (id) => {
        var response = await getAxios({
            url: tagNumbertypeEndPoint + id + '/'

        })

        if (response !== null) {
            setTagNumberDropdown(response.data.list)

            let tempList = [];
            for (var i of response.data.list) {
                var tempDict = {
                    value: i.tag_number,
                    label: i.tag_number
                };
                tempList.push(tempDict);
            }
            setOptions(tempList)
        }
    }


    const handleTagNumberChange = async (selectedTagNumber) => {
        setSelectedTagNumber(selectedTagNumber);
        datetimetodate(selectedTagNumber);

        try {
            const response = await getAxios({
                url: tagdetailsEndPoint + selectedTagNumber + '/',

            });


            if (response !== null) {

                setAdditionalData(response.data);

                setStoneList(response.data.stone_details)
                setDiamondList(response.data.diamond_details)
            }

        } catch (error) {
            console.error('Error fetching additional data:', error);
        }
    };




    const onSubmit = async (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        let notRequiredList = [];

        var err = {};

        // for (var field of Object.entries(formDataObject)) {
        //     if (field[1].length === 0 && !notRequiredList.includes(field[0])) {
        //         err[field[0]] = `${field[0]} field is required`;
        //     } else {
        //         err[field[0]] = "";
        //     }
        // }

        if (Object.values(err).some((errMsg) => errMsg.length > 0)) {
            console.error('Form validation errors:', err);
            return;
        }

        try {
            const response = await putAxiosWithToken({
                url: SingleTagUpdateEndPoint + selectedTagNumber + '/',
                body: formDataObject

            });

            if (response !== null) {
                toast.success(response.message)
            }
        } catch (error) {
            console.error('Error updating tag:', error);
        }
    };




    return (
        <>
            <div>
                <form onSubmit={onSubmit} >
                    <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-[15px]" >
                    {localStorage.getItem("branch") === "true" &&
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Branch Name</p>
                        <select
                            placeholder="branch name"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="branch"
                            // {...register('branch')}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    getTagNumberDropdownData(e.target.value)
                                } else {
                                    setAdditionalData([])
                                }
                            }}
                        >
                            <option value={""} className="capitalize">--select branch name--</option>
                            {
                                branchDropdown.map((option) => (
                                    <option className="capitalize" key={option.id} value={option.id}>{option.branch_name}</option>
                                ))
                            }
                        </select>
                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.branch_name?.message}</p> */}
                    </div>}
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Tag Number</p>
                            <Select
                                value={selectedTag}
                                options={options}
                                isSearchable
                                onChange={(e) => handleTagNumberChange(e.value)}
                                className="w-[300px]"
                            />
                        </div>
                        <div className="mt-7">
                            <button
                                type="button" // Set type to "button" to prevent form submission
                                id="singletagformsubmit"
                                className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-[50%] font-[500] text-[15px] outline-none border-none"
                                onClick={handleButtonClick}
                            >
                                Get<span className="lowercase"></span>
                            </button>
                        </div>
                        <div className="flex gap-[10px] mt-7 ">
                            <button
                                onClick={(e) => { e.preventDefault(); setStonePopup(!stonePopup) }}
                                className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >View Stone</button>
                            <button
                                onClick={(e) => { e.preventDefault(); setDiamondPopup(!diamondPopup) }}
                                className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >View Diamond</button>
                        </div>
                    </div>
                    <div className={`${Getcalc ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg`}>
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Details</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-5 hidden">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Size Value</p>
                                <input
                                    type="text"
                                    // placeholder="Tag Type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="size_value"
                                    defaultValue={tagdetailsdata.size}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Gross Weight</p>
                                <input
                                    type="text"
                                    // placeholder="Tag Type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gross_weight"
                                    defaultValue={tagdetailsdata.gross_weight}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Tag Weight</p>
                                <input
                                    type="text"
                                    // placeholder="Tag Type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tag_weight"
                                    defaultValue={tagdetailsdata.tag_weight}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Cover Weight</p>
                                <input
                                    type="text"
                                    // placeholder="Size"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="cover_weight"
                                    defaultValue={tagdetailsdata.cover_weight}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Loop Weight</p>
                                <input
                                    type="text"
                                    // placeholder="Tag Type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="loop_weight"
                                    defaultValue={tagdetailsdata.loop_weight}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Other Weight</p>
                                <input
                                    type="text"
                                    // placeholder="Pieces"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="other_weight"
                                    defaultValue={tagdetailsdata.other_weight}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Tag Pieces</p>
                                <input
                                    type="text"
                                    // placeholder="Tag Count"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tag_pieces"
                                    defaultValue={tagdetailsdata.pieces}
                                />
                            </div>
                            <div className="w-full ">
                                <p className="mb-[5px] text-[14px]">Calculation Type</p>
                                <input
                                    type="text"
                                    placeholder="Hallmark Center"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="calculation_type_name"
                                    value={tagdetailsdata.calculation_type_name}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* fixed_rate */}
                        <div className={`${tagdetailsdata.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px]`}>

                            <div className="  w-full grid xl:grid-cols-5 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Fixed Rate</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_fixed_rate"

                                        defaultValue={tagdetailsdata.min_fixed_rate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_sale_value"

                                        defaultValue={tagdetailsdata.min_sale_value}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`${tagdetailsdata.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px]`}>
                            <div className=" w-full grid xl:grid-cols-5 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Fixed Rate</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_fixed_rate"

                                        defaultValue={tagdetailsdata.max_fixed_rate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_sale_value"

                                        defaultValue={tagdetailsdata.max_sale_value}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* fixed_rate */}

                        {/* wastage_calculation */}
                        <div className={`${tagdetailsdata.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px]`}>
                            <div className=" w-full grid xl:grid-cols-5 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Wastage %</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_wastage_percent"

                                        defaultValue={tagdetailsdata.min_wastage_percent}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Flat Wastage</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_flat_wastage"

                                        defaultValue={tagdetailsdata.min_flat_wastage}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Mc/G</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_making_charge_gram"

                                        defaultValue={tagdetailsdata.min_making_charge_gram}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]"> Min Flat Mc</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_flat_making_charge"

                                        defaultValue={tagdetailsdata.min_flat_making_charge}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_sale_value"

                                        defaultValue={tagdetailsdata.min_sale_value}
                                    />
                                </div>
                            </div>
                        </div>
                        {console.log(tagdetailsdata.calculation_type)}

                        <div className={`${tagdetailsdata.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px]`}>

                            <div className=" w-full grid xl:grid-cols-5 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Wastage %</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_wastage_percent"

                                        defaultValue={tagdetailsdata.max_wastage_percent}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Flat Wastage</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_flat_wastage"

                                        defaultValue={tagdetailsdata.max_flat_wastage}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Mc/G</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_making_charge_gram"

                                        defaultValue={tagdetailsdata.max_making_charge_gram}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]"> Max Flat Mc</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_flat_making_charge"

                                        defaultValue={tagdetailsdata.max_flat_making_charge}
                                    />
                                </div>

                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_sale_value"

                                        defaultValue={tagdetailsdata.max_sale_value}
                                    />
                                </div>
                            </div>


                        </div>
                        {/* wastage_calculation */}




                        {/* pergram */}
                        <div className={`${tagdetailsdata.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px]`}>

                            <div className=" w-full grid xl:grid-cols-5 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Pergram Rate</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_pergram_rate"

                                        defaultValue={tagdetailsdata.min_pergram_rate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Per Gram Weight Type</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="per_gram_weight_type"

                                        defaultValue={tagdetailsdata.per_gram_weight_type}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="min_sale_value"

                                        defaultValue={tagdetailsdata.min_sale_value}
                                    />
                                </div>

                            </div>



                        </div>

                        <div className={`${tagdetailsdata.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px]`}>

                            <div className=" w-full grid xl:grid-cols-5 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Pergram Rate</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_pergram_rate"

                                        defaultValue={tagdetailsdata.max_pergram_rate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Per Gram Weight Type Name</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="per_gram_weight_type_name"

                                        defaultValue={tagdetailsdata.per_gram_weight_type_name}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                                    <input
                                        type="text"
                                        placeholder="Hallmark Center"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="max_sale_value"

                                        defaultValue={tagdetailsdata.max_sale_value}
                                    />
                                </div>


                            </div>



                        </div>
                        {/* pergram */}
                    </div>




                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 mt-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create') }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>

                <Popup
                    title={`${formMode} Stone`}
                    extraClassName="min-w-full"
                    setIsOpen={setStonePopup}
                    isOpen={stonePopup}
                    closeModal={closeStoneModal}
                >
                    <StoneForm
                        closeModal={closeStoneModal}
                        dataList={stoneList}
                        setDataList={setStoneList}
                        setRemovedStone={setRemovedStones}
                        tagdetailsdata={tagdetailsdata}
                    />

                </Popup>

                <div className="w-full">
                    <Popup title={`${formMode} Diamond`} extraClassName="min-w-full" setIsOpen={setDiamondPopup} isOpen={diamondPopup} closeModal={closeDiamondModal}>
                        <DiamondForm
                            closeModal={closeDiamondModal}
                            dataList={diamondList}
                            setDataList={setDiamondList} />
                    </Popup>
                </div>
            </div>
        </>
    );
}