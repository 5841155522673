import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { branchListEndPoint, floorEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function FloorForm({ getData, formMode, currentFloor, setFormMode, setCurrentFloor }) {

    const [branchDropdown, setBranchDropDown] = useState([]);

    const schema = yup
        .object({
            branch: localStorage.getItem("branch") == "true" ? yup.string().required() : yup.string(),
            floor_name: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            branch: currentFloor.branch,
            floor_name: currentFloor.floor_name
        } : {
            branch: "",
            floor_name: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    useEffect(() => {
        getBranchDropdownData()
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list)
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createFloor(data)
            } else {
                updateFloor(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createFloor = async (body) => {
        var response = await postAxiosWithToken({
            url: floorEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateFloor = async (body) => {
        var response = await putAxiosWithToken({
            url: floorEndPoint + currentFloor?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentFloor({
            branch: "",
            floor_name: ""
        })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg shadow border mt-5">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Floor</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                { localStorage.getItem("branch") == "true" && <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Branch Name</p>
                        <select
                            placeholder="branch name"
                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="branch"
                            {...register('branch')}
                        >
                            <option value={""}>--select branch name--</option>
                            {
                                branchDropdown.map((option) => (
                                    <option key={option.id} value={option.id}>{option.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.branch?.message}</p>
                    </div> }
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Floor Name</p>
                        <input
                            type="text"
                            placeholder="floor name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="floor_name"
                            id="forminit"
                            {...register('floor_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.floor_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-[35px] xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}