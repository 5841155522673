import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { OrderReciptListEndPoint, dublicatetagEndPoint, dublicatetagListEndPoint, dublicatetagStatusEndPoint, getduplicateTagEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { datetimetodate } from "../../../utilities/datetime";
import DublicatetagForm from "./dublicatetagform";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import DublicatetagFilters from "./dublicatetagfilter";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";

export default function Dublicatetag() {

    const searchRef = useRef();

    const [dublicatetagList, setDublicatetagList] = useState([]);
    const [filteredDublicatetagList, setFilteredDublicatetagList] = useState([]);
    const [currentDublicatetag, setCurrentDublicatetag] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);


    const { actions,pagination } = useSelector((state) => state.appConfig);


    const [tableFilters, setTableFilters] = useState({
        search: '',
        items_per_page: JSON.parse(localStorage.getItem('pagination') )? 10 : null,
        page: 1,
        
    });


    useEffect(() => {
        getDublicatetagListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);


    const getDublicatetagListData = async () => {
        var response = await getAxios({
            url: getduplicateTagEndPoint,
        })

        if (response !== null) {
            setDublicatetagList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    
    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          page: value,
        }));
      };
    
      const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          items_per_page: value,
          page: 1,
        }));
      };



    return (
        <>
            <div>
                {
                    formMode === 'create' ? actions.addPermit ? <div>
                        <DublicatetagForm getData={getDublicatetagListData} formMode={formMode} setFormMode={setFormMode} currentDublicatetag={currentDublicatetag} setCurrentDublicatetag={setCurrentDublicatetag} />
                    </div> : '' : actions.editPermit ? <div>
                        <DublicatetagForm getData={getDublicatetagListData} formMode={formMode} setFormMode={setFormMode} currentDublicatetag={currentDublicatetag} setCurrentDublicatetag={setCurrentDublicatetag} />
                    </div> : ''
                }
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <DublicatetagFilters searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters}  />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                         <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Tag Details</td>
                                <td className="p-[10px] min-w-[150px]">Number Copies</td>
                                <td className="p-[10px] min-w-[150px]">Created By</td>
                                <td className="p-[10px] min-w-[150px]">Created At</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-menu-head-bg">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    dublicatetagList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                        dublicatetagList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.tag_details}</td>
                                                <td className="p-[10px]">{item.number_copies}</td>
                                                <td className="p-[10px]">{item.created_by}</td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at)}</td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                {pagination === true ? 
                <div >
                <Pagination
      totalPages={totalPages}
      currentPage={tableFilters.page}
      setCurrentPage={pageHandler}
      entries={tableFilters.items_per_page}
      entriesHandler={entriesHandler}
      />
            </div>:""}
            </div>
           
        </>
    );
}