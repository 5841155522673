import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { meltingIssuedStatusType } from "../../../../constant";

export default function ActionButton({ item, setIsDeleteOpen, setCurrentDetails, getDetails }) {

  const navigate = useNavigate();

  const { actions } = useSelector((state) => state.appConfig);

  return (
    <Popover
      placement="bottom-end"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0, y: 25 },
      }}
    >
      <PopoverHandler>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M6 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0m4 2a2 2 0 1 0 0-4a2 2 0 0 0 0 4" /></svg>
        </button>
      </PopoverHandler>
      <PopoverContent>
        <div className="h-[55px] flex flex-col gap-2">
          <div onClick={() => {
            if (actions.editPermit) {
              if (item.melting_status == meltingIssuedStatusType) {
                getDetails(item.id);
                setCurrentDetails(item);
              } else {
                toast.error(`Can't edit the item! beacause it is in ${item.melting_status_name} status`)
              }
            } else {
              toast.error("You don't have permission for edit")
            }
          }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]">
            <div className="w-6">
              <svg className="!text-[#444444]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
            </div>
            <p className="text-md text-[#444444]">Edit</p>
          </div>
          <div onClick={() => {
            if (actions.deletePermit) {
              setCurrentDetails(item)
              setIsDeleteOpen(true)
            } else {
              toast.error("You don't have permission for edit")
            }
          }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]">
            <div className="w-6">
              <svg className="!text-[#444444]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
            </div>
            <p className="text-md text-[#444444]">Delete</p>
          </div>
        </div>

      </PopoverContent>
    </Popover>
  );
}