import { useEffect, useState } from "react";
import { accountheadListEndPoint, metalListEndPoint } from "../../../../service/api";
import { getAxios } from "../../../../service/apiservice";

export default function VendorDetailsForm({ formValues, setFormValues, errors, setErrors, getVendorDetails }) {

    const [vendorDropDown, setVendorDropDown] = useState([])
    const [metalDropDown, setMetalDropDown] = useState([])

    useEffect(() => {
        getVendorDropDown()
        getMetalDropDown()
    },[])

    const getMetalDropDown = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response != null) {
            setMetalDropDown(response.data.list)
        }
    }

    const getVendorDropDown = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response != null) {
            setVendorDropDown(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == "vendor") {
            getVendorDetails(value)
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }
    
    return (
        <form>
            <div className="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 xl:grid-cols-9 gap-3">
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">VP ID </p>
                    <input
                        type="text"
                        placeholder="VP ID"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="payment_bill_no"
                        value={formValues.payment_bill_no}
                        readOnly
                    />
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Vendor <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Vendor"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                        name="designer_name"
                        value={formValues.designer_name}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select vendor-</option>
                        {
                            vendorDropDown.map((item) => (
                                <option key={'vendor'+item.id} value={item.id}>{item.account_head_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.designer_name}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Date <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="Pcs"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[15px] outline-none"
                        name="date"
                        value={formValues.date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">{errors.date}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Metal Type <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Metal Type"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                        name="metal"
                        value={formValues.metal}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select metal-</option>
                        {
                            metalDropDown.map((item) => (
                                <option key={'metal'+item.id} value={item.id}>{item.metal_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                </div>
            </div>
        </form>
    );
}