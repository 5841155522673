import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { accountGroupEndPoint, branchListEndPoint, counterEndPoint, floorListEndPoint, groupLedgerListEndPoint, groupTypeListEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function AccountGroupForm({ getData, formMode, currentAccountGroup, setFormMode, setCurrentAccountGroup }) {

    const [groupLedgerDropdown, setGroupLedgerDropDown] = useState([]);
    const [groupTypeDropdown, setGroupTypeDropDown] = useState([]);

    const [selectedGroupType, setSelectedGroupType] = useState("");
    const [selectedGroupLedger, setSelectedGroupLedger] = useState("");

    const schema = yup
        .object({
            account_group_name: yup.string().required(),
            group_ledger: yup.string().required(),
            group_type: yup.string().required(),
            account_under: yup.string().required('group_under is a required field')
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            account_group_name: currentAccountGroup.account_group_name,
            group_ledger: currentAccountGroup.group_ledger,
            group_type: currentAccountGroup.group_type,
            account_under: currentAccountGroup.account_under
        } : {
            account_group_name: "",
            group_ledger: "",
            group_type: "",
            account_under: ""
        }
    })

    useEffect(() => {
        getGroupLedgerDropdownData()
        getGroupTypeDropdownData()
    },[])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getGroupLedgerDropdownData = async () => {
        var response = await getAxios({
            url: groupLedgerListEndPoint
        })

        if (response !== null) {
            setGroupLedgerDropDown(response.data.list) 
        }
    }

    const getGroupTypeDropdownData = async () => {
        var response = await getAxios({
            url: groupTypeListEndPoint
        })

        if (response !== null) {
            setGroupTypeDropDown(response.data.list)
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createAccountGroup(data)
            } else {
                updateAccountGroup(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createAccountGroup = async (body) => {
        var response = await postAxiosWithToken({
            url: accountGroupEndPoint,
            body: body
        })

        if (response !== null) {
            getData({})
            reset()
            clearData()
            toast.success(response.message)
        }
    }

    const updateAccountGroup = async (body) => {
        var response = await putAxiosWithToken({
            url: accountGroupEndPoint + currentAccountGroup?.id + '/',
            body: body
        })

        if (response !== null) {
            getData({})
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setSelectedGroupType("")
        setSelectedGroupLedger("")
        setCurrentAccountGroup({
            account_group_name: "",
            group_ledger: "",
            group_type: "",
            account_under: ""
        })
    }

    return (
        <>
           <div className="p-5 rounded-[10px] bg-menu-head-bg shadow">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} acount group</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Group Name</p>
                        <input
                            type="text"
                            placeholder="group name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="account_group_name"
                            id="forminit"
                            {...register('account_group_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.account_group_name?.message}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Group Ledger</p>
                        <select
                            placeholder="group ledger"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="group_ledger"
                            {...register('group_ledger')}
                            value={selectedGroupLedger}
                            onChange={(e) => {setSelectedGroupLedger(e.target.value)}}
                        >
                            <option value={""} className="capitalize">--select group ledger--</option>
                            {
                                groupLedgerDropdown.map((option) => (
                                    <option className="capitalize" key={option.id} value={option.id}>{option.group_ledger_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.group_ledger?.message}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Group type</p>
                        <select
                            placeholder="group type name"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="group_type"
                            {...register('group_type')}
                            value={selectedGroupType}
                            onChange={(e) => {setSelectedGroupType(e.target.value)}}
                        >
                            <option value={""} className="capitalize">--select group type--</option>
                            {
                                groupTypeDropdown.map((option) => (
                                    <option className="capitalize" key={option.id} value={option.id}>{option.group_type_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.group_type?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Group Under</p>
                        <input
                            type="text"
                            placeholder="group under"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="account_under"
                            {...register('account_under')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.account_under?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-0 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg w-full p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey rounded-lg w-full p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}