import { useEffect, useRef, useState } from "react";
import { receivedTableListEndPoint } from "../../../../service/api";
import { useSelector } from "react-redux";
import { postAxiosWithToken } from "../../../../service/apiservice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { receivedBranchTransferType, reqReceivedBranchTransferType } from "../../../../constant";
import ItemRecieverFilter from "./itemrecieverfillter";
import Pagination from "../../../../components/Pagination";
import { useKeyPress } from "../../../../hooks/useKeyPress";

export default function ItemReciever({ status, currentData, setCurrentData }) {

    const navigate = useNavigate();
    const searchRef = useRef();


    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);

    const [filteredData, setFilteredData] = useState({})

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        transfer_status: status.toString(),
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination') )? 10 : null,
        page: 1,
        tag_status:null
        
    });

    const { actions,pagination } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getTableData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').style.display === 'block') {
                document.getElementById('filteroptions').style.display = 'none'
            } else {
                document.getElementById('filteroptions').style.display = 'block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    const getTableData = async () => {
        var response = await postAxiosWithToken({
            url: receivedTableListEndPoint,
            body: tableFilters
        })

        if (response != null) {
            setFilteredData(response.data.list);
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }


    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          page: value,
        }));
      };
    
      const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          items_per_page: value,
          page: 1,
        }));
      };


    
    return (
<>
        <div id="filteroptions" className='my-[25px]' style={{display: 'none'}}>
        <p className="font-[600] text-[18px]">Filters</p>
        <ItemRecieverFilter tab={status} searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />
    </div>
        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                <table className="w-full">
                    <thead className="border bg-table-head">
                        <tr>
                            <td className="p-[10px] text-table-head-font text-[16px]">Transfer ID</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Transfer From</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Transfer To</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">No.of Item</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Receive Item</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Receive Date</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Status</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Authority</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px] text-center">Actions</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-[#fff]">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={9}>Loading...</td>
                            </tr> :
                                filteredData.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                    filteredData.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px]">{index + 1}</td>
                                            <td className="p-[10px] capitalize">{item.transfer_from}</td>
                                            <td className="p-[10px] capitalize">{item.transfer_to}</td>
                                            <td className="p-[10px] capitalize">{item.no_of_item}</td>
                                            <td className="p-[10px] capitalize">{item.no_of_receive}</td>
                                            <td className="p-[10px] capitalize">{item.received_date ? item.received_date : '-'}</td>
                                            <td className="p-[10px]">
                                                <div style={{backgroundColor: item.bgcolor, color: item.color}} className="p-[10px] rounded-2xl">{item.status}</div>
                                            </td>
                                            <td className="p-[10px] capitalize">{item.authority_name}</td>

                                            <td className="p-[10px] text-center">
                                                <div className="flex gap-3 justify-center">
                                                    <div onClick={() => {
                                                        if (actions.viewPermit) {
                                                            setCurrentData(item);
                                                            if (item.transfer_status == reqReceivedBranchTransferType) {
                                                                localStorage.setItem('btrnasfer_form_mode', 'view'); localStorage.setItem('btransfer_form_id', item.id.toString()); navigate('stock-reciever-form');
                                                            } else {
                                                                localStorage.setItem('branchreturn_form_mode', 'create'); localStorage.setItem('branchreturn_form_id', item.id.toString()); navigate('stock-return-form');
                                                            }
                                                        } else if (item.transfer_status == receivedBranchTransferType) {
                                                            toast.error("You don't have permission for view")
                                                        }
                                                    }} className="cursor-pointer hover:scale-125">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 32 32"><path fill="currentColor" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25"/><path fill="currentColor" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4"/></svg>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                        }

                    </tbody>
                </table>
            </div>

            {pagination === true ? 
                <div >
                <Pagination
      totalPages={totalPages}
      currentPage={tableFilters.page}
      setCurrentPage={pageHandler}
      entries={tableFilters.items_per_page}
      entriesHandler={entriesHandler}
      />
            </div>:""}

            </>
    );
}