import { Link } from "react-router-dom";




export function Maintanance() {

    return (
        <>

            <main className="h-screen w-full flex flex-col justify-center items-center bg-primary-btn">
                <div className="bg-[#D7B56D] px-2 text-3xl text-white rounded">
                    Site Under Maintanance
                </div>
                <p>Contact Administrator</p>
            </main>
        </>
    )

}