import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { StockTagWiseReportListEndPoint, stockSummaryEndPoint, stockTagWiseEndPoint } from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import { datetimetodate } from "../../../utilities/datetime";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import StockTagWiseReportForm from "./stocktagwisereportform";
import StockTagWisereportFilter from "./stocktagwisereportfilter";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function StockTagWiseReport() {

    const searchRef = useRef();
    const [stockList, setStockList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);

    console.log(stockList)


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Sales Daily Report"


    function closeExcelModal() {
        setExcelOpen(false)
    }


    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        metal_type: null,
        item_name: null,
        location: null,
        search: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);


    useEffect(() => {
        if (stockList.length > 0) {
            let firstData = Object.keys(stockList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [stockList])

    useEffect(() => {
        getCustomerListData(tableFilters)
    }, [tableFilters])



    const getCustomerListData = async (body) => {
        var response = await postAxiosWithToken({
            url: stockTagWiseEndPoint,
            body: body
        })

        if (response !== null) {
            setStockList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                    Stock Wise Tag Report
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={stockList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={stockList} fileName={fileName} />
                </div>
            </div>
            <StockTagWiseReportForm searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <StockTagWisereportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }

                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[13px] uppercase font-[700]">
                        <tr>
                            <td className="p-[10px]">S.No</td>
                            <td className="p-[10px]">Tag Id</td>
                            <td className="p-[10px]">Metal Type</td>
                            <td className="p-[10px]">item name</td>
                            <td className="p-[10px]">Sub item name</td>
                            <td className="p-[10px]">counter</td>
                            <td className="p-[10px]">Gross Weight</td>
                            <td className="p-[10px]">Net Weight</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-menu-head-bg">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={8}>Loading...</td>
                            </tr> :
                                stockList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                    stockList.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px] capitalize">{index + 1}</td>
                                            <td className="p-[10px] capitalize">{item.tag_number}</td>
                                            <td className="p-[10px] capitalize">{item.metal_type}</td>
                                            <td className="p-[10px] capitalize">{item.item_name}</td>
                                            <td className="p-[10px] capitalize">{item.subitem_name}</td>
                                            <td className="p-[10px] capitalize">{item.counter}</td>
                                            <td className="p-[10px] capitalize">{item.gross_weight}</td>
                                            <td className="p-[10px] capitalize">{item.net_weight}</td>
                                        </tr>
                                    ))
                        }

                        {/* <tr className="border bg-[F9F9FA]">
                            <td className="p-[10px]  capitalize"></td>
                            <td className="p-[10px] text-black font-[500] capitalize">Total</td>
                            <td className="p-[10px] capitalize"></td>
                            <td className="p-[10px] capitalize"></td>
                            <td className="p-[10px] text-black font-[500] capitalize"></td>
                            <td className="p-[10px] text-black font-[500] capitalize">963</td>
                            <td className="p-[10px]  text-black font-[500] capitalize">852</td>
                            <td className="p-[10px] text-black font-[500] capitalize">8520</td>
                        </tr> */}

                    </tbody>
                </table>
            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}