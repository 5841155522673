import { useEffect, useState } from "react";
import { accountheadListEndPoint, assignDesignerEndPoint, userListEndPoint } from "../../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../../service/apiservice";
import { isValidForm } from "../../../../utilities/formvalidation";
import { convertToDate } from "../../../../utilities/datetime";
import { addDays, differenceInDays, subDays } from "date-fns";
import toast from "react-hot-toast";

export default function AssignDesignerForm({ inPopUp=true, items, setItems, orderDetails, closeModel, getData }) {

    const [formValues, setFormValues] = useState({
        issue_date: new Date().toLocaleDateString('en-CA'),
        est_due_date: orderDetails?.due_date,
        no_of_days: parseInt(orderDetails?.no_of_days) - 1,
        remainder_date: convertToDate(subDays(new Date(orderDetails?.due_date?.split('-')?.join('/')), 1))
    })

    const [errors, setErrors] = useState({});

    const [vendorDropDown, setVendorDropDown] = useState([])
    const [userDropDown, setUserDropDown] = useState([])

    useEffect(() => {
        getVendorDropDown();
        getUserDropDown();
    }, [])

    const getVendorDropDown = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response != null) {
            setVendorDropDown(response.data.list)
        }
    }

    const getUserDropDown = async () => {
        var response = await getAxios({
            url: userListEndPoint
        })

        if (response != null) {
            setUserDropDown(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'no_of_days') {
            if (formValues.issue_date) {
                setFormValues((prevState) => ({
                    ...prevState,
                    remainder_date: convertToDate(addDays(new Date(prevState.issue_date), value))
                }))
            }
        } else if (name == 'issue_date') {
            setFormValues((prevState) => ({
                ...prevState,
                remainder_date: convertToDate(addDays(new Date(value), prevState.no_of_days))
            }))
        } else if (name == 'remainder_date') {
            setFormValues((prevState) => ({
                ...prevState,
                no_of_days: differenceInDays(new Date(value), new Date(prevState.issue_date))
            }))
        }

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let orderIssueForm = new FormData(e.target)

        var err = {}

        for (var field of Object.entries(Object.fromEntries(orderIssueForm.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isValidForm(Object.fromEntries(orderIssueForm.entries()))) {
            assignDesinger(Object.fromEntries(orderIssueForm.entries()))
        }
    }

    const assignDesinger = async (body) => {
        body['order_id'] = orderDetails?.order_id
        body['order_items'] = []
        for (var i of items) {
            body['order_items'].push(i.id)
        }
        var response = await postAxiosWithToken({
            url: assignDesignerEndPoint,
            body: body
        })

        if (response != null) {
            getData(orderDetails?.order_id)
            toast.success(response.message)
            closeModel()
            setItems([])
        }
    }

    return (
        <form onSubmit={handleSubmit} className={`${inPopUp ? "" : "flex gap-3 items-center "}`}>
            <div className={`${inPopUp ? "p-4 border rounded-lg mb-3 min-w-full" : "grid grid-cols-6 gap-3"}`}>
                <div className={`${inPopUp ? "my-[2px]": "mt-[2px]"} `}>
                    <p className="mb-[2px] text-[12px]">Vendor <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Vendor"
                        className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] bg-white outline-none"
                        name="vendor"
                        value={formValues.vendor}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select vendor-</option>
                        {
                            vendorDropDown.map((item) => (
                                <option key={'vendor' + item.id} value={item.id}>{item.account_head_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.vendor}</p>
                </div>
                <div className={`${inPopUp ? "grid grid-cols-2 gap-3" : "grid grid-cols-5 gap-3 col-span-5"}`}>
                    <div className="">
                        <p className="mb-[2px] text-[13px]">Issue Date <span className="text-red-500">*</span></p>
                        <input
                            type="date"
                            placeholder="Issue Date"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="issue_date"
                            value={formValues.issue_date}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.issue_date}</p>
                    </div>
                    <div className="">
                        <p className="mb-[3px] text-[13px]">Est Due Date <span className="text-red-500">*</span></p>
                        <input
                            type="date"
                            placeholder="Est Due Date"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="est_due_date"
                            value={formValues.est_due_date}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.est_due_date}</p>
                    </div>
                    <div className="">
                        <p className="mb-[3px] text-[13px]">Remainder Days <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Remainder Days"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="no_of_days"
                            value={formValues.no_of_days}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.no_of_days}</p>
                    </div>
                    <div className="">
                        <p className="mb-[3px] text-[13px]">Remainder Date <span className="text-red-500">*</span></p>
                        <input
                            type="date"
                            placeholder="Remainder Date"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="remainder_date"
                            value={formValues.remainder_date}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.remainder_date}</p>
                    </div>
                    <div className={`${inPopUp ? "my-[2px]": "mt-[2px]"} `}>
                        <p className="mb-[2px] text-[12px]">Assigned By <span className="text-red-500 ">*</span></p>
                        <select
                            placeholder="Assigned By"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[4px] text-[13px] bg-white outline-none"
                            name="assigned_by"
                            value={formValues.assigned_by}
                            onChange={onValueUpdate}
                        >
                            <option value={""}>--select vendor-</option>
                            {
                                userDropDown.map((item) => (
                                    <option key={'vendor' + item.id} value={item.id}>{item.email}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.assigned_by}</p>
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-3 justify-end mt-[16px]">
                {inPopUp && <button
                    onClick={(e) => { e.preventDefault(); closeModel(-1) }}
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                >close</button> }
                <button
                    type="submit"
                    className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                >Assign</button>
            </div>
        </form>
    );
}