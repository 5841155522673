import { accountheadEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { isValidJson } from "../../../utilities/jsonvalidation";
import QRCode from "react-qr-code";

export default function AccountHeadDetails() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('View');
    const [currentAccountHead, setCurrentAccountHead] = useState({
        account_head_name: "",
        customer_type_name: "",
        account_head_code: "",
        account_type_name: "",
        group_name_name: "",
        credit_balance_rupee: "",
        credit_balance_gm: "",
        debit_balance_rupee: "",
        debit_balance_gm: "",
        pan_no: "",
        tin_no: "",
        gst_no: "",
        registered_name: "",
        gst_status: "",
        tax_payer_type: "",
        bussiness_type: ""

    });
    const [bankDetailsInit, setBankDetailsInit] = useState([]);
    const [addressDetailsInit, setAddressDetailsInit] = useState([]);
    const [contactDetailsInit, setContactDetailsInit] = useState([]);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {

        getAccountHeadDetails()
    }, [])

    const getAccountHeadDetails = async () => {
        var response = await getAxios({
            url: accountheadEndPoint + localStorage.getItem('accounthead_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                account_head_name: response.data?.accounthead_details?.account_head_name,
                customer_type_name: response.data?.accounthead_details?.customer_type_name,
                account_head_code: response.data?.accounthead_details?.account_head_code,
                account_type_name: response.data?.accounthead_details?.account_type_name,
                group_name_name: response.data?.accounthead_details?.group_name_name,
                upi_id: response.data?.accounthead_details?.upi_id,
                credit_balance_rupee: response.data?.accounthead_details?.credit_balance_rupee,
                credit_balance_gm: response.data?.accounthead_details?.credit_balance_gm,
                debit_balance_rupee: response.data?.accounthead_details?.debit_balance_rupee,
                debit_balance_gm: response.data?.accounthead_details?.debit_balance_gm,
                pan_no: response.data?.gst_detail?.pan_no,
                tin_no: response.data?.gst_detail?.tin_no,
                gst_no: response.data?.gst_detail?.gst_no,
                registered_name: response.data?.gst_detail?.registered_name,
                gst_status: response.data?.gst_detail?.gst_status,
                tax_payer_type: response.data?.gst_detail?.tax_payer_type,
                bussiness_type: response.data?.gst_detail?.bussiness_type
            }
            setAddressDetailsInit(response.data?.address_detail)

            setContactDetailsInit(response.data?.contact_detail)

            setBankDetailsInit(response.data?.bank_detail)




            setCurrentAccountHead(tempDetails)
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} AccountHead</p>
                <form>
                    <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">AccountHead Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">AccountHead Name</p>
                                <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.account_head_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Customer Type</p>
                                <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.customer_type_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Account Head Code</p>
                                <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.account_head_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Account Type</p>
                                <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.account_type_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Group Name</p>
                                <p className="text-primary-btn font-[400] text-[16px] hover:underline">{currentAccountHead.group_name_name}</p>
                            </div>

                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg mt-5">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Credit/Debit Details</p>
                        <div className="flex gap-10 justify-between items-center">
                            {currentAccountHead.upi_id && <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">UPI</p>
                                <QRCode value={'upi://pay?pa=' + currentAccountHead.upi_id} />
                            </div>}
                            <div className="w-full grid md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">

                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Credit Balance(RS)</p>
                                    <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.credit_balance_rupee} ₹</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Credit Balance(g)</p>
                                    <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.credit_balance_gm} gm</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Debit Balance(RS)</p>
                                    <p className="text-primary-btn font-[400] text-[16px]">{currentAccountHead.debit_balance_rupee} ₹</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Debit Balance(g)</p>
                                    <p className="text-primary-btn font-[400] text-[16px] hover:underline">{currentAccountHead.debit_balance_gm} gm</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Contact Details</p>
                        {
                            contactDetailsInit?.map((contact) => (
                                <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Email</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{contact.email_id}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Phone No</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{contact.mobile_number}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Website</p>
                                        <p className="text-primary-btn font-[400] text-[16px]"><Link to={contact.website} target="_blank">{contact.website}</Link></p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Landline</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{contact.std_code}-{contact.landline_number}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>


                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                        {
                            addressDetailsInit?.map((address) => (
                                <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Door No</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.door_no}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Street Name</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.street_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Area</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.area}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Taluk</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.taluk}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Postal</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.postal}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">District</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.district}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Pincode</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{address.pin_code}</p>
                                    </div>
                                    <div className="sm:my-[5px] ">
                                        <p className="mb-[5px] text-[16px]">Country </p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{isValidJson(address.country)?.name}</p>
                                    </div>
                                    <div className="sm:my-[5px] ">
                                        <p className="mb-[5px] text-[16px]">State</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{isValidJson(address.state)?.name}</p>
                                    </div>

                                </div>
                            ))
                        }
                    </div>



                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b  mb-4 pb-2">Bank Details</p>
                        {
                            bankDetailsInit?.map((bank) => (
                                <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5 sm:grid-cols-2 min-[400px]:grid-cols-2 p-[10px] my-[10px]">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Account Holder Name</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{bank.acc_holder_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Account No</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{bank.account_no}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">IFSC Code</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{bank.ifsc_code}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Bank Name</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{bank.bank_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">Branch Name</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{bank.branch_name}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[16px]">MICR No</p>
                                        <p className="text-primary-btn font-[400] text-[16px]">{bank.micr_code}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>


                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">GST Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[400px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pan Number</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.pan_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">TIN NO</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.tin_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST NO</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.gst_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Registered Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.registered_name}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Status</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.gst_status}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Type</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.tax_payer_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bussiness Type</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentAccountHead.bussiness_type}</p>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}