import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { accountheadListEndPoint, branchListEndPoint, centgroupListEndPoint, clarityListEndPoint, colorListEndPoint, cutListEndPoint, departmentListEndPoint, designationListEndPoint, floorListEndPoint, locationListEndPoint, metalEndPoint, metalListEndPoint, shapeListEndPoint, stoneListEndPoint, caratrateEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { phoneRegExp } from "../../../constant";
import { toast } from "react-hot-toast";

export default function CaratrateForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentCaratrate, setCurrentCaratrate] = useState({});

    const [stoneDropdown, setStoneDropdown] = useState([]);
    const [designerDropdown, setDesignerDropdown] = useState([]);
    const [shapeDropdown, setShapeDropdown] = useState([]);
    const [cutDropdown, setCutDropdown] = useState([]);
    const [colorDropdown, setColorDropdown] = useState([]);
    const [clarityDropdown, setClarityDropdown] = useState([]);
    const [centgroupDropdown, setCentgroupDropdown] = useState([]);


    // const [selectedstone, setSelectedStone] = useState([]);
    // const [selecteddesigner, setSelectedDesigner] = useState([]);
    // const [selectedshape, setSelectedShape] = useState([]);
    // const [selectedcut, setSelectedCut] = useState([]);
    // const [selectedcolor, setSelectedColor] = useState([]);
    // const [selectedclarity, setSelectedClarity] = useState([]);
    // const [selectedcentgroup, setSelectedCentgroup] = useState([]);




    const [selectedMetal, setSelectedMetal] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');


    const schema = yup
        .object({
            designer: yup.string().required(),
            stone: yup.string().required(),
            shape: yup.string().required(),
            cut: yup.string().required(),
            color: yup.string().required(),
            clarity: yup.string().required(),
            cent_group: yup.string().required(),
            purchase_rate: yup.string().required(),
            selling_rate: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            designer: currentCaratrate?.designer,
            stone: currentCaratrate?.stone,
            shape: currentCaratrate?.shape,
            cut: currentCaratrate?.cut,
            color: currentCaratrate?.color,
            clarity: currentCaratrate?.clarity,
            cent_group: currentCaratrate?.cent_group,
            purchase_rate: currentCaratrate?.purchase_rate,
            selling_rate: currentCaratrate?.selling_rate
        } : {
            designer: "",
            stone: "",
            shape: "",
            cut: "",
            color: "",
            clarity: "",
            cent_group: "",
            purchase_rate: "",
            selling_rate: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            reset()
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getStoneDropdownData();
        getDesignerDropdownData();
        getShapeDropdownData();
        getCutDropdownData();
        getColorDropdownData();
        getClarityDropdownData();
        getCentgroupDropdownData();

        setFormMode(localStorage.getItem('caratrate_form_mode'))

        if (localStorage.getItem('caratrate_form_mode') === 'update') {
            getCaratrateDetails()
        }
    }, [])

    const getCaratrateDetails = async () => {
        var response = await getAxios({
            url: caratrateEndPoint + localStorage.getItem('caratrate_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                designer: response.data?.designer,
                stone: response.data?.stone,
                shape: response.data?.shape,
                cut: response.data?.cut,
                color: response.data?.color,
                clarity: response.data?.clarity,
                cent_group: response.data?.cent_group,
                purchase_rate: response.data?.purchase_rate,
                selling_rate: response.data?.selling_rate
            }

            setCurrentCaratrate(tempDetails)
        }
    }

    const getStoneDropdownData = async () => {
        var response = await getAxios({
            url: stoneListEndPoint
        })

        if (response !== null) {
            setStoneDropdown(response.data.list)
        }
    }

    const getDesignerDropdownData = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response !== null) {
            setDesignerDropdown(response.data.list)
        }
    }

    const getShapeDropdownData = async () => {
        var response = await getAxios({
            url: shapeListEndPoint
        })

        if (response !== null) {
            setShapeDropdown(response.data.list) 
        }
    }

    const getCutDropdownData = async () => {
        var response = await getAxios({
            url: cutListEndPoint
        })

        if (response !== null) {
            setCutDropdown(response.data.list) 
        }
    }

    const getColorDropdownData = async () => {
        var response = await getAxios({
            url: colorListEndPoint
        })

        if (response !== null) {
            setColorDropdown(response.data.list) 
        }
    }


    const getClarityDropdownData = async () => {
        var response = await getAxios({
            url: clarityListEndPoint
        })

        if (response !== null) {
            setClarityDropdown(response.data.list) 
        }
    }

    const getCentgroupDropdownData = async () => {
        var response = await getAxios({
            url: centgroupListEndPoint
        })

        if (response !== null) {
            setCentgroupDropdown(response.data.list) 
        }
    }



    const onSubmit = async (data) => {
        if (localStorage.getItem('caratrate_form_mode') === 'create') {

            let createBody = {
                designer: data.designer,
                stone: data.stone,
                shape: data.shape,
                cut: data.cut,
                color: data.color,
                clarity: data.clarity,
                cent_group: data.cent_group,
                purchase_rate: data.purchase_rate,
                selling_rate: data.selling_rate,
            }

            createCaratrate(createBody)

        } else {

            let updateBody = {
                designer: data.designer,
                stone: data.stone,
                shape: data.shape,
                cut: data.cut,
                color: data.color,
                clarity: data.clarity,
                cent_group: data.cent_group,
                purchase_rate: data.purchase_rate,
                selling_rate: data.selling_rate,
            }
            
            updateCaratrate(updateBody)
        }
    }

    const createCaratrate = async (body) => {
        var response = await postAxiosWithToken({
            url: caratrateEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/masters/caratrate')
        }
    }

    const updateCaratrate = async (body) => {
        var response = await putAxiosWithToken({
            url: caratrateEndPoint + localStorage.getItem('caratrate_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/masters/caratrate')
        }
    }

    const clearData = () => {
        reset()
        setCurrentCaratrate({
            designer: "",
            stone: "",
            shape: "",
            cut: "",
            color: "",
            clarity: "",
            cent_group: "",
            purchase_rate: "",
            selling_rate: ""
        })
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Caratrate</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg border">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Caratrate Details</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Designer<span className="text-red-500">*</span></p>
                                <select
                                    placeholder="designer"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="designer"
                                    {...register('designer')}
                                >
                                    <option value={""}>--select designer--</option>
                                    {
                                        designerDropdown?.map((option) => (
                                            <option className="capitalize" key={"designer" + option.account_head_name} value={option.id}>{option.account_head_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.designer?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Stone <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="stone"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="stone"
                                    {...register('stone')}
                                >
                                    <option value={""}>--select stone--</option>
                                    {
                                        stoneDropdown.map((option) => (
                                            <option className="capitalize" key={"stone" + option.stone_name} value={option.id}>{option.stone_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.stone?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Shape <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="shape"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="shape"
                                    {...register('shape')}
                                >
                                    <option value={""}>--select shape--</option>
                                    {
                                        shapeDropdown?.map((option) => (
                                            <option className="capitalize" key={"shape" + option.shape_name} value={option.id}>{option.shape_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.shape?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Cut <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="cut"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="cut"
                                    {...register('cut')}
                                >
                                    <option value={""}>--select cut--</option>
                                    {
                                        cutDropdown?.map((option) => (
                                            <option className="capitalize" key={"cut" + option.cut_name} value={option.id}>{option.cut_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.cut?.message}</p>
                            </div>

                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Color <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="color"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="color"
                                    {...register('color')}
                                >
                                    <option value={""}>--select color--</option>
                                    {
                                        colorDropdown?.map((option) => (
                                            <option className="capitalize" key={"color" + option.color_name} value={option.id}>{option.color_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.color?.message}</p>
                            </div>

                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Clarity <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="clarity"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="clarity"
                                    {...register('clarity')}
                                >
                                    <option value={""}>--select clarity--</option>
                                    {
                                        clarityDropdown?.map((option) => (
                                            <option className="capitalize" key={"clarity" + option.clarity_name} value={option.id}>{option.clarity_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.clarity?.message}</p>
                            </div>

                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Cent Group <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Metal"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="cent_group"
                                    {...register('cent_group')}
                                >
                                    <option value={""}>--select cent group--</option>
                                    {
                                        centgroupDropdown?.map((option) => (
                                            <option className="capitalize" key={"CentGroup" + option.centgroup_name} value={option.id}>{option.centgroup_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.cent_group?.message}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Purchase Rate <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Purchase Rate" 
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_rate"
                                    {...register('purchase_rate')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_rate?.message}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Selling Rate <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Selling Rate"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="selling_rate"
                                    {...register('selling_rate')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.selling_rate?.message}</p>
                            </div>
                            
                        </div>
                    </div>

                    {/* <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Purchase Details</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Tax % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder="Purchase caratrate percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_caratrate_percent"
                                    {...register('purchase_caratrate_percent')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_caratrate_percent?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">sur charge % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder="sur-charge percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_surcharge_percent"
                                    {...register('purchase_surcharge_percent')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_surcharge_percent?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Additional Charges <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder="Additional Charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_additional_charges"
                                    {...register('purchase_additional_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_additional_charges?.message}</p>
                            </div>
                            
                        </div>
                    </div> */}

                    {/* <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Sales Details</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Tax% <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder="Tax"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_caratrate_percent"
                                    {...register('sales_caratrate_percent')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_caratrate_percent?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">surcharge% <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder="surcharge"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_surcharge_percent"
                                    {...register('sales_surcharge_percent')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_surcharge_percent?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Additional Charges <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder="Additional Charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_additional_charges"
                                    {...register('sales_additional_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_additional_charges?.message}</p>
                            </div>
                            
                        </div>
                    </div> */}

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 mt-5 sticky bottom-[35px] bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                            className="capitalize bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}