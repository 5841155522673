import React, { useEffect, useState } from "react";
import { TEChart } from "tw-elements-react";
import ApexCharts from 'apexcharts';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { faker } from '@faker-js/faker';
import DatePicker1 from "../../components/Datepicker";
import Arrow from "../../../src/assets/images/arrowsymbol.svg"


import {
    Card,
    CardBody,
    CardHeader,
    Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import Linechart from "./linechart";
import Barchart from "./barchart";
import ApexChart from "./monthchart";
import ChartComponent from "./piechart";
import { useNavigate } from "react-router-dom";
import CircleChart from "./circlechart";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
import { branchListEndPoint, headercountEndPoint, purchaselistEndPoint, saleslistEndPoint, stocklistEndPoint } from "../../service/api";
import Popup from "../../components/Popup";
import Purchaseentrypopup from "./purchaseentrypopup";
import SalesDetailsPopup from "./salesdetails";
import Stockdetailspopup from "./stockdetails";
import { convertToDate } from "../../utilities/datetime";
import { addDays } from "date-fns";
import Dashboardfilter from "./dashboardfilter";
import { useSelector } from "react-redux";
import ApexChart2 from "./monthchart2";
import Chitdetails from "./chitdetails";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};




const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};



const chartConfig = {
    type: "bar",
    height: 240,
    series: [
        {
            name: "Sales",
            data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        title: {
            show: "",
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#61A375"],
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadius: 2,
            },
        },
        xaxis: {
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
            categories: [
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
        },
        grid: {
            show: true,
            borderColor: "#dddddd",
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 5,
                right: 20,
            },
        },
        fill: {
            opacity: 0.8,
        },
        tooltip: {
            theme: "dark",
        },
    },
};




export function Dashboard() {

    const navigate = useNavigate();

    const [headercount, setHeadercount] = useState([]);
    const [saleslist, setSaleslist] = useState([]);
    const [stocklist, setStocklist] = useState([]);
    const [purchaselist, setPurchaselist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [purchaseentrypopup, setPurchaseentryPopup] = useState(false);
    const [salesdetailspopup, setSalesdetailsPopup] = useState(false);
    const [stockdetailspopup, setStockdetailsPopup] = useState(false);

    const billingValue = headercount?.billing || 0;
    const estimationValue = headercount?.estimation || 0;
    const tag_entryValue = headercount?.tag_entry || 0;
    const purchaseValue = headercount?.purchase || 0;

    const [value, setValue] = useState({
        startDate: null,
        endDate: null
    });


    const { addT, now } = useSelector((state) => state.appConfig);

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
    });


    useEffect(() => {
        setTableFilters(tableFilters)
    }, [tableFilters])

    const closePurchaseentry = () => {
        setPurchaseentryPopup(!purchaseentrypopup)
    }

    const closeSalesDetails = () => {
        setSalesdetailsPopup(!salesdetailspopup)
    }

    const closeStockDetails = () => {
        setStockdetailsPopup(!stockdetailspopup)
    }

    useEffect(() => {
        getHeaderData(tableFilters)
        getSaleslistData(tableFilters)
        getStocklistData(tableFilters)
        getPurchaselistData(tableFilters)
    }, [tableFilters])

    const getHeaderData = async (body) => {
        var response = await postAxiosWithToken({
            url: headercountEndPoint,
            body: body
        })

        if (response !== null) {
            setHeadercount(response.data.list)
            setIsLoading(false)
        }
    }

    const getSaleslistData = async (body) => {
        var response = await postAxiosWithToken({
            url: saleslistEndPoint,
            body: body
        })

        if (response !== null) {
            setSaleslist(response.data.list)
            setIsLoading(false)
        }
    }

    const getStocklistData = async (body) => {
        var response = await postAxiosWithToken({
            url: stocklistEndPoint,
            body: body
        })

        if (response !== null) {
            setStocklist(response.data.list)
            setIsLoading(false)
        }
    }

    const getPurchaselistData = async (body) => {
        var response = await postAxiosWithToken({
            url: purchaselistEndPoint,
            body: body
        })

        if (response !== null) {
            setPurchaselist(response.data.list)
            setIsLoading(false)
        }
    }


    return (
        <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
            <div className="md:flex justify-between p-3">
                <div className="text-[20px] font-[700] my-auto">Overview</div>
                <div>
                    <Dashboardfilter filters={tableFilters} setFilters={setTableFilters} />
                </div>
                
            </div>

            

            <div className="w-full">
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                    <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between rounded  border border-[#61A375] items-center  ">
                        <div>
                            <div className="flex gap-2 w-[125px]">
                                <h3 class="font-[500] text-black text-[14px]">Total Billing</h3>
                                <div className="">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                                </div>
                            </div>
                            <p class="text-black text-[20px] mt-1">{headercount.billing}</p>
                            <p class="text-black text-[12px] mt-1">Today's</p>
                        </div>
                        <div>
                            <CircleChart color={["#DE5753"]} value={[headercount?.billing || 0]} />
                        </div>
                    </div>

                    <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between rounded border border-[#F1C837] items-center ">
                        <div>
                            <div className="flex gap-2 w-[150px]">
                                <h3 class="font-[500] text-black text-[14px]">Total Estimation</h3>
                                <div className="">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                                </div>
                            </div>
                            <p class="text-black text-[20px] mt-1">{headercount.estimation}</p>
                            <p class="text-black text-[12px] mt-1">Today's</p>
                        </div>

                        <div>
                            <CircleChart color={["#AD9FFF"]} value={[estimationValue]} />
                        </div>
                    </div>


                    <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between rounded border border-[#61A375] items-center">
                        <div>
                            <div className="flex gap-2 w-[125px]">
                                <h3 class="font-[500] text-black text-[14px]">Total Items</h3>
                                <div className="">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                                </div>
                            </div>
                            <p class="text-black text-[20px] mt-1">{headercount.tag_entry}</p>
                            <p class="text-black text-[12px] mt-1">Today's</p>
                        </div>

                        <div>
                            <CircleChart color={["#F1C837"]} value={[tag_entryValue]} />
                        </div>
                    </div>


                    <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between rounded border border-[#F1C837] items-center">
                        <div>
                            <div className="flex gap-2 w-[150px]">
                                <h3 class="font-[500] text-black text-[14px]">Total Purchase</h3>
                                <div className="">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                                </div>
                            </div>
                            <p class="text-black text-[20px] mt-1">{headercount.purchase}</p>
                            <p class="text-black text-[12px] mt-1">Today's</p>
                        </div>

                        <div>
                            <CircleChart color={["#DE5753"]} value={[purchaseValue]} />
                        </div>
                    </div>

                </div>
            </div>

            <Chitdetails tableFilters={tableFilters} />

            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-12 gap-4 px-1 py-6 text-gray-700">
                <div className="w-[100%] lg:col-span-5">
                    <Linechart tableFilters={tableFilters} />
                </div>
                <div className=" bg-white border border-[#D9D9D9] rounded-lg lg:col-span-7">
                    <Card>
                        <div className="flex justify-between p-6 mb-[-37px]">
                            <div className="font-[700] ">
                                Overall Purchase Vs Sales Statistics
                            </div>
                            <div className="font-[500] text-[#919EAB]">
                                View
                            </div>

                        </div>
                        <div className="mt-5">
                            <ApexChart tableFilters={tableFilters} />
                        </div>
                    </Card>
                </div>
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-12 gap-4" >
                <div className="w-[100%] lg:col-span-5">
                    <Barchart tableFilters={tableFilters} />
                </div>

                <div className=" bg-white border border-[#D9D9D9] rounded-lg lg:col-span-7">
                    <div className="flex justify-between p-5 border">
                        <div className="font-[700] text-[18px]">
                            New Purchase Entry
                        </div>
                        <div className="font-[500] text-[#919EAB]">
                            View
                        </div>
                    </div>

                    <div className="p-4">
                        <div class="shadow-md overflow-scroll max-h-[260px] sm:rounded-lg mt-[22px] border">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead class="bg-[#F1F5F9] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            BILL NO
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                CUSTOMER NAME
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                TOTAL AMT
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                TYPE
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <p>View</p>
                                        </th>

                                    </tr>
                                </thead>
                                {
                                    purchaselist.map((item) => (
                                        <tbody className="overflow-scroll w-full"     >
                                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" class="px-6 py-4 font-medium text-[#3F80CC] whitespace-nowrap dark:text-white">
                                                    {item.bill_no}
                                                </th>
                                                <td class="px-6 py-4">
                                                    {item.customer_name}
                                                </td>
                                                <td class="px-6 py-4">
                                                    {(item.total_amount).toFixed(2)}
                                                </td>
                                                <td class="px-6 py-4">
                                                    {item.type}
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div className="cursor-pointer hover:scale-125" onClick={(e) => { e.preventDefault(); localStorage.setItem('purchaseentry_form_id', item.id.toString()); setPurchaseentryPopup(!purchaseentrypopup) }}>
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.392 0 9.878 3.88 10.819 9c-.94 5.12-5.427 9-10.819 9c-5.392 0-9.878-3.88-10.818-9C2.122 6.88 6.608 3 12 3m0 16a9.005 9.005 0 0 0 8.778-7a9.005 9.005 0 0 0-17.555 0A9.005 9.005 0 0 0 12 19m0-2.5a4.5 4.5 0 1 1 0-9a4.5 4.5 0 0 1 0 9m0-2a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5" /></svg>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-12 gap-4 py-6 text-gray-700">
                <Card className="w-[100%] lg:col-span-5 border">
                    <div className="flex justify-between p-6 mb-[-55px]">
                        <div className="font-[700] ">
                            Sales and Returns
                        </div>
                    </div>
                    <div className="mt-8">
                        <ChartComponent />
                    </div>
                </Card>

                <div className=" bg-white border border-[#D9D9D9] rounded-lg lg:col-span-7">
                    <div className="flex justify-between p-5 ">
                        <div className="font-[700] text-[18px]">
                            Recent Sales
                        </div>
                        <div className="font-[500] text-[#919EAB]">
                            View
                        </div>
                    </div>

                    <div className="p-4 border-t">
                        <div class="shadow-md overflow-scroll max-h-[220px] sm:rounded-lg mt-[22px] border">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead class="bg-[#F1F5F9] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            #Bill No
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                CUSTOMER
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                TOTAL AMT
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <p>view</p>
                                        </th>

                                    </tr>
                                </thead>
                                {
                                    saleslist.map((item) => (
                                        <tbody className="overflow-scroll w-full">
                                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" class="px-6 py-4 font-medium text-[#3F80CC] whitespace-nowrap dark:text-white">
                                                    {item.bill_no}
                                                </th>
                                                <td class="px-6 py-4">
                                                    {item.customer_name}
                                                </td>
                                                <td class="px-6 py-4">
                                                    {(item.total_amount).toFixed(2)}
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div className="cursor-pointer hover:scale-125" onClick={(e) => { e.preventDefault(); localStorage.setItem('salesdetails_form_id', item.id.toString()); setSalesdetailsPopup(!salesdetailspopup) }}>
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.392 0 9.878 3.88 10.819 9c-.94 5.12-5.427 9-10.819 9c-5.392 0-9.878-3.88-10.818-9C2.122 6.88 6.608 3 12 3m0 16a9.005 9.005 0 0 0 8.778-7a9.005 9.005 0 0 0-17.555 0A9.005 9.005 0 0 0 12 19m0-2.5a4.5 4.5 0 1 1 0-9a4.5 4.5 0 0 1 0 9m0-2a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5" /></svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-12 gap-4 px-1 pb-6 text-gray-700">

                <div className="w-[100%] bg-white border border-[#D9D9D9] rounded-lg lg:col-span-5">
                    <Card className="w-[100%]">
                        <div className="flex justify-between p-6 mb-[-55px]">
                            <div className="font-[700] ">
                                Vendor Wise Sales
                            </div>
                            <div className="p-2 rounded-full border">
                                <img src={Arrow} alt="arrow" />
                            </div>
                        </div>
                        <div className="mt-5">
                            <ApexChart2 tableFilters={tableFilters} />
                        </div>
                    </Card>
                </div>
                <div className=" bg-white border border-[#D9D9D9] rounded-lg lg:col-span-7 ">
                    <div className="flex justify-between p-5 border">
                        <div className="font-[700] text-[18px]">
                            Item Stock Details
                        </div>
                        <div className="font-[500] text-[#919EAB]">
                            View
                        </div>
                    </div>

                    <div className="p-4">
                        <div class="shadow-md overflow-scroll max-h-[260px] sm:rounded-lg mt-[22px] border">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead class="bg-[#F1F5F9] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Item Name
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                Total stock
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <div class="flex items-center">
                                                Total Wgt
                                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                                </svg></a>
                                            </div>
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            <p>view</p>
                                        </th>

                                    </tr>
                                </thead>

                                {
                                    stocklist.map((item) => (
                                        <tbody className="overflow-scroll w-full"     >
                                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" class="px-6 py-4 font-medium text-[#3F80CC] whitespace-nowrap dark:text-white">
                                                    {item.item_name}
                                                </th>
                                                <td class="px-6 py-4">
                                                    {item.total_stock}
                                                </td>
                                                <td class="px-6 py-4">
                                                    {(item.weight).toFixed(2)}
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div className="cursor-pointer hover:scale-125" onClick={(e) => { e.preventDefault(); localStorage.setItem('stockdetails_form_id', item.id.toString()); setStockdetailsPopup(!stockdetailspopup) }}>
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.392 0 9.878 3.88 10.819 9c-.94 5.12-5.427 9-10.819 9c-5.392 0-9.878-3.88-10.818-9C2.122 6.88 6.608 3 12 3m0 16a9.005 9.005 0 0 0 8.778-7a9.005 9.005 0 0 0-17.555 0A9.005 9.005 0 0 0 12 19m0-2.5a4.5 4.5 0 1 1 0-9a4.5 4.5 0 0 1 0 9m0-2a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5" /></svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>



            <div className="">
                <Popup title={` Puchase Details`} setIsOpen={setPurchaseentryPopup} isOpen={purchaseentrypopup} closeModal={closePurchaseentry}>
                    <Purchaseentrypopup closeModal={closePurchaseentry} />
                </Popup>
            </div>

            <div className="">
                <Popup title={` Sales Details`} setIsOpen={setSalesdetailsPopup} isOpen={salesdetailspopup} closeModal={closeSalesDetails}>
                    <SalesDetailsPopup closeModal={closeSalesDetails} />
                </Popup>
            </div>

            <div className="">
                <Popup title={` Stock Details`} setIsOpen={setStockdetailsPopup} isOpen={stockdetailspopup} closeModal={closeStockDetails}>
                    <Stockdetailspopup closeModal={closeStockDetails} />
                </Popup>
            </div>
        </div>
    )
}