import { useEffect, useState } from "react";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { accountheadListEndPoint, branchListEndPoint, generateMeltingIssueNumberEndPoint, meltingIssueEndPoint, metalListEndPoint, oldMetalCategoryListEndPoint, transferCreationListEndPoint } from "../../../../service/api";
import { convertToDate } from "../../../../utilities/datetime";
import { addDays, differenceInDays } from "date-fns";
import { isOptionalValidForm, isValidForm } from "../../../../utilities/formvalidation";
import toast from "react-hot-toast";

export default function IssueForm({ formValues, setFormValues, formMode, setFormMode, currentItem, getData , setnetvalue, netValue }) {
    

    const [bagNumberList, setBagNumberList] = useState([]);
    const [designerDropDown, setDesignerDropDown] = useState([]);
    const [categoryDropDown, setCategoryDropDown] = useState([]);
    const [metalDropDown, setMetalDropDown] = useState([]);
    const [branchDropDown, setBranchDropDown] = useState([]);

    const [errors, setErrors] = useState({});
    console.log("netValue",errors)

    useEffect(() => {
        getBagNumberList();
        generateMeltingIssueNumber();
        getDesignerDropDownList();
        getCategoryDropDownList();
        getMetalListData();
        getBranchDropDownList();
    }, [])

    const generateMeltingIssueNumber = async () => {
        var response = await getAxios({
            url: generateMeltingIssueNumberEndPoint
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                melting_issue_id: response.data
            }))
        }
    }

    const getBagNumberList = async () => {
        var response = await getAxios({
            url: transferCreationListEndPoint
        })

        if (response != null) {
            setBagNumberList(response.data.list)
        }
    }

    const getDesignerDropDownList = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response != null) {
            setDesignerDropDown(response.data.list)
        }
    }

    const getCategoryDropDownList = async () => {
        var response = await getAxios({
            url: oldMetalCategoryListEndPoint
        })

        if (response != null) {
            setCategoryDropDown(response.data.list)
        }
    }

    const getMetalListData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropDown(response.data.list)
        }
    }

    const getBranchDropDownList = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropDown(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'transfer_creation_details') {
            for (var i of bagNumberList) {
                if (i.id == value) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        smith: i.smith,
                        metal: i.metal,
                        gross_weight: i.total_gross_weight,
                        net_weight: i.total_net_weight
                    }))
                    setnetvalue(i.total_net_weight)
                    break;
                }
            }
        } else if (name == 'return_days') {
            if (formValues.issued_date) {
                setFormValues((prevState) => ({
                    ...prevState,
                    return_date: convertToDate(addDays(new Date(prevState.issued_date), value))
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    return_date: value.length > 0 ? "" : `return_date field is required`
                }))
            }
        } else if (name == 'issued_date') {
            if (formValues.return_days) {
                setFormValues((prevState) => ({
                    ...prevState,
                    return_date: convertToDate(addDays(new Date(value), prevState.return_days))
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    return_date: value.length > 0 ? "" : `return_date field is required`
                }))
            }
        } else if (name == 'return_date') {
            setFormValues((prevState) => ({
                ...prevState,
                return_days: differenceInDays(new Date(value), new Date(prevState.issued_date))
            }))
            setErrors((prevState) => ({
                ...prevState,
                return_days: value.length > 0 ? "" : `return_date field is required`
            }))
        } else if (name == 'stone_weight') {
            if (netValue === "" || netValue <= 0 || netValue <= value ) {
                setFormValues((prevState) => ({
                    ...prevState,
                    net_weight: ""
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    stone_weight: netValue <= value ? `stone_weight greater then netwight` : ""
                }))
            } else {
                setFormValues((prevState) => ({
                    ...prevState,
                    net_weight: netValue - parseFloat(value)
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    stone_weight: value.length > 0 ? "" : `stone_weight field is required`
                }))
            }
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value 
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`,
            stone_weight: netValue <= value ? `stone_weight greater then netwight` : ""
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var formData = new FormData(e.target);

        var err = {}

        for (var field of Object.entries(Object.fromEntries(formData.entries()))) {
        
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(Object.fromEntries(formData.entries()), ['notes'])) {
            if (formMode == 'create') {
                createMeltingIssue(Object.fromEntries(formData.entries()))
            } else {
                updateMeltingIssue(Object.fromEntries(formData.entries()))
            }
        }
    }

    const createMeltingIssue = async (data) => {
        var response = await postAxiosWithToken({
            url: meltingIssueEndPoint,
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForm();
            getData();
        }
    }

    const updateMeltingIssue = async (data) => {
        var response = await putAxiosWithToken({
            url: meltingIssueEndPoint + currentItem.id + "/",
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForm();
            getData();
        }
    }

    const resetForm = () => {
        setFormValues({
            transfer_creation_details: "",
            branch: "",
            smith: "",
            issued_category: "",
            issued_date: new Date().toLocaleDateString('en-CA'),
            return_days: "",
            return_date: "",
            metal: "",
            bullion_rate: "",
            stone_weight: "",
            gross_weight: "",
            net_weight: "",
            notes: "",
        })
        setErrors({
            transfer_creation_details: "",
            branch: "",
            issued_category: "",
            return_days: "",
            return_date: "",
            bullion_rate: "",
            stone_weight: ""
        })
        generateMeltingIssueNumber();
        setFormMode('create');
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-x-2">
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Bag No <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Bag No"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="transfer_creation_details"
                        value={formValues.transfer_creation_details}
                        onChange={(e) => {
                            if (formMode == 'create') {
                                onValueUpdate(e)
                            }
                        }}
                        readOnly={formMode == 'update' ? true : false}
                    >
                        <option value={""}>--select bag no--</option>
                        {
                            bagNumberList.map((option) => (
                                <option key={option.id} value={option.id}>{option.refference_number}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[10px]">{errors.transfer_creation_details}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Melting Issue Id</p>
                    <input
                        type="text"
                        placeholder="Melting Issue Id"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="melting_issue_id"
                        value={formValues.melting_issue_id}
                        readOnly
                    />
                </div>
                {
                    localStorage.getItem('branch') == 'true' && <div className="my-[5px] col-span-2">
                        <p className="mb-[5px] text-[13px]">Branch <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Branch"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                            name="branch"
                            value={formValues.branch}
                            onChange={onValueUpdate}
                        >
                            <option value="">--select branch--</option>
                            {
                                branchDropDown.map((item, index) => (
                                    <option value={item.id} key={'branch' + index}>{item.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[10px]">{errors.branch}</p>
                    </div>
                }
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Smith</p>
                    <select
                        placeholder="Smith"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="smith"
                        value={formValues.smith}
                        disabled
                    >
                        <option value={""}>--select smith--</option>
                        {
                            designerDropDown.map((item) => (
                                <option key={'designer' + item.id} value={item.id}>{item.account_head_name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Category <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Category"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="issued_category"
                        value={formValues.issued_category}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select category--</option>
                        {
                            categoryDropDown.map((item) => (
                                <option key={'category'+item.id} value={item.id}>{item.category_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[10px]">{errors.issued_category}</p>
                </div>
                <div className="my-[5px] ">
                    <p className="mb-[5px] text-[13px]">Date <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="Date"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="issued_date"
                        value={formValues.issued_date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.issued_date}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Due Days <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Due Days"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="return_days"
                        value={formValues.return_days}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.return_days}</p>
                </div>
                <div className="my-[5px] ">
                    <p className="mb-[5px] text-[13px]">Est. Melt Return <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="Est. Melt Return"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="return_date"
                        value={formValues.return_date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.return_date}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Metal</p>
                    <select
                        placeholder="Metal"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="metal"
                        value={formValues.metal}
                        disabled
                    >
                        <option value={""}>--select metal--</option>
                        {
                            metalDropDown.map((option) => (
                                <option key={option.id} value={option.id}>{option.metal_name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Bullion Rate <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Bullion Rate"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="bullion_rate"
                        value={formValues.bullion_rate}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.bullion_rate}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Stone Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Stone Weight"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="stone_weight"
                        value={formValues.stone_weight}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.stone_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Gross Weight</p>
                    <input
                        type="number"
                        placeholder="Gross Weight"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="gross_weight"
                        value={formValues.gross_weight}
                        readOnly
                    />
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Net Weight</p>
                    <input
                        type="number"
                        placeholder="Net Weight"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="net_weight"
                        value={formValues.net_weight}
                        readOnly
                    />
                </div>
                <div className="my-[5px] col-span-2">
                    <p className="mb-[5px] text-[13px]">Remark</p>
                    <textarea
                        name="notes"
                        rows="3"
                        placeholder="Remark"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        value={formValues.notes}
                        onChange={onValueUpdate}
                    ></textarea>
                </div>

                <button
                    id="backnav"
                    onClick={(e) => { e.preventDefault(); resetForm()}}
                    className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[13px] outline-none border-none"
                >reset <span className="lowercase">(esc)</span></button>
                <button
                    type="submit"
                    className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[13px] outline-none border-none"
                >save<span className="lowercase">(ctrl+s)</span></button>
            </div>
        </form>
    );
}