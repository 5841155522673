import { useEffect, useState } from "react";
import { paymentProviderEndPoint } from "../../../../service/api";
import { getAxios } from "../../../../service/apiservice";

export default function PaymentProviderOptions({ method, id }) {

    const [providersDropDown, setProviderDropDown] = useState([]);

    useEffect(() => {
        getPaymentProviderList();
    }, [])

    const getPaymentProviderList = async () => {
        var response = await getAxios({
            url: paymentProviderEndPoint + method + '/'
        })

        if (response != null) {
            setProviderDropDown(response.data.list)
        }
    }
    
    return (
        <select id={id} className="sm:mb-[2px] border border-gray-300 rounded-l-lg w-[40%] p-[4px] text-[13px] outline-none">
            {
                providersDropDown.map((item) => (
                    <option>{item.payment_provider_name}</option>
                ))
            }
        </select>
    );
}