import Popup from "../../../components/Popup";
import StoneForm from "./stoneform";
import DiamondForm from "./diamondform";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { TagvalueEndPoint, calculationtypeDropdownEndPoint, counterListEndPoint, getTagNumbersListEndPoint, lotEndPoint, subitemEndPoint, subitemListEndPoint, tagEndPoint, tagListEndPoint, taggingEndPoint, weightTypeListEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { cararWeightType, caratRateType, fixedCalcId, grossWeightType, pergramCalId, weightCalId } from "../../../constant";
import toast from "react-hot-toast";
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";
import ReactToPrint from "react-to-print";
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";

export default function ItemTagForm() {

    let componentRef = useRef();

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [itemFormMode, setItemFormMode] = useState('create');
    const [lotDetails, setLotDetails] = useState({});

    const [weightTypeList, setWeightTypeList] = useState([]);

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [openAddItems, setOpenAdditems] = useState(false);

    const [particularsList, setParticularsList] = useState([]);

    const [stonePopup, setStonePopup] = useState(false);
    const [diamondPopup, setDiamondPopup] = useState(false);

    const [stoneList, setStoneList] = useState([]);
    const [diamondList, setDiamondList] = useState([]);

    const [itemList, setItemList] = useState([]);
    const [subItemList, setSubItemList] = useState([]);
    const [counterList, setCounterList] = useState([]);

    const [tagTypeList, setTagTypeList] = useState([]);
    const [calculationtypeDropdown, setCalculationTypeDropdown] = useState([]);

    const [allowZeroWeight, setAllowZeroWeight] = useState(false);
    const [selectedSubItem, setSelectedSubItem] = useState('');
    const [itemFormValues, setItemFormValues] = useState({
        net_weight: 0,
        gross_weight: 0,
        tag_weight: 0,
        cover_weight: 0,
        loop_weight: 0,
        other_weight: 0,
        max_wastage_percent: 0,
        max_flat_wastage: 0,
        max_making_charge_gram: 0,
        max_flat_making_charge: 0,
        max_pergram_rate: 0,
        max_fixed_rate: 0,

    });

    // console.log(itemFormValues,"123itemFormValuesitemFormValuesitemFormValuesitemFormValues")

    const generateQRCodeValue = (item) => {
        return `${item.tag_number}`;
    };

    const [roughSaleValue, setRoughSaleValue] = useState(0.00)
    const [maxroughSaleValue, setMaxRoughSaleValue] = useState(0)
    const [stoneDiamondDetials, setStoneDiamondDetails] = useState({})

    const [deleteTagItem, setDeleteTagItem] = useState({
        index: null,
        tagNumber: ''
    })

    const [errors, setErrors] = useState({});



    const closeStoneModal = () => {
        setStonePopup(!stonePopup)
    }

    const closeDiamondModal = () => {
        setDiamondPopup(!diamondPopup)
    }

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getLotDetails();
        getCounterDropDownData();
        getTagTypeDropDownData();
        getCalculationTypeDropdown();
        getWeightTypeDropDown();
    }, [])

    useEffect(() => {
        for (var i of subItemList) {
            if (i.id == selectedSubItem) {
                setAllowZeroWeight(i.allow_zero_weight);
                break;
            } else {
                setAllowZeroWeight(false);
            }
        }
    }, [selectedSubItem, subItemList])

    useEffect(() => {
        if (!diamondPopup) {
            var diamondWeight = 0.0

            var totalStoneRate = 0.0
            var totalDiamondRate = 0.0

            var tagWgt = itemFormValues.tag_weight === undefined || itemFormValues.tag_weight === null ? 0.0 : parseFloat(itemFormValues.tag_weight)
            var coverWt = itemFormValues.cover_weight === undefined || itemFormValues.cover_weight === null ? 0.0 : parseFloat(itemFormValues.cover_weight)
            var loopWgt = itemFormValues.loop_weight === undefined || itemFormValues.loop_weight === null ? 0.0 : parseFloat(itemFormValues.loop_weight)
            var otherWgt = itemFormValues.other_weight === undefined || itemFormValues.other_weight === null ? 0.0 : parseFloat(itemFormValues.other_weight)
            var grossWgt = itemFormValues.gross_weight === undefined || itemFormValues.gross_weight === null ? 0.0 : parseFloat(itemFormValues.gross_weight)

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    } else {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0

            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    } else {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }
            setItemFormValues((prevState) => ({
                ...prevState,
                net_weight: parseFloat(grossWgt) - (stoneWeight + diamondWeight + tagWgt + coverWt + loopWgt + otherWgt),
                diamond_weight: parseFloat(diamondWeight)
            }))
        }
    }, [diamondPopup])

    useEffect(() => {
        if (!stonePopup) {
            var stoneWeight = 0.0

            var totalStoneRate = 0.0
            var totalDiamondRate = 0.0

            var tagWgt = itemFormValues.tag_weight === undefined || itemFormValues.tag_weight === null ? 0.0 : parseFloat(itemFormValues.tag_weight)
            var coverWt = itemFormValues.cover_weight === undefined || itemFormValues.cover_weight === null ? 0.0 : parseFloat(itemFormValues.cover_weight)
            var loopWgt = itemFormValues.loop_weight === undefined || itemFormValues.loop_weight === null ? 0.0 : parseFloat(itemFormValues.loop_weight)
            var otherWgt = itemFormValues.other_weight === undefined || itemFormValues.other_weight === null ? 0.0 : parseFloat(itemFormValues.other_weight)
            var grossWgt = itemFormValues.gross_weight === undefined || itemFormValues.gross_weight === null ? 0.0 : parseFloat(itemFormValues.gross_weight)

            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    } else {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            var diamondWeight = 0.0
            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    } else {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }
            setItemFormValues((prevState) => ({
                ...prevState,
                net_weight: parseFloat(grossWgt) - (diamondWeight + stoneWeight + tagWgt + coverWt + loopWgt + otherWgt),
                stone_weight: parseFloat(stoneWeight)
            }))
        }
    }, [stonePopup])

    const getLotDetails = async () => {
        var response = await getAxios({
            url: lotEndPoint + localStorage.getItem('lot_form_id') + "/"
        })

        if (response !== null) {
            setLotDetails(response.data)
            let tempList = [];

            for (var item of response.data?.item_details) {
                var calcType = !item.is_subitem_detail ? item.item_calculation_type : item.subitem_calculation_type
                var dictItem = {
                    id: item.id,
                    item_details: item.item_details.id,
                    item_name: item.item_details.item_name,
                    sub_item_details: item.is_subitem_detail ? item.subitem_details.id : null,
                    sub_item_name: item.is_subitem_detail ? item.subitem_details.sub_item_name : null,
                    display_counter: item.is_subitem_detail ? item.sub_item_counter : item.item_counter,
                    measurement_type_name: item.measurement_type_name,
                    is_subitem_detail: item.is_subitem_detail,
                    tag_type: item.tag_type,
                    bulk_tag: item.bulk_tag,
                    metal: item.subitem_details?.metal,
                    calculation_type: !item.is_subitem_detail ? item.item_calculation_type : item.subitem_calculation_type,
                    min_fixed_rate: String(calcType) === String(fixedCalcId) ? item.is_subitem_detail ? item.subitem_min_fixedrate : item.item_min_fixedrate : null,
                    wastage_calculation: String(calcType) === String(weightCalId) ? item.is_subitem_detail ? item.subitem_min_wastage_calculation : item.item_min_wastage_calculation : null,
                    min_wastage_percent: String(calcType) === String(weightCalId) ? item.is_subitem_detail ? item.subitem_min_wastagepercent : item.item_min_wastagepercent : null,
                    min_flat_wastage: String(calcType) === String(weightCalId) ? item.is_subitem_detail ? item.subitem_min_flatwastage : item.item_min_flatwastage : null,
                    making_charge_calculation: String(calcType) === String(weightCalId) ? item.is_subitem_detail ? item.subitem_min_making_charge_calculation : item.item_min_making_charge_calculation : null,
                    min_making_charge_gram: String(calcType) === String(weightCalId) ? item.is_subitem_detail ? item.subitem_min_makingcharge_gram : item.item_min_makingcharge_gram : null,
                    min_flat_making_charge: String(calcType) === String(weightCalId) ? item.is_subitem_detail ? item.subitem_min_flat_makingcharge : item.item_min_flat_makingcharge : null,
                    min_pergram_rate: String(calcType) === String(pergramCalId) ? item.is_subitem_detail ? item.subitem_min_per_gram_rate : item.item_min_pergram_rate : null,
                    metal_rate: item.is_subitem_detail ? item.sub_item_metalrate : item.item_metal_rate,
                    tagged_grossweight: item.tagged_grossweight,
                    tagged_pieces: item.tagged_pieces,
                    total_gross_weight: item.gross_weight,
                    total_pieces: item.pieces,
                    stone_details: item.stone_details,
                    diamond_details: item.diamond_details
                }
                tempList.push(dictItem)
            }

            setItemList(tempList)
        }
    }

    const getWeightTypeDropDown = async () => {
        var response = await getAxios({
            url: weightTypeListEndPoint
        })

        if (response !== null) {
            setWeightTypeList(response.data.list)
        }
    }

    const getSubItemDropDownData = async (id) => {
        var response = await getAxios({
            url: subitemListEndPoint + id + "/"
        })

        if (response !== null) {
            setSubItemList(response.data.list)
        }
    }

    const getCounterDropDownData = async () => {
        var response = await getAxios({
            url: counterListEndPoint
        })

        if (response !== null) {
            setCounterList(response.data.list)
        }
    }

    const getTagTypeDropDownData = async () => {
        var response = await getAxios({
            url: tagListEndPoint
        })

        if (response !== null) {
            setTagTypeList(response.data.list)
        }
    }

    const getCalculationTypeDropdown = async () => {
        var response = await getAxios({
            url: calculationtypeDropdownEndPoint

        })

        if (response !== null) {
            setCalculationTypeDropdown(response.data.list)
        }
    }

    useEffect(() => {
        console.log(itemFormValues)
    },[itemFormValues])

    const onItemFormValueUpdate = async (e) => {

        var { name, value, checked } = e.target

        if (name === 'item_details') {
            setItemFormValues((prevState) => ({
                ...prevState,
                item_details: value
            }))

            localStorage.setItem('stone_diamond_list_id', value)

            setErrors((prevState) => ({
                ...prevState,
                item_details: value.length === 0 ? `item_details field is required` : '',
            }));

            for (var i of itemList) {
                if (String(i.id) === String(value)) {

                    if (value.length !== 0) {
                        getSubItemDropDownData(i.item_details)
                    }

                    setItemFormValues((prevState) => ({
                        ...prevState,
                        item_name: i.item_name,
                        display_counter: i.display_counter,
                        measurement_type_name: i.measurement_type_name,
                        tag_type: i.tag_type,
                        bulk_tag: i.bulk_tag,
                        metal: i?.metal,
                        calculation_type: i.calculation_type,
                        min_fixed_rate: i.min_fixed_rate,
                        wastage_calculation: i.wastage_calculation,
                        min_wastage_percent: i.min_wastage_percent,
                        min_flat_wastage: i.min_flat_wastage,
                        making_charge_calculation: i.making_charge_calculation,
                        min_making_charge_gram: i.min_making_charge_gram,
                        min_flat_making_charge: i.min_flat_making_charge,
                        min_pergram_rate: i.min_pergram_rate,
                        tag_count: !i.bulk_tag ? 1 : '',
                        metal_rate: i.metal_rate,
                        tagged_grossweight: i.tagged_grossweight,
                        tagged_pieces: i.tagged_pieces,
                        total_gross_weight: i.total_gross_weight,
                        total_pieces: i.total_pieces
                    }))
                    setSelectedSubItem(i.sub_item_details)
                    break;
                }
            }

            setErrors((prevState) => ({
                ...prevState,
                sub_item_details: '',
                display_counter: '',
                tag_type: '',
                calculation_type: '',
                min_fixed_rate: '',
                min_wastage_percent: '',
                min_flat_wastage: '',
                min_making_charge_gram: '',
                min_flat_making_charge: '',
                min_pergram_rate: '',
                max_fixed_rate: '',
                max_wastage_percent: '',
                max_flat_wastage: '',
                max_making_charge_gram: '',
                max_flat_making_charge: '',
                max_pergram_rate: '',
            }))
        } else if (name === 'tag_count') {

            if (!itemFormValues.bulk_tag) {
                setItemFormValues((prevState) => ({
                    ...prevState,
                    [name]: 1
                }))
            } else {
                setItemFormValues((prevState) => ({
                    ...prevState,
                    [name]: value
                }))

                setErrors((prevState) => ({
                    ...prevState,
                    [name]: value.length === 0 ? `${name} field is required` : '',
                }));
            }

        } else if (name === 'bulk_tag') {
            setItemFormValues((prevState) => ({
                ...prevState,
                [name]: checked,
                tag_count: checked ? '' : 1
            }))
        } else if (name === "gross_weight" || name === "tag_weight" || name === "cover_weight" || name === "loop_weight" || name === "other_weight") {

            var tagWgt = itemFormValues.tag_weight === undefined || itemFormValues.tag_weight === null ? 0.0 : parseFloat(itemFormValues.tag_weight)
            var coverWt = itemFormValues.cover_weight === undefined || itemFormValues.cover_weight === null ? 0.0 : parseFloat(itemFormValues.cover_weight)
            var loopWgt = itemFormValues.loop_weight === undefined || itemFormValues.loop_weight === null ? 0.0 : parseFloat(itemFormValues.loop_weight)
            var otherWgt = itemFormValues.other_weight === undefined || itemFormValues.other_weight === null ? 0.0 : parseFloat(itemFormValues.other_weight)

            var diamondWeight = 0.0
            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                        }
                    }
                }
            }

            if (name === 'gross_weight') {
                setErrors((prevState) => ({
                    ...prevState,
                    gross_weight: value.length === 0 ? `gross_weight field is required` : '',
                    net_weight: value.length === 0 ? `net_weight field is required` : ''
                }));

                setItemFormValues((prevState) => ({
                    ...prevState,
                    stone_weight: parseFloat(stoneWeight),
                    diamond_weight: parseFloat(diamondWeight),
                    gross_weight: allowZeroWeight ? 0 : value,
                    net_weight: parseFloat(allowZeroWeight ? 0 : value) - (tagWgt + coverWt + loopWgt + otherWgt + stoneWeight + diamondWeight)
                }))

                let data={
                    metal: itemFormValues?.metal,
                    tag_type: itemFormValues.tag_type,
                    sub_item_details: itemFormValues.sub_item_details,
                    calculation_type: itemFormValues.calculation_type,
                    gross_weight: value
                }

                if (value.length === 0) {

                } else {
                    getMaxValueDetails(data)

                }
            } else if (name === "tag_weight") {
                setItemFormValues((prevState) => ({
                    ...prevState,
                    stone_weight: parseFloat(stoneWeight),
                    diamond_weight: parseFloat(diamondWeight),
                    tag_weight: allowZeroWeight ? 0 : value,
                    net_weight: itemFormValues.gross_weight - (parseFloat(allowZeroWeight ? 0 : value) + coverWt + loopWgt + otherWgt + stoneWeight + diamondWeight)
                }))
            } else if (name === "cover_weight") {
                setItemFormValues((prevState) => ({
                    ...prevState,
                    stone_weight: parseFloat(stoneWeight),
                    diamond_weight: parseFloat(diamondWeight),
                    cover_weight: allowZeroWeight ? 0 : value,
                    net_weight: itemFormValues.gross_weight - (tagWgt + parseFloat(allowZeroWeight ? 0 : value) + loopWgt + otherWgt + stoneWeight + diamondWeight)
                }))
            } else if (name === "loop_weight") {
                setItemFormValues((prevState) => ({
                    ...prevState,
                    stone_weight: parseFloat(stoneWeight),
                    diamond_weight: parseFloat(diamondWeight),
                    loop_weight: allowZeroWeight ? 0 : value,
                    net_weight: itemFormValues.gross_weight - (tagWgt + coverWt + parseFloat(allowZeroWeight ? 0 : value) + otherWgt + stoneWeight + diamondWeight)
                }))
            } else if (name === "other_weight") {
                setItemFormValues((prevState) => ({
                    ...prevState,
                    stone_weight: parseFloat(stoneWeight),
                    diamond_weight: parseFloat(diamondWeight),
                    other_weight: allowZeroWeight ? 0 : value,
                    net_weight: itemFormValues.gross_weight - (tagWgt + coverWt + loopWgt + parseFloat(allowZeroWeight ? 0 : value) + stoneWeight + diamondWeight)
                }))
            }
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : '',
            }));

        } else if (name === 'sub_item_details') {
            for (var i of subItemList) {
                if (i.id == value) {

                    var response = await getAxios({
                        url: subitemEndPoint + i.id + '/'
                    })

                    if (response != null) {
                        setItemFormValues((prevState) => ({
                            ...prevState,
                            [name]: value,
                            sub_item_name: i.sub_item_name,
                            calculation_type: i.calculation_type,
                            measurement_type_name: response.data.subitem_details.measurement_type_name
                        }));

                        if (i.calculation_type == fixedCalcId) {
                            setItemFormValues((prevState) => ({
                                ...prevState,
                                min_fixed_rate: response.data.calculation_details.fixed_rate,
                                max_fixed_rate: response.data.calculation_details.max_fixed_rate,
                            }));
                        } else if (i.calculation_type == pergramCalId) {
                            setItemFormValues((prevState) => ({
                                ...prevState,
                                min_pergram_rate: response.data.calculation_details.per_gram_rate,
                                max_pergram_rate: response.data.calculation_details.max_pergram_rate,
                            }));
                        } else if (i.calculation_type == weightCalId) {
                            setItemFormValues((prevState) => ({
                                ...prevState,
                                min_wastage_percent: response.data.calculation_details.wastage_percent,
                                min_flat_wastage: response.data.calculation_details.flat_wastage,
                                min_making_charge_gram: response.data.calculation_details.making_charge_gram,
                                min_flat_making_charge: response.data.calculation_details.flat_making_charge,
                               
                            }));
                        }
                    }
                    break;

                }
            }


            setSelectedSubItem(value)

            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : '',
            }));
        } else {
            setItemFormValues((prevState) => ({
                ...prevState,
                [name]: value
            }))

            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : '',
            }));
        }
    }

    const getTagNumbersList = async (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(formDataObject, ['remarks'])) {

            if (itemFormValues.pieces > 0 && itemFormValues.tag_count > 0) {
                var response = await postAxiosWithToken({
                    url: getTagNumbersListEndPoint,
                    body: {
                        "lot_details": localStorage.getItem('lot_form_id'),
                        "tagged_pieces": itemFormValues.tag_count,
                        "tagged_grossweight": parseFloat(itemFormValues.tag_count) * parseFloat(itemFormValues.gross_weight),
                        "tag_netweight": parseFloat(itemFormValues.tag_count) * parseFloat(itemFormValues.net_weight)
                    }
                })

                if (response !== null) {

                    let listData = [...particularsList]

                    for (var tag of response.data) {
                        let data = { ...itemFormValues, ...stoneDiamondDetials };
                        // console.log(data,"datadata")
                        data['min_sales_value'] = roughSaleValue
                        data['max_sales_value'] = maxroughSaleValue
                        data['tag_number'] = tag
                        data['tag_pieces'] = itemFormValues.pieces
                        data['stone_details'] = []
                        if (stoneList.length > 0) {

                            for (var stone of stoneList) {
                                let stoneDetails = stone
                                stoneDetails['stone_name'] = stone.stone_id
                                data['stone_details'].push(stoneDetails)
                            }
                        }
                        data['diamond_details'] = []
                        if (diamondList.length > 0) {

                            for (var diamond of diamondList) {
                                let diamondDetails = diamond
                                diamondDetails['diamond_name'] = diamond.diamond_id
                                data['diamond_details'].push(diamondDetails)
                            }
                        }
                        data['halmark_center'] = lotDetails.lot_details.hallmark_center
                        listData.push(data)

                    }

                    setParticularsList(listData)
                    // console.log(listData,"listData")
                }
            } else {
                if (itemFormValues.pieces <= 0) {
                    console.log("hi")
                    toast.error('Pieces should not be 0!')
                }

                if (itemFormValues.tag_count <= 0) {
                    toast.error('Tag count should not be 0!')
                }
            }
        }
    }

    useEffect(() => {
        calculateMinSaleValue();
        calculateMaxSaleValue();
    }, [itemFormValues])

    console.log(">>>>>>>>>>>>>>>>>",itemFormValues)

    const calculateMinSaleValue = () => {
        if (itemFormValues.calculation_type === weightCalId) {
            var grossWgt = itemFormValues.gross_weight === undefined || itemFormValues.gross_weight === null ? 0.0 : parseFloat(itemFormValues.gross_weight)
            var netWt = itemFormValues.net_weight === undefined || itemFormValues.net_weight === null ? 0.0 : parseFloat(itemFormValues.net_weight)
            var wastagePercent = itemFormValues.min_wastage_percent === undefined || itemFormValues.min_wastage_percent === null ? 0.0 : parseFloat(itemFormValues.min_wastage_percent)
            var flatWastage = itemFormValues.min_flat_wastage === undefined || itemFormValues.min_flat_wastage === null ? 0.0 : parseFloat(itemFormValues.min_flat_wastage)
            var makingChargeGram = itemFormValues.min_making_charge_gram === undefined || itemFormValues.min_making_charge_gram === null ? 0.0 : parseFloat(itemFormValues.min_making_charge_gram)
            var flatMakingCharge = itemFormValues.min_flat_making_charge === undefined || itemFormValues.min_flat_making_charge === null ? 0.0 : parseFloat(itemFormValues.min_flat_making_charge)
            var metalRate = itemFormValues.metal_rate === undefined || itemFormValues.metal_rate === null ? 0.0 : parseFloat(itemFormValues.metal_rate)

            var diamondWeight = 0.0
            var totalDiamondRate = 0.0
            var totalDiamondPieces = 0

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            var totalStoneRate = 0.0
            var totalStonePieces = 0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    totalStonePieces = totalStonePieces + i.stone_pieces
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            let wastageValue = itemFormValues.wastage_calculation?.toString() === grossWeightType.toString() ? grossWgt * (wastagePercent / 100) * metalRate : netWt * (wastagePercent / 100) * metalRate
            let makingChargeValue = itemFormValues.making_charge_calculation?.toString() === grossWeightType.toString() ? grossWgt * makingChargeGram : netWt * makingChargeGram
            let metalValue = netWt * metalRate

            setStoneDiamondDetails((prevState) => ({
                ...prevState,
                total_stone_pieces: totalStonePieces,
                total_diamond_pieces: totalDiamondPieces,
                total_stone_rate: totalStoneRate,
                total_diamond_rate: totalDiamondRate
            }))

            setRoughSaleValue(parseFloat(wastageValue) + parseFloat(flatWastage) + parseFloat(makingChargeValue) + parseFloat(flatMakingCharge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue))
        } else if (itemFormValues.calculation_type === fixedCalcId) {

            var diamondWeight = 0.0
            var totalDiamondRate = 0.0
            var totalDiamondPieces = 0

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            var totalStoneRate = 0.0
            var totalStonePieces = 0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    totalStonePieces = totalStonePieces + i.stone_pieces
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            setStoneDiamondDetails((prevState) => ({
                ...prevState,
                total_stone_pieces: totalStonePieces,
                total_diamond_pieces: totalDiamondPieces,
            }))
            setRoughSaleValue(itemFormValues.min_fixed_rate + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate))

        } else if (itemFormValues.calculation_type === pergramCalId) {

            var diamondWeight = 0.0
            var totalDiamondRate = 0.0
            var totalDiamondPieces = 0

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            var totalStoneRate = 0.0
            var totalStonePieces = 0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    totalStonePieces = totalStonePieces + i.stone_pieces
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            if (String(itemFormValues.per_gram_weight_type) === String(grossWeightType)) {
                var grossWgt = itemFormValues.gross_weight === undefined || itemFormValues.gross_weight === null ? 0.0 : parseFloat(itemFormValues.gross_weight)

                setRoughSaleValue((parseFloat(itemFormValues.min_pergram_rate) * parseFloat(grossWgt)) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate))

            } else {
                var netWt = itemFormValues.net_weight === undefined || itemFormValues.net_weight === null ? 0.0 : parseFloat(itemFormValues.net_weight)
                setRoughSaleValue((parseFloat(itemFormValues.min_pergram_rate) * parseFloat(netWt)) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate))
            }

            setStoneDiamondDetails((prevState) => ({
                ...prevState,
                total_stone_pieces: totalStonePieces,
                total_diamond_pieces: totalDiamondPieces,
            }))

        }
    }

    const calculateMaxSaleValue = () => {
        if (itemFormValues.calculation_type === weightCalId) {
            var grossWgt = itemFormValues.gross_weight === undefined || itemFormValues.gross_weight === null ? 0.0 : parseFloat(itemFormValues.gross_weight)
            var netWt = itemFormValues.net_weight === undefined || itemFormValues.net_weight === null ? 0.0 : parseFloat(itemFormValues.net_weight)
            var wastagePercent = itemFormValues.max_wastage_percent === undefined || itemFormValues.max_wastage_percent === null ? 0.0 : parseFloat(itemFormValues.max_wastage_percent)
            var flatWastage = itemFormValues.max_flat_wastage === undefined || itemFormValues.max_flat_wastage === null ? 0.0 : parseFloat(itemFormValues.max_flat_wastage)
            var makingChargeGram = itemFormValues.max_making_charge_gram === undefined || itemFormValues.max_making_charge_gram === null ? 0.0 : parseFloat(itemFormValues.max_making_charge_gram)
            var flatMakingCharge = itemFormValues.max_flat_making_charge === undefined || itemFormValues.max_flat_making_charge === null ? 0.0 : parseFloat(itemFormValues.max_flat_making_charge)
            var metalRate = itemFormValues.metal_rate === undefined || itemFormValues.metal_rate === null ? 0.0 : parseFloat(itemFormValues.metal_rate)

            var diamondWeight = 0.0
            var totalDiamondRate = 0.0
            var totalDiamondPieces = 0

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            var totalStoneRate = 0.0
            var totalStonePieces = 0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    totalStonePieces = totalStonePieces + i.stone_pieces
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            let wastageValue = itemFormValues.wastage_calculation?.toString() === grossWeightType.toString() ? grossWgt * (wastagePercent / 100) * metalRate : netWt * (wastagePercent / 100) * metalRate
            let makingChargeValue = itemFormValues.making_charge_calculation?.toString() === grossWeightType.toString() ? grossWgt * makingChargeGram : netWt * makingChargeGram
            let metalValue = netWt * metalRate

            setStoneDiamondDetails((prevState) => ({
                ...prevState,
                total_stone_pieces: totalStonePieces,
                total_diamond_pieces: totalDiamondPieces,
                total_stone_rate: totalStoneRate,
                total_diamond_rate: totalDiamondRate
            }))

            setMaxRoughSaleValue(parseFloat(wastageValue) + parseFloat(flatWastage) + parseFloat(makingChargeValue) + parseFloat(flatMakingCharge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue))
        } else if (itemFormValues.calculation_type === fixedCalcId) {

            var diamondWeight = 0.0
            var totalDiamondRate = 0.0
            var totalDiamondPieces = 0

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            var totalStoneRate = 0.0
            var totalStonePieces = 0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    totalStonePieces = totalStonePieces + i.stone_pieces
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            setStoneDiamondDetails((prevState) => ({
                ...prevState,
                total_stone_pieces: totalStonePieces,
                total_diamond_pieces: totalDiamondPieces,
            }))
            setMaxRoughSaleValue(itemFormValues.max_fixed_rate + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate))

        } else if (itemFormValues.calculation_type === pergramCalId) {

            var diamondWeight = 0.0
            var totalDiamondRate = 0.0
            var totalDiamondPieces = 0

            if (diamondList.length !== 0) {
                for (var i of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                    if (i.include_diamond_weight === true) {
                        if (String(i.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                            if (String(i.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                            }
                        }
                    }
                }
            }

            var stoneWeight = 0.0
            var totalStoneRate = 0.0
            var totalStonePieces = 0
            if (stoneList.length !== 0) {
                for (var i of stoneList) {
                    totalStonePieces = totalStonePieces + i.stone_pieces
                    if (i.include_stone_weight === true) {
                        if (String(i.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                            if (String(i.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                            }
                        }
                    }
                }
            }

            if (String(itemFormValues.per_gram_weight_type) === String(grossWeightType)) {
                var grossWgt = itemFormValues.gross_weight === undefined || itemFormValues.gross_weight === null ? 0.0 : parseFloat(itemFormValues.gross_weight)

                setMaxRoughSaleValue((parseFloat(itemFormValues.max_pergram_rate) * parseFloat(grossWgt)) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate))

            } else {
                var netWt = itemFormValues.net_weight === undefined || itemFormValues.net_weight === null ? 0.0 : parseFloat(itemFormValues.net_weight)
                setMaxRoughSaleValue((parseFloat(itemFormValues.max_pergram_rate) * parseFloat(netWt)) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate))
            }

            setStoneDiamondDetails((prevState) => ({
                ...prevState,
                total_stone_pieces: totalStonePieces,
                total_diamond_pieces: totalDiamondPieces,
            }))

        }
    }

    // useEffect(() => {
        // console.log(particularsList,"particularsListparticularsList")
    // })

    const createItemTag = async (e) => {
        e.preventDefault();
        let body = {
            branch: lotDetails?.lot_details?.branch,
            lot_details: localStorage.getItem('lot_form_id'),
            tag_item_details : particularsList
        };
        
        // if (particularsList.length > 0) {   
        //     body.tag_item_details = particularsList;
        // }
       
        var response = await postAxiosWithToken({
            url: taggingEndPoint,
            body: body
        })

        if (response !== null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const removeParticulars = async () => {

        var response = await deleteAxiosWithToken({
            url: getTagNumbersListEndPoint + deleteTagItem.tagNumber + '/',
        })

        if (response !== null) {
            let data = [...particularsList];
            data.splice(deleteTagItem.index, 1)
            setParticularsList(data)
            setIsDeleteOpen(false)
        }
    }

    const addHallmarkHUID = (text, index) => {
        let data = [...particularsList];
        data[index]['halmark_huid'] = text;
        setParticularsList(data)
    }

    const onGrossWeightChange = (value, index) => {
        let data = [...particularsList];
        var tagWgt = data[index].tag_weight === undefined || data[index].tag_weight === null ? 0.0 : parseFloat(data[index].tag_weight)
        var coverWt = data[index].cover_weight === undefined || data[index].cover_weight === null ? 0.0 : parseFloat(data[index].cover_weight)
        var loopWgt = data[index].loop_weight === undefined || data[index].loop_weight === null ? 0.0 : parseFloat(data[index].loop_weight)
        var otherWgt = data[index].other_weight === undefined || data[index].other_weight === null ? 0.0 : parseFloat(data[index].other_weight)

        var wastagePercent = data[index].min_wastage_percent === undefined || data[index].min_wastage_percent === null ? 0.0 : parseFloat(data[index].min_wastage_percent)
        var flatWastage = data[index].min_flat_wastage === undefined || data[index].min_flat_wastage === null ? 0.0 : parseFloat(data[index].min_flat_wastage)
        var makingChargeGram = data[index].min_making_charge_gram === undefined || data[index].min_making_charge_gram === null ? 0.0 : parseFloat(data[index].min_making_charge_gram)
        var flatMakingCharge = data[index].min_flat_making_charge === undefined || data[index].min_flat_making_charge === null ? 0.0 : parseFloat(data[index].min_flat_making_charge)

        var metalRate = data[index].metal_rate === undefined || data[index].metal_rate === null ? 0.0 : parseFloat(data[index].metal_rate)

        var diamondWeight = 0.0
        var totalDiamondRate = 0.0
        var totalDiamondPieces = 0
        if (data[index].diamond_details.length !== 0) {
            for (var i of data[index].diamond_details) {
                totalDiamondPieces = totalDiamondPieces + i.diamond_pieces
                if (i.include_diamond_weight === true) {
                    if (String(i.diamond_weight_type) === String(cararWeightType)) {
                        diamondWeight = diamondWeight + (parseFloat(i.diamond_weight) / 5)
                        if (String(i.diamond_rate_type) === String(caratRateType)) {
                            totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * (parseFloat(i.diamond_weight) / 5))
                        } else {
                            totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * (parseFloat(i.diamond_weight) / 5))
                        }
                    } else {
                        diamondWeight = diamondWeight + parseFloat(i.diamond_weight)
                        if (String(i.diamond_rate_type) === String(caratRateType)) {
                            totalDiamondRate = totalDiamondRate + ((parseFloat(i.diamond_rate) * 5) * parseFloat(i.diamond_weight))
                        } else {
                            totalDiamondRate = totalDiamondRate + (parseFloat(i.diamond_rate) * parseFloat(i.diamond_weight))
                        }
                    }
                }
            }
        }

        var stoneWeight = 0.0
        var totalStoneRate = 0.0
        var totalStonePieces = 0
        if (data[index].stone_details.length !== 0) {
            for (var i of data[index].stone_details) {
                totalStonePieces = totalStonePieces + i.stone_pieces
                if (i.include_stone_weight === true) {
                    if (String(i.stone_weight_type) === String(cararWeightType)) {
                        stoneWeight = stoneWeight + (parseFloat(i.stone_weight) / 5)
                        if (String(i.stone_rate_type) === String(caratRateType)) {
                            totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * (parseFloat(i.stone_weight) / 5))
                        } else {
                            totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * (parseFloat(i.stone_weight) / 5))
                        }
                    } else {
                        stoneWeight = stoneWeight + parseFloat(i.stone_weight)
                        if (String(i.stone_rate_type) === String(caratRateType)) {
                            totalStoneRate = totalStoneRate + ((parseFloat(i.stone_rate) * 5) * parseFloat(i.stone_weight))
                        } else {
                            totalStoneRate = totalStoneRate + (parseFloat(i.stone_rate) * parseFloat(i.stone_weight))
                        }
                    }
                }
            }
        }

        data[index]['gross_weight'] = allowZeroWeight ? 0 : value;

        let netWt = parseFloat(allowZeroWeight ? 0 : value) - (tagWgt + coverWt + loopWgt + otherWgt + stoneWeight + diamondWeight);

        data[index]['net_weight'] = netWt

        let wastageValue = data[index].wastage_calculation?.toString() === grossWeightType.toString() ? value * (wastagePercent / 100) * metalRate : netWt * (wastagePercent / 100) * metalRate
        let makingChargeValue = data[index].making_charge_calculation?.toString() === grossWeightType.toString() ? value * makingChargeGram : netWt * makingChargeGram
        let metalValue = netWt * metalRate

        data[index]['min_sales_value'] = parseFloat(wastageValue) + parseFloat(flatWastage) + parseFloat(makingChargeValue) + parseFloat(flatMakingCharge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue)
        data[index]['max_sales_value'] = parseFloat(wastageValue) + parseFloat(flatWastage) + parseFloat(makingChargeValue) + parseFloat(flatMakingCharge) + parseFloat(totalDiamondRate) + parseFloat(totalStoneRate) + parseFloat(metalValue)

        setParticularsList(data)
    }

    const getMaxValueDetails = async (body) => {
        var response = await postAxiosWithToken({
            url: TagvalueEndPoint,
            body: body
        })

        if (response != null) {
            setItemFormValues((prevState) => ({
                ...prevState,
                max_flat_making_charge: response.data.max_flat_making_charge,
                max_flat_wastage: response.data.max_flat_wastage,
                max_making_charge_gram: response.data.max_making_charge_gram,
                max_wastage_percent: response.data.max_wastage_percent,
                max_fixed_rate: response.data.max_fixed_rate,
                max_fixed_rate: response.data.max_fixed_rate,
            }))
        }
    }

    return (
        <>
            <div>
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Item Tag</p>

                <div className="w-full bg-white mb-[25px] border shadow-md rounded-md p-[15px]">
                    <p className="font-[500] text-[18px] text-menu-head">Lot Details ({lotDetails?.lot_details?.branch_name})</p>
                    <div className="w-full grid xl:grid-cols-4 sm:grid-cols-4 gap-5 mt-4">
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Gross Wt :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.total_grossweight}</p>
                        </div>
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Net Wt :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.total_netweight}</p>
                        </div>
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Pieces :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.total_pieces}</p>
                        </div>
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Stone Pieces :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.total_stone_pieces}</p>
                        </div>
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Total Diamond Pieces :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.total_diamond_pieces}</p>
                        </div>
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Tagged Gross Wt :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.tagged_grossweight}</p>
                        </div>

                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Tagged Stone Pieces :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.tagged_stone_pieces}</p>
                        </div>
                        <div className="w-full flex gap-2">
                            <p className="mb-[5px] text-[14px] font-[500] text-menu-head">Tagged Diamond Pieces :</p>
                            <p className="text-blue-500 font-[400] text-[14px]">{lotDetails?.lot_details?.tagged_diamond_weight}</p>
                        </div>
                    </div>

                </div>

                <div className={`${openAddItems ? '-translate-x-[20px]' : '-translate-x-[490px]'} transition ease-in-out fixed top-[0px] w-[500px] z-[5] flex items-center`}>
                    <form onSubmit={getTagNumbersList} id="tagitemform" className="bg-white h-[100vh] border shadow rounded overflow-scroll">
                        <div className=" sm:flex  gap-4 mb-[50px]">
                            <div className=" w-full sm:w-[500px] p-[10px] ">

                                <div className="flex justify-between gap-[10px]">

                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Item Details</p>
                                        <select
                                            placeholder="Item Code"
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                                            name="item_details"
                                            onChange={onItemFormValueUpdate}
                                            value={itemFormValues.item_details}
                                        >
                                            <option value={""}>--select Item Details--</option>
                                            {
                                                itemList?.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.item_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.item_details}</p>
                                    </div>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Sub Item Details</p>
                                        <select
                                            placeholder="Item Id"
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                                            name="sub_item_details"
                                            onChange={onItemFormValueUpdate}
                                            value={itemFormValues.sub_item_details}
                                        >
                                            <option value={""}>--select S.item Details--</option>
                                            {
                                                subItemList.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.sub_item_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item_details}</p>
                                    </div>
                                </div>

                                <div className="flex gap-[10px] justify-between">
                                    <div className="sm:my-[5px] ">
                                        <p className="mb-[5px] text-[12px]">Assigned Counter</p>
                                        <select
                                            placeholder="Sub Item name"
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-[200px] p-[9px] text-[14px] outline-none"
                                            name="display_counter"
                                            onChange={onItemFormValueUpdate}
                                            value={itemFormValues.display_counter}
                                        >
                                            <option value={""}>--select Assigned Counter--</option>
                                            {
                                                counterList.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.counter_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.display_counter}</p>
                                    </div>

                                    <div className="my-[5px] max-w-[150px]">
                                        <p className="mb-[5px] text-[12px]">Measurement ({itemFormValues.measurement_type_name})</p>
                                        <input
                                            placeholder={itemFormValues.measurement_type_name}
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="size_value"
                                            value={itemFormValues.size_value}
                                            onChange={onItemFormValueUpdate}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.size_value}</p>
                                    </div>
                                    <div className="my-[5px] max-w-[100px]">
                                        <p className="mb-[5px] text-[12px]">Bulk Tag</p>
                                        <input
                                            type="checkbox"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg p-[5px] text-[17px] outline-none"
                                            name="bulk_tag"
                                            onChange={onItemFormValueUpdate}
                                            checked={itemFormValues.bulk_tag}
                                        />
                                    </div>

                                </div>

                                <div className="flex gap-[10px] justify-between">

                                    <div className="sm:my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Tag Type</p>
                                        <select
                                            placeholder="Tag Type"
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                                            name="tag_type"
                                            onChange={onItemFormValueUpdate}
                                            value={itemFormValues.tag_type}

                                        >
                                            <option value={""}>--select tag type--</option>
                                            {
                                                tagTypeList.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.tag_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.tag_type}</p>
                                    </div>

                                    <div className="my-[5px] max-w-[100px]">
                                        <p className="mb-[5px] text-[12px]">Pieces</p>
                                        <input
                                            type="number"
                                            min={'0'}
                                            placeholder="Pieces"
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-[100px] p-[7px] text-[14px] outline-none"
                                            name="pieces"
                                            value={itemFormValues.pieces}
                                            onChange={onItemFormValueUpdate}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.pieces}</p>
                                    </div>
                                    <div className="my-[5px] max-w-[100px]">
                                        <p className="mb-[5px] text-[12px]">Tag Count</p>
                                        <input
                                            placeholder="Tag Count"
                                            className={` ${!itemFormValues.bulk_tag ? 'cursor-not-allowed' : ''} sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-[100px] p-[7px] text-[14px] outline-none `}
                                            name="tag_count"
                                            onChange={onItemFormValueUpdate}
                                            value={itemFormValues.tag_count}
                                            readOnly={!itemFormValues.bulk_tag}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.tag_count}</p>
                                    </div>

                                </div>

                                <div className="flex gap-[10px] justify-between">
                                    <div className="my-[5px] max-w-full">
                                        <p className="mb-[5px] text-[12px]">Gross wt</p>
                                        <input
                                            type="number"
                                            min="0"
                                            step={0.0001}
                                            placeholder="Gross wt"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[14px] outline-none"
                                            name="gross_weight"
                                            onChange={onItemFormValueUpdate}
                                            value={allowZeroWeight ? 0 : itemFormValues.gross_weight}
                                            readOnly={allowZeroWeight}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.gross_weight}</p>
                                    </div>
                                    <div className="my-[5px] max-w-full">
                                        <p className="mb-[5px] text-[12px]">Net Wt</p>
                                        <input
                                            type="number"
                                            min="0"
                                            placeholder="Net Wt"
                                            className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[14px] outline-none"
                                            name="net_weight"
                                            value={allowZeroWeight ? 0 : itemFormValues.net_weight}
                                            readOnly
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.net_weight}</p>
                                    </div>
                                </div>


                                <div className={`${itemFormValues.gross_weight > 0 ? 'grid' : 'hidden'} grid-cols-2 min-[500px]:grid-cols-4 gap-[10px] w-full`}>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Tag Wt</p>
                                        <input
                                            type="number"
                                            min="0"
                                            step={0.0001}
                                            placeholder="Tag Wt"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="tag_weight"
                                            onChange={onItemFormValueUpdate}
                                            value={allowZeroWeight ? 0 : itemFormValues.tag_weight}
                                            readOnly={allowZeroWeight}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.tag_weight}</p>
                                    </div>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Cover Wt</p>
                                        <input
                                            type="number"
                                            min="0"
                                            step={0.0001}
                                            placeholder="Cover Wt"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="cover_weight"
                                            onChange={onItemFormValueUpdate}
                                            value={allowZeroWeight ? 0 : itemFormValues.cover_weight}
                                            readOnly={allowZeroWeight}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.cover_weight}</p>
                                    </div>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Loop Wt</p>
                                        <input
                                            type="number"
                                            min="0"
                                            step={0.0001}
                                            placeholder="Loop Wt"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="loop_weight"
                                            onChange={onItemFormValueUpdate}
                                            value={allowZeroWeight ? 0 : itemFormValues.loop_weight}
                                            readOnly={allowZeroWeight}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.loop_weight}</p>
                                    </div>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Other Wt</p>
                                        <input
                                            type="number"
                                            min="0"
                                            step={0.0001}
                                            placeholder="Other Wt"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="other_weight"
                                            onChange={onItemFormValueUpdate}
                                            value={allowZeroWeight ? 0 : itemFormValues.other_weight}
                                            readOnly={allowZeroWeight}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.other_weight}</p>
                                    </div>

                                </div>


                                <div className="my-[5px] max-w-full">
                                    <p className="mb-[5px] text-[12px]">Calculation Type</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg p-[9px] text-[14px] outline-none"
                                        name="calculation_type"
                                        value={itemFormValues.calculation_type}
                                        onChange={onItemFormValueUpdate}
                                    >
                                        <option value={""}>--select Calculation Type--</option>
                                        {
                                            calculationtypeDropdown?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.calculation_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.calculation_type}</p>
                                </div>


                                {
                                    String(itemFormValues.calculation_type) === String(fixedCalcId) && <div className="grid grid-cols-2 gap-2">
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Min Fixed Rate </p>
                                            <input
                                                placeholder="Fixed Rate"
                                                className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="min_fixed_rate"
                                                value={itemFormValues.min_fixed_rate}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.min_fixed_rate}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Max Fixed Rate </p>
                                            <input
                                                placeholder="Fixed Rate"
                                                className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="max_fixed_rate"
                                                value={itemFormValues.max_fixed_rate}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_fixed_rate}</p>
                                        </div>
                                    </div>
                                }

                                {
                                    String(itemFormValues.calculation_type) === String(weightCalId) &&
                                    <div className="grid grid-cols-4 gap-x-[10px]">
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Min Wastage %</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Wastage %"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="min_wastage_percent"
                                                value={itemFormValues.min_wastage_percent}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.min_wastage_percent}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Max Wastage %</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Wastage %"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="max_wastage_percent"
                                                value={itemFormValues?.max_wastage_percent}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_wastage_percent}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Min Flat Wastage</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Flat Wastage"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="min_flat_wastage"
                                                value={itemFormValues.min_flat_wastage}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.min_flat_wastage}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Max Flat Wastage</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Flat Wastage"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="max_flat_wastage"
                                                value={itemFormValues?.max_flat_wastage}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_flat_wastage}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Min Mc/gm</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Mc/gm"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="min_making_charge_gram"
                                                value={itemFormValues.min_making_charge_gram}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.min_making_charge_gram}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Max Mc/gm</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Mc/gm"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="max_making_charge_gram"
                                                value={itemFormValues?.max_making_charge_gram}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_making_charge_gram}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Min Flat Mc</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Flat Mc"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="min_flat_making_charge"
                                                value={itemFormValues.min_flat_making_charge}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.min_flat_making_charge}</p>
                                        </div>
                                        <div className="my-[5px] max-w-full">
                                            <p className="mb-[5px] text-[12px]">Max Flat Mc</p>
                                            <input
                                                type="number"
                                                min="0"
                                                step={0.0001}
                                                placeholder="Flat Mc"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                name="max_flat_making_charge"
                                                value={itemFormValues?.max_flat_making_charge}
                                                onChange={onItemFormValueUpdate}
                                            />
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.max_flat_making_charge}</p>
                                        </div>

                                    </div>
                                }

                                {
                                    String(itemFormValues.calculation_type) === String(pergramCalId) && <div>
                                        <div className="grid grid-cols-2  gap-2">
                                            <div className="my-[5px] max-w-full">
                                                <p className="mb-[5px] text-[12px]">Min Per Gram Rate </p>
                                                <input
                                                    placeholder="Per Gram Rate"
                                                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                    name="min_pergram_rate"
                                                    value={itemFormValues.min_pergram_rate}
                                                    onChange={onItemFormValueUpdate}
                                                />
                                                <p className="text-red-500 font-[400] text-[13px]">{errors.min_pergram_rate}</p>
                                            </div>
                                            <div className="my-[5px] max-w-full">
                                                <p className="mb-[5px] text-[12px]">MAX Per Gram Rate </p>
                                                <input
                                                    placeholder="Per Gram Rate"
                                                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                    name="max_pergram_rate"
                                                    value={itemFormValues.max_pergram_rate}
                                                    onChange={onItemFormValueUpdate}
                                                />
                                                <p className="text-red-500 font-[400] text-[13px]">{errors.max_pergram_rate}</p>
                                            </div>
                                        </div>
                                        <div className="my-[5px] w-full">
                                            <p className="mb-[5px] text-[12px]">Weight Type</p>
                                            <select
                                                placeholder="Item Code"
                                                className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                                                name="per_gram_weight_type"
                                                onChange={onItemFormValueUpdate}
                                                value={itemFormValues.per_gram_weight_type}
                                            >
                                                <option value={""}>--select weight type--</option>
                                                {
                                                    weightTypeList.map((option) => (
                                                        <option key={option.id} value={option.id}>{option.weight_name}</option>
                                                    ))
                                                }
                                            </select>
                                            <p className="text-red-500 font-[400] text-[13px]">{errors.per_gram_weight_type}</p>
                                        </div>
                                    </div>
                                }

                                <div className="flex justify-between gap-[10px]">
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Min Value</p>
                                        <input
                                            type="text"
                                            placeholder="Rough Sale Value"
                                            className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="min_sales_value"
                                            value={roughSaleValue}
                                            readOnly
                                        />
                                    </div>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Max Value</p>
                                        <input
                                            type="text"
                                            placeholder="Rough Sale Value"
                                            className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="max_sales_value"
                                            value={maxroughSaleValue}
                                            readOnly
                                        />
                                    </div>


                                </div>

                                <div className={`${itemFormValues.item_details ? 'block min-[423px]:flex ' : 'hidden'} sm:mt-0 xl:mt-[15px] gap-[10px] mb-[25px]`}>
                                    <button
                                        onClick={(e) => { e.preventDefault(); setStonePopup(!stonePopup) }}
                                        className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                                    >Add/Update Stone</button>
                                    <button
                                        onClick={(e) => { e.preventDefault(); setDiamondPopup(!diamondPopup) }}
                                        className="max-[423px]:mt-[10px] capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                                    >Add/Update Diamond</button>
                                </div>

                                <div>
                                    <div className="my-[5px] w-full">
                                        <p className="mb-[5px] text-[12px]">Remarks</p>
                                        <input
                                            type="text"
                                            placeholder="Remarks"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                            name="remarks"
                                        />
                                    </div>
                                </div>

                                <div className="min-[423px]:flex gap-[10px] sm:mt-0 xl:mt-[15px]">
                                    <button
                                        id="additem"
                                        className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                                    >{itemFormMode} <span className="lowercase">(enter)</span></button>
                                    <button
                                        id="resetitem"
                                        onClick={(e) => { e.preventDefault(); }}
                                        className="max-[423px]:mt-[10px] capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                                    >Reset<span className="lowercase">(ctrl+z)</span></button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="w-[25px] text-[20px] break-all ">
                        {
                            openAddItems && <div onClick={() => setOpenAdditems(false)} className="cursor-pointer hover:text-red-600">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><circle cx="12" cy="12" r="9" fill="currentColor" fill-opacity="0.25" /><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="m16 8l-8 8m0-8l8 8" /></g></svg>
                            </div>
                        }
                        <div className="bg-secondary p-[5px] rounded-r-lg text-center" onClick={() => setOpenAdditems(true)}>ADD ITEMS</div>
                    </div>
                </div>





                <div className="w-full">


                    {
                        particularsList.length >= 0 && <div className="overflow-scroll w-full max-[639px]:mt-[25px] mb-[200px]">

                            {/* <div className=""> */}
                            <div className="min-[560px]:flex block min-[560px]:justify-between px-[15px] py-[5px] border-b min-[880px]:h-[65px] shadow-lg">
                                <div className='flex gap-2 items-center' >
                                    <p className="text-[18px] font-[500]">Add Item</p>
                                </div>
                                <div className="max-[880px]:block max-[640px]:my-auto flex gap-8 items-center">

                                    <div className="max-[640px]:text-[12px]">
                                        Taged Pieces  <input type="text" className="w-[150px] h-[28px] outline-none bg-gray-200 p-2 rounded" value={lotDetails?.lot_details?.tagged_pieces} name="tagged_netweight" readOnly />
                                    </div>
                                    <div className="max-[640px]:text-[12px] max-[880px]:mt-1">
                                        Taged Net Weight <input type="text" className="w-[150px] h-[28px] outline-none bg-gray-200 p-2 rounded" value={lotDetails?.lot_details?.tagged_netweight} name="tagged_netweight" readOnly />
                                    </div>
                                </div>

                                <div className="flex gap-4 items-center">
                                    <div className="flex gap-4">

                                        <div onClick={() => setOpenAdditems(true)} className="rounded-lg bg-check border text-end w-[120px]">
                                            <div className="p-2 text-center gap-2">
                                                <p className="text-[16px] text-menu-head-bg gap-2"><span>+</span> Add Items</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <table className="w-full">
                                <thead className="bg-table-head text-table-head-font  sticky top-0">
                                    <tr>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>S.No</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Tag Number</td>
                                        <td className="p-[10px] border text-[13px] text-center min-w-[200px]" rowSpan={2}>Item Name</td>
                                        <td className="p-[10px] border text-[13px] text-center min-w-[200px]" rowSpan={2}>SubItem name</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Pieces</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Gross Wt</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Net Wt</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Min Value</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Value</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Fixed Rate</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Wastage %</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Flat Wastage</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Making Charge <br /> (per g)</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Flat Making Charge</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Fixed Rate</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Wastage %</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Flat Wastage</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Making Charge <br /> (per g)</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Max Flat Making Charge</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Stone Rate</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Diamond Rate</td>
                                        <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Stone</td>
                                        <td className="p-[10px] border text-[13px] text-center" colSpan={2}>Diamond</td>
                                        <td className="p-[10px] border text-[13px] text-center" rowSpan={2}>Actions</td>
                                    </tr>
                                    <tr>
                                        <td className="p-[10px] border text-[13px] text-center" >pcs</td>
                                        <td className="p-[10px] border text-[13px] text-center">wt</td>
                                        <td className="p-[10px] border text-[13px] text-center">pcs</td>
                                        <td className="p-[10px] border text-[13px] text-center">wt</td>
                                    </tr>
                                </thead>
                                <tbody className="text-primary-grey">
                                    {
                                        particularsList.map((item, index) => (
                                            <tr>
                                                <td className="p-[10px] border text-center">{index + 1}</td>
                                                <td className="p-[10px] border">{item.tag_number}</td>
                                                <td className="p-[10px] border">
                                                    <p>{item.item_name}</p>
                                                    <input
                                                        type="text"
                                                        step={0.0001}
                                                        placeholder="Hallmark HUID"
                                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                                                        name="halmark_huid"
                                                        onChange={(e) => addHallmarkHUID(e.target.value, index)}
                                                    />
                                                </td>
                                                <td className="p-[10px] border">{item.sub_item_name}</td>
                                                <td className="p-[10px] border">{item.pieces}</td>
                                                <td className="p-[10px] border">
                                                    <input value={item.gross_weight} onChange={(e) => { onGrossWeightChange(e.target.value, index) }} className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none" />
                                                </td>
                                                <td className="p-[10px] border">{item.net_weight?.toFixed(3)}</td>
                                                <td className="p-[10px] border text-center">{item.min_sales_value?.toFixed(2)}</td>
                                                <td className="p-[10px] border text-center">{item.max_sales_value?.toFixed(2)}</td>
                                                <td className="p-[10px] border text-center">{item.min_fixed_rate ? item.min_fixed_rate : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.min_wastage_percent ? item.min_wastage_percent : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.min_flat_wastage ? item.min_flat_wastage : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.min_making_charge_gram ? item.min_making_charge_gram : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.min_flat_making_charge ? item.min_flat_making_charge : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.max_fixed_rate ? item.max_fixed_rate : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.max_wastage_percent ? item.max_wastage_percent : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.max_flat_wastage ? item.max_flat_wastage : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.max_making_charge_gram ? item.max_making_charge_gram : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.max_flat_making_charge ? item.max_flat_making_charge : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.total_stone_rate ? item.total_stone_rate : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.total_diamond_rate ? item.total_diamond_rate : '-'}</td>
                                                <td className="p-[10px] border text-center">{item.total_stone_pieces}</td>
                                                <td className="p-[10px] border text-center">{item.stone_weight}</td>
                                                <td className="p-[10px] border text-center">{item.total_diamond_pieces}</td>
                                                <td className="p-[10px] border text-center">{item.diamond_weight}</td>
                                                <td className="p-[10px] border text-center">
                                                    <div onClick={() => { setDeleteTagItem({ index: index, tagNumber: item.tag_number }); setIsDeleteOpen(true); }} className="cursor-pointer hover:scale-125 w-fit mx-auto">
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            {/* </div> */}
                        </div>
                    }

                    <div className="fixed w-full flex max-[480px]:flex-col gap-4 bg-menu-head-bg justify-end bottom-[35px] border p-[15px] right-0">

                        <div className="flex max-[700px]:flex-wrap justify-center min-[450px]:justify-end items-center gap-4">
                            <div className="hidden">
                                <div ref={(el) => (componentRef = el)} >
                                {
                                            particularsList.map((item) => (
                                                <div className="flex items-center ml-[15px] w-[210px] justify-between">
                                                    <div className="text-[9px]">
                                                        <p>{item.gross_weight} g</p>
                                                        <p>{item.item_name}</p>
                                                    </div>
                                                    <Barcode
                                                        value={item.tag_number}
                                                        width={1}
                                                        height={15}
                                                        format="CODE128"
                                                        displayValue={true}
                                                        fontSize={8}
                                                    />
                                                </div>
                                            ))
                                        }
                                </div>
                            </div>
                            <ReactToPrint
                                trigger={() => <button
                                    className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none mb-[5px]"
                                >Print <span className="lowercase">(ctrl+p)</span></button>}
                                content={() => componentRef}
                            />

                            <button
                                onClick={createItemTag}
                                id="formsubmit"
                                className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none mb-[5px]"
                            >{formMode} <span className="lowercase">(ctrl+s)</span></button>
                            <button
                                onClick={(e) => { e.preventDefault(); }}
                                className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] mb-[5px] outline-none border-none"
                            >Reset <span className="lowercase">(ctrl+e)</span></button>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <Popup title={'Delete Item'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete permanently the {deleteTagItem.tagNumber} Itemtag?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { removeParticulars() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>

            <div className="w-full">
                <Popup title={`Stone`} extraClassName="min-w-full" setIsOpen={setStonePopup} isOpen={stonePopup} closeModal={closeStoneModal}>
                    <StoneForm closeModal={closeStoneModal} dataList={stoneList} setDataList={setStoneList} formType={formMode} />
                </Popup>
            </div>

            <div className="w-full">
                <Popup title={`Diamond`} extraClassName="min-w-full" setIsOpen={setDiamondPopup} isOpen={diamondPopup} closeModal={closeDiamondModal}>
                    <DiamondForm closeModal={closeDiamondModal} dataList={diamondList} setDataList={setDiamondList} formType={formMode} />
                </Popup>
            </div>
        </>
    );
}