import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { valueAddtionDesignerEndPoint, valueAddtionDesignerListEndPoint } from "../../../service/api";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import ValueAdditionDesignForm from "./valueadditiondesignerform";
import { useDispatch, useSelector } from "react-redux";

export default function ValueadditionDesigner() {

    const [valueadditionDesignList, setValueadditionDesignList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    
    const { actions } = useSelector((state) => state.appConfig);

    const [selecteItem, setSelectedItem] = useState('');
    const [selecteMetal, setSelectedMetal] = useState('');

    const [currentValueadditionDesign, setCurrentValueadditionDesign] = useState({});
    const [itemFormValues, setItemFormValues] = useState({});
    const [currentIndex, setCurrentIndex] = useState(null);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    const deleteValueadditionDesign = async () => {
        if (currentValueadditionDesign.value_addition_id !== null && currentValueadditionDesign.value_addition_id !== undefined) {
            var response = await deleteAxiosWithToken({
                url: valueAddtionDesignerEndPoint + currentValueadditionDesign.value_addition_id + '/'
            })
    
            if (response !== null) {
                closeDeleteModal()
                toast.success(response.message)
                getValueaddtionDesignerListData()
            }
        } else {
            closeDeleteModal()
            toast.success("Value Addition Designer Reset Successfully")
            getValueaddtionDesignerListData()
        }
        
    }

    const getValueaddtionDesignerListData = async () => {
        setIsLoading(true)
        var respose = await postAxiosWithToken({
            url: valueAddtionDesignerListEndPoint,
            body: itemFormValues
        })

        if (respose !== null) {
            setValueadditionDesignList(respose.data)
            setIsLoading(false)
        }
    }

    const onValueUpdate = (text, index) => {
        const { name, value } = text;

        if (value.length > 0) {
            setCurrentValueadditionDesign((state) => ({
                ...state,
                [name]: value
            }))
            let data = [...valueadditionDesignList];
            data[index][name] = value;
            setValueadditionDesignList(data);
        }

    }

    const updateValueaddtion = async () => {
        let data = currentValueadditionDesign;
        data['sub_item'] = currentValueadditionDesign.sub_item_id
        var response = await postAxiosWithToken({
            url: valueAddtionDesignerEndPoint,
            body: data
        })

        if (response !== null) {
            getValueaddtionDesignerListData()
        }
    }

    return (
        <>
            <div>
                {
                     actions.editPermit && <div>
                        <ValueAdditionDesignForm setValueadditionDesignList={setValueadditionDesignList} getData={getValueaddtionDesignerListData} setSelectedMetal={setSelectedMetal} setSelectedItem={setSelectedItem} setItemFormValues={setItemFormValues} itemFormValues={itemFormValues} />
                    </div>
                }
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">

                        <thead className="bg-table-head text-white  top-0">

                            <tr>
                                <td className="p-[10px] text-[13px] border border-gray-300" rowSpan={2}>Item Name</td>
                                <td className="p-[10px] text-[13px] border border-gray-300" rowSpan={2}>S Item Name</td>
                                <td className="p-[10px] text-[13px] border border-gray-300" rowSpan={2}>Metal Name</td>
                                <td className="p-[10px] text-[13px] border border-gray-300" rowSpan={2}>Designer Name</td>
                                <td className="p-[10px] text-[13px] border border-gray-300" rowSpan={2}>Tag Type</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" colSpan={5}>Purchase</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" colSpan={5}>Retail</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" colSpan={5}>Vip Reatil</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" rowSpan={2}>Actions</td>
                            </tr>
                            <tr>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" >Touch</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">W %</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Flat W</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Mc/g</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Flat Mc</td>


                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" >Touch</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">W %</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Flat W</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Mc/g</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Flat Mc</td>


                                <td className="p-[10px] text-[13px] border border-gray-300 text-center" >Touch</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">W %</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Flat W</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Mc/g</td>
                                <td className="p-[10px] text-[13px] border border-gray-300 text-center">Flat Mc</td>

                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border border-gray-300" colSpan={22}>Loading...</td>
                                </tr> :
                                    valueadditionDesignList.length === 0 ? <tr><td className="text-center border border-gray-300" colSpan={22}>No Data</td></tr> :
                                        valueadditionDesignList.map((item, index) => (
                                            <tr key={item.id} className="">
                                                <td className="py-[5px] border border-gray-300 px-[5px] text-[13px] capitalize">{item.item_name}</td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] text-[13px] capitalize">{item.sub_item_name}</td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] text-[13px] capitalize">{item.metal_name}</td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] text-[13px] capitalize">{item.designer_name}</td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] text-[13px] capitalize">{item.tag_type_name}</td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="purchase_touch"
                                                        value={item.purchase_touch}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.purchase_touch}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="purchase_wastage_percent"
                                                        value={item.purchase_wastage_percent}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.purchase_wastage_percent}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="purchase_flat_wastage"
                                                        value={item.purchase_flat_wastage}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.purchase_flat_wastage}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="purchase_making_charge_gram"
                                                        value={item.purchase_making_charge_gram}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.purchase_making_charge_gram}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="purchase_flat_making_charge"
                                                        value={item.purchase_flat_making_charge}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.purchase_flat_making_charge}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="retail_touch"
                                                        value={item.retail_touch}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.retail_touch}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="retail_wastage_percent"
                                                        value={item.retail_wastage_percent}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.retail_wastage_percent}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="retail_flat_wastage"
                                                        value={item.retail_flat_wastage}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.retail_flat_wastage}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="retail_making_charge_gram"
                                                        value={item.retail_making_charge_gram}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.retail_making_charge_gram}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="retail_flat_making_charge"
                                                        value={item.retail_flat_making_charge}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.retail_flat_making_charge}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="vip_touch"
                                                        value={item.vip_touch}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.vip_touch}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="vip_wastage_percent"
                                                        value={item.vip_wastage_percent}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.vip_wastage_percent}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="vip_flat_wastage"
                                                        value={item.vip_flat_wastage}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.vip_flat_wastage}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="vip_making_charge_gram"
                                                        value={item.vip_making_charge_gram}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.vip_making_charge_gram}</p>}
                                                </td>
                                                <td className="py-[5px] border border-gray-300 px-[5px] w-[70px] text-[13px] text-center capitalize" colSpan={1}>
                                                    {index === currentIndex ? <input
                                                        className="w-[50px] bg-white"
                                                        name="vip_flat_making_charge"
                                                        value={item.vip_flat_making_charge}
                                                        onChange={(e) => onValueUpdate(e.target, index)}
                                                    /> : <p>{item.vip_flat_making_charge}</p>}
                                                </td>
                                                <td className="py-[5px] px-[5px] border border-gray-300">
                                                    <div className="flex gap-3">
                                                        {
                                                            index === currentIndex ? <div onClick={() => {setCurrentIndex(null); updateValueaddtion();}} className="cursor-pointer hover:scale-125">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.25 21v-4.765a1.59 1.59 0 0 0-1.594-1.588H9.344a1.59 1.59 0 0 0-1.594 1.588V21m8.5-17.715v2.362a1.59 1.59 0 0 1-1.594 1.588H9.344A1.59 1.59 0 0 1 7.75 5.647V3m8.5.285A3.196 3.196 0 0 0 14.93 3H7.75m8.5.285c.344.156.661.374.934.645l2.382 2.375A3.17 3.17 0 0 1 20.5 8.55v9.272A3.182 3.182 0 0 1 17.313 21H6.688A3.182 3.182 0 0 1 3.5 17.823V6.176A3.182 3.182 0 0 1 6.688 3H7.75"/></svg>
                                                            </div> :
                                                                <div onClick={() => { setCurrentValueadditionDesign(item); setCurrentIndex(index) }} className="cursor-pointer hover:scale-125">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                                </div>
                                                        }
                                                        <div onClick={() => { setCurrentValueadditionDesign(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path fill="currentColor" d="m5.505 11.41l.53.53zM3 14.952h-.75zM9.048 21v.75zM11.41 5.505l-.53-.53zm6.555 6.555l-5.905 5.905l1.06 1.06l5.905-5.904zm-11.93-.12l5.905-5.905l-1.06-1.06l-5.905 5.904zm0 6.025c-.85-.85-1.433-1.436-1.812-1.933c-.367-.481-.473-.79-.473-1.08h-1.5c0 .749.312 1.375.78 1.99c.455.596 1.125 1.263 1.945 2.083zm-1.06-7.086c-.82.82-1.49 1.488-1.945 2.084c-.468.614-.78 1.24-.78 1.99h1.5c0-.29.106-.6.473-1.08c.38-.498.962-1.083 1.812-1.933zm7.085 7.086c-.85.85-1.435 1.433-1.933 1.813c-.48.366-.79.472-1.08.472v1.5c.75 0 1.376-.312 1.99-.78c.596-.455 1.264-1.125 2.084-1.945zm-7.085 1.06c.82.82 1.487 1.49 2.084 1.945c.614.468 1.24.78 1.989.78v-1.5c-.29 0-.599-.106-1.08-.473c-.497-.38-1.083-.962-1.933-1.812zm12.99-12.99c.85.85 1.433 1.436 1.813 1.933c.366.481.472.79.472 1.08h1.5c0-.749-.312-1.375-.78-1.99c-.455-.596-1.125-1.263-1.945-2.083zm1.06 7.086c.82-.82 1.49-1.488 1.945-2.084c.468-.614.78-1.24.78-1.99h-1.5c0 .29-.106.6-.473 1.08c-.38.498-.962 1.083-1.812 1.933zm0-8.146c-.82-.82-1.487-1.49-2.084-1.945c-.614-.468-1.24-.78-1.989-.78v1.5c.29 0 .599.106 1.08.473c.497.38 1.083.962 1.933 1.812zm-7.085 1.06c.85-.85 1.435-1.433 1.933-1.812c.48-.367.79-.473 1.08-.473v-1.5c-.75 0-1.376.312-1.99.78c-.596.455-1.264 1.125-2.084 1.945z"/><path fill="currentColor" d="M13.241 17.844a.75.75 0 0 0 1.06-1.06zM7.216 9.698a.75.75 0 1 0-1.06 1.061zm7.086 7.086L7.216 9.698l-1.06 1.061l7.085 7.085z" opacity=".5"/><path stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="M9 21h12" opacity=".5"/></g></svg>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>

                    </table>
                </div>
            </div>
            <div>
                <Popup title={'Delete Value Addition Designer'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentValueadditionDesign?.first_name} ValueadditionDesign?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteValueadditionDesign() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}