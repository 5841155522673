import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { branchTransferEndPoint, stockTransferTypeListEndPoint, transferTableListEndPoint } from "../../../service/api";
import ItemTransfer from "./itemtransfer";
import { allBranchTranferType, approveBranchTranferType, receivedBranchTranferType, returnBranchTranferType } from "../../../constant";
import ItemReciever from "./itemreciever";
import ItemApproval from "./itemapproval/indx";
import Popup from "../../../components/Popup";
import ItemReturn from "./itemreturn";

export default function StockTransfer() {

    const searchRef = useRef();
    const wrapperRef = useRef();

    const navigate = useNavigate();

    const [currentData, setCurrentData] = useState({})

    const { actions } = useSelector((state) => state.appConfig);

    const [tab, setTab] = useState(allBranchTranferType)
    const [tabList, setTabList] = useState([]);
    const [tabOpen, setTabOpen] = useState(false);

    

    useEffect(() => {
        getStatusDropDown();

        document.addEventListener('mousedown', handleClickListener);
        
        return () => {
          document.removeEventListener('mousedown', handleClickListener);
        };
    }, [])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('stock-transfer-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    const getStatusDropDown = async () => {
        var response = await getAxios({
            url: stockTransferTypeListEndPoint
        })

        if (response != null) {
            setTabList(response.data.list)
        }
    }

    const handleClickListener = (event) => {
        if(wrapperRef.current.contains(event.target)){
            
        } else {
            setTabOpen(false)
        }
      }


    

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="relative" ref={wrapperRef}>
                    <div onClick={() => {setTabOpen(!tabOpen)}} className="cursor-pointer capitalize text-[22px] flex items-center gap-2">
                        <p>Item {tabList[tab-1]?.status_name}</p>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m7 10l5 5l5-5z" /></svg>
                        </div>
                    </div>
                    {tabOpen && <div className="absolute border shadow-md bg-white w-full py-[5px]">
                        {
                            tabList.map((item, index) => (
                                <div onClick={() => {
                                    setTab(item.id);
                                    setTabOpen(false);
                                }} className={`${tab == item.id ? 'bg-primary-btn text-white' : ''} cursor-pointer px-[10px]`} key={'tab' + index}>{item.status_name}</div>
                            ))
                        }
                    </div>}
                </div>
                { tab === allBranchTranferType && <button
                    onClick={() => {
                        if (actions.addPermit) {
                            localStorage.setItem('btrnasfer_form_mode', 'create'); navigate('stock-transfer-form')
                        } else {
                            toast.error("You don't have permission for edit")
                        }
                    }}
                    className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                >Add Stock Transfer <span className="lowercase">(ctrl+m)</span></button> }
            </div>

            {tab === allBranchTranferType && <ItemTransfer status={tab} currentData={currentData} setCurrentData={setCurrentData} />}
            {tab === approveBranchTranferType && <ItemApproval status={tab} currentData={currentData} setCurrentData={setCurrentData} />}
            {tab === receivedBranchTranferType && <ItemReciever status={tab} currentData={currentData} setCurrentData={setCurrentData} />}
            {tab === returnBranchTranferType && <ItemReturn status={tab} currentData={currentData} setCurrentData={setCurrentData} />}

            
        </>
    );
}