import { useEffect, useRef, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { salereportListEndPoint } from "../../../service/api";
import { useKeyPress } from "../../../hooks/useKeyPress";
import SalereportFilters from "./salereportfilter";
import { useSelector } from "react-redux";
import { ExportToExcel } from "../../../components/ExportToExcel";
import Pagination from "../../../components/Pagination";
import ExcelViewer from "../../../components/ExcelViewer";

export default function Salereport() {

    const searchRef = useRef();

    const [salereportList, setSalereportList] = useState([]);
    const [filteredSalereportList, setFilteredSalereportList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const [totalPages, setTotalPages] = useState(1);

    const { pagination } = useSelector((state) => state.appConfig);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const fileName = "Sales Report"

    function closeExcelModal() {
        setExcelOpen(false)
    }

    useEffect(() => {
        getSalereportListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    useEffect(() => {
        if (salereportList.length > 0) {
            let firstData = Object.keys(salereportList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [salereportList])


    const getSalereportListData = async (body) => {
        var response = await postAxiosWithToken({
            url: salereportListEndPoint,
            body: body
        })

        if (response !== null) {
            setSalereportList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };



    return (
        <>
            <div>
                <div className="flex items-center justify-between">
                    <p className="text-[24px] font-[700]">
                        Sale Report
                    </p>
                    <div className="flex gap-3 items-center">
                        <ExcelViewer title={fileName} data={salereportList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                        <ExportToExcel apiData={filteredSalereportList} fileName={fileName} />
                    </div>
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <SalereportFilters filters={tableFilters} setFilters={setTableFilters} searchRef={searchRef} getData={getSalereportListData} dataList={salereportList} setFilteredData={setFilteredSalereportList} />
                </div>


                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Customer</td>
                                <td className="p-[10px] min-w-[300px]">Address</td>
                                <td className="p-[10px] min-w-[150px]">Mobile</td>
                                <td className="p-[10px] min-w-[150px]">Item Name</td>
                                <td className="p-[10px] min-w-[150px]">Weight</td>
                                <td className="p-[10px] min-w-[150px]">Amount</td>
                                <td className="p-[10px] min-w-[150px]">Sub Total</td>
                                <td className="p-[10px] min-w-[150px]">Exchange</td>
                                <td className="p-[10px] min-w-[150px]">chit</td>
                                <td className="p-[10px] min-w-[150px]">Advance</td>
                                <td className="p-[10px] min-w-[150px]">Discount</td>
                                <td className="p-[10px] min-w-[150px]">Bill Total</td>
                                <td className="p-[10px] min-w-[150px]">Cash</td>
                                <td className="p-[10px] min-w-[150px]">A/C Trans</td>
                                <td className="p-[10px] min-w-[150px]">POS Cup</td>
                                <td className="p-[10px] min-w-[150px]">Credit</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-menu-head-bg">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={16}>Loading...</td>
                                </tr> :
                                    salereportList.length === 0 ? <tr><td className="text-center border" colSpan={16}>No Data</td></tr> :
                                        salereportList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.customer_name}</td>
                                                <td className="p-[10px]">{item.door_no} {item.street_name} {item.area} {item.district} {item.pincode}</td>
                                                <td className="p-[10px]">{item.customer_mobile}</td>
                                                <td className="p-[10px]">{item.item_name}</td>
                                                <td className="p-[10px]">{item.weight}</td>
                                                <td className="p-[10px]">{item.amount}</td>
                                                <td className="p-[10px]">{item.sub_total}</td>
                                                <td className="p-[10px]">{item.exchange}</td>
                                                <td className="p-[10px]">{item.chit}</td>
                                                <td className="p-[10px]">{item.advance}</td>
                                                <td className="p-[10px]">{item.discount}</td>
                                                <td className="p-[10px]">{item.bill_total}</td>
                                                <td className="p-[10px]">{item.cash}</td>
                                                <td className="p-[10px]">{item.Acountc_transfer}</td>
                                                <td className="p-[10px]">{item.pos_cub}</td>
                                                <td className="p-[10px]">{item.credit}</td>

                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>

                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>

        </>
    );
}