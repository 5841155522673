import { Outlet, useLocation } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import MenuHeader from "./menuheader";
import { Toaster } from 'react-hot-toast';
import SideBar from "./sidebar";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDark, setMenuOption, setSideOptions } from "../redux/themeconfigslice";
import { setFullScreenPopupOpen } from "../redux/appconfigslice";
import { useRefreshToken } from "../hooks/useRefreshToken";
import { Maintanance } from "../session/maintanance";


export default function Layout() {
    const menuOption = useSelector((state) => state.themeConfig.menuOption)
    const isDark = useSelector((state) => state.themeConfig.isDark)

    const { underMaintanance } = useSelector((state) => state.appConfig)

    const dispatch = useDispatch();

    const { pathname } = useLocation();

    useRefreshToken();

    useEffect(() => {
        if (pathname.includes('billing') || pathname.includes('stock')) {
            dispatch(setSideOptions(false))
        } else {
            dispatch(setSideOptions(true))
        }
    }, [pathname])

    

    return (
        <>
            
            <div>{
                menuOption === 'default' ?

                    <div>
                        <div>
                            <Header logoVisible={true} />
                        </div>
                        <div className="hidden md:block sticky top-0 z-[5]" id="menubar">
                            <MenuHeader isDark={isDark} />
                        </div>
                        <div className="mx-[15px] my-[25px]">
                            <Outlet />
                        </div>
                        <div className={`${isDark ? "bg-dark-bg" : "bg-white"} fixed bottom-0 z-[6] h-[35px] flex items-center shadow-inner border-t w-full`}>
                            <Footer />
                        </div>
                    </div> :

                    menuOption === 'left-sidebar' ?

                        <div className="flex w-full">
                            <div className="fixed top-0 left-0 z-[5]" id="menubar">
                                <SideBar isDark={isDark} classess={'h-[100vh] md:border-b border-l w-[225px] fixed top-0 left-0 z-[5] shadow-lg'} position={'left'} />
                            </div>
                            <div className="w-full lg:w-[calc(100vw-225px)] lg:ml-[225px]">
                                <div>
                                    <Header />
                                </div>
                                <div className="mx-[15px] my-[25px]">
                                    <Outlet />
                                </div>
                                <div className={`${isDark ? "bg-dark-bg" : "bg-white"} fixed bottom-0 h-[35px] flex items-center shadow-inner border-t w-full lg:w-[calc(100vw-225px)]`}>
                                    <Footer />
                                </div>
                            </div>
                        </div> :

                        menuOption === 'right-sidebar' ?

                            <div className="flex w-full">

                                <div className="w-full lg:w-[calc(100vw-225px)]">
                                    <div>
                                        <Header />
                                    </div>
                                    <div className="mx-[15px] my-[25px]">
                                        <Outlet />
                                    </div>
                                    <div className={`${isDark ? "bg-dark-bg" : "bg-white"} fixed bottom-0 h-[35px] flex items-center shadow-inner border-t w-full lg:w-[calc(100vw-225px)]`}>
                                        <Footer />
                                    </div>
                                </div>
                                <div className="fixed top-0 right-0" id="menubar">
                                    <SideBar position={'right'} classess={'h-[100vh] md:border-b border-l w-[225px] fixed top-0 right-0 shadow-lg'} isDark={isDark} />
                                </div>
                            </div> :

                            <div>
                                <div>
                                    <Header logoVisible={true} />
                                </div>
                                <div className="hidden md:block sticky top-0 z-[5]" id="menubar">
                                    <MenuHeader isDark={isDark} />
                                </div>
                                <div className="mx-[15px] my-[25px]">
                                    <Outlet />
                                </div>
                                <div className={`${isDark ? "bg-dark-bg" : "bg-white"} fixed bottom-0 h-[35px] flex items-center shadow-inner border-t w-full`}>
                                    <Footer />
                                </div>
                            </div>
            }</div>



            <div id="layoutbar" className={`${isDark ? "bg-dark-bg" : "bg-white"} ${menuOption === 'right-sidebar' ? "left-0" : "right-0"}  border top-0 z-[5] p-[15px] w-[225px] h-[100vh] overflow-y-scroll fixed hidden`}>
                <div className="absolute top-0 -right-0"
                    onClick={() => {
                        document.getElementById('layoutbar').className = `${isDark ? "bg-dark-bg" : "bg-white"} ${menuOption === 'right-sidebar' ? "left-0" : "right-0"} border top-0 z-[5] p-[15px] w-[225px] h-full fixed hidden`
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-6 -6 24 24">
                        <path fill="currentColor"
                            d="m7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485L2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535l3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z" />
                    </svg>
                </div>

                <div className="my-[15px] text-[20px] font-[600] tracking-wider">
                    <p>Layouts</p>
                </div>

                <div className="my-[25px]" onClick={() => {
                    dispatch(setMenuOption('default'));
                    document.getElementById('layoutbar').className = `${isDark ? "bg-dark-bg" : "bg-white"} ${menuOption === 'right-sidebar' ? "left-0" : "right-0"} border top-0 z-[5] p-[15px] w-[225px] h-full fixed hidden`
                }}>

                    <div className="w-[200px] h-[150px] border rounded-lg p-[5px] flex gap-1">

                        <div className="h-full flex flex-col w-full gap-1">
                            <div className="bg-gray-500 rounded-lg h-[30px] flex items-center justify-center">
                                <p>header</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-[20px] flex items-center justify-center">
                                <p >subheader</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-full flex items-center justify-center">
                                <p>body</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-[30px] flex items-center justify-center">
                                <p>footer</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-[5px]">Default</div>
                </div>

                <div className="my-[25px]" onClick={() => dispatch(setMenuOption('left-sidebar'))}>

                    <div className="w-[200px] h-[150px] border rounded-lg p-[5px] flex gap-1">
                        <div className="bg-gray-500 rounded-lg w-[40px] flex items-center justify-center">
                            <p className="-rotate-90">sidebar</p>
                        </div>
                        <div className="h-full flex flex-col w-full gap-1">
                            <div className="bg-gray-500 rounded-lg h-[30px] flex items-center justify-center">
                                <p>header</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-full flex items-center justify-center">
                                <p>body</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-[30px] flex items-center justify-center">
                                <p>footer</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-[5px]">Left Sidebar</div>
                </div>

                <div className="my-[25px]" onClick={() => dispatch(setMenuOption('right-sidebar'))}>

                    <div className="w-[200px] h-[150px] border rounded-lg p-[5px] flex gap-1">

                        <div className="h-full flex flex-col w-full gap-1">
                            <div className="bg-gray-500 rounded-lg h-[30px] flex items-center justify-center">
                                <p>header</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-full flex items-center justify-center">
                                <p>body</p>
                            </div>
                            <div className="bg-gray-500 rounded-lg h-[30px] flex items-center justify-center">
                                <p>footer</p>
                            </div>
                        </div>
                        <div className="bg-gray-500 rounded-lg w-[40px] flex items-center justify-center">
                            <p className="-rotate-90">sidebar</p>
                        </div>
                    </div>
                    <div className="text-center mt-[5px]">Right Sidebar</div>
                </div>
            </div>

            {/* <div className={`${fullScreenPopupOpen ? 'block' : 'hidden'} fixed top-0 left-0 bg-black w-full h-full z-[6] bg-opacity-[0.7] flex items-center justify-center`}>
                <div className="w-[500px] h-[150px] bg-white rounded p-[15px] relative">
                    <p>Please enable the fullscreen mode to continue</p>
                    <button
                        onClick={(e) => {
                            dispatch(setFullScreenPopupOpen(!fullScreenPopupOpen))
                        }}
                        className="absolute bottom-5 capitalize bg-primary-btn text-white w-[94%] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >
                        Enable Full Screen
                    </button>
                </div>
            </div> */}

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}