import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrintSizeEndPoint, orderEndPoint } from "../../../../service/api";
import { getAxios } from "../../../../service/apiservice";
import { orderForCustomer } from "../../../../constant";
import ViewParticulars from "./viewparticulars";
import ViewActionButtons from "./viewactionbuttons";
import { ToWords } from "to-words";
import OrderReceiptPrint from "../../../../components/Printouts/OrderReceiptPrint";

const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
            // can be used to override defaults for the selected locale
            name: 'Rupee',
            plural: 'Rupees',
            symbol: '₹',
            fractionalUnit: {
                name: 'Paisa',
                plural: 'Paise',
                symbol: '',
            },
        },
    },
});

export default function ViewOrderDetails() {

    const navigate = useNavigate()

    const { id } = useParams();

    let componentRef = useRef();

    const [orderDetailValues, setOrderDetailValues] = useState({});
    const [particularsList, setParticularsList] = useState([]);

    const [isRecievable, setIsRecievable] = useState(false);
    const [printsize, setPrintSize] = useState([]);

    useEffect(() => {
        getPrintSizeData();
    }, [])

    const getPrintSizeData = async () => {

        var response = await getAxios({
            url: PrintSizeEndPoint
        })

        if (response !== null) {
            setPrintSize(response.data.list)
        }
    }

    useEffect(() => {
        getOrderDetails()
    }, [])

    const getOrderDetails = async () => {
        var response = await getAxios({
            url: orderEndPoint + id + '/'
        })

        if (response != null) {
            let tempDetails = {
                order_id: response.data.order_id,
                order_for: response.data.order_for,
                order_date: response.data.order_date,
                priority_name: response.data.priority_name,
                branch_name: response.data.branch_name,
                vendor_name: response.data.vendor_name,
                no_of_days: response.data.no_of_days,
                due_date: response.data.due_date,
                description: response.data.description,
                branch: response.data.branch,
                order_status: response.data.order_status,
                customer_name: response.data.customer_name,
                customer_no: response.data.customer_no,
                customer: response.data.customer,
                state: response.data.state,
                country: response.data.country,
                priority: response.data.priority,
                total_weight: response.data.total_weight,
                total_quantity: response.data.total_quantity,
                pending_quantity: response.data.pending_quantity,
                total_item: response.data.total_item,
                approximate_amount: response.data.approximate_amount,
                total_advance_amount: response.data.total_advance_amount,
                balance_amount: response.data.balance_amount,
                advance_in_amount: response.data.advance_in_amount,
                advance_in_gram: response.data.advance_in_gram
            }
            setParticularsList(response.data.particular_list)
            setOrderDetailValues(tempDetails)
        }
    }

    return (
        <div className="mb-[50px]">
            <div className="flex justify-between items-center gap-3">
                <p className="font-[600] text-[20px]">Order Details</p>
                <div className="flex gap-3 items-center">
                    <button

                        onClick={(e) => { e.preventDefault(); navigate(-1) }}
                        className="capitalize border border-primary-btn text-primary-btn w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none"
                    >Back</button>
                    <button

                        onClick={(e) => { e.preventDefault(); navigate('/order/order-details/orderdetails-update/'+id) }}
                        className="capitalize bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >Edit</button>
                    <ViewActionButtons orderNo={orderDetailValues.order_no} setRecievable={setIsRecievable} orderStatus={orderDetailValues.order_status} componentRef={componentRef} />
                </div>
            </div>            

            <div className="p-3 rounded-lg border-2 bg-white my-6">
                <div className="grid grid-cols-6 ">
                    <div>
                        <p className="font-[600] text-[16px]">To Customer</p>
                        <p>{orderDetailValues.order_id}</p>
                        {
                            orderDetailValues.order_for == orderForCustomer && <p>{orderDetailValues.customer_no}</p>
                        }
                        {
                            orderDetailValues.order_for == orderForCustomer && <p>{orderDetailValues.customer_name}</p>
                        }
                    </div>
                    <div>
                        <p className="text-gray-500 text-[14px]">Order Date</p>
                        <p>{orderDetailValues.order_date}</p>
                        <p className="text-gray-500 text-[14px]">Due Date</p>
                        <p>{orderDetailValues.due_date}</p>
                    </div>
                    <div>
                        <p className="text-gray-500 text-[14px]">Priority</p>
                        <p>{orderDetailValues.priority_name}</p>
                    </div>
                </div>

                <div className="border-y py-5 my-3">
                    <div className="flex justify-between items-center">
                        <p className="mb-[5px] text-[16px] font-[500]">Particulars</p>

                        <div className="flex gap-3 items-center">
                            <p className="mb-[5px] text-[13px]">Total Weight</p>
                            <input
                                type="text"
                                placeholder="Total Weight"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[10px] text-[13px] outline-none"
                                name="total_weight"
                                value={orderDetailValues.total_weight}
                                readOnly
                            />
                            <p className="mb-[5px] text-[13px]">Total Qty</p>
                            <input
                                type="text"
                                placeholder="Total Qty"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[10px] text-[13px] outline-none"
                                name="total_quantity"
                                value={orderDetailValues.total_quantity}
                                readOnly
                            />
                            <p className="mb-[5px] text-[13px]">Pending Qty</p>
                            <input
                                type="text"
                                placeholder="Pending Qty"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[10px] text-[13px] outline-none"
                                name="pending_quantity"
                                value={orderDetailValues.pending_quantity}
                                readOnly
                            />
                        </div>
                    </div>

                    <div>
                        <ViewParticulars particularsList={particularsList} recievable={isRecievable} getData={getOrderDetails} />
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-x-4 w-1/2 hidden">
                    <div className="my-3">
                        <p className="mb-[5px] text-[16px] font-[500]">Stone Amt</p>
                        <input
                            type="text"
                            placeholder="Stone Amt"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="issue_date"
                            value={orderDetailValues.description}
                            readOnly
                        />
                    </div>
                    <div className="my-3">
                        <p className="mb-[5px] text-[16px] font-[500]">Est Work Charge</p>
                        <input
                            type="text"
                            placeholder="Est Work Charge"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="issue_date"
                            value={orderDetailValues.description}
                            readOnly
                        />
                    </div>
                    <div className="my-3">
                        <p className="mb-[5px] text-[16px] font-[500]">Adv Old Gold Amount</p>
                        <input
                            type="text"
                            placeholder="Adv Old Gold Amount"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="issue_date"
                            value={orderDetailValues.description}
                            readOnly
                        />
                    </div>
                    <div className="my-3">
                        <p className="mb-[5px] text-[16px] font-[500]">Advance Amount</p>
                        <input
                            type="text"
                            placeholder="Advance Amount"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="issue_date"
                            value={orderDetailValues.description}
                            readOnly
                        />
                    </div>
                    <div className="my-3">
                        <p className="mb-[5px] text-[16px] font-[500]">Grand Total</p>
                        <input
                            type="text"
                            placeholder="Grand Total"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="issue_date"
                            value={orderDetailValues.description}
                            readOnly
                        />
                    </div>
                    <div className="my-3">
                        <p className="mb-[5px] text-[16px] font-[500]">Balance Amount</p>
                        <input
                            type="text"
                            placeholder="Balance Amount"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="issue_date"
                            value={orderDetailValues.description}
                            readOnly
                        />
                    </div>
                </div>
            </div>

            <div id="download" style={{display: 'none'}}>
                <div className="mb-[25px]" ref={componentRef}>
                    <OrderReceiptPrint
                        printsize={printsize}
                        customerName={orderDetailValues.customer_name}
                        orderId={orderDetailValues.order_id}
                        orderDate={orderDetailValues.order_date}
                        orderFor={orderDetailValues.order_for}
                        expDelDate={orderDetailValues.due_date}
                        items={particularsList}
                        totalItems={orderDetailValues.total_quantity}
                        advAmt={orderDetailValues.advance_in_amount}
                        advInGram={orderDetailValues.advance_in_gram}
                        appAmt={orderDetailValues.approximate_amount}
                        grandTotal={parseFloat(orderDetailValues.approximate_amount) - parseFloat(orderDetailValues.total_advance_amount)}
                        balAmt={orderDetailValues.balance_amount}
                        amtInWords={toWords.convert(parseFloat(orderDetailValues.approximate_amount ?? "0") - parseFloat(orderDetailValues.total_advance_amount ?? "0"), { currency: true })}
                    />

                </div>
            </div>
        </div>
    );
}