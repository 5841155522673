import { Switch } from '@headlessui/react'
import { useSelector } from "react-redux";

export default function SwitchButton({ enabled = false, setEnabled, data }) {
  const isDark = useSelector((state) => state.themeConfig.isDark)

  return (
    <div className="">
      <Switch
        checked={enabled}
        className={`${enabled ? 'bg-primary-btn' : 'bg-gray-300 '}
                                        relative inline-flex h-[18px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-4' : 'translate-x-0'} ${isDark ? 'bg-secondary' : 'bg-white'}
                                            pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  )
}
