import { useEffect, useRef } from "react"

export const useClickOutside = (handler) => {
    let nodeRef = useRef();

    useEffect(() => {
        let mayBeHandler = (event) => {
            if (!nodeRef.current.contains(event.target)) {
                handler();
            }
        }

        document.addEventListener("mousedown", mayBeHandler);

        return () => {
            document.removeEventListener("mousedown", mayBeHandler);
        }
    })

    return nodeRef;
}