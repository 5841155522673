import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
 
export default function EstimationPrint({ billDate, estimateNo, mobile, items, oldItems, exchangeAmount, payableAmount, totalAmount, gstAmount, chitAmount,companyDetails , printsize }) {
 
    const { todayGoldRate, todaySilverRate } = useSelector((state) => state.appConfig);
 
    const [particulars, setParticulars] = useState([]);
    const [oldGold, setOldGold] = useState([]);
    const [size, setSize] = useState([]);

    console.log(oldGold,"oldGold")


    useEffect(() => {
        let Sizelist ;
        for (var item of printsize) {
            Sizelist = item.estimation_is_a4
        }
        setSize(Sizelist)
    },[printsize])
 
 
    useEffect(() => {
        let tempDataList = []
        for (var item of items) {
            if (item.tag_number.length == 7) {
                tempDataList.push(item)
            }
        }
        setParticulars(tempDataList)
    },[items])
 
 
    useEffect(() => {
        let tempDataList = []
        for (var item of oldItems) {
            if (item.metal && item.gross_weight && item.metal_rate) {
                tempDataList.push(item)
            }
        }
        setOldGold(tempDataList)
    },[oldItems])
 
    return (
        <div className={` ${ size === true ? "w-full" : "max-w-[500px]" } relative h-[1100px]`}>
            <p className="font-[700] text-[14px] text-center pt-5 px-5">{companyDetails.company_details?.company_name}</p>
 
            <div className="flex justify-between items-center mt-[25px] pb-[15px] mx-5 border-b border-black">
                <div className="flex gap-5 items-start">
                    <div>
                        <p className="font-[700] text-[9px]">Bill Date</p>
                        <p className="font-[700] text-[10px] text-primary-btn">{billDate}</p>
                    </div>
                    <div>
                        <p className="font-[700] text-[9px]">Est. No #</p>
                        <p className="font-[700] text-[10px] text-primary-btn">{estimateNo}</p>
                    </div>
                    <div>
                        <p className="font-[700] text-[9px]">Mobile No</p>
                        <p className="font-[700] text-[10px] text-primary-btn">{mobile}</p>
                    </div>
                </div>
 
                <div>
                    <p className="font-[700] text-[9px]">Gold Rate : <span className="font-[700] text-[10px] text-primary-btn">₹ {todayGoldRate}</span></p>
                    <p className="font-[700] text-[9px]">Silver Rate : <span className="font-[700] text-[10px] text-primary-btn">₹ {todaySilverRate}</span></p>
                </div>
            </div>
 
            {/* <div className="mt-[25px] pb-[25px] border-b border-black mx-5">
                <p className="font-[700] text-[14px] text-primary-btn">Bill To</p>
            </div> */}
 
            {particulars.length > 0 && <div className="mt-[15px] mx-5">
                <p className="font-[700] text-[14px] text-primary-btn">Particulars</p>
 
                <table className="w-full">
                    <thead className=" border-b border-b-primary-btn ">
                        <tr className="">
                            <td className="py-3 text-center text-[10px]">S No</td>
                            <td className="py-3 text-center text-[10px]">Tag Number</td>
                            <td className="py-3 text-center text-[10px]">Description</td>
                            <td className="py-3 text-center text-[10px]">Pcs</td>
                            <td className="py-3 text-center text-[10px]">Net Total</td>
                        </tr>
                    </thead>
 
                    <tbody>
                        {
                            particulars.map((item, index) => (
                                <tr className="border-b">
                                    <td className="py-3 text-center text-[10px]">{index + 1}</td>
                                    <td className="py-3 text-center text-[10px]">#{item.tag_number}</td>
                                    <td className="py-3 text-center text-[10px]">{item.item}</td>
                                    <td className="py-3 text-center text-[10px]">{item.pieces}</td>
                                    <td className="py-3 text-center text-[10px]">{item.rate}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px] border-b">Sub Total</td>
                            <td className="py-3 text-center text-[10px] border-b">{parseFloat(totalAmount).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px] border-b">Gst Amount</td>
                            <td className="py-3 text-center text-[10px] border-b">{parseFloat(gstAmount).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px] border-b">Chit Amount</td>
                            <td className="py-3 text-center text-[10px] border-b">{parseFloat(chitAmount).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>}
            {oldGold.length > 0 && <div className="mt-[15px] mx-5">
                <p className="font-[700] text-[14px] text-primary-btn">Old Items</p>
 
                <table className="w-full">
                    <thead className=" border-b border-b-primary-btn ">
                        <tr className="">
                            <td className="py-3 text-center text-[10px]">S No</td>
                            <td className="py-3 text-center text-[10px]">Metal</td>
                            <td className="py-3 text-center text-[10px]">Gross Wgt</td>
                            <td className="py-3 text-center text-[10px]">Net Weight</td>
                            <td className="py-3 text-center text-[10px]">Total</td>
                        </tr>
                    </thead>
 
                    <tbody>
                        {
                            oldGold.map((item, index) => (
                                <tr className="border-b">
                                    <td className="py-3 text-center text-[10px]">{index + 1}</td>
                                    <td className="py-3 text-center text-[10px]">#{item.metal}</td>
                                    <td className="py-3 text-center text-[10px]">{item.gross_weight} g</td>
                                    <td className="py-3 text-center text-[10px]">{item.net_weight}</td>
                                    <td className="py-3 text-center text-[10px]">{item.total}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px] border-b">Exchange Amount</td>
                            <td className="py-3 text-center text-[10px] border-b">{parseFloat(exchangeAmount).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px]"></td>
                            <td className="py-3 text-center text-[10px] border-b">Grand Total</td>
                            <td className="py-3 text-center text-[10px] border-b">{parseFloat(payableAmount).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>}
        </div>
    );
}