import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isDark: false,
  menuOption: 'default',
  isLoading: true,
  sideOptions: true
}

export const themeConfigSlice = createSlice({
  name: 'themeConfig',
  initialState: initialState,
  reducers: {
    setIsDark: (state, action) => {
      localStorage.setItem('dark', action.payload)
      state.isDark = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setMenuOption: (state, action) => {
      localStorage.setItem('layout', action.payload)
      state.menuOption = action.payload
    },
    setSideOptions: (state, action) => {
      state.sideOptions = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setIsDark, setMenuOption, setIsLoading, setSideOptions } = themeConfigSlice.actions

export default themeConfigSlice.reducer