import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { metalListEndPoint, approvalruleEndPoint, ApprovalTypeListEndPoint, userRoleListEndPoint, ApprovalruleEndPoint, GetApprovalruleEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import Select from 'react-select';

export default function ApprovalruleForm({ getData, formMode, currentApprovalrule, setFormMode, setCurrentApprovalrule }) {

    const [approvaltype, setApprovaltypeList] = useState([]);
    const [userrolelist, setUserroleList] = useState([]);
    const [approvalByDropdown, setApprovalByDropdown] = useState([]);


    const [selectedUser, setSelectedUser] = useState('');

    const schema = yup
        .object({
            approval_type: yup.string().required(),
            user_role: yup.string().required(),
            approved_by: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            approval_type: currentApprovalrule.approval_type,
            user_role: currentApprovalrule.user_role,
            approved_by: currentApprovalrule.approved_by,
        } : {
            approval_type: "",
            user_role: "",
            approved_by: "",
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    useEffect(() => {
        getApprovaltypeListData();
        getUseeroleListData()
    }, [])

    useEffect(() => {
        if (formMode === 'update') {
            getUserDropdownData(currentApprovalrule.user_role)
            setSelectedUser(currentApprovalrule.approve_by)
        }
    }, [currentApprovalrule])

    const getApprovaltypeListData = async () => {
        var response = await getAxios({
            url: ApprovalTypeListEndPoint
        })

        if (response !== null) {
            setApprovaltypeList(response.data.list)
        }
    }

    const getUseeroleListData = async () => {
        var response = await getAxios({
            url: userRoleListEndPoint
        })

        if (response !== null) {
            setUserroleList(response.data.list)
        }
    }


    const getUserDropdownData = async (value) => {
        var response = await getAxios({
            url: GetApprovalruleEndPoint + value + '/'
        })

        if (response !== null) {
            let tempList = [];
            for (var i of response.data.list) {
                var tempDict = {
                    value: i.id,
                    label: i.email
                };
                tempList.push(tempDict);
            }
            setApprovalByDropdown(tempList)
            setSelectedUser([{ value: response.data.approve_by, label: response.data.email }])
            setApprovalByDropdown(response.data.list) 
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createApprovalrule(data)
            } else {
                updateApprovalrule(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createApprovalrule = async (body) => {
        var response = await postAxiosWithToken({
            url: ApprovalruleEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)

        }
    }

    const updateApprovalrule = async (body) => {
        var response = await putAxiosWithToken({
            url: ApprovalruleEndPoint + currentApprovalrule?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentApprovalrule({
            approval_type: "",
            user_role: "",
            approve_by: "",
        })
        setSelectedUser("")
    }


    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg border shadow">
                <p className="text-menu-head font-[600] text-[18px] capitalize capitalize">{formMode} Approvalrule</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Approval Type</p>
                        <select
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="approval_type"

                            {...register('approval_type')}
                        >
                            <option value={""}>--select Approval Type--</option>
                            {
                                approvaltype.map((option) => (
                                    <option key={option.id} value={option.id}>{option.approval_type}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.metal?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">User Role</p>
                        <select
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="user_role"

                            {...register('user_role')}
                            onChange={(e) => getUserDropdownData(e.target.value)}
                        >
                            <option value={""}>--select User Role--</option>
                            {
                                userrolelist.map((option) => (
                                    <option key={option.id} value={option.id}>{option.role_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.user_role?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Approve By</p>
                        <select
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="approved_by"
                            value={selectedUser}
                            {...register('approved_by')}
                            onChange={(e) => setSelectedUser(e.target.value)}
                            isMulti
                            isSearchable
                        >
                            <option value={""}>--select User Role--</option>
                            {
                                approvalByDropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.email}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.approve_by?.message}</p>
                    </div>

                    {/* <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Approval By</p>
                        <Select
                            value={selectedUser}
                            onChange={(e) => { setSelectedUser(e) }}
                            options={approvalByDropdown}
                            isMulti
                            isSearchable
                            name="approve_by"
                            isDisabled={formMode === 'update'}
                        />

                        <p className="text-red-500 font-[400] text-[13px]">{errors.approve_by?.message}</p>
                    </div> */}

                    <div className="min-[423px]:flex gap-4 sm:mt-0 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}