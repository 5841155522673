import { useEffect, useState } from "react";
import { branchListEndPoint, generateMeltingRecieptNumberEndPoint, meltingIssueListEndPoint, meltingRecieptEndPoint, oldMetalCategoryListEndPoint, transferCreationListEndPoint } from "../../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import toast from "react-hot-toast";
import { isOptionalValidForm, isValidForm } from "../../../../utilities/formvalidation";

export default function RecieptForm({ formValues, setFormValues, formMode, setFormMode, currentItem, getData, selectedDetails, setSelectedDetails }) {

    const [meltingIssueList, setMeltingIssueList] = useState([]);
    const [categoryDropDown, setCategoryDropDown] = useState([]);
    const [branchDropDown, setBranchDropDown] = useState([]);

    const [showDetails, setShowDetails] = useState(true);

    const [meltingLossWeight, setMeltingLossWeight] = useState(0);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getMeltingIssueList();
        generateMeltingRecieptNumber();
        getCategoryDropDownList();
        getBranchDropDownList();
    }, [])

    useEffect(() => {
        if (parseFloat(meltingLossWeight) >= parseFloat(selectedDetails.net_weight)) {
            toast.error("Melting loss weight greater than net weight")
        } else {
            setFormValues((prevState) => ({
                ...prevState,
                recipt_net_weight: (parseFloat(selectedDetails.net_weight) - parseFloat(meltingLossWeight)).toFixed(3)
            }))
        }
    },[meltingLossWeight])

    const generateMeltingRecieptNumber = async () => {
        var response = await getAxios({
            url: generateMeltingRecieptNumberEndPoint
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                melting_recipt_id: response.data
            }))
        }
    }

    const getMeltingIssueList = async () => {
        var response = await getAxios({
            url: meltingIssueListEndPoint
        })

        if (response != null) {
            setMeltingIssueList(response.data.list)
        }
    }

    const getBranchDropDownList = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropDown(response.data.list)
        }
    }

    const getCategoryDropDownList = async () => {
        var response = await getAxios({
            url: oldMetalCategoryListEndPoint
        })

        if (response != null) {
            setCategoryDropDown(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'melting_issue_details') {
            for (var i of meltingIssueList) {
                if (i.id == value) {
                    setSelectedDetails(i)
                    break;
                }
            }
        } else if (name == 'melting_loss_weight') {
            setMeltingLossWeight(value)
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var formData = new FormData(e.target);

        var err = {}

        for (var field of Object.entries(Object.fromEntries(formData.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(Object.fromEntries(formData.entries()), ['remark'])) {
            if (formMode == 'create') {
                createMeltingIssue(Object.fromEntries(formData.entries()))
            } else {
                updateMeltingIssue(Object.fromEntries(formData.entries()))
            }
        }
    }

    const createMeltingIssue = async (data) => {
        var response = await postAxiosWithToken({
            url: meltingRecieptEndPoint,
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForm();
            getData();
        }
    }

    const updateMeltingIssue = async (data) => {
        var response = await putAxiosWithToken({
            url: meltingRecieptEndPoint + currentItem.id + "/",
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForm();
            getData();
        }
    }

    const resetForm = () => {
        setFormValues({
            melting_issue_details: "",
            received_date: new Date().toLocaleDateString('en-CA'),
            received_category: "",
            melting_loss_weight: "",
            recipt_net_weight: "",
            melting_charges: "",
            amount_paid: "",
            branch: "",
            remark: ""
        })
        setErrors({
            melting_issue_details: "",
            received_date: "",
            received_category: "",
            melting_loss_weight: "",
            recipt_net_weight: "",
            melting_charges: "",
            amount_paid: "",
            branch: "",
        })
        setSelectedDetails({});
        setShowDetails(true);
        setMeltingLossWeight(0);
        generateMeltingRecieptNumber();
        setFormMode('create');
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-x-2">
                <div className="my-[5px] col-span-2">
                    <div className="flex justify-between items-center">
                        <p className="mb-[5px] text-[13px]">Melting Issue <span className="text-red-500 ">*</span></p>
                        <button 
                        onClick={(e) => {e.preventDefault(); setShowDetails(!showDetails)}}
                        className="text-[12px] text-blue-600">{!showDetails ? 'Show' : 'Hide'} Details</button>
                    </div>
                    { formMode == 'create' && <select
                        placeholder="Melting Issue"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="melting_issue_details"
                        value={formValues.melting_issue_details}
                        onChange={(e) => {
                            if (formMode == 'create') {
                                onValueUpdate(e)
                            }
                        }}
                        readOnly={formMode == 'update' ? true : false}
                    >
                        <option value={""}>--select issue id--</option>
                        {
                            meltingIssueList.map((option) => (
                                <option key={option.id} value={option.id}>{option.melting_issue_id}</option>
                            ))
                        }
                    </select> }
                    { formMode == 'create' && <p className="text-red-500 font-[400] text-[10px]">{errors.melting_issue_details}</p>}
                    { formMode == 'update' && <p>{formValues.melting_issue_id}</p> }
                </div>

                {
                    localStorage.getItem('branch') == 'true' && <div className="my-[5px] col-span-2">
                        <p className="mb-[5px] text-[13px]">Branch <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Branch"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                            name="branch"
                            value={formValues.branch}
                            onChange={onValueUpdate}
                        >
                            <option value="">--select branch--</option>
                            {
                                branchDropDown.map((item, index) => (
                                    <option value={item.id} key={'branch' + index}>{item.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[10px]">{errors.branch}</p>
                    </div>
                }

                { (Object.entries(selectedDetails).length > 0 && showDetails) && <div className="col-span-2 grid grid-cols-2 gap-1">
                    <div>
                        <p className="text-[12px] font-[500]">Bag No</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.bag_number}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Smith</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.smith_name}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Category</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.issued_category_name}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Issued Date</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.issued_date}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Est. Return Date</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.return_date}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Metal</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.metal_name}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Bullion Rate</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.bullion_rate}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Stone Weight</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.stone_weight}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Gross Weight</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.gross_weight}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Net Weight</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.net_weight}</p>
                    </div>
                </div> }

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Melting Reciept Id</p>
                    <input
                        type="text"
                        placeholder="Melting Reciept Id"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="melting_recipt_id"
                        value={formValues.melting_recipt_id}
                        readOnly
                    />
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Category <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Category"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="received_category"
                        value={formValues.received_category}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select category--</option>
                        {
                            categoryDropDown.map((item) => (
                                <option key={'category'+item.id} value={item.id}>{item.category_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[10px]">{errors.received_category}</p>
                </div>

                <div className="my-[5px] ">
                    <p className="mb-[5px] text-[13px]">Reciept Date <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="Date"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="received_date"
                        value={formValues.received_date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.received_date}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Melting Loss Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Melting Loss Weight"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="melting_loss_weight"
                        value={formValues.melting_loss_weight}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.melting_loss_weight}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Reciept Net Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Reciept Net Weight"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="recipt_net_weight"
                        value={formValues.recipt_net_weight}
                        readOnly
                    />
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Melting Charges <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Melting Charges"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="melting_charges"
                        value={formValues.melting_charges}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.melting_charges}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Amount Paid <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Amount Paid"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="amount_paid"
                        value={formValues.amount_paid}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.amount_paid}</p>
                </div>

                <div className="my-[5px] col-span-2">
                    <p className="mb-[5px] text-[13px]">Remark</p>
                    <textarea
                        name="remark"
                        rows="3"
                        placeholder="Remark"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        value={formValues.remark}
                        onChange={onValueUpdate}
                    ></textarea>
                </div>

                <button
                    id="backnav"
                    onClick={(e) => { e.preventDefault(); resetForm() }}
                    className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[13px] outline-none border-none"
                >reset <span className="lowercase">(esc)</span></button>
                <button
                    type="submit"
                    className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[13px] outline-none border-none"
                >save<span className="lowercase">(ctrl+s)</span></button>
            </div>
        </form>
    );
}