import { useEffect, useState } from "react";
import { metalListEndPoint } from "../../../../service/api";
import { getAxios } from "../../../../service/apiservice";

export default function OldMetalDetails({ particularsList, setParticularsList, formType, closeModal }) {

    const [metalDropdown, setMetalDropdown] = useState([]);

    useEffect(() => {
        if (formType === 'create') {
            addValue()
        }
        getMetalDropdownData()
    }, [])

    const addValue = () => {
        let firstData = {
            metal: "",
            gross_weight: "",
            metal_rate: "",
            total: "",
            old_rate: ""
        }
        setParticularsList([...particularsList, firstData])
    }

    const getMetalDropdownData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropdown(response.data.list)
        }
    }

    const onValueUpdate = (input, index) => {
        const { name, value } = input;

        let data = [...particularsList];
        data[index][name] = value;

        if (name === 'metal') {
            for (var i of metalDropdown) {
                if (i.id == value) {
                    data[index]['old_rate'] = i.old_metal_rate;
                }
            }
        } else if (name === 'gross_weight') {
            if (data[index].metal_rate.length !== 0) {
                let oldRate = parseFloat(data[index].old_rate);
                data[index]['total'] = (parseFloat(value).toFixed(3) * oldRate).toFixed(2)
            }
        } else if (name === 'metal_rate') {
            if (data[index].gross_weight.length !== 0) {
                let oldRate = parseFloat(data[index].old_rate);
                data[index]['total'] = (parseFloat(data[index].gross_weight).toFixed(3) * parseFloat(oldRate)).toFixed(2)
            }
        } else if (name === 'old_rate') {
            if (data[index].gross_weight.length !== 0) {
                let oldRate = parseFloat(value);
                data[index]['total'] = (parseFloat(data[index].gross_weight).toFixed(3) * parseFloat(oldRate)).toFixed(2)
            }
        }

        setParticularsList(data);
    }

    return (
        <div>
            <button
                onClick={(e) => { e.preventDefault(); addValue() }}
                className="mb-2 capitalize float-right bg-primary-btn bg-opacity-50 hover:bg-opacity-100 text-black hover:text-white w-fit rounded p-[5px] px-[10px] h-fit text-[12px] outline-none border border-primary-btn"
            >Add Item</button>
            <div className="w-full max-h-[300px] overflow-scroll">
                <table className="w-full">
                    <thead className="bg-table-head text-table-head-font">
                        <tr>
                            <td className="p-3 border">S.No</td>
                            <td className="p-3 border">Metal</td>
                            <td className="p-3 border">Gross Weight</td>
                            <td className="p-3 border">Metal Rate</td>
                            <td className="p-3 border">Total</td>
                            <td className="p-3 border">Old Metal Rate</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            particularsList.map((item, index) => (
                                <tr>
                                    <td className="p-3 border">S.No</td>
                                    <td className="p-3 border">
                                        <select
                                            placeholder="metal name"
                                            className="border outline-none w-[150px] bg-white"
                                            name="metal"
                                            value={item.metal}
                                            onChange={(e) => {
                                                onValueUpdate(e.target, index);
                                            }}
                                        >
                                            <option value={""}>--select metal--</option>
                                            {
                                                metalDropdown?.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.metal_name}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                    <td className="p-3 border">
                                        <input
                                            type="number"
                                            name="gross_weight"
                                            className="border outline-none w-[100px]"
                                            value={item.gross_weight}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className="p-3 border">
                                        <input
                                            type="number"
                                            name="metal_rate"
                                            className="border outline-none w-[100px]"
                                            value={item.metal_rate}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className="p-3 border">{item.total}</td>
                                    <td className="p-3 border">
                                        <input
                                            type="number"
                                            name="old_rate"
                                            className="border outline-none w-[100px]"
                                            value={item.old_rate}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <button
                onClick={(e) => { e.preventDefault(); closeModal() }}
                className="mt-2 float-right capitalize bg-secondary border-2 border-gray-300 text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none"
            >Done </button>
        </div>
    );
}