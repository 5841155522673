import { useEffect, useRef, useState } from "react";
import ParticularsList from "./widgets/particularslist";
import toast from "react-hot-toast";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";
import { billTypePaymentEndPoint, branchListEndPoint, estimationApprovalListEndPoint, estimationDetailGetEndPoint, estimationdataEndPoint, generateRequisitionApprovalIdEndPoint, getTagDetailsByBranchEndPoint, requisitionApprovalEndPoint, shopListEndPoint, staffListByBranchEndPoint, tagdetailsEndPoint, userListEndPoint } from "../../../service/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RequisitionApprovalPrint from "../../../components/Printouts/RequisitionApprovalPrint";
import ReactToPrint from "react-to-print";
import Select from "react-select";

export default function RequisitionApprovalForm() {

    let componentRef = useRef();

    const { id } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create')

    const [formValues, setFormValues] = useState({
        issue_date: new Date().toLocaleDateString('en-CA')
    });
    const [errors, setErrors] = useState({});

    const [tagNumber, setTagNumber] = useState('');
    const [tagNumberError, setTagNumberError] = useState(false);

    const [branchDropDown, setBranchDropDown] = useState([]);
    const [shopDropDown, setShopDropDown] = useState([]);

    const [selectedBranch, setSelectedBranch] = useState(null);

    const [particulars, setParticulars] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedEstimationNumber, setSelectedEstimationNumber] = useState('');

    const [billtype, setBilltype] = useState([]);
    const [billid, setBillid] = useState({});

    useEffect(() => {
        if (pathname.includes('update')) {
            setFormMode('update')
            getApprovalDetails();
        } else {
            generateApprovalId()
        }
        getBranchDropDown();
        getMetalType();
    }, [])

    useEffect(() => {
        let totalGrossWeight = 0.0;
        let totalNetWeight = 0.0;
        let tagNumbers = [];
        for (var i of particulars) {
            totalGrossWeight = totalGrossWeight + parseFloat(i.gross_weight)
            totalNetWeight = totalNetWeight + parseFloat(i.net_weight)

            if (formMode == "create") {
                tagNumbers.push(i.tag_number)
            } else {
                if (!(i.from_db)) {
                    tagNumbers.push(i.tag_number)
                }
            }
        }
        setFormValues((prevState) => ({
            ...prevState,
            issued_gross_weight: totalGrossWeight,
            issued_net_weight: totalNetWeight
        }));
        setTagList(tagNumbers)
    }, [particulars])

    useEffect(() => {
        if (selectedBranch == null || selectedBranch == undefined || selectedBranch == '') {
            setParticulars([])
        } else {
            if (String(selectedBranch).length > 0) {
                getShopDropDown(selectedBranch);
            } else {
                setShopDropDown([]);
            }
        }
    }, [selectedBranch])

    useEffect(() => {

        if (selectedBranch && billid) {
            var tempid = {
                bill_type: selectedBranch,
                branch: billid
            }
            getEstimationListDropDown(tempid)
        } else {
            setOptions([])
        }
    }, [selectedBranch, billid])

    const getApprovalDetails = async () => {
        var response = await getAxios({
            url: requisitionApprovalEndPoint + id + '/'
        })

        if (response != null) {
            var tempDetails = {
                approval_issue_id: response.data.approval_issue_id,
                issue_date: response.data.issue_date,
                issued_by: response.data.issued_by,
                receiver_name: response.data.receiver_name,
                shop_name: response.data.shop_name,
                branch: response.data.branch,
                notes: response.data.notes,
            }
            setSelectedBranch(response.data.branch)
            setParticulars(response.data.issued_tag_details)
            setFormValues(tempDetails)
        }
    }

    const generateApprovalId = async () => {
        var response = await getAxios({
            url: generateRequisitionApprovalIdEndPoint
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                approval_issue_id: response.data
            }))
        }
    }

    const getBranchDropDown = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropDown(response.data.list)
        }
    }

    const getShopDropDown = async () => {
        var response = await getAxios({
            url: shopListEndPoint + selectedBranch + '/'
        })

        if (response != null) {
            setShopDropDown(response.data.list)
        }
    }

    const getTagDetails = async () => {
        if (selectedBranch) {
            var response = await postAxiosWithToken({
                url: getTagDetailsByBranchEndPoint,
                body: {
                    tag_number: tagNumber,
                    branch: selectedBranch
                }
            })

            if (response != null) {

                if (particulars.length > 0) {
                    var validate = particulars.filter((item) => item.tag_number == tagNumber)

                    if (validate.length == 0) {
                        let tempData = {
                            tag_number: tagNumber,
                            item_name: response.data.item_name,
                            sub_item_name: response.data.sub_item_name,
                            pieces: response.data.pieces,
                            gross_weight: response.data.gross_weight,
                            net_weight: response.data.net_weight,
                            metal: response.data.metal
                        }

                        setParticulars([tempData, ...particulars])
                        setTagNumber("")
                    } else {
                        toast.error('Tag details already added!')
                    }
                } else {
                    let tempData = {
                        tag_number: tagNumber,
                        item_name: response.data.item_name,
                        sub_item_name: response.data.sub_item_name,
                        pieces: response.data.pieces,
                        gross_weight: response.data.gross_weight,
                        net_weight: response.data.net_weight,
                        metal: response.data.metal
                    }

                    setParticulars([tempData, ...particulars])
                    setTagNumber("")
                }
            }
        } else {
            toast.error('Select Branch')
        }

    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))

        if (name == 'branch') {
            setSelectedBranch(value);
        } else if (name == 'bill_type') {
            setBillid(value);
        }
    }

    const handleSubmit = (print) => {
        let detailsForm = new FormData(document.getElementById('form'))

        var jsonData = Object.fromEntries(detailsForm.entries())

        var err = {}

        for (var field of Object.entries(jsonData)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(jsonData, ['notes'])) {
            if (formMode == 'create') {
                if (tagList.length > 0) {
                    let data = { ...formValues }
                    data['issue_tag_details'] = tagList
                    createApproval(data, print)
                } else {
                    toast.error('No items added in the list')
                }
            } else {
                let data = { ...formValues }
                if (tagList.length > 0) {
                    data['new_tag_items'] = tagList
                }

                if (particulars.length > 0) {
                    updateApproval(data, print)
                } else {
                    toast.error('No items added in the list')
                }
            }
        }
    }

    const createApproval = async (body, print) => {
        var response = await postAxiosWithToken({
            url: requisitionApprovalEndPoint,
            body: body
        })

        if (response != null) {
            if (print) {
                document.getElementById('print').click()
            } else {
                resetForm();
                navigate(-1)
            }
            toast.success(response.message)
        }
    }

    const updateApproval = async (body, print) => {
        var response = await putAxiosWithToken({
            url: requisitionApprovalEndPoint + id + '/',
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            if (print) {
                document.getElementById('print').click()
            } else {

                resetForm();
            }
        }
    }

    const resetForm = () => {
        setFormValues((prevState) => ({
            ...prevState,
            issue_date: new Date().toLocaleDateString('en-CA'),
            branch: "",
            issued_by: "",
            issued_gross_weight: 0,
            issued_net_weight: 0,
            notes: "",
            receiver_name: "",
            shop_name: ""
        }))
        generateApprovalId();
        setParticulars([]);
    }






    const getEstimationListDropDown = async (body) => {
        console.log("body",body)
        var response = await postAxiosWithToken({
            url: estimationdataEndPoint,
            body: body
        })
        if (response !== null) {
            let tempList = [];
            for (var i of response.data.list) {
                let dictData = {
                    value: i.id,
                    label: i.estimate_no
                }
                tempList.push(dictData)
            }
            setOptions(tempList);

        }
    }

    const handleEstimationChange = async (selectedEstimationNumber) => {
        setSelectedEstimationNumber(selectedEstimationNumber);

        var response = await getAxios({
            url: estimationDetailGetEndPoint + selectedEstimationNumber + '/'
        })

        if (response != null) {
            var templist = []
            for (var i of response.data.estimation_details.tag_item_details) {
                var dictData = {
                    tag_number: i.tag_number,
                    metal: i.metal,
                    item_name: i.item,
                    sub_item_name: i.sub_item_name,
                    pieces: i.total_pieces,
                    gross_weight: i.gross_weight,
                    net_weight: i.net_weight,
                    isoCode: i.metal,
                }
                templist.push(dictData)
            }
            setParticulars(templist)
        }
    }

    const getMetalType = async () => {
        var response = await getAxios({
            url: billTypePaymentEndPoint
        })

        if (response !== null) {
            setBilltype(response.data.list)
        }
    }


    return (
        <div className="mb-[150px]">
            <p className="font-[500] text-[20px] capitalize">{formMode} Requisition Approval</p>

            <div className="border rounded-xl bg-white p-3">
                <p className="font-[500] text-[15px] mb-[5px]">Requisition Approval</p>

                <form id='form'>
                    <div className="grid grid-cols-6 gap-x-3">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Requisition Id</p>
                            <input
                                type="text"
                                placeholder="Requisition Id"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                name="approval_issue_id"
                                value={formValues.approval_issue_id}
                                readOnly
                            />
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Date <span className="text-red-500">*</span></p>
                            <input
                                type="date"
                                placeholder="Date"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                name="issue_date"
                                value={formValues.issue_date}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.issue_date}</p>
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Metal</p>
                            <select
                                placeholder="metal_type"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-white"
                                name="bill_type"
                                value={formValues.bill_type}
                                onChange={onValueUpdate}
                            >
                                <option value="">-- Select Metal Type --</option>
                                {billtype.map((option) => (
                                    <option key={option?.id} value={option.id}>
                                        {option.bill_type}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {localStorage.getItem('branch') === 'true' && <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[16px]">Branch <span className="text-red-500">*</span></p>
                            <select
                                placeholder="Branch"
                                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="branch"
                                value={formValues.branch}
                                onChange={onValueUpdate}
                            >
                                <option value={""}>--select branch--</option>
                                {
                                    branchDropDown.map((option) => (
                                        <option className="capitalize hover:bg-[red]" key={"branch" + option.name} value={option.id}>{option.branch_name}</option>
                                    ))
                                }
                            </select>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.branch}</p>
                        </div>}

                        <div>
                            <div className="sm:my-[5px] text-[13px]">Estimation No</div>
                            <div className="flex bg-white p-[5px] border rounded-lg">
                                <Select
                                    // value={selectedEstimationNumber}
                                    options={options}
                                    isSearchable
                                    onChange={(e) => handleEstimationChange(e.value)}
                                    className="w-[300px]"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? 'none' : 'none',
                                            border: 'none',
                                        }),
                                    }}
                                />
                                <button className="bg-primary-btn rounded-md text-white  text-[13px] w-[100px]" onClick={(e) => { e.preventDefault(); }}>Fill Details</button>
                            </div>
                        </div>


                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Issued By <span className="text-red-500">*</span></p>
                            <input
                                type="text"
                                placeholder="Issued By"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                name="issued_by"
                                value={formValues.issued_by}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.issued_by}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Requisition Shop <span className="text-red-500">*</span></p>
                            <select
                                type="text"
                                placeholder="Requisition Shop"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-white"
                                name="shop_name"
                                value={formValues.shop_name}
                                onChange={onValueUpdate}
                            >
                                <option value={""}>--select requistion shop--</option>
                                {
                                    shopDropDown.map((option) => (
                                        <option className="capitalize" key={"order_for" + option.name} value={option.id}>{option.customer_name}</option>
                                    ))
                                }
                            </select>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.shop_name}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[16px]">Receiver Name <span className="text-red-500">*</span></p>
                            <input
                                type="text"
                                placeholder="Receiver Name"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                name="receiver_name"
                                value={formValues.receiver_name}
                                onChange={onValueUpdate}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.receiver_name}</p>
                        </div>
                        <div className="my-[5px] col-span-2">
                            <p className="mb-[5px] text-[16px]">Notes</p>
                            <input
                                type="text"
                                placeholder="Notes"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                name="notes"
                                value={formValues.notes}
                                onChange={onValueUpdate}
                            />
                        </div>
                    </div>

                    <div className="fixed bottom-[35px] left-0 p-3 bg-white flex gap-3 justify-end items-center w-full">
                        <button
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-fit rounded-lg p-[10px] font-[500] text-[15px] outline-none border"
                        >close <span className="lowercase">(esc)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); handleSubmit(false) }}
                            className="capitalize bg-primary-btn bg-opacity-50 hover:bg-opacity-100 text-black hover:text-white w-fit rounded p-[10px] h-fit text-[15px] font-[500] outline-none border border-primary-btn"
                        >save <span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); handleSubmit(true) }}
                            className="capitalize bg-primary-btn text-white w-fit rounded-lg p-[10px] font-[500] text-[15px] outline-none border"
                        >save & print <span className="lowercase">(ctrl+p)</span></button>
                    </div>
                </form>

                <div className="mt-[10px]">
                    <p className="font-[500] text-[15px] mb-[5px">Requisition Item Details</p>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Scan Barcode</p>
                        <div className='sm:mb-[5px] w-[300px] flex gap-3 items-center border border-gray-300 rounded-lg p-[10px]'>
                            <input
                                type="text"
                                placeholder="Tag Number"
                                className=" w-full text-[17px] outline-none"
                                name="barcode"
                                value={tagNumber}
                                onChange={(e) => setTagNumber(e.target.value)}
                            />
                            <div onClick={() => {
                                if (tagNumber.length >= 7) {
                                    getTagDetails();
                                    setTagNumberError(false)
                                } else {
                                    setTagNumberError(true)
                                }
                            }} className='w-[24px] h-[24px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16"><path fill="currentColor" d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0z" /></svg>
                            </div>
                        </div>
                        {tagNumberError && <p className="text-red-500 font-[400] text-[13px]">Tag number is less than 7 should not be applicable</p>}
                    </div>

                    <ParticularsList items={particulars} setItems={setParticulars} formValues={formValues} formMode={formMode} />
                </div>
            </div>
            <ReactToPrint
                trigger={() => <button id="print" className=" hidden capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none">
                    Print (ctrl+p)
                </button>}
                content={() => componentRef}
            />

            <div className="hidden">
                <div className="mb-[25px]" ref={(el) => (componentRef = el)}>
                    <RequisitionApprovalPrint
                        particulars={particulars || []}
                        detials={formValues}

                    />
                </div>
            </div>
        </div>
    );
}