import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import { baseUrl } from '../../../../service/api';

export default function TableImageSlider({ imagesList }) {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                keyboard={{
                    enabled: true,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Keyboard, Pagination, Navigation]}
                className="mySwiper"
            >
                {
                    imagesList.map((item, index) => (
                        <SwiperSlide>
                            <div className='w-full h-full '>
                                <img src={baseUrl + item.image} width={"100%"} />
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    );
}
