import React, { useRef, useEffect, useState } from "react";
import Chart from "react-apexcharts";

const CircleChart = ({ value , color }) => {
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({
    colors: color,
    series: [0],
    chart: {
      height: 130,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "40%"   
        }, 
         dataLabels: {
          show: false,
        },
        stroke: {
          lineCap: "round",
        }
      }
    },
  });

  useEffect(() => {
    setChartOptions((prevState) => ({
      ...prevState,
      series: value
    }))
  },[value])

  return (
    <div className="app">
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type={chartOptions.chart.type}
        height={chartOptions.chart.height}
      />
    </div>
  );
};

export default CircleChart;
