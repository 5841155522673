import { useState } from "react";
import Shape from "./shape";
import Cut from "./cut";
import Clarity from "./clarity";
import Color from "./color";
import Stone from "./stone";
import Centgroup from "./centgroup";

export default function Standard() {

  const [tab, setTab] = useState(1);



  return (
    <>
      <div className="rounded-full flex justify-evenly bg-slate-300 flex-wrap text-sm font-medium text-center text-gray-500 p-[10px]">
        <button
          onClick={() => setTab(1)}
          className={` px-4 py-1 rounded-full m-1 hover:violet-700   ${tab === 1 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Shape
        </button>
        <button
          onClick={() => setTab(2)}
          className={` px-4 py-1 rounded-full m-1 hover:violet-700   ${tab === 2 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Cut
        </button>
        <button
          onClick={() => setTab(3)}
          className={` px-4 py-1 rounded-full m-1 hover:violet-700  ${tab === 3 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Clarity
        </button>
        <button
          onClick={() => setTab(4)}
          className={` px-4 py-1 rounded-full m-1 hover:violet-700  ${tab === 4 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Color
        </button>
        <button
          onClick={() => setTab(5)}
          className={` px-4 py-1 rounded-full m-1 hover:violet-700  ${tab === 5 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Cent Group
        </button>
        <button
          onClick={() => setTab(6)}
          className={` px-4 py-1 rounded-full m-1 hover:violet-700  ${tab === 6 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Stone
        </button>
      </div>

      <div style={{ display: tab === 1 ? "block" : "none", }}>
        <Shape tab={tab} /></div>

      <div style={{ display: tab === 2 ? "block" : "none", }}>
        <Cut tab={tab} /></div>
  
      <div style={{ display: tab === 3 ? "block" : "none", }}>
        <Clarity tab={tab} /></div>

      <div style={{ display: tab === 4 ? "block" : "none", }}>
        <Color tab={tab} /></div>
        <div style={{ display: tab === 5 ? "block" : "none", }}>
        <Centgroup tab={tab} /></div>

      <div style={{ display: tab === 6 ? "block" : "none", }}>
        <Stone tab={tab} /></div>
    </>
  );
}