import { useEffect, useState } from "react";
import { accountheadListEndPoint, branchListEndPoint, generateBagNumberEndPoint, oldMetalCategoryListEndPoint, transferCreationTypeListEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { bagTransferType } from "../../../constant";
import { isValidForm } from "../../../utilities/formvalidation";

export default function BagForm({ formValues, setFormValues, errors, setErrors, handleSubmit }) {

    const [designerDropDown, setDesignerDropDown] = useState([]);
    const [transferTypeDropDown, setTransferTypeDropDown] = useState([]);
    const [categoryDropDown, setCategoryDropDown] = useState([]);

    const [selectedTransferType, setSelectedTransferType] = useState(bagTransferType);
   
    useEffect(() => {
        getDesignerDropDownList()
        getTransferTypeDropDownList()
        getCategoryDropDownList()
    }, [])

    useEffect(() => {
        if (selectedTransferType == bagTransferType) {
            genertateBagNumber()
        }
    }, [selectedTransferType])

    const getCategoryDropDownList = async () => {
        var response = await getAxios({
            url: oldMetalCategoryListEndPoint
        })

        if (response != null) {
            setCategoryDropDown(response.data.list)
        }
    }

    const getTransferTypeDropDownList = async () => {
        var response = await getAxios({
            url: transferCreationTypeListEndPoint
        })

        if (response != null) {
            setTransferTypeDropDown(response.data.list)
        }
    }

    const getDesignerDropDownList = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response != null) {
            setDesignerDropDown(response.data.list)
        }
    }

    const genertateBagNumber = async () => {
        var response = await getAxios({
            url: generateBagNumberEndPoint
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                refference_number: response.data
            }))
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == "transfer_type") {
            setSelectedTransferType(value)
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-3 w-full gap-x-3">
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Transfer Type <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Transfer Type"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                        name="transfer_type"
                        value={formValues.transfer_type}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select transfer-</option>
                        {
                            transferTypeDropDown.map((item) => (
                                <option key={'designer' + item.id} value={item.id}>{item.type_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.transfer_type}</p>
                </div>
                {formValues.transfer_type == bagTransferType ? <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Bag No</p>
                    <input
                        type="text"
                        placeholder="Bag No"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="refference_number"
                        value={formValues.refference_number}
                        readOnly
                    />
                </div> :
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Lot No</p>
                        <input
                            type="text"
                            placeholder="Lot No"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="refference_number"
                            value={formValues.refference_number}
                            readOnly
                        />
                    </div>}

                    <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Purchase Category <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Purchase Category"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                        name="transfer_category"
                        value={formValues.transfer_category}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select category-</option>
                        {
                            categoryDropDown.map((item) => (
                                <option key={'category'+item.id} value={item.id}>{item.category_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.transfer_category}</p>
                </div>

                <div className="my-[5px] col-span-2">
                    <p className="mb-[5px] text-[15px]">Designer <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Designer"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                        name="smith"
                        value={formValues.smith}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select designer-</option>
                        {
                            designerDropDown.map((item) => (
                                <option key={'designer' + item.id} value={item.id}>{item.account_head_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.smith}</p>
                </div>

                <button
                    type="submit"
                    className="mt-[25px] capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >Save</button>

            </div>
        </form>
    );
}