import { useEffect, useRef, useState } from "react";
import { calculationtypeDropdownEndPoint, counterListEndPoint, itemMasterEndPoint, itemMasterListEndPoint, measurementTypeEndPoint, metalListEndPoint, purityListEndPoint, subItemUploadImgEndPoint, subitemEndPoint, weightTypeListEndPoint, subitemidcodeEndPoint, itemListbyMetalEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { fixedCalcId, pergramCalId, weightCalId } from "../../../constant";
import { isOptionalValidForm } from "../../../utilities/formvalidation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function SubitemForm() {

    const navigate = useNavigate();

    const [imageFile, setImageFile] = useState("")

    const [formMode, setFormMode] = useState('create');
    const [currentSubItem, setCurrentSubItem] = useState({
        allow_zero_weight: false
    });

    const [errors, setErrors] = useState({});

    const [subitemid, setSubItemId] = useState('')

    const [calculationTypeDropdown, setCalculationTypeDropdown] = useState([]);
    const [measurementTypeDropdown, setMeasurementTypeDropdown] = useState([]);
    const [metalTypeDropdown, setMetalTypeDropdown] = useState([]);
    const [purityTypeDropdown, setPurityTypeDropdown] = useState([]);
    const [itemDropdown, setItemDropdown] = useState([]);
    const [counterDropdown, setCounterDropdown] = useState([]);
    const [weightTypeDropdown, setWeightTypeDropdown] = useState([]);
    const [metaldrop, setMetalDrop] = useState('')
    const [puritydrop, setPurityDrop] = useState('')

    const [itemDetails, setItemDetails] = useState({});

    useEffect(() => {
        getCalculationTypeDropDown();
        getMeasurementTypeDropDown();
        getMetalTypeDropDown();
        getCounterDropDown();
        getWeightTypeDropDown();


        setFormMode(localStorage.getItem('subitem_form_mode'));

        if (localStorage.getItem('subitem_form_mode') === 'update') {
            getSubItemDetails()
        } else {
            getSubItemIdData();
        }
    }, [])


    useEffect(() => {
        if (metaldrop.length !== 0 && puritydrop.length !== 0) {
            getItemDropDown();
        }

    }, [metaldrop, puritydrop])


    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit')?.blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    const getSubItemIdData = async () => {
        var response = await getAxios({
            url: subitemidcodeEndPoint

        })

        if (response !== null) {
            setCurrentSubItem((prevState) => ({
                ...prevState,
                sub_item_id: response.sub_item_id
            }))
        }
    }

    const getSubItemDetails = async () => {
        var response = await getAxios({
            url: subitemEndPoint + localStorage.getItem('subitem_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {

                metal: response.data.subitem_details.metal,
                purity: response.data.subitem_details.purity,
                item_id: response.data.subitem_details.item_details,
                item_code: response.data.subitem_details.item_details,
                item_name: response.data.subitem_details.item_details,
                hsn_code: response.data.subitem_details.item_details.hsn_code,
                stock_type: response.data.subitem_details.item_details.stock_type__stock_type_name,
                sub_item_code: response.data.subitem_details.sub_item_code,
                sub_item_name: response.data.subitem_details.sub_item_name,
                sub_item_id: response.data.subitem_details.sub_item_id,
                subitem_hsn_code: response.data.subitem_details.subitem_hsn_code,
                sub_item_counter: response.data.subitem_details.sub_item_counter,
                allow_zero_weight: response.data.subitem_details.allow_zero_weight,
                measurement_type: response.data.subitem_details.measurement_type,
                sub_item_image: response.data.subitem_details.sub_item_image,
                calculation_type: response.data.subitem_details.calculation_type,
                fixed_rate: String(response.data.subitem_details.calculation_type) === String(fixedCalcId) ? response.data.calculation_details.fixed_rate : "",
                per_gram_rate: String(response.data.subitem_details.calculation_type) === String(pergramCalId) ? response.data.calculation_details.per_gram_rate : "",
                wastage_percent: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_percent : "",
                flat_wastage: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.flat_wastage : "",
                making_charge_gram: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_gram : "",
                flat_making_charge: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.flat_making_charge : "",
                wastage_calculation: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_calculation : "",
                making_charge_calculation: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_calculation : "",

            }

            setItemDetails((prevState) => ({
                ...prevState,
                fixed_rate: String(response.data.subitem_details.calculation_type) === String(fixedCalcId) ? response.data.calculation_details.fixed_rate : "",
                per_gram_rate: String(response.data.subitem_details.calculation_type) === String(pergramCalId) ? response.data.calculation_details.per_gram_rate : "",
                wastage_percent: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_percent : "",
                flat_wastage: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.flat_wastage : "",
                making_charge_gram: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_gram : "",
                flat_making_charge: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.flat_making_charge : "",
                wastage_calculation: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_calculation : "",
                making_charge_calculation: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_calculation : "",
            }))

            // getItemDetails(response.data.subitem_details.item_details)

            setMetalDrop(response.data.subitem_details.metal)
            setPurityDrop(response.data.subitem_details.purity)
            getPurityListDropDown(response.data.subitem_details.metal)
            setCurrentSubItem(tempDetails)

        }
    }

    const getCalculationTypeDropDown = async () => {
        var respose = await getAxios({
            url: calculationtypeDropdownEndPoint
        })

        if (respose !== null) {
            setCalculationTypeDropdown(respose.data.list)
        }
    }

    const getMeasurementTypeDropDown = async () => {
        var respose = await getAxios({
            url: measurementTypeEndPoint
        })

        if (respose !== null) {
            setMeasurementTypeDropdown(respose.data.list)
        }
    }

    const getMetalTypeDropDown = async () => {
        var respose = await getAxios({
            url: metalListEndPoint
        })

        if (respose !== null) {
            setMetalTypeDropdown(respose.data.list)
        }
    }

    const getPurityListDropDown = async (id) => {
        var respose = await getAxios({
            url: purityListEndPoint + id + '/',
        })

        if (respose !== null) {
            setPurityTypeDropdown(respose.data.list)
        }
    }

    const getItemDropDown = async (id) => {
        var respose = await postAxiosWithToken({
            url: itemListbyMetalEndPoint,
            body: {
                metal: metaldrop,
                purity: puritydrop
            }
        })

        if (respose !== null) {
            setItemDropdown(respose.data.list)
        }
    }


    const getItemDetails = async (item, id) => {

        let itemShortDetails = JSON.parse(item)

        var respose = await getAxios({
            url: itemMasterEndPoint + itemShortDetails.id + "/",

        })

        if (respose !== null) {
            setItemDetails(respose.data)
            setCurrentSubItem((prevState) => ({
                ...prevState,
                calculation_type: respose.data.calculation_type
            }))
        }

    }

    const getCounterDropDown = async () => {
        var respose = await getAxios({
            url: counterListEndPoint
        })

        if (respose !== null) {
            setCounterDropdown(respose.data.list)
        }
    }


    const getWeightTypeDropDown = async () => {
        var respose = await getAxios({
            url: weightTypeListEndPoint
        })

        if (respose !== null) {
            setWeightTypeDropdown(respose.data.list)
        }
    }

    const onValueUpdate = (text) => {
        const { name, value } = text
        setItemDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const onSubItemValueUpdate = (text) => {
        const { name, value, checked } = text

        if (name === "item_id" || name === "item_code" || name === "item_name") {

            if (value === "") {
                setCurrentSubItem((prevState) => ({
                    ...prevState,
                    item_id: value,
                    item_code: value,
                    item_name: value,
                    hsn_code: "",
                    stock_type: "",
                }))
            } else {
                getItemDetails(value)
                setCurrentSubItem((prevState) => ({
                    ...prevState,
                    item_id: JSON.parse(value),
                    item_code: JSON.parse(value),
                    item_name: JSON.parse(value),
                    hsn_code: JSON.parse(value)?.hsn_code,
                    stock_type: JSON.parse(value)?.stock_type__stock_type_name,
                }))

            }
            setErrors((prevState) => ({
                ...prevState,
                item_id: value.length === 0 ? `item_id field is required` : '',
                item_code: value.length === 0 ? `item_code field is required` : '',
                item_name: value.length === 0 ? `item_name field is required` : '',
                hsn_code: value.length === 0 ? `hsn_code field is required` : '',
                stock_type: value.length === 0 ? `stock_type field is required` : '',
            }));

        } else if (name === "allow_zero_weight") {
            setCurrentSubItem((prevState) => ({
                ...prevState,
                allow_zero_weight: checked
            }))
        } else if (name === "metal") {
            if (value === "") {
                setPurityTypeDropdown([])
            } else {
                getPurityListDropDown(value);
                setMetalDrop(value)
            }
            setCurrentSubItem((prevState) => ({
                ...prevState,
                [name]: value
            }))
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
        } else if (name === "purity") {
            if (value === "") {
            } else {
                setPurityDrop(value)
            }
            setCurrentSubItem((prevState) => ({
                ...prevState,
                [name]: value
            }))
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
        } else {
            setCurrentSubItem((prevState) => ({
                ...prevState,
                [name]: value
            }))

            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
        }

    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        let notRequiredList = [];

        if (formDataObject.calculation_type === String(fixedCalcId)) {
            notRequiredList = ['wastage_calculation', 'wastage_percent', 'flat_wastage', 'making_charge_calculation', 'making_charge_gram', 'flat_making_charge', 'per_gram_rate']
        } else if (formDataObject.calculation_type === String(pergramCalId)) {
            notRequiredList = ['wastage_calculation', 'wastage_percent', 'flat_wastage', 'making_charge_calculation', 'making_charge_gram', 'flat_making_charge', 'fixed_rate']
        } else if (formDataObject.calculation_type === String(weightCalId)) {
            notRequiredList = ['fixed_rate', 'per_gram_rate']
        } else {
            notRequiredList = []
        }

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !notRequiredList.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(formDataObject, notRequiredList)) {
            if (formMode === 'create') {
                let createBody = formDataObject

                if (formDataObject.calculation_type === String(fixedCalcId)) {
                    delete createBody.wastage_calculation
                    delete createBody.wastage_percent
                    delete createBody.flat_wastage
                    delete createBody.making_charge_calculation
                    delete createBody.making_charge_gram
                    delete createBody.flat_making_charge
                    delete createBody.per_gram_rate
                } else if (formDataObject.calculation_type === String(pergramCalId)) {
                    delete createBody.wastage_calculation
                    delete createBody.wastage_percent
                    delete createBody.flat_wastage
                    delete createBody.making_charge_calculation
                    delete createBody.making_charge_gram
                    delete createBody.flat_making_charge
                    delete createBody.fixed_rate
                } else if (formDataObject.calculation_type === String(weightCalId)) {
                    delete createBody.fixed_rate
                    delete createBody.per_gram_rate
                }


                createBody['item_details'] = JSON.parse(formDataObject.item_id)?.id
                createBody['allow_zero_weight'] = currentSubItem.allow_zero_weight

                if (imageFile !== "") {
                    var imageResponse = await postAxiosWithToken({
                        url: subItemUploadImgEndPoint,
                        body: new FormData(document.getElementById('imageupload'))
                    })

                    if (imageResponse !== null) {
                        createBody['sub_item_image'] = imageResponse.sub_item_image_url
                    } else {
                        createBody['sub_item_image'] = ''
                    }
                } else {
                    createBody['sub_item_image'] = ''
                }

                createSubItemmaster(createBody)
            } else {
                let updateBody = formDataObject

                if (formDataObject.calculation_type === String(fixedCalcId)) {
                    delete updateBody.wastage_calculation
                    delete updateBody.wastage_percent
                    delete updateBody.flat_wastage
                    delete updateBody.making_charge_calculation
                    delete updateBody.making_charge_gram
                    delete updateBody.flat_making_charge
                    delete updateBody.per_gram_rate
                } else if (formDataObject.calculation_type === String(pergramCalId)) {
                    delete updateBody.wastage_calculation
                    delete updateBody.wastage_percent
                    delete updateBody.flat_wastage
                    delete updateBody.making_charge_calculation
                    delete updateBody.making_charge_gram
                    delete updateBody.flat_making_charge
                    delete updateBody.fixed_rate
                } else if (formDataObject.calculation_type === String(weightCalId)) {
                    delete updateBody.fixed_rate
                    delete updateBody.per_gram_rate
                }

                updateBody['item_details'] = JSON.parse(formDataObject.item_id)?.id
                updateBody['allow_zero_weight'] = currentSubItem.allow_zero_weight

                if (currentSubItem.sub_item_image) {
                    if (imageFile !== "") {
                        var tempImageSplit = currentSubItem.sub_item_image.split("/")

                        var imageResponse = await putAxiosWithToken({
                            url: subItemUploadImgEndPoint + tempImageSplit[tempImageSplit.length - 1] + "/",
                            body: new FormData(document.getElementById('imageupload'))
                        })

                        if (imageResponse !== null) {
                            updateBody['sub_item_image'] = imageResponse.sub_item_image_url
                        } else {
                            updateBody['sub_item_image'] = ''
                        }
                    } else {
                        updateBody['sub_item_image'] = currentSubItem.sub_item_image
                    }
                } else {
                    if (imageFile !== "") {
                        var imageResponse = await postAxiosWithToken({
                            url: subItemUploadImgEndPoint,
                            body: new FormData(document.getElementById('imageupload'))
                        })

                        if (imageResponse !== null) {
                            updateBody['sub_item_image'] = imageResponse.sub_item_image_url
                        } else {
                            updateBody['sub_item_image'] = ''
                        }
                    } else {
                        updateBody['sub_item_image'] = ''
                    }
                }

                updateSubItemmaster(updateBody)


                console.log(updateSubItemmaster(updateBody))
            }
        }

    }
    const createSubItemmaster = async (body) => {
        var response = await postAxiosWithToken({
            url: subitemEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate('/productmaster/subitem')
        }
    }

    const updateSubItemmaster = async (body) => {
        var response = await putAxiosWithToken({
            url: subitemEndPoint + localStorage.getItem('subitem_form_id') + "/",
            body: body
        })

        if (response != null) {
            localStorage.removeItem('subitem_form_id')
            localStorage.setItem('subitem_form_mode', 'create')
            toast.success(response.message)
            navigate('/productmaster/subitem')
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Subitem</p>
                <form id="imageupload" hidden>
                    <input onChange={(e) => { setImageFile(e.target.value.split('\\')[e.target.value.split('\\').length - 1]) }} type="file" name="image" id="subitemimage" />
                </form>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Subitem Details</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Metal</p>
                                <select
                                    placeholder="metal name"
                                    className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="metal"
                                    value={currentSubItem.metal}
                                    onChange={(e) => {
                                        onSubItemValueUpdate(e.target)
                                    }}
                                >
                                    <option value={""}>--select metal--</option>
                                    {
                                        metalTypeDropdown.map((item, index) => (
                                            <option key={item.metal_name} value={item.id}>{item.metal_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Purity</p>
                                <select
                                    placeholder="Purity"
                                    className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="purity"
                                    value={currentSubItem.purity}
                                    onChange={(e) => {
                                        onSubItemValueUpdate(e.target)
                                    }}
                                >
                                    <option value={""}>--select Purity--</option>
                                    {
                                        purityTypeDropdown.map((item, index) => (
                                            <option key={item.purity_name} value={item.id}>{item.purity_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purity}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Id</p>
                                <select
                                    // placeholder="metal name"
                                    className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="item_id"
                                    value={JSON.stringify(currentSubItem.item_id)}
                                    onChange={(e) => {
                                        onSubItemValueUpdate(e.target)
                                    }}
                                >
                                    <option value={""}>--select Id--</option>
                                    {
                                        itemDropdown.map((item, index) => (
                                            <option key={item.id} value={JSON.stringify(item)}>{item.item_id}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.item_id}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Short Code</p>
                                <select
                                    // placeholder="metal name"
                                    className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="item_code"
                                    value={JSON.stringify(currentSubItem.item_code)}
                                    onChange={(e) => onSubItemValueUpdate(e.target)}
                                >
                                    <option value={""}>--select Short Code--</option>
                                    {
                                        itemDropdown.map((item, index) => (
                                            <option key={item.id} value={JSON.stringify(item)}>{item.item_code}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.item_code}</p>
                            </div>
                            <div className="sm:my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Name</p>
                                <select
                                    placeholder="metal name"
                                    className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="item_name"
                                    value={JSON.stringify(currentSubItem.item_name)}
                                    onChange={(e) => onSubItemValueUpdate(e.target)}
                                >
                                    <option value={""}>--select metal--</option>
                                    {
                                        itemDropdown.map((item, index) => (
                                            <option key={item.id} value={JSON.stringify(item)}>{item.item_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.item_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">HSN Code</p>
                                <input
                                    type="text"
                                    placeholder="HSN Code"
                                    className="cursor-not-allowed sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="hsn_code"
                                    readOnly
                                    value={currentSubItem?.hsn_code}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.hsn_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Stock Type</p>
                                <input
                                    type="text"
                                    placeholder="Stock Type"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="stock_type"
                                    readOnly
                                    value={currentSubItem?.stock_type}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.stock_type}</p>
                            </div>

                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Sub Item Creation</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[15px]">Sub Item Id</p>
                                    <input
                                        type="text"
                                        placeholder="Sub Item Id"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="sub_item_id"
                                        readOnly
                                        value={currentSubItem.sub_item_id}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item_id}</p>
                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">Sub Item Code </p>
                                    <input
                                        type="text"
                                        placeholder="Sub Item Code"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="sub_item_code"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        value={currentSubItem.sub_item_code}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item_code}</p>
                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">Sub Item Name</p>
                                    <input
                                        type="text"
                                        placeholder="Sub Item Name"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="sub_item_name"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        value={currentSubItem.sub_item_name}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item_name}</p>
                                </div>

                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">HSN Code</p>
                                    <input
                                        type="text"
                                        placeholder="HSN Code"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="subitem_hsn_code"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        value={currentSubItem.subitem_hsn_code}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.subitem_hsn_code}</p>
                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">Sub Item Counter</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                        name="sub_item_counter"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        value={currentSubItem.sub_item_counter}
                                    >
                                        <option value={""}>--select Counter--</option>
                                        {
                                            counterDropdown?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.counter_name}({option.branch_name})</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.sub_item_counter}</p>
                                </div>

                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">Measurement Type</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                        name="measurement_type"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        value={currentSubItem.measurement_type}
                                    >
                                        <option value={""}>--select Measurement--</option>
                                        {
                                            measurementTypeDropdown.map((item, index) => (
                                                <option key={item.measurement_name} value={item.id}>{item.measurement_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.measurement_type}</p>
                                </div>
                                <div className="my-[5px] flex items-center gap-5">
                                    <input
                                        type="checkbox"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                                        name="allow_zero_weight"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        checked={currentSubItem.allow_zero_weight}
                                    />
                                    <p className="mb-[5px] text-[15px]">Allow Zero Weight</p>

                                </div>
                            </div>
                            <div>
                                <div className="sm:my-[5px] w-full">

                                    <p className="mb-[5px] text-[15px]">Item Image Upload</p>
                                    {
                                        formMode === 'update' ? <div className="flex items-center">
                                            <p className="text-[12px]">{imageFile.length <= 0 ? currentSubItem.sub_item_image : imageFile}</p>
                                            <label className="" htmlFor="subitemimage" >
                                                <div className="border w-[50px] h-[45px] rounded-lg p-[10px] hover">Edit</div>
                                            </label>
                                        </div> :
                                            <label className="" htmlFor="subitemimage" >
                                                <div>
                                                    <div className="border-[2px] border-dashed max-w-[130px] h-[150px] flex justify-center px-2 py-5">
                                                        {imageFile.length > 0 ? imageFile :
                                                            <div>
                                                                <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#B6B6B6" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm0-2h14V5H5zm1-2h12l-3.75-5l-3 4L9 13zm-1 2V5z" /></svg>
                                                                <p className="text-[#3F80CC] text-[12px] font-[400] px-[15px] py-1 bg-[#3f80cc61] rounded-md mt-6">Add Image</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </label>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-menu-head-bg  border rounded-xl mt-5 p-[15px]">

                        <p className="font-[600] text-[18px] text-menu-head mb-4 capitalize">Charges Calculation</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">Calculation Type</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                        name="calculation_type"
                                        onChange={(e) => onSubItemValueUpdate(e.target)}
                                        value={currentSubItem.calculation_type}
                                    >
                                        <option value={""}>--select Calculation Type--</option>
                                        {
                                            calculationTypeDropdown.map((item, index) => (
                                                <option key={item.calculation_name} value={item.id}>{item.calculation_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.calculation_type}</p>
                                </div>
                            </div>
                            <div></div>
                        </div>

                        {/* fixed rate st*/}
                        <div className={`${String(currentSubItem?.calculation_type) === String(fixedCalcId) ? "block" : "hidden"}`}>
                            {/* <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Calculation Value</p> */}
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Fixed Rate Value<span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Fixed Rate Value"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="fixed_rate"
                                            defaultValue={itemDetails.fixed_rate}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.fixed_rate}</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>

                        {/* fixed rate end*/}

                        {/* pergram rate st*/}
                        <div className={`${String(currentSubItem?.calculation_type) === String(pergramCalId) ? "block" : "hidden"}`}>
                            {/* <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Per Gram Rate</p> */}
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Per Gram Rate<span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Per Gram Rate"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="per_gram_rate"
                                            defaultValue={itemDetails.per_gram_rate}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.per_gram_rate}</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>

                        {/* pergram rate end*/}

                        {/* weight calculation st*/}
                        <div className={`${String(currentSubItem?.calculation_type) === String(weightCalId) ? "block" : "hidden"}   `}>
                            {/* <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Charges Calculation</p> */}
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Wastage Calculation</p>
                                        <select
                                            placeholder="metal name"
                                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                            name="wastage_calculation"
                                            value={itemDetails?.wastage_calculation}
                                            onChange={(e) => onValueUpdate(e.target)}
                                        >
                                            <option value={""}>--select wastage type--</option>
                                            {
                                                weightTypeDropdown.map((item, index) => (
                                                    <option key={item.id} value={item.id}>{item.weight_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.wastage_calculation}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Wastage % <span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Wastage % "
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="wastage_percent"
                                            value={itemDetails?.wastage_percent}
                                            onChange={(e) => onValueUpdate(e.target)}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.wastage_percent}</p>
                                    </div>

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Flat Wastage (g) <span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Flat Wastage (g)"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="flat_wastage"
                                            value={itemDetails?.flat_wastage}
                                            onChange={(e) => onValueUpdate(e.target)}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.flat_wastage}</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>

                            <p className="font-[600] text-[17px] mt-3 mb-1 pb-2">Makeing Charges Calculations</p>
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Making Charges Calculations</p>
                                        <select
                                            placeholder="metal name"
                                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                            name="making_charge_calculation"
                                            value={itemDetails.making_charge_calculation}
                                            onChange={(e) => onValueUpdate(e.target)}
                                        >
                                            <option value={""}>--select charges calc--</option>
                                            {
                                                weightTypeDropdown.map((item, index) => (
                                                    <option key={item.id} value={item.id}>{item.weight_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.making_charge_calculation}</p>
                                    </div>

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">MC / gram(₹)<span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="MC / gram(₹)"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="making_charge_gram"
                                            value={itemDetails.making_charge_gram}
                                            onChange={(e) => onValueUpdate(e.target)}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.making_charge_gram}</p>
                                    </div>

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Flat MC (₹) <span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Flat MC (₹)"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="flat_making_charge"
                                            value={itemDetails.flat_making_charge}
                                            onChange={(e) => onValueUpdate(e.target)}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.flat_making_charge}</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        {/* weight calculation end*/}
                    </div>
                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form >
            </div >
        </>
    );
}