import { useEffect, useState } from "react";
import { isValidForm } from "../../../utilities/formvalidation";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { branchListEndPoint, counterListEndPoint, counterTargetEndPoint } from "../../../service/api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function CounterTargetForm({ getData, formMode, setFormMode, currentCounterTarget, setCurrentCounterTarget }) {
    const navigate = useNavigate();

    const [branchList, setBranchList] = useState([]);
    const [counterList, setCounterList] = useState([]);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getBranchDropdownData()
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint,
        });

        if (response !== null) {
            setBranchList(response.data.list);
        }
    };

    const getCounterDropDownData = async (id) => {
        var response = await getAxios({
            url: counterListEndPoint + id
        })

        if (response !== null) {
            setCounterList(response.data.list)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isValidForm(formDataObject)) {
            if (formMode === 'create') {
                createCounterTarget(formDataObject)
            } else {

            }
        }
    }

    const createCounterTarget = async (body) => {
        var response = await postAxiosWithToken({
            url: counterTargetEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            getData()
        }
    }

    const onValueUpdate = (e) => {
        const { name, value, checked } = e.target;

        if (name == 'branch') {
            getCounterDropDownData(value)
        }

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
        setCurrentCounterTarget((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }


    const clearData = () => {
        setCurrentCounterTarget({
            branch: "",
            counter_details: "",
            target_from_date: "",
            target_to_date: "",
            target_pieces: "",
            target_weight: "",
            target_amount: "",
        })
    }
    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Counter Target</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        <div className="grid xl:grid-cols-4 sm:grid-cols-2 gap-5">
                            {localStorage.getItem("branch") === "true" && (
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[15px]">Branch</p>
                                    <div className=''>
                                        <select
                                            placeholder="branch"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                            name="branch"
                                            value={currentCounterTarget.branch}
                                            onChange={onValueUpdate}
                                        >
                                            <option value="all">-- Select Branch Type --</option>
                                            {branchList.map((option) => (
                                                <option key={option?.id} value={option.id}>
                                                    {option.branch_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.branch}</p>
                                </div>
                            )}
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Counter</p>
                                <div className=''>
                                    <select
                                        placeholder="counter"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                        name="counter_details"
                                        value={currentCounterTarget.counter_details}
                                        onChange={onValueUpdate}
                                    >
                                        <option value="all">-- Select Counter --</option>
                                        {counterList.map((option) => (
                                            <option key={option?.id} value={option.id}>
                                                {option.counter_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.counter_details}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Target From Date</p>
                                <input
                                    type="date"
                                    placeholder="Target From Date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                    name="target_from_date"
                                    value={currentCounterTarget.target_from_date}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.target_from_date}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Target To Date</p>
                                <input
                                    type="date"
                                    placeholder="Target To Date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                    name="target_to_date"
                                    value={currentCounterTarget.target_to_date}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.target_to_date}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Target Pieces</p>
                                <input
                                    type="number"
                                    placeholder="Target Pieces"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                    name="target_pieces"
                                    value={currentCounterTarget.target_pieces}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.target_pieces}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Target Weight</p>
                                <input
                                    type="number"
                                    placeholder="Target Weight"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                    name="target_weight"
                                    value={currentCounterTarget.target_weight}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.target_weight}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Target Amount</p>
                                <input
                                    type="number"
                                    placeholder="Target Amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                    name="target_amount"
                                    value={currentCounterTarget.target_amount}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.target_amount}</p>
                            </div>
                            <div className="min-[423px]:flex gap-4 sm:mt-0 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className=" capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}