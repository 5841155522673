import { useState } from "react";
import Mulitipletag from "./mulitipletag";
import Singletag from "./singletag";

export default function Tagupdate() {

  const [tab, setTab] = useState(1);

  return (
    <>
      <div className="rounded-full flex justify-evenly bg-slate-300 flex-wrap text-sm font-medium text-center text-gray-500 p-[10px]">
        <button
          onClick={() => setTab(1)}
          className={` px-5 py-3 rounded-full hover:violet-700   ${tab === 1 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Single Tag Update 
        </button>
        {/* <button
          onClick={() => setTab(2)}
          className={` px-5 py-3 rounded-full hover:violet-700   ${tab === 2 ? 'bg-primary-btn text-white' : ''
            }`}
        >
          Mulitiple Tag Update
        </button> */}
            
      </div>
      <div style={{ display: tab === 1 ? "block" : "none", }}>
        <Singletag tab={tab} /></div>

            
      {/* <div style={{ display: tab === 2 ? "block" : "none", }}>
        <Mulitipletag tab={tab} /></div> */}

      
    </>
  );
}