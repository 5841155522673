import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stoneEndPoint } from "../../../../service/api";
import { postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../../hooks/useKeyPress";

export default function StoneForm({ tab, getData, formMode, currentStone, setFormMode, setCurrentStone }) {

    const schema = yup
        .object({
            stone_name: yup.string().required(),
            stone_code: yup.string().required(),
            reduce_weight: yup.string(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            stone_name: currentStone.stone_name,
            stone_code: currentStone.stone_code,
            reduce_weight: currentStone.reduce_weight
        } : {
            stone_name: "",
            stone_code: "",
            reduce_weight: true
        },
        mode: "onChange"
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's' && tab === 6) {
            event.preventDefault();
            document.getElementById('stoneformsubmit').click()
            document.getElementById('stoneforminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createStone(data)
            } else {
                updateStone(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createStone = async (body) => {
        var response = await postAxiosWithToken({
            url: stoneEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateStone = async (body) => {
        var response = await putAxiosWithToken({
            url: stoneEndPoint + currentStone?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentStone({
            stone_name: "",
            stone_code: "",
            reduce_weight: true
        })
    }

    return (
        <>
           <div className="p-5 rounded-[10px] bg-menu-head-bg border shadow mt-5">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Stone</p>
                <form className="grid 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-[20px]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Stone Name</p>
                        <input
                            type="text"
                            placeholder="stone name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="stone_name"
                            id="stoneforminit"
                            {...register('stone_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.stone_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Stone Code</p>
                        <input
                            type="text"
                            placeholder="stone code"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="stone_code"
                            {...register('stone_code')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.stone_code?.message}</p>
                    </div>
                    <div className="my-[5px] flex items-center gap-5 mt-8">
                        <p className="mb-[5px] text-[16px]">Reduce Weight</p>
                        <input
                            type="checkbox"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                            name="reduce_weight"    
                            {...register('reduce_weight')}
                        />
                       
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-0 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="stoneformsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}