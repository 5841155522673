import React, { useEffect, useRef, useState } from "react";
import {
  billingGetEndPoint,
  branchListEndPoint,
  companyListEndPoint,
  estimationListEndPoint,
  itemListbyMetalEndPoint,
  metalListEndPoint,
  purchaseAllGetEndPoint,
  purchaseEndPoint,
  purchaseorderIdEndPoint,
  purchasereturnItemDetailsEndPoint,
  purityListEndPoint,
  subitemListEndPoint,
} from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import PurchasePrint from "../../../components/Printouts/PurchasePrint";
import ReactToPrint from "react-to-print";
import Select from 'react-select';

function Purchaseentryform() {
  const [selectedOption, setSelectedOption] = useState("all");

  const [formMode, setFormMode] = useState('create');

  const [branchList, setBranchList] = useState([]);
  const [poidList, setPoidList] = useState({});
  const [eastimationList, setEastimationList] = useState([]);
  const [eastimationCustomer, setEastimationCustomer] = useState("");
  const [metaldrop, setMetalDrop] = useState("");
  const [puritydrop, setPurityDrop] = useState("");
  const [itemDrop, setItemDrop] = useState("");
  const [itemDropdown, setItemDropdown] = useState([]);
  const [errors, setErrors] = useState({});
  const [metalTypeDropdown, setMetalTypeDropdown] = useState([]);
  const [purityTypeDropdown, setPurityTypeDropdown] = useState([]);
  const [purityTypeDropdownall, setPurityTypeDropdownall] = useState([]);
  const [subItemList, setSubItemList] = useState([]);
  const [currentList, setcurrentList] = useState({})

  const [newDatas, setNewDatas] = useState("");
  const [options, setOptions] = useState([]);

  const [customerid, Setcustomerid] = useState({});

  const [isBranchSet, setIsBranchSet] = useState(localStorage.getItem("branch") !== null);


  console.log("purityTypeDropdown", purityTypeDropdown)


  const navigate = useNavigate();
  let componentRef = useRef();




  const [itemDetails, setItemDetails] = useState({
    purchase_metal: "",
    purchase_purity: "",
    purchase_item: "",
    purchase_subitem: "",
    pieces: "",
    gross_weight: "",
    net_weight: "",
    stone_pieces: 0.0,
    stone_weight: 0.0,
    diamond_pieces: 0.0,
    diamond_weight: 0.0,
    less_weight: 0.0,
    total_amount: "",
    total_stone_amount: 0,
  });


  const [particularsData, setParticularsData] = useState([]);
  const [PurchaseData, setTotalPurchaseData] = useState([]);

  console.log("particularsData", particularsData)
  console.log("PurchaseData", PurchaseData)



  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      document.getElementById('formsubmit').click()
      document.getElementById('forminit').blur()
    } else if (event.key === 'Escape') {
      event.preventDefault();
      // reset()
      setFormMode('create')
      navigate(-1)
    }
  };


  useKeyPress(['s', 'Escape'], onKeyPress);

  useEffect(() => {
    getBranchDropdownData();
    getMetalTypeDropDown();
    getPurityListDropDownall();
    if (localStorage.getItem("branch") === "false") {
      getEastimationDropdownData();
    }




    setFormMode(localStorage.getItem('user_purchaseentry_mode'))

    if (localStorage.getItem('user_purchaseentry_mode') === 'update') {
      getPurcheaseDetails()
    } else {
      getPurchasePoiddData();
    }

    //   setItemDetails((prevState) => ({
    //     ...prevState,
    //     net_weight: parseFloat(gross_weight)
    // }))
  }, []);

  useEffect(() => {
    if (metaldrop.length !== 0 && puritydrop.length !== 0) {
      getItemDropDown();
    }
  }, [metaldrop, puritydrop]);

  // useEffect(()=>{
  //   getBillsDropdownData();
  // },[particularsData])





  const getPurcheaseDetails = async () => {
    var response = await getAxios({
      url: purchaseEndPoint + localStorage.getItem('purchase_form_id') + "/"
    })

    if (response !== null) {
      var tempDetails = {
        igst: response.data?.purchase_list.igst,
        sgst: response.data?.purchase_list.sgst,
        sub_total: response.data?.purchase_list.sub_total,
        branch: response.data?.purchase_list.branch,
        estimation_no: response.data?.purchase_list.estimation_no,
        purchase_date: response.data?.purchase_list.purchase_date,
        total_pieces: response.data?.purchase_list.total_pieces,
        total_grossweight: response.data?.purchase_list.total_grossweight,
        total_netweight: response.data?.purchase_list.total_netweight,
        gst: response.data?.purchase_list.gst,
        total_amount: response.data?.purchase_list.total_amount,
        bill_no: response.data?.purchase_list.bill_no,
      }


      setcurrentList(tempDetails);
      setParticularsData(response.data?.iteam_details)
      setItemDetails(response.data?.iteam_details)
      setSelectedOption(response.data?.purchase_list.purchase_type)
    }
  }

  const handleItemDetailsChange = (event) => {
    const { name, value } = event.target;
    setItemDetails({
      ...itemDetails,
      [name]: value,
    });
  };

  const handleAddItem = (e) => {
    e.preventDefault();

    if (!validateItemDetails()) {
      return; // If validation fails, stop adding the item
    }

    var data = { ...itemDetails };

    for (var i of metalTypeDropdown) {
      if (i.id == itemDetails.purchase_metal) {
        data['purchase_metal__metal_name'] = i.metal_name;
        break; // Stop loop once found
      }
    }
    for (var i of purityTypeDropdown) {
      if (i.id == itemDetails.purchase_purity) {
        data['purchase_purity__purity_name'] = i.purity_name;
        break; // Stop loop once found
      }
    }
    for (var i of itemDropdown) {
      if (i.id == itemDetails.purchase_item) {
        data['purchase_item__item_name'] = i.item_name;
        break; // Stop loop once found
      }
    }
    for (var i of subItemList) { // Assuming subItemList is the correct array
      if (i.id == itemDetails.purchase_subitem) {
        data['purchase_subitem__sub_item_name'] = i.sub_item_name;
        break; // Stop loop once found
      }
    }

    // Add the validated item to particularsData
    setParticularsData([...particularsData, data]);

    // Reset itemDetails state for a new item entry
    setItemDetails({
      purchase_metal: "",
      purchase_purity: "",
      purchase_item: "",
      purchase_subitem: "",
      pieces: "",
      gross_weight: "",
      total_amount: "",
      net_weight: "",
      stone_pieces: 0.0,
      stone_weight: 0.0,
      diamond_pieces: 0.0,
      diamond_weight: 0.0,
      less_weight: 0.0,
      total_stone_amount: 0,
    });


    addallitems([...particularsData, data]);

  };


  const addallitems = async (particularsData) => {

    const body = { detail: particularsData };
    console.log("body", body);
    var response = await postAxiosWithToken({
      url: purchasereturnItemDetailsEndPoint,
      body: body,
    });

    if (response != null) {
      setTotalPurchaseData(response.data)
      toast.success(response.message);
    }
  };


  const validateItemDetails = () => {
    // Example: Check if purchase_metal is empty
    if (!itemDetails.purchase_metal) {
      console.error("Purchase Metal is required.");
      return false; // Return false to indicate validation failure
    }
    return true;
  };



  const getPurchasePoiddData = async () => {
    var response = await getAxios({
      url: purchaseorderIdEndPoint,
    });

    if (response !== null) {
      setPoidList(response);
    }
  };
  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchList(response.data.list);
    }
  };


  const getEastimationDropdownData = async (id) => {
    if (isBranchSet) {
      // Check if id is defined and not null before appending it to the URL
      const url = id ? billingGetEndPoint + id + "/" : billingGetEndPoint;

      var response = await getAxios({
        url: url,
      });

      if (response !== null) {
        setEastimationList(response.data.list);

        let tempList = [];
        for (var i of response.data.list) {
          var tempDict = {
            value: i?.bill_no,
            label: i?.bill_no
          };
          tempList.push(tempDict);
        }
        setOptions(tempList);
      }
    } else {
      setEastimationList([]);
    }
  };


  const getBillsDropdownData = async (id) => {
    setNewDatas("createbill")
    if (id != "") {
      var response = await getAxios({
        url: purchaseAllGetEndPoint + id + "/",
      });

      if (response !== null) {



        setTotalPurchaseData(response.data)
        Setcustomerid(response.data.customer_id)

        var tempLists = [];

        if (response.data && response.data.old_gold_details) {
          response.data.old_gold_details.forEach((item, index) => {

            var purchase_item = null;
            var purchase_subitem = null;
            var stone_pieces = "0";
            var stone_weight = "0";
            var diamond_pieces = "0";
            var diamond_weight = "0";
            var less_weight = "0";
            var total_amount = "0";
            var purchase_purity = "";
            var pieces = "";
            console.log("purchase_purity_id", item.purchase_purity_id)
            var newData = {
              purchase_metal: item.metal,
              purchase_purity: item.purchase_purity_id,
              pieces: pieces,
              gross_weight: response.data.gross_weight,
              net_weight: response.data.netweight,
              purchase_item: purchase_item,
              purchase_subitem: purchase_subitem,
              stone_pieces: stone_pieces,
              stone_weight: stone_weight,
              diamond_pieces: diamond_pieces,
              diamond_weight: diamond_weight,
              less_weight: less_weight,
              total_amount: item.total_amount,

            };

            for (var i of metalTypeDropdown) {
              if (i.id == item.metal) {
                newData['purchase_metal__metal_name'] = i.metal_name;
                break; // Stop loop once found
              }
            }



            for (var i of purityTypeDropdownall) {

              if (i.id == item.purchase_purity_id) {
                newData['purchase_purity__purity_name'] = i.purity_name;
                console.log("Match found. newData['purchase_purity__purity_name']:", i.purity_name); // Log the matched purity name
                break; // Stop loop once found
              }
            }





            tempLists.push(newData); // Push newData to tempLists
          });


        } else {
          console.log("Error: Unable to retrieve old gold details from response data.");
        }



        setParticularsData(tempLists);



      }
    } else {
      setParticularsData([]);
      setTotalPurchaseData([])
    }
  };



  const onValueUpdate = (event) => {
    const { name, value } = event.target;
    setcurrentList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };




  const onSubItemValueUpdate = async (text) => {
    const { name, value, checked } = text;


    if (name == 'purchase_metal') {
      setItemDetails((prevState) => ({
        ...prevState,
        [name]: value
      }))
      if (value.length > 0) {

        getPurityListDropDown(value)
        setMetalDrop(value)
      }
      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? '' : `${name} field is required`
      }))
    } else if (name == 'purchase_purity') {
      setItemDetails((prevState) => ({
        ...prevState,
        [name]: value
      }))
      if (value.length > 0) {
        setPurityDrop(value)

      }
      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? '' : `${name} field is required`
      }))
    } else if (name == 'purchase_item') {
      setItemDetails((prevState) => ({
        ...prevState,
        [name]: value
      }))
      if (value.length > 0) {
        getSubItemDropDownData(value)
      }
      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? '' : `${name} field is required`
      }))
    } else {
      setItemDetails((prevState) => ({
        ...prevState,
        [name]: value
      }))

      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length > 0 ? '' : `${name} field is required`
      }))
    }

    // Set errors based on input field validation rules...
  };

  const getMetalTypeDropDown = async () => {
    var respose = await getAxios({
      url: metalListEndPoint,
    });

    if (respose !== null) {
      setMetalTypeDropdown(respose.data.list);


    }
  };


  const getPurityListDropDown = async (id) => {
    var respose = await getAxios({
      url: purityListEndPoint + id + "/",
    });

    if (respose !== null) {
      setPurityTypeDropdown(respose.data.list);

    }
  };

  const getPurityListDropDownall = async () => {
    var respose = await getAxios({
      url: purityListEndPoint
    });

    if (respose !== null) {
      setPurityTypeDropdownall(respose.data.list);

    }
  };

  const getItemDropDown = async (id) => {
    var respose = await postAxiosWithToken({
      url: itemListbyMetalEndPoint,
      body: {
        metal: metaldrop,
        purity: puritydrop,
      },
    });

    if (respose !== null) {
      setItemDropdown(respose.data.list);

    }
  };

  const getSubItemDropDownData = async (id) => {

    var response = await getAxios({
      url: subitemListEndPoint + id + "/",
    });

    if (response !== null) {
      setSubItemList(response.data.list);
    }
  };

  const handleCreatePurchase = (e) => {
    e.preventDefault();

    const formDataObject = {};
    const formData = new FormData(e.target);

    formData.forEach((value, key) => {
      if (formDataObject[key]) {
        if (!Array.isArray(formDataObject[key])) {
          formDataObject[key] = [formDataObject[key]];
        }
        formDataObject[key].push(value);
      } else {
        formDataObject[key] = value;
      }
    });

    formDataObject.item_details = particularsData.map((item) => ({
      purchase_metal: item.purchase_metal,
      purchase_purity: item.purchase_purity,
      purchase_item: item.purchase_item,
      purchase_subitem: item.purchase_subitem,
      pieces: item.pieces,
      gross_weight: item.gross_weight,
      net_weight: item.net_weight,
      stone_pieces: item.stone_pieces,
      stone_weight: item.stone_weight,
      diamond_pieces: item.diamond_pieces,
      diamond_weight: item.diamond_weight,
      less_weight: item.less_weight,
      total_amount: item.total_amount,
    }));

    const selectedEstimationNo = document.getElementsByName("estimation_no")[0]?.value;
    formDataObject.estimation_no = selectedEstimationNo;
    formDataObject.person_id = customerid;

    if (localStorage.getItem('user_purchaseentry_mode') === 'create') {
      createPurchase(formDataObject);
    } else {
      updatePurchase(formDataObject);
    }
  };


  const createPurchase = async (body) => {
    var response = await postAxiosWithToken({
      url: purchaseEndPoint,
      body: body,
    });

    if (response != null) {
      // clearData()
      toast.success(response.message);
      navigate('/purchase/purchase-entry')
    }
  };

  const updatePurchase = async (body) => {
    var response = await putAxiosWithToken({
      url: purchaseEndPoint + localStorage.getItem('purchase_form_id') + "/",
      body: body
    })

    if (response != null) {

      toast.success(response.message)
      navigate('/purchase/purchase-entry')
    }
  }

  const handleEditItem = (index) => {
    const editedItem = particularsData[index];
    setItemDetails(editedItem);
    const updatedParticularsData = [...particularsData];
    updatedParticularsData.splice(index, 1);
    setParticularsData(updatedParticularsData);
  };

  const handleDeleteItem = (index) => {
    const updatedParticularsData = [...particularsData];
    updatedParticularsData.splice(index, 1);
    setParticularsData(updatedParticularsData);
  };


  const [companyDetails, setcompanyDetails] = useState({})

  useEffect(() => {
    getCompany();
  }, [])

  const getCompany = async () => {
    var response = await getAxios({
      url: companyListEndPoint
    })

    if (response !== null) {
      setcompanyDetails(response.data.list)

    }
  }

  return (
    <>
      <div className="mb-[130px] border bg-menu-head-bg shadow-lg p-[15px] rounded-lg">
        <form id="purchaseEntryform" onSubmit={handleCreatePurchase}>
          <p className="text-[18px] font-[500] text-[menu-head]">
            Purchase Entry
          </p>
          <div className="grid xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-2 rounded-lg mb-[5px]">

            <>
              <div className="my-[2px]">
                <p className="mb-[2px] text-[13px]">PO ID </p>
                <input
                  type="text"
                  placeholder="PO ID "
                  className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                  name="po_id"
                  value={poidList.po_id}
                  readOnly
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {/* {errors.entry_date} */}
                </p>
              </div>

              {localStorage.getItem('branch') == 'true' &&

                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">
                    Branch <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="branch"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="branch"
                    value={currentList.branch}
                    onChange={(e) => {
                      // getEastimationDropdownData(e.target.value);
                      // onValueUpdate(e);
                      if (e.target.value !== "") {
                        getEastimationDropdownData(e.target.value)
                      } else {
                        setOptions([])
                      }

                    }}
                  >
                    <option value="">--select branch--</option>
                    {branchList.map((option) => (
                      <option
                        className="capitalize"
                        key={"branch" + option.branch_name}
                        value={option.id}
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {/* <p className="text-red-500 font-[400] text-[13px]">{errors.branch?.message}</p> */}
                </div>
              }

              <div className="my-[2px]">
                <p className="mb-[2px] text-[13px]">Bill No *</p>

                <Select
                  options={options}
                  name="bill_no"
                  isSearchable
                  onChange={(event) => {
                    getBillsDropdownData(event.value)
                  }}
                  className="w-full"
                />



                <p className="text-red-500 font-[400] text-[13px]">
                  {/* {errors.entry_date} */}
                </p>
              </div>

              <div className="my-[2px]">
                <p className="mb-[2px] text-[13px]">Date *</p>
                <input
                  type="date"
                  placeholder="Bill Date"
                  className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                  name="purchase_date"
                  value={PurchaseData.bill_date}
                  onChange={onValueUpdate}
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {/* {errors.entry_date} */}

                </p>
              </div>

              <div className="my-[2px]">
                <p className="mb-[2px] text-[13px]">
                  Customer
                </p>
                <input
                  type="text"
                  placeholder="Customer "
                  className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                  name="person_id"
                  onChange={onValueUpdate}
                  value={PurchaseData?.customer_name}
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {/* {errors.entry_date} */}
                </p>
              </div>
            </>
          </div>

          <>
          <div className="border bg-menu-head-bg shadow-lg mb-2 p-[15px] rounded-lg">
              <p className="text-[18px] font-[500] text-[menu-head]">
                Item Details
              </p>

              <div className="grid xl:grid-cols-9 lg:grid-cols-7 sm:grid-cols-5 grid-cols-2 gap-2 rounded-lg mb-[10px]">
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">Metal </p>
                  <select
                    placeholder="metal name"
                    className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none"
                    name="purchase_metal"
                    value={itemDetails.purchase_metal}
                    onChange={(e) => {
                      onSubItemValueUpdate(e.target);
                    }}
                  >
                    <option value={""}>--select metal--</option>
                    {metalTypeDropdown.map((item, index) => (
                      <option key={item.metal_name} value={item.id}>
                        {item.metal_name}
                      </option>
                    ))}
                  </select>

                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.entry_date} */}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Purity</p>
                  <select
                    placeholder="Purity"
                    className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none"
                    name="purchase_purity"
                    value={itemDetails.purchase_purity}
                    onChange={(e) => {
                      onSubItemValueUpdate(e.target);
                    }}
                  >
                    <option value={""}>--select Purity--</option>
                    {purityTypeDropdown.map((item, index) => (
                      <option key={item.purity_name} value={item.id}>
                        {item.purity_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.item_name} */}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Item Name </p>

                  <select
                    placeholder="metal name"
                    className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none"
                    name="purchase_item"
                    value={itemDetails.purchase_item}
                    onChange={(e) => onSubItemValueUpdate(e.target)}
                  >
                    <option value={""}>--select Item--</option>
                    {itemDropdown.map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.item_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.item_name} */}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Sub Item Name </p>
                  <select
                    placeholder="Sub Item name"
                    className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[4px] text-[13px] outline-none"
                    name="purchase_subitem"
                    value={itemDetails.purchase_subitem}
                    onChange={(e) => onSubItemValueUpdate(e.target)}
                  >
                    <option value={""}>--select sub item name--</option>
                    {subItemList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.sub_item_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.item_name} */}
                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Pcs </p>
                  <input
                    type="text"
                    placeholder="Pcs"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="pieces"
                    value={itemDetails.pieces}
                    onChange={handleItemDetailsChange}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.item_name} */}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Grs Wt</p>
                  <input
                    type="text"
                    placeholder="Grs Wt"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="gross_weight"
                    value={itemDetails.gross_weight}
                    onChange={handleItemDetailsChange}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.item_name} */}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Net Wt</p>
                  <input
                    type="text"
                    placeholder="Net Wt"
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="net_weight"
                    value={itemDetails.net_weight}
                    onChange={handleItemDetailsChange}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {/* {errors.item_name} */}
                  </p>
                </div>

                <div className="my-[2px]">
                  <p className="mb-[5px] text-[12px]">Amount</p>
                  <input
                    type="text"
                    placeholder=""
                    className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                    name="total_amount"
                    value={itemDetails.total_amount}
                    onChange={handleItemDetailsChange}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">

                  </p>
                </div>
                <div className="my-[5px] mt-6">
                  <button
                    // onClick={(e) => { e.preventDefault(); setStonePopup(!stonePopup) }}
                    onClick={handleAddItem}
                    className="capitalize bg-primary-btn text-white w-full rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-[15px]">
              <p className="text-[18px] font-[500]">Particulars</p>

              <div className="overflow-x-scroll mt-[15px]">
                <table className="w-full">
                  <thead className="border bg-table-head text-table-head-font text-[16px] font-[700]">
                    <tr>
                      <td className="p-[5px] min-w-[120px]">S.No</td>
                      <td className="p-[5px] min-w-[120px]">Metal</td>
                      <td className="p-[5px] min-w-[120px]">Purity</td>
                      <td className="p-[5px] min-w-[120px]">Item Name</td>
                      <td className="p-[5px] min-w-[120px]">
                        Sub Item Name
                      </td>
                      <td className="p-[5px] min-w-[120px]">Pcs</td>
                      <td className="p-[5px] min-w-[120px]">Grs Wt</td>
                      <td className="p-[5px] min-w-[120px]">Net Wt</td>
                      <td className="p-[5px] min-w-[120px]">Total Amount</td>
                      <td className="p-[5px] min-w-[120px]">Action</td>
                    </tr>
                  </thead>
                  <tbody className="text-primary-grey bg-menu-head-bg">
                    {particularsData?.map((item, index) => (
                      <tr key={item.id} className="border">
                        <td className="p-[5px]">{index + 1}</td>
                        <td className="p-[5px] capitalize">

                          {item.purchase_metal__metal_name}
                        </td>
                        <td className="p-[5px] capitalize">
                          {item.purchase_purity__purity_name}
                        </td>
                        <td className="p-[5px] capitalize">
                          {item.purchase_item__item_name}
                        </td>
                        <td className="p-[5px] capitalize">
                          {item.purchase_subitem__sub_item_name}
                        </td>
                        <td className="p-[5px] capitalize">{item.pieces}</td>
                        <td className="p-[5px] capitalize">
                          {item.gross_weight}
                        </td>
                        <td className="p-[5px] capitalize">
                          {item.net_weight}
                        </td>
                        <td className="p-[5px] capitalize">
                          {item.total_amount}
                        </td>
                        <td className="p-[5px] flex gap-3">
                          <div
                            onClick={() => handleEditItem(index)}
                            className="cursor-pointer hover:scale-125"
                          >
                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                          </div>
                          <div
                            onClick={() => handleDeleteItem(index)}
                            className="cursor-pointer hover:scale-125"
                          >
                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="border bg-menu-head-bg shadow-lg mb-2 p-[15px] rounded-lg">
              <p className="text-[18px] font-[500] text-[menu-head]">
              Tax Details
              </p>

              <div className="grid xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-2 rounded-lg">
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">SGST </p>
                  <div className="flex ">
                    <input
                      type="text"
                      placeholder="sgst"
                      disabled
                      className="sm:mb-[2px] border border-gray-300 rounded-l-lg w-full sm:w-[150px] p-[3px] text-[13px] outline-none"
                      name="sgst"
                      value={currentList.sgst}
                      onChange={onValueUpdate}
                    />
                    <p className="sm:mb-[2px] border border-gray-300 rounded-r-lg w-[30px] p-[3px] text-[13px] outline-none">
                      %
                    </p>
                  </div>
                  <p className="text-red-500 font-[400] text-[13px]">

                  </p>
                </div>
                <div className="my-[2px]">
                  <p className="mb-[2px] text-[13px]">IGST </p>
                  <div className="flex ">
                    <input
                      type="text"
                      placeholder="igst"
                      disabled
                      className="sm:mb-[2px] border border-gray-300 rounded-l-lg w-full sm:w-[150px] p-[3px] text-[13px] outline-none"
                      name="igst"
                      value={currentList.igst}
                      onChange={onValueUpdate}
                    />
                    <p className="sm:mb-[2px] border border-gray-300 rounded-r-lg w-[30px] p-[3px] text-[13px] outline-none">
                      %
                    </p>
                  </div>
                  <p className="text-red-500 font-[400] text-[13px]">

                  </p>
                </div>
              </div>
            </div>

            <div className="border bg-menu-head-bg shadow-lg mb-2 p-[15px] rounded-lg mt-5">
              <div className="relative">
                <p className="text-[18px] font-[500]">Total Purchase</p>

                <div className="grid xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-5 grid-cols-2 gap-2 rounded-lg mb-[10px] mt-2">
                  <div className="my-[2px]">
                    <p className="mb-[2px] text-[13px]">Total Item *</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="total_pieces"
                      value={PurchaseData.totalitem}
                      onChange={onValueUpdate}
                    />
                  </div>
                  <div className="my-[2px]">
                    <p className="mb-[2px] text-[13px]">Total Gross Wt *</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="total_grossweight"
                      value={PurchaseData.gross_weight}
                      onChange={onValueUpdate}
                    />
                  </div>
                  <div className="my-[2px]">
                    <p className="mb-[2px] text-[13px]">Total Net Wt *</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="total_netweight"
                      value={PurchaseData.netweight}
                      onChange={onValueUpdate}
                    />
                  </div>
                  <div className="my-[2px]">
                    <p className="mb-[2px] text-[13px]">Sub Total *</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="sub_total"
                      value={PurchaseData.totalamount}
                      onChange={onValueUpdate}
                    />
                  </div>
                  <div className="my-[2px]">
                    <p className="mb-[2px] text-[13px]">GST (₹) *</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="gst"
                      value={PurchaseData.gst_amount}
                      onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">

                    </p>
                  </div>

                  <div className="my-[2px]">
                    <p className="mb-[2px] text-[13px]">Total Amount *</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                      name="total_amount"
                      value={PurchaseData.totalamount}
                      onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">

                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="fixed w-full z-[5] flex max-[480px]:flex-col gap-4 bg-menu-head-bg justify-center md:justify-end bottom-[35px] border p-[15px] right-0">

              <div className="hidden">
                <div className="mb-[25px]" ref={(el) => (componentRef = el)}>
                  <PurchasePrint itemDetails={particularsData} PurchaseData={PurchaseData} companyDetails={companyDetails} />

                </div>
              </div>


              <ReactToPrint
                trigger={() =>
                  // <button id="print" type="submit" className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[5px] h-fit font-[500] text-[15px] outline-none border-none">
                  //   Print (ctrl+p)
                  // </button>

                  <button id="formsubmit" type="submit"
                    className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[5px] h-fit font-[500] text-[15px] outline-none border-none"
                  >Save & Print <span className="lowercase">(ctrl+s)</span></button>

                }
                content={() => componentRef}
              />




              {/* 
                <button
                  type="submit"
                  id="formsubmit"
                  class="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[5px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto"
                >
                  create Purchase <span class="lowercase">(ctrl+s)</span>
                </button> */}

            </div>
          </>

          {selectedOption == "all" && (
            <>
              {/* <div className="mt-[25px]">
                <p className="text-[18px] font-[500]">Particulars</p>

                <div className="overflow-x-scroll mt-[15px]">
                  <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                      <tr>
                        <td className="border p-2 text-center" rowSpan={2}>
                          S.No
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Item Name
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Sub Item
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Pcs
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Grs Wt.
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Net Wt
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          St Wt
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          St Amt
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Dia Wt
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Dia Wt
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Pure Wt
                        </td>
                        <td className="border p-2 text-center" rowSpan={2}>
                          Total Amt
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-2 text-center">Metal</td>
                        <td className="border p-2 text-center">Purity</td>
                      </tr>
                    </thead>
                  </table>
                  <tbody></tbody>
                </div>


                <div class="fixed w-full z-[5] flex max-[480px]:flex-col gap-4 bg-menu-head-bg justify-center md:justify-end bottom-[35px] border p-[15px] right-0">
                <button
                        onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                        className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[5px] h-fit font-[500] text-[15px] outline-none border"
                    >close<span className="lowercase">(esc)</span></button>
                </div>


                
                    
              </div> */}
            </>
          )}
        </form>

        {/* {selectedOption === "New Purchase" && <Newpurchase />} */}
      </div>
    </>
  );
}

export default Purchaseentryform;
