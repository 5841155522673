import { useEffect, useState } from "react";
import VendorDetailsForm from "./vendordetailsform";
import MetalRateCutForm from "./metalratecutform";
import CashRateCutForm from "./cashratecutform";
import AmountSettlementForm from "./amountsettlementform";
import MetalRateCutList from "../widgets/metalratecutlist";
import CashRateCutList from "../widgets/cashratecutlist";
import PendingPaymentList from "../widgets/pendingpaymentlist";
import SelectedPaymentDetails from "../widgets/selectedpaymentdetails";
import TotalPurchaseDetails from "../widgets/totalpurchasedetails";
import { useLocation, useParams } from "react-router-dom";
import { getAxios, postAxiosWithToken } from "../../../../service/apiservice";
import { cashRateCutEndPoint, generateVendorPaymentId, metalRateCutEndPoint, vendorDetailsByIdEndPoint } from "../../../../service/api";
import { goldMetalType } from "../../../../constant";
import toast from "react-hot-toast";

export default function VendorPaymentsForm() {

    const [vendorDetailsFormValues, setVendorDetailsFormValues] = useState({
        date: new Date().toLocaleDateString('en-CA'),
        metal: goldMetalType,
        designer_name: localStorage.getItem('vendor_due_id')
    })
    const [metalCutRateFormValues, setMetalCutRateFormValues] = useState({})
    const [cashCutRateFormValues, setCashCutRateFormValues] = useState({})
    const [amountSettlementFormValues, setAmountSettlementFormValues] = useState({})
    
    const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({

    });
    const [selectedPendingPaymentList, setSelectedPendingPaymentList] = useState([]);

    const [metalRateCutList, setMetalRateCutList] = useState([]);
    const [cashRateCutList, setCashRateCutList] = useState([]);
    const [pendingPaymentList, setPendingPaymentList] = useState([]);

    const [totalUnPaidWeight, setTotalUnPaidWeight] = useState(0);
    const [totalUnPaidAmount, setTotalUnPaidAmount] = useState(0);

    const [totalMetalPureWeight, setTotalMetalPureWeight] = useState(0);
    const [totalCashRate, setTotalCashRate] = useState(0)

    const [errors, setErrors] = useState({})

    const [calculateSelected, setCalculateSelected] = useState(false);

    useEffect(() => {
        getVendorPaymentId()
        getVendorDetails()
    },[])

    useEffect(() => {
        let totalItems = 0;
        let totalNetWgt = 0.0;
        let totalPureWgt = 0.0;
        let totalHallmark = 0.0;
        let totalOthers = 0.0;
        let totalAmount = 0.0;
        let pendingAmt = 0.0;
        let pendingWeight = 0.0;

        for (var i of selectedPendingPaymentList) {
            totalItems = totalItems + parseInt(i.total_pieces)
            totalNetWgt = totalNetWgt + parseFloat(i.total_net_weight)
            totalPureWgt = totalPureWgt + parseFloat(i.total_pure_weight)
            totalHallmark = totalHallmark + parseFloat(i.hallmark)
            totalOthers = totalOthers + parseFloat(i.others)
            totalAmount = totalAmount + parseFloat(i.total_amount)
            pendingWeight = pendingWeight + parseFloat(i.pending_weight)
            pendingAmt = pendingAmt + parseFloat(i.pending_amount)
        }

        setSelectedPaymentDetails((prevState) => ({
            ...prevState,
            total_items: totalItems,
            total_net_weight: totalNetWgt,
            total_purity_weight: totalPureWgt,
            hallmark: totalHallmark,
            others: totalOthers,
            total_amount: totalAmount,
            total_cash: totalAmount,
            total_weight: totalPureWgt,
            pending_amount: pendingAmt,
            pending_weight: pendingWeight
        }))
    },[selectedPendingPaymentList])

    useEffect(() => {
        let newList = [];
        for (var i of pendingPaymentList) {
            for (var j of selectedPendingPaymentList) {
                if (j.id == i.id) {
                    newList.push(i)
                }
            }
        }
        setSelectedPendingPaymentList(newList)
    }, [pendingPaymentList])

    useEffect(() => {
        let totalPendingWt = 0.0;
        let totalPendingAmt = 0.0;

        for (var i of pendingPaymentList) {
            totalPendingWt = totalPendingWt + parseFloat(i.pending_weight)
            totalPendingAmt = totalPendingAmt + parseFloat(i.pending_amount)
        }

        setTotalUnPaidAmount(totalPendingAmt)
        setTotalUnPaidWeight(totalPendingWt)
    },[pendingPaymentList])

    useEffect(() => {
        let total = 0;
        for (var i of metalRateCutList) {
            total = total + parseFloat(i.pure_weight)
        }
        setTotalMetalPureWeight(total)
    },[metalRateCutList])

    useEffect(() => {
        let total = 0;
        for (var i of cashRateCutList) {
            total = total + parseFloat(i.rate)
        }
        setTotalCashRate(total)
    },[cashRateCutList])

    const getVendorPaymentId = async () => {
        var response = await getAxios({
            url: generateVendorPaymentId
        })

        if (response != null) {
            setVendorDetailsFormValues((prevState) => ({
                ...prevState,
                payment_bill_no: response.vendor_id
            }))
        }
    }

    const getVendorDetails = async () => {
        var response = await getAxios({
            url: vendorDetailsByIdEndPoint + localStorage.getItem('vendor_due_id') + "/"
        })

        if (response != null) {
            setPendingPaymentList(response.data.list)
            setAmountSettlementFormValues((prevState) => ({
                ...prevState,
                credit_balance_gm: response.data.credit_balance_gm,
                credit_balance_rupee: response.data.credit_balance_rupee
            }))
        }
    }

    const settleMetalRateCut = async (e) => {
        e.preventDefault();
        var response = await postAxiosWithToken({
            url: metalRateCutEndPoint,
            body: {
                details: metalRateCutList
            }
        })

        if (response != null) {
            toast.success(response.message);
            getVendorDetails();
            setMetalRateCutList([]);
            
        }
    }

    const settleCashRateCut = async (e) => {
        e.preventDefault();
        var response = await postAxiosWithToken({
            url: cashRateCutEndPoint,
            body: {
                details: cashRateCutList
            }
        })

        if (response != null) {
            toast.success(response.message);
            getVendorDetails();
            setCashRateCutList([]);
        }
    }

    return (
        <div className="mb-[50px]">
            <p className="text-[18px] font-[600]">Vendor Payment Due</p>

            <div className="p-3 border-2 rounded-xl bg-white">
                <p className="text-[14px] font-[500]">Vendor Details</p>
                <VendorDetailsForm formValues={vendorDetailsFormValues} setFormValues={setVendorDetailsFormValues} errors={errors} setErrors={setErrors} getVendorDetails={getVendorDetails} />

                <div className="grid sm:grid-cols-3 border-y my-5">
                    <div className="p-3">
                        <p className="text-[14px] font-[500]">Payment Due - Metal</p>
                        <MetalRateCutForm selectedItems={selectedPendingPaymentList} vendorDetails={vendorDetailsFormValues} formValues={metalCutRateFormValues} setFormValues={setMetalCutRateFormValues} vendorErrors={errors} setVendorErrors={setErrors} setMetalRateCutList={setMetalRateCutList} metalRateCutList={metalRateCutList} getData={getVendorDetails} />
                    </div>
                    <div className="border-x p-3">
                        <p className="text-[14px] font-[500]">Payment Due - Cash</p>
                        <CashRateCutForm selectedItems={selectedPendingPaymentList} vendorDetails={vendorDetailsFormValues} formValues={cashCutRateFormValues} setFormValues={setCashCutRateFormValues} vendorErrors={errors} setVendorErrors={setErrors} cashRateCutList={cashRateCutList} setCashRateCutList={setCashRateCutList} />
                    </div>
                    <div className="p-3">
                        <AmountSettlementForm calculate={calculateSelected} setCalculate={setCalculateSelected} selectedItems={selectedPendingPaymentList} vendorDetails={vendorDetailsFormValues} formValues={amountSettlementFormValues} setFormValues={setAmountSettlementFormValues} vendorErrors={errors} setVendorErrors={setErrors} getData={getVendorDetails} />
                    </div>
                </div>

                <div className="lg:flex gap-5 border-b pb-5">
                    <div className="w-full">
                        <div className=" sm:flex justify-between items-center">
                            <p className="ext-[14px] sm:text-[18px] font-[500]">Payment In Metal</p>
                            <div className="my-[5px] flex gap-3 items-center">
                                <p className="text-[12px] sm:text-[15px]">Total Pure Wt</p>
                                <input
                                    type="number"
                                    placeholder="0.00 g"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[4px] text-[17px] outline-none"
                                    value={totalMetalPureWeight?.toFixed(3)}
                                    readOnly
                                />
                                <button
                                    onClick={settleMetalRateCut}
                                    disabled={metalRateCutList.length > 0 ? false : true}
                                    className="capitalize border border-primary-btn disabled:border-gray-400 bg-primary-btn disabled:bg-gray-400 text-white w-fit rounded-lg p-[5px] sm:px-[12px] sm:py-[5px] h-fit font-[500] text-[10px] sm:text-[15px] outline-none float-right"
                                >Pay Metal</button>
                            </div>
                        </div>
                        <MetalRateCutList items={metalRateCutList} setItems={setMetalRateCutList} />
                    </div>
                    <div className="w-full">
                        <div className=" sm:flex justify-between items-center">
                            <p className="text-[14px] sm:text-[18px] font-[500]">Payment In-Cash</p>
                            <div className="my-[5px] flex gap-3 items-center">
                                <p className="text-[12px] sm:text-[15px]">Total Rate Cut</p>
                                <input
                                    type="number"
                                    placeholder="₹0.00"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[4px] text-[17px] outline-none"
                                    value={totalCashRate?.toFixed(2)}
                                    readOnly
                                />
                                <button
                                    onClick={settleCashRateCut}
                                    disabled={cashRateCutList.length > 0 ? false : true}
                                    className="capitalize border border-primary-btn disabled:border-gray-400 bg-primary-btn disabled:bg-gray-400 text-white w-fit rounded-lg p-[5px] sm:px-[12px] sm:py-[5px] h-fit font-[500] text-[10px] sm:text-[15px] outline-none float-right"
                                >Pay Cash</button>
                            </div>
                        </div>
                        <CashRateCutList items={cashRateCutList} setItems={setCashRateCutList} />
                    </div>
                </div>

                <div className="lg:flex w-full">
                    <div className="p-3 w-full border-r">
                        <div className=" sm:flex justify-between items-center">
                            <p className="text-[15px] sm:text-[18px] font-[500]">Vendor Pending Payment </p>
                            <div className="my-[5px] sm:flex gap-3 items-center">
                                <p className="text-[13px] sm:text-[15px]">Total Unpaid Pure Wt(g)</p>
                                <input
                                    type="number"
                                    placeholder="0.00 g"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[4px] text-[17px] outline-none"
                                    value={totalUnPaidWeight}
                                    readOnly
                                />
                                <p className="text-[13px] sm:text-[15px]">Total Unpaid Amt(₹)</p>
                                <input
                                    type="number"
                                    placeholder="₹ 0.00"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[4px] text-[17px] outline-none"
                                    value={totalUnPaidAmount}
                                    readOnly
                                />
                            </div>
                        </div>
                        <PendingPaymentList items={pendingPaymentList} selectedItems={selectedPendingPaymentList} setSelectedItems={setSelectedPendingPaymentList} />
                    </div>
                    <div className="p-3 w-full lg:w-[600px]">
                        <p className="text-[18px] font-[500]">Selected Details</p>
                        <SelectedPaymentDetails formValues={selectedPaymentDetails} />
                    </div>
                </div>

                <div>
                    <p className="text-[18px] font-[500]">Total Purchase</p>
                    <TotalPurchaseDetails formValues={selectedPaymentDetails} />
                </div>
            </div>
        </div>
    );
}