import { useEffect } from "react";

export default function AccountHeadContactForm({ dataList = [], setDataList, removedItems, setRemovedItems }) {

    useEffect(() => {
        if (localStorage.getItem('accounthead_form_mode') === 'update') {
            if (dataList?.length === 0) {
                addHeadContactDetails(); 
            }
        } else {
            if (dataList?.length === 0) {
                addHeadContactDetails();
            }
        }
    }, [dataList]);

    const addHeadContactDetails = () => {
        const data = {
            email_id: "",
            mobile_number: "",
            website: "",
            std_code: "",
            landline_number: ""
        };
        setDataList([...dataList, data]);
    };

    const onValueUpdate = (text, index) => {
        const { name, value } = text
        const data = [...dataList];
        data[index][name] = value
        setDataList(data)
    }

    const onRemoveDetails = (index) => {
        if (dataList?.length > 1) {
            const data = [...dataList];
            if (data[index].id !== undefined) {
                removedItems.push(data[index].id)
            }
            data.splice(index, 1);
            setDataList(data);
        }

    }

    return (
        <>
            <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Contact Details</p>

                <ListDetails rows={dataList} onValueUpdate={onValueUpdate} onRemoveDetails={onRemoveDetails} />

                <button onClick={(e) => { e.preventDefault(); addHeadContactDetails() }} className="flex gap-3 text-primary-btn justify-end w-full my-[10px] mt-[30px] font-[600] text-[17px]">
                    <span className="bg-primary-btn text-white rounded-lg w-[25px]">+</span>
                    <p>Add Contact details</p>
                </button>
            </div>
        </>
    );
}

function ListDetails({
    rows,
    onValueUpdate,
    onRemoveDetails
}) {
    
    return rows.map((item, index) => {
        const {
            email_id,
            mobile_number,
            website,
            std_code,
            landline_number
        } = item;
        return <div key={"address" + index} className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5  p-[30px] my-[10px]  rounded-xl relative">
            {
                rows.length > 1 ? <button onClick={(e) => { e.preventDefault(); onRemoveDetails(index) }} className="flex gap-3 text-red-600 justify-end w-full my-[10px] font-[600] text-[17px] absolute right-8">
                    <span className="bg-red-600 text-white rounded-lg w-[25px]">-</span>
                    <p>Remove Contact details</p>
                </button> : ""
            }


                        <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Email</p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email_id"
                                    value={email_id}
                                    onChange={(e) => onValueUpdate(e.target, index)}
                                    // {...register('email')}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.email?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Phone no <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobile_number"
                                    value={mobile_number}
                                    onChange={(e) => onValueUpdate(e.target, index)}
                                    // {...register('phone')}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.phone?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Website</p>
                                <input
                                    type="text"
                                    placeholder="website link"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="website"
                                    value={website}
                                      onChange={(e) => onValueUpdate(e.target, index)}
                                    // {...register('last_name')}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.last_name?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Landline <span className="text-red-500">*</span></p>
                                <div className="flex gap-1 w-full">
                                    <div className="w-[50%]">
                                        <input
                                            type="text"
                                            placeholder="std code"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="std_code"
                                            value={std_code}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                            // {...register('last_name')}
                                        />
                                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.last_name?.message}</p> */}
                                    </div>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            placeholder="landline no"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="landline_number"
                                            value={landline_number}
                                             onChange={(e) => onValueUpdate(e.target, index)}
                                            // {...register('last_name')}
                                        />
                                        {/* <p className="text-red-500 font-[400] text-[13px]">{errors.last_name?.message}</p> */}
                                    </div>
                                </div>
                            </div>
        </div>
    })
}
