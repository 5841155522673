import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { vendorwiseListEndPoint,} from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { useKeyPress } from "../../../hooks/useKeyPress";
import VendorwisereportFilter from "./vendorwisereportfilter";
import Vendorwisereportform from "./vendorwisereportform";

export default function VendorwiseReport() {

    const searchRef = useRef();
    const [vendorwiseList, setVendorwiseList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Vendor Wise Sales Report"

    function closeExcelModal() {
        setExcelOpen(false)
    }

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        account_head_name: null,
        item_name: null,
        metal_type: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    useEffect(() => {
        let tempData = [];
        for (var i of vendorwiseList) {
            tempData.push(i)
            tempData.push({
                is_total: true,
                item_list: ["hi"],
                total_gross_weight: i.total_gross_weight,
                total_net_weight: i.total_net_weight,
                total_item: i.total_item,
                total_amount: i.total_amount                                                                                                                                                                     
            })
        }
        setData(tempData)
    },[vendorwiseList])


    useEffect(() => {
        if (vendorwiseList.length > 0) {
            let firstData = Object.keys(vendorwiseList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [vendorwiseList])

    useEffect(() => {
        getvendorwisereportListData(tableFilters)
    }, [tableFilters])



    const getvendorwisereportListData = async (body) => {
        var response = await postAxiosWithToken({
            url: vendorwiseListEndPoint,
            body: body
        })

        if (response !== null) {
            setVendorwiseList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                LOT Report- Vendor Wise
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={vendorwiseList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={vendorwiseList} fileName={fileName} />
                </div>
            </div>
            <Vendorwisereportform searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <VendorwisereportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }

                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">
            <table className="w-full ">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                    <tr>
                        <td className="border p-[10px]">Vendor</td>
                        <td className="border p-[10px]">date</td>
                        <td className="border p-[10px]">LOt NO</td>
                        <td className="border p-[10px]">INVOICE</td>
                        <td className="border p-[10px]">Gold weight</td>
                        <td className="border p-[10px]">Silver weight</td>
                        <td className="border p-[10px]">Gold Pieces</td>
                        <td className="border p-[10px]">Silver Pieces</td>
                        <td className="border p-[10px]">amount</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => (
                            item.item_data?.length > 0 &&
                            item.item_data.map((subItem, subIndex) => (
                                item.is_total ?
                                <tr className="border">
                                    <td className="p-[10px] border">Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="p-[10px] border">{item.total_gross_weight}</td>
                                    <td className="p-[10px] border">{item.total_net_weight}</td>
                                    <td className="p-[10px] border">{item.total_item}</td>
                                    <td></td>
                                    <td className="p-[10px] border">{item.making_charge_pergram}</td>
                                    <td className="p-[10px] border">{item.total_amount}</td>
                                </tr> : <tr >
                                    { subIndex == 0 && <td className="border p-[10px]" rowSpan={item.item_data?.length ? item.item_data?.length : 1}>{item.account_head_name}</td> }
                                    <td className="p-[10px] border">{subItem.date}</td>
                                    <td className="p-[10px] border">{subItem.lot_number}</td>
                                    <td className="p-[10px] border">{subItem.invoice}</td>
                                    <td className="p-[10px] border">{subItem.gold_weight}</td>
                                    <td className="p-[10px] border">{subItem.silver_weight}</td>
                                    <td className="p-[10px] border">{subItem.gold_pieces}</td>
                                    <td className="p-[10px] border">{subItem.silver_pieces}</td>
                                    <td className="p-[10px] border">{subItem.total_amount}</td>
                                </tr>
                            ))
                            
                        ))
                    }
                </tbody>
            </table>
            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}