

export default function PurchasePrint({ billDate, estimateNo, mobile, items, itemDetails,companyDetails }) {





    return (
        <div className=" relative h-[600px]">
            <p className="font-[700] text-[14px] text-center pt-5 px-5">Ausale</p>

            <div className="flex justify-between items-center mt-[25px] pb-[25px] mx-5 border-b border-black">
                <div className="flex gap-5 items-start">
                    <div className="text-[11px]">
                        <p className="text-[11px] font-[600]">{companyDetails.company_details?.company_name}</p>
                        <p>{companyDetails.address_details?.door_no},{companyDetails.address_details?.street_name}</p>
                        <p>{companyDetails.address_details?.area},{companyDetails.address_details?.taluk},{companyDetails.address_details?.district},</p>
                        <p>Tamil Nadu - {companyDetails.address_details?.postal}</p>
                        <p>Ph : <span>{companyDetails.company_details?.mobile_no}</span></p>
                        <p>GSTIN : <span>{companyDetails.gst_details?.gst_no}</span></p>
                        <p>Email : <span>{companyDetails.company_details?.email_id}</span></p>
                    </div>




                </div>

                <div>
                    <p className="font-[600] text-[18px] text-[#000000] ">Purchase Order</p>
                    <p className="font-[400] text-[12px] text-[#1B1B1B]">PO. No#: <span className="font-[500] text-[12px] text-primary-btn">PO- 0002</span></p>
                    <p className="font-[400] text-[12px] text-[#1B1B1B]">PO Date: <span className="font-[500] text-[12px] text-primary-btn">DD-MM-YYYY</span></p>


                    <div className="mt-[25px] ">
                        <p className="font-[400] text-[12px] text-[#000000]">Purchase Type: Scrap</p>
                    </div>
                </div>
            </div>

            <div className="mt-[25px] pb-[25px] mx-5 border-b border-black">
                <div className="flex gap-5 items-start">
                    <div>
                        <p className="font-[600] text-[12px] text-primary-btn">Customer Name</p>
                        <p className="font-[500] text-[10px] text-[#2C2C3C]">SIVA</p>
                        <p className="font-[500] text-[10px] text-[#2C2C3C]">282, 4TH FLOOR, SAI AKSHAYAM,
                            Marudhamalai Rd, Mullai Nagar</p>
                        <p className="font-[500] text-[10px] text-[#2C2C3C]">Coimbatore, </p>
                        <p className="font-[500] text-[10px] text-[#2C2C3C]">Tamil Nadu 641041</p>
                    </div>
                </div>
            </div>



            <div className="mt-[25px] mx-5">
                <p className="font-[500] text-[18px] text-[#000000]">Particulars</p>

                <table className="w-full mt-[10px]">
                    <thead className="border-y border-[#000000]">
                        <tr className="">
                            <td className="py-6 text-center">#</td>
                            <td className="py-6 text-center">Metal</td>
                            <td className="py-6 text-center">Purity</td>
                            <td className="py-6 text-center">Item Name</td>
                            <td className="py-6 text-center">Sub Item Name.</td>
                            <td className="py-6 text-center">Pcs</td>
                            <td className="py-6 text-center">Grs Wt</td>
                            <td className="py-6 text-center">Net Wt</td>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            itemDetails?.map((item, index) => (
                                <tr className="border-b border-[#000000]">
                                    <td className="py-6 text-center"></td>
                                    <td className="py-6 text-center">{item.purchase_metal__metal_name}</td>
                                    <td className="py-6 text-center">{item.purchase_purity__purity_name}</td>
                                    <td className="py-6 text-center">{item.purchase_item__item_name}</td>
                                    <td className="py-6 text-center">{item.purchase_subitem__sub_item_name}</td>
                                    <td className="py-6 text-center">{item.pieces}</td>
                                    <td className="py-6 text-center">{item.gross_weight}</td>
                                    <td className="py-6 text-center">{item.net_weight}</td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>

                <div className="flex justify-between items-center mt-[40px] ">
                    {/* <div className="flex gap-5 items-start">
                    <div>
                    <p className="font-[400] text-[12px] text-[#1B1B1B]">Payment Mode:<span className="font-[500] text-[12px] text-[#1B1B1B]">Cash</span></p>
                    
                    </div>

                </div> */}

                    {/* <div>
                    <div className="flex gap-[30px] ">
                    <p className="font-[400] text-[12px] text-[#000000] ">Sub Total</p>
                    <p className="font-[500] text-[12px] text-[#000000] ">₹ 1,70,000</p>
                    </div>
                    <div className="flex gap-[30px] ">
                    <p className="font-[400] text-[12px] text-[#000000] ">Grand Total</p>
                    <p className="font-[500] text-[12px] text-[#000000] ">₹ 1,70,000</p>
                    </div>

                </div> */}
                </div>

            </div>

            {/* <div className="border-t pt-5 absolute bottom-0 w-full">
                <p className="font-[700] text-[14px] text-center">Thank You</p>
            </div> */}
        </div>
    );
}