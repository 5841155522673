
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useRef } from 'react'
import { useEffect, useState } from "react";
import { approveTransferEndPoint, branchTransferEndPoint, stockTransferTypeListEndPoint, transferStatusByStatusEndPoint, transferTableListEndPoint } from "../../../../service/api";
import { useSelector } from "react-redux";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { datetimetodate } from "../../../../utilities/datetime";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { allBranchTranferType } from "../../../../constant";
import Popup from '../../../../components/Popup';
import Pagination from '../../../../components/Pagination';
import { useKeyPress } from '../../../../hooks/useKeyPress';
import ItemApprovalFilter from './itemapprovalfillter';

export default function ItemApproval({ status, currentData, setCurrentData }) {

    const navigate = useNavigate();
    const searchRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(null);

    const [filteredData, setFilteredData] = useState({})
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        transfer_status: status.toString(),
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination') )? 10 : null,
        page: 1,
        tag_status:null
        
    });
    const { actions,pagination } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }



    useEffect(() => {
        getTableData(transferTableListEndPoint,tableFilters)
    }, [tableFilters])



    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').style.display === 'block') {
                document.getElementById('filteroptions').style.display = 'none'
            } else {
                document.getElementById('filteroptions').style.display = 'block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);





    const getTableData = async (endPoint, body) => {
        var response = await postAxiosWithToken({
            url: endPoint,
            body: body
        })

        if (response != null) {
            setFilteredData(response.data.list);
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const deleteTransfer = async () => {
        var response = await deleteAxiosWithToken({
            url: branchTransferEndPoint + currentData.id + '/'
        })

        if (response !== null) {
            getTableData(transferTableListEndPoint, tableFilters)
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          page: value,
        }));
      };
    
      const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          items_per_page: value,
          page: 1,
        }));
      };


    return (
        <>

<div id="filteroptions" className='my-[25px]' style={{display: 'none'}}>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <ItemApprovalFilter tab={status} searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />
                </div>
            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                <table className="w-full">
                    <thead className="border bg-table-head">
                        <tr>
                            <td className="p-[10px] text-table-head-font text-[16px]">Transfer ID</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Transfer From</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Transfer To</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">No.of QTY</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Transfer Date</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Required Date</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Authority</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Status</td>
                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Actions</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-[#fff]">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={9}>Loading...</td>
                            </tr> :
                                filteredData.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                    filteredData.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px]">{index + 1}</td>
                                            <td className="p-[10px] capitalize">{item.transfer_from}</td>
                                            <td className="p-[10px] capitalize">{item.transfer_to}</td>
                                            <td className="p-[10px] capitalize">{item.no_of_item}</td>
                                            <td className="p-[10px] capitalize">{item.transfer_date}</td>
                                            <td className="p-[10px] capitalize">{item.required_date}</td>
                                            <td className="p-[10px] capitalize">{item.authority_name}</td>
                                            <td className="p-[10px]">
                                                <StatusDropdown  status={item.transfer_status} item={item} index={index} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} getData={getTableData} filters={tableFilters} />
                                            </td>
                                            <td className="p-[10px]">
                                                <div className="flex gap-3">
                                                    <div onClick={() => {
                                                        if (actions.editPermit) {
                                                            setCurrentData(item);
                                                            localStorage.setItem('btrnasfer_form_mode', 'update'); localStorage.setItem('btransfer_form_id', item.id.toString()); navigate('stock-transfer-form');
                                                        } else {
                                                            toast.error("You don't have permission for edit")
                                                        }
                                                    }} className="cursor-pointer hover:scale-125">
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                    </div>
                                                    <div onClick={() => {
                                                        if (actions.deletePermit) {
                                                            setCurrentData(item);
                                                            setIsDeleteOpen(true);
                                                        } else {
                                                            toast.error("You don't have permission for delete")
                                                        }
                                                    }} className="cursor-pointer hover:scale-125">
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                        }

                    </tbody>
                </table>
            </div>

            {pagination === true ? 
                <div >
                <Pagination
      totalPages={totalPages}
      currentPage={tableFilters.page}
      setCurrentPage={pageHandler}
      entries={tableFilters.items_per_page}
      entriesHandler={entriesHandler}
      />
            </div>:""}

            <Popup title={'Delete Tranfer'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to delete the transfer?</p>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                        <button type="submit" onClick={() => { deleteTransfer() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                    </div>
                </div>
            </Popup>
        </>
    );
}


function StatusDropdown({ status, item, index, currentIndex, setCurrentIndex, filters, getData }) {
    const wrapperRef = useRef();

    const [statusList, setStatusList] = useState([])
    const [loading, setLoading] = useState(true);

    const statusListData = async (id) => {
        var response = await getAxios({
            url: transferStatusByStatusEndPoint + id + "/"
        })

        if (response != null) {
            setStatusList(response.data.list)
            setLoading(false)
        }
    }

    useEffect(() => {
        // document.addEventListener('mousedown', handleClickListener);

        // return () => {
        //     document.removeEventListener('mousedown', handleClickListener);
        // };
    }, []);

    const handleClickListener = (event) => {
        if (wrapperRef.current?.contains(event.target)) {

        } else {
            setCurrentIndex(null)
        }
    }

    const updateStatus = async (statusId) => {
        var response = await putAxiosWithToken({
            url: approveTransferEndPoint + item.id + '/',
            body: {
                "transfer_status": statusId
            }
        })

        if (response != null) {
            getData(transferTableListEndPoint, filters)
            toast.success(response.message)
            setCurrentIndex(null)
        }
    }

    return <div className='' ref={wrapperRef}>
        <div onClick={() => {
            if (index == currentIndex) {
                setCurrentIndex(null)
            } else {
                statusListData(status);
                setCurrentIndex(index)
            }
        }} style={{ backgroundColor: item.bgcolor, color: item.color  }} className="px-[10px] py-[5px] rounded-2xl text-center flex items-center justify-between">
            <p>{item.status}</p>
            <div className=''>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" /></g></svg>
            </div>
        </div>
        {index === currentIndex && <div className="w-[200px] bg-white absolute py-[5px]">
            {
                loading ? <div>Loading...</div> : statusList.map((item) => (
                    <div key={item.status_name} onClick={() => updateStatus(item.id)} className='cursor-pointer hover:bg-primary-btn hover:text-white px-[10px]'>{item.status_name}</div>
                ))
            }
        </div>}
    </div>
}