import { useEffect, useState } from "react";
import { gstViewEndPoint } from "../../../../service/api";
import { getAxios } from "../../../../service/apiservice";

export default function PaymentDetails({ formValues, setFormValues, grandTotal, setGrandTotal,readMode }) {

    console.log(">>>>>>> formValues", formValues)


    const [Gstdrop, setGstdrop] = useState([])

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        if (name == 'discount_percent') {
            let disAmt = (parseFloat(value) / 100) * grandTotal
            setGrandTotal(grandTotal - disAmt)

            setFormValues((prevState) => ({
                ...prevState,
                discount_amount: disAmt
            }))
        }
    }

   

    useEffect(() => {
        GstDropdata()
    }, [])

    const GstDropdata = async () => {
        var response = await getAxios({
            url: gstViewEndPoint
        })

        if (response !== null) {
            setGstdrop(response.data.list)
        }
    }

    return (
        <form>
            <div className="grid grid-cols-6 min-[900px]:grid-cols-3 gap-2">
                <div>
                    <p className="mb-[2px] text-[13px]">Est Chg</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="approximate_amount"
                        defaultValue={formValues?.approximate_amount}
                        onChange={onValueUpdate}
                        readOnly={readMode}
                    />
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">Discount</p>
                    <div className="flex items-center">
                        <input
                            type="number"
                            placeholder="0 %"
                            className="outline-none border border-gray-300 w-2/5 p-[3px] text-[13px] rounded-l"
                            name="discount_percent"
                            defaultValue={formValues?.discount_percentage}
                            onChange={onValueUpdate}
                            readOnly={readMode}
                        />
                        <input
                            type="number"
                            placeholder="₹ 0.00"
                            className="outline-none border border-gray-300 w-3/5 p-[3px] text-[13px] rounded-r"
                            name="discount_amount"
                            defaultValue={formValues?.discount_amount}
                            onChange={onValueUpdate}
                            readOnly={readMode}
                        />
                    </div>
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">Round Off</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="round_off"
                        value={formValues?.round_off}
                        onChange={onValueUpdate}
                        readOnly={readMode}
                    />
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">Sto / Dia Amt</p>
                    <div className="flex items-center">
                        <input
                            type="number"
                            placeholder="₹ 0.00"
                            className="outline-none border border-gray-300 w-1/2 p-[3px] text-[13px] rounded-l"
                            name="stone_amount"
                            value={formValues?.stone_amount}
                            onChange={onValueUpdate}
                            readOnly={readMode}
                        />
                        <input
                            type="number"
                            placeholder="₹ 0.00"
                            className="outline-none border border-gray-300 w-1/2 p-[3px] text-[13px] rounded-r"
                            name="diamond_amount"
                            value={formValues?.diamond_amount}
                            onChange={onValueUpdate}
                            readOnly={readMode}
                        />
                    </div>
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">GST Type <span className="text-red-500">*</span></p>
                    <select
                        type="text"
                        placeholder="GST Type"
                        className="border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none bg-white"
                        name="gst_type"
                        value={formValues?.gst_type}
                        onChange={onValueUpdate}
                        readOnly={readMode}
                    >
                        {
                            Gstdrop.map((option) => (
                                <option key={option.id} value={option.id}>{option.gst_type_name}</option>
                            ))
                        }
                    </select>
                </div>
                {String(formValues?.gst_type) == "2" && <div>
                    <p className="mb-[2px] text-[13px]">IGST</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="cursor-not-allowed border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="igst"
                        value={formValues?.igst}
                        // readOnly
                        readOnly={readMode}
                    />
                </div>}
                {String(formValues?.gst_type) == "1" && <div>
                    <p className="mb-[2px] text-[13px]">SGST</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="cursor-not-allowed border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="sgst"
                        value={formValues?.sgst}
                        // readOnly
                        readOnly={readMode}
                    />
                </div>}
                {String(formValues?.gst_type) == "1" && <div>
                    <p className="mb-[2px] text-[13px]">CGST</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="cursor-not-allowed border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="cgst"
                        value={formValues?.cgst}
                        // readOnly
                        readOnly={readMode}
                    />
                </div>}
                <div>
                    <p className="mb-[2px] text-[13px]">Others</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="others"
                        value={formValues?.others}
                        onChange={onValueUpdate}
                        readOnly={readMode}
                    />
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">Macking Chg</p>
                    <div className="flex items-center">
                        <input
                            type="number"
                            placeholder="₹ 0.00/g"
                            className="outline-none border border-gray-300 w-1/2 p-[3px] text-[13px] rounded-l"
                            name="making_charge_pergram"
                            value={formValues?.making_charge_pergram}
                            onChange={onValueUpdate}
                            readOnly={readMode}
                        />
                        <input
                            type="number"
                            placeholder="₹ 0.00"
                            className="outline-none border border-gray-300 w-1/2 p-[3px] text-[13px] rounded-r"
                            name="flat_making_charge"
                            value={formValues?.flat_making_charge}
                            onChange={onValueUpdate}
                            readOnly={readMode}
                        />
                    </div>
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">Old Gold Amt</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="cursor-not-allowed border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="advance_old_gold_amount"
                        value={formValues?.advance_old_gold_amount}
                        readOnly
                    />
                </div>
                <div>
                    <p className="mb-[2px] text-[13px]">Old Silver Amt</p>
                    <input
                        type="number"
                        placeholder="₹ 0.00"
                        className="cursor-not-allowed border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                        name="advance_old_silver_amount"
                        value={formValues?.advance_old_silver_amount}
                        readOnly
                    />
                </div>
            </div>
        </form>
    );
}