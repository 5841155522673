import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { itemwiseSaleReportListEndPoint } from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { useKeyPress } from "../../../hooks/useKeyPress";
import ItemWiseSalereportFilter from "./itemwisesalereportfilter";
import ItemWiseSaleReportForm from "./itemwisesalereportform";

export default function ItemWiseSaleReport() {

    const searchRef = useRef();
    const [itemwiseList, setitemwiseList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Item Wise Sale Report"


    function closeExcelModal() {
        setExcelOpen(false)
    }


    let Totalquantity = 0;
    for (var i of itemwiseList) {
      if (i.pieces != "") {
        Totalquantity += parseFloat(i.pieces);
      }
    }

    
    let Totalweight = 0;
    for (var i of itemwiseList) {
      if (i.total_weight != "") {
        Totalweight += parseFloat(i.total_weight);
      }
    }

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        metal_type: null,
        item_name: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);


    useEffect(() => {
        if (itemwiseList.length > 0) {
            let firstData = Object.keys(itemwiseList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [itemwiseList])

    useEffect(() => {
        getitemwiseListData(tableFilters)
    }, [tableFilters])



    const getitemwiseListData = async (body) => {
        var response = await postAxiosWithToken({
            url: itemwiseSaleReportListEndPoint,
            body: body
        })

        if (response !== null) {
            setitemwiseList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                    Item Wise Sale Report
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={itemwiseList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={itemwiseList} fileName={fileName} />
                </div>
            </div>
            <ItemWiseSaleReportForm searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <ItemWiseSalereportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }

                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[13px] uppercase font-[700]">
                        <tr>
                        <td className="p-[10px]">sI.no</td>
                            <td className="p-[10px]">item name</td>
                            <td className="p-[10px]">Metal type</td>
                            <td className="p-[10px]">Pcs</td>
                            <td className="p-[10px]">weight</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-menu-head-bg">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={8}>Loading...</td>
                            </tr> :
                                itemwiseList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                    itemwiseList.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px] capitalize">{index + 1}</td>
                                            <td className="p-[10px] capitalize">{item.item_name}</td>
                                            <td className="p-[10px] capitalize">{item.metal_name}</td>
                                            <td className="p-[10px] capitalize">{item.pieces}</td>
                                            <td className="p-[10px] capitalize">{item.total_weight}</td>
                                        </tr>
                                    ))
                        }
                        <tr className="bg-[#DFE4EA]">
                            <td></td>
                            <td className="pl-2">Total</td>
                            <td></td>
                            <td className="pl-2">{Totalquantity ? Totalquantity : 0}</td>
                            <td className="pl-2">{Totalweight ? Totalweight : 0}</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}