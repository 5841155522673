import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { convertToDate } from '../../../utilities/datetime';
import { addDays } from 'date-fns/esm';
import { getAxios } from '../../../service/apiservice';
import { branchListEndPoint } from '../../../service/api';

export default function ValueadditionFilters({ searchRef, getData, dataList, setFilteredData }) {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [search, setSearch] = useState('');
    const [branchList, setBranchList] = useState([]);

    useEffect(()=>{
        getBranchDropdownData();
    },[])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
          url: branchListEndPoint,
        });
    
        if (response !== null) {
            getData();
            setBranchList(response.data.list);
        }
      };

    useEffect(() => {
        handleSearch(search)
    }, [dataList])

    const handleSearch = (value) => {
        setSearch(value)

        var returnValue = dataList.filter(post => {
            if (value === "") {
                //if query is empty
                return post;
            } else if (post.metal__metal_name.toLowerCase().includes(value.toLowerCase()) ||
                post.stock_type__stock_type_name.toLowerCase().includes(value.toLowerCase()) ||
                post.sub_item_details__sub_item_name.toLowerCase().includes(value.toLowerCase()) ||
                post.calculation_type__calculation_name.toLowerCase().includes(value.toLowerCase()) ||
                post.tag_type__tag_name.toLowerCase().includes(value.toLowerCase())) {
                //returns filtered array
                return post;
            }
        });

        setFilteredData(returnValue)
    }

    const handleDateFromFilter = (value) => {
        setFromDate(value)
        if (toDate !== null && value !== null) {
            let body = {
                search: search,
                from_date: value === null ? null : convertToDate(value),
                to_date: toDate === null ? null : convertToDate(addDays(toDate, 1)),
            }
            getData(body)
        }
    }

    const handleDateToFilter = (value) => {
        setToDate(value)
        if (fromDate !== null && value !== null) {
            let body = {
                search: search,
                from_date: fromDate === null ? null : convertToDate(fromDate),
                to_date: value === null ? null : convertToDate(addDays(value, 1)),
            }
            getData(body)
        }

    }

    const handleDateClearFilter = () => {
        let body = {
            search: search,
            from_date: null,
            to_date: null,
        }
        getData(body)
    }

    const handleBranchFilter = (value) => {
        // setToDate(value)
        if (value.length !== 0 ) {
            let body = {
                search: search,
                from_date: fromDate === null ? null : convertToDate(fromDate),
                to_date: toDate === null ? null : convertToDate(addDays(value, 1)),
                branch: value === "all" ? null :parseInt(value),
            }
            getData(body)
        }
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full">
                <div className='sm:flex justify-between lg:gap-3 w-full 2xl:w-[73%]'>
                    <div class="flex gap-3 border rounded-xl px-[10px]">
                        <div className="flex items-center pl-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            autoComplete="off"
                            id="searchfilter"
                            ref={searchRef}
                            className="py-[10px] outline-none w-[230px]"
                            placeholder="Search User roles..."
                            value={search}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>

                {localStorage.getItem("branch") === "true" && 
                    <div className=''>
                        <select
                            placeholder="metal_type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                            name="metal_type"
                            // value={classification.classification_name} 
                            onChange={(e) => handleBranchFilter(e.target.value)}
                            >
                            <option value="all">All Branch</option>
                            {branchList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                {option?.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>}

                <div className='min-[400px]:flex  gap-5 w-full 2xl:w-[25%] min-[400px]:justify-between border items-center py-[10px] px-[10px] rounded-xl'>
                    <div className=''>
                        <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateFromFilter} value={fromDate} />
                    </div>
                    <p className='text-center'>to</p>
                    <div>
                        <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateToFilter} value={toDate} />
                    </div>
                    <div onClick={() => { setFromDate(null); setToDate(null); handleDateClearFilter() }} className='cursor-pointer'>
                        <svg className='min-[400px]:block hidden' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z" /></svg>
                        <p className='min-[400px]:hidden text-center block'>clear</p>
                    </div>
                </div>
            </div>
        </>
    );
}