export default function SelectedPaymentDetails({ formValues }) {

    return (
        <form>
            <div className="flex gap-3 flex-wrap w-full">
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <p className="text-[15px] text-green-700">Cash paid(₹)</p>
                    <div>
                        <input
                            type="text"
                            placeholder="Cash paid(₹)"
                            className="sm:mb-[5px] border border-green-700 rounded-lg w-full p-[5px] text-green-700 text-[17px] outline-none"
                            name="total_cash"
                            value={formValues.total_cash}
                            readOnly
                        />
                    </div>
                </div>
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <p className="text-[15px] text-green-700">Metal Given(g)</p>
                    <div>
                        <input
                            type="text"
                            placeholder="Metal Given(g)"
                            className="sm:mb-[5px] border border-green-700 rounded-lg w-full p-[5px] text-green-700 text-[17px] outline-none"
                            name="total_weight"
                            value={formValues.total_weight}
                            readOnly
                        />
                    </div>
                </div>
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <p className="text-[15px] text-red-600">Cash Balance(₹)</p>
                    <div>
                        <input
                            type="text"
                            placeholder="Cash Balance(₹)"
                            className="sm:mb-[5px] border border-red-600 rounded-lg w-full p-[5px] text-red-600 text-[17px] outline-none"
                            name="pending_amount"
                            value={formValues.pending_amount}
                            readOnly
                        />
                    </div>
                </div>
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <p className="text-[15px] text-red-600">Metal Balance(g)</p>
                    <div>
                        <input
                            type="text"
                            placeholder="Metal Balance(g)"
                            className="sm:mb-[5px] border border-red-600 rounded-lg w-full p-[5px] text-red-600 text-[17px] outline-none"
                            name="pending_weight"
                            value={formValues.pending_weight}
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}