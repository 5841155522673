import { useEffect, useState } from "react";
import { cardnameListEndPoint, cardtypeListEndPoint, centgroupListEndPoint, lotStoneDeleteEndPoint, rateTypeListEndPoint, stoneListEndPoint, taggingWeightTypeListEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios } from "../../../service/apiservice";
import { isValidForm } from "../../../utilities/formvalidation";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import Select from 'react-select';

export default function Cardform({ dataList = [], setDataList, formType, closeModal, setRemovedStone, removedStone }) {

    const [cardnameDropDown, setCardnameDropDown] = useState([]);
    const [weightDropDown, setWeightDropDown] = useState([]);
    const [cardtypeDropDown, setCardtypeDropDown] = useState([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
        setIsLoading(null)
    }

    useEffect(() => {

        if (formType === 'create') {
            addValue()
        }
        getCardNameDropDownData();
        getWeightTypeDropDownData();
        cardTypeDropDownData();
    }, [])

    const getCardNameDropDownData = async () => {
        var response = await getAxios({
            url: cardnameListEndPoint + "3" + "/"
        })

        if (response !== null) {
            setCardnameDropDown(response.data.list)
        }
    }

    const getWeightTypeDropDownData = async () => {
        var response = await getAxios({
            url: taggingWeightTypeListEndPoint
        })

        if (response !== null) {
            setWeightDropDown(response.data.list)
        }
    }

    const cardTypeDropDownData = async () => {
        var response = await getAxios({
            url: cardtypeListEndPoint
        })

        if (response !== null) {
            setCardtypeDropDown(response.data.list)
        }
    }

    const addValue = () => {
        let firstData = {
            card_type: "",
            card_name: "",
            received_date: "",
            date: "",
            reference: "",
        }
        setDataList([...dataList, firstData])
    }

    const removeValue = () => {
        let data = [...dataList];

        if (formType === 'update') {
            if (data[deleteIndex].id) {
                setIsLoading(deleteIndex)
                setIsDeleteOpen(true)
                deleteStone(data[deleteIndex].id)
            } else {
                setIsLoading(null)
                setIsDeleteOpen(false)
            }
        }
        data.splice(deleteIndex, 1);
        setDataList(data);
    }

    const onValueUpdate = (text, index) => {
        const { name, value } = text
        let data = [...dataList];
        data[index][name] = value
        setDataList(data)
    }

    const deleteStone = async (id) => {

        var response = await deleteAxiosWithToken({
            url: lotStoneDeleteEndPoint + id + '/'
        })

        if (response !== null) {
            setIsLoading(null)
            setIsDeleteOpen(false)
            toast.success(response.message)
        }
    }

    return (
        <div className="w-full mt-[15px]">
            {/* <button className="border-none outline-none bg-primary-btn text-white rounded float-right mb-[10px] px-[10px] py-[7px]" onClick={(e) => { e.preventDefault(); addValue() }}>Add Stone</button> */}
            <div className="w-full mb-10  rounded-md ">

                {
                    dataList.map((item, index) => (
                        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Card Type <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Card Type"
                                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="card_type"
                                    value={item.card_type}
                                    onChange={(e) => onValueUpdate(e.target, index)}
                                >
                                    <option value={""}>--select Card type--</option>
                                    {
                                        cardtypeDropDown.map((option, index) => (
                                            <option key={"cardtype" + index} value={option.id}>{option.card_name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Card Name <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Card Name "
                                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="card_name"
                                    value={item.card_name}
                                    onChange={(e) => onValueUpdate(e.target, index)}
                                >
                                    <option value={""}>--select card name--</option>
                                    {
                                        cardnameDropDown.map((option, index) => (
                                            <option key={"rate" + index} value={option.id}>{option.payment_provider_name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Date <span className="text-red-500">*</span></p>
                                <input
                                    type="date"
                                    name="date"
                                    placeholder="Date"
                                    value={item.date}
                                    onChange={(e) => onValueUpdate(e.target, index)}

                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.issued_gross_weight}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Amount<span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    name="Amount"
                                    placeholder="amount"
                                    value={item.amount}
                                    onChange={(e) => onValueUpdate(e.target, index)}

                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Reference<span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    name="reference"
                                    placeholder="received_date"
                                    value={item.reference}
                                    onChange={(e) => onValueUpdate(e.target, index)}

                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.issued_gross_weight}</p> */}
                            </div>
                        </div>
                    ))
                }


            </div>

            <div className="mt-[10px] flex gap-3 justify-end items-center">
                <button onClick={() => {

                    closeModal()
                }} className="border-[2px] outline-none px-[10px] py-[5px] w-fit rounded-lg hover:bg-primary-btn  hover:text-white">Reset</button>

                <button onClick={() => {
                    let tempDataList = []
                    for (var item of dataList) {
                        if (isValidForm(item)) {
                            tempDataList.push(item)
                        }
                    }
                    setDataList(tempDataList)
                    closeModal()
                }} className="border-[2px] outline-none px-[10px] py-[5px] w-fit rounded-lg border-secondary hover:bg-secondary hover:text-primary-grey">Add</button>
                {/* <button className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Save</button> */}
            </div>

            <div>
                <Popup title={'Delete Stone'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the stone details?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { removeValue() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
}