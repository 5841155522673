import { useEffect, useState } from "react";
import { getAxios } from "../../../service/apiservice";
import { tagdetailsEndPoint } from "../../../service/api";
import Popup from "../../../components/Popup";
import { packetStockType } from "../../../constant";

export default function ParticularsList({ items, setItems, particularsCalculation }) {

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
        setIsLoading(null)
    }

    useEffect(() => {
        addItem();
    },[])

    const addItem = () => {
        let data = [...items];
        data.push({
            tag_no: ""
        });
        setItems(data)
    }

    const onValueUpdate = (input, index) => {
        const { name, value } = input;

        let data = [...items];
        data[index][name] = value;

        if (name == 'tag_no') {
            if (value.length == 7) {
                getTagDetails(value, index)
            }
        }
        setItems(data)
    }

    const getTagDetails = async (tagNumber, index) => {
        var response = await getAxios({
            url: tagdetailsEndPoint + tagNumber + '/'
        })

        if (response != null) {
            let data = [...items];
            data[index]['tag_no'] = tagNumber
            data[index]['item_name'] = response.data.item_name
            data[index]['sub_item_name'] = response.data.sub_item_name
            data[index]['gross_weight'] = response.data.gross_weight
            data[index]['net_weight'] = response.data.net_weight
            data[index]['pieces'] = response.data.pieces
            data[index]['purity_name'] = response.data.item_purity_name
            if (response.data.stock_type == packetStockType) {
                data[index]['rate'] = response.data.max_fixed_rate
            } else {
                data[index]['rate'] = response.data.metal_rate
            }
            data[index]['total_amount'] = response.data.max_sale_value
            setItems(data)
        }
    }

    const removeValue = () => {
        let data = [...items];
        data.splice(deleteIndex, 1);
        setItems(data);
        setIsDeleteOpen(false);
        setIsLoading(null);
        setDeleteIndex(null);
    }

    return (
        <div className="w-full">
            <div className="mb-2 flex items-center justify-between">
                <p>Items</p>
            <button
                onClick={(e) => { e.preventDefault(); addItem(); }}
                className="capitalize bg-primary-btn bg-opacity-50 hover:bg-opacity-100 text-black hover:text-white w-fit rounded p-[5px] px-[10px] h-fit text-[12px] outline-none border border-primary-btn"
            >Add Item</button>
            </div>
            <div className="w-full">
                <table className="w-full">
                    <thead className="bg-table-head text-table-head-font">
                        <tr>
                            <td className="p-2 border">S.No</td>
                            <td className="p-2 border ">Tag No</td>
                            <td className="p-2 border">Item Name</td>
                            <td className="p-2 border">Sub Item Name</td>
                            <td className="p-2 border">Gross Weight</td>
                            <td className="p-2 border">Net Weight</td>
                            <td className="p-2 border">Pieces</td>
                            <td className="p-2 border">Purity</td>
                            <td className="p-2 border">Rate</td>
                            <td className="p-2 border">Total Amount</td>
                            <td className="p-2 border">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map((item, index) => (
                                <tr>
                                    <td className="p-2 border">{index + 1}</td>
                                    <td className="p-2 border">
                                        <input
                                            type="text"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg p-[5px] text-[13px] outline-none"
                                            name="tag_no"
                                            value={item.tag_no}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className="p-2 border">{item.item_name}</td>
                                    <td className="p-2 border">{item.sub_item_name}</td>
                                    <td className="p-2 border">{item.gross_weight} g</td>
                                    <td className="p-2 border">{item.net_weight} g</td>
                                    <td className="p-2 border">
                                        <input 
                                        type="number"
                                        name="pieces"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg p-[5px] text-[13px] outline-none max-w-[80px]"
                                        value={item.pieces}
                                        onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className="p-2 border">{item.purity_name}</td>
                                    <td className="p-2 border">₹ {item.rate}</td>
                                    <td className="p-2 border">₹ {item.total_amount}</td>
                                    <td className="p-2 border">
                                        <div onClick={() => { setIsDeleteOpen(true); setDeleteIndex(index); setIsLoading(index); }} className={`${String(isLoading) !== String(index) ? 'block' : 'hidden'} cursor-pointer hover:scale-125 hover:text-red-600 w-fit mx-auto`}>
                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className="border-x border-b">
                            <td className="p-2"></td>
                            <td className="p-2 "></td>
                            <td className="p-2"></td>
                            <td className="p-2"></td>
                            <td className="p-2">{particularsCalculation.gross_weight} g</td>
                            <td className="p-2">{particularsCalculation.net_weight} g</td>
                            <td className="p-2">{particularsCalculation.pieces}</td>
                            <td className="p-2"></td>
                            <td className="p-2"></td>
                            <td className="p-2">₹ {particularsCalculation.total_amount}</td>
                            <td className="p-2"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Popup title={'Delete item'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to delete the item details?</p>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                        <button type="submit" onClick={() => { removeValue() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}