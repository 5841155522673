import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { mainmenuGroupEndPoint, mainmenuGroupStatusEndPoint, mainmenuListEndPoint, menuEndPoint, menuStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import { useSelector } from "react-redux";
import MainMenuForm from "./mainmenuform";
import MainMenuFilters from "./mainmenufilters";

export default function MainMenu() {

  const searchRef = useRef();

  const [mainmenuList, setMainMenuList] = useState([]);
  const [filteredMainMenuList, setFilteredMainMenuList] = useState([]);
  const [currentMainMenu, setCurrentMainMenu] = useState({});
  const [formMode, setFormMode] = useState('create');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { actions } = useSelector((state) => state.appConfig);

  function closeDeleteModal() {
    setIsDeleteOpen(false)
  }

  useEffect(() => {
    getMainMenuListData({})
  }, [])

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'f') {
      event.preventDefault();
      if (document.getElementById('filteroptions').className === 'my-[25px] block') {
        document.getElementById('filteroptions').className = 'my-[25px] hidden'
      } else {
        document.getElementById('filteroptions').className = 'my-[25px] block'
        searchRef.current?.focus()
      }
    }
  };

  useKeyPress(['f'], onKeyPress);

  const getMainMenuListData = async (body) => {
    var response = await postAxiosWithToken({
      url: mainmenuListEndPoint,
      body: body
    })

    if (response !== null) {
      setMainMenuList(response.data.list)
      setIsLoading(false)
    }
  }

  const onStatusChange = async (id) => {
    var response = await getAxios({
      url: mainmenuGroupStatusEndPoint + id + '/'
    })

    if (response !== null) {
      getMainMenuListData()
      toast.success(response.message)
    }
  }

  const deleteMainMenu = async () => {
    var response = await deleteAxiosWithToken({
      url: mainmenuGroupEndPoint + currentMainMenu.id + '/'
    })

    if (response !== null) {
      getMainMenuListData()
      closeDeleteModal()
      toast.success(response.message)
    }
  }

  return (
    <>
      <div>
        {
          formMode === 'create' ? actions.addPermit ? <div>
            <MainMenuForm getData={getMainMenuListData} formMode={formMode} setFormMode={setFormMode} curMenu={currentMainMenu} setCurrentMainMenu={setCurrentMainMenu} />
          </div> : '' : actions.editPermit ? <div>
            <MainMenuForm getData={getMainMenuListData} formMode={formMode} setFormMode={setFormMode} curMenu={currentMainMenu} setCurrentMainMenu={setCurrentMainMenu} />
          </div> : ''
        }
        <div id="filteroptions" className='my-[25px] hidden'>
          <p className="font-[600] text-[18px]">Filters</p>
          <MainMenuFilters searchRef={searchRef} getData={getMainMenuListData} dataList={mainmenuList} setFilteredData={setFilteredMainMenuList} />
        </div>
        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
          <table className="w-full">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[150px]">Main Menu Name</td>
                <td className="p-[10px] min-w-[150px]">Created At</td>
                <td className="p-[10px] min-w-[150px]">Status</td>
                <td className="p-[10px] min-w-[150px]">Actions</td>
              </tr>
            </thead>
            <tbody className="text-primary-grey">
              {
                isLoading ? <tr className="text-center w-full">
                  <td className="text-center border" colSpan={7}>Loading...</td>
                </tr> :
                  filteredMainMenuList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                    filteredMainMenuList.map((item, index) => (
                      <tr key={item.id} className="border">
                        <td className="p-[10px]">{index + 1}</td>
                        <td className="p-[10px]">{item.main_menugroup_name}</td>
                        <td className="p-[10px]">{datetimetodate(item.created_at)}</td>
                        <td className="p-[10px]">
                          <div className="mt-1" onClick={() => {
                            if (actions.editPermit) {
                              onStatusChange(item.id)
                            } else {
                              toast.error("You don't have permission for update the status")
                            }
                          }} >
                            <SwitchButton enabled={item.is_active} />
                          </div></td>
                        <td className="p-[10px]">
                          <div className="flex gap-3">
                            <div onClick={() => {
                              if (actions.editPermit) {
                                setCurrentMainMenu(item);
                                setFormMode('update');
                              } else {
                                toast.error("You don't have permission for edit")
                              }
                            }} className="cursor-pointer hover:scale-125">
                              <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                            </div>
                            <div onClick={() => {
                              if (actions.deletePermit) {
                                setCurrentMainMenu(item);
                                setIsDeleteOpen(true);
                              } else {
                                toast.error("You don't have permission for delete")
                              }
                            }} className="cursor-pointer hover:scale-125">
                              <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
              }

            </tbody>
          </table>
        </div>
      </div>

      <div>
        <Popup title={'Delete Main Menu'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">Do you want to delete the {currentMainMenu?.main_menugroup_name} main menu?</p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
              <button type="submit" onClick={() => { deleteMainMenu() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
}