export default function ViewParticulars({ items }) {
    return (
        <div className="w-full">
            <table className="w-full">
                <thead className="bg-table-head text-table-head-font">
                    <tr>
                        <td className="p-2 border">#</td>
                        <td className="p-2 border">Tag No</td>
                        <td className="p-2 border">Metal</td>
                        <td className="p-2 border">Item Name</td>
                        <td className="p-2 border">Sub Item Name</td>
                        <td className="p-2 border">Gross Weight</td>
                        <td className="p-2 border">Net Weight</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) => (
                            <tr>
                                <td className="p-2 border">{index + 1}</td>
                                <td className="p-2 border">{item.tag_number}</td>
                                <td className="p-2 border">{item.metal}</td>
                                <td className="p-2 border">{item.item_name}</td>
                                <td className="p-2 border">{item.sub_item_name}</td>
                                <td className="p-2 border">{item.gross_weight} g</td>
                                <td className="p-2 border">{item.net_weight} g</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}