import { useEffect, useState } from "react";

export default function RequisitionApprovalPrint({ particulars, detials }) {

    const [totalPieces, setTotalPieces] = useState(0);
    const [totalGrsWt, setTotalGrsWt] = useState(0.0);
    const [totalNetWt, setTotalNetWt] = useState(0.0);

    useEffect(() => {
        let totalPieces = 0;
        let totalGross = 0.0;
        let totalNet = 0.0;
        for (var i of particulars) {
            totalPieces = totalPieces + i.pieces;
            totalGross = totalGross + i.gross_weight;
            totalNet = totalNet + i.net_weight;
        }
        setTotalPieces(totalPieces);
        setTotalGrsWt(totalGross);
        setTotalNetWt(totalNet)
    },[particulars])
    
    return (
        <div className="mb-[100px] h-[1100px] p-[10px] relative bg-white">
            <div className="flex items-center justify-between w-full">
                <div className="w-[80px] h-[80px] rounded-full bg-[#D9D9D9] flex items-center justify-center">
                    Shop Logo
                </div>
                <div>
                    <p className="font-[700] text-[20px]">Sales Requisition</p>
                    <p className="font-[700] text-[20px]">Req. No#: <span className="text-primary-btn">{detials.approval_issue_id}</span></p>
                    <p className="font-[400] text-[12px]">DATE: <span>{detials.issue_date}</span></p>
                </div>
            </div>
            <div className="relative flex items-start justify-between w-full mt-[15px]">
                <div className="flex items-start w-[40%] text-[13px] float-left">
                    <p className="w-[150px]">Issue by:</p>
                    <div>
                        <p className="font-[700] text-[14px]">Shop Name</p>
                        <p className="break-all">282, 4TH FLOOR, SAI AKSHAYAM, Marudhamalai Rd, Mullai Nagar, Coimbatore, Tamil Nadu 641041</p>
                    </div>
                </div>
                <div className="flex items-start gap-3 text-[13px] w-[40%] float-right ">
                    <p className="w-[130px]">Sale To:</p>
                    <div>
                        <p className="font-[700] text-[14px]">{detials.shop_name_details}</p>
                        <p className="break-all">{detials.shop_address}</p>
                    </div>
                </div>
            </div>
            <div className="my-[15px] border-2 border-[#DFE4EA] bg-[#F9F9FA] px-6 rounded-lg w-full flex justify-between items-center h-[40px]">
                <p className="capitalize font-[400] text-[12px]">issue date: <span>{detials.issue_date}</span></p>
                <p className="capitalize font-[400] text-[12px]">Sales Person: <span>{detials.issued_by}</span></p>
            </div>
            <div className="mt-[15px] border border-[#DFE4EA] rounded-lg">
                <table className="w-full text-[12px]  ">
                    <thead className="font-[700]">
                        <tr>
                            <td className="p-[5px]">#</td>
                            <td className="p-[5px]">Tag Number</td>
                            <td className="p-[5px]">Item Name</td>
                            <td className="p-[5px]">Pcs</td>
                            <td className="p-[5px]">Grs Wt</td>
                            <td className="p-[5px]">Net Wt</td>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            particulars.map((item, index) => (
                                <tr className="border">
                                    <td className="p-[5px]">{index + 1}</td>
                                    <td className="p-[5px]">{item.tag_number}</td>
                                    <td className="p-[5px]">{item.item_name}</td>
                                    <td className="p-[5px]">{item.pieces}</td>
                                    <td className="p-[5px]">{item.gross_weight} g</td>
                                    <td className="p-[5px]">{item.net_weight} g</td>
                                </tr>
                            ))
                        }
                        <tr className=" px-6 py-3 rounded-lg font-[700] text-[12px]">
                            <td colSpan={3}>Total</td>
                            <td>{totalPieces||0}</td>
                            <td>{totalGrsWt} g</td>
                            <td>{totalNetWt} g</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex items-center justify-between px-[50px] w-full bottom-[100px] absolute">
                <div>
                    <p className="italic text-[9px]">Customer Signatory</p>
                </div>
                <div>
                    <p className="italic text-[9px]">Authorised Signatory</p>
                </div>
            </div>
        </div>
    );
}