import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { metalListEndPoint, purityEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";

export default function PurityForm({ getData, formMode, currentPurity, setFormMode, setCurrentPurity }) {

    const [metalList, setMetalList] = useState([]);

    const schema = yup
        .object({
            purity_name: yup.string().required(),
            purity_code: yup.string().required(),
            purrity_percent: yup.string().required(),
            metal: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            purity_name: currentPurity.purity_name,
            purity_code: currentPurity.purity_code,
            purrity_percent: currentPurity.purrity_percent,
            metal: currentPurity.metal
        } : {
            purity_name: "",
            purity_code: "",
            purrity_percent: "",
            metal: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    useEffect(() => {
        getMetalListData()
    }, [])

    const getMetalListData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalList(response.data.list)
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createPurity(data)
            } else {
                updatePurity(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createPurity = async (body) => {
        var response = await postAxiosWithToken({
            url: purityEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)

        }
    }

    const updatePurity = async (body) => {
        var response = await putAxiosWithToken({
            url: purityEndPoint + currentPurity?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentPurity({
            purity_name: "",
            purity_code: "",
            purrity_percent: "",
            metal: ""
        })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg border shadow">
                <p className="text-menu-head font-[600] text-[18px] capitalize capitalize">{formMode} Purity</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Metal Name</p>
                        <select
                            placeholder="metal name"
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="metal"

                            {...register('metal')}
                        >
                            <option value={""}>--select metal name--</option>
                            {
                                metalList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.metal_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.metal?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Purity Name</p>
                        <input
                            type="text"
                            placeholder="purity name"
                            className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="purity_name"
                            id="forminit"
                            {...register('purity_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.purity_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Purity Code</p>
                        <input
                            type="text"
                            placeholder="purity code"
                            className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="purity_code"
                            {...register('purity_code')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.purity_code?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Purity percent</p>
                        <input
                            type="number"
min="0"
                            step={0.1}
                            placeholder="purity percent"
                            className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="purrity_percent"
                            {...register('purrity_percent')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.purrity_percent?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-0 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}