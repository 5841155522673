import { useState } from "react";
import { isOptionalValidForm } from "../../../../utilities/formvalidation";
import { postAxiosWithToken } from "../../../../service/apiservice";
import { amountRateCutEndPoint } from "../../../../service/api";
import toast from "react-hot-toast";

export default function AmountSettlementForm({ calculate, setCalculate, selectedItems, vendorDetails, formValues, setFormValues, vendorErrors, setVendorErrors, getData }) {
    const [errors, setErrors] = useState({});

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target)
        let formDataObject = Object.fromEntries(formFields.entries());

        let data = { ...vendorDetails, ...formDataObject }
        
        var err = {}
        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }
        setErrors(err)

        if (isOptionalValidForm(data, ['discount'])) {
            delete data.credit_balance_gm
            delete data.credit_balance_rupee
            delete data.metal

            if (data.metal_receivable?.toLowerCase() == 'on') {
                data['metal_receivable'] = true
            } else {
                data['metal_receivable'] = false
            }  
            
            if (data.cash_receivable?.toLowerCase() == 'on') {
                data['cash_receivable'] = true
            } else {
                data['cash_receivable'] = false
            }  

            if (selectedItems.length > 0) {
                let purchaseList = [];

                for (var i of selectedItems) {
                    purchaseList.push(i.id)
                }
                data['purchase_order'] = purchaseList.join(',')

                if (data.discount.length > 0) {
                    settleAmount(data)
                } else {
                    data['discount'] = 0
                    settleAmount(data)
                }
            } else {
                toast.error('Select pending due list')
            }
        }
    }

    const settleAmount = async (data) => {
        var response = await postAxiosWithToken({
            url: amountRateCutEndPoint,
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            getData();
            setFormValues((prevState) => ({
                ...prevState,
                amount: '',
                discount: ''
            }))

            setErrors({})
            setCalculate(!calculate)
        }
    }

    return (
        <form id="amountsettleform" onSubmit={handleSubmit}>
            <div className="">
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                        <p className="text-[12px] sm:text-[15px]">Metal Receivable(g)</p>
                        <div class="inline-flex items-center">
                            <label class="relative flex items-center p-3 rounded-full " htmlFor="check">
                                <input type="checkbox"
                                    name="metal_receivable"
                                    id="metal_receivable"
                                    class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border-2 border-gray-400 transition-all rounded  checked:border-primary-btn checked:bg-primary-btn checked:before:bg-primary-btn hover:before:opacity-10"
                                />
                                <span
                                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                        stroke="currentColor" stroke-width="1">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Metal Receivable(g)"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="credit_balance_gm"
                            value={formValues.credit_balance_gm}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.credit_balance_gm}</p>
                    </div>
                </div>
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <div className="flex gap-3 items-center">
                        <p className="text-[12px] sm:text-[15px]">Cash Receivable(₹)</p>
                        <div class="inline-flex items-center">
                            <label class="relative flex items-center p-3 rounded-full " htmlFor="check">
                                <input type="checkbox"
                                    name="cash_receivable"
                                    id="cash_receivable"
                                    class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border-2 border-gray-400 transition-all rounded checked:border-primary-btn checked:bg-primary-btn checked:before:bg-primary-btn hover:before:opacity-10"
                                />
                                <span
                                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                        stroke="currentColor" stroke-width="1">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Cash Receivable(₹)"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="credit_balance_rupee"
                            value={formValues.credit_balance_rupee}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.credit_balance_rupee}</p>
                    </div>
                </div>
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <p className="text-[12px] sm:text-[15px]">Amount paid(₹) <span className="text-red-500 ">*</span></p>
                    <div>
                        <input
                            type="text"
                            placeholder="Amount paid(₹)"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="amount"
                            value={formValues.amount}
                            onChange={onValueUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.amount}</p>
                    </div>
                </div>
                <div className="my-[5px] lg:flex gap-3 items-center justify-between">
                    <p className="text-[12px] sm:text-[15px]">Discount(₹)</p>
                    <div>
                        <input
                            type="text"
                            placeholder="Discount(₹)"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                            name="discount"
                            value={formValues.discount}
                            onChange={onValueUpdate}
                        />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                disabled={selectedItems.length > 0 ? false : true}
                className="capitalize border border-primary-btn disabled:border-gray-400 bg-primary-btn disabled:bg-gray-400 text-white w-fit rounded-lg p-[5px] sm:px-[12px] sm:py-[5px] h-fit font-[500] text-[13px] sm:text-[15px] outline-none float-right"
            >Amount Settle</button>
        </form>
    );
}