import { AdvancePaaymentIdEndPoint, AdvancePaymentListEndPoint, AdvancePurposeEndPoint, AdvancepaymentEndPoint, advancepaymentEndPoint, advancepaymentGroupListEndPoint, customerListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { isOptionalValidForm } from "../../../utilities/formvalidation";

const optionalFields = ['email', 'dob', 'address', 'gst_no', 'pan_card', 'aadhar_card']

export default function AdvancepaymentForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentAdvancepayment, setCurrentAdvancepayment] = useState({
        is_redeem: false
    });
    const [advancepurposedropdown, setAdvancePurposeDropDown] = useState([]);
    // const [advanceId, setAdvanceId] = useState('')

    const [customerdropdown, setCustomerDropDown] = useState([]);

    const [errors, setErrors] = useState({});

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {

        getCustomerListData();
        getAdvancePurposeListData();
        setFormMode(localStorage.getItem('advancepayment_form_mode'))

        if (localStorage.getItem('advancepayment_form_mode') === 'update') {
            getAdvancepaymentDetails()
        } else {
            getAdvanceIdData();
        }

    }, [])

    const getCustomerListData = async () => {
        var response = await getAxios({
            url: customerListEndPoint
        })

        if (response !== null) {
            setCustomerDropDown(response.data.list)
        }
    }

    const getAdvancePurposeListData = async () => {
        var response = await getAxios({
            url: AdvancePurposeEndPoint
        })

        if (response !== null) {
            setAdvancePurposeDropDown(response.data.list)
        }
    }


    const getAdvanceIdData = async () => {
        var response = await getAxios({
            url: AdvancePaaymentIdEndPoint

        })

        if (response !== null) {
            setCurrentAdvancepayment((prevState) => ({
                ...prevState,
                advance_id: response.data
            }))
        }
    }

    const getAdvancepaymentDetails = async () => {
        var response = await getAxios({
            url: AdvancepaymentEndPoint + localStorage.getItem('advancepayment_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                advance_id: response.data?.advance_id,
                customer: response.data?.customer,
                advance_amount: response.data?.advance_amount,
                advance_weight: response.data?.advance_weight,
                payment_date: response.data?.payment_date,
                redeem_amount: response.data?.redeem_amount,
                advance_purpose: response.data?.advance_purpose,
            }

            setCurrentAdvancepayment(tempDetails)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !optionalFields.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(formDataObject, optionalFields)) {
            if (localStorage.getItem('advancepayment_form_mode') === 'create') {
                createAdvancepayment(currentAdvancepayment)
            } else {
                updateAdvancepayment(currentAdvancepayment)
            }
        }
    }

    const createAdvancepayment = async (body) => {
        var response = await postAxiosWithToken({
            url: AdvancepaymentEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updateAdvancepayment = async (body) => {
        var response = await putAxiosWithToken({
            url: AdvancepaymentEndPoint + localStorage.getItem('advancepayment_form_id') + "/",
            body: body
        })

        if (response != null) {
            localStorage.removeItem('advancepayment_form_id')
            localStorage.setItem('advancepayment_form_mode', 'create')
            toast.success(response.message)
            navigate(-1)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
        setCurrentAdvancepayment((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }


    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Advancepayment</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Advancepayment Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Advance Id</p>
                                <input
                                    type="text"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="advance_id"
                                    value={currentAdvancepayment.advance_id}
                                    readOnly
                                />
                            </div>

                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Customer</p>
                                <select
                                    placeholder="Customer"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="customer"
                                    value={currentAdvancepayment.customer}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select Customer--</option>
                                    {
                                        customerdropdown.map((option) => (
                                            <option className="capitalize" key={"customer" + option.customer_name} value={option.id}>{option.customer_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.customer}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Advance Amount</p>
                                <input
                                    type="text"
                                    placeholder="Advance Amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="advance_amount"
                                    value={currentAdvancepayment.advance_amount}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.advance_amount}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Advance Weight</p>
                                <input
                                    type="text"
                                    placeholder="Advance Weight"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="advance_weight"
                                    value={currentAdvancepayment.advance_weight}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.advance_weight}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Payment Date</p>
                                <input
                                    type="date"
                                    placeholder="Payment Date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="payment_date"
                                    value={currentAdvancepayment.payment_date}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.payment_date}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Redeem Amount</p>
                                <input
                                    type="text"
                                    placeholder="Redeem Amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="redeem_amount"
                                    value={currentAdvancepayment.redeem_amount}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.redeem_amount}</p>
                            </div>
                            {/* <div className="my-[5px] flex items-center gap-5 mt-8">
                                <p className="mb-[5px] text-[15px]">Is Redeem</p>
                                <input
                                    type="checkbox"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                                    name="is_redeem"
                                    checked={currentAdvancepayment.is_redeem}
                                />
                            </div> */}
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Advance Purpose</p>
                                <select
                                    placeholder="advancepayment group"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="advance_purpose"
                                    value={currentAdvancepayment.advance_purpose}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select --</option>
                                    {
                                        advancepurposedropdown?.map((option) => (
                                            <option className="capitalize" key={"advance_purpose" + option.purpose_name} value={option.id}>{option.purpose_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.advance_purpose}</p>
                            </div>

                        </div>
                    </div>



                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 mt-[20px]">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}