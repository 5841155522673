import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { locationEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function LocationForm({ getData, formMode, currentLocation, setFormMode, setCurrentLocation }) {

    const schema = yup
        .object({
            location_name: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            location_name: currentLocation.location_name
        } : {
            location_name: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createLocation(data)
            } else {
                updateLocation(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createLocation = async (body) => {
        var response = await postAxiosWithToken({
            url: locationEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateLocation = async (body) => {
        var response = await putAxiosWithToken({
            url: locationEndPoint + currentLocation?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentLocation({ location_name: '' })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg border shadow">
            <p className="text-menu-head font-[600] text-[18px]">{formMode} Location</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Location Name</p>
                        <input
                            type="text"
                            placeholder="location name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="location_name"
                            id="forminit"
                            {...register('location_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.location_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}