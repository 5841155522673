import { useNavigate, useParams } from "react-router-dom";
import ViewActionButtons from "./viewactionbuttons";
import { useEffect, useRef, useState } from "react";
import ViewParticulars from "./viewparticualrs";
import { getAxios } from "../../../../service/apiservice";
import { requisitionApprovalEndPoint } from "../../../../service/api";
import RequisitionApprovalPrint from "../../../../components/Printouts/RequisitionApprovalPrint";

export default function ViewRequisitionAproval() {

    let componentRef = useRef();

    const { id } = useParams();

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({})
    const [particularsList, setParticularsList] = useState([]);

    useEffect(() => {
        getApprovalDetails();
    },[])

    const getApprovalDetails = async () => {
        var response = await getAxios({
            url: requisitionApprovalEndPoint + id + '/'
        })

        if (response != null) {
            var shopAddress = response.data.shop_address
            try {
                delete shopAddress.country;
                delete shopAddress.state;
            } catch (err) {

            }

            var tempDetails = {
                approval_issue_id: response.data.approval_issue_id,
                issue_date: response.data.issue_date,
                issued_by: response.data.issued_by,
                receiver_name: response.data.receiver_name,
                shop_name: response.data.shop_name,
                branch: response.data.branch,
                notes: response.data.notes,
                shop_address: Object.values(shopAddress).join(',')
            }
            setParticularsList(response.data.issued_tag_details)
            setFormValues(tempDetails)
        }
    }

    return (
        <div>
            <div className="flex justify-between items-center gap-3">
                <p className="font-[600] text-[20px]">Order Details</p>
                <div className="flex gap-3 items-center">
                    <button

                        onClick={(e) => { e.preventDefault(); navigate(-1) }}
                        className="capitalize border border-primary-btn text-primary-btn w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none"
                    >Back</button>
                    <button

                        onClick={(e) => { e.preventDefault(); navigate('/stock/approval-issue/requisition-approval-update/' + id) }}
                        className="capitalize bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >Edit</button>
                    <ViewActionButtons componentRef={componentRef} />
                </div>
            </div>

            <div className="p-3 rounded-lg border-2 bg-white my-6">
                <p className="font-[500] text-[15px] mb-[5px]">Transfer Details</p>
                <div className="grid grid-cols-6 ">
                    <div>
                        <p className="text-[13px]">Requisition ID</p>
                        <p>{formValues.approval_issue_id}</p>
                    </div>
                    <div>
                        <p className="text-[13px]">Date</p>
                        <p>{formValues.issue_date}</p>
                    </div>
                    <div>
                        <p className="text-[13px]">Receiver Name</p>
                        <p>{formValues.receiver_name}</p>
                    </div>
                    <div>
                        <p className="text-[13px]">Shop Name</p>
                        <p>{formValues.shop_address}</p>
                    </div>
                </div>
                <div className="mt-[10px]">
                    <p className="font-[500] text-[15px] mb-[5px">Requisition Item Details</p>
                    <ViewParticulars items={particularsList} />
                </div>
            </div>
            <div id="download" style={{display: 'none'}}>
                <div className="mb-[25px]" ref={componentRef}>
                    <RequisitionApprovalPrint 
                    particulars={particularsList}
                    detials={formValues}
                    />
                </div>
            </div>
        </div>
    );
}