import { useEffect, useState } from "react";
import { ifscVerifyEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { Country, State } from "country-state-city";
import { defaultCountry, defaultState } from "../../../constant";

export const getStateDropdown = (value) => {
    var stateTempList = []
    try {
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
    } catch (err) {

    }
    return stateTempList
}

export default function AccountAddressForm({ dataList = [], setDataList, removedItems, setRemovedItems }) {

    const [ran, setRan] = useState(0);

    useEffect(() => {
        if (localStorage.getItem('accounthead_form_mode') === 'update') {
            if (dataList?.length === 0) {
                addAddressDetails();
            }
        } else {
            if (dataList?.length === 0) {
                addAddressDetails();
            }
        }
    }, []);

    const addAddressDetails = () => {

        const data = {
            door_no: "",
            street_name: "",
            area: "",
            taluk: "",
            postal: "",
            district: "",
            pin_code: "",
            country: defaultCountry,
            state: defaultState,
            stateDropDown: getStateDropdown(defaultCountry)
        };

        setDataList([...dataList, data]);
    };

    const onValueUpdate = (text, index) => {
        const { name, value } = text
        const data = [...dataList];
        data[index][name] = value

        if (name === 'country') {
            data[index]['stateDropDown'] = getStateDropdown(value)
        }
        setDataList(data)
    }



    const onRemoveDetails = (index) => {
        if (dataList?.length > 1) {
            const data = [...dataList];
            if (data[index].id !== undefined) {
                removedItems.push(data[index].id)
            }
            data.splice(index, 1);
            setDataList(data);
        }

    }


    return (
        <>
            <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>

                <ListDetails rows={dataList} onValueUpdate={onValueUpdate} onRemoveDetails={onRemoveDetails} />

                <button onClick={(e) => { e.preventDefault(); addAddressDetails() }} className="flex gap-3 text-primary-btn justify-end w-full my-[10px] mt-[30px] font-[600] text-[17px]">
                    <span className="bg-primary-btn text-white rounded-lg w-[25px]">+</span>
                    <p>Add Address details</p>
                </button>
            </div>
        </>
    );
}

function ListDetails({
    rows,
    onValueUpdate,
    onRemoveDetails
}) {

    const [countryDropdown, setCountryDropdown] = useState([]);
    useEffect(() => {

    }, [rows])
    useEffect(() => {
        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)
    }, [])

    return rows.map((item, index) => {
        const {
            door_no,
            street_name,
            area,
            taluk,
            postal,
            district,
            pin_code,
            country,
            state,
            stateDropDown
        } = item;
        return <div key={"address" + index} className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-x-5  p-[30px] my-[10px] rounded-xl relative">
            {
                rows.length > 1 ? <button onClick={(e) => { e.preventDefault(); onRemoveDetails(index) }} className="flex gap-3 text-red-600 justify-end w-full my-[10px] font-[600] text-[17px] absolute right-8">
                    <span className="bg-red-600 text-white rounded-lg w-[25px]">-</span>
                    <p>Remove Address details</p>
                </button> : ""
            }


            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Door No</p>
                <input
                    type="text"
                    placeholder="door no"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="door_no"
                    value={door_no}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Street Name</p>
                <input
                    type="text"
                    placeholder="street name"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="street_name"
                    value={street_name}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Area</p>
                <input
                    type="text"
                    placeholder="Area"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="area"
                    value={area}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Taluk</p>
                <input
                    type="text"
                    placeholder="Taluk"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="taluk"
                    value={taluk}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Postal</p>
                <input
                    type="text"
                    placeholder="Postal"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="postal"
                    value={postal}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">District</p>
                <input
                    type="text"
                    placeholder="District"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="district"
                    value={district}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Pincode</p>
                <input
                    type="text"
                    placeholder="Pincode"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[2px] text-[17px] outline-none"
                    name="pin_code"
                    value={pin_code}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Country</p>
                <select
                    placeholder="country"
                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                    name="country"
                    value={country}
                    onChange={(e) => { onValueUpdate(e.target, index); }}
                >
                    <option value={""}>--select country--</option>
                    {
                        countryDropdown.map((option) => (
                            <option className="capitalize" key={"country" + option.name + index} value={JSON.stringify(option)}>{option.name}</option>
                        ))
                    }
                </select>
            </div>
            <div className="my-[5px]">
                    <p className="mb-[5px] text-[16px]">State</p>
                    <select
                        placeholder="state"
                        className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                        name="state"
                        value={state}
                        onChange={(e) => {onValueUpdate(e.target, index);}}

                    >
                        <option value={""}>--select state--</option>
                        {
                            stateDropDown?.map((option) => (
                                <option className="capitalize" key={"state" + option.name + index} value={JSON.stringify(option)}>{option.name}</option>
                            ))
                        }
                    </select>
                </div>
        </div>
    })
}