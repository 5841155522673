import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { metalEndPoint, metalListEndPoint, metalStatusEndPoint, paymentViewEndPoint, repairManagementEndPoint, repairManagementcreateEndPoint, valueAddtionCustomerEndPoint, valueAddtionCustomerListEndPoint, valueadditionEndPoint, valueadditionListEndPoint, valueadditionStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { datetimetodate } from "../../../utilities/datetime";
// import ValueadditionFilters from "./valueadditioncustomerfilter";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import PaymentDetails from "./widgets/paymentdetails";
import AddPayments from "./widgets/addpayments";
import { cash, goldMetal, repairpayment, silverMetal } from "../../../constant";
import { isValidForm } from "../../../utilities/formvalidation";

export default function Repaircreation() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [ValueadditionList, setValueadditionList] = useState([]);
    const [filteredValueadditionList, setFilteredValueadditionList] = useState([]);
    const [currentValueaddition, setCurrentValueaddition] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [repairList, setRepairList] = useState([]);

    const { actions, pagination } = useSelector((state) => state.appConfig);

    const [Paymentcreate, setPaymentcreate] = useState(false);
    const [CurrentpymentDetails, setCurrentpymentDetails] = useState({})
    const [paymentScreen, setPaymentScreen] = useState(false);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        page: 1,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null



    });


    const [paymentDetailValues, setPaymentDetailValues] = useState({
        order_id: "",
        gst_type: 1,
        igst: 3,
        sgst: 1.5,
        cgst: 1.5
    });

    const [paymentMethodDropDown, setPaymentMethodDropDown] = useState([]);

    const [grandTotal, setGrandTotal] = useState(0.0);
    const [advanceAmount, setAdvanceAmount] = useState(0.0);
    const [balanceAmount, setBalanceAmount] = useState(0.0);
    const [oldMetalList, setOldMetalList] = useState([]);

    const [formMode, setFormMode] = useState('create');

    const [particularsList, setParticularsList] = useState([]);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        getRepairListData(tableFilters)
    }, [tableFilters])

    const getRepairListData = async (body) => {
        var response = await postAxiosWithToken({
            url: repairManagementEndPoint,
            body: body
        })

        if (response !== null) {
            setRepairList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }




    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getValueadditionListData({})
    }, [])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('valueaddition-customer-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    const getValueadditionListData = async (body) => {
        var response = await postAxiosWithToken({
            url: valueAddtionCustomerListEndPoint,
            body: body
        })

        if (response !== null) {
            setValueadditionList(response.data.list)
            setIsLoading(false)
        }
    }

    const deleteValueaddition = async () => {
        var response = await deleteAxiosWithToken({
            url: repairManagementcreateEndPoint + currentValueaddition.id + '/'
        })

        if (response !== null) {
            getValueadditionListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }





    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };



    const getRepairpaymentDetails = async (id) => {
        var response = await getAxios({
            url: repairManagementcreateEndPoint + id + '/'
        })

        if (response != null) {
            let tempdetails = {
                estimate_charge: response.data.repair_order_Details.payment_details?.estimate_charge,
                repair_number: response.data.repair_order_Details.repair_number,
                customer_details: response.data.repair_order_Details.customer_details,
                discount_percentage: response.data.repair_order_Details.payment_details?.discount_percentage,
                discount_amount: response.data.repair_order_Details.payment_details?.discount_amount,
                round_off: response.data.repair_order_Details.payment_details?.round_off,
                gst_type: response.data.repair_order_Details.payment_details?.gst_type,
                igst: response.data.repair_order_Details.payment_details?.igst,
                sgst: response.data.repair_order_Details.payment_details?.sgst,
                cgst: response.data.repair_order_Details.payment_details?.cgst,
                others: response.data.repair_order_Details.payment_details?.others,
                stone_amount: response.data.repair_order_Details.payment_details?.stone_amount,
                diamond_amount: response.data.repair_order_Details.payment_details?.diamond_amount,
                making_charge_per_gram: response.data.repair_order_Details.payment_details?.making_charge_per_gram,
                flat_making_charge: response.data.repair_order_Details.payment_details?.flat_making_charge,
                advance_old_gold_amount: response.data.repair_order_Details.payment_details?.advance_old_gold_amount,
                advance_old_silver_amount: response.data.repair_order_Details.payment_details?.advance_old_silver_amount,
                advance_amount: response.data.repair_order_Details.payment_details?.advance_amount,
                balance_amount: response.data.repair_order_Details.payment_details?.balance_amount,

            }



            setCurrentpymentDetails(tempdetails)
            // setCurrentpymentDetails(response.data)

        }
    }


    const handleRepairPaymentSubmit = async () => {

        console.log("hiiiiii")

        let paymentlist = []

        for (var i of paymentMethodDropDown) {


            var temdata = {
                "customer_details": CurrentpymentDetails.customer_details,
                "payment_method": i.id,
                "payment_provider": i.id === cash ? null : document.getElementById(i.payment_method_name + "provider")?.value,
                "payment_module": repairpayment,
                "paid_amount": document.getElementById(i.payment_method_name)?.value || null,
                "payment_refference_number": document.getElementById(i.payment_method_name + "ref_number")?.value || null,
                "refference_number": CurrentpymentDetails.repair_number
            }
            paymentlist.push(temdata)

        }

        let data = {
            refference_number: CurrentpymentDetails.repair_number,
            igst: CurrentpymentDetails.igst,
            sgst: CurrentpymentDetails.sgst,
            cgst: CurrentpymentDetails.cgst,
            customer_details: CurrentpymentDetails.customer_details,
            gst_type: CurrentpymentDetails.gst_type,
            round_off: CurrentpymentDetails.round_off,
            estimate_charge: CurrentpymentDetails?.estimate_charge,
            discount_percentage: CurrentpymentDetails?.discount_percentage,
            discount_amount: CurrentpymentDetails.discount_amount,
            round_off: CurrentpymentDetails.round_off,
            advance_old_gold_amount: CurrentpymentDetails.advance_old_gold_amount,
            advance_old_silver_amount: CurrentpymentDetails.advance_old_silver_amount,
            grand_total: grandTotal,
            advance_amount: advanceAmount,
            balance_amount: balanceAmount,

            payment_particulars: paymentlist
        };



        if (formMode == 'create') {
            createPaymentOrder(data)
            console.log(">>>>>>>>>>> data", data)
        }

    };


    const createPaymentOrder = async (body) => {
        console.log(">>>>>>>>> body", body)
        var response = await postAxiosWithToken({

            url: paymentViewEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            setPaymentcreate(true)
        }
    }


    useEffect(() => {
        setBalanceAmount(grandTotal - (advanceAmount || 0))
    }, [advanceAmount, grandTotal])


    useEffect(() => {
        let otherAmt = parseFloat(CurrentpymentDetails.others || 0);
        let gstAmt = parseFloat(CurrentpymentDetails.gst_amount || 0);
        let totalAmt = parseFloat(CurrentpymentDetails?.approximate_amount || 0);
        let makingChargePerGramAmt = parseFloat(CurrentpymentDetails.total_weight || 0) * parseFloat(CurrentpymentDetails.making_charge_pergram || 0);
        let flatMCAmt = parseFloat(CurrentpymentDetails.flat_making_charge || 0);
        let roundOffAmt = parseFloat(CurrentpymentDetails.round_off || 0);
        let oldGoldAmt = parseFloat(CurrentpymentDetails.advance_old_gold_amount || 0);
        let oldSilverAmt = parseFloat(CurrentpymentDetails.advance_old_silver_amount || 0);
        let discountAmt = parseFloat(CurrentpymentDetails.discount_amount || 0);
        setGrandTotal(totalAmt + gstAmt + otherAmt + makingChargePerGramAmt + flatMCAmt + roundOffAmt - (oldGoldAmt + oldSilverAmt + discountAmt))
    }, [CurrentpymentDetails])


    useEffect(() => {
        let silverRate = 0.0;
        let goldRate = 0.0;
        for (var i of oldMetalList) {
            if (i.metal == goldMetal) {
                goldRate = goldRate + parseFloat(i.total)
            } else if (i.metal == silverMetal) {
                silverRate = silverRate + parseFloat(i.total)
            }
        }
        setPaymentDetailValues((prevState) => ({
            ...prevState,
            advance_old_gold_amount: goldRate,
            advance_old_silver_amount: silverRate
        }))
    }, [oldMetalList])


    useEffect(() => {
        let totalQuantity = particularsList.length;
        let totalWeight = 0.0;
        let totalAmount = 0.0;
        let totalStoneAmt = 0.0;
        let totalDiamondAmt = 0.0;
        for (var i of particularsList) {
            totalWeight = totalWeight + parseFloat(i.gross_weight)
            totalAmount = totalAmount + parseFloat(i.total_amount)
            totalStoneAmt = totalStoneAmt + parseFloat(i.total_stone_amount)
            totalDiamondAmt = totalDiamondAmt + parseFloat(i.total_diamond_amount)
        }

        setCurrentpymentDetails((prevState) => ({
            ...prevState,
            total_quantity: totalQuantity,
            total_weight: totalWeight,
            approximate_amount: totalAmount
        }))


        let gstPercent = 0;
        if (paymentDetailValues.gst_type == 1) {
            gstPercent = paymentDetailValues.cgst + paymentDetailValues.cgst
        } else if (paymentDetailValues.gst_type == 2) {
            gstPercent = paymentDetailValues.igst
        }

        let gstAmount = totalAmount * (gstPercent / 100)
        setPaymentDetailValues((prevState) => ({
            ...prevState,
            approximate_amount: totalAmount,
            stone_amount: totalStoneAmt,
            diamond_amount: totalDiamondAmt,
            gst_amount: gstAmount
        }))
        setGrandTotal(totalAmount + gstAmount)
    }, [particularsList])

    return (
        <>
            <div>
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            // if (actions.addPermit) {
                            localStorage.setItem('repair_creation_form_mode', 'create'); navigate('repair-creation-form')
                            // } else {
                            // toast.error("You don't have permission for add")
                            // }
                        }}
                        className="w-[350px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Repair Creation<span className="lowercase">(ctrl+m)</span></button>
                </div>
                {/* <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <ValueadditionFilters searchRef={searchRef} getData={getValueadditionListData} dataList={ValueadditionList} setFilteredData={setFilteredValueadditionList} />
                </div> */}
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Repair ID</td>
                                <td className="p-[10px]">Repair For</td>
                                <td className="p-[10px]">Customer Name</td>
                                <td className="p-[10px]">Metal Type</td>
                                <td className="p-[10px]">Gross Wt</td>
                                <td className="p-[10px]">Net Wt</td>
                                <td className="p-[10px]">Pieces</td>
                                <td className="p-[10px]">Vendor  Name</td>
                                <td className="p-[10px]">Status</td>
                                <td className="p-[10px]">Received date</td>
                                <td className="p-[10px]">Est.D date</td>
                                <td className="p-[10px]">Customer Charges</td>
                                <td className="p-[10px]">Vendor Charges</td>
                                <td className="p-[10px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={12}>Loading...</td>
                                </tr> :
                                    repairList.length === 0 ? <tr><td className="text-center border" colSpan={12}>No Data</td></tr> :
                                        repairList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize">{item.repair_number}</td>
                                                <td className="p-[10px] capitalize">{item.repair_for_name}</td>
                                                <td className="p-[10px] capitalize">{item.customer_name}</td>
                                                <td className="p-[10px] capitalize">{item.metal}</td>
                                                <td className="p-[10px]">{item.gross_weight}</td>
                                                <td className="p-[10px]">{item.net_weight} </td>
                                                <td className="p-[10px]">{item.pieces}</td>
                                                <td className="p-[10px]">{item.vendor_name}</td>
                                                <td className="p-[10px]">{item.status}</td>
                                                <td className="p-[10px]">{item.repair_recived_date}</td>
                                                <td className="p-[10px]">{item.est_repair_delivery_date}</td>
                                                <td className="p-[10px]">{item.customer_charges}</td>
                                                <td className="p-[10px]">{item.vendor_charges}</td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => { localStorage.setItem('repair_creation_form_mode', 'update'); navigate('repair-creation-form/' + item.id) }}
                                                            className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setPaymentScreen(true)
                                                                getRepairpaymentDetails(item.id);
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="flex items-center gap-3 cursor-pointer hover:scale-[105%]" id="print">
                                                            <div className="w-6">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-width="2" d="M16 16c0-1.105-3.134-2-7-2s-7 .895-7 2s3.134 2 7 2s7-.895 7-2ZM2 16v4.937C2 22.077 5.134 23 9 23s7-.924 7-2.063V16M9 5c-4.418 0-8 .895-8 2s3.582 2 8 2M1 7v5c0 1.013 3.582 2 8 2M23 4c0-1.105-3.1-2-6.923-2c-3.824 0-6.923.895-6.923 2s3.1 2 6.923 2S23 5.105 23 4Zm-7 12c3.824 0 7-.987 7-2V4M9.154 4v10.166M9 9c0 1.013 3.253 2 7.077 2C19.9 11 23 10.013 23 9" /></svg>
                                                            </div>
                                                            {/* <p className="text-md text-[#444444]">Payment</p> */}
                                                        </div>
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentValueaddition(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>



                <div className={`${paymentScreen ? 'translate-y-0' : 'translate-y-[100%]'} transition ease-in-out fixed bottom-[30px] z-[5] left-0 w-full p-5 bg-white border-t`}>
                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={() => setPaymentScreen(false)}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >close<span className="lowercase">(esc)</span></button>

                        <button
                            onClick={(e) => { e.preventDefault(); handleRepairPaymentSubmit() }}
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                    <div className="flex gap-3 mt-4">
                        <div className="w-[35%]">
                            <PaymentDetails setFormValues={setPaymentDetailValues} readMode={true} grandTotal={grandTotal} formValues={CurrentpymentDetails} setGrandTotal={setGrandTotal} setParticularsList={setParticularsList} />
                        </div>

                        <div className="w-[15%] ">
                            <div className="bg-[#E2E8F0] rounded-lg p-[15px] h-full">
                                <div>
                                    <p>Grand Total</p>
                                    <p>₹ {grandTotal}</p>
                                </div>
                                <div className="mt-3">
                                    <p>Advance</p>
                                    <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[5px] border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                                        defaultValue={advanceAmount || CurrentpymentDetails.advance_amount}
                                        onChange={(e) => setAdvanceAmount(parseFloat(e.target.value))}
                                    />
                                </div>
                                <div className="mt-3">
                                    <p>Balance Amount</p>
                                    <p>₹ {balanceAmount}</p>
                                </div>
                            </div>
                        </div>

                        <div className="w-[50%]">
                            <AddPayments formValues={CurrentpymentDetails} setPaymentMethodDropDown={setPaymentMethodDropDown} paymentMethodDropDown={paymentMethodDropDown} />
                        </div>

                    </div>
                </div>



                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete Value Addition Customer'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the valueaddition?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteValueaddition() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}