export default function MetalRateCutList({ items, setItems }) {

    const removeItem = (index) => {
        let data = [...items];
        data.splice(index, 1);
        setItems(data)
    }
    
    return (
        <div className="w-full overflow-scroll">
            <table className="w-full">
                <thead className="bg-primary-btn text-white">
                    <tr>
                        <td className="p-2 border">S.No</td>
                        <td className="p-2 border">Metal Wt</td>
                        <td className="p-2 border">Calculation</td>
                        <td className="p-2 border">Pure Wt</td>
                        <td className="p-2 border">Dis(g)</td>
                        <td className="p-2 border"></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) => (
                            <tr>
                                <td className="p-2 border">{index + 1}</td>
                                <td className="p-2 border">{item.metal_weight}</td>
                                <td className="p-2 border">{item.pure_calculation}</td>
                                <td className="p-2 border">{item.pure_weight}</td>
                                <td className="p-2 border">{item.discount}</td>
                                <td className="p-2 border">
                                    <button
                                    onClick={(e) => { e.preventDefault(); removeItem(index) }}
                                    >
                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}