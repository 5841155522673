import { useEffect, useRef, useState } from "react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import OrderdetailsFilters from "./orderdetailsfilter";
import { useSelector } from "react-redux";
import { PrintSizeEndPoint, cancelOrderDetailsEndPoint, orderDetailsListEndPoint, orderEndPoint, paymentViewEndPoint } from "../../../service/api";
import TableImageSlider from "./widgets/tableimageslider";
import ActionButton from "./widgets/actionbutton";
import PaymentHistoryDetails from "./widgets/paymenthistorydetails";
import Pagination from "../../../components/Pagination";
import OrderReceiptPrint from "../../../components/Printouts/OrderReceiptPrint";
import ReactToPrint from "react-to-print";
import { ToWords } from 'to-words';
import AddPayments from "./widgets/addpayments";
import PaymentDetails from "./widgets/paymentdetails";
import OldMetalDetails from "./widgets/oldmetaldetails";
import { cash, goldMetal, orderForCustomer, repairpayment, silverMetal } from "../../../constant";
import { isValidForm } from "../../../utilities/formvalidation";

const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
            // can be used to override defaults for the selected locale
            name: 'Rupee',
            plural: 'Rupees',
            symbol: '₹',
            fractionalUnit: {
                name: 'Paisa',
                plural: 'Paise',
                symbol: '',
            },
        },
    },
});


export default function Orderdetails({ }) {

    let componentRef = useRef();

    const { id } = useParams();
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const searchRef = useRef();
    const [formMode, setFormMode] = useState('create');

    const [orderdetailsList, setOrderdetailsList] = useState([]);
    const [orderdetails, setOrderdetails] = useState({});
    const [orderpaymentdetails, setOrderpaymentdetails] = useState({});

    console.log(">>>>>>>>>>>>>>> orderpaymentdetails", orderpaymentdetails)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const [imagePopupOpen, setImagePopupOpen] = useState(false);

    const [paymentMethodDropDown, setPaymentMethodDropDown] = useState([]);
    const [oldMetalList, setOldMetalList] = useState([]);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        order_status: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });




    console.log(">>>>>>>>>>>>>> orderpaymentdetails", orderpaymentdetails)

    const [Paymentcreate, setPaymentcreate] = useState(false);

    const [images, setImages] = useState([]);

    const [printView, setPrintView] = useState(false)
    const [errors, setErrors] = useState({});

    const { actions, pagination } = useSelector((state) => state.appConfig);


    const [paymentScreen, setPaymentScreen] = useState(false);

    const [paymentDetailValues, setPaymentDetailValues] = useState({
        order_id: "",
        gst_type: 1,
        igst: 3,
        sgst: 1.5,
        cgst: 1.5
    });



    console.log(">>>>> paymentDetailValues", paymentDetailValues)

    const [grandTotal, setGrandTotal] = useState(0.0);
    const [advanceAmount, setAdvanceAmount] = useState(0.0);
    const [balanceAmount, setBalanceAmount] = useState(0.0);
    console.log(">>>>>>>>>> grandTotal", grandTotal)

    const [oldMetalOpen, setOldMetalOpen] = useState(false);

    const [particularsList, setParticularsList] = useState([]);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    function closeImageModal() {
        setImagePopupOpen(false)
    }

    function closePrintModal() {
        setPrintView(false)
    }

    useEffect(() => {
        getOrderdetailsListData()
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('orderdetails-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);





    const getOrderdetailsListData = async () => {
        var response = await postAxiosWithToken({
            url: orderDetailsListEndPoint,
            body: tableFilters
        })

        if (response !== null) {
            setOrderdetailsList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const cancelOrder = async () => {
        var response = await getAxios({
            url: cancelOrderDetailsEndPoint + orderdetails.id + "/"
        })

        if (response != null) {
            toast.success(response.message)
            setIsDeleteOpen(false)
            getOrderdetailsListData()
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };



    useEffect(() => {
        let otherAmt = parseFloat(orderpaymentdetails.others || 0);
        let gstAmt = parseFloat(orderpaymentdetails.gst_amount || 0);
        let totalAmt = parseFloat(orderpaymentdetails?.approximate_amount || 0);
        let makingChargePerGramAmt = parseFloat(orderdetails.total_weight || 0) * parseFloat(orderpaymentdetails.making_charge_pergram || 0);
        let flatMCAmt = parseFloat(orderpaymentdetails.flat_making_charge || 0);
        let roundOffAmt = parseFloat(orderpaymentdetails.round_off || 0);
        let oldGoldAmt = parseFloat(orderpaymentdetails.advance_old_gold_amount || 0);
        let oldSilverAmt = parseFloat(orderpaymentdetails.advance_old_silver_amount || 0);
        let discountAmt = parseFloat(orderpaymentdetails.discount_amount || 0);
        setGrandTotal(totalAmt + gstAmt + otherAmt + makingChargePerGramAmt + flatMCAmt + roundOffAmt - (oldGoldAmt + oldSilverAmt + discountAmt))
    }, [orderpaymentdetails])


    useEffect(() => {
        let silverRate = 0.0;
        let goldRate = 0.0;
        for (var i of oldMetalList) {
            if (i.metal == goldMetal) {
                goldRate = goldRate + parseFloat(i.total)
            } else if (i.metal == silverMetal) {
                silverRate = silverRate + parseFloat(i.total)
            }
        }
        setPaymentDetailValues((prevState) => ({
            ...prevState,
            advance_old_gold_amount: goldRate,
            advance_old_silver_amount: silverRate
        }))
    }, [oldMetalList])


    useEffect(() => {
        let totalQuantity = particularsList.length;
        let totalWeight = 0.0;
        let totalAmount = 0.0;
        let totalStoneAmt = 0.0;
        let totalDiamondAmt = 0.0;
        for (var i of particularsList) {
            totalWeight = totalWeight + parseFloat(i.gross_weight)
            totalAmount = totalAmount + parseFloat(i.total_amount)
            totalStoneAmt = totalStoneAmt + parseFloat(i.total_stone_amount)
            totalDiamondAmt = totalDiamondAmt + parseFloat(i.total_diamond_amount)
        }

        setOrderpaymentdetails((prevState) => ({
            ...prevState,
            total_quantity: totalQuantity,
            total_weight: totalWeight,
            approximate_amount: totalAmount
        }))


        let gstPercent = 0;
        if (paymentDetailValues.gst_type == 1) {
            gstPercent = paymentDetailValues.cgst + paymentDetailValues.cgst
        } else if (paymentDetailValues.gst_type == 2) {
            gstPercent = paymentDetailValues.igst
        }

        let gstAmount = totalAmount * (gstPercent / 100)
        setPaymentDetailValues((prevState) => ({
            ...prevState,
            approximate_amount: totalAmount,
            stone_amount: totalStoneAmt,
            diamond_amount: totalDiamondAmt,
            gst_amount: gstAmount
        }))
        setGrandTotal(totalAmount + gstAmount)
    }, [particularsList])



    function closeOldMetalModal() {
        setOldMetalOpen(false)
    }


    useEffect(() => {
        setBalanceAmount(grandTotal - (advanceAmount || 0))
    }, [advanceAmount, grandTotal])

    console.log(orderpaymentdetails, "orderpaymentdetailsorderpaymentdetails")


    const handlePaymentSubmit = () => {



        let paymentlist = []

        for (var i of paymentMethodDropDown) {


            var temdata = {
                "customer_details": orderpaymentdetails.customer,
                "payment_method": i.id,
                "payment_provider": i.id === cash ? null : document.getElementById(i.payment_method_name + "provider")?.value,
                "payment_module": repairpayment,
                "paid_amount": document.getElementById(i.payment_method_name)?.value || null,
                "payment_refference_number": document.getElementById(i.payment_method_name + "ref_number")?.value || null,
                "refference_number": orderpaymentdetails.order_id
            }
            paymentlist.push(temdata)

        }

        console.log(">>>>>>> i", paymentlist)
        let data = {
            refference_number: orderpaymentdetails.order_id,
            estimate_charge: orderpaymentdetails.approximate_amount,
            igst: orderpaymentdetails.igst,
            sgst: orderpaymentdetails.sgst,
            cgst: orderpaymentdetails.cgst,
            gst_type: orderpaymentdetails.gst_type,
            discount_amount: orderpaymentdetails.discount_amount,
            discount_percentage: orderpaymentdetails.discount_percentage,
            advance_old_gold_amount: orderpaymentdetails.advance_old_gold_amount,
            advance_old_silver_amount: orderpaymentdetails.advance_old_silver_amount,
            round_off: orderpaymentdetails.round_off,
            grand_total: grandTotal,
            advance_amount: advanceAmount,
            balance_amount: balanceAmount,
            payment_details: paymentlist,
        }




        if (formMode == 'create') {
            createPaymentOrder(data)
            console.log(">>>>>>>>>>> data", data)
        }


    }

    const createPaymentOrder = async (body) => {
        console.log(">>>>>>>>> body", body)
        var response = await postAxiosWithToken({

            url: paymentViewEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            setPaymentcreate(true)
        }
    }


    const [printsize, setPrintSize] = useState([]);

    useEffect(() => {
        getPrintSizeData();
    }, [])

    const getPrintSizeData = async () => {

        var response = await getAxios({
            url: PrintSizeEndPoint
        })

        if (response !== null) {
            setPrintSize(response.data.list)
        }
    }


    return (
        <>
            <div>

                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            if (actions.addPermit) {
                                navigate('orderdetails-create')
                            } else {
                                toast.error("You don't have permission for edit")
                            }
                        }}
                        className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Orderdetails <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <OrderdetailsFilters filters={tableFilters} setFilters={setTableFilters} searchRef={searchRef} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5 bg-white">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px] min-w-[150px]">ORDER ID</td>
                                <td className="p-[10px] text-center">QTY</td>
                                <td className="p-[10px] min-w-[100px] text-center">QTY Issued</td>
                                <td className="p-[10px] min-w-[100px] text-center">QTY Received</td>
                                <td className="p-[10px] min-w-[100px] text-center">Total WT</td>
                                <td className="p-[10px] min-w-[150px]">Order Date</td>
                                <td className="p-[10px] min-w-[150px]">Due date</td>
                                <td className="p-[10px] min-w-[150px] text-center">Order Status</td>
                                <td className="p-[10px] min-w-[150px] text-center">Attachments</td>
                                <td className="p-[10px] text-center sticky right-0 bg-table-head">Action</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={15}>Loading...</td>
                                </tr> :
                                    orderdetailsList.length === 0 ? <tr><td className="text-center border" colSpan={15}>No Data</td></tr> :
                                        orderdetailsList?.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">
                                                    <Link to={'view-orderdetails/' + item.id}>
                                                        <span className="text-blue-600">{item.order_no}</span>
                                                    </Link>
                                                </td>
                                                <td className="p-[10px] capitalize text-center">{item.total_quantity}</td>
                                                <td className="p-[10px] capitalize text-center">{item.issued_quantity}</td>
                                                <td className="p-[10px] capitalize text-center">{item.recieved_quantity}</td>
                                                <td className="p-[10px] capitalize text-center">{item.total_weight}</td>
                                                <td className="p-[10px] capitalize">{item.order_date}</td>
                                                <td className="p-[10px] capitalize">{item.due_date}</td>
                                                <td className="p-[10px] capitalize">
                                                    <div className="px-5 py-2 rounded-full text-white text-center" style={{ backgroundColor: item.order_status_color }} >{item.order_status_name}</div>
                                                </td>
                                                <td className="p-[10px] text-center">
                                                    {item.attachements.length > 0 ? <div onClick={() => { setImagePopupOpen(true); setImages(item.attachements) }} className="text-blue-600 flex gap-3 items-center cursor-pointer w-fit mx-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M18.71 17.565a4.25 4.25 0 0 0 0-6.01l-6.54-6.54A1 1 0 0 1 13.584 3.6l6.54 6.54a6.25 6.25 0 1 1-8.838 8.84l-7.954-7.955A4.501 4.501 0 0 1 9.698 4.66l7.953 7.953a2.752 2.752 0 0 1-3.892 3.891L6.513 9.257a1 1 0 0 1 1.414-1.415l7.247 7.247a.751.751 0 0 0 1.063-1.062L8.284 6.074A2.501 2.501 0 0 0 4.746 9.61l7.954 7.954a4.25 4.25 0 0 0 6.01 0Z" /></g></svg>
                                                        <p>Images</p>
                                                    </div> : <p>No attachments</p>}
                                                </td>
                                                <td className="p-[10px] text-center sticky right-0 bg-white">
                                                    <ActionButton
                                                        item={item}
                                                        setIsDeleteOpen={setIsDeleteOpen}
                                                        setCurrentDetails={setOrderdetails}
                                                        setPrintView={setPrintView}
                                                        setPaymentScreen={setPaymentScreen}
                                                        setCurrentpymentDetails={setOrderpaymentdetails}


                                                    ></ActionButton>
                                                </td>
                                            </tr>
                                        ))
                            }
                        </tbody>
                    </table>
                </div>


                <div className={`${paymentScreen ? 'translate-y-0' : 'translate-y-[100%]'} transition ease-in-out fixed bottom-[30px] z-[5] left-0 w-full p-5 bg-white border-t`}>
                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={() => setPaymentScreen(false)}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >close<span className="lowercase">(esc)</span></button>

                        <button
                            onClick={(e) => { e.preventDefault(); handlePaymentSubmit() }}
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                    <div className="flex gap-3 mt-4">
                        <div className="w-[35%]">
                            <PaymentDetails setFormValues={setPaymentDetailValues} readMode={true} grandTotal={grandTotal} formValues={orderpaymentdetails} setGrandTotal={setGrandTotal} setParticularsList={setParticularsList} />
                        </div>

                        <div className="w-[15%] ">
                            <div className="bg-[#E2E8F0] rounded-lg p-[15px] h-full">
                                <div>
                                    <p className="text-[13px]">Grand Total</p>
                                    <p className="text-[13px]">₹ {grandTotal}</p>
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px]">Advance</p>
                                    <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[2px] border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                                        defaultValue={advanceAmount || orderpaymentdetails.advance_amount}
                                        onChange={(e) => setAdvanceAmount(parseFloat(e.target.value))}
                                    />
                                </div>
                                <div className="mt-3">
                                    <p className="text-[13px]">Balance Amount</p>
                                    <p className="text-[13px]">₹ {balanceAmount}</p>
                                </div>
                            </div>
                        </div>

                        <div className="w-[50%]">
                            <AddPayments formValues={orderpaymentdetails} setPaymentMethodDropDown={setPaymentMethodDropDown} paymentMethodDropDown={paymentMethodDropDown} />
                        </div>

                    </div>
                </div>



                {pagination === true &&
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div>}
            </div>
            <div>
                <Popup title={'Delete Orderdetails'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to cancel the {orderdetails.orderdetails_number} orderdetails?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">No</button>
                            <button type="submit" onClick={() => { cancelOrder() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Yes</button>
                        </div>
                    </div>
                </Popup>
            </div>



            <Popup extraClassName="min-w-[800px] min-h-[500px]" title={'Attachments'} setIsOpen={setImagePopupOpen} isOpen={imagePopupOpen} closeModal={closeImageModal}>
                <TableImageSlider imagesList={images} />
            </Popup>

            <Popup extraClassName="min-w-full h-[600px] overflow-y-scroll" title={'Order Details'} setIsOpen={setPrintView} isOpen={printView} closeModal={closePrintModal}>
                <div className="mb-[25px]" ref={(el) => (componentRef = el)}>
                    <OrderReceiptPrint
                        printsize={printsize}
                        customerName={orderdetails.customer_name}
                        orderId={orderdetails.order_id}
                        orderDate={orderdetails.order_date}
                        orderFor={orderdetails.order_for}
                        expDelDate={orderdetails.due_date}
                        items={orderdetails.particular_list}
                        totalItems={orderdetails.total_quantity}
                        advAmt={orderdetails.advance_in_amount}
                        advInGram={orderdetails.advance_in_gram}
                        appAmt={orderdetails?.approximate_amount}
                        grandTotal={parseFloat(orderdetails?.approximate_amount) - parseFloat(orderdetails.total_advance_amount)}
                        balAmt={orderdetails.balance_amount}
                        amtInWords={toWords.convert(parseFloat(orderdetails?.approximate_amount ?? "0") - parseFloat(orderdetails.total_advance_amount ?? "0"), { currency: true })}
                    />

                </div>
                <ReactToPrint
                    trigger={() => <button id="print" className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none">
                        Print (ctrl+p)
                    </button>}
                    content={() => componentRef}
                />
            </Popup>

            <Popup extraClassName="min-w-[800px] max-h-[500px]" title={'Old Metal'} setIsOpen={setOldMetalOpen} isOpen={oldMetalOpen} closeModal={closeOldMetalModal}>
                <OldMetalDetails particularsList={oldMetalList} setParticularsList={setOldMetalList} formType={formMode} closeModal={closeOldMetalModal} />
            </Popup>


        </>
    );
}