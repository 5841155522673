import { useEffect, useState } from "react";
import { accountheadListEndPoint, branchListEndPoint, itemMasterListEndPoint, metalListEndPoint, tagListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { isValidForm } from "../../../utilities/formvalidation";

export default function ValueAdditionDesignForm({ setValueadditionDesignList, getData, setSelectedItem, setSelectedMetal, itemFormValues, setItemFormValues }) {

    const [designerList, setDesignerList] = useState([]);
    const [metalList, setMetalList] = useState([]);
    const [tagTypeList, setTagTypeList] = useState([]);
    const [itemList, setItemList] = useState([]);

    const [branchDropdown, setBranchDropDown] = useState([]);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getMetalListData();
        getDesignerListData();
        getTagTypeListData();
        getItemListData();
        getBranchDropdownData();
    }, [])

    const getMetalListData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalList(response.data.list)
        }
    }

    const getDesignerListData = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response !== null) {
            setDesignerList(response.data.list)
        }
    }

    const getTagTypeListData = async () => {
        var response = await getAxios({
            url: tagListEndPoint
        })

        if (response !== null) {
            setTagTypeList(response.data.list)
        }
    }

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list) 
        }
    }

    const getItemListData = async () => {
        var respose = await getAxios({
            url: itemMasterListEndPoint
        })

        if (respose !== null) {
            setItemList(respose.data.list)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isValidForm(formDataObject)) {
            getData()
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setItemFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        if (name === 'metal') {
            setSelectedMetal(value)
        } else if (name === 'item') {
            setSelectedItem(value)
        }

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length !== 0 ? '' : `${name} filed is required`
        }))
    }

    const resetForm = () => {
        setErrors({})
        setItemFormValues({
            designer: '',
            metal: '',
            tag_type: '',
            item: ''
        })
        setValueadditionDesignList([])
    }

    return (
        <div className="">
            <form onSubmit={handleSubmit}>
                <div className="grid xl:grid-cols-5 sm:grid-cols-2 gap-5">
                    {localStorage.getItem("branch") === "true" &&
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Branch Name</p>
                        <select
                            placeholder="branch name"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="branch"
                            onChange={onValueUpdate}
                            value={itemFormValues?.branch}
                            
                        >
                            <option value={" "} className="capitalize">--select branch name--</option>
                            {
                                branchDropdown.map((option) => (
                                    <option className="capitalize" key={option.id} value={option.id}>{option.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.branch_name?.message}</p>
                    </div>}
                    <div className="sm:my-[5px]">
                        <p className="mb-[5px] text-[15px]">Designer Name</p>
                        <select
                            placeholder="designer name"
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="designer"
                            onChange={onValueUpdate}
                            value={itemFormValues?.designer}
                        >
                            <option value={""}>--select designer name--</option>
                            {
                                designerList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.account_head_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.designer}</p>
                    </div>
                    <div className="sm:my-[5px]">
                        <p className="mb-[5px] text-[15px]">Metal Name</p>
                        <select
                            placeholder="metal name"
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="metal"
                            onChange={onValueUpdate}
                            value={itemFormValues?.metal}
                        >
                            <option value={""}>--select metal name--</option>
                            {
                                metalList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.metal_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                    </div>
                    <div className="sm:my-[5px]">
                        <p className="mb-[5px] text-[15px]">Tag Type</p>
                        <select
                            placeholder="tag type"
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="tag_type"
                            onChange={onValueUpdate}
                            value={itemFormValues?.tag_type}
                        >
                            <option value={""}>--select tag type--</option>
                            {
                                tagTypeList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.tag_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.tag_type}</p>
                    </div>
                    <div className="sm:my-[5px]">
                        <p className="mb-[5px] text-[15px]">Item</p>
                        <select
                            placeholder="item"
                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="item"
                            onChange={onValueUpdate}
                            value={itemFormValues?.item}
                        >
                            <option value={""}>--select item--</option>
                            {
                                itemList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.item_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.item}</p>
                    </div>
                    <div className="min-[423px]:flex justify-end gap-4 sm:mt-0 lg:mt-[33px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Submit <span className="lowercase">(enter)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); resetForm()}}
                            id="reset"
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Reset<span className="lowercase">(ctrl+z)</span></button>
                    </div>
                </div>
            </form>
        </div>
    )
}