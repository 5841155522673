import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { receivedTableListEndPoint, receivedTrasferEndPoint, returnTranferEndPoint, transferTableListEndPoint } from "../../../../service/api";
import toast from "react-hot-toast";

export default function ItemReturnForm() {

    const navigate = useNavigate();

    const today = new Date().toLocaleDateString('en-CA')
    const [formMode, setFormMode] = useState('create');
    const [errors, setErrors] = useState({});

    const [particulars, setParticulars] = useState([]);

    const [allChecked, setAllChecked] = useState(false)

    const [formValues, setFormValues] = useState({
        transfer_date: '',
        required_date: '',
        stock_authority: '',
        transfer_from: '',
        transfer_to: '',
        return_date: today,
        transfer_id: null,
        reason: ''
    });

    useEffect(() => {
        getTranferDetails();

        setFormMode(localStorage.getItem('branchreturn_form_mode'))
    }, []);

    useEffect(() => {
        if (particulars.length > 0) {
            let tempList = []
            for (var i of particulars) {
                let tempData = i;
                tempData['checked'] = allChecked;
                tempList.push(tempData);
            }

            setParticulars(tempList)
        }
    },[allChecked])

    const getTranferDetails = async () => {
        var response = await getAxios({
            url: receivedTableListEndPoint + localStorage.getItem('branchreturn_form_id') + '/'
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                transfer_date: response.data.received_item.transfer_itemid__transfer_date,
                required_date: response.data.received_item.transfer_itemid__required_date,
                stock_authority: response.data.received_item.transfer_itemid__stock_authority__first_name,
                transfer_from: response.data.received_item.transfer_itemid__transfer_from__branch_name,
                transfer_to: response.data.received_item.transfer_itemid__transfer_to__branch_name,
                transfer_id: response.data.received_item.transfer_itemid,
            }))

            for (var i of response.data.tagged_item) {
                let tempData = {
                    tag_number: i.tag_number,
                    item_name: i.item_details__item_details__item_name,
                    sub_item_name: i.sub_item_details__sub_item_name,
                    gross_weight: i.gross_weight,
                    net_weight: i.net_weight,
                    checked: false
                }

                setParticulars([...particulars, tempData])
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err);

        
        let checkedList = [];
        for (var i of particulars) {
            if (i.checked) {
                let tempData = {
                    tag_number: i.tag_number
                }
                checkedList.push(tempData)
            }
        }
        let body = {
            transfer_itemid: formValues.transfer_id,
            return_date: formValues.return_date,
            reason: formValues.reason,
            tag_item_details: checkedList
        }

        if (checkedList.length > 0) {
            createReturn(body)
        } else {
            toast.error('Select atleast one item!')
        }

    }

    const createReturn = async (body) => {
        var response = await postAxiosWithToken({
            url: returnTranferEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const onValueUpdate = (e) => {
        const  { name, value, type } = e.target;

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
        
        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} is required`
        }))
    }

    const onTableValueUpdate = (input, index) => {
        const { name, value, checked } = input;

        let data = [...particulars];
        data[index]['checked'] = checked;
        setParticulars(data)
    }

    return (
        <>
            <div className='mb-[120px] relative'>
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Return</p>

                <form onSubmit={handleSubmit}>
                    <div className='bg-menu-head-bg w-full border p-[15px] rounded-xl shadow-lg'>
                        <div>
                            <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">New Item Transfer</p>
                            <div className='grid grid-cols-5 gap-[25px]'>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Transfer Date</p>
                                    <p>{formValues.transfer_date}</p>

                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Required Date</p>
                                    <p>{formValues.required_date}</p>

                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Stock Authority</p>
                                    <p>{formValues.stock_authority}</p>

                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Transfer From</p>
                                    <p>{formValues.transfer_from}</p>

                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Transfer To</p>
                                    <p>{formValues.transfer_to}</p>

                                </div>
                            </div>
                            <div className='grid grid-cols-3 gap-[25px]'>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Return Date <span className="text-red-500">*</span></p>
                                    <input
                                        type="date"
                                        placeholder="Return Date"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="return_date"
                                        value={formValues.return_date}
                                        onChange={onValueUpdate}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.return_date}</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Reason <span className="text-red-500">*</span></p>
                                    <input
                                        type="text"
                                        placeholder="Reason"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="reason"
                                        value={formValues.reason}
                                        onChange={onValueUpdate}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.reason}</p>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <p className='font-[500] text-[18px] mt-[34px] mb-[14px]'>Item Details</p>

                            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                                <table className="w-full">
                                    <thead className="border bg-table-head">
                                        <tr>
                                            <td className="p-[10px] text-table-head-font text-[16px]">
                                                <input type="checkbox" checked={allChecked} onChange={(e) => setAllChecked(e.target.checked)} />
                                            </td>
                                            <td className="p-[10px] text-table-head-font text-[16px]">S.No</td>
                                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Tag No</td>
                                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Item Name</td>
                                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Sub Item Name</td>
                                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Gross Weight</td>
                                            <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Net Weight</td>
                                        </tr>
                                    </thead>
                                    <tbody className="text-primary-grey bg-[#fff]">
                                        {
                                            particulars.map((item, index) => (
                                                <tr key={item.id} className="">
                                                    <td className="p-[10px] text-table-head-font text-[16px]">
                                                        <input type="checkbox" checked={item.checked} onChange={(e) => onTableValueUpdate(e.target, index)} />
                                                    </td>
                                                    <td className="p-[10px] border">{index + 1}</td>
                                                    <td className="p-[10px] border capitalize">{item.tag_number}</td>
                                                    <td className="p-[10px] border capitalize">{item.item_name}</td>
                                                    <td className="p-[10px] border capitalize">{item.sub_item_name}</td>
                                                    <td className="p-[10px] border capitalize">{item.gross_weight}</td>
                                                    <td className="p-[10px] border capitalize">{item.net_weight}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="absolute top-[-20px] left-0 w-full p-[15px] flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                            className="capitalize bg-menu-head-bg border text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Back<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Return</button>
                    </div>

                </form>

            </div>
        </>
    );
}