import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAxios } from "../../../service/apiservice";
import { rangestockReportEndPoint } from "../../../service/api";

export default function ViewRangeStock() {

    const { id } = useParams();

    const [dataList, setDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getRangeStockDetails()
    },[id])

    const getRangeStockDetails = async () => {
        var response = await getAxios({
            url: rangestockReportEndPoint + id + '/'
        })

        if (response != null) {
            setDataList(response.data.list)
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Tag Number</td>
                                <td className="p-[10px]">Item</td>
                                <td className="p-[10px]">Sub Item</td>
                                <td className="p-[10px]">Size</td>
                                <td className="p-[10px]">Tag Pieces</td>
                                <td className="p-[10px]">Gross Weight</td>
                                <td className="p-[10px]">Net Weight</td>
                                <td className="p-[10px]">Hallmark Huid</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={9}>Loading...</td>
                                </tr> :
                                    dataList?.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                        dataList?.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item?.tag_number}</td>
                                                <td className="p-[10px]">{item?.item_details}</td>
                                                <td className="p-[10px]">{item?.sub_item_details}</td>
                                                <td className="p-[10px]">{item?.size_value}</td>
                                                <td className="p-[10px]">{item?.tag_pieces}</td>
                                                <td className="p-[10px]">{item?.gross_weight}</td>
                                                <td className="p-[10px]">{item?.net_weight}</td>
                                                <td className="p-[10px]">{item?.halmark_huid}</td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
        </>
    );
}