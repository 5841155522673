import { useEffect, useRef, useState } from "react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { menuPermissionEndPoint, userRoleListEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
// import './menupermisssion.scss';

export default function MenuPermission() {
    const isDark = useSelector((state) => state.themeConfig.isDark)

    const [userRolesList, setUserRoleList] = useState([]);
    const [menuPermissionList, setMenuPermissionList] = useState([]);


    const [activeRole, setActiveRole] = useState(0);

    useEffect(() => {
        getUserRoleData()
    }, [])

    const getMenuPermissionData = async (id) => {
        var response = await getAxios({
            url: menuPermissionEndPoint + String(id) + "/"
        })

        if (response !== null) {
            setMenuPermissionList(response.data)
        }
    }

    const getUserRoleData = async (id) => {
        var response = await getAxios({
            url: userRoleListEndPoint
        })

        if (response !== null) {
            setUserRoleList(response.data.list)
            getMenuPermissionData(response.data.list[0].id)
        }
    }

    const updateMenuPermission = async (value, action, menuId) => {
        if (action === 'all') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "view_permit": value,
                "add_permit": value,
                "edit_permit": value,
                "delete_permit": value
            }

            updatePermission(body)
        } else if (action === 'view') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "view_permit": value,
            }

            updatePermission(body)
        } else if (action === 'add') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "add_permit": value,
            }

            updatePermission(body)
        } else if (action === 'edit') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "edit_permit": value
            }

            updatePermission(body)
        } else if (action === 'delete') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "delete_permit": value
            }

            updatePermission(body)
        }
    }

    const updatePermission = async (body) => {
        var response = await postAxiosWithToken({
            url: menuPermissionEndPoint,
            body: body
        })

        if (response !== null) {
            getMenuPermissionData(userRolesList[activeRole].id)
            toast.success(response.message)
        }
    }

    return (
        <>
            <div className="md:flex md:gap-8">
                <div className={`bg-white border rounded-lg w-[250px] my-[10px] h-fit`}>

                    <div className="bg-table-head text-table-head-font p-[10px] text-[16px] font-[600]">

                        Role
                    </div>
                    <div className="p-2">
                        {
                            userRolesList.map((item, index) => (
                                <div
                                    onClick={() => { getMenuPermissionData(item.id); setActiveRole(Number(index)) }}
                                    className={`${activeRole === index ? 'bg-primary-btn text-[#fff] rounded-lg' : ''} p-[10px] cursor-pointer capitalize`}
                                >{item.role_name}</div>
                            ))
                        }
                    </div>
                </div>

                <div className="w-full my-[10px] mb-[50px] max-[440px]:overflow-x-scroll border rounded-md">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px] table-head-font text-[16px] font-[600]">Menu Name</td>
                                <td className="p-[10px] table-head-font text-[16px] font-[600]">All</td>
                                <td className="p-[10px] table-head-font text-[16px] font-[600]">View</td>
                                <td className="p-[10px] table-head-font text-[16px] font-[600]">Add</td>
                                <td className="p-[10px] table-head-font text-[16px] font-[600]">Edit</td>
                                <td className="p-[10px] table-head-font text-[16px] font-[600]">Delete</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                menuPermissionList.map((item) =>


                                    item.menu_list.map((menu) => (
                                        <tr key={menu.id} className="border">
                                            <td className="p-[10px]">{menu.menu_name}</td>
                                            <td className="p-[10px]">
                                                <div class="inline-flex items-center">
                                                    <label class="relative flex items-center p-3 rounded-full " htmlFor="check">
                                                        <input type="checkbox"
                                                            class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                                                            id="check"
                                                            checked={menu.view_permit && menu.add_permit && menu.edit_permit && menu.delete_permit ? true : false}
                                                            onChange={(e) => updateMenuPermission(e.target.checked, 'all', menu.menu_id)} />

                                                        <span
                                                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                stroke="currentColor" stroke-width="1">
                                                                <path fill-rule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>





                                            <td className="p-[10px]">
                                                <div class="inline-flex items-center">
                                                    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                                                        <input type="checkbox"

                                                        class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"

                                                            id="check"
                                                            checked={menu.view_permit}
                                                            onChange={(e) => updateMenuPermission(e.target.checked, 'view', menu.menu_id)} />

                                                        <span
                                                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                stroke="currentColor" stroke-width="1">
                                                                <path fill-rule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>

                                            <td className="p-[10px]">
                                                <div class="inline-flex items-center">
                                                    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                                                        <input type="checkbox"

                                                        class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"

                                                            id="check"
                                                            checked={menu.add_permit}
                                                            onChange={(e) => updateMenuPermission(e.target.checked, 'add', menu.menu_id)} />

                                                        <span
                                                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                stroke="currentColor" stroke-width="1">
                                                                <path fill-rule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>


                                            <td className="p-[10px]">
                                                <div class="inline-flex items-center">
                                                    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                                                        <input type="checkbox"

                                                        class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"

                                                            id="check"
                                                            checked={menu.edit_permit}
                                                            onChange={(e) => updateMenuPermission(e.target.checked, 'edit', menu.menu_id)} />

                                                        <span
                                                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                stroke="currentColor" stroke-width="1">
                                                                <path fill-rule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>

                                            <td className="p-[10px]">
                                                <div class="inline-flex items-center">
                                                    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                                                        <input type="checkbox"

                                                        class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"

                                                            id="check"
                                                            checked={menu.delete_permit}
                                                            onChange={(e) => updateMenuPermission(e.target.checked, 'delete', menu.menu_id)} />

                                                        <span
                                                            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                stroke="currentColor" stroke-width="1">
                                                                <path fill-rule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}