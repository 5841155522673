export default function ViewParticulars({ 
    items,
    setItems
 }) {

    const updateSoldItems = (checked, index) => {
        let itemsData = [...items];
        itemsData[index]['is_sold'] = checked
        itemsData[index]['is_received'] = !checked
        setItems(itemsData)
    }

    const updateReturnItems = (checked, index) => {
        let itemsData = [...items];
        itemsData[index]['is_received'] = checked
        itemsData[index]['is_sold'] = !checked
        setItems(itemsData)
    }

    return (
        <div className="w-full">
            <table className="w-full">
                <thead className="bg-table-head text-table-head-font">
                    <tr>
                        <td className="p-2 border">#</td>
                        <td className="p-2 border">Tag No</td>
                        <td className="p-2 border">Metal</td>
                        <td className="p-2 border">Item Name</td>
                        <td className="p-2 border">Sub Item Name</td>
                        <td className="p-2 border">Gross Weight</td>
                        <td className="p-2 border">Net Weight</td>
                        <td className="p-2 border">Sale</td>
                        <td className="p-2 border">Return to Stock</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) => (
                            <tr>
                                <td className="p-2 border">{index + 1}</td>
                                <td className="p-2 border">{item.tag_number}</td>
                                <td className="p-2 border">{item.metal}</td>
                                <td className="p-2 border">{item.item_name}</td>
                                <td className="p-2 border">{item.sub_item_name}</td>
                                <td className="p-2 border">{item.gross_weight} g</td>
                                <td className="p-2 border">{item.net_weight} g</td>
                                <td className="p-2 border">
                                    <div class="inline-flex items-center">
                                        <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check1">
                                            <input type="checkbox"
                                                class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border border-gray-400 transition-all rounded checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                                                id="check1"
                                                checked={item.is_sold}
                                                onChange={(e) => {updateSoldItems(e.target.value, index)}} />
                                            <span
                                                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                    stroke="currentColor" stroke-width="1">
                                                    <path fill-rule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clip-rule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                </td>
                                <td className="p-2 border">
                                    <div class="inline-flex items-center">
                                        <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="check">
                                            <input type="checkbox"
                                                class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-gray-400 transition-all  checked:border-table-head checked:bg-table-head checked:before:bg-table-head hover:before:opacity-10"
                                                id="check"
                                                checked={item.is_received}
                                                onChange={(e) => {updateReturnItems(e.target.value, index)}} />
                                            <span
                                                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                    stroke="currentColor" stroke-width="1">
                                                    <path fill-rule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clip-rule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}