import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import Popup from "../../../components/Popup";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { postAxiosWithToken } from "../../../service/apiservice";
import { vendorDueListEndPoint } from "../../../service/api";

export default function VendorPayments() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [vendorDueList, setVendorDueList] = useState([]);
    const [currentVendorDue, setCurrentvendorDue] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const { actions, pagination } = useSelector((state) => state.appConfig);

    const [tableFilters, setTableFilters] = useState({
        // search: '',
        // from_date: null,
        // to_date: null,
        // branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    useEffect(() => {
        getVendorDueList()
    },[])

    const getVendorDueList = async () => {
        var response = await postAxiosWithToken({
            url: vendorDueListEndPoint,
            body: tableFilters
        })

        if (response != null) {
            setVendorDueList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <div>
            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="p-[10px]">S.No</td>
                            <td className="p-[10px]">Vendor Name</td>
                            <td className="p-[10px] min-w-[150px]">Total Pure Wt</td>
                            <td className="p-[10px] min-w-[150px]">Pending Amount</td>
                            <td className="p-[10px] min-w-[150px]">Pending Wt</td>
                            <td className="p-[10px] min-w-[150px]">Opening Balance</td>
                            <td className="p-[10px] min-w-[150px]">Opening WT</td>
                            <td className="p-[10px] min-w-[150px]">Payment Status</td>
                            <td className="p-[10px] min-w-[150px]">Action</td>
                            <td className="p-[10px] min-w-[150px]">Payment History</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-[#fff]">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={11}>Loading...</td>
                            </tr> :
                                vendorDueList.length === 0 ? <tr><td className="text-center border" colSpan={11}>No Data</td></tr> :
                                    vendorDueList.map((item, index) => (

                                        <tr key={item.id} className="border">
                                            <td className="p-[10px]">{index + 1}</td>
                                            <td className="p-[10px]">{item.designer_name}</td>
                                            <td className="p-[10px]">{item.total_pure_weight}</td>
                                            <td className="p-[10px]">{item.pending_amount}</td>
                                            <td className="p-[10px]">{item.pending_weight}</td>
                                            <td className="p-[10px]">{item.credit_balance_rupee}</td>
                                            <td className="p-[10px]">{item.credit_balance_gm}</td>
                                            <td className="p-[10px]">
                                                <div style={{backgroundColor: item.color}} className="px-5 py-2 rounded-3xl">{item.status}</div>
                                            </td>
                                            <td className="p-[10px]">
                                                <button 
                                                onClick={(e) => {e.preventDefault(); localStorage.setItem('vendor_due_id', item.id); navigate('vendor-payment-create')}}
                                                className="bg-[#AD9FFF] text-white rounded-lg px-5 py-2 text-[16px]">
                                                    Pay Now
                                                </button>
                                            </td>
                                            <td className="p-[10px]">{item.balance_amount}</td>
                                        </tr>
                                    ))
                        }

                    </tbody>
                </table>
            </div>




            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </div >
    );
}