import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAxios } from '../../../service/apiservice';
import { billingEndListPoint, billingGetByIdEndPoint } from '../../../service/api';

export default function SalesReturnForm({ dataList, setDataList, selectedItems, setSelectedItems }) {

    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getBillingList();
    }, [])


    const getBillingList = async () => {
        var response = await getAxios({
            url: billingEndListPoint
        });

        if (response != null) {
            var tempList = [];
            for (var i of response.data.list) {
                tempList.push({
                    label: i.bill_no,
                    value: i.id
                })
            }
            setOptions(tempList)
        }
    }

    const getBillDetails = async (value) => {
        var response = await getAxios({
            url: billingGetByIdEndPoint + value.value + '/'
        })

        if (response != null) {
            let tempList = [];
            for (var i of response.data.billing_item_details) {
                if (!(i.is_returned)) {
                    tempList.push(i)
                }
            }
            setDataList(tempList)
        }
    }

    const onValueChange = (value, id) => {
        let data = [...selectedItems];

        if (value) {
            data.push(id)
        } else {
            let index = data.indexOf(id)
            data.splice(index, 1)
        }

        setSelectedItems(data)
    }

    return (
        <div>
            <div className="">
                <Select
                    defaultValue={selectedOption}
                    onChange={(value) => { getBillDetails(value) }}
                    options={options}
                    className='w-[300px]'
                    placeholder={'Bill No'}
                />
            </div>
            <div className="w-full mt-[15px]">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="py-[10px] border text-center text-[10px]"></td>
                            <td className="py-[10px] border text-center text-[10px]">S.No</td>
                            <td className="py-[10px] border text-center text-[10px]">Tag No</td>
                            <td className="py-[10px] border text-center text-[10px]">Sub Item</td>
                            <td className="py-[10px] border text-center text-[10px]">Rate</td>
                            <td className="py-[10px] border text-center text-[10px]">Pcs</td>
                            <td className="py-[10px] border text-center text-[10px]">Gross wt</td>
                            <td className="py-[10px] border text-center text-[10px]">Net wt</td>
                            <td className="py-[10px] border text-center text-[10px]">Wastage %</td>
                            <td className="py-[10px] border text-center text-[10px]">F.Wastage</td>
                            <td className="py-[10px] border text-center text-[10px]">MC/g</td>
                            <td className="py-[10px] border text-center text-[10px]">F.MC</td>
                            <td className="py-[10px] border text-center text-[10px]">Stone amt</td>
                            <td className="py-[10px] border text-center text-[10px]">Diamond amt</td>
                            <td className="py-[10px] border text-center text-[10px]">HUID Rate</td>
                            <td className="py-[10px] border text-center text-[10px]">Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataList.map((item, index) => (
                                <tr className=''>
                                    <td className="py-[10px] border text-center text-[10px]">
                                        <input type="checkbox" checked={selectedItems.includes(item.id) ? true : false} onChange={(e) => onValueChange(e.target.checked, item.id)} />
                                    </td>
                                    <td className="py-[10px] border text-center text-[10px]">{index + 1}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.tag_number}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.sub_item_details_name}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.metal_rate}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.pieces}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.gross_weight}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.net_weight}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.wastage_percentage}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.flat_making_charge}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.making_charge}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.flat_making_charge}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.stone_rate}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.diamond_rate}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.item_huid_rate}</td>
                                    <td className="py-[10px] border text-center text-[10px]">{item.rate}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}