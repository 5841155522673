import { isValid } from "date-fns";
import { useState } from "react";
import { isOptionalValidForm, isValidForm } from "../../../../utilities/formvalidation";
import { metalRateCutEndPoint } from "../../../../service/api";
import { postAxiosWithToken } from "../../../../service/apiservice";
import toast from "react-hot-toast";

export default function MetalRateCutForm({ selectedItems, vendorDetails, formValues, setFormValues, vendorErrors, setVendorErrors, setMetalRateCutList, metalRateCutList, getData }) {
    const [errors, setErrors] = useState({});

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'metal_weight') {
            if (formValues.pure_calculation?.length > 0) {
                setFormValues((prevState) => ({
                    ...prevState,
                    pure_weight: (parseFloat(value)/100) * parseFloat(formValues.pure_calculation)
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    pure_weight: value.length > 0 ? "" : `pure_weight field is required`
                }))
            }
        } else if (name == 'pure_calculation') {
            if (formValues.metal_weight?.length > 0) {
                setFormValues((prevState) => ({
                    ...prevState,
                    pure_weight: (parseFloat(value)/100) * parseFloat(formValues.metal_weight)
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    pure_weight: value.length > 0 ? "" : `pure_weight field is required`
                }))
            }
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target)
        let formDataObject = Object.fromEntries(formFields.entries());

        let data = {...vendorDetails, ...formDataObject} 
        
        var err = {}
        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }
        setErrors(err)

        if (isOptionalValidForm(data, ['discount'])) {
            delete data.credit_balance_gm
            delete data.credit_balance_rupee

            if (selectedItems.length > 0) {
                let purchaseList = [];
            
                for (var i of selectedItems) {
                    purchaseList.push(i.id)
                }
                data['purchase_order'] = purchaseList.join(',')
                
                if (data.discount.length <= 0) {
                    data['discount'] = 0
                }
                
                let tempList = [...metalRateCutList];
                tempList.unshift(data)
                setMetalRateCutList(tempList)
                setFormValues((prevState) => ({
                    ...prevState,
                    metal_weight: "",
                    pure_calculation: "",
                    pure_weight: "",
                    discount: ""
                }))
            } else {
                toast.error('Select pending due list')
            }
        }
    }

    return (
        <form id="metalratecutform" onSubmit={handleSubmit}>
            <div className="grid lg:grid-cols-2 gap-3">

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Metal Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="text"
                        placeholder="Metal Weight"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="metal_weight"
                        value={formValues.metal_weight}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">{errors.metal_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Pure Calculation <span className="text-red-500 ">*</span></p>
                    <input
                        type="text"
                        placeholder="Pure Calculation"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="pure_calculation"
                        value={formValues.pure_calculation}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">{errors.pure_calculation}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Pure Wt(g) <span className="text-red-500 ">*</span></p>
                    <input
                        type="text"
                        placeholder="Pure Wt(g)"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="pure_weight"
                        value={formValues.pure_weight}
                        readOnly
                    />
                    <p className="text-red-500 font-[400] text-[13px]">{errors.pure_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[12px]">Dis(g)</p>
                    <input
                        type="text"
                        placeholder="Dis(g)"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="discount"
                        value={formValues.discount}
                        onChange={onValueUpdate}
                    />
                </div>
            </div>
            <button
                type="submit"
                disabled={selectedItems.length > 0 ? false : true}
                className="mt-3 capitalize border border-primary-btn disabled:border-gray-400 text-primary-btn disabled:bg-gray-400 disabled:text-white w-fit rounded-lg px-[25px] py-[5px] h-fit font-[500] text-[15px] outline-none"
            >Add</button>
        </form>
    );
}