import React, { useEffect, useState } from 'react'
import SearchDropDown from '../../../../components/SearchDropDown'
import { getAxios, postAxiosWithToken } from '../../../../service/apiservice';
import { approvalRecieptEndPoint, requisitionApprovalEndPoint, requisitionApprovalListEndPoint, userListEndPoint } from '../../../../service/api';
import { useNavigate, useParams } from 'react-router-dom';
import ViewParticulars from './viewparticualrs';
import ReturnStockParticulars from './returnstockparticulars';
import SalesParticulars from './salesparticulars';
import { isValidForm } from '../../../../utilities/formvalidation';
import toast from 'react-hot-toast';

function RequisitionReciept() {

  const navigate = useNavigate();

  const { id } = useParams();

  const [requisitionDropDown, setRequisitionDropDown] = useState([]);

  const [selectedId, setSelectedId] = useState(id);
  const [formValues, setFormValues] = useState({
    recieved_date: new Date().toLocaleDateString('en-CA'),
  })
  const [particularsList, setParticularsList] = useState([]);
  const [salesParticulars, setSalesParticulars] = useState([]);
  const [returnParticulars, setReturnParticulars] = useState([]);
  const [errors, setErrors] = useState({});
  const [calculatedValues, setCalculatedValues] = useState({
    received_gross_weight: 0.0,
    received_net_weight: 0.0,
    sold_gross_weight: 0.0,
    sold_net_weight: 0.0
  })
  const [selectedSold, setSelectedSold] = useState([]);
  const [selectedRecieved, setSelectedRecieved] = useState([]);

  const [itemsError, setItemsError] = useState(false);

  useEffect(() => {
    getRequisitionListData();
    getApprovalDetails(id);
  }, [])

  useEffect(() => {
    let salesList = [];
    let returnList = [];

    let salesIdList = [];
    let returnIdList = [];

    let recGrsWgt = 0.0;
    let recNetWgt = 0.0;
    let soldGrsWgt = 0.0;
    let soldNetWgt = 0.0;

    let isValidItems = [];

    for (var i of particularsList) {
      if (i.is_sold) {
        salesList.push(i);
        salesIdList.push(i.id);
        soldGrsWgt = soldGrsWgt + i.gross_weight;
        soldNetWgt = soldNetWgt + i.net_weight;
      }
      if (i.is_received) {
        returnList.push(i)
        returnIdList.push(i.id);
        recGrsWgt = recGrsWgt + i.gross_weight;
        recNetWgt = recNetWgt + i.net_weight;
      }

      if (i.is_received || i.is_sold) {
        isValidItems.push(1)
      } else {
        isValidItems.push(0)
      }
    }

    if (isValidItems.includes(0)) {
      setItemsError(true)
    } else {
      setItemsError(false)
    }

    setSalesParticulars(salesList);
    setReturnParticulars(returnList);
    setSelectedSold(salesIdList);
    setSelectedRecieved(returnIdList);

    setCalculatedValues({
      sold_gross_weight: soldGrsWgt,
      sold_net_weight: soldNetWgt,
      received_gross_weight: recGrsWgt,
      received_net_weight: recNetWgt
    })
  }, [particularsList])

  const getRequisitionListData = async () => {
    var response = await getAxios({
      url: requisitionApprovalListEndPoint
    });

    if (response != null) {
      let tempData = [];
      for (var i of response.data.list) {
        var dictData = {
          value: i.id,
          label: i.approval_issue_id
        }
        tempData.push(dictData)
      }
      setRequisitionDropDown(tempData);
    }
  }
  const getApprovalDetails = async (value) => {
    var response = await getAxios({
      url: requisitionApprovalEndPoint + value + '/'
    })

    if (response != null) {
      var shopAddress = response.data.shop_address
      try {
        delete shopAddress.country;
        delete shopAddress.state;
      } catch (err) {

      }

      var tempDetails = {
        approval_issue_id: response.data.approval_issue_id,
        issue_date: response.data.issue_date,
        issued_by: response.data.issued_by,
        receiver_name: response.data.receiver_name,
        shop_name: response.data.shop_name,
        branch: response.data.branch,
        notes: response.data.notes,
        shop_address: Object.values(shopAddress).join(',')
      }
      setParticularsList(response.data.issued_tag_details)
      setFormValues(tempDetails)
    }
  }

  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value
    }))

    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length > 0 ? "" : `${name} field is required`
    }))
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let detailsForm = new FormData(e.target)

    var jsonData = Object.fromEntries(detailsForm.entries())

    var err = {}

    for (var field of Object.entries(jsonData)) {
        if (field[1].length === 0) {
            err[field[0]] = `${field[0]} field is required`
        } else {
            err[field[0]] = ""
        }
    }

    setErrors(err);


    if (isValidForm(jsonData)) {
      

      if (itemsError) {
        toast.error('Please checkout all the items!')
      } else {
        let data = {
          ...jsonData,
          ...calculatedValues,
          sold_tag_details: selectedSold,
          received_tag_details: selectedRecieved
        }

        createReciept(data)
      }
    }    
  }

  const createReciept = async (body) => {
    var response = await postAxiosWithToken({
      url: approvalRecieptEndPoint + body.requisition_id + '/',
      body: body
    })

    if (response != null) {
      toast.success(response.message);
      navigate(-1)
    }
  }
  return (
    <div className='mb-[100px]'> 
      <div className='flex gap-[2%]'>
        <form onSubmit={handleSubmit} className='w-[49%] bg-white p-5 border rounded-xl'>
          <div className='flex gap-4 items-center '>
            <div className='w-[70%]'>
              <p className='font-[600]'>Requisition Id</p>
              <SearchDropDown
                name={"requisition_id"}
                id={"requisition_id"}
                options={requisitionDropDown}
                value={selectedId}
                setValue={setSelectedId}
              />
            </div>
            <button onClick={(e) => { e.preventDefault(); getApprovalDetails(selectedId) }} className='bg-primary-btn border border-primary-btn text-white rounded-md px-5 py-2 h-fit w-fit'>
              Get Details
            </button>
          </div>

          <div className='grid grid-cols-2 gap-3'>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[16px]">Recieved Date</p>
              <input
                type="date"
                placeholder="Recieved Date"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                name="recieved_date"
                value={formValues.recieved_date}
                onChange={onValueUpdate}
              />
              <p className="text-red-500 font-[400] text-[13px]">{errors.recieved_date}</p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[16px]">Recieved By <span className="text-red-500">*</span></p>
              <input
                type="text"
                placeholder="Recieved By"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                name="received_by"
                value={formValues.received_by}
                onChange={onValueUpdate}
              />
              <p className="text-red-500 font-[400] text-[13px]">{errors.received_by}</p>
            </div>
          </div>
          <div className="fixed bottom-[35px] left-0 p-3 bg-white flex gap-3 justify-end items-center w-full border-t">
            <button
              onClick={(e) => { e.preventDefault(); navigate(-1) }}
              className="capitalize bg-secondary text-black w-fit rounded-lg p-[10px] font-[500] text-[15px] outline-none border"
            >close <span className="lowercase">(esc)</span></button>
            <button type='submit'
              className="capitalize bg-primary-btn bg-opacity-50 hover:bg-opacity-100 text-black hover:text-white w-fit rounded p-[10px] h-fit text-[15px] font-[500] outline-none border border-primary-btn"
            >save <span className="lowercase">(ctrl+s)</span></button>
          </div>
        </form>
        <div className='w-[49%] bg-white p-5 border rounded-xl'>
          <p className='font-[600]'>Shop Details</p>

          <div className='grid grid-cols-4 gap-3 mt-[10px]'>
            <div className=''>
              <p className='font-[500]'>Date</p>
              <p>{formValues.issue_date}</p>
            </div>
            <div className='col-span-2 break-all'>
              <p className='font-[500]'>Shop Name</p>
              <p>{formValues.shop_address}</p>
            </div>
            <div className=''>
              <p className='font-[500] break-keep'>Reciever Name</p>
              <p>{formValues.receiver_name}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white p-5 border rounded-xl mt-[10px]'>
        <p className='font-[600]'>Requistion Items</p>
        <ViewParticulars items={particularsList} setItems={setParticularsList} soldItems={salesParticulars} setSoldItems={setSalesParticulars} recievedItems={returnParticulars} setRecievedItems={setReturnParticulars} />

        <div className='flex gap-3 items-start mt-5'>
          <div className='border rounded-lg overflow-hidden w-full bg-gray-200'>
            <div className='flex justify-between items-center px-3'>
              <p className='font-[600]'>Sale</p>
              <div className='flex gap-3'>
                <p className='font-[700]'>Gross Weight: <span className='text-[400]'>{calculatedValues.sold_gross_weight}</span></p>
                <p className='font-[700]'>Net Weight: <span className='text-[400]'>{calculatedValues.sold_net_weight}</span></p>
              </div>
            </div>
            <SalesParticulars items={salesParticulars} />
          </div>
          <div className='border rounded-lg overflow-hidden w-full bg-gray-200'>
            <div className='flex justify-between items-center px-3'>
              <p className='font-[600]'>Return to Stock</p>
              <div className='flex gap-3'>
                <p className='font-[700]'>Gross Weight: <span className='text-[400]'>{calculatedValues.received_gross_weight}</span></p>
                <p className='font-[700]'>Net Weight: <span className='text-[400]'>{calculatedValues.received_net_weight}</span></p>
              </div>
            </div>
            <ReturnStockParticulars items={returnParticulars} />
          </div>
        </div>
      </div>


    </div>
  )
}

export default RequisitionReciept
