export const baseUrl = 'http://13.49.168.229:8000';
// export const baseUrl = 'http://192.168.1.108:8000';
export const thirdpartybaseUrl = 'https://aupay.auss.co';

export const loginEndPoint = baseUrl + '/accounts/login/';
export const logoutEndPoint = baseUrl + '/accounts/logout/';
export const changePasswordEndPoint = baseUrl + '/accounts/change-password/';

export const menuPermissionEndPoint = baseUrl + '/settings/menu-permission/';
export const activeUserPermissionEndPoint = baseUrl + '/settings/active-user-menu-permission/';
export const gstVerifyEndPoint = baseUrl + '/settings/gst-verification/';
export const ifscVerifyEndPoint = baseUrl + '/settings/ifsc-verification/';

export const userRoleListEndPoint = baseUrl + '/settings/user-role-list/';
export const userRoleEndPoint = baseUrl + '/settings/user-role/';
export const userRoleStatusEndPoint = baseUrl + '/settings/change-user-role-status/';
export const userRoleAdminStatusEndPoint = baseUrl + '/settings/change-user-role-admin-status/';

export const menuListEndPoint = baseUrl + '/settings/menu-list/';
export const menuEndPoint = baseUrl + '/settings/menu/';
export const menuStatusEndPoint = baseUrl + '/settings/change-menu-status/';

export const menuGroupListEndPoint = baseUrl + '/settings/menu-group-list/';
export const menuGroupEndPoint = baseUrl + '/settings/menu-group/';
export const menuGroupStatusEndPoint = baseUrl + '/settings/change-menu-group-status/';

//Main Menu Group
export const mainmenuListEndPoint = baseUrl + '/settings/main-menu-list/';
export const mainmenudropdownListEndPoint = baseUrl + '/settings/main-menu-list/';
export const mainmenuGroupStatusEndPoint = baseUrl + '/settings/change-main-menu-status/';
export const mainmenuGroupEndPoint = baseUrl + '/settings/main-menu/';

export const locationListEndPoint = baseUrl + '/organizations/location-list/';
export const locationEndPoint = baseUrl + '/organizations/location/';
export const locationStatusEndPoint = baseUrl + '/organizations/change-location-status/';

export const branchListEndPoint = baseUrl + '/organizations/branch-list/';
export const branchEndPoint = baseUrl + '/organizations/branch/';
export const branchStatusEndPoint = baseUrl + '/organizations/change-branch-status/';

export const departmentListEndPoint = baseUrl + '/organizations/department-list/';
export const departmentEndPoint = baseUrl + '/organizations/department/';
export const departmentStatusEndPoint = baseUrl + '/organizations/change-department-status/';

export const designationListEndPoint = baseUrl + '/organizations/designation-list/';
export const designationEndPoint = baseUrl + '/organizations/designation/';
export const designationStatusEndPoint = baseUrl + '/organizations/change-designation-status/';

export const staffListEndPoint = baseUrl + '/organizations/staff-list/';
export const staffEndPoint = baseUrl + '/organizations/staff/';
export const staffStatusEndPoint = baseUrl + '/organizations/change-staff-status/';
export const staffListByBranchEndPoint = baseUrl + "/organizations/staff-branch-list/";

export const userListEndPoint = baseUrl + '/organizations/user-list/';
export const userEndPoint = baseUrl + '/organizations/user/';
export const userStatusEndPoint = baseUrl + '/organizations/change-user-status/';

export const metalListEndPoint = baseUrl + '/masters/metal-list/';
export const metalEndPoint = baseUrl + '/masters/metal/';
export const metalStatusEndPoint = baseUrl + '/masters/change-metal-status/';
export const metaltypeListEndPoint = baseUrl + '/masters/tag-type/';

export const purityListEndPoint = baseUrl + '/masters/purity-list/';
export const purityEndPoint = baseUrl + '/masters/purity/';
export const purityStatusEndPoint = baseUrl + '/masters/change-purity-status/';
export const purityVisibleStatusEndPoint = baseUrl + '/masters/change-purity-visible/';

export const metalRatesListEndPoint = baseUrl + '/masters/metal-rates-list/';
export const metalRatesEndPoint = baseUrl + '/masters/metal-rates/';
export const metalRatesStatusEndPoint = baseUrl + '/masters/change-metal-rates-status/';
export const todayMetalRatesEndPoint = baseUrl + '/masters/today-metal-rate/';
export const metalPuritybasedRateEndPoint = baseUrl + '/masters/metal-purity-based-rate/';

export const floorListEndPoint = baseUrl + '/infrastructure/floor-list/';
export const floorEndPoint = baseUrl + '/infrastructure/floor/';
export const floorStatusEndPoint = baseUrl + '/infrastructure/change-floor-status/';

export const counterListEndPoint = baseUrl + '/infrastructure/counter-list/';
export const counterEndPoint = baseUrl + '/infrastructure/counter/';
export const counterStatusEndPoint = baseUrl + '/infrastructure/change-counter-status/';

export const companyListEndPoint = baseUrl + '/books/company-list/';
export const companyEndPoint = baseUrl + '/books/company/';
export const companyStatusEndPoint = baseUrl + '/books/change-company-status/';
export const companyBankMultiDeleteEndPoint = baseUrl + '/books/company-bank-delete-list/';

export const groupLedgerListEndPoint = baseUrl + '/books/group-ledger-list/';

export const groupTypeListEndPoint = baseUrl + '/books/group-type-list/';

export const accountGoupListEndPoint = baseUrl + '/books/account-group-list/';
export const accountGroupEndPoint = baseUrl + '/books/account-group/';
export const accountGroupStatusEndPoint = baseUrl + '/books/change-account-group-status/';

export const tagListEndPoint = baseUrl + '/masters/tag-type-list/';
export const tagEndPoint = baseUrl + '/masters/tag-type/';
export const tagStatusEndPoint = baseUrl + '/masters/change-tag-type-status/';

export const taxListEndPoint = baseUrl + '/masters/tax-list/';
export const taxEndPoint = baseUrl + '/masters/tax/';
export const taxStatusEndPoint = baseUrl + '/masters/change-tax-status/';

export const caratrateListEndPoint = baseUrl + '/masters/carat-rate-list/';
export const caratrateEndPoint = baseUrl + '/masters/carat-rate/';
export const caratrateStatusEndPoint = baseUrl + '/masters/carat-rate-status/';

export const shapeListEndPoint = baseUrl + '/masters/shape-list/';
export const shapeEndPoint = baseUrl + '/masters/shape/';
export const shapeStatusEndPoint = baseUrl + '/masters/change-shape-status/';

export const cutListEndPoint = baseUrl + '/masters/cut-list/';
export const cutEndPoint = baseUrl + '/masters/cut/';
export const cutStatusEndPoint = baseUrl + '/masters/change-cut-status/';

export const clarityListEndPoint = baseUrl + '/masters/clarity-list/';
export const clarityEndPoint = baseUrl + '/masters/clarity/';
export const clarityStatusEndPoint = baseUrl + '/masters/change-clarity-status/';

export const colorListEndPoint = baseUrl + '/masters/color-list/';
export const colorEndPoint = baseUrl + '/masters/color/';
export const colorStatusEndPoint = baseUrl + '/masters/change-color-status/';

export const stoneListEndPoint = baseUrl + '/masters/stone-list/';
export const stoneEndPoint = baseUrl + '/masters/stone/';
export const stoneStatusEndPoint = baseUrl + '/masters/change-stone-status/';

export const centgroupListEndPoint = baseUrl + '/masters/cent-group-list/';
export const createcentgroupEndPoint = baseUrl + '/masters/cent-group/';
export const centgropStatusEndPoint = baseUrl + '/masters/change-cent-group-status/';

export const rangestockListEndPoint = baseUrl + '/masters/range-stock-list/';
export const createrangestockEndPoint = baseUrl + '/masters/range-stock/';  
export const rangestockStatusEndPoint = baseUrl + '/masters/change-range-stock-status/';
export const rangestockReportEndPoint = baseUrl + '/masters/range-stock-report/';

export const accountheadListEndPoint = baseUrl + '/books/account-head-list/';
export const accountheadEndPoint = baseUrl + '/books/account-head/';  
export const accountheadStatusEndPoint = baseUrl + '/books/change-account-head-status/';
export const accountheadBankMultiDeleteEndPoint = baseUrl + '/books/account-head-delete-bank/';
export const accountheadAddressMultiDeleteEndPoint = baseUrl + '/books/account-head-delete-address/';
export const accountheadContactMultiDeleteEndPoint = baseUrl + '/books/account-head-delete-contact/';

export const customertypeListEndPoint = baseUrl + '/books/customer-type-list/';
export const accounttypeListEndPoint = baseUrl + '/books/account-type-list/';
export const groupnameListEndPoint = baseUrl + '/books/account-group-list/';

export const itemMasterListEndPoint = baseUrl + '/product/item-list/';
export const itemMasterEndPoint = baseUrl + '/product/item/';  
export const itemStatusEndPoint = baseUrl + '/masters/change-range-stock-status/';
export const itemIdEndPoint = baseUrl + '/product/item-id/';
export const itemmasterStatusEndPoint = baseUrl + '/product/change-item-status/';
export const itemListbyMetalEndPoint = baseUrl + '/product/item-metal/';
export const itemListByStockMetalEndPoint = baseUrl + '/product/item-metal-stock/';

export const subitemListEndPoint = baseUrl + '/product/sub-item-list/';
export const subitemidcodeEndPoint = baseUrl + '/product/sub-item-id/';
export const subitemEndPoint = baseUrl + '/product/sub-item/';
export const subitemStatusEndPoint = baseUrl + '/product/change-sub-item-status/';
export const subitemByItemEndPoint = baseUrl + '/product/sub-item-item/';
export const subitemByMetalStockEndPoint = baseUrl + '/product/sub-item-metal-stock-calc/';

export const entryTypeListEndPoint = baseUrl + '/tagging/entry-type/';
export const getLotIdListEndPoint = baseUrl + '/tagging/lot-id/';
export const lotListEndPoint = baseUrl + "/tagging/lot-list/";
export const lotEndPoint = baseUrl + "/tagging/lot/";
export const lotItemDeleteEndPoint = baseUrl + "/tagging/lot-item/";
export const lotStoneDeleteEndPoint = baseUrl + "/tagging/lot-stone/";
export const lotDiamondDeleteEndPoint = baseUrl + "/tagging/lot-diamond/";
export const lotStoneListEndPoint = baseUrl + "/tagging/lot-stone-list/";
export const lotDiamondListEndPoint = baseUrl + "/tagging/lot-diamond-list/";

export const getTagNumbersListEndPoint = baseUrl + "/tagging/tag-number/";
export const getTagDetailsByBranchEndPoint = baseUrl + '/tagging/approval-tag-item/';

export const weightTypeListEndPoint = baseUrl + "/product/weight-type/";
export const rateTypeListEndPoint = baseUrl + "/tagging/rate-type/";
export const diamondListEndPoint = baseUrl + "/tagging/diamond-list/";
export const measurementTypeEndPoint = baseUrl + '/product/measurement-type/';
export const calculationtypeDropdownEndPoint = baseUrl + '/product/calculation-type/';
export const stocktypeDropdownEndPoint = baseUrl + '/product/stock-type/';
export const itemUploadImgEndPoint = baseUrl + '/product/item-image-upload/';
export const subItemUploadImgEndPoint = baseUrl + '/product/sub-item-image-upload/';
export const tagNumbertypeEndPoint = baseUrl + '/tagging/tag-entry-list/';
export const taggingWeightTypeListEndPoint = baseUrl + "/tagging/weight-type/";

export const tagdetailsEndPoint = baseUrl + '/tagging/tag-item/';
export const taggingEndPoint = baseUrl + '/tagging/tag/';
export const itemstockEndPoint = baseUrl + '/product/item-metal-stock/';
export const taggingListEndPoint = baseUrl + '/tagging/tag-entry-list/';
export const multiTagdetailsEndPoint = baseUrl + '/tagging/tag-item-list/';

export const valueAddtionCustomerEndPoint = baseUrl + '/value-addition/value-addition-customer/';
export const valueAddtionCustomerListEndPoint = baseUrl + '/value-addition/value-addition-customer-list/';
export const subItemByMetalStockEndPoint = baseUrl + '/product/sub-item-metal-stock-calc/';

export const valueAddtionDesignerListEndPoint = baseUrl + '/value-addition/sub-item-details/';
export const valueAddtionDesignerEndPoint = baseUrl + '/value-addition/value-addition-designer/';
export const subItemMultipleEndpoint = baseUrl + '/product/sub-item-item/';

export const customerEndPoint = baseUrl + '/customer/customer/';
export const customerListEndPoint = baseUrl + '/customer/customer-list/';
export const customerByMobileEndPoint = baseUrl + '/customer/customer-mobile-search/'

export const customerGroupListEndPoint = baseUrl + '/customer/customer-group-list/';
export const customerGroupEndPoint = baseUrl + '/customer/customer-group/';
export const customerGroupStatusEndPoint = baseUrl + '/customer/customer-group-status/';

export const SingleTagUpdateEndPoint = baseUrl + '/tagging/single-tag-update/';

export const orderIdGenerateEndPoint = baseUrl + '/order/autogenerate-order-id/';
export const orderForListEndPoint = baseUrl + '/order/order-for-list/';
export const priyorityListEndPoint= baseUrl + "/order/priority-list/";
export const genderTypeEndPoint = baseUrl + '/settings/gender-list/';
export const orderEndPoint = baseUrl + '/order/order/';
export const orderDetailsListEndPoint = baseUrl + '/order/order-list/';
export const addNewPaymentsEndPoint = baseUrl + '/order/make-order-payments/';
export const cancelOrderDetailsEndPoint = baseUrl + '/order/cancel-order/';
export const uploadOrderImagesEndPoint = baseUrl + '/order/file-upload/';
export const orderItemEndPoint = baseUrl + '/order/order-item/';
export const assignDesignerEndPoint = baseUrl + '/order/assign-designer/';
export const recieveOrderItemsEndPoint = baseUrl + '/order/recieve-items/';

export const AdvancePaymentListEndPoint = baseUrl + '/advance-payment/advance-payment-list/';
export const AdvancePurposeEndPoint = baseUrl + '/advance-payment/advance-purpose/';
export const AdvancepaymentEndPoint = baseUrl + '/advance-payment/advancepayment/';
export const AdvancePaaymentIdEndPoint = baseUrl + '/advance-payment/advance-number-autogenerate/';

export const getEstimateBillNoEndPoint = baseUrl + '/billing/estimation-number-autogenerate/';
export const estimationEndPoint = baseUrl + '/billing/estimation-without-stock/';
export const estimationListEndPoint = baseUrl + '/billing/estimation-list/'
export const oldGoldNumberEstGenerateEndPoint = baseUrl + '/billing/oldgold-number-autogenerate/';
export const estimationApprovalEndPoint = baseUrl + '/billing/estimation-status-approval/';
export const estimationDetailGetEndPoint = baseUrl + '/billing/estimation-billing/';

export const estimationApprovalListEndPoint = baseUrl + '/billing/estimate-number-search/';

export const getBillNoEndPoint = baseUrl + '/billing/bill-number/';
export const getBackupBillGoldNumberEndPoint = baseUrl + '/billing_backup/backup-bill-gold-number/';
export const getBackupBillSilverNumberEndPoint = baseUrl + '/billing_backup/backup-bill-silver-number/';
export const billingEndPoint = baseUrl + '/billing/sale-bill/';
export const billingBackupEndPoint = baseUrl + '/billing_backup/sale-bill-backup/';
export const billingEndListPoint = baseUrl + '/billing/sale-bill-list/';
export const billingGetByIdEndPoint = baseUrl + '/billing/sale-bill/';

export const oldGoldNumberBillGenerateEndPoint = baseUrl + '/billing/oldgold-number-bill/';

export const DuplicateTagEndPoint = baseUrl + '/tagging/duplicate-tag/';
export const getduplicateTagEndPoint = baseUrl + '/tagging/duplicate-tag/';

export const getStockListEndPoint = baseUrl + '/tagging/stock-list/'
export const getBackupStockListEndPoint = baseUrl + '/tagging/huid-stock-list/'

export const ApprovalTypeListEndPoint = baseUrl + '/approval/approval-type-list/';
export const ApprovalTypeEndPoint = baseUrl + '/approval/approval-type/';
export const ApprovalTypeStatusEndPoint = baseUrl + '/approval/approval-type-status/';

export const ApprovalruleListEndPoint = baseUrl + '/approval/approval-rule-list/'
export const ApprovalruleEndPoint = baseUrl + '/approval/approval-rule/'
export const GetApprovalruleEndPoint = baseUrl + '/organizations/user-list-by-userrole/'
export const GetApprovalruleStatusEndPoint = baseUrl + '/approval/approval-rule-status/'

export const EstimationApprovalListEndPoint = baseUrl + '/billing/estimation-details-approval-list/'

export const EstimationApprovalEndPoint = baseUrl + '/billing/estimation-status-approval/'

export const transferTableListEndPoint = baseUrl + "/stock/transfer-item-list/";
export const branchTransferEndPoint = baseUrl + "/stock/tansferitem/";
export const receivedTableListEndPoint = baseUrl + "/stock/received-item-list/";
export const approveTransferEndPoint = baseUrl + "/stock/approvetransferitem/";
export const receivedTrasferEndPoint = baseUrl + "/stock/receiveditem/";
export const returnTranferEndPoint = baseUrl + "/stock/returnitem/";
export const returnTableListEndPoint = baseUrl + "/stock/return-item-list/";
export const approveReturnStatusEndPoint = baseUrl + "/stock/approvereturnitem/";
export const transferTagDetailsEnsPoint = baseUrl + "/stock/tagged-branch-item-number/";

export const stockTransferTypeListEndPoint = baseUrl + "/stock/transfertype/";
export const transferStatusByStatusEndPoint = baseUrl + "/stock/transferstatus/";

export const tagStatusdropdownByStatusEndPoint = baseUrl + "/settings/status-list/";
export const transferStatusdropdownByStatusEndPoint = baseUrl + "/stock/transferfilterstatus/";

export const salereportListEndPoint = baseUrl + '/report/estimation-report-list/'
export const itemStockreportListEndPoint = baseUrl + '/report/itemreport-list/'

export const itemWiseRportEndPoint = baseUrl + '/report/tagged-itemwise-list/'

export const purchaseentryListEndPoint = baseUrl + '/purchase/purchaseentry-list/';
export const purchaseorderIdEndPoint = baseUrl + '/purchase/purchaseorder-id/';
export const purchaseOrderValueAdditionDetailsEndPoint = baseUrl + '/value-addition/purchase-value/';

export const salesReturnPolicyDaysUpdateEndPoint = baseUrl + "/settings/return-policy/";

export const repairtypeListEndPoint = baseUrl + '/masters/repair-type-list/';
export const repairtypeEndPoint = baseUrl + '/masters/repair-type/';
export const repairtypeStatusEndPoint = baseUrl + '/customer/customer-group-status/';

export const settingsVerificationEndPoint = baseUrl + '/settings/verification/'

export const repairManagementEndPoint = baseUrl + '/repair-management/repair-order-list/'
export const repairTypeDropdownEndPoint = baseUrl + '/repair-management/repair-type-dropdown/'
export const repairMetalListEndPoint = baseUrl + '/masters/metal-list/'
export const repairOrdernoEndPoint = baseUrl + '/repair-management/repair-order-id/'
export const repairManagementdropdownEndPoint = baseUrl + '/repair-management/get-repair-for-dropdown/'
export const repairManagementcreateEndPoint = baseUrl + '/repair-management/order-repair-details/'

export const counterTargetListEndPoint = baseUrl + '/infrastructure/counter-target-list/';
export const counterTargetEndPoint = baseUrl + '/infrastructure/counter-target/';


// export const settingsVerificationEndPoint = baseUrl + '/settings/verification/';

export const repairissueListEndPoint = baseUrl + '/repair-management/repair-issue-list/'
export const repairissueEndPoint = baseUrl + '/repair-management/repair-order-issue/'

export const repairOrderListEndPoint = baseUrl + '/repair-management/repair-order-list/'
export const repairOrderGetListEndPoint = baseUrl + '/repair-management/order-repair-details/'

export const getRepairidEndPoint = baseUrl + '/repair-management/repair-issue-id/'
export const paymentEndPoint = baseUrl + '/settings/payment-status/'

// purchase
export const purchaseEndPoint = baseUrl + '/purchase/purchaseentry/'
export const autoGeneratePOIDEndPoint = baseUrl + '/purchase/newpurchase-id/';
export const newPurchaseEndPoint = baseUrl + '/purchase/new-purchase/';
export const itemListForPurchaseEndPoint = baseUrl + '/purchase/item-list/';
export const purchasereturnItemDetailsEndPoint = baseUrl + '/purchase/calculate/'
export const newPurchaseListEndPoint = baseUrl + '/purchase/newpurchase-list/';

// vendor due
export const generateVendorPaymentId = baseUrl + '/purchase/vendorpayment-id/';
export const vendorDetailsByIdEndPoint = baseUrl + '/purchase/getvendordetail/';
export const vendorDueListEndPoint = baseUrl + '/purchase/vendor-list/';
export const amountRateCutEndPoint = baseUrl + '/purchase/amount-rate-cut/';
export const metalRateCutEndPoint = baseUrl + '/purchase/metal-rate-cut/';
export const cashRateCutEndPoint = baseUrl + '/purchase/cash-rate-cut/';

export const billingGetEndPoint = baseUrl + '/purchase/billing-list/'
export const purchaseAllGetEndPoint = baseUrl + '/purchase/billing/'


export const repairEndPoint = baseUrl + '/repair-management/vendor-receipt-list/'
export const repairVendorGetEndPoint = baseUrl + '/repair-management/vendor-receipt/'

export const repairVendorid = baseUrl + '/repair-management/vendor-repair-receipt-id/'

export const customerReciptListEndPoint = baseUrl + '/repair-management/customer-receipt-list/'
export const customerReciptIdEndPoint = baseUrl + '/repair-management/customer-repair-receipt-id/'
export const customerReciptDetailsEndPoint = baseUrl + '/repair-management/customer-receipt/'


export const billingRepairIdEndPoint = baseUrl + '/repair-management/delivery-note-id/'

export const deliveryBillingRepairIdEndPoint = baseUrl + '/repair-management/delivery-bill-list/'
export const deliveryBillRepairIdEndPoint = baseUrl + '/repair-management/delivery-bill/'

export const transferCreationTypeListEndPoint = baseUrl + '/refinery-management/transfer-creation-type-list/';
export const generateBagNumberEndPoint = baseUrl + '/refinery-management/bag-number/';
export const oldMetalCategoryListEndPoint = baseUrl + '/refinery-management/old-metal-category-list/';
export const getOldGoldListEndPoint = baseUrl + '/refinery-management/all-old-gold-list/';
export const transferCreationEndPoint = baseUrl + '/refinery-management/trasfer-creation/';
export const transferCreationListEndPoint = baseUrl + '/refinery-management/trasfer-creation-list/';

export const generateMeltingIssueNumberEndPoint = baseUrl + '/refinery-management/melting-issue-number/';
export const meltingIssueEndPoint = baseUrl + '/refinery-management/melting-issue/';
export const meltingIssueListEndPoint = baseUrl + '/refinery-management/melting-issue-list/';

export const generateMeltingRecieptNumberEndPoint = baseUrl + '/refinery-management/melting-recipt-number/';
export const meltingRecieptListEndPoint = baseUrl + '/refinery-management/melting-recipt-list/';
export const meltingRecieptEndPoint = baseUrl + '/refinery-management/melting-recipt/';

export const generatePurificationIssueNumberEndPoint = baseUrl + '/refinery-management/purification-issue-number/';
export const purificationIssueListEndPoint = baseUrl + '/refinery-management/purification-issue-list/';
export const purificationIssueEndPoint = baseUrl + '/refinery-management/purification-issue/';

export const generatePurificationRecieptNumberEndPoint = baseUrl + '/refinery-management/purification-recipt-number/';
export const purificationRecieptListEndPoint = baseUrl + '/refinery-management/purification-recipt-list/';
export const purificationRecieptEndPoint = baseUrl + '/refinery-management/purification-recipt/';

export const billingUpdateEndPoint = baseUrl + '/billing/sale-bill-update/'
export const headercountEndPoint = baseUrl + '/dashboard/dashboard-count/'
export const totalpaymentEndPoint = baseUrl + '/dashboard/total-payment/'
export const metalwisesalesEndPoint = baseUrl + '/dashboard/metal_wise_sales/'
export const saleslistEndPoint = baseUrl + '/dashboard/billing-list/'
export const stocklistEndPoint = baseUrl + '/dashboard/item-stock-list/'
export const purchaselistEndPoint = baseUrl + '/dashboard/purchase-list/'
export const purchasevssalesEndPoint = baseUrl + '/dashboard/salesvspurchase-list/'
export const purchaseDashboardEndPoint = baseUrl + '/purchase/purchaseentry/'
export const salesdetailsEndPoint = baseUrl + '/billing/sale-bill/'
export const stockdetailsEndPoint = baseUrl + '/product/item/'
export const vendorwiseEndPoint = baseUrl + '/dashboard/vendorwise-list/'

// MiscIssue
export const autoGenerateMiscIssueIdEndPoint = baseUrl + '/billing/generate-misc-id/';
export const createMiscIssueEndPoint = baseUrl + '/billing/create-misc/';


// requisition approval
export const generateRequisitionApprovalIdEndPoint = baseUrl + '/stock/approval-issue-number/';
export const requisitionApprovalEndPoint = baseUrl + '/stock/approval-issue/';
export const requisitionApprovalListEndPoint = baseUrl + '/stock/approval-issue-list/';
export const deleteApprovalIssueItemEndPoint = baseUrl + '/stock/approval-issue-tag/';
export const approvalRecieptEndPoint = baseUrl + '/stock/approval-recipt/';
export const estimationdataEndPoint = baseUrl + '/billing/metal-estimation-list/';

export const shopListEndPoint = baseUrl + '/customer/shop-list/';

// export const paymentMethodEndPoint = baseUrl + '/payment-management/payment-method-list/'
// export const voucherListEndPoint = baseUrl + '/masters/card-type-list/'

// export const billTypePaymentEndPoint = baseUrl + '/billing/bill-type/'
// export const silverEstimationEndPoint = baseUrl + '/billing/silver-bill-number/'
// export const goldEstimationEndPoint = baseUrl + '/billing/gold-estimation-number/'

export const billTypePaymentEndPoint = baseUrl + '/billing/bill-type/'
export const goldEstimationEndPoint = baseUrl + '/billing/gold-estimation-number/'
export const silverEstimationEndPoint = baseUrl + '/billing/silver-estimation-number/'
export const silverTagEndPoint = baseUrl + '/tagging/tag-item-silver/'
export const goldTagEndPoint = baseUrl + '/tagging/tag-item-gold/'
export const silverBillTagEndPoint = baseUrl + '/billing/silver-bill-number/'

export const paymentMethodEndPoint = baseUrl + '/payment-management/payment-method-list/'
export const paymentProviderEndPoint = baseUrl + '/payment-management/payment-provider-list/'
export const cardtypeListEndPoint = baseUrl + '/masters/card-type-list/'
export const cardnameListEndPoint = baseUrl + '/payment-management/payment-provider-list/'
export const voucherListEndPoint = baseUrl + '/masters/card-type-list/'
export const chitDetailsEndPoint = thirdpartybaseUrl + '/webadmin/master/dashboard/dashboardcount'

export const UpipaymentListEndPoint = baseUrl + '/payment-management/payment-provider-list/'
export const billingDrangEndPoint = baseUrl + '/billing_backup/truncate-model/'

export const SalesDailyReportListEndPoint = baseUrl + '/report/billing-report-list/'
export const SalesDetailsReportListEndPoint = baseUrl + '/report/sale-details-report/'
export const customerSalesReportListEndPoint = baseUrl + '/report/customer-sale-report/'
export const customermobileEndPoint = baseUrl + '/customer/customer-list/'
export const itemwiseSaleReportListEndPoint = baseUrl + '/report/itemwise-sale-report/'
export const LotReportListEndPoint = baseUrl + '/report/lot-report/'
export const itemnameEndPoint = baseUrl + '/product/item-list/'
export const vendornameEndPoint = baseUrl + '/books/account-head-list/'
export const vendorwiseListEndPoint = baseUrl + '/report/vendor-lot-report/'
export const purchaseListEndPoint = baseUrl + '/report/purchase-report/'
export const itemwisepurchaseListEndPoint = baseUrl + '/report/item-purchase-report/'
export const vendorwisepurchaseListEndPoint = baseUrl + '/report/vendor-purchase-report/'
export const oldpurchasereportListEndPoint = baseUrl + '/report/old-purchase-report/'

export const stockSummaryEndPoint = baseUrl + '/report/stock-summary-report/'
export const stockTagWiseEndPoint = baseUrl + '/report/tagwise-stock-report/'
export const customerissuerepairEndPoint = baseUrl + '/report/customer-repair-issue-report/'
export const vendorissuerepairEndPoint = baseUrl + '/report/vendor-repair-issue-report/'


export const TagvalueEndPoint = baseUrl + '/value-addition/tag-value/'
export const SingleTagEndPoint = baseUrl + '/tagging/single-tag/'

export const paymentViewEndPoint = baseUrl + '/payment-management/payment-view/'
export const gstViewEndPoint = baseUrl + '/masters/gst-list/'

//A4 or Thermal
export const PrintSizeEndPoint = baseUrl + '/settings/print-list/'
