import { useEffect, useState } from "react";
import CustomerDetailsForm from "./customerdetailsform";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrderItemForm from "./orderitemform";
import ParticularList from "./widgets/particularlist";
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";
import toast from "react-hot-toast";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { orderEndPoint, orderIdGenerateEndPoint, orderItemEndPoint, paymentViewEndPoint } from "../../../service/api";
import { cash, goldMetal, orderForCustomer, orderpayment, pendingStatusType, repairpayment, silverMetal } from "../../../constant";
import Popup from "../../../components/Popup";
import AssignDesignerForm from "./widgets/assigndesignerform";
import TableImageSlider from "./widgets/tableimageslider";
import PaymentDetails from "./widgets/paymentdetails";
import AddPayments from "./widgets/addpayments";
import OldMetalDetails from "./widgets/oldmetaldetails";

export default function OrderDetailsForm() {

    const { id } = useParams();
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');

    const [errors, setErrors] = useState({});
    const [itemErrors, setItemErrors] = useState({});

    const [metalRate, setMetalRate] = useState(0);

    const [orderDetailValues, setOrderDetailValues] = useState({
        customer_id: "",
        order_id: "",
        branch: "",
        customer_no: "",
        no_of_days: "",
        due_date: "",
        priority: "",
        order_date: new Date().toLocaleDateString('en-CA'),
        order_for: orderForCustomer
    });

    const [paymentDetailValues, setPaymentDetailValues] = useState({
        order_id: "",
        gst_type: 1,
        igst: 3,
        sgst: 1.5,
        cgst: 1.5
    });
    const [formValues, setFormValues] = useState({
        measurement_type: ""
    })

    const [selectedItems, setSelectedItems] = useState([])

    const [particularsList, setParticularsList] = useState([]);

    const [addStoneList, setAddStoneList] = useState(false);
    const [addDiamondList, setAddDiamondList] = useState(false);

    const [stoneList, setStoneList] = useState([]);
    const [diamondList, setDiamondList] = useState([]);

    const [assignPopupOpen, setAssignPopupOpen] = useState(false);

    const [imagePopupOpen, setImagePopupOpen] = useState(false);

    const [images, setImages] = useState([]);

    const [paymentScreen, setPaymentScreen] = useState(false);
    const [Paymentcreate, setPaymentcreate] = useState(false);
    const [oldMetalOpen, setOldMetalOpen] = useState(false);

    const [oldMetalList, setOldMetalList] = useState([]);

    const [grandTotal, setGrandTotal] = useState(0.0);
    const [advanceAmount, setAdvanceAmount] = useState(0.0);
    const [balanceAmount, setBalanceAmount] = useState(0.0);


    const [paymentMethodDropDown, setPaymentMethodDropDown] = useState([]);

    function closeAssignModal() {
        setAssignPopupOpen(false)
    }

    function closeImageModal() {
        setImagePopupOpen(false)
    }

    function closeOldMetalModal() {
        setOldMetalOpen(false)
    }

    useEffect(() => {
        if (pathname.includes('update')) {
            setFormMode('update')
            getOrderDetails()
        } else {
            getOrderId()
            console.log("asdfghjkl")

        }

    }, [])

    useEffect(() => {
        let totalQuantity = particularsList.length;
        let totalWeight = 0.0;
        let totalAmount = 0.0;
        let totalStoneAmt = 0.0;
        let totalDiamondAmt = 0.0;
        for (var i of particularsList) {
            totalWeight = totalWeight + parseFloat(i.gross_weight)
            totalAmount = totalAmount + parseFloat(i.total_amount)
            totalStoneAmt = totalStoneAmt + parseFloat(i.total_stone_amount)
            totalDiamondAmt = totalDiamondAmt + parseFloat(i.total_diamond_amount)
        }
        setOrderDetailValues((prevState) => ({
            ...prevState,
            total_quantity: totalQuantity,
            total_weight: totalWeight,
            approximate_amount: totalAmount
        }))

        let gstPercent = 0;
        if (paymentDetailValues.gst_type == 1) {
            gstPercent = paymentDetailValues.cgst + paymentDetailValues.cgst
        } else if (paymentDetailValues.gst_type == 2) {
            gstPercent = paymentDetailValues.igst
        }

        let gstAmount = totalAmount * (gstPercent / 100)
        setPaymentDetailValues((prevState) => ({
            ...prevState,
            approximate_amount: totalAmount,
            stone_amount: totalStoneAmt,
            diamond_amount: totalDiamondAmt,
            gst_amount: gstAmount
        }))
        setGrandTotal(totalAmount + gstAmount)
    }, [particularsList])

    useEffect(() => {
        let otherAmt = parseFloat(paymentDetailValues.others || 0);
        let gstAmt = parseFloat(paymentDetailValues.gst_amount || 0);
        let totalAmt = parseFloat(paymentDetailValues?.approximate_amount || 0);
        let makingChargePerGramAmt = parseFloat(orderDetailValues.total_weight || 0) * parseFloat(paymentDetailValues.making_charge_pergram || 0);
        let flatMCAmt = parseFloat(paymentDetailValues.flat_making_charge || 0);
        let roundOffAmt = parseFloat(paymentDetailValues.round_off || 0);
        let oldGoldAmt = parseFloat(paymentDetailValues.advance_old_gold_amount || 0);
        let oldSilverAmt = parseFloat(paymentDetailValues.advance_old_silver_amount || 0);
        let discountAmt = parseFloat(paymentDetailValues.discount_amount || 0);
        setGrandTotal(totalAmt + gstAmt + otherAmt + makingChargePerGramAmt + flatMCAmt + roundOffAmt - (oldGoldAmt + oldSilverAmt + discountAmt))
    }, [paymentDetailValues])

    console.log(paymentDetailValues, "paymentDetailValuespaymentDetailValues")

    useEffect(() => {
        setBalanceAmount(grandTotal - (advanceAmount || 0))
    }, [advanceAmount, grandTotal])

    useEffect(() => {
        let silverRate = 0.0;
        let goldRate = 0.0;
        for (var i of oldMetalList) {
            if (i.metal == goldMetal) {
                goldRate = goldRate + parseFloat(i.total)
            } else if (i.metal == silverMetal) {
                silverRate = silverRate + parseFloat(i.total)
            }
        }
        setPaymentDetailValues((prevState) => ({
            ...prevState,
            advance_old_gold_amount: goldRate,
            advance_old_silver_amount: silverRate
        }))
    }, [oldMetalList])

    const getOrderId = async () => {
        var response = await getAxios({
            url: orderIdGenerateEndPoint
        })

        if (response != null) {

            if (response.data.from_draft) {
                setOrderDetailValues(response.data)
            } else {
                setOrderDetailValues((prevState) => ({
                    ...prevState,
                    order_id: response.data.order_id,
                }))
            }
            setPaymentDetailValues((prevState) => ({
                ...prevState,
                order_id: response.data.order_id,
            }))
            getOrderItems(response.data.order_id)
        }
    }

    const getOrderDetails = async () => {
        var response = await getAxios({
            url: orderEndPoint + id + '/'
        })

        if (response != null) {
            let tempDetails = {
                order_id: response.data.order_id,
                order_for: response.data.order_for,
                order_date: response.data.order_date,
                no_of_days: response.data.no_of_days,
                due_date: response.data.due_date,
                branch: response.data.branch,
                priority: response.data.priority,
                total_weight: response.data.total_weight,
                total_quantity: response.data.total_quantity,
                approximate_amount: response.data?.approximate_amount,
                order_status: response.data.order_status
            }

            if (response.data.order_for == orderForCustomer) {
                tempDetails['customer_name'] = response.data.customer_name
                tempDetails['customer_no'] = response.data.customer_no
                tempDetails['customer'] = response.data.customer
            }
            setParticularsList(response.data.particular_list)
            setOrderDetailValues(tempDetails)
        }
    }

    const addItem = () => {

        let orderItemForm = new FormData(document.getElementById('orderitem'))

        var err = {}

        for (var field of Object.entries(Object.fromEntries(orderItemForm.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setItemErrors(err)

        if (isOptionalValidForm(Object.fromEntries(orderItemForm.entries()), ['description', 'measurement_type', 'measurement_value'])) {

            if (addStoneList) {
                if (formValues.total_stone_weight?.length <= 0 &&
                    formValues.total_stone_pieces?.length <= 0 &&
                    formValues.total_stone_amount?.length <= 0 &&
                    formValues.stone_description?.length <= 0) {
                    toast.error('One of the value of stone details is missing')
                    return;
                }
            } else {
                formValues['total_stone_weight'] = 0
                formValues['total_stone_pieces'] = 0
                formValues['total_stone_amount'] = 0
                formValues['stone_description'] = ""
            }
            if (addDiamondList) {
                if (formValues.total_diamond_weight?.length <= 0 &&
                    formValues.total_diamond_pieces?.length <= 0 &&
                    formValues.total_diamond_amount?.length <= 0 &&
                    formValues.diamond_description?.length <= 0) {
                    toast.error('One of the value of diamond details is missing')
                    return;
                }
            } else {
                formValues['total_diamond_weight'] = 0
                formValues['total_diamond_pieces'] = 0
                formValues['total_diamond_amount'] = 0
                formValues['diamond_description'] = ""
            }

            formValues['order_id'] = orderDetailValues.order_id
            formValues["metal_rate"] = metalRate
            formValues["actual_amount"] = (parseFloat(formValues.total_stone_amount) + parseFloat(formValues.total_diamond_amount)) + (parseFloat(metalRate) * parseFloat(formValues.net_weight))
            formValues["total_amount"] = (parseFloat(formValues.total_stone_amount) + parseFloat(formValues.total_diamond_amount)) + (parseFloat(metalRate) * parseFloat(formValues.net_weight))

            let orderDetailsForm = new FormData(document.getElementById('orderdetails'))

            var err = {}

            for (var field of Object.entries(Object.fromEntries(orderDetailsForm.entries()))) {
                if (field[1].length === 0) {
                    err[field[0]] = `${field[0]} field is required`
                } else {
                    err[field[0]] = ""
                }
            }

            setErrors(err)

            if (isValidForm(Object.fromEntries(orderDetailsForm.entries()))) {
                createOrderItems({ ...formValues, ...orderDetailValues })
            } else {
                toast.error('Fill the above order details')
            }
        }
    }


    console.log(particularsList, "particularsList")

    const handlePaymentSubmit = () => {
        let orderDetailsForm = new FormData(document.getElementById('orderdetails'))

        var err = {}

        for (var field of Object.entries(Object.fromEntries(orderDetailsForm.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)




        if (isValidForm(Object.fromEntries(orderDetailsForm.entries()))) {
            if (particularsList.length > 0) {
                let paymentlist = []

                for (var i of paymentMethodDropDown) {


                    var temdata = {
                        "customer_details": orderDetailValues.customer,
                        "payment_method": i.id,
                        "payment_provider": i.id === cash ? null : document.getElementById(i.payment_method_name + "provider")?.value,
                        "payment_module": orderpayment,
                        "paid_amount": document.getElementById(i.payment_method_name)?.value || null,
                        "payment_refference_number": document.getElementById(i.payment_method_name + "ref_number")?.value || null,
                        "refference_number": orderDetailValues.order_id
                    }
                    paymentlist.push(temdata)

                }

                console.log(">>>>>>> i", paymentlist)
                let data = {
                    estimate_charge: paymentDetailValues.approximate_amount,
                    refference_number: orderDetailValues.order_id,
                    gst_type: paymentDetailValues.gst_type,
                    igst: paymentDetailValues.igst,
                    sgst: paymentDetailValues.sgst,
                    cgst: paymentDetailValues.cgst,
                    discount_amount: paymentDetailValues.discount_amount,
                    discount_percentage: paymentDetailValues.discount_percent,
                    advance_old_gold_amount: paymentDetailValues.advance_old_gold_amount,
                    advance_old_silver_amount: paymentDetailValues.advance_old_silver_amount,
                    round_off: paymentDetailValues.round_off,
                    grand_total: grandTotal,
                    advance_amount: advanceAmount,
                    balance_amount: balanceAmount,
                    payment_details: paymentlist,
                    oldgold_particulars: oldMetalList
                }




                console.log(orderDetailValues, "orderDetailValues")
                if (formMode == 'create') {
                    createPaymentOrder(data)
                    // console.log(">>>>>>>>>>> data", data)
                } else {
                    updateOrder(data)
                }
            } else {
                toast.error("No items added")
            }
        }

    }


    const handleSubmit = () => {
        let orderDetailsForm = new FormData(document.getElementById('orderdetails'))

        var err = {}

        for (var field of Object.entries(Object.fromEntries(orderDetailsForm.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)


        if (isValidForm(Object.fromEntries(orderDetailsForm.entries()))) {
            if (particularsList.length > 0) {
                let data = {
                    ...orderDetailValues,
                    gst_type: paymentDetailValues.gst_type,

                }

                if (formMode == 'create') {
                    let tempList = []
                    for (var i of particularsList) {
                        if (i.is_assigned) {
                            tempList.push(1)
                        } else {
                            tempList.push(0)
                        }
                    }

                    if (tempList.includes(1)) {
                        toast.success("Order Created Successfully")
                        setPaymentScreen(true)
                    } else {
                        createOrder(data)
                    }
                } else {
                    updateOrder(data)
                    setPaymentScreen(true)
                }
            } else {
                toast.error("No items added")
            }
        }

    }

    const createOrderItems = async (body) => {
        var response = await postAxiosWithToken({
            url: orderItemEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            getOrderItems(formValues.order_id)
        }
    }

    const getOrderItems = async (id) => {
        var response = await getAxios({
            url: orderItemEndPoint + id + '/'
        })

        if (response != null) {
            setParticularsList(response.data)
        }
    }

    const createOrder = async (body) => {

        var response = await postAxiosWithToken({
            url: orderEndPoint,
            body: body,
        })


        if (response != null) {

            toast.success(response.message)
            setPaymentScreen(true)
        }
    }


    const createPaymentOrder = async (body) => {
        console.log(">>>>>>>>> body", body)
        var response = await postAxiosWithToken({

            url: paymentViewEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            setPaymentcreate(true)
        }
    }

    const updateOrder = async (body) => {
        var response = await putAxiosWithToken({
            url: orderEndPoint + id + '/',
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            setPaymentScreen(true)
        }
    }



    return (
        <div>
            <p className="capitalize font-[500] text-[16px]">{formMode} order</p>

            <div className="bg-white p-1 rounded-lg mb-[100px]">
                <div className="xl:flex gap-5">
                    <div className="xl:w-[25%] border-2 rounded-lg bg-[#fff] shadow-lg p-3">
                        <CustomerDetailsForm formId={id} errors={errors} setErrors={setErrors} formValues={orderDetailValues} setFormValues={setOrderDetailValues} />

                    </div>

                    <div className="xl:w-[75%] mt-2 xl:mt-[-26px]">
                        <p className="font-[500] text-[17px]">Item Details</p>
                        <div className="border-2 rounded-lg bg-[#fff] shadow-lg p-3 xl:min-h-[278px]">
                            <OrderItemForm
                                metalRate={metalRate}
                                setMetalRate={setMetalRate}
                                orderDetails={orderDetailValues}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                errors={itemErrors}
                                setErrors={setItemErrors}
                                addDiamondList={addDiamondList}
                                addStoneList={addStoneList}
                                setAddDiamondList={setAddDiamondList}
                                setAddStoneList={setAddStoneList}
                                diamondList={diamondList}
                                setDiamondList={setDiamondList}
                                stoneList={stoneList}
                                setStoneList={setStoneList}
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-3 mt-3">
                    <div className="mb-2 flex items-end justify-between">
                        <p className="font-[500] text-[17px]">Particular Details</p>
                        <button
                            onClick={(e) => { e.preventDefault(); addItem() }}
                            className="mt-[5px] capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[2px] h-fit font-[500] text-[13px] outline-none border-none"
                        >Add Item</button>
                    </div>
                    <ParticularList orderDetails={orderDetailValues} particularsList={particularsList} setParticularsList={setParticularsList} setAssignOpen={setAssignPopupOpen} selectedItems={selectedItems} setSelectedItems={setSelectedItems} setImagePopupOpen={setImagePopupOpen} setImages={setImages} />
                </div>
                {particularsList.length > 0 && <div>
                    <p className="font-[500] text-[17px]">VendorDetails</p>
                    <AssignDesignerForm inPopUp={false} items={selectedItems} setItems={setSelectedItems} orderDetails={orderDetailValues} closeModel={closeAssignModal} getData={getOrderItems} />
                </div>}
            </div>

            <div className={`${paymentScreen ? 'translate-y-0' : 'translate-y-[83%]'} transition ease-in-out fixed bottom-[8px] min-[900px]:bottom-[30px] z-[5] left-0 w-full p-5 bg-white border-t`}>
                <div className="flex justify-between">
                    <div className="flex">
                        {
                            paymentScreen && <div onClick={() => setPaymentScreen(false)} className="cursor-pointer hover:text-white text-white">
                                <div className="bg-[red] text-[white] p-[3px] rounded-l-lg text-center text-[13px]" id="close">Close</div>
                            </div>
                        }
                        <div className="bg-primary-btn cursor-pointer text-[white] p-[3px] rounded-r-lg text-center text-[13px]" id="payment" onClick={() => setPaymentScreen(true)}>Add Payment  <span className="">(F1)</span></div>
                    </div>
                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[3px] font-[500] text-[13px] outline-none border-none"
                        >close<span className="lowercase">(esc)</span></button>
                        {!paymentScreen ? <button
                            onClick={(e) => { e.preventDefault(); handleSubmit() }}
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button> :
                            <button
                                onClick={(e) => { e.preventDefault(); handlePaymentSubmit(); navigate(-1) }}
                                className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                            >save<span className="lowercase">(ctrl+s)</span></button>}
                    </div>
                </div>
                <div className="min-[900px]:flex min-[900px]:gap-3 mt-4">
                    <div className="min-[900px]:w-[35%]">
                        <button
                            onClick={(e) => { e.preventDefault(); setOldMetalOpen(true) }}
                            className="mb-2 capitalize bg-primary-btn bg-opacity-50 hover:bg-opacity-100 text-black hover:text-white w-fit rounded p-[5px] px-[5px] text-[12px] outline-none border border-primary-btn"
                        >Old metal</button>
                        <PaymentDetails formValues={paymentDetailValues} setFormValues={setPaymentDetailValues} grandTotal={grandTotal} setGrandTotal={setGrandTotal} />
                    </div>
                    <div className="flex gap-3 w-full min-[900px]:w-[65%] max-[899px]:mt-3">
                        <div className="w-[20%]">
                            <div className="bg-[#E2E8F0] rounded-lg p-[10px] h-full">
                                <div className="max-[899px]:mt-2">
                                    <p className="text-[13px]">Grand Total</p>
                                    <p className="text-[13px]">₹ {grandTotal}</p>
                                </div>
                                <div className="min-[900px]:mt-3">
                                    <p className="text-[13px]">Advance</p>
                                    <input
                                        type="number"
                                        placeholder="₹ 0.00"
                                        className="sm:mb-[2px] border border-gray-300 rounded w-full p-[3px] text-[13px] outline-none"
                                        value={advanceAmount}
                                        onChange={(e) => setAdvanceAmount(parseFloat(e.target.value))}
                                    />
                                </div>
                                <div className="mt-2">
                                    <p className="text-[13px]">Balance Amount</p>
                                    <p className="text-[13px]">₹ {balanceAmount}</p>
                                </div>
                            </div>
                        </div>

                        <div className="w-[80%]">
                            <AddPayments orderDetails={orderDetailValues} setPaymentMethodDropDown={setPaymentMethodDropDown} paymentMethodDropDown={paymentMethodDropDown} />
                        </div>

                    </div>
                </div>
            </div>

            <Popup title={'Assign Designer'} setIsOpen={setAssignPopupOpen} isOpen={assignPopupOpen} closeModal={closeAssignModal}>
                <AssignDesignerForm items={selectedItems} setItems={setSelectedItems} orderDetails={orderDetailValues} closeModel={closeAssignModal} getData={getOrderItems} />
            </Popup>

            <Popup extraClassName="min-w-[800px] min-h-[500px]" title={'Attachments'} setIsOpen={setImagePopupOpen} isOpen={imagePopupOpen} closeModal={closeImageModal}>
                <TableImageSlider imagesList={images} />
            </Popup>

            <Popup extraClassName="min-w-[800px] max-h-[500px]" title={'Old Metal'} setIsOpen={setOldMetalOpen} isOpen={oldMetalOpen} closeModal={closeOldMetalModal}>
                <OldMetalDetails particularsList={oldMetalList} setParticularsList={setOldMetalList} formType={formMode} closeModal={closeOldMetalModal} />
            </Popup>
        </div>
    );
}