import { useEffect, useRef, useState } from "react";
import {  postAxiosWithToken } from "../../../service/apiservice";
import {   itemWiseRportEndPoint } from "../../../service/api";
import { useKeyPress } from "../../../hooks/useKeyPress";
import ItemwisereportFilters from "./itemstockreportfilter";
import {  useSelector } from "react-redux";
import { ExportToExcel } from "../../../components/ExportToExcel";
import Pagination from "../../../components/Pagination";
import ExcelViewer from "../../../components/ExcelViewer";

export default function Itemwisereport() {

    const searchRef = useRef();

    const [itemwisereportList, setItemwisereportList] = useState([]);
    const [filteredItemwisereportList, setFilteredItemwisereportList] = useState([]);
    
    const [isLoading, setIsLoading] = useState(true);

    const fileName = "Item Wise Stock Report"

    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const [totalPages, setTotalPages] = useState(1);

    const {  pagination } = useSelector((state) => state.appConfig);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    function closeExcelModal() {
        setExcelOpen(false)
    }

    useEffect(() => {
        if (itemwisereportList.length > 0) {
            let firstData = Object.keys(itemwisereportList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [itemwisereportList])

    useEffect(() => {
        getItemwisereportListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);


    const getItemwisereportListData = async (body) => {
        var response = await postAxiosWithToken({   
            url: itemWiseRportEndPoint,
            body: body
        })

        if (response !== null) {
            setItemwisereportList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <>
            <div>
                <div className="flex items-center justify-between">
                    <p className="text-[24px] font-[700]">
                    Item Wise Report
                    </p>
                    <div className="flex gap-3 items-center">
                        <ExcelViewer title={fileName} data={itemwisereportList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={filteredItemwisereportList} fileName={fileName} />
                    </div>
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>   
                    <ItemwisereportFilters filters={tableFilters} setFilters={setTableFilters} searchRef={searchRef} getData={getItemwisereportListData} dataList={itemwisereportList} setFilteredData={setFilteredItemwisereportList}  />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Item Name</td>
                                <td className="p-[10px] min-w-[150px]">Item Type</td>
                                <td className="p-[10px] min-w-[150px]">Qty</td>
                                <td className="p-[10px] min-w-[150px]">Gross Wt</td>
                                <td className="p-[10px] min-w-[150px]">Net Wt</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-menu-head-bg">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    itemwisereportList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                    itemwisereportList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.item_name}</td>
                                                <td className="p-[10px]">{item.metal_name}</td>
                                                <td className="p-[10px]">{item.tag_count}</td>
                                                <td className="p-[10px]">{item.gross_weight}</td>
                                                <td className="p-[10px]">{item.net_weight}</td>
                                                
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>

                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            
        </>
    );
}