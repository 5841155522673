import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { SalesDailyReportListEndPoint } from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import { datetimetodate } from "../../../utilities/datetime";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import SalesDailyReportFilters from "./salesdailyreportform";
import SalesdailyreportFilter from "./salesdailyfilter";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function SalesDailyReport() {

    const searchRef = useRef();
    const [salesdailyList, setSalesdailyList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Sales Daily Report"


    function closeExcelModal() {
        setExcelOpen(false)
    }


    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        metal_type: null,
        customer_details: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);


    useEffect(() => {
        if (salesdailyList.length > 0) {
            let firstData = Object.keys(salesdailyList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [salesdailyList])

    useEffect(() => {
        getSalesdailyListData(tableFilters)
    }, [tableFilters])



    const getSalesdailyListData = async (body) => {
        var response = await postAxiosWithToken({
            url: SalesDailyReportListEndPoint,
            body: body
        })

        if (response !== null) {
            setSalesdailyList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                    Sales Daily Report
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={salesdailyList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={salesdailyList} fileName={fileName} />
                </div>
            </div>
            <SalesDailyReportFilters searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <SalesdailyreportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }
                    
                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[13px] uppercase font-[700]">
                        <tr>
                            <td className="p-[10px]">Bill No</td>
                            <td className="p-[10px]">Bill date</td>
                            <td className="p-[10px]">Customer Name</td>
                            <td className="p-[10px]">mobile n0</td>
                            <td className="p-[10px]">Item  Name</td>
                            <td className="p-[10px]">Metal Type</td>
                            <td className="p-[10px]">Amount</td>
                            <td className="p-[10px]">CHIT</td>
                            <td className="p-[10px]">advance</td>
                            <td className="p-[10px]">discount</td>
                            <td className="p-[10px]">bill total</td>
                            <td className="p-[10px]">cASH</td>
                            <td className="p-[10px]">a/C Transfer</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-menu-head-bg">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={8}>Loading...</td>
                            </tr> :
                                salesdailyList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                    salesdailyList.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px] capitalize">{item.bill_no}</td>
                                            <td className="p-[10px] capitalize">{datetimetodate(item.bill_date)}</td>
                                            <td className="p-[10px] capitalize">{item.customer_name}</td>
                                            <td className="p-[10px] capitalize">{item.customer_mobile}</td>
                                            <td className="p-[10px] capitalize">{item.item_name}</td>
                                            <td className="p-[10px] capitalize">{item.metal_type}</td>
                                            <td className="p-[10px] capitalize">{item.amount}</td>
                                            <td className="p-[10px] capitalize">{item.chit_amount}</td>
                                            <td className="p-[10px] capitalize">{item.advance_amount}</td>
                                            <td className="p-[10px] capitalize">{item.discount_amount}</td>
                                            <td className="p-[10px] capitalize">{item.total_amount}</td>
                                            <td className="p-[10px] capitalize">{item.cash_amount}</td>
                                            <td className="p-[10px] capitalize">{item.account_transfer_amount}</td>
                                        </tr>
                                    ))
                        }

                    </tbody>
                </table>
            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}