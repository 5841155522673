export default function PendingPaymentList({ items, selectedItems, setSelectedItems }) {

    const onValueChecked = (checked, item) => {
        if (checked) {
            let data = [...selectedItems]
            data.push(item)
            setSelectedItems(data);
        } else {
            let data = [...selectedItems]
            let newData = []
            for (var i of data) {
                if (i.id != item.id) {
                    newData.push(i)
                }
            }
            setSelectedItems(newData);
        }
    }

    return (
        <div className="w-full h-[150px] overflow-scroll">
            <table className="w-full text-[12px]">
                <thead className="bg-primary-btn text-white sticky top-0 z-[6]">
                    <tr>
                        <td className="p-2 border w-[50px] text-center">#</td>
                        <td className="p-2 border">S.No</td>
                        <td className="p-2 border">Invoice</td>
                        <td className="p-2 border">Order Date</td>
                        <td className="p-2 border">Due Date</td>
                        <td className="p-2 border">Qty</td>
                        <td className="p-2 border">Pure Wt</td>
                        <td className="p-2 border">Paid Wt</td>
                        <td className="p-2 border">Pending Wt</td>
                        <td className="p-2 border">Total Amt</td>
                        <td className="p-2 border">Paid Amt</td>
                        <td className="p-2 border">Pending Amt</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) => (
                            <tr>
                                <td className="p-2 border w-[50px]">
                                    <div class="inline-flex items-center">
                                        <label class="relative flex items-center p-3 rounded-full " htmlFor="check">
                                            <input type="checkbox"
                                            checked={item.is_selected}
                                            onChange={(e) => onValueChecked(e.target.checked, item)}
                                                class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none  border-2 border-gray-400 transition-all rounded checked:border-primary-btn checked:bg-primary-btn checked:before:bg-primary-btn hover:before:opacity-10"
                                            />
                                            <span
                                                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                    stroke="currentColor" stroke-width="1">
                                                    <path fill-rule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clip-rule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                </td>
                                <td className="p-2 border">{index + 1}</td>
                                <td className="p-2 border">{item.purchase_order_id}</td>
                                <td className="p-2 border">{item.order_date}</td>
                                <td className="p-2 border">{item.due_date}</td>
                                <td className="p-2 border">{item.total_pieces}</td>
                                <td className="p-2 border">{item.total_pure_weight}</td>
                                <td className="p-2 border">{item.paid_weight}</td>
                                <td className="p-2 border">{item.pending_weight}</td>
                                <td className="p-2 border">{item.total_amount}</td>
                                <td className="p-2 border">{item.paid_amount}</td>
                                <td className="p-2 border">{item.pending_amount}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}