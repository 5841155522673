import ActionButton from "./actionbutton";

export default function Particulars({ particularsList, isLoading, setCurrentItem, setIsDeleteOpen, getDetails }) {
    return (
        <div className="w-full overflow-x-scroll">
            <table className="w-full text-[14px]">
                <thead className="bg-primary-btn text-white">
                    <tr>
                        <td className="px-[5px] py-[5px] border min-w-[50px]">S. No</td>
                        <td className="px-[5px] py-[5px] border min-w-[125px]">Purification Id</td>
                        <td className="px-[5px] py-[5px] border min-w-[125px]">Issue Date</td>
                        <td className="px-[5px] py-[5px] border min-w-[125px]">Bag No</td>
                        <td className="px-[5px] py-[5px] border">Bag Weight</td>
                        <td className="px-[5px] py-[5px] border">Rec. Metal Weight</td>
                        <td className="px-[5px] py-[5px] border">Touch</td>
                        <td className="px-[5px] py-[5px] border">Pure Weight</td>
                        <td className="px-[5px] py-[5px] border">Status</td>
                        <td className="px-[5px] py-[5px] border sticky top-0 right-0 bg-primary-btn">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading ? <tr className="text-center w-full">
                        <td className="text-center border" colSpan={15}>Loading...</td>
                    </tr> :
                        particularsList.length === 0 ? <tr><td className="text-center border" colSpan={15}>No Data</td></tr> :
                        particularsList.map((item, index) => (
                            <tr>
                                <td className="px-[5px] py-[5px] border">{index + 1}</td>
                                <td className="px-[5px] py-[5px] border">{item.purification_issue_id}</td>
                                <td className="px-[5px] py-[5px] border">{item.issued_date}</td>
                                <td className="px-[5px] py-[5px] border">{item.bag_number}</td>
                                <td className="px-[5px] py-[5px] border">{item.bag_weight} g</td>
                                <td className="px-[5px] py-[5px] border">{item.recipt_metal_weight} g</td>
                                <td className="px-[5px] py-[5px] border">{item.touch}</td>
                                <td className="px-[5px] py-[5px] border">{item.pure_weight} g</td>
                                <td className="px-[5px] py-[5px] border capitalize">
                                    <div style={{backgroundColor: item.purification_status_color}} className="p-2 rounded-xl text-white text-center">{item.purification_status_name}</div>
                                </td>
                                <td className="px-[5px] py-[5px] border sticky right-0 bg-white text-center">
                                    <ActionButton
                                        item={item}
                                        setIsDeleteOpen={setIsDeleteOpen}
                                        setCurrentDetails={setCurrentItem}
                                        getDetails={getDetails}
                                    ></ActionButton>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}