import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { vendorwisepurchaseListEndPoint } from "../../../service/api";
import Pagination from "../../../components/Pagination";
import { useSelector } from "react-redux";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { useKeyPress } from "../../../hooks/useKeyPress";
import VendorwisePurchaseReportFilter from "./vendorwisepurchasereportfilter";
import VendorwisePurchaseReportform from "./vendorwisepurchasereportform";

export default function VendorwisePurchaseReport() {

    const searchRef = useRef();
    const [vendorwisepurchaseList, setVendorwisepurchaseList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(1);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const fileName = "Vendor Wise Purchase Report"

    function closeExcelModal() {
        setExcelOpen(false)
    }

 
    useEffect(() => {
        let tempData = [];
        for (var i of vendorwisepurchaseList) {
            tempData.push(i)
            tempData.push({
                is_total: true,
                item_list: ["hi"],
                total_pieces: i.total_pieces,
                total_gross_weight: i.total_gross_weight,
                total_net_weight: i.total_net_weight,
                hallmark: i.hallmark,
                total_amount: i.total_amount                                                                                                                                                                     
            })
        }
        setData(tempData)
    },[vendorwisepurchaseList])

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        vendor: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);


    useEffect(() => {
        if (vendorwisepurchaseList.length > 0) {
            let firstData = Object.keys(vendorwisepurchaseList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [vendorwisepurchaseList])

    useEffect(() => {
        getvendorwisepurchasereportListData(tableFilters)
    }, [tableFilters])



    const getvendorwisepurchasereportListData = async (body) => {
        var response = await postAxiosWithToken({
            url: vendorwisepurchaseListEndPoint,
            body: body
        })

        if (response !== null) {
            setVendorwisepurchaseList(response.data.list)
            setTotalitems(response.data)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-[24px] font-[700]">
                    Vendor Wise Purchase Report
                </p>
                <div className="flex gap-3 items-center">
                    <ExcelViewer title={fileName} data={vendorwisepurchaseList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                    <ExportToExcel apiData={vendorwisepurchaseList} fileName={fileName} />
                </div>
            </div>
            <VendorwisePurchaseReportform searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />


            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <VendorwisePurchaseReportFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
            </div>


            <div className="my-4 flex gap-5">
                <div className="flex gap-2">
                    <p className="text-[16px] text-[#1D1D1D]">
                        Result
                    </p>
                    {tableFilters.from_date === null ? " " :
                        <p>From {tableFilters.from_date} </p>
                    }
                    {tableFilters.to_date === null ? " " :
                        <p>to {tableFilters.to_date} </p>
                    }

                </div>

                <p>Total Result : {totalitems.total_items}</p>
            </div>

            <div className="w-full my-[10px] mb-[50px] border rounded-md mt-5 max-h-[600px] overflow-y-scroll">
           
            <table className="w-full ">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                    <tr>
                        <td className="border p-[10px]">Vendor</td>
                        <td className="border p-[10px]">Metal</td>
                        <td className="border p-[10px]">Item name</td>
                        <td className="border p-[10px]">PCS</td>
                        <td className="border p-[10px]">G WET</td>
                        <td className="border p-[10px]">N WET</td>
                        <td className="border p-[10px]">Purity (%)</td>
                        <td className="border p-[10px]">Pure Gram</td>
                        <td className="border p-[10px]">MC</td>
                        <td className="border p-[10px]">Amt</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => (
                            item.item_list?.length > 0 &&
                            item.item_list.map((subItem, subIndex) => (
                                item.is_total ?
                                <tr className="border">
                                    <td className="p-[10px] border">Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="p-[10px] border">{item.total_pieces}</td>
                                    <td className="p-[10px] border">{item.total_gross_weight}</td>
                                    <td className="p-[10px] border">{item.total_net_weight}</td>
                                    <td></td>
                                    <td></td>
                                    <td className="p-[10px] border"></td>
                                    <td className="p-[10px] border">{item.total_amount}</td>
                                </tr> : <tr >
                                    { subIndex == 0 && <td className="border p-[10px]" rowSpan={item.item_list?.length ? item.item_list?.length : 1}>{item.vendor_name}</td> }
                                    <td className="p-[10px] border">{subItem.metal_name}</td>
                                    <td className="p-[10px] border">{subItem.item_name}</td>
                                    <td className="p-[10px] border">{subItem.pieces}</td>
                                    <td className="p-[10px] border">{subItem.gross_weight}</td>
                                    <td className="p-[10px] border">{subItem.net_weight}</td>
                                    <td className="p-[10px] border">{subItem.pure_weight}</td>
                                    <td className="p-[10px] border">{subItem.pure_weight}</td>
                                    <td className="p-[10px] border">{subItem.making_charge_pergram}</td>
                                    <td className="p-[10px] border">{subItem.total_amount}</td>
                                </tr>
                            ))
                        ))
                    }
                </tbody>
            </table>

            </div>

            {pagination === true ?
                <div >
                    <Pagination
                        totalPages={totalPages}
                        currentPage={tableFilters.page}
                        setCurrentPage={pageHandler}
                        entries={tableFilters.items_per_page}
                        entriesHandler={entriesHandler}
                    />
                </div> : ""}
        </>
    )
}