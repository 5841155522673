import { useEffect, useRef, useState } from "react";
import { userRoleAdminStatusEndPoint, userRoleEndPoint, userRoleListEndPoint, userRoleStatusEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import UserRoleForm from "./userroleform";
import { toast } from "react-hot-toast";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import UserRoleFilters from "./userrolefilters";
import { useKeyPress } from "../../../hooks/useKeyPress";
import SwitchButton from "../../../components/Switch";
import { useDispatch, useSelector } from "react-redux";

export default function UserRole() {

    const searchRef = useRef();

    const [userRolesList, setUserRoleList] = useState([]);
    const [filterdUserRolesList, setFilteredUserRoleList] = useState([]);
    const [curUserRole, setCurrentUserRole] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { actions } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getUserRoleData({})
    }, [])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    const getUserRoleData = async (body) => {
        var response = await postAxiosWithToken({
            url: userRoleListEndPoint,
            body: body
        })

        if (response !== null) {
            setUserRoleList(response.data.list)
            setIsLoading(false)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: userRoleStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getUserRoleData()
            toast.success(response.message)
        }
    }

    const onAdminStatusChange = async (id) => {
        var response = await getAxios({
            url: userRoleAdminStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getUserRoleData()
            toast.success(response.message)
        }
    }

    const deleteUserRole = async () => {
        var response = await deleteAxiosWithToken({
            url: userRoleEndPoint + curUserRole.id + '/'
        })

        if (response !== null) {
            getUserRoleData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    return (
        <>
            <div>
                {
                    formMode === 'create' ? actions.addPermit ? <div>
                        <UserRoleForm getData={getUserRoleData} formMode={formMode} setFormMode={setFormMode} curUserRole={curUserRole} setCurrentUserRole={setCurrentUserRole} />
                    </div> : '' : actions.editPermit ? <div>
                        <UserRoleForm getData={getUserRoleData} formMode={formMode} setFormMode={setFormMode} curUserRole={curUserRole} setCurrentUserRole={setCurrentUserRole} />
                    </div> : ''
                }
                {/* <div>
                    <UserRoleForm getData={getUserRoleData} formMode={formMode} setFormMode={setFormMode} curUserRole={curUserRole} setCurrentUserRole={setCurrentUserRole} />
                </div> */}
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <UserRoleFilters searchRef={searchRef} getData={getUserRoleData} dataList={userRolesList} setFilteredData={setFilteredUserRoleList} />
                </div>
                <div className="my-[10px] w-full grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-5">
                    {                   isLoading ?
                            <div className="text-center">Loading...</div> :
                            filterdUserRolesList.length === 0 ? <div className="text-center">No Data</div> :
                                filterdUserRolesList.map((item) => (
                                    <div className="flex min-w-[290px] md:min-w-[23%] h-[60px] justify-between items-center border p-[10px] shadow-lg rounded-lg">
                                        <div className="min-w-[150px]">{item.role_name}</div>
                                        <div onClick={() => {
                                            if (actions.editPermit) {
                                                onStatusChange(item.id)
                                            } else {
                                                toast.error("You don't have permission for update the status")
                                            }
                                        }}>
                                            <p className="text-[12px]">Status</p>
                                            <SwitchButton enabled={item.is_active} />
                                        </div>
                                        <div onClick={() => {
                                            if (actions.editPermit) {
                                                onAdminStatusChange(item.id)
                                            } else {
                                                toast.error("You don't have permission for update the status")
                                            }
                                        }}>
                                            <p className="text-[12px]">Is Admin</p>
                                            <SwitchButton enabled={item.is_admin} />
                                        </div>
                                        <div className="flex gap-3">
                                            <div onClick={() => {
                                                if (actions.editPermit) {
                                                    setCurrentUserRole(item);
                                                    setFormMode('update');
                                                } else {
                                                    toast.error("You don't have permission for edit")
                                                }
                                            }} className="cursor-pointer hover:scale-125">
                                                <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                            </div>
                                          
                                            <div onClick={() => {
                                                if (actions.deletePermit) {
                                                    setCurrentUserRole(item);
                                                    setIsDeleteOpen(true);
                                                } else {
                                                    toast.error("You don't have permission for delete")
                                                }
                                            }} className="cursor-pointer hover:scale-125">
                                                <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                            </div>
                                           
                                        </div>
                                    </div>
                                ))
                    }
                </div>
                <div>
                    <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                        <div className="mt-[10px]">
                            <p className="font-400 text-[15px]">Do you want to delete the {curUserRole?.role_name} role?</p>
                            <div className="mt-[10px] flex gap-3 justify-end items-center">
                                <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                                <button type="submit" onClick={() => { deleteUserRole() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
        </>
    );
}