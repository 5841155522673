import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { estimationapprovalListEndPoint, itemmasterStatusEndPoint, itemMasterEndPoint, estimationapprovalEndPoint, EstimationApprovalListEndPoint } from "../../../service/api";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import EstimationapprovalFilters from "./estimationapprovalfilter";
import { datetimetodate } from "../../../utilities/datetime";


export default function Estimationapproval() {


    const navigate = useNavigate();

    const searchRef = useRef();

    const [estimationapprovalList, setEstimationapprovalList] = useState([]);
    const [filteredEstimationapprovalList, setFilteredEstimationapprovalList] = useState([]);
    const [estimationapproval, setEstimationapproval] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { actions } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getEstimationapprovalListData({})
    }, [])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('estimationapproval-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    const getEstimationapprovalListData = async (body) => {
        var response = await postAxiosWithToken({
            url: EstimationApprovalListEndPoint,
            body: body
        })

        if (response !== null) {
            setEstimationapprovalList(response.data.list)
            setIsLoading(false)
        }
    }

    const deleteEstimationapproval = async () => {
        var response = await deleteAxiosWithToken({
            url: 'estimationapprovalEndPoint' + estimationapproval.id + '/'
        })

        if (response !== null) {
            getEstimationapprovalListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    return (
        <>
            <div>
                <div>
                    <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2"> Estimation Approval</p>
                </div>

                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <EstimationapprovalFilters searchRef={searchRef} getData={getEstimationapprovalListData} dataList={estimationapprovalList} setFilteredData={setFilteredEstimationapprovalList} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px] min-w-[100px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">estimate no</td>
                                <td className="p-[10px] min-w-[150px]">customer mobile</td>
                                <td className="p-[10px] min-w-[150px]">gst amount</td>
                                <td className="p-[10px] min-w-[150px]">customer name</td>
                                <td className="p-[10px] min-w-[150px]">status name</td>
                                <td className="p-[10px] min-w-[150px]">estimate date</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={11}>Loading...</td>
                                </tr> :
                                    filteredEstimationapprovalList.length === 0 ? <tr><td className="text-center border" colSpan={11}>No Data</td></tr> :
                                        filteredEstimationapprovalList?.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize text-[#3F80CC] cursor-pointer" onClick={() => { navigate('estimationapproval-form/'+item.id.toString()) }}>{item.estimate_no}</td>
                                                <td className="p-[10px] capitalize">{item.customer_mobile}</td>
                                                <td className="p-[10px] capitalize">{item.gst_amount}</td>
                                                <td className="p-[10px] capitalize">{item.customer_name}</td>
                                                <td className="p-[10px] capitalize">{item.status_name}</td>
                                                <td className="p-[10px] capitalize">{datetimetodate(item.estimate_date)}</td>
                                            </tr>
                                        ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <Popup title={'Delete Estimationapproval'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {estimationapproval.estimationapproval_number} estimationapproval?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteEstimationapproval() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}