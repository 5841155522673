import { useEffect, useState } from "react";
import { lotEndPoint } from "../../../service/api"
import { getAxios } from "../../../service/apiservice"
import { isValidJson } from "../../../utilities/jsonvalidation";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";

export default function ViewLot() {

    const navigate = useNavigate();

    const [lotFormValues, setLotFormValues] = useState({
        lot_number: ""
    });

    const [particularsList, setParticularsList] = useState([]);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getLotDetails();
    }, [])

    const getLotDetails = async () => {
        var response = await getAxios({
            url: lotEndPoint + localStorage.getItem('lot_form_id') + "/"
        })

        if (response !== null) {

            setLotFormValues({
                branch: response.data.lot_details.branch_name,
                lot_number: response.data.lot_details.lot_number,   
                entry_date: response.data.lot_details.entry_date,
                entry_type: response.data.lot_details.entry_type_name,
                invoice_number: response.data.lot_details.invoice_number,
                total_pieces: response.data.lot_details.total_pieces,
                net_weight: response.data.lot_details.total_netweight,
                hallmark_number: response.data.lot_details.hallmark_number,
                hallmark_center: response.data.lot_details.hallmark_center,
                designer_name: JSON.stringify(response.data.lot_details.designer_name),
                designer_code: response.data.lot_details.designer_name.account_head_code
            })

            var tempItems = []

            for (var i of response.data.item_details) {
                var tempDict = {
                    id: i.id,
                    item_name: JSON.stringify(i.item_details),
                    item_id: JSON.stringify(i.item_details),
                    item_code: JSON.stringify(i.item_details),
                    sub_item_name: i.subitem_details != null ? JSON.stringify(i.subitem_details) : "",
                    sub_item_id: i.subitem_details != null ? JSON.stringify(i.subitem_details) : "",
                    sub_item_code: i.subitem_details != null ? JSON.stringify(i.subitem_details) : "",
                    bulk_tag: i.bulk_tag,
                    pieces: i.pieces,
                    gross_wgt: i.gross_weight,
                    net_wgt: i.net_weight,
                    tag_count: i.tag_count,
                    loop_wgt: i.loop_weight,
                    other_wgt: i.other_weight,
                    tag_wgt: i.tag_weight,
                    cover_wt: i.cover_weight,
                    stone_pcs: i.item_stone_pieces,
                    stone_wt: i.item_stone_weight,
                    diamond_pcs: i.item_diamond_pieces,
                    diamond_wt: i.item_diamond_weight,
                    stone_details: i.stone_details,
                    diamond_details: i.diamond_details,
                    remarks: i.remark,
                    tag_type: i.tag_type
                }

                tempItems.push(tempDict)
            }

            setParticularsList(tempItems)

        }
    }

    return (
        <>
            <div>
                <div className="border bg-menu-head-bg shadow-lg mb-2 p-[15px] rounded-lg">
                    <p className="text-[18px] font-[500] text-[menu-head]">Lot Details</p>
                    <div className="grid xl:grid-cols-6 sm:grid-cols-3 gap-5 rounded-lg mb-[10px] mt-2">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Lot Number</p>
                            <p className="font-[400] text-[17px]">{lotFormValues.lot_number}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Entry Date</p>
                            <p className="font-[400] text-[17px]">{lotFormValues.entry_date}</p>
                        </div>
                        {localStorage.getItem("branch") === "true" && (
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Branch</p>


                                <p className="font-[400] text-[17px]">{lotFormValues.branch}</p>
                            </div>
                        )}
                        <div className="my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Entry Type</p>
                            <p className="font-[400] text-[17px]">{lotFormValues.entry_type}</p>
                        </div>
                        <div className="my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Designer Name</p>
                            <p className="font-[400] text-[17px]">{isValidJson(lotFormValues.designer_name)?.account_head_name}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Designer Code</p>
                            <p className="font-[400] text-[17px]">{isValidJson(lotFormValues.designer_name)?.account_head_code}</p>
                        </div>

                    </div>

                    <div className="w-full mb-[25px]">

                        <div className="rounded-md w-full grid xl:grid-cols-6 sm:grid-cols-3 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Ref.Invoice Number</p>
                                <p className="font-[400] text-[17px]">{lotFormValues.invoice_number}</p>
                            </div>
                            <div className="w-full my-[5px]">
                                <p className="mb-[5px] text-[14px]">Total Pieces</p>
                                <p className="font-[400] text-[17px]">{lotFormValues.total_pieces}</p>
                            </div>
                            <div className="w-full my-[5px]">
                                <p className="mb-[5px] text-[14px]">Total Net Weight</p>
                                <p className="font-[400] text-[17px]">{lotFormValues.net_weight}</p>
                            </div>
                            <div className="w-full my-[5px]">
                                <p className="mb-[5px] text-[14px]">Hallmark Number</p>
                                <p className="font-[400] text-[17px]">{lotFormValues.hallmark_number}</p>
                            </div>
                            <div className="w-full my-[5px]">
                                <p className="mb-[5px] text-[14px]">Hallmark Center</p>
                                <p className="font-[400] text-[17px]">{lotFormValues.hallmark_center}</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            {
                particularsList.length >= 0 && <div className="overflow-scroll w-full max-[639px]:mt-[25px] bg-[#fff] border rounded-lg">
                    <p className="text-[18px] font-[500] text-[menu-head] p-[15px]">Item List</p>
                    <div className="rounded-md border">
                        <table className="w-full">
                            <thead className="bg-[#F9F9FC] sticky top-0">
                                <tr>
                                    <td className="p-[10px] " rowSpan={2}>S.No</td>
                                    <td className="p-[10px] border-[#E0E0E0] border min-w-[110px]" rowSpan={2}>Item Name</td>
                                    <td className="p-[10px] border-[#E0E0E0] border min-w-[150px]" rowSpan={2}>SubItem name</td>
                                    <td className="p-[10px] border-[#E0E0E0] border min-w-[150px]" rowSpan={2}>Pieces</td>
                                    <td className="p-[10px] border-[#E0E0E0] border min-w-[150px]" rowSpan={2}>Gross Wt</td>
                                    <td className="p-[10px] border-[#E0E0E0] border min-w-[150px]" rowSpan={2}>Net Wt</td>
                                    <td className="p-[10px] border-[#E0E0E0] border text-center min-w-[150px]" colSpan={2}>Stone</td>
                                    <td className="p-[10px] border-[#E0E0E0] border text-center min-w-[150px]" colSpan={2}>Diamond</td>
                                    <td className="p-[10px] border-[#E0E0E0] border min-w-[150px]" rowSpan={2}>Remarks</td>
                                </tr>
                                <tr>
                                    <td className="p-[10px] border text-center" >pcs</td>
                                    <td className="p-[10px] border text-center">wt</td>
                                    <td className="p-[10px] border text-center">pcs</td>
                                    <td className="p-[10px] border text-center">wt</td>
                                </tr>
                            </thead>
                            <tbody className="text-primary-grey">
                                {
                                    particularsList.map((item, index) => (
                                        <tr>
                                            <td className="p-[10px] border text-center">{index + 1}</td>
                                            <td className="p-[10px] border">{isValidJson(item.item_name)?.item_name}</td>
                                            <td className="p-[10px] border">{isValidJson(item.sub_item_name)?.sub_item_name}</td>
                                            <td className="p-[10px] border">{item.pieces}</td>
                                            <td className="p-[10px] border">{item.gross_wgt}</td>
                                            <td className="p-[10px] border">{item.net_wgt}</td>
                                            <td className="p-[10px] border text-center" >{item.stone_pcs}</td>
                                            <td className="p-[10px] border text-center">{item.stone_wt}</td>
                                            <td className="p-[10px] border text-center">{item.diamond_pcs}</td>
                                            <td className="p-[10px] border text-center">{item.diamond_wt}</td>
                                            <td className="p-[10px] border">{item.remarks}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            <div className="flex items-center justify-end my-4">
            <button
                className="capitalize bg-primary-btn text-white w-fit rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                onClick={() => navigate(-1)}
            >Back <span className="lowercase">(Esc)</span></button>
            </div>
        </>
    );
}