import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { branchListEndPoint, counterEndPoint, floorListEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function CounterForm({ getData, formMode, currentCounter, setFormMode, setCurrentCounter }) {

    const [branchDropdown, setBranchDropDown] = useState([]);
    const [floorDropdown, setFloorDropDown] = useState([]);

    const [selectedFloor, setSelectedFloor] = useState("");

    const schema = yup
        .object({
            counter_name: yup.string().required(),
            branch: localStorage.getItem("branch") == "true" ? yup.string().required() : yup.string(),
            floor: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            counter_name: currentCounter.counter_name,
            branch: currentCounter.branch,
            floor: currentCounter.floor
        } : {
            counter_name: "",
            branch: "",
            floor: ""
        }
    })

    useEffect(() => {
        if (localStorage.getItem("branch") == "true") {
            getBranchDropdownData();
        } else {

            getFloorDropdownData(1);
        }
    },[])

    useEffect(() => {
        if (currentCounter?.branch !== undefined && currentCounter?.branch !== "") {
            getFloorDropdownData(currentCounter.branch)
            setSelectedFloor(currentCounter?.floor)
        }
    },[currentCounter])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list) 
        }
    }

    const getFloorDropdownData = async (branchId) => {
        var response = await getAxios({
            url: floorListEndPoint + branchId + "/"
        })

        if (response !== null) {
            setFloorDropDown(response.data.list)
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createCounter(data)
            } else {
                updateCounter(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createCounter = async (body) => {
        var response = await postAxiosWithToken({
            url: counterEndPoint,
            body: body
        })

        if (response !== null) {
            getData({})
            reset()
            clearData()
            toast.success(response.message)
        }
    }

    const updateCounter = async (body) => {
        var response = await putAxiosWithToken({
            url: counterEndPoint + currentCounter?.id + '/',
            body: body
        })

        if (response !== null) {
            getData({})
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setSelectedFloor("")
        setCurrentCounter({
            counter_name: "",
            branch: "",
            floor: ""
        })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg shadow border mt-5">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Counter</p>
                <form className="grid 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    { localStorage.getItem("branch") == "true" && <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Branch Name</p>
                        <select
                            placeholder="branch name"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="branch"
                            {...register('branch')}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    getFloorDropdownData(e.target.value)
                                } else {
                                    setFloorDropDown([])
                                }
                            }}
                        >
                            <option value={""} className="capitalize">--select branch name--</option>
                            {
                                branchDropdown.map((option) => (
                                    <option className="capitalize" key={option.id} value={option.id}>{option.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.branch_name?.message}</p>
                    </div> }
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Floor Name</p>
                        <select
                            placeholder="floor name"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="floor"
                            {...register('floor')}
                            value={selectedFloor}
                            onChange={(e) => {setSelectedFloor(e.target.value)}}
                        >
                            <option value={""} className="capitalize">--select floor name--</option>
                            {
                                floorDropdown.map((option) => (
                                    <option className="capitalize" key={option.id} value={option.id}>{option.floor_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.floor_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Counter Name</p>
                        <input
                            type="text"
                            placeholder="counter name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="counter_name"
                            id="forminit"
                            {...register('counter_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.counter_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-[32px] xl:mt-[32px] 2xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg w-full p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey rounded-lg w-full p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}