import React, { useEffect, useState } from 'react'
import { chitDetailsEndPoint } from '../../service/api'
import { postAxios, postAxiosWithToken } from '../../service/apiservice'
import axios from 'axios'

export default function Chitdetails({tableFilters}) {

    console.log(tableFilters,"tableFilters")

    const [chitdetails, setChitdetails] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getchitdetailsData(tableFilters)
    }, [tableFilters])



    const getchitdetailsData = async (body) => {
        const fetchPromise = fetch(chitDetailsEndPoint, {
            method: 'POST',
            body: JSON.stringify(body)
        });

        fetchPromise
        .then((response) => response.json())
        .then((data) => {
            if (data !== null) {
                    setChitdetails(data.data.list)
                    setIsLoading(false) 
                }
        }).catch((err) => {
            setChitdetails([])
            setIsLoading(false) 
        });

       
    }


    return (
        <div className='my-5'>
            <p className='text-[20px] font-[700] my-auto pl-[15px] mb-4'>Chit Details</p>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">


                    <div className="shadow-lg p-5 m-2 flex justify-between rounded border border-[#61a375] items-center ">
                        <div className="w-full">
                            <div className="flex gap-2 w-full justify-between">
                                <h3 className="font-[500] text-black text-[14px]">Customers</h3>
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg>
                                </div>
                            </div>

                            <p>
                                {
                                    chitdetails.custom
                                }
                            </p>
                        </div>
                    </div>
                    <div className="shadow-lg p-5 m-2 flex justify-between rounded  border items-center border-[red] ">
                        <div className="w-full">
                            <div className="flex gap-2 w-full justify-between">
                                <h3 className="font-[500] text-black text-[14px]">Payment</h3>
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg>
                                </div>
                            </div>
                            <p>
                                {
                                    chitdetails.payment
                                }
                            </p>
                        </div>
                    </div>
                    <div className="shadow-lg p-5 m-2 flex justify-between rounded border border-[#61a375] items-center  ">
                        <div className="w-full">
                            <div className="flex gap-2 w-full justify-between">
                                <h3 className="font-[500] text-black text-[14px]">Open Accounts</h3>
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg>
                                </div>
                            </div>
                            <p>
                                {
                                    chitdetails.open
                                }
                            </p>
                        </div>
                    </div>
                    <div className="shadow-lg p-5 m-2 flex justify-between rounded  border border-[red] items-center  ">
                        <div className="w-full">
                            <div className="flex gap-2 w-full justify-between">
                                <h3 className="font-[500] text-black text-[14px]">Close Accounts</h3>
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg>
                                </div>
                            </div>
                            <p>
                                {
                                    chitdetails.close
                                }
                            </p>
                        </div>
                    </div>
                    <div className="shadow-lg p-5 m-2 flex justify-between rounded  border border-[#61a375] items-center  ">
                        <div className="w-full">
                            <div className="flex gap-2 w-full justify-between">
                                <h3 className="font-[500] text-black text-[14px]">Completed</h3>
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg>
                                </div>
                            </div>
                            <p>
                                {
                                    chitdetails.completed
                                }
                            </p>
                        </div>
                    </div>
                    <div className="shadow-lg p-5 m-2 flex justify-between rounded border-[red] border items-center  ">
                        <div className="w-full">
                            <div className="flex gap-2 w-full justify-between">
                                <h3 className="font-[500] text-black text-[14px]">Verify Payment</h3>
                                <div className="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg>
                                </div>
                            </div>
                            <p>
                                {
                                    chitdetails.verifypaycount
                                }
                            </p>
                        </div>
                    </div>
            </div>
        </div>
    )
}
