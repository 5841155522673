import { itemMasterEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { fixedCalcId, pergramCalId, weightCalId } from "../../../constant";

export default function ViewItemDetails() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('View');
    const [currentItemMaster, setCurrentItemmaster] = useState({});

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {

        getItemmasterDetails()
    }, [])

    const getItemmasterDetails = async () => {
        var response = await getAxios({
            url: itemMasterEndPoint + localStorage.getItem('itemmaster_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                item_id: response.data?.item_id,
                metal: response.data?.metal_name,
                purity: response.data?.purity_name,
                hsn_code: response.data?.hsn_code,
                stock_type: response.data?.stock_type_name,
                item_code: response.data?.item_code,
                item_name: response.data?.item_name,
                item_image: response.data?.item_image,
                allow_zero_weight: response.data?.allow_zero_weight,
                calculation_type_name: response.data?.calculation_type_name,
                calculation_type: response.data?.calculation_type,
                fixed_rate: response.data?.fixed_rate,
                per_gram_rate: response.data?.per_gram_rate,
                item_counter: response.data?.item_counter,
                wastage_calculation_name: response.data?.wastage_calculation_name,
                wastage_percent: response.data?.wastage_percent,
                flat_wastage: response.data?.flat_wastage,
                making_charge_calculation_name: response.data?.making_charge_calculation_name,
                making_charge_gram: response.data?.making_charge_gram,
                flat_making_charge: response.data?.flat_making_charge,
            }
            setCurrentItemmaster(tempDetails)
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Item Details</p>
                <form>

                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Item Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[500px]:grid-cols-2  gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.item_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Code</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.item_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Id</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.item_id}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Metal</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.metal}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Purity</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.purity}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">HSN</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.hsn_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Image</p>
                                <Link to={currentItemMaster.item_image} target="_blank" className="text-primary-btn font-[400] text-[15px]">view</Link>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Stock Type</p>
                                <p className="text-primary-btn font-[400] text-[15px] hover:underline">{currentItemMaster.stock_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Allow Zero Weight</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.allow_zero_weight ? "Yes" : "No"}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Calculation Type</p>
                                <p className="text-primary-btn font-[400] text-[15px] hover:underline">{currentItemMaster.calculation_type_name}</p>
                            </div>

                        </div>
                    </div>

                    <div className={`${currentItemMaster?.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg`}>
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Calculation Value</p>
                        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Fixed Value</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.fixed_rate}</p>
                            </div>

                        </div>
                    </div>

                    <div className={`${currentItemMaster?.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-menu-head-bg`}>
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Per Gram Rate</p>
                        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Per Gram Rate</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.per_gram_rate}</p>
                            </div>

                        </div>
                    </div>

                    <div className={`${currentItemMaster?.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-menu-head-bg`}>
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Calculation Value</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 min-[500px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Wastage Calculation</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.wastage_calculation_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Wastage %</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.wastage_percent}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Flat Wastage (g)</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.flat_wastage}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Makeing Charges Calculations</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.making_charge_calculation_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">MC / gram(₹)</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.making_charge_gram}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Flac MC (₹)</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentItemMaster.flat_making_charge}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}