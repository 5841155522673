import { useCallback, useEffect, useState } from "react"
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { accountheadListEndPoint, autoGeneratePOIDEndPoint, branchListEndPoint, itemListForPurchaseEndPoint, itemMasterListEndPoint, metalListEndPoint, newPurchaseEndPoint, purchaseEndPoint, purchaseOrderValueAdditionDetailsEndPoint, subitemListEndPoint } from "../../../service/api";
import Popup from "../../../components/Popup";
import StoneForm from "./stoneform";
import DiamondForm from "./diamondform";
import { cararWeightType, caratRateType } from "../../../constant";
import { isValidForm } from "../../../utilities/formvalidation";
import ParticularList from "./particularlist";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { convertToDate } from "../../../utilities/datetime";
import { addDays, differenceInDays } from "date-fns";

export default function NewPurchaseForm() {


    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [itemFormMode, setItemFormMode] = useState('create');

    const [updateItemIndex, setUpdateItemIndex] = useState(null);

    const [branchDropDown, setBranchDropDown] = useState([]);
    const [designerDropDown, setDesignerDropDown] = useState([]);
    const [metalDropDown, setMetalDropDown] = useState([]);
    const [itemDropDown, setItemDropDown] = useState([]);
    const [subItemDropDown, setSubItemDropDown] = useState([]);

    const [errors, setErrors] = useState({});
    const [itemErrors, setItemErrors] = useState({});

    const [stonePopup, setStonePopup] = useState(false);
    const [diamondPopup, setDiamondPopup] = useState(false);

    const [stoneList, setStoneList] = useState([]);
    const [diamondList, setDiamondList] = useState([]);

    const [formValues, setFormValues] = useState({
        order_date: new Date().toLocaleDateString('en-CA')
    });
    const [itemFormValues, setItemFormValues] = useState({});

    const [pureWeight, setPureWeight] = useState();
    const [itemAmount, setItemAmount] = useState(0);

    const [grandTotal, setGrandTotal] = useState(0);

    const [particularList, setParticularList] = useState([])

    const [metalRate, setMetalRate] = useState(0);

    const [selectedItem, setSelctedItem] = useState(null);
    const [selectedMetal, setSelctedMetal] = useState(null);

    const [selectedDesigner, setSelctedDesigner] = useState(null);

    const closeStoneModal = () => {
        setStonePopup(!stonePopup)
    }

    const closeDiamondModal = () => {
        setDiamondPopup(!diamondPopup)
    }

    useEffect(() => {
        getBranchDropDownList();
        getDesignerDropDownList();
        getMetalDropDownList();
        getItemDropDownList();
        setFormMode(localStorage.getItem('user_purchaseentry_mode'))
        if (localStorage.getItem('user_purchaseentry_mode') === 'update') {
            getDetails()
        } else {
            generatePOID();
        }
    }, [])

    useEffect(() => {
        if ((selectedItem != "" && selectedItem != null && selectedItem != undefined) &&
            (selectedMetal != "" && selectedMetal != null && selectedMetal != undefined) &&
            (selectedDesigner != "" && selectedDesigner != null && selectedDesigner != undefined)) {
            getValueAdditionDetails();
        }
    }, [selectedItem, selectedMetal, selectedDesigner])

    useEffect(() => {
        if (selectedItem) {
            for (var i of itemDropDown) {
                if (i.id == selectedItem) {
                    setMetalRate(i.rate)
                    break
                }
            }
        }
    }, [selectedItem])

    useEffect(() => {
        if (!stonePopup) {
            let stoneWeight = 0.0
            let diamondWeight = 0.0

            var totalStoneRate = 0.0
            var totalDiamondRate = 0.0

            var totalStonePieces = 0
            var totalDiamondPieces = 0

            var grossWgt = itemFormValues.gross_weight
            var lessWgt = itemFormValues.less_weight

            if (stoneList.length !== 0) {
                for (var j of stoneList) {
                    totalStonePieces = totalStonePieces + parseInt(j.stone_pieces)
                    if (j.include_stone_weight === true) {
                        if (String(j.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(j.stone_weight) / 5)
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(j.stone_weight)
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                            }
                        }
                    } else {
                        if (String(j.stone_weight_type) === String(cararWeightType)) {
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                            }
                        } else {
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                            }
                        }
                    }
                }
            }

            if (diamondList.length !== 0) {
                for (var j of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + parseInt(j.diamond_pieces)
                    if (j.include_diamond_weight === true) {
                        if (String(j.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(j.diamond_weight) / 5)
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(j.diamond_weight)
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                            }
                        }
                    } else {
                        if (String(j.diamond_weight_type) === String(cararWeightType)) {
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                            }
                        } else {
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                            }
                        }
                    }
                }
            }

            var netWgt = parseFloat(grossWgt) - (diamondWeight + stoneWeight + parseFloat(lessWgt))


            setItemFormValues((prevState) => ({
                ...prevState,
                net_weight: netWgt,
                stone_weight: stoneWeight,
                no_stone_pieces: totalStonePieces,
                stone_amount: totalStoneRate,
                diamond_weight: diamondWeight,
                no_diamond_pieces: totalDiamondPieces,
                diamond_amount: totalDiamondRate
            }))

        }
    }, [stonePopup])

    useEffect(() => {
        if (!diamondPopup) {
            var stoneWeight = 0.0
            var diamondWeight = 0.0

            var totalStoneRate = 0.0
            var totalDiamondRate = 0.0

            var totalStonePieces = 0
            var totalDiamondPieces = 0

            var grossWgt = itemFormValues.gross_weight
            var lessWgt = itemFormValues.less_weight

            if (stoneList.length !== 0) {
                for (var j of stoneList) {
                    totalStonePieces = totalStonePieces + parseInt(j.stone_pieces)
                    if (j.include_stone_weight === true) {
                        if (String(j.stone_weight_type) === String(cararWeightType)) {
                            stoneWeight = stoneWeight + (parseFloat(j.stone_weight) / 5)
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                            }
                        } else {
                            stoneWeight = stoneWeight + parseFloat(j.stone_weight)
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                            }
                        }
                    } else {
                        if (String(j.stone_weight_type) === String(cararWeightType)) {
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * (parseFloat(j.stone_weight) / 5))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * (parseFloat(j.stone_weight) / 5))
                            }
                        } else {
                            if (String(j.stone_rate_type) === String(caratRateType)) {
                                totalStoneRate = totalStoneRate + ((parseFloat(j.stone_rate) * 5) * parseFloat(j.stone_weight))
                            } else {
                                totalStoneRate = totalStoneRate + (parseFloat(j.stone_rate) * parseFloat(j.stone_weight))
                            }
                        }
                    }
                }
            }

            if (diamondList.length !== 0) {
                for (var j of diamondList) {
                    totalDiamondPieces = totalDiamondPieces + parseInt(j.diamond_pieces)
                    if (j.include_diamond_weight === true) {
                        if (String(j.diamond_weight_type) === String(cararWeightType)) {
                            diamondWeight = diamondWeight + (parseFloat(j.diamond_weight) / 5)
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                            }
                        } else {
                            diamondWeight = diamondWeight + parseFloat(j.diamond_weight)
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                            }
                        }
                    } else {
                        if (String(j.diamond_weight_type) === String(cararWeightType)) {
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * (parseFloat(j.diamond_weight) / 5))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * (parseFloat(j.diamond_weight) / 5))
                            }
                        } else {
                            if (String(j.diamond_rate_type) === String(caratRateType)) {
                                totalDiamondRate = totalDiamondRate + ((parseFloat(j.diamond_rate) * 5) * parseFloat(j.diamond_weight))
                            } else {
                                totalDiamondRate = totalDiamondRate + (parseFloat(j.diamond_rate) * parseFloat(j.diamond_weight))
                            }
                        }
                    }
                }
            }

            var netWgt = parseFloat(grossWgt) - (diamondWeight + stoneWeight + parseFloat(lessWgt))

            setItemFormValues((prevState) => ({
                ...prevState,
                net_weight: netWgt,
                stone_weight: stoneWeight,
                no_stone_pieces: totalStonePieces,
                stone_amount: totalStoneRate,
                diamond_weight: diamondWeight,
                no_diamond_pieces: totalDiamondPieces,
                diamond_amount: totalDiamondRate
            }))
        }
    }, [diamondPopup])

    useEffect(() => {
        setPureWeight((parseFloat(itemFormValues.net_weight || 0) * (parseFloat(itemFormValues.touch || 0) / 100)).toFixed(2))
        let addMCG = parseFloat(itemFormValues.net_weight) * parseFloat(itemFormValues.making_charge_pergram)
        let addFMC = addMCG + parseFloat(itemFormValues.flat_makingcharge)
        let addStoneAmt = addFMC + parseFloat(itemFormValues.stone_amount)
        let addDiamondAmt = addStoneAmt + parseFloat(itemFormValues.diamond_amount)

        let wasPercent = (parseFloat(itemFormValues.wastage) / 100) * parseFloat(itemFormValues.net_weight)
        let addWasAmount = parseFloat(metalRate) * parseFloat(wasPercent)
        let addFlatWas = parseFloat(addWasAmount) + parseFloat(itemFormValues.flat_wastage)

        let actualRate = parseFloat(metalRate) * parseFloat(itemFormValues.net_weight)

        setItemAmount(addDiamondAmt + addFlatWas + actualRate)

    }, [itemFormValues, metalRate])

    useEffect(() => {
        let totalNetWeight = 0.0;
        let totalGrossWeight = 0.0;
        let totalPieces = 0;
        let totalPureWeight = 0.0;
        let totalAmount = 0.0;
        let totalItem = 0

        for (var i of particularList) {
            totalGrossWeight = totalGrossWeight + parseFloat(i.gross_weight)
            totalNetWeight = totalNetWeight + parseFloat(i.net_weight)
            totalPieces = totalPieces + parseInt(i.pieces)
            totalPureWeight = totalPureWeight + parseFloat(i.pure_weight)
            totalAmount = totalAmount + parseFloat(i.total_amount)
            totalItem = totalItem + 1
        }

        setFormValues((prevState) => ({
            ...prevState,
            total_pieces: totalPieces,
            total_net_weight: totalNetWeight,
            total_gross_weight: totalGrossWeight,
            total_pure_weight: totalPureWeight,
            total_item: totalItem
        }))

    }, [particularList])

    useEffect(() => {
        let totalAmount = 0.0

        for (var i of particularList) {
            totalAmount = totalAmount + parseFloat(i.total_amount)
        }

        setGrandTotal(totalAmount + parseFloat(formValues.hallmark ? formValues.hallmark : 0) + parseFloat(formValues.others ? formValues.others : 0))
    }, [formValues])

    const getValueAdditionDetails = async () => {
        var response = await postAxiosWithToken({
            url: purchaseOrderValueAdditionDetailsEndPoint,
            body: {
                "metal": selectedMetal,
                "item": selectedItem,
                "designer": selectedDesigner
            }
        })

        if (response != null) {
            setItemFormValues((prevState) => ({
                ...prevState,
                touch: response.data.purchase_touch,
                wastage: response.data.purchase_wastage_percent,
                flat_wastage: response.data.purchase_flat_wastage,
                making_charge_pergram: response.data.purchase_making_charge_gram,
                flat_makingcharge: response.data.purchase_flat_making_charge,
            }))
        }
    }

    const getDetails = async () => {
        var response = await getAxios({
            url: newPurchaseEndPoint + localStorage.getItem('purchase_form_id') + '/'
        })

        if (response != null) {
            var tempDetails = {
                purchase_order_id: response.data.purchase_order_id,
                branch: response.data.branch,
                designer_name: response.data.designer_name,
                order_date: response.data.order_date,
                no_of_days: response.data.no_of_days,
                due_date: response.data.due_date,
                hallmark: response.data.hallmark,
                others: response.data.others,
            }
            setParticularList(response.data.new_purchase_item)
            setFormValues(tempDetails)
        }
    }

    const generatePOID = async () => {
        var response = await getAxios({
            url: autoGeneratePOIDEndPoint
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                purchase_order_id: response.po_id
            }))
        }
    }

    const getBranchDropDownList = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropDown(response.data.list)
        }
    }

    const getDesignerDropDownList = async () => {
        var response = await getAxios({
            url: accountheadListEndPoint
        })

        if (response != null) {
            setDesignerDropDown(response.data.list)
        }
    }

    const getMetalDropDownList = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response != null) {
            setMetalDropDown(response.data.list)
        }
    }

    const getItemDropDownList = async () => {
        var response = await getAxios({
            url: itemListForPurchaseEndPoint
        })

        if (response != null) {
            setItemDropDown(response.data)
        }
    }

    const getSubItemDropDownList = async (id) => {
        var response = await getAxios({
            url: subitemListEndPoint + id + '/'
        })

        if (response != null) {
            setSubItemDropDown(response.data.list)
        }
    }

    const onItemUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'item' || name == 'item_code') {
            getSubItemDropDownList(value)

            setItemFormValues((prevState) => ({
                ...prevState,
                item: value,
                item_code: value
            }))
            setSelctedItem(value)
        } else if (name == 'gross_weight') {
            var tempNetValue = parseFloat(value) - parseFloat(itemFormValues.less_weight || 0)
            setItemFormValues((prevState) => ({
                ...prevState,
                net_weight: tempNetValue,
            }))
        } else if (name == 'less_weight') {
            setItemFormValues((prevState) => ({
                ...prevState,
                net_weight: parseFloat(prevState.gross_weight || 0) - parseFloat(value || 0),
            }))
        } else if (name == 'touch') {
            setItemFormValues((prevState) => ({
                ...prevState,
                pure_weight: (parseFloat(itemFormValues.net_weight || 0) * (parseFloat(value || 0) / 100)).toFixed(2)
            }))
        } else if (name == 'metal') {
            setItemFormValues((prevState) => ({
                ...prevState,
                metal: value
            }))
            setSelctedMetal(value)
        }
        setItemFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))


        setItemErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const onFormUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'no_of_days') {
            if (formValues.order_date) {
                setFormValues((prevState) => ({
                    ...prevState,
                    due_date: convertToDate(addDays(new Date(prevState.order_date), value))
                }))
                setErrors((prevState) => ({
                    ...prevState,
                    due_date: value.length > 0 ? "" : `due_date field is required`
                }))
            }
        } else if (name == 'order_date') {
            setFormValues((prevState) => ({
                ...prevState,
                due_date: convertToDate(addDays(new Date(value), prevState.no_of_days))
            }))
            setErrors((prevState) => ({
                ...prevState,
                due_date: value.length > 0 ? "" : `due_date field is required`
            }))
        } else if (name == 'due_date') {
            setFormValues((prevState) => ({
                ...prevState,
                no_of_days: differenceInDays(new Date(value), new Date(prevState.order_date))
            }))
            setErrors((prevState) => ({
                ...prevState,
                no_of_days: value.length > 0 ? "" : `no_of_days field is required`
            }))
        } else if (name == 'designer_name') {
            setFormValues((prevState) => ({
                ...prevState,
                [name]: value
            }))
            setSelctedDesigner(value)
        }
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))


        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const addItem = () => {
        let formFields = new FormData(document.getElementById('particularform'));
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setItemErrors(err)

        if (isValidForm(formDataObject)) {
            formDataObject['stone_details'] = stoneList
            formDataObject['diamond_details'] = diamondList

            for (var i of metalDropDown) {
                if (i.id == formDataObject.metal) {
                    formDataObject['metal_name'] = i.metal_name
                }
            }

            for (var i of itemDropDown) {
                if (i.id == formDataObject.item_code) {
                    formDataObject['item_name'] = i.item_name
                }
            }

            for (var i of subItemDropDown) {
                if (i.id == formDataObject.sub_item) {
                    formDataObject['sub_item_name'] = i.sub_item_name
                }
            }

            if (itemFormMode == 'create') {
                let tempList = [...particularList]
                tempList.unshift(formDataObject)
                setParticularList(tempList)
                resetItemForm()
            } else {
                if (updateItemIndex != null) {
                    let tempList = [...particularList]
                    tempList[updateItemIndex] = formDataObject
                    setParticularList(tempList)
                    resetItemForm()
                }
            }
            setItemFormMode('create')
        }
    }

    const resetItemForm = () => {
        setStoneList([])
        setDiamondList([])
        setItemFormValues((prevState) => ({
            ...prevState,
            metal: "",
            item_code: "",
            item: "",
            sub_item: "",
            wastage: "",
            flat_wastage: "",
            making_charge_pergram: "",
            flat_makingcharge: "",
            pieces: "",
            gross_weight: "",
            less_weight: "",
            net_weight: "",
            touch: "",
            no_stone_pieces: 0,
            stone_weight: 0,
            no_diamond_pieces: 0,
            diamond_weight: 0,
            pure_weight: 0,
            stone_amount: 0,
            diamond_amount: 0,
            total_amount: 0,
            stone_details: [],
            diamond_details: [],
            metal_name: "",
            item_name: "",
            sub_item_name: ""
        }))
        setItemErrors((prevState) => ({
            ...prevState,
            metal: "",
            item_code: "",
            item: "",
            sub_item: "",
            wastage: "",
            flat_wastage: "",
            making_charge_pergram: "",
            flat_makingcharge: "",
            pieces: "",
            gross_weight: "",
            less_weight: "",
            net_weight: "",
            touch: "",
        }))
    }

    const handleSubmit = () => {
        let orderForm = document.getElementById('orderdetails')
        let paymentForm = document.getElementById('paymentdetails')

        var orderFormData = new FormData(orderForm);
        var paymentFormData = new FormData(paymentForm);

        var err = {}

        for (var field of Object.entries(Object.fromEntries(orderFormData.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        for (var field of Object.entries(Object.fromEntries(paymentFormData.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isValidForm(Object.fromEntries(orderFormData.entries())) && isValidForm(Object.fromEntries(paymentFormData.entries()))) {
            let data = {
                ...Object.fromEntries(orderFormData.entries()),
                ...Object.fromEntries(paymentFormData.entries()),
                item_details: particularList,
                total_gross_weight: formValues.total_gross_weight,
                total_item: formValues.total_item
            }

            if (formMode == 'create') {
                createPurchaseOrder(data)
            } else {
                updatePurchaseOrder(data)
            }
        }
    }

    const createPurchaseOrder = async (body) => {
        var response = await postAxiosWithToken({
            url: newPurchaseEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updatePurchaseOrder = async (body) => {
        var response = await putAxiosWithToken({
            url: newPurchaseEndPoint + localStorage.getItem('purchase_form_id') + '/',
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    return (
        <div className="bg-white p-3 rounded-lg border mb-[100px]">
            <p className="text-[18px] font-[500] capitalize">
                {formMode} New Purchase
            </p>
            <form id="orderdetails">
                <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-3 border-b pb-3">
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">PO ID <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            placeholder="PO ID "
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="purchase_order_id"
                            value={formValues?.purchase_order_id}
                            onChange={onFormUpdate}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_order_id}</p>
                    </div>
                    {
                        localStorage.getItem('branch') == 'true' && <div className="my-[2px]">
                            <p className="mb-[2px] text-[13px]">Branch <span className="text-red-500">*</span></p>
                            <select
                                placeholder="Branch"
                                className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                                name="branch"
                                value={formValues?.branch}
                                onChange={onFormUpdate}
                            >
                                <option value="">--select branch--</option>
                                {
                                    branchDropDown.map((item, index) => (
                                        <option value={item.id} key={'branch' + index}>{item.branch_name}</option>
                                    ))
                                }
                            </select>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.branch}</p>
                        </div>
                    }
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Vendor <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Vendor"
                            className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="designer_name"
                            value={formValues?.designer_name}
                            onChange={onFormUpdate}
                        >
                            <option value="">--select vendor--</option>
                            {
                                designerDropDown.map((item, index) => (
                                    <option value={item.id} key={'designer' + index}>{item.account_head_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.designer_name}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">PO Date <span className="text-red-500">*</span></p>
                        <input
                            type="date"
                            placeholder="PO Date "
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="order_date"
                            value={formValues?.order_date}
                            onChange={onFormUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.order_date}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">No of Days <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="No of Days "
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="no_of_days"
                            value={formValues?.no_of_days}
                            onChange={onFormUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.no_of_days}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Payment Due <span className="text-red-500">*</span></p>
                        <input
                            type="date"
                            placeholder="Payment Due "
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="due_date"
                            value={formValues?.due_date}
                            onChange={onFormUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.due_date}</p>
                    </div>
                </div>
            </form>

            <form className="my-3 border-b pb-3" id='particularform'>
                <p className="font-[500] text-lg">Item Details</p>
                <div className="grid grid-cols-2 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-9 gap-2">
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Metal <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Metal"
                            className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="metal"
                            value={itemFormValues.metal}
                            onChange={onItemUpdate}
                        >
                            <option value="">--select metal--</option>
                            {
                                metalDropDown.map((item, index) => (
                                    <option value={item.id} key={'metal' + index}>{item.metal_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.metal}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Item Code <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Item Code"
                            className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="item_code"
                            value={itemFormValues.item_code}
                            onChange={onItemUpdate}
                        >
                            <option value="">--select item code--</option>
                            {
                                itemDropDown.map((item, index) => (
                                    <option value={item.id} key={'item_code' + index}>{item.item_code}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.item_code}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Item Name <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Item Name"
                            className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="item"
                            value={itemFormValues.item}
                            onChange={onItemUpdate}
                        >
                            <option value="">--select item--</option>
                            {
                                itemDropDown.map((item, index) => (
                                    <option value={item.id} key={'item_name' + index}>{item.item_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.item}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Sub Item Name <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Sub Item Name"
                            className="sm:mb-[2px] bg-white border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="sub_item"
                            value={itemFormValues.sub_item}
                            onChange={onItemUpdate}
                        >
                            <option value="">--select item--</option>
                            {
                                subItemDropDown.map((item, index) => (
                                    <option value={item.id} key={'item_name' + index}>{item.sub_item_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.sub_item}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Was % <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Was % "
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="wastage"
                            value={itemFormValues.wastage}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.wastage}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Flat Was <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Flat Was"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="flat_wastage"
                            value={itemFormValues.flat_wastage}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.flat_wastage}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">MC/G <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="MC/G "
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="making_charge_pergram"
                            value={itemFormValues.making_charge_pergram}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.making_charge_pergram}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Flat MC <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Flat MC"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="flat_makingcharge"
                            value={itemFormValues.flat_makingcharge}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.flat_makingcharge}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Pieces <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Pieces"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="pieces"
                            value={itemFormValues.pieces}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.pieces}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Gross Weight <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Gross Weight"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="gross_weight"
                            value={itemFormValues.gross_weight}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.gross_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Less Weight <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Less Weight"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="less_weight"
                            value={itemFormValues.less_weight}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.less_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Net Weight <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Net Weight"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="net_weight"
                            value={itemFormValues.net_weight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.net_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Touch <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Touch"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="touch"
                            value={itemFormValues.touch}
                            onChange={onItemUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.touch}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">St Pcs <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="St Pcs"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="no_stone_pieces"
                            value={itemFormValues.no_stone_pieces}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.no_stone_pieces}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">St Wt <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="St Wt"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="stone_weight"
                            value={itemFormValues.stone_weight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.stone_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Dia Pcs <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Dia Pcs"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="no_diamond_pieces"
                            value={itemFormValues.no_diamond_pieces}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.no_diamond_pieces}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Dia Wt <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Dia Wt"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="diamond_weight"
                            value={itemFormValues.diamond_weight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.diamond_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Pure Wt <span className="text-red-500">*</span></p>
                        <input
                            type="text"
                            placeholder="Pure Wt"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="pure_weight"
                            value={pureWeight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.pure_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Stone Rate <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Stone Rate"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="stone_amount"
                            value={itemFormValues.stone_amount}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.stone_amount}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Diamond Rate <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Diamond Rate"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="diamond_amount"
                            value={itemFormValues.diamond_amount}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.diamond_amount}</p>
                    </div>
                    {/* <div className="col-span-9 flex w-full justify-between gap-5"> */}
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Amount <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Amount"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="total_amount"
                            value={itemAmount}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{itemErrors.total_amount}</p>
                    </div>

                    {/* <div className="flex gap-3 items-center"> */}
                    <button
                        onClick={(e) => { e.preventDefault(); setStonePopup(!stonePopup) }}
                        className="capitalize border border-primary-btn text-primary-btn w-full rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none mt-6"
                    >Add/Update Sto</button>
                    <button
                        onClick={(e) => { e.preventDefault(); setDiamondPopup(!diamondPopup) }}
                        className="capitalize border border-primary-btn text-primary-btn w-full rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none mt-6"
                    >Add/Update Dia</button>
                    <button
                        className="capitalize bg-primary-btn text-white w-full rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none mt-6"
                        onClick={(e) => { e.preventDefault(); addItem() }}
                    >{itemFormMode} Item</button>
                    {/* </div> */}
                    {/* </div> */}
                </div>

                <p className="font-[500] text-lg mt-3">Particulars</p>
                <ParticularList setItemFormMode={setItemFormMode} setUpdateItemIndex={setUpdateItemIndex} setSelctedItem={setSelctedItem} particularList={particularList} setParticularList={setParticularList} setItemFormValues={setItemFormValues} setSelectedItem={getSubItemDropDownList} setStoneList={setStoneList} setDiamondList={setDiamondList} />
            </form>

            <form id="paymentdetails">
            <div className="grid grid-cols-2 sm:grid-cols-5 md:grid-cols-6 gap-2">
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Total Pcs <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Total Pcs"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="total_pieces"
                            value={formValues?.total_pieces}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.total_pieces}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Total Net Wt <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Total Net Wt"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="total_net_weight"
                            value={formValues?.total_net_weight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.total_net_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Total Pure Wt <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Total Pure Wt"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="total_pure_weight"
                            value={formValues?.total_pure_weight}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.total_pure_weight}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Hallmark Chrg ₹ <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Hallmark Charge"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="hallmark"
                            value={formValues?.hallmark}
                            onChange={onFormUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.hallmark}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Other ₹ <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Other"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="others"
                            value={formValues?.others}
                            onChange={onFormUpdate}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.others}</p>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[2px] text-[13px]">Total Amount <span className="text-red-500">*</span></p>
                        <input
                            type="number"
                            placeholder="Total Amount"
                            className="sm:mb-[2px] border border-gray-300 rounded-lg w-full p-[3px] text-[13px] outline-none"
                            name="total_amount"
                            value={grandTotal}
                            readOnly
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.total_amount}</p>
                    </div>
                </div>
            </form>

            <div className="fixed w-full bottom-[35px] left-0 bg-white min-[423px]:flex gap-4 justify-end sm:mt-0 xl:mt-[15px] p-[10px] mt-5">
                <button
                    id="resetitem"
                    onClick={(e) => { e.preventDefault(); }}
                    className="max-[423px]:mt-[5px] capitalize bg-secondary text-black w-[150px] rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                >Reset<span className="lowercase">(ctrl+z)</span></button>
                <button
                    onClick={(e) => { e.preventDefault(); handleSubmit(); }}
                    className="capitalize bg-primary-btn text-white w-[150px] rounded-lg p-[3px] h-fit font-[500] text-[13px] outline-none border-none"
                >{formMode} <span className="lowercase">(enter)</span></button>
            </div>

            <Popup title={`Stone`} extraClassName="min-w-full" setIsOpen={setStonePopup} isOpen={stonePopup} closeModal={closeStoneModal}>
                <StoneForm closeModal={closeStoneModal} dataList={stoneList} setDataList={setStoneList} formType={formMode} />
            </Popup>

            <Popup title={`Diamond`} extraClassName="min-w-full" setIsOpen={setDiamondPopup} isOpen={diamondPopup} closeModal={closeDiamondModal}>
                <DiamondForm closeModal={closeDiamondModal} dataList={diamondList} setDataList={setDiamondList} formType={formMode} />
            </Popup>


        </div>
    )
}