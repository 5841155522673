import { useEffect, useRef, useState } from "react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { branchListEndPoint, lotListEndPoint, taggingListEndPoint } from "../../../service/api";
import ItemtagFilters from "./singletagfilter";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { datetimetodate } from "../../../utilities/datetime";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Pagination from "../../../components/Pagination";

export default function SingleTag() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [ItemtagList, setItemtagList] = useState([]);
    const [filteredItemtagList, setFilteredItemtagList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);

    const [lotDropdown, setLotDropdown] = useState([]);
    const [selectedLot, setSeletedLot] = useState({})
    const [branchDropdown, setBranchDropDown] = useState([]);
    const [totalPages, setTotalPages] = useState(1);


    const { actions, pagination } = useSelector((state) => state.appConfig);

    useEffect(() => {
        getBranchDropdownData();
        getLotDropdown(1);
    }, [])


    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1,

    });

    useEffect(() => {
        getItemtagListData(tableFilters)
    }, [tableFilters])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list)
        }
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            setShowModal(true)
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    const getItemtagListData = async (body) => {
        var response = await postAxiosWithToken({
            url: taggingListEndPoint,
            body: body
        })

        if (response !== null) {
            setItemtagList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const getLotDropdown = async (id) => {
        var response = await getAxios({
            url: lotListEndPoint + id + '/'
        })

        if (response !== null) {
            setLotDropdown(response.data.list)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div>
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            localStorage.setItem('itemtag_form_mode', 'create'); navigate('demoitem-tag');
                        }}

                        className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Itemtag <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <ItemtagFilters searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[16px] font-[700]">
                            <tr>
                                <td className="p-[10px] min-w-[150px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Lot No</td>
                                <td className="p-[10px] min-w-[150px]">Branch</td>
                                <td className="p-[10px] min-w-[150px]">Designer Name</td>
                                <td className="p-[10px] min-w-[150px]">Created by</td>
                                <td className="p-[10px] min-w-[150px]">Entry Type</td>
                                <td className="p-[10px] min-w-[150px]">Tagging Status</td>
                                <td className="p-[10px] min-w-[150px]">Created Date</td>
                                {/* <td className="p-[10px] min-w-[150px]">View</td> */}
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-menu-head-bg">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={8}>Loading...</td>
                                </tr> :
                                    ItemtagList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                        ItemtagList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize">{item.lot_details__lot_number}</td>
                                                <td className="p-[10px] capitalize">{item.branch}</td>
                                                <td className="p-[10px] capitalize">{item.designer_name}</td>
                                                <td className="p-[10px] capitalize">{item.created_by}</td>
                                                <td className="p-[10px] capitalize">{item.entry_type}</td>
                                                <td className="p-[10px] capitalize">
                                                    <div style={{ backgroundColor: item.status_colour }} className="p-[5px] rounded-2xl text-center text-white">{item.tagging_status}</div>
                                                </td>
                                                <td className="p-[10px] capitalize">{datetimetodate(item.created_at, 3)}</td>
                                                {/* <td className="p-[10px] capitalize">
                                                    <div className="cursor-pointer " onClick={() => { localStorage.setItem('itemtag_form_id', item.id.toString()); navigate('view-itemtag-details') }}>
                                                        <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.392 0 9.878 3.88 10.819 9c-.94 5.12-5.427 9-10.819 9c-5.392 0-9.878-3.88-10.818-9C2.122 6.88 6.608 3 12 3m0 16a9.005 9.005 0 0 0 8.778-7a9.005 9.005 0 0 0-17.555 0A9.005 9.005 0 0 0 12 19m0-2.5a4.5 4.5 0 1 1 0-9a4.5 4.5 0 0 1 0 9m0-2a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5" /></svg>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>

        </>
    );
}