import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { metalListEndPoint, taxEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";

export default function TaxsForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentTaxs, setCurrentTaxs] = useState({});

    const [metalDropdown, setMetalDropdown] = useState([]);

    const [selectedMetal, setSelectedMetal] = useState('');


    const schema = yup
        .object({
            tax_code: yup.string().required(),
            tax_name: yup.string().required(),
            tax_description: yup.string().required(),
            metal: yup.string().required(),
            purchase_tax_igst: yup.string().required(),
            purchase_tax_cgst: yup.string().required(),
            purchase_tax_sgst: yup.string().required(),
            purchase_surcharge_percent: yup.string().required(),
            purchase_additional_charges: yup.string().required(),
            sales_tax_igst: yup.string().required(),
            sales_tax_cgst: yup.string().required(),
            sales_tax_sgst: yup.string().required(),
            sales_surcharge_percent: yup.string().required(),
            sales_additional_charges: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        values: formMode === 'update' ? {
            tax_code: currentTaxs?.tax_code,
            tax_name: currentTaxs?.tax_name,
            tax_description: currentTaxs?.tax_description,
            purchase_tax_igst: currentTaxs?.purchase_tax_igst,
            purchase_tax_cgst: currentTaxs?.purchase_tax_cgst,
            purchase_tax_sgst: currentTaxs?.purchase_tax_sgst,
            purchase_surcharge_percent: currentTaxs?.purchase_surcharge_percent,
            purchase_additional_charges: currentTaxs?.purchase_additional_charges,
            sales_tax_igst: currentTaxs?.sales_tax_igst,
            sales_tax_cgst: currentTaxs?.sales_tax_cgst,
            sales_tax_sgst: currentTaxs?.sales_tax_sgst,
            sales_surcharge_percent: currentTaxs?.sales_surcharge_percent,
            sales_additional_charges: currentTaxs?.sales_additional_charges
        } : {
            tax_code: "",
            tax_name: "",
            tax_description: "",
            purchase_tax_igst: "",
            purchase_tax_cgst: "",
            purchase_tax_sgst: "",
            purchase_surcharge_percent: "",
            purchase_additional_charges: "",
            sales_tax_igst: "",
            sales_tax_cgst: "",
            sales_tax_sgst: "",
            sales_surcharge_percent: "",
            sales_additional_charges: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            reset()
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getMetalDropdownData();

        setFormMode(localStorage.getItem('tax_form_mode'))

        if (localStorage.getItem('tax_form_mode') === 'update') {
            getTaxsDetails()
        }
    }, [])

    const getTaxsDetails = async () => {
        var response = await getAxios({
            url: taxEndPoint + localStorage.getItem('tax_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                tax_code: response.data?.tax_details?.tax_code,
                tax_name: response.data?.tax_details?.tax_name,
                tax_description: response.data?.tax_details?.tax_description,
                metal: response.data?.tax_details?.metal,
                purchase_tax_igst: response.data?.purchase_details?.purchase_tax_igst,
                purchase_tax_cgst: response.data?.purchase_details?.purchase_tax_cgst,
                purchase_tax_sgst: response.data?.purchase_details?.purchase_tax_sgst,
                purchase_surcharge_percent: response.data?.purchase_details?.purchase_surcharge_percent,
                purchase_additional_charges: response.data?.purchase_details?.purchase_additional_charges,
                sales_tax_igst: response.data?.sales_details?.sales_tax_igst,
                sales_tax_cgst: response.data?.sales_details?.sales_tax_cgst,
                sales_tax_sgst: response.data?.sales_details?.sales_tax_sgst,
                sales_surcharge_percent: response.data?.sales_details?.sales_surcharge_percent,
                sales_additional_charges: response.data?.sales_details?.sales_additional_charges
            }

            setSelectedMetal(response.data?.tax_details?.metal)

            setCurrentTaxs(tempDetails)
        }
    }

    const getMetalDropdownData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropdown(response.data.list)
        }
    }

    const onSubmit = async (data) => {
        if (localStorage.getItem('tax_form_mode') === 'create') {

            let createBody = {
                tax_details: {
                    tax_code: data.tax_code,
                    tax_name: data.tax_name,
                    tax_description: data.tax_description,
                    metal: data.metal
                },
                purchase_details: {
                    purchase_tax_igst: data.purchase_tax_igst,
                    purchase_tax_cgst: data.purchase_tax_cgst,
                    purchase_tax_sgst: data.purchase_tax_sgst,
                    purchase_surcharge_percent: data.purchase_surcharge_percent,
                    purchase_additional_charges: data.purchase_additional_charges
                },
                sales_details: {
                    sales_tax_igst: data.sales_tax_igst,
                    sales_tax_cgst: data.sales_tax_cgst,
                    sales_tax_sgst: data.sales_tax_sgst,
                    sales_surcharge_percent: data.sales_surcharge_percent,
                    sales_additional_charges: data.sales_additional_charges
                }
            }

            createTaxs(createBody)

        } else {

            let updateBody = {
                tax_details: {
                    tax_code: data.tax_code,
                    tax_name: data.tax_name,
                    tax_description: data.tax_description,
                    metal: data.metal
                },
                purchase_details: {
                    purchase_tax_igst: data.purchase_tax_igst,
                    purchase_tax_cgst: data.purchase_tax_cgst,
                    purchase_tax_sgst: data.purchase_tax_sgst,
                    purchase_surcharge_percent: data.purchase_surcharge_percent,
                    purchase_additional_charges: data.purchase_additional_charges
                },
                sales_details: {
                    sales_tax_igst: data.sales_tax_igst,
                    sales_tax_cgst: data.sales_tax_cgst,
                    sales_tax_sgst: data.sales_tax_sgst,
                    sales_surcharge_percent: data.sales_surcharge_percent,
                    sales_additional_charges: data.sales_additional_charges
                }
            }

            updateTaxs(updateBody)
        }
    }

    const createTaxs = async (body) => {
        var response = await postAxiosWithToken({
            url: taxEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/masters/tax')
        }
    }

    const updateTaxs = async (body) => {
        var response = await putAxiosWithToken({
            url: taxEndPoint + localStorage.getItem('tax_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/masters/tax')
        }
    }

    const clearData = () => {
        reset()
        setCurrentTaxs({
            tax_code: "",
            tax_name: "",
            tax_description: "",
            purchase_tax_igst: "",
            purchase_tax_cgst: "",
            purchase_tax_sgst: "",
            purchase_surcharge_percent: "",
            purchase_additional_charges: "",
            sales_tax_igst: "",
            sales_tax_cgst: "",
            sales_tax_sgst: "",
            sales_surcharge_percent: "",
            sales_additional_charges: ""
        })
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Taxs</p>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Taxs Details</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Tax Code<span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Tax Code"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tax_code"
                                    id="forminit"
                                    {...register('tax_code')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.tax_code?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Tax Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Tax Name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tax_name"
                                    {...register('tax_name')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.tax_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Tax Description<span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Tax Description"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tax_description"
                                    {...register('tax_description')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.tax_description?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Metal <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Metal"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="metal"
                                    {...register('metal')}
                                    value={selectedMetal}
                                    onChange={(e) => setSelectedMetal(e.target.value)}
                                >
                                    <option value={""}>--select metal--</option>
                                    {
                                        metalDropdown.map((option) => (
                                            <option className="capitalize" key={"metal" + option.metal_name} value={option.id}>{option.metal_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.metal?.message}</p>
                            </div>

                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Purchase Details</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">CGST % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="CGST percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_tax_cgst"
                                    {...register('purchase_tax_cgst')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_tax_cgst?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">SGST % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="SGST percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_tax_sgst"
                                    {...register('purchase_tax_sgst')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_tax_sgst?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">IGST % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="IGST percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_tax_igst"
                                    {...register('purchase_tax_igst')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_tax_igst?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">sur charge % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="sur-charge percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_surcharge_percent"
                                    {...register('purchase_surcharge_percent')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_surcharge_percent?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Additional Charges <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="Additional Charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_additional_charges"
                                    {...register('purchase_additional_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_additional_charges?.message}</p>
                            </div>

                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Sales Details</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">CGST % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="CGST percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_tax_cgst"
                                    {...register('sales_tax_cgst')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_tax_cgst?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">SGST % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="SGST percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_tax_sgst"
                                    {...register('sales_tax_sgst')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_tax_sgst?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">IGST % <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="IGST percent"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_tax_igst"
                                    {...register('sales_tax_igst')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_tax_igst?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">surcharge% <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="surcharge"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_surcharge_percent"
                                    {...register('sales_surcharge_percent')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_surcharge_percent?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Additional Charges <span className="text-red-500">*</span></p>
                                <input
                                    type="number"
                                    min="0"
                                    step={0.01}
                                    placeholder="Additional Charges"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sales_additional_charges"
                                    {...register('sales_additional_charges')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.sales_additional_charges?.message}</p>
                            </div>

                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}