import { useEffect, useState } from "react";
import { fixedCalcId } from "../../constant";
import { useSelector } from "react-redux";

export default function InvoicePrint({ billDate, billNo, name, mobile, items, payableAmount, gstAmount, door, street, district, pincode, taluk,companyDetails ,  printsize  }) {
    console.log(companyDetails, "companyDetails")
    console.log(mobile, "mobilemobile")

    const { todayGoldRate, todaySilverRate } = useSelector((state) => state.appConfig);

    const [wastagerate, setwastagerate] = useState([]);

    const [size, setSize] = useState([]);


    useEffect(() => {
        let Sizelist;
        for (var item of printsize) {
            Sizelist = item.billing_is_a4
        }
        setSize(Sizelist)
    }, [printsize])



    useEffect(() => {
        const calculateWastageRate = (metalRate, wastagePercentage) => {
            return (metalRate * wastagePercentage) / 100;
        };

        const updatedItems = items.map(item => {
            const metalRate = parseFloat(item.metal_rate);
            const wastagePercentage = parseFloat(item.wastage_percent);
            const wastageRate = calculateWastageRate(metalRate, wastagePercentage);

            return {
                ...item,
                wastage_rate: wastageRate.toFixed(2),
            };


        });
        setwastagerate(updatedItems);
    }, [items]);


    const numberToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const scales = ['', 'Thousand', 'Million', 'Billion'];


        const formattedNum = num.toFixed(2);


        let words = '';
        let scaleIndex = 0;

        if (formattedNum === '0.00') return 'Zero';


        const [wholePart, decimalPart] = formattedNum.split('.');


        let wholeWords = '';
        for (let i = 0; i < wholePart.length; i += 3) {
            const chunk = parseInt(wholePart.slice(-i - 3, wholePart.length - i), 10);
            if (chunk !== 0) {
                const chunkWords = chunkToWords(chunk);
                wholeWords = chunkWords + ' ' + scales[scaleIndex] + ' ' + wholeWords;
            }
            scaleIndex++;
        }

        words += wholeWords.trim();


        if (decimalPart !== '00') {
            const decimalWords = chunkToWords(parseInt(decimalPart, 10));
            words += ' and ' + decimalWords + ' Cents';
        }

        return words.trim();

        function chunkToWords(chunk) {
            let chunkWords = '';
            if (chunk < 10) {
                chunkWords = units[chunk];
            } else if (chunk < 20) {
                chunkWords = teens[chunk - 10];
            } else {
                chunkWords = tens[Math.floor(chunk / 10)] + ' ' + units[chunk % 10];
            }
            return chunkWords;
        }
    };


    let balanceAmount = payableAmount;

    // Calculate totalAmount by rounding off payableAmount
    let totalAmount = Math.round(payableAmount);

    // Calculate the remaining balance after deducting the total amount
    let remainingBalance = balanceAmount - totalAmount; 

    // Round off the remaining balance to 2 decimal places
    let roundedBalance = remainingBalance.toFixed(2);

    console.log(roundedBalance);

    return (
       <div className={` ${size === true ? "w-full" : "max-w-[500px]"} relative h-[1100px] p-2`}>
            <div className="h-[235px] border w-full flex ">
                <div className="w-full">
                    <div className="flex gap-5 p-2 border-l border-y border-black h-[135px]">
                        <div className="w-[42px] h-[42px] bg-gray-400 rounded-full"></div>
                        <div className="text-[11px]">
                            <p className="text-[11px] font-[600]">{companyDetails.company_details?.company_name}</p>
                            <p>{companyDetails.address_details?.door_no},{companyDetails.address_details?.street_name}</p>
                            <p>{companyDetails.address_details?.area},{companyDetails.address_details?.taluk},{companyDetails.address_details?.district},</p>
                            <p>Tamil Nadu - {companyDetails.address_details?.postal}</p>
                            <p>Ph : <span>{companyDetails.company_details?.mobile_no}</span></p>
                            <p>GSTIN : <span>{companyDetails.gst_details?.gst_no}</span></p>
                            <p>Email : <span>{companyDetails.company_details?.email_id}</span></p>
                        </div>
                    </div>
                    <div className="p-2 border-l border-black border-b h-[100px] text-[11px]">
                        <p className="text-[11px] font-[600]">Bill To</p>
                        <p>{name}</p>
                        <p>{door}, {street},{taluk}, {district},</p>
                        <p>Tamil Nadu - {pincode}</p>
                        <p>Ph : <span>{mobile}</span></p>
                    </div>
                </div>
                <div className="w-full h-[400px] text-[11px]">
                    <div className="p-1 border h-[58px] border-black">
                        <p className="font-[600]">Bill. No #</p>
                        <p>{billNo}</p>
                    </div>
                    <div className="p-1 border-x h-[58px] border-black">
                        <p className="font-[600]">Date</p>
                        <p>{billDate}</p>
                    </div>
                    <div className="p-1 border h-[58px] border-black">
                        <p className="font-[600]">Today's Gold Rate</p>
                        <p>₹{todayGoldRate}.00</p>
                    </div>
                    <div className="p-1 border-x border-b h-[60px] border-black">
                        <p className="font-[600]">Today's Silver Rate</p>
                        <p>₹{todaySilverRate}.00</p>
                    </div>


                </div>
            </div>


            <div className="mt-[10px]">
                <table className="w-full ">
                    <thead>
                        <tr>
                            <td className="border border-black px-2 text-center">Description Of Goods</td>
                            <td className="border border-black px-2 text-center">Types</td>
                            <td className="border border-black px-2 text-center">Weight</td>
                            <td className="border border-black px-2 text-center">Wastage</td>
                            <td className="border border-black px-2 text-center">Amount</td>
                        </tr>
                    </thead>
                    <tbody className="border-b border-black h-[300px]">
                        {
                            wastagerate.map((item, index) => (
                                <tr className="">
                                    <td className="border-x border-black px-2 text-center">{item.item} (#{item.tag_number})</td>
                                    <td className="border-x border-black px-2 text-center">{item.jewel_type}</td>
                                    <td className="border-x border-black px-2 text-center">{item.metal_weight}</td>
                                    <td className="border-x border-black px-2 text-center">{item.wastage_rate}</td>
                                    <td className="border-x border-black px-2 text-center">{item.rate}</td>
                                </tr>

                            ))
                        }
                        <tr className="h-[35px]">
                            <td className="border border-black px-2 text-right">CGST @ 1.5%</td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center">{(gstAmount / 2).toFixed(2)}</td>
                        </tr>
                        <tr className="h-[35px]">
                            <td className="border border-black px-2 text-right">SGST @ 1.5%</td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center">{(gstAmount / 2).toFixed(2)}</td>
                        </tr>
                        <tr className="h-[35px]">
                            <td colSpan={2} className="border border-black px-2 text-right">Round Off Amount</td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center">{roundedBalance}</td>
                        </tr>
                        <tr className="h-[35px]">
                            <td colSpan={2} className="border border-black px-2 text-right">Total</td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center"></td>
                            <td className="border border-black px-2 text-center">{Math.round(payableAmount)}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div className="border-x border-black h-20 p-2">
                <p className="text-[16px] font-600">Amount In Words:</p>
            </div>


            <div className="border-x border-black h-20 p-2 text-center">

                <p className="text-[16px] font-600 mt-2" >{numberToWords(Math.round(payableAmount))} only</p>
            </div>
        


            <div className="h-[100px] flex">
                <div className="border-y border-l border-black w-full p-2">
                    <p className="text-[12px] font-600 underline mb-[10px]">Declaration:</p>
                    <p className="text-[11px]">We declare that this invoice shows the actual prices of goods describe and that all particulars are true and correct.</p>
                </div>
                <div className="border border-black w-full h-full p-2 items-end flex flex-col justify-between">
                    <p className="text-[14px] font-600 ">For {companyDetails.company_details?.company_name},</p>
                    <p className="text-[11px]">Authorised Signatory</p>
                </div>
            </div>
        </div>
    );
}