import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { convertToDate } from '../../../utilities/datetime';
import { addDays } from 'date-fns/esm';
import { counterTargetListEndPoint, customermobileEndPoint, itemMasterListEndPoint, metalListEndPoint } from '../../../service/api';
import { getAxios } from '../../../service/apiservice';
import Select from "react-select";

export default function StockTagWiseReportForm({ searchRef, setFilters, filters }) {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [metalDropDown, setMetalDropDown] = useState([]);
    const [ItemDropDown, setItemDropDown] = useState([]);
    const [LocationDropDown, setLocationDropDown] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getMetalListData()
        getCustomermobileListDropDown()
        getItemListData()
        getLocationListData()
    }, [])


    const getCustomermobileListDropDown = async (value) => {
        var response = await getAxios({
            url: customermobileEndPoint
        })
        if (response !== null) {
            let tempList = [];
            for (var i of response.data.list) {
                let dictData = {
                    value: i.id,
                    label: i.phone
                }
                tempList.push(dictData)
            }
            setOptions(tempList);

        }
    }

    const getMetalListData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropDown(response.data.list)
        }
    }

    const getItemListData = async () => {
        var response = await getAxios({
            url: itemMasterListEndPoint
        })

        if (response !== null) {
            setItemDropDown(response.data.list)
        }
    }

    const getLocationListData = async () => {
        var response = await getAxios({
            url: counterTargetListEndPoint
        })

        if (response !== null) {
            setLocationDropDown(response.data.list)
        }
    }

    const handleDateFromFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            from_date: value === null ? null : convertToDate(value)
        }))
    }

    const handleDateToFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            to_date: value === null ? null : convertToDate(addDays(value, 1)),
        }))
    }

    const handleDateClearFilter = () => {
        setFilters((prevState) => ({
            ...prevState,
            from_date: null,
            to_date: null
        }))
    }

    const handleCustomerFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            customer_details: value
        }))
    }


    const handleMetalFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            metal_type: value === "" ? null : value
        }))
    }


    const handlesearchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            search: value
        }))
    }

    const handleLocationFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            location: value === "" ? null : value
        }))
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg shadow border mt-5">
                <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-5 mt-4">
              
                    <div className="my-[2px]">
                        <p className="mb-[5px] text-[12px]">Search<span className="text-red-500 ">*</span></p>
                        <input
                            placeholder="Search"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] bg-white outline-none"
                            name="search"
                            onChange={(e) => handlesearchFilter(e.target.value)}
                            value={filters.search}
                        />
                           
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[5px] text-[12px]">Metal Type<span className="text-red-500 ">*</span></p>
                        <select
                            placeholder="Purchase Category"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] bg-white outline-none"
                            name="metal_type"
                            onChange={(e) => handleMetalFilter(e.target.value)}
                            value={filters.metal_type}
                        >
                            <option value={""}>--select metal-</option>
                            {
                                metalDropDown.map((option) => (
                                    <option key={option.id} value={option.id}>{option.metal_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[2px]">
                        <p className="mb-[5px] text-[12px]">Location <span className="text-red-500 ">*</span></p>
                        <select
                            placeholder="Purchase Category"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] bg-white outline-none"
                            name="location"
                            onChange={(e) => handleLocationFilter(e.target.value)}
                            value={filters.location}
                        >
                            <option value={""}>--select metal-</option>
                            {
                                LocationDropDown.map((option) => (
                                    <option key={option.id} value={option.id}>{option.counter_details__counter_name}</option>
                                ))
                            }
                        </select>
                    </div>


                    <div className='w-full'>
                        <p className="mb-[5px] text-[12px]">Date <span className="text-red-500 ">*</span></p>
                        <div className='min-[400px]:flex gap-5 w-full min-[400px]:justify-between border items-center py-[10px] px-[10px] rounded-xl'>
                            <div className=''>
                                <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateFromFilter} value={filters.from_date} />
                            </div>
                            <p className='text-center'>to</p>
                            <div>
                                <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateToFilter} value={filters.to_date} />
                            </div>
                            <div onClick={() => { setFromDate(null); setToDate(null); handleDateClearFilter() }} className='cursor-pointer'>
                                <svg className='min-[400px]:block hidden' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z" /></svg>
                                <p className='min-[400px]:hidden text-center block'>clear</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}