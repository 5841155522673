import { useEffect, useRef, useState } from "react";
import SingletagForm from "./singletagform";

export default function Singletag({ tab }) {

    const searchRef = useRef();

    const [currentSingletag, setCurrentSingletag] = useState({});
    const [formMode, setFormMode] = useState('create');

    return (
        <>
            <div>
                <SingletagForm tab={tab} formMode={formMode} setFormMode={setFormMode} currentSingletag={currentSingletag} setCurrentSingletag={setCurrentSingletag} />
            </div>
        </>
    );
}