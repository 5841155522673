import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { datetimetodate } from "../../../utilities/datetime";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { postAxiosWithToken } from "../../../service/apiservice";
import { counterTargetListEndPoint } from "../../../service/api";
import CounterTargetForm from "./countertargetform";
import CounterTargetFilters from "./countertargetfilter";

export default function CounterTarget() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [counterTargetList, setCounterTargetList] = useState([]);
    const [currentCounterTarget, setCurrentCounterTarget] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { actions, pagination } = useSelector((state) => state.appConfig);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        branch: null,
        counter: null,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getCounterTargetList()
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('customer-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    const getCounterTargetList = async () => {
        var response = await postAxiosWithToken({
            url: counterTargetListEndPoint,
            body: tableFilters
        })

        if (response !== null) {
            setCounterTargetList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    return (
        <>
            {
                formMode === 'create' ? actions.addPermit ? <div>
                    <CounterTargetForm getData={getCounterTargetList} formMode={formMode} setFormMode={setFormMode} currentCounterTarget={currentCounterTarget} setCurrentCounterTarget={setCurrentCounterTarget} />
                </div> : '' : actions.editPermit ? <div>
                    <CounterTargetForm getData={getCounterTargetList} formMode={formMode} setFormMode={setFormMode} currentCounterTarget={currentCounterTarget} setCurrentCounterTarget={setCurrentCounterTarget} />
                </div> : ''
            }

            <div id="filteroptions" className='my-[25px] hidden'>
                <p className="font-[600] text-[18px]">Filters</p>
                <CounterTargetFilters filters={tableFilters} setFilters={setTableFilters} searchRef={searchRef} getData={getCounterTargetList} />
            </div>
            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>

                            <td className="p-[10px]">S.No</td>
                            <td className="p-[10px] min-w-[150px]">Counter</td>
                            <td className="p-[10px] min-w-[150px]">Branch</td>
                            <td className="p-[10px] min-w-[150px]">From Date</td>
                            <td className="p-[10px] min-w-[150px]">To Date</td>
                            <td className="p-[10px] min-w-[150px]">Target Wgt</td>
                            <td className="p-[10px] min-w-[150px]">Target Pcs</td>
                            <td className="p-[10px] min-w-[150px]">Target Amt</td>
                            <td className="p-[10px] min-w-[150px]">Status</td>
                            <td className="p-[10px] min-w-[150px]">Achived Wgt</td>
                            <td className="p-[10px] min-w-[150px]">Achived Pcs</td>
                            <td className="p-[10px] min-w-[150px]">Achived Amt</td>
                            <td className="p-[10px] min-w-[150px]">Remaining Wgt</td>
                            <td className="p-[10px] min-w-[150px]">Remaining Pcs</td>
                            <td className="p-[10px] min-w-[150px]">Remaining Amt</td>
                            <td className="p-[10px] min-w-[150px]">Created at</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey bg-menu-head-bg">
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={8}>Loading...</td>
                            </tr> :
                                counterTargetList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                    counterTargetList.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px]">{index + 1}</td>
                                            <td className="p-[10px] capitalize">{item.counter_name}</td>
                                            <td className="p-[10px] capitalize">{item.branch_name}</td>
                                            <td className="p-[10px] capitalize">{item.target_from_date}</td>
                                            <td className="p-[10px] capitalize">{item.target_to_date}</td>
                                            <td className="p-[10px] capitalize">{item.target_pieces}</td>
                                            <td className="p-[10px] capitalize">{item.target_weight}</td>
                                            <td className="p-[10px] capitalize">{item.target_amount}</td>
                                            <td className="p-[10px] capitalize">
                                                <div style={{ backgroundColor: item.status_colour }} className="p-2 rounded-full text-center text-white">{item.target_status}</div>
                                            </td>
                                            <td className="p-[10px] capitalize">{item.achived_pieces}</td>
                                            <td className="p-[10px] capitalize">{item.achived_weight}</td>
                                            <td className="p-[10px] capitalize">{item.achived_amount}</td>
                                            <td className="p-[10px] capitalize">{item.remaining_pieces}</td>
                                            <td className="p-[10px] capitalize">{item.remaining_weight}</td>
                                            <td className="p-[10px] capitalize">{item.remaining_amount}</td>
                                            <td className="p-[10px] capitalize">{datetimetodate(item.created_at)}</td>
                                        </tr>
                                    ))
                        }

                    </tbody>
                </table>
            </div>
        </>
    );
}