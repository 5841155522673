import { useEffect, useState } from "react";
import BagForm from "./bagform";
import FilterForm from "./filterform";
import ParticularList from "./particularlist";
import { isValidForm } from "../../../utilities/formvalidation";
import { bagTransferType } from "../../../constant";
import { postAxiosWithToken } from "../../../service/apiservice";
import { transferCreationEndPoint } from "../../../service/api";
import toast from "react-hot-toast";

export default function TransferCreation() {

    const [filterFormValues, setFilterFormValues] = useState({});

    const [bagFormValues, setBagFormValues] = useState({
        transfer_type: bagTransferType,
        total_gross_weight: 0.0,
        total_net_weight: 0.0,
        total_dust_weight: 0.0
    });
    const [bagErrors, setBagErrors] = useState({})

    const [particularList, setParticularList] = useState([]);
    const [selectedParticularList, setSelectedParticularList] = useState([]);

    useEffect(() => {
        let grossWeight = 0.0;
        let netWeight = 0.0;
        let dustWeight = 0.0;
        for (var i of selectedParticularList) {
            grossWeight = grossWeight + i.gross_weight
            netWeight = netWeight + i.net_weight
            dustWeight = dustWeight + i.dust_weight
        }
        setBagFormValues((prevState) => ({
            ...prevState,
            total_gross_weight: grossWeight,
            total_net_weight: netWeight,
            total_dust_weight: dustWeight
        }))
    },[selectedParticularList])

    const handleSubmit = (e) => {
        e.preventDefault();

        var formData = new FormData(e.target);

        var err = {}

        for (var field of Object.entries(Object.fromEntries(formData.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setBagErrors(err)

        if (isValidForm(Object.fromEntries(formData.entries()))) {
            if (selectedParticularList.length > 0) {
                let data = {...bagFormValues}
                data['branch'] = filterFormValues.branch
                data['metal'] = filterFormValues.metal
                data['item_details'] = selectedParticularList
                createTransferCreation(data)
            } else {
                toast.error('Select items')
            }
        }
    }

    const createTransferCreation = async (data) => {
        var response = await postAxiosWithToken({
            url: transferCreationEndPoint,
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForms();
        }
    }

    const resetForms = () => {
        setFilterFormValues((prevState) => ({
            ...prevState,
            metal: "",
            branch: "",
            from_date: "",
            to_date: ""
        }))
        setBagFormValues((prevState) => ({
            ...prevState,
            transfer_type: bagTransferType,
            total_gross_weight: 0.0,
            total_net_weight: 0.0,
            total_dust_weight: 0.0
        }))
        setSelectedParticularList([])
        setParticularList([])
    }

    return (
        <div>
            <p className="font-[600] text-[18px]">Transfer Creation</p>
            <div className="flex gap-3 items-start">
                <div className="bg-white rounded-xl p-5 border w-full">
                    <FilterForm setParticularList={setParticularList} formValues={filterFormValues} setFormValues={setFilterFormValues} />
                </div>
                <div className="bg-white rounded-xl p-5 border w-full">
                    <BagForm handleSubmit={handleSubmit} formValues={bagFormValues} setFormValues={setBagFormValues} errors={bagErrors} setErrors={setBagErrors}/>
                </div>
            </div>
            <div className="bg-white rounded-xl p-2 border w-full my-3">
                <ParticularList particularList={particularList} selectedItem={selectedParticularList} setSelectedItem={setSelectedParticularList} />
            </div>
            <div className="flex gap-3 items-center bg-white fixed bottom-[35px] left-0 p-4 border w-full">
                <div className="text-[16px] flex gap-3 items-center">
                    <p className="font-[500]">Total Gross Weight:</p>
                    <p className="font-[500] text-primary-btn">{bagFormValues.total_gross_weight}</p>
                </div>
                <div className="text-[16px] flex gap-3 items-center">
                    <p className="font-[500]">Total Net Weight:</p>
                    <p className="font-[500] text-primary-btn">{bagFormValues.total_net_weight}</p>
                </div>
                <div className="text-[16px] flex gap-3 items-center">
                    <p className="font-[500]">Total Dust Weight:</p>
                    <p className="font-[500] text-primary-btn">{bagFormValues.total_dust_weight}</p>
                </div>
            </div>
        </div>
    );
}