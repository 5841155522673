
import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { convertToDate } from '../../../utilities/datetime';
import { addDays } from 'date-fns/esm';
import DatePicker1 from '../../../components/Datepicker';
import { branchListEndPoint, calculationtypeDropdownEndPoint, counterListEndPoint, getStockListEndPoint, itemMasterListEndPoint, lotListEndPoint, stocktypeDropdownEndPoint, subitemListEndPoint, tagListEndPoint, tagNumbertypeEndPoint } from '../../../service/api';
import { getAxios, postAxiosWithToken } from '../../../service/apiservice';

export default function StocklistFilters({ searchRef, setFilters,filters }) {

    const [tagnumberdropdown, setTagNumberDropdown] = useState([])
    const [itemdropdown, setItemDropdown] = useState([]);
    const [subitemdropdown, setSubItemDropdown] = useState([]);
    const [tagdropdown, setTagDropdown] = useState([]);
    const [stockdropdown, setStockDropdown] = useState([]);
    const [calculculationtypedropdown, setCalculationtypeDropdown] = useState([]);
    const [lotdropdown, setLotDropdown] = useState([]);
    const [counterdropdown, setCounterDropdown] = useState([]);
    const [branchList, setBranchList] = useState([]);

    useEffect(()=>{
        getBranchDropdownData();
        getItemDropdownData();
        getSubItemDropdownData();
        getTagTypeDropdownData();
        getStockTypeDropdownData();
        getCalculationTypeDropdownData();
        getLotDropdownData();
        getCounterDropdownData();
        getTagNumberDropdownData();
    },[])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
          url: branchListEndPoint,
        });
    
        if (response !== null) {
            setBranchList(response.data.list);
        }
      };

      const getTagNumberDropdownData = async () => {
        var response = await getAxios({
            url: tagNumbertypeEndPoint

        })

        if (response !== null) {
            setTagNumberDropdown(response.data.list)
        }
    }

    const getItemDropdownData = async () => {
        var response = await getAxios({
            url: itemMasterListEndPoint

        })

        if (response !== null) {
            setItemDropdown(response.data.list)
        }
    }


    const getSubItemDropdownData = async () => {
        var response = await getAxios({
            url: subitemListEndPoint

        })

        if (response !== null) {
            setSubItemDropdown(response.data.list)
        }
    }


    const getTagTypeDropdownData = async () => {
        var response = await getAxios({
            url: tagListEndPoint

        })

        if (response !== null) {
            setTagDropdown(response.data.list)
        }
    }

    const getStockTypeDropdownData = async () => {
        var response = await getAxios({
            url: stocktypeDropdownEndPoint

        })

        if (response !== null) {
            setStockDropdown(response.data.list)
        }
    }


    const getCalculationTypeDropdownData = async () => {
        var response = await getAxios({
            url: calculationtypeDropdownEndPoint

        })

        if (response !== null) {
            setCalculationtypeDropdown(response.data.list)
        }
    }

    const getLotDropdownData = async () => {
        var response = await getAxios({
            url: lotListEndPoint

        })

        if (response !== null) {
            setLotDropdown(response.data.list)
        }
    }

    const getCounterDropdownData = async () => {
        var response = await getAxios({
            url: counterListEndPoint

        })  

        if (response !== null) {
          setCounterDropdown(response.data.list)                                                   
        }
    }




    const handleSearch = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            search: value
        }))
    }

    const handleDateFilter = (value) => {
        console.log(value)
            setFilters((prevState) => ({
                ...prevState,
                from_date: value.startDate,
                to_date: value.endDate
            }))
    }

    const handleStatusFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            active_status: value === "all" ? null : JSON.parse(value)
        }))
    }


    const handleBranchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            branch: value === "all" ? null : value
        }))
    }
    const handletagTypeFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            tag_type: value === "all" ? null : value
        }))
    }
    const handleItemTypeFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            item_details: value === "all" ? null : value
        }))
    }
    const handleSubItemTypeFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            sub_item_details: value === "all" ? null : value
        }))
    }

    const handleStocktypeFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            stock_type: value === "all" ? null : value
        }))
    }
    const handleCalculationtypeFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            calculation_type: value === "all" ? null : value
        }))
    }
    const handleLotdetailsFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            lot_details: value === "all" ? null : value
        }))
    }
    const handleCounterFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            counter: value === "all" ? null : value
        }))
    }
   

    return (
        <>
            <div className='flex gap-4 justify-between' >
            
            {localStorage.getItem("branch") === "true" && 
                    <div className=''>
                        <select
                            placeholder="metal_type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                            name="branch"
                            value={filters.branch} 
                            onChange={(e) => handleBranchFilter(e.target.value)}
                            >
                            <option value="all">All Branch</option>
                            {branchList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                {option?.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>}

                    <div className="border rounded-lg h-fit">
                    <DatePicker1
                     handleValueChange={handleDateFilter}
                      value={{ 
                        startDate: filters.from_date,
                        endDate: filters.to_date 
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full mt-5">
                <div className='sm:flex justify-between lg:gap-3 w-full 2xl:w-[73%]'>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Tag Number</p>
                        <input
                            placeholder="Tag Number"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="search" // Set the name attribute to "tag_number"
                            value={filters.search} 
                            onChange={(e) => handleSearch(e.target.value)}
                            ref={searchRef}
                        />
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Item Name</p>
                        <select
                            placeholder="item name"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="item_details" // Set the name attribute to "item_details"
                            value={filters.item_details} 
                            onChange={(e) => handleItemTypeFilter(e.target.value)}
                        >
                            <option value="all">All Item</option>
                            {itemdropdown?.map((option) => (
                                <option key={option.id} value={option.id}>{option.item_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Sub Item Name</p>
                        <select
                            placeholder="Sub Item name"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="sub_item_details"
                            value={filters.sub_item_details} 
                            onChange={(e) => handleSubItemTypeFilter(e.target.value)}

                        >
                            <option value="all">All Sub Item</option>
                            {
                                subitemdropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.sub_item_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Tag Type</p>
                        <select
                            placeholder="Tag Type"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="tag_type"
                            value={filters.tag_type} 
                            onChange={(e) => handletagTypeFilter(e.target.value)}

                        >
                            <option value="all">All Tag Type</option>
                            {
                                tagdropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.tag_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Stock Type</p>
                        <select
                            placeholder="Stock Type"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="stock_type"
                            value={filters.stock_type} 
                            onChange={(e) => handleStocktypeFilter(e.target.value)}
                        >
                            <option value="all">All Stock Type</option>
                            {
                                stockdropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.stock_type_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Calculation Type</p>
                        <select
                            placeholder="purity name"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="calculation_type"
                            value={filters.calculation_type} 
                            onChange={(e) => handleCalculationtypeFilter(e.target.value)}

                        >
                            <option value="all">All Calculation Type</option>
                            {
                                calculculationtypedropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.calculation_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Lot</p>
                        <select
                            placeholder="Lot"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="lot_details"
                            value={filters.lot_details} 
                            onChange={(e) => handleLotdetailsFilter(e.target.value)}

                        >
                            <option value="all">All Lot</option>
                            {
                                lotdropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.lot_number}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Counter</p>
                        <select
                            placeholder="Counter"
                            className="sm:mb-[5px] bg-white border w-[150px] border-gray-300 rounded-lg  p-[11px] text-[17px] outline-none"
                            name="counter"
                            value={filters.counter} 
                            onChange={(e) => handleCounterFilter(e.target.value)}

                        >
                            <option value="all">All Counter</option>
                            {
                                counterdropdown?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.counter_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Active Status</p>
                        <select
                            placeholder="status"
                            className="max-[640px]:mt-[15px] bg-white border border-gray-300 rounded-lg w-[150px]  p-[11px] text-[17px] outline-none"
                            // onChange={(e) => handleStatusFilter(e.target.value)}
                            name='active_status'
                            value={filters.active_status} 
                            onChange={(e) => handleStatusFilter(e.target.value)}
                        >
                            <option value={'all'}>All Status</option>
                            <option value={true}>Active</option>
                            <option value={false}>InActive</option>
                        </select>
                    </div>






                </div>

                {/* <div className='min-[400px]:flex  gap-5 w-full 2xl:w-[25%] min-[400px]:justify-between border items-center py-[10px] px-[10px] rounded-xl'>
                    <div className=''>
                        <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateFromFilter} value={fromDate} />
                    </div>
                    <p className='text-center'>to</p>
                    <div>
                        <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateToFilter} value={toDate} />
                    </div>
                    <div onClick={() => { setFromDate(null); setToDate(null); handleDateClearFilter();  }} className='cursor-pointer'>
                        <svg className='min-[400px]:block hidden' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z" /></svg>
                        <p className='min-[400px]:hidden text-center block'>clear</p>
                    </div>
                </div> */}
            </div>
        </>
    );
            }