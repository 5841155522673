import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAxios, postAxiosWithToken, putAxiosWithToken } from '../../../service/apiservice';
import { branchListEndPoint, branchTransferEndPoint, staffListByBranchEndPoint, transferTableListEndPoint, transferTagDetailsEnsPoint, userListEndPoint, userRoleListEndPoint } from '../../../service/api';
import { isOptionalValidForm, isValidForm } from '../../../utilities/formvalidation';
import toast from 'react-hot-toast';
import { useKeyPress } from '../../../hooks/useKeyPress';

function StockTranferForm() {
    const navigate = useNavigate();

    const today = new Date().toLocaleDateString('en-CA')

    const [formMode, setFormMode] = useState('create');
    const [errors, setErrors] = useState({});

    const [branchDropdown, setBranchDropdown] = useState([]);
    const [userDropdown, setUserDropdown] = useState([]);

    const [tagNumber, setTagNumber] = useState('');
    const [tagNumberError, setTagNumberError] = useState(false);

    const [particulars, setParticulars] = useState([]);

    const [formValues, setFormValues] = useState({
        transfer_date: today,
        required_date: today,
        stock_authority: '',
        transfer_from: '',
        transfer_to: ''
    });

    useEffect(() => {
        branchDropDownData();

        if (localStorage.getItem('btrnasfer_form_mode') == 'update') {
            setFormMode('update');
            getTranferDetails();
        }
    }, []);

    const onKeyPress = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            getTagDetails();
        }
    };

    useKeyPress(['Enter'], onKeyPress);

    const branchDropDownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropdown(response.data.list)
        }
    }

    const userDropDownData = async (id) => {
        var response = await getAxios({
            url: staffListByBranchEndPoint + id + "/"
        })

        if (response != null) {
            setUserDropdown(response.data.list)
        }
    }

    const getTranferDetails = async () => {
        var response = await getAxios({
            url: transferTableListEndPoint + localStorage.getItem('btransfer_form_id') + '/'
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                transfer_date: response.data.transfer_item.transfer_date,
                required_date: response.data.transfer_item.required_date,
                stock_authority: response.data.transfer_item.stock_authority,
                transfer_from: response.data.transfer_item.transfer_from,
                transfer_to: response.data.transfer_item.transfer_to,
            }))
            userDropDownData(response.data.transfer_item.transfer_from)
            let tempList = []
            for (var i of response.data.tagged_item) {
                let tempData = {
                    tag_number: i.tag_number,
                    item_name: i.item_details__item_details__item_name,
                    sub_item_name: i.sub_item_details__sub_item_name,
                    gross_weight: i.gross_weight,
                    net_weight: i.net_weight
                }
    
                tempList.push(tempData)
            }
            setParticulars(tempList)
        }
    }

    const getTagDetails = async () => {
        var err = {}

        for (var field of Object.entries(formValues)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err);

        if (isValidForm(formValues)) {
            var response = await getAxios({
                url: !JSON.parse(localStorage.getItem('branch')) ? `${transferTagDetailsEnsPoint}${tagNumber}/1/` : `${transferTagDetailsEnsPoint}${tagNumber}/${formValues.transfer_from}/`
            })
    
            if (response != null) {

                if (particulars.length > 0) {
                    var validate = particulars.filter((item) => item.tag_number == tagNumber)                    
                    
                    if (validate.length == 0) {
                        let tempData = {
                            tag_number: tagNumber,
                            item_name: response.data.list.item_details__item_details__item_name,
                            sub_item_name: response.data.list.sub_item_details__sub_item_name,
                            gross_weight: response.data.list.gross_weight,
                            net_weight: response.data.list.net_weight
                        }
                        
                        setParticulars([tempData, ...particulars])
                        setTagNumber("")
                    } else {
                        toast.error('Tag details already added!')
                    }
                } else {
                    let tempData = {
                        tag_number: tagNumber,
                        item_name: response.data.list.item_details__item_details__item_name,
                        sub_item_name: response.data.list.sub_item_details__sub_item_name,
                        gross_weight: response.data.list.gross_weight,
                        net_weight: response.data.list.net_weight
                    }
                    
                    setParticulars([tempData, ...particulars])
                    setTagNumber("")
                }
            }
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err);
       
        if (isOptionalValidForm(formValues, JSON.parse(localStorage.getItem('branch')) ? [] : ['transfer_from'])) {
            if (particulars.length > 0) {
                let data = formValues;
                data['tag_item_details'] = []

                for (var i of particulars) {

                    data.tag_item_details.push({
                        tag_number: i.tag_number
                    })
                }
                if (formMode === 'create') {
                    createTransfer(data)
                } else {
                    updateTransfer(data)
                }
            } else {
                toast.error('No items addded for trasfer')
            }
        }
    }

    const createTransfer = async (body) => {
        var response = await postAxiosWithToken({
            url: branchTransferEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updateTransfer = async (body) => {
        var response = await putAxiosWithToken({
            url: branchTransferEndPoint + localStorage.getItem('btransfer_form_id') + '/',
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const onValueUpdate = (e) => {
        const  { name, value, type } = e.target;

        if (name == 'transfer_from') {
            userDropDownData(value)
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
        
        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} is required`
        }))
    }

    const removeItem = (index) => {
        let data = [...particulars];
        data.splice(index, 1);
        setParticulars(data);
    }

    return (
        <div className='mb-[120px]'>
            <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Item Transfer</p>

            <form onSubmit={handleSubmit}>
                <div className='bg-menu-head-bg w-full border p-[15px] rounded-xl shadow-lg'>
                    <div>
                        <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">New Item Transfer</p>
                        <div className='grid grid-cols-5 gap-[25px]'>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Transfer Date <span className="text-red-500">*</span></p>
                                <input
                                    type="date"
                                    placeholder="Transfer Date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="transfer_date"
                                    value={formValues.transfer_date}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transfer_date}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Required Date <span className="text-red-500">*</span></p>
                                <input
                                    type="date"
                                    placeholder="Required Date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="required_date"
                                    value={formValues.required_date}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.required_date}</p>
                            </div>                            
                            { JSON.parse(localStorage.getItem('branch')) && <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Transfer From <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Transfer From"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="transfer_from"
                                    value={formValues.transfer_from}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select transfer from--</option>
                                    {
                                        branchDropdown.map((item, index) => (
                                            formValues.transfer_to != item.id && <option key={'branch_from' + index} value={item.id}>{item.branch_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transfer_from}</p>
                            </div> }
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Transfer To <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Transfer To"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="transfer_to"
                                    value={formValues.transfer_to}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select transfer to--</option>
                                    {
                                        branchDropdown.map((item, index) => (
                                            formValues.transfer_from != item.id &&  <option key={'branch_to' + index} value={item.id}>{item.branch_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transfer_to}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Stock Authority <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Stock Authority"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="stock_authority"
                                    value={formValues.stock_authority}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select stock authority--</option>
                                    {
                                        userDropdown.map((item, index) => (
                                            <option key={'role' + index} value={item.id}>{item.email}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.stock_authority}</p>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <p className='font-[500] text-[18px] mt-[34px] mb-[14px]'>Item Details</p>
                        <div className='grid grid-cols-3'>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Scan Barcode</p>
                                <div className='sm:mb-[5px]  flex gap-3 items-center border border-gray-300 rounded-lg p-[10px]'>
                                    <input
                                        type="text"
                                        placeholder="Tag Number"
                                        className=" w-full text-[17px] outline-none"
                                        name="barcode"
                                        value={tagNumber}
                                        onChange={(e) => setTagNumber(e.target.value)}
                                    />
                                    <div onClick={() => {
                                        if (tagNumber.length >= 7) {
                                            getTagDetails();
                                            setTagNumberError(false)
                                        } else {
                                            setTagNumberError(true)
                                        }
                                    }} className='w-[24px] h-[24px]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16"><path fill="currentColor" d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0z" /></svg>
                                    </div>
                                </div>
                                { tagNumberError && <p className="text-red-500 font-[400] text-[13px]">Tag number is less than 7 should not be applicable</p> }
                            </div>
                        </div>

                        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                            <table className="w-full">
                                <thead className="border bg-table-head">
                                    <tr>
                                        <td className="p-[10px] text-table-head-font text-[16px]">S.No</td>
                                        <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Tag No</td>
                                        <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Item Name</td>
                                        <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Sub Item Name</td>
                                        <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Gross Weight</td>
                                        <td className="p-[10px] text-table-head-font text-[16px] min-w-[150px]">Net Weight</td>
                                        <td className="p-[10px] text-table-head-font text-[16px] min-w-[50px] text-center">Actions</td>
                                    </tr>
                                </thead>
                                <tbody className="text-primary-grey bg-[#fff]">
                                    {
                                        particulars.map((item, index) => (
                                            <tr key={item.id} className="">
                                                <td className="p-[10px] border">{index + 1}</td>
                                                <td className="p-[10px] border capitalize">{item.tag_number}</td>
                                                <td className="p-[10px] border capitalize">{item.item_name}</td>
                                                <td className="p-[10px] border capitalize">{item.sub_item_name}</td>
                                                <td className="p-[10px] border capitalize">{item.gross_weight}</td>
                                                <td className="p-[10px] border capitalize">{item.net_weight}</td>
                                                <td className='p-[10px] text-center'>
                                                    <div onClick={() => removeItem(index)} className='w-[24px] h-[24px]'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 36 36"><path fill="currentColor" d="m19.41 18l7.29-7.29a1 1 0 0 0-1.41-1.41L18 16.59l-7.29-7.3A1 1 0 0 0 9.3 10.7l7.29 7.3l-7.3 7.29a1 1 0 1 0 1.41 1.41l7.3-7.29l7.29 7.29a1 1 0 0 0 1.41-1.41Z" class="clr-i-outline clr-i-outline-path-1" /><path fill="none" d="M0 0h36v36H0z" /></svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="bg-menu-head-bg fixed bottom-[30px] left-0 w-full p-[15px] flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                        className="capitalize bg-menu-head-bg border text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >close<span className="lowercase">(esc)</span></button>
                    <button
                        type="submit"
                        className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >save<span className="lowercase">(ctrl+s)</span></button>
                </div>

            </form>

        </div>
    )
}

export default StockTranferForm;