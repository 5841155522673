import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartComponent = () => {
  const options = {
    series: [20, 80],
    chart: {
        height: 350,
      type: 'pie',
    },
    labels: ['Vendor_1', 'Vendor_2'],
    responsive: [{
      breakpoint: 320,
      options: {
        chart: {
            height: 350
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={options.series} type="pie" height={300}/>
    </div>
  );
};

export default ChartComponent;
