import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { centgroupEndPoint, createcentgroupEndPoint } from "../../../../service/api";
import { postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../../hooks/useKeyPress";

export default function CentgroupForm({ tab, getData, formMode, currentCentgroup, setFormMode, setCurrentCentgroup }) {
    const schema = yup
        .object({
            centgroup_name: yup.string().required(),
            from_weight: yup.string().required(),
            to_weight: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {

            centgroup_name:currentCentgroup.centgroup_name,
            from_weight:currentCentgroup.from_weight,
            to_weight:currentCentgroup.to_weight


        } : {
            centgroup_name: "",
            from_weight: "",
            to_weight: ""
        },
        mode: "onChange"
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's' && tab === 5) {
            event.preventDefault();
            document.getElementById('centgroupformsubmit').click()
            document.getElementById('centgroupforminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createCentgroup(data)
                console.log(data)
            } else {
                updateCentgroup(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createCentgroup = async (body) => {
        var response = await postAxiosWithToken({
            url: createcentgroupEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }



    const updateCentgroup = async (body) => {
        var response = await putAxiosWithToken({
            url: createcentgroupEndPoint + currentCentgroup.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentCentgroup({
            centgroup_name: "",
            from_weight: "",
            to_weight:""
        })
    }

    return (
        <>
           <div className="p-5 rounded-[10px] bg-menu-head-bg border shadow mt-5">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Centgroup</p>
                <form className="grid 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-[20px] mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Centgroup Name</p>
                        <input
                            type="text"
                            step={0.01}
                            placeholder="centgroup name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="centgroup_name"
                            id="centgroupforminit"
                            {...register('centgroup_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.centgroup_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">From Weight</p>
                        <input
                            type="number"
min="0"
                            step={0.01}
                            placeholder="From Weight"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="from_weight"
                            {...register('from_weight')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.from_weight?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">To Weight</p>
                        <input
                            type="number"
min="0"
                            step={0.01}
                            placeholder="To Weight"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="to_weight"
                            {...register('to_weight')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.to_weight?.message}</p>
                    </div>
                   
                    <div className="min-[423px]:flex gap-4 sm:mt-9 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="centgroupformsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}