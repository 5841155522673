import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { colorEndPoint } from "../../../../service/api";
import { postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../../hooks/useKeyPress";

export default function ColorForm({ tab, getData, formMode, currentColor, setFormMode, setCurrentColor }) {

    const schema = yup
        .object({
            color_name: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            color_name: currentColor.color_name
        } : {
            color_name: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's' && tab === 4) {
            event.preventDefault();
            document.getElementById('colorformsubmit').click()
            document.getElementById('colorforminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createColor(data)
            } else {
                updateColor(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createColor = async (body) => {
        var response = await postAxiosWithToken({
            url: colorEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateColor = async (body) => {
        var response = await putAxiosWithToken({
            url: colorEndPoint + currentColor?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentColor({
            color_name: ""
        })
    }

    return (
        <>
            <div className="p-5 rounded-[10px] bg-menu-head-bg border shadow mt-5">
                <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Color</p>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Color Name</p>
                        <input
                            type="text"
                            placeholder="Color name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="color_name"
                            id="colorforminit"
                            {...register('color_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.color_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-9 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="colorformsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-black rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}