import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { companyBankMultiDeleteEndPoint, companyEndPoint, gstVerifyEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { defaultCountry, defaultState, phoneRegExp } from "../../../constant";
import { toast } from "react-hot-toast";
import CompanyBankForm from "./companybankform";
import { isValidForm } from "../../../utilities/formvalidation";

export default function CompanyForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentCompany, setCurrentCompany] = useState({
        company_name: "",
        email_id: "",
        mobile_no: "",
        website: "",
        std_code: "",
        landline_no: "",
        door_no: "",
        street_name: "",
        area: "",
        taluk: "",
        postal: "",
        district: "",
        country: "",
        state: "",
        pincode: "",
        pan_no: "",
        gst_no: "",
        registered_name: "",
        gst_status: "",
        tax_payer_type: "",
        bussiness_type: "",
        country: defaultCountry,
        state: defaultState
    });
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const [bankDetailsInit, setBankDetailsInit] = useState([]);
    const [removedBankDetails, setRemovedBankDetails] = useState([]);

    const [currentCompanyErrors, setCurrentCompanyErrors] = useState({});

    useEffect(() => {
        getStateDropdown(defaultCountry)
    },[defaultCountry])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)

        setFormMode(localStorage.getItem('company_form_mode'))

        if (localStorage.getItem('company_form_mode') === 'update') {
            getCompanyDetails()
        }
    }, [])

    const getCompanyDetails = async () => {
        var response = await getAxios({
            url: companyEndPoint + localStorage.getItem('company_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                company_name: response.data?.company_details?.company_name,
                email_id: response.data?.company_details?.email_id,
                mobile_no: response.data?.company_details?.mobile_no,
                website: response.data?.company_details?.website,
                std_code: response.data?.company_details?.std_code,
                landline_no: response.data?.company_details?.landline_no,
                door_no: response.data?.address_details?.door_no,
                street_name: response.data?.address_details?.street_name,
                area: response.data?.address_details?.area,
                taluk: response.data?.address_details?.taluk,
                postal: response.data?.address_details?.postal,
                district: response.data?.address_details?.district,
                country: response.data?.address_details?.country,
                state: response.data?.address_details?.state,
                pincode: response.data?.address_details?.pincode,
                pan_no: response.data?.gst_details?.pan_no,
                gst_no: response.data?.gst_details?.gst_no,
                registered_name: response.data?.gst_details?.registered_name,
                gst_status: response.data?.gst_details?.gst_status,
                tax_payer_type: response.data?.gst_details?.tax_payer_type,
                bussiness_type: response.data?.gst_details?.bussiness_type
            }

            setSelectedCountry(JSON.parse(response.data?.address_details?.country))

            getStateDropdown(response.data?.address_details?.country)
            setSelectedState(JSON.parse(response.data?.address_details?.state))

            setBankDetailsInit(response.data?.bank_details)

            setCurrentCompany(tempDetails)
        }
    }

    const getStateDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getGstDetails = async (gstId) => {
        var response = await getAxios({
            url: gstVerifyEndPoint + gstId + "/",
        })

        if (response != null) {
            setCurrentCompany((state) => ({
                ...state,
                ['registered_name']: response.data.registered_name,
                ['gst_status']: response.data.gst_status,
                ['tax_payer_type']: response.data.tax_payer_type,
                ['bussiness_type']: response.data.bussiness_type
            }))
            setCurrentCompanyErrors((state) => ({
                ...state,
                ['registered_name']: "",
                ['gst_status']: "",
                ['tax_payer_type']: "",
                ['bussiness_type']: ""
            }));
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setCurrentCompanyErrors(err)

        if (isValidForm(formDataObject)) {

            if (localStorage.getItem('company_form_mode') === 'create') {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })

                let tempCreateBody = {}
                tempCreateBody['company_details'] = {
                    company_name: formDataObject.company_name,
                    email_id: formDataObject.email_id,
                    mobile_no: formDataObject.mobile_no,
                    website: formDataObject.website,
                    std_code: formDataObject.std_code,
                    landline_no: formDataObject.landline_no
                }
                tempCreateBody['address_details'] = {
                    door_no: formDataObject.door_no,
                    street_name: formDataObject.street_name,
                    area: formDataObject.area,
                    taluk: formDataObject.taluk,
                    postal: formDataObject.postal,
                    district: formDataObject.district,
                    country: formDataObject.country,
                    state: formDataObject.state,
                    pincode: formDataObject.pincode
                }
                tempCreateBody['gst_details'] = {
                    pan_no: formDataObject.pan_no,
                    gst_no: formDataObject.gst_no,
                    registered_name: formDataObject.registered_name,
                    gst_status: formDataObject.gst_status,
                    tax_payer_type: formDataObject.tax_payer_type,
                    bussiness_type: formDataObject.bussiness_type
                }
                tempCreateBody['bank_details'] = bankDetailsInit

                createCompany(tempCreateBody)

            } else {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })

                let tempUpdateBody = {}
                tempUpdateBody['company_details'] = {
                    company_name: formDataObject.company_name,
                    email_id: formDataObject.email_id,
                    mobile_no: formDataObject.mobile_no,
                    website: formDataObject.website,
                    std_code: formDataObject.std_code,
                    landline_no: formDataObject.landline_no
                }
                tempUpdateBody['address_details'] = {
                    door_no: formDataObject.door_no,
                    street_name: formDataObject.street_name,
                    area: formDataObject.area,
                    taluk: formDataObject.taluk,
                    postal: formDataObject.postal,
                    district: formDataObject.district,
                    country: formDataObject.country,
                    state: formDataObject.state,
                    pincode: formDataObject.pincode
                }
                tempUpdateBody['gst_details'] = {
                    pan_no: formDataObject.pan_no,
                    gst_no: formDataObject.gst_no,
                    registered_name: formDataObject.registered_name,
                    gst_status: formDataObject.gst_status,
                    tax_payer_type: formDataObject.tax_payer_type,
                    bussiness_type: formDataObject.bussiness_type
                }
                tempUpdateBody['bank_details'] = []

                for (var i of bankDetailsInit) {
                    var tempDict = {
                        acc_holder_name: i.acc_holder_name,
                        account_no: i.account_no,
                        ifsc_code: i.ifsc_code,
                        bank_name: i.bank_name,
                        branch_name: i.branch_name,
                        micr_code: i.micr_code
                    }
                    tempUpdateBody['bank_details'].push(tempDict)
                }

                if (removedBankDetails.length > 0) {
                    let deleteBody = {
                        id_list: removedBankDetails
                    }
                    deleteCompanyBankDetails(deleteBody)
                }

                updateCompany(tempUpdateBody)
            }
        }
    }

    const createCompany = async (body) => {
        var response = await postAxiosWithToken({
            url: companyEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/books/company')
        }
    }

    const updateCompany = async (body) => {
        var response = await putAxiosWithToken({
            url: companyEndPoint + localStorage.getItem('company_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('company_form_id')
            localStorage.setItem('company_form_mode', 'create')
            toast.success(response.message)
            navigate('/books/company')
        }
    }

    const deleteCompanyBankDetails = async (body) => {
        var response = await postAxiosWithToken({
            url: companyBankMultiDeleteEndPoint,
            body: body
        })
    }

    const clearData = () => {
        setCurrentCompany({
            company_name: "",
            email_id: "",
            mobile_no: "",
            website: "",
            std_code: "",
            landline_no: "",
            door_no: "",
            street_name: "",
            area: "",
            taluk: "",
            postal: "",
            district: "",
            country: "",
            state: "",
            pincode: "",
            pan_no: "",
            gst_no: "",
            registered_name: "",
            gst_status: "",
            tax_payer_type: "",
            bussiness_type: ""
        })
        setBankDetailsInit([]);
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target

        if (name === 'country') {
            getStateDropdown(value)
            setCurrentCompany((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setCurrentCompany((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        setCurrentCompanyErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Company{removedBankDetails.join(',')}</p>
                <form onSubmit={onSubmit}>

                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Company Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Company Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="company name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="company_name"
                                    value={currentCompany.company_name}
                                    onChange={onValueUpdate}
                                    id="forminit"
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.company_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Email <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email_id"
                                    value={currentCompany.email_id}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.email_id}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Phone no <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobile_no"
                                    value={currentCompany.mobile_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.mobile_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Website <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="website link"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="website"
                                    value={currentCompany.website}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.website}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Landline <span className="text-red-500">*</span></p>
                                <div className="flex gap-1 w-full">
                                    <div className="w-[50%]">
                                        <input
                                            type="text"
                                            placeholder="std code"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="std_code"
                                            value={currentCompany.std_code}
                                            onChange={onValueUpdate}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.std_code}</p>
                                    </div>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            placeholder="landline no"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="landline_no"
                                            value={currentCompany.landline_no}
                                            onChange={onValueUpdate}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.landline_no}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Door No <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="door no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="door_no"
                                    value={currentCompany.door_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.door_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Street Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="street name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="street_name"
                                    value={currentCompany.street_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.street_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Area <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="area"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="area"
                                    value={currentCompany.area}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.area}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Postal <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="postal"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="postal"
                                    value={currentCompany.postal}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.postal}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Taluk <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="taluk"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="taluk"
                                    value={currentCompany.taluk}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.taluk}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">District <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="district"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="district"
                                    value={currentCompany.district}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.district}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Country <span className="text-red-500">*</span> </p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    value={currentCompany.country}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">State <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    value={currentCompany.state}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.state}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pincode <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentCompany.pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">GST Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pan Number</p>
                                <input
                                    type="text"
                                    placeholder="pan no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pan_no"
                                    value={currentCompany.pan_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.pan_no}</p>
                            </div>


                            <div class=" items-center my-[5px] py-2 mt-[-5px]">
                                <p className="mb-[5px] text-[16px]">GST NO</p>
                                <div className="bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] flex justify-between items-center">
                                    <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                        type="text"
                                        placeholder="Jane Doe"
                                        name="gst_no"
                                        id="gst_no"
                                        value={currentCompany.gst_no}
                                        onChange={onValueUpdate} />
                                    <button class="flex-shrink-0 bg-blue-700    text-sm  text-white py-1 px-2 rounded" type="button" onClick={() => getGstDetails(document.getElementById('gst_no')?.value)}>
                                        verify
                                    </button>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.gst_no}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">GST Status</p>
                                <input
                                    type="text"
                                    placeholder="gst status"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_status"
                                    id="gst_status"
                                    value={currentCompany.gst_status}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.gst_status}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">GST Type</p>
                                <input
                                    type="text"
                                    placeholder="gst type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tax_payer_type"
                                    id="tax_payer_type"
                                    value={currentCompany.tax_payer_type}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.tax_payer_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Bussiness Type</p>
                                <input
                                    type="text"
                                    placeholder="bussiness type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="bussiness_type"
                                    id="bussiness_type"
                                    value={currentCompany.bussiness_type}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.bussiness_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Registered Name</p>
                                <input
                                    type="text"
                                    placeholder="registered name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="registered_name"
                                    id="registered_name"
                                    value={currentCompany.registered_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCompanyErrors.registered_name}</p>
                            </div>
                        </div>
                    </div>

                    <CompanyBankForm formMode={formMode} removedItems={removedBankDetails} setRemovedItems={setRemovedBankDetails} dataList={bankDetailsInit} setDataList={setBankDetailsInit} />

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 fixed bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}