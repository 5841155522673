import React, { useEffect, useState } from 'react'
import { branchListEndPoint } from '../../service/api';
import { getAxios } from '../../service/apiservice';
import DatePicker1 from '../../components/Datepicker';

export default function Dashboardfilter({ setFilters, filters }) {

    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
        getBranchDropdownData();
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint,
        });

        if (response !== null) {
            setBranchList(response.data.list);
        }
    };

    const handleDateFilter = (value) => {
        console.log(value)
        setFilters((prevState) => ({
            ...prevState,
            from_date: value.startDate,
            to_date: value.endDate
        }))
    }


    const handleBranchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            branch: value === "all" ? null : value
        }))
    }
    return (
        <>
            <div className='flex gap-2 max-[768px]:mt-5'>
                {localStorage.getItem("branch") === "true" &&
                    <div className=''>
                        <select
                            placeholder="metal_type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                            name="branch"
                            value={filters.branch}
                            onChange={(e) => handleBranchFilter(e.target.value)}
                        >
                            <option value="all">All Branch</option>
                            {branchList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                    {option?.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>}

                <div className="border rounded-lg h-fit">
                    <DatePicker1
                        handleValueChange={handleDateFilter}
                        value={{
                            startDate: filters.from_date,
                            endDate: filters.to_date
                        }}
                    />
                </div>

            </div>
        </>
    )
}
