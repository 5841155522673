import { branchListEndPoint, customerEndPoint, customerGroupListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { isOptionalValidForm } from "../../../utilities/formvalidation";
import { Country, State } from "country-state-city";
import { defaultCountry, defaultState } from "../../../constant";

const optionalFields = ['upi_id', 'email', 'dob', 'address', 'gst_no', 'pan_card', 'aadhar_card']

export default function CustomerForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentCustomer, setCurrentCustomer] = useState({
        country: defaultCountry,
        state: defaultState,
        is_married: false
    });

    const [customerGroupDropDown, setCustomerGroupDropDown] = useState([]);
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);

    const [errors, setErrors] = useState({});

    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
        getStateDropdown(defaultCountry)
    }, [defaultCountry])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint,
        });

        if (response !== null) {
            setBranchList(response.data.list);
        }
    };


    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getCustomerGroupListData()
        setFormMode(localStorage.getItem('customer_form_mode'))

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)
        getBranchDropdownData();

        if (localStorage.getItem('customer_form_mode') === 'update') {
            getCustomerDetails()
        }
    }, [])

    const getCustomerGroupListData = async () => {
        var response = await getAxios({
            url: customerGroupListEndPoint
        })

        if (response !== null) {
            setCustomerGroupDropDown(response.data.list)
        }
    }

    const getStateDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getCustomerDetails = async () => {
        var response = await getAxios({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                customer_name: response.data?.customer_name,
                email: response.data?.email,
                phone: response.data?.phone,
                customer_group: response.data?.customer_group,
                dob: response.data?.dob,
                door_no: response.data?.door_no,
                street_name: response.data?.street_name,
                area: response.data?.area,
                taluk: response.data?.taluk,
                postal: response.data?.postal,
                district: response.data?.district,
                state: response.data?.state ? response.data?.state : defaultState,
                country: response.data?.country ? response.data?.country : defaultCountry,
                pincode: response.data?.pincode,
                aadhar_card: response.data?.aadhar_card,
                pan_card: response.data?.pan_card,
                gst_no: response.data?.gst_no,
                is_married: response.data?.is_married,
                marriage_date: response.data.marriage_date,
                branch: response.data.branch,
                upi_id: response.data.upi_id
            }
            if (response.data?.country) {
                getStateDropdown(response.data?.country)
            }

            setCurrentCustomer(tempDetails)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !optionalFields.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(formDataObject, optionalFields)) {
            if (localStorage.getItem('customer_form_mode') === 'create') {
                createCustomer(currentCustomer)
            } else {
                updateCustomer(currentCustomer)
            }
        }
    }

    const createCustomer = async (body) => {
        var response = await postAxiosWithToken({
            url: customerEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updateCustomer = async (body) => {
        var response = await putAxiosWithToken({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/",
            body: body
        })

        if (response != null) {
            localStorage.removeItem('customer_form_id')
            localStorage.setItem('customer_form_mode', 'create')
            toast.success(response.message)
            navigate(-1)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'country') {
            getStateDropdown(value)
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value
            }))
        } else if (name === 'is_married') {
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: checked
            }))
        } else {
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }


    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Customer</p>
                <form onSubmit={onSubmit}>

                    <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Customer Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Customer Name</p>
                                <input
                                    type="text"
                                    placeholder="customer name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="customer_name"
                                    value={currentCustomer.customer_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.customer_name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Customer Group</p>
                                <select
                                    placeholder="customer group"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="customer_group"
                                    value={currentCustomer.customer_group}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select customer group--</option>
                                    {
                                        customerGroupDropDown.map((option) => (
                                            <option className="capitalize" key={"customer_group" + option.customer_group_name} value={option.id}>{option.customer_group_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.customer_group}</p>
                            </div>
                            <div className="my-[5px]">

                                <p className="mb-[5px] text-[16px]">Email</p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email"
                                    value={currentCustomer.email}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Phone no</p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="phone"
                                    value={currentCustomer.phone}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.phone}</p>
                            </div>
                            {localStorage.getItem("branch") === "true" && (
                                <div className="my-[5px]">
                                    <>
                                        <p className="mb-[5px] text-[15px]">Branch</p>
                                        <div className=''>
                                            <select
                                                placeholder="branch"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                                name="branch"
                                                value={currentCustomer.branch}
                                                // onChange={(e) => handleBranchFilter(e.target.value)}
                                                onChange={onValueUpdate}
                                            >
                                                <option value="all">-- Select Branch Type --</option>
                                                {branchList.map((option) => (
                                                    <option key={option?.id} value={option.id}>
                                                        {option.branch_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>

                                    <p className="text-red-500 font-[400] text-[13px]">{errors.branch}</p>
                                </div>
                            )}
                            <div className="my-[5px]">

                                <p className="mb-[5px] text-[16px]">DOB</p>
                                <input
                                    type="date"
                                    placeholder="dob"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[8px] text-[17px] outline-none bg-transparent"
                                    name="dob"
                                    value={currentCustomer.dob}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">

                                <p className="mb-[5px] text-[16px]">Married</p>
                                <input
                                    type="checkbox"
                                    placeholder="is_married"
                                    name="is_married"
                                    checked={currentCustomer.is_married}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            {currentCustomer.is_married && <div className="my-[5px]">

                                <p className="mb-[5px] text-[16px]">Marriage Date</p>
                                <input
                                    type="date"
                                    placeholder="marriage_date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[8px] text-[17px] outline-none"
                                    name="marriage_date"
                                    value={currentCustomer.marriage_date}
                                    onChange={onValueUpdate}
                                />
                            </div>}
                            <div className="my-[5px]">

                                <p className="mb-[5px] text-[16px]">UPI ID</p>
                                <input
                                    type="text"
                                    placeholder="UPI ID"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[8px] text-[17px] outline-none bg-transparent"
                                    name="upi_id"
                                    value={currentCustomer.upi_id}
                                    onChange={onValueUpdate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Door No</p>
                                <input
                                    type="text"
                                    placeholder="door no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="door_no"
                                    value={currentCustomer.door_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.door_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Street Name</p>
                                <input
                                    type="text"
                                    placeholder="street name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="street_name"
                                    value={currentCustomer.street_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.street_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Area</p>
                                <input
                                    type="text"
                                    placeholder="area"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="area"
                                    value={currentCustomer.area}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.area}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Taluk</p>
                                <input
                                    type="text"
                                    placeholder="taluk"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="taluk"
                                    value={currentCustomer.taluk}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.taluk}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Postal</p>
                                <input
                                    type="text"
                                    placeholder="postal"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="postal"
                                    value={currentCustomer.postal}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.postal}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">District</p>
                                <input
                                    type="text"
                                    placeholder="district"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="district"
                                    value={currentCustomer.district}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.district}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country </p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    value={currentCustomer.country}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State</p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    value={currentCustomer.state}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.state}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode</p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentCustomer.pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.pincode}</p>
                            </div>
                        </div>
                    </div>


                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Other Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">GSTIN</p>
                                <input
                                    type="text"
                                    placeholder="gstin"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_no"
                                    value={currentCustomer.gst_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pan no</p>
                                <input
                                    type="text"
                                    placeholder="pan card no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pan_card"
                                    value={currentCustomer.pan_card}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Aadhar no</p>
                                <input
                                    type="text"
                                    placeholder="aadhar card no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="aadhar_card"
                                    value={currentCustomer.aadhar_card}
                                    onChange={onValueUpdate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}