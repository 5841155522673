import { Card } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { AreaChart, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts';
import Arrow from "../../assets/images/arrowsymbol.svg"
import { metalwisesalesEndPoint } from '../../service/api';
import { postAxiosWithToken } from "../../service/apiservice";



export default function Barchart( {tableFilters} ) {

    const [metalWisesales, setMetalWisesales] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const Branch = {branch : tableFilters.branch}

    useEffect(() => {
        getMetalwisesalesData(Branch)
    }, [tableFilters])
  
    const getMetalwisesalesData = async (body) => {
      var response = await postAxiosWithToken ({
        url: metalwisesalesEndPoint,
        body: body
      })
  
      if (response !== null) {
        setMetalWisesales(response.data.list)
        setIsLoading(false)
      }
    }

    const data = [
        {
            "Gold": 4000,
            "Silver": 2400,
        },
        {
            "Gold": 3000,
            "Silver": 1398,
            "amt": 2210
        },
        {
            "Gold": 2000,
            "Silver": 9800,
            "amt": 2290
        },
        {
            "Gold": 2780,
            "Silver": 3908,
            "amt": 2000
        },
        {
            "Gold": 1890,
            "Silver": 4800,
            "amt": 2181
        },
        {
            "Gold": 2390,
            "Silver": 3800,
            "amt": 2500
        },
        {
            "Gold": 3490,
            "Silver": 4300,
            "amt": 2100
        }
    ]


    return (
        <>
            <Card className='border'>

                <div className="flex justify-between p-5">
                    <div className="font-[700]">
                    Metal-Wise Sales
                    </div>
                    <div className="p-2 rounded-full border">
                        <img src={Arrow} alt="arrow" />
                    </div>
                </div>

                <ResponsiveContainer width="104%" height={250}>
                    <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F1DD80" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#F1DD80" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Area type="monotone" dataKey="Gold" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                        <Area type="monotone" dataKey="Silver" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                    </AreaChart>
                </ResponsiveContainer>


                <div className='flex'>
                    <div class="bg-[#FFFFFF] p-3 m-2 rounded-[10px] w-[100%] flex justify-between rounded  border border-[#E2E8F0] items-center  ">
                        <div>
                            <h3 class="font-[500] text-black text-[16px]">Gold Weight</h3>

                            <div className='flex gap-2'>
                                <span className="inline-block w-[20px] h-[5px] bg-[#F1DD80] mt-3 rounded"></span><p class="text-black"> {(metalWisesales.gold_weight)?.toFixed(2)}</p>
                            </div>

                        </div>

                    </div>
                    <div class="bg-[#FFFFFF] p-3 m-2 rounded-[10px] w-[100%] flex justify-between rounded  border border-[#E2E8F0] items-center  ">
                        <div>
                            <h3 class="font-[500] text-black text-[16px]">Silver Weight</h3>
                            <div className='flex gap-2'>
                                <span className="inline-block w-[20px] h-[5px] bg-[#61A375] mt-3 rounded"></span><p class="text-black"> {(metalWisesales.silver_weight)?.toFixed(2)}</p>
                            </div>
                        </div>

                    </div>
                </div>


            </Card>
        </>
    );
}