import { useEffect, useState } from "react"
import { logoutEndPoint } from "../service/api"
import { getAxios } from "../service/apiservice"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { sessionLogoutTime } from "../constant"

export const useRefreshToken = () => {

    const navigate = useNavigate();

    const logout = async () => {
        var response = await getAxios({
            url: logoutEndPoint
        })

        if (response !== null) {
            if (response.status === 200) {
                toast.success(response.message)
                localStorage.removeItem('login')
                localStorage.setItem('authenticated', JSON.parse(false))
                navigate('/')
                window.location.reload()
            } else {
                toast.error(response.message)
            }
        }
    }
    
    const checkInactivity = () => {
        const exprieTokenTime = localStorage.getItem('expire_time')
        if (exprieTokenTime < Date.now()) {
            logout()
        }
    }

    const updateExpireTokenTime = () => {
        const exprieTokenTime = Date.now() + sessionLogoutTime;

        localStorage.setItem('expire_time', exprieTokenTime)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkInactivity();
        },sessionLogoutTime);

        return () => clearInterval(interval);
    },[])

    useEffect(() => {
        updateExpireTokenTime();

        window.addEventListener("click", updateExpireTokenTime);
        window.addEventListener("keypress", updateExpireTokenTime);
        window.addEventListener("scroll", updateExpireTokenTime);
        window.addEventListener("mousemove", updateExpireTokenTime);

        return () => {
            window.removeEventListener("click", updateExpireTokenTime);
            window.removeEventListener("keypress", updateExpireTokenTime);
            window.removeEventListener("scroll", updateExpireTokenTime);
            window.removeEventListener("mousemove", updateExpireTokenTime);
        }
    },[])
}