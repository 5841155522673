import { useEffect, useState } from "react";
import { rateTypeListEndPoint, diamondListEndPoint, weightTypeListEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { isValidForm } from "../../../utilities/formvalidation";

export default function DiamondForm({ dataList = [], setDataList, formType, closeModal }) {



    return (
        <div className="w-full mt-[15px]">

            <div className="w-full overflow-scroll">
                <table className="w-full">
                     <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="p-[10px] text-center">S.No</td>
                            <td className="p-[10px] text-center">Diamond Name</td>
                            <td className="p-[10px] text-center">Diamond Pieces</td>
                            <td className="p-[10px] text-center">Diamond Weight Type</td>
                            <td className="p-[10px] text-center">Diamond Weight</td>
                            <td className="p-[10px] text-center">Diamond Rate Type</td>
                            <td className="p-[10px] text-center">Diamond Rate</td>
                        </tr>
                    </thead>

                    <tbody className="text-primary-grey">
                        {
                            dataList.map((item, index) => (
                                <tr key={'item' + index}>
                                    <td className="border py-[7px] text-center">{index + 1}</td>
                                    <td className="border py-[7px] text-center">
                                        <p className="text-primary-btn font-[400] text-[15px]">{item.diamond_name || ''}</p>
                                    </td>
                                    <td className="border py-[7px] text-center">
                                        <p className="text-primary-btn font-[400] text-[15px]">{item.diamond_pieces || ''}</p>
                                    </td>
                                    <td className="border py-[7px] text-center">
                                        <p className="text-primary-btn font-[400] text-[15px]">{item.diamond_weight_type_name || ''}</p>
                                    </td>
                                    <td className="border py-[7px] text-center">
                                        <p className="text-primary-btn font-[400] text-[15px]">{item.diamond_weight || ''}</p>
                                    </td>
                                    <td className="border py-[7px] text-center">
                                        <p className="text-primary-btn font-[400] text-[15px]">{item.diamond_rate_type_name || ''}</p>
                                    </td>
                                    <td className="border py-[7px] text-center">
                                        <p className="text-primary-btn font-[400] text-[15px]">{item.diamond_rate || ''}</p>
                                    </td>


                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="mt-[10px] flex gap-3 justify-end items-center">
                <button onClick={() => {

                    closeModal()
                }} className="border outline-none px-[10px] py-[5px] w-fit rounded-lg border-secondary hover:bg-secondary hover:text-white">Close</button>
                {/* <button className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Save</button> */}
            </div>
        </div>
    );
}