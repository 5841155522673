import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { createrangestockEndPoint, itemMasterListEndPoint } from "../../../service/api";
import { useEffect, useState } from "react";


export default function RangestockForm({ getData, formMode, currentrangestock, setFormMode, setCurrentRangestock }) {
    const [itemList, setItemList] = useState([]);

    const schema = yup
        .object({
            range_value: yup.string().required(),
            from_weight: yup.string().required(),
            to_weight: yup.string().required(),
            item_details: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {

            range_value: currentrangestock?.range_value,
            from_weight: currentrangestock?.from_weight,
            to_weight: currentrangestock?.to_weight,
            item_details: currentrangestock?.item_details

        } : {
            range_value: "",
            from_weight: "",
            to_weight: "",
            item_details: ""
        },
        mode: "onChange"
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('rangegroupformsubmit').click()
            document.getElementById('rangegroupforminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    useEffect(() => {
        getItemListData()
    }, [])

    const getItemListData = async () => {
        var respose = await getAxios({
            url: itemMasterListEndPoint
        })

        if (respose !== null) {
            setItemList(respose.data.list)
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createRangestock(data)
                console.log(data)
            } else {
                updateRangestock(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createRangestock = async (body) => {
        var response = await postAxiosWithToken({
            url: createrangestockEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }



    const updateRangestock = async (body) => {
        var response = await putAxiosWithToken({
            url: createrangestockEndPoint + currentrangestock.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentRangestock({
            range_value: "",
            from_weight: "",
            to_weight: ""
        })
    }

    return (
        <>
            <div>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px]">
                        <p className="mb-[5px] text-[15px]">Item</p>
                        <select
                            placeholder="item_details"
                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="item_details"
                            {...register('item_details')}
                        >
                            <option value={""}>--select item--</option>
                            {
                                itemList.map((option) => (
                                    <option key={option.id} value={option.id}>{option.item_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.item_details?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Range Value</p>
                        <input
                            type="text"
                            step={0.01}
                            placeholder="Range Value"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="range_value"
                            id="rangegroupforminit"
                            {...register('range_value')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.range_value?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">From Weight</p>
                        <input
                            type="number"
                            min="0"
                            step={0.01}
                            placeholder="From Weight"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="from_weight"
                            {...register('from_weight')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.from_weight?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">To Weight</p>
                        <input
                            type="number"
                            min="0"
                            step={0.01}
                            placeholder="To Weight"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="to_weight"
                            {...register('to_weight')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.to_weight?.message}</p>
                    </div>

                    <div className="min-[423px]:flex gap-4 sm:mt-9 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="rangegroupformsubmit"
                            className="capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-black rounded-lg p-[10px] h-fit w-full font-[500] text-[15px] outline-none border-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}