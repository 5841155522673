import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
} from "@material-tailwind/react";
import { orderIssuedStatusType } from "../../../../constant";
import * as htmlToImage from 'html-to-image';
import ReactToPrint, { useReactToPrint } from "react-to-print";

export default function ViewActionButtons({ componentRef }) {

    const downloadImage = () => {
        var node = document.getElementById('download');
        node.style.display = 'block'
        htmlToImage.toPng(node)
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'order.png';
                link.href = dataUrl;
                link.click();
                node.style.display = 'none'
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                node.style.display = 'none'
            });
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Popover
            placement="bottom-end"
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
            }}
        >
            <PopoverHandler>
                <button
                    className="flex capitalize border border-primary-btn text-primary-btn w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none"
                >
                    <p>Actions</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m17 10l-5 5l-5-5" /></svg>
                </button>
            </PopoverHandler>
            <PopoverContent>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        downloadImage()
                    }}
                >
                    <button className="p-2 w-full text-start rounded-md hover:bg-primary-btn hover:bg-opacity-5">Download</button>
                </div>
                <div>
                    <button onClick={handlePrint} className="p-2 w-full text-start rounded-md hover:bg-primary-btn hover:bg-opacity-5">Print</button>
                </div>
            </PopoverContent>
        </Popover>
    );
}