import { useEffect, useState } from "react";
import { branchListEndPoint, generatePurificationRecieptNumberEndPoint, oldMetalCategoryListEndPoint, purificationIssueListEndPoint, purificationRecieptEndPoint } from "../../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../../service/apiservice";
import toast from "react-hot-toast";
import { isOptionalValidForm } from "../../../../utilities/formvalidation";

export default function RecieptForm({ formValues, setFormValues, formMode, setFormMode, currentItem, getData, selectedDetails, setSelectedDetails , issuedwgt , setIssuedwgt}) {
    
    const [purificationIssueList, setPurificationIssueList] = useState([]);
    const [branchDropDown, setBranchDropDown] = useState([]);
    const [categoryDropDown, setCategoryDropDown] = useState([]);
    
    const [showDetails, setShowDetails] = useState(true);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        generateMeltingRecieptNumber();
        getPurificationIssueList();
        getBranchDropDownList();
        getCategoryDropDownList();
    }, [])

    const generateMeltingRecieptNumber = async () => {
        var response = await getAxios({
            url: generatePurificationRecieptNumberEndPoint
        })

        if (response != null) {
            setFormValues((prevState) => ({
                ...prevState,
                putification_recipt_id: response.data
            }))
        }
    }

    const getPurificationIssueList = async () => {
        var response = await getAxios({
            url: purificationIssueListEndPoint
        })

        if (response != null) {
            setPurificationIssueList(response.data.list)
        }
    }

    const getBranchDropDownList = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropDown(response.data.list)
        }
    }

    const getCategoryDropDownList = async () => {
        var response = await getAxios({
            url: oldMetalCategoryListEndPoint
        })

        if (response != null) {
            setCategoryDropDown(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        if (name == 'purification_issue_details') {
            for (var i of purificationIssueList) {
                if (i.id == value) {
                    setSelectedDetails(i)
                    setFormValues((prevState) => ({
                        ...prevState,
                        issued_pure_weight: i.pure_weight,
                        issued_weight: i.recipt_metal_weight
                    }))
                    setIssuedwgt(i.issued_pure_weight)
                    break;
                }
            }
        } else if (name == 'touch') {
            const receivedPureWeight = (parseFloat(value) / 100) * issuedwgt;
            setFormValues((prevState) => ({
                ...prevState,
                received_pure_weight : receivedPureWeight.toFixed(2)
            }))
            setErrors((prevState) => ({
                ...prevState,
                return_days: value.length > 0 ? "" : `return_date field is required`
            }))
        }

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var formData = new FormData(e.target);

        var err = {}

        for (var field of Object.entries(Object.fromEntries(formData.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(Object.fromEntries(formData.entries()), ['remark'])) {
            if (formMode == 'create') {
                createMeltingIssue(Object.fromEntries(formData.entries()))
            } else {
                updateMeltingIssue(Object.fromEntries(formData.entries()))
            }
        }
    }

    const createMeltingIssue = async (data) => {
        var response = await postAxiosWithToken({
            url: purificationRecieptEndPoint,
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForm();
            getData();
        }
    }

    const updateMeltingIssue = async (data) => {
        var response = await putAxiosWithToken({
            url: purificationRecieptEndPoint + currentItem.id + "/",
            body: data
        })

        if (response != null) {
            toast.success(response.message);
            resetForm();
            getData();
        }
    }

    const resetForm = () => {
        setFormValues({
            purification_issue_details: "",
            putification_recipt_id: "",
            received_date: new Date().toLocaleDateString('en-CA'),
            received_category: "",
            issued_weight: "",
            issued_pure_weight: "",
            received_pure_weight: "",
            purification_charges: "",
            branch: "",
            amount_paid: "",
            touch:"" ,
            melting_bullion_rate : ""
        })
        setErrors({
            purification_issue_details: "",
            putification_recipt_id: "",
            received_category: "",
            issued_weight: "",
            issued_pure_weight: "",
            received_pure_weight: "",
            purification_charges: "",
            branch: "",
            amount_paid: "",
            touch:"" ,
            melting_bullion_rate : ""
        })
        setSelectedDetails({});
        setShowDetails(true);
        generateMeltingRecieptNumber();
        setFormMode('create');
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-x-2">
                <div className="my-[5px] col-span-2">
                    <div className="flex justify-between items-center">
                        <p className="mb-[5px] text-[13px]">Purification Issue <span className="text-red-500 ">*</span></p>
                        <button 
                        onClick={(e) => {e.preventDefault(); setShowDetails(!showDetails)}}
                        className="text-[12px] text-blue-600">{!showDetails ? 'Show' : 'Hide'} Details</button>
                    </div>
                    { formMode == 'create' && <select
                        placeholder="Purification Issue"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="purification_issue_details"
                        value={formValues.purification_issue_details}
                        onChange={(e) => {
                            if (formMode == 'create') {
                                onValueUpdate(e)
                            }
                        }}
                        readOnly={formMode == 'update' ? true : false}
                    >
                        <option value={""}>--select issue id--</option>
                        {
                            purificationIssueList.map((option) => (
                                <option key={option.id} value={option.id}>{option.purification_issue_id}</option>
                            ))
                        }
                    </select> }
                    { formMode == 'create' && <p className="text-red-500 font-[400] text-[10px]">{errors.purification_issue_details}</p>}
                    { formMode == 'update' && <p>{formValues.putification_recipt_id}</p> }
                </div>

                {
                    localStorage.getItem('branch') == 'true' && <div className="my-[5px] col-span-2">
                        <p className="mb-[5px] text-[13px]">Branch <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Branch"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                            name="branch"
                            value={formValues.branch}
                            onChange={onValueUpdate}
                        >
                            <option value="">--select branch--</option>
                            {
                                branchDropDown.map((item, index) => (
                                    <option value={item.id} key={'branch' + index}>{item.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[10px]">{errors.branch}</p>
                    </div>
                }

                { (Object.entries(selectedDetails).length > 0 && showDetails) && <div className="col-span-2 grid grid-cols-2 gap-1">
                    <div>
                        <p className="text-[12px] font-[500]">Bag No</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.bag_number}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Smith</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.smith_name}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Category</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.issued_category_name}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Issued Date</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.issued_date}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Est. Return Date</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.return_date}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Recieved Metal</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.recipt_metal_weight}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Bullion Rate</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.melting_bullion_rate}</p>
                    </div>
                    <div>
                        <p className="text-[12px] font-[500]">Bag Weight</p>
                        <p className="text-[12px] text-primary-btn">{selectedDetails.bag_weight}</p>
                    </div>
                </div> }

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Purification Reciept Id</p>
                    <input
                        type="text"
                        placeholder="Purification Reciept Id"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="putification_recipt_id"
                        value={formValues.putification_recipt_id}
                        readOnly
                    />
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Category <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Category"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] bg-white outline-none"
                        name="received_category"
                        value={formValues.received_category}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select category--</option>
                        {
                            categoryDropDown.map((item) => (
                                <option key={'category'+item.id} value={item.id}>{item.category_name}</option>
                            ))
                        }
                    </select>
                    <p className="text-red-500 font-[400] text-[10px]">{errors.received_category}</p>
                </div>

                <div className="my-[5px] ">
                    <p className="mb-[5px] text-[13px]">Reciept Date <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="Date"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="received_date"
                        value={formValues.received_date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.received_date}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Issued Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Issued Weight"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="issued_weight"
                        value={formValues.issued_weight}
                        readOnly
                    />
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Issued Pure Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Issued Pure Weight"
                        className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="issued_pure_weight"
                        value={formValues.issued_pure_weight}
                        readOnly
                    />
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Touch <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Touch"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="touch"
                        value={formValues.touch}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.touch}</p>
                </div>
                
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Melting Bullion Rate <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Melting Bullion Rate"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="melting_bullion_rate"
                        value={formValues.melting_bullion_rate}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.melting_bullion_rate}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Recieved Pure Weight <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Recieved Pure Weight"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="received_pure_weight"
                        value={formValues.received_pure_weight}
                        onChange={onValueUpdate}
                    />
                     <p className="text-red-500 font-[400] text-[10px]">{errors.received_pure_weight}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Purification Charges <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Purification Charges"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="purification_charges"
                        value={formValues.purification_charges}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.purification_charges}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[13px]">Amount Paid <span className="text-red-500 ">*</span></p>
                    <input
                        type="number"
                        placeholder="Amount Paid"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[14px] outline-none"
                        name="amount_paid"
                        value={formValues.amount_paid}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[10px]">{errors.amount_paid}</p>
                </div>
                <button
                    id="backnav"
                    onClick={(e) => { e.preventDefault(); resetForm() }}
                    className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[13px] outline-none border-none"
                >reset <span className="lowercase">(esc)</span></button>
                <button
                    type="submit"
                    className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[13px] outline-none border-none"
                >save<span className="lowercase">(ctrl+s)</span></button>
            </div>
        </form>
    );
}