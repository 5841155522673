import { useEffect } from "react";
import { ifscVerifyEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";

export default function CompanyBankForm({ dataList = [], formMode, setDataList, removedItems, setRemovedItems }) {

    useEffect(() => {
        if (localStorage.getItem('company_form_mode') === 'update') {
            if (dataList.length === 0) {
                addBankDetails(); 
            }
        } else if(localStorage.getItem('company_form_mode') === 'create') {
            if (dataList.length === 0) {
                addBankDetails();
            }
        }
    }, [dataList]);

    const addBankDetails = () => {
        const data = {
            acc_holder_name: "",
            account_no: "",
            ifsc_code: "",
            bank_name: "",
            branch_name: "",
            micr_code: ""
        };
        setDataList([...dataList, data]);
    };

    const onValueUpdate = (text, index) => {
        const { name, value } = text
        const data = [...dataList];
        data[index][name] = value
        setDataList(data)
    }

    const onRemoveDetails = (index) => {
        if (dataList.length > 1) {
            const data = [...dataList];
            if(data[index].id !== undefined) {
                removedItems.push(data[index].id)
            }
            data.splice(index, 1);
            setDataList(data);
        }

    }

    const getBankDetails = async (index, ifsc) => {
        var response = await getAxios({
            url: ifscVerifyEndPoint + ifsc + "/",
        })

        if (response != null) {
            const data = [...dataList];
            data[index]['bank_name'] = response.data.bank_name
            data[index]['branch_name'] = response.data.branch_name
            data[index]['micr_code'] = response.data.micr_code
            setDataList(data);
        }
    }

    return (
        <>
            <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Bank Details</p>

                <ListDetails rows={dataList} onValueUpdate={onValueUpdate} onRemoveDetails={onRemoveDetails} getBankDetails={getBankDetails} />

                <button onClick={(e) => { e.preventDefault(); addBankDetails() }} className="flex gap-3 text-primary-btn justify-end w-full my-[10px] mt-[30px] font-[600] text-[17px]">
                    <span className="bg-primary-btn text-white rounded-lg w-[25px]">+</span>
                    <p>Add bank details</p>
                </button>
            </div>
        </>
    );
}

function ListDetails({
    rows,
    onValueUpdate,
    onRemoveDetails,
    getBankDetails
}) {
    return rows.map((item, index) => {
        const {
            acc_holder_name,
            account_no,
            ifsc_code,
            bank_name,
            branch_name,
            micr_code
        } = item;
        return <div key={"bank" + index} className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5 border p-[30px] my-[10px] shadow-lg rounded-xl relative">
            {
                rows.length > 1 ? <button onClick={(e) => { e.preventDefault(); onRemoveDetails(index) }} className="flex gap-3 text-red-600 justify-end w-full my-[10px] font-[600] text-[17px] absolute right-8">
                    <span className="bg-red-600 text-white rounded-lg w-[25px]">-</span>
                    <p>Remove bank details</p>
                </button> : ""
            }


            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Account Holder Name</p>
                <input
                    type="text"
                    placeholder="account holder name"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    name="acc_holder_name"
                    value={acc_holder_name}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Account No</p>
                <input
                    type="text"
                    placeholder="account no"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    name="account_no"
                    value={account_no}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div class=" items-center my-[5px] py-2 mt-[-5px]">
            <p className="mb-[5px] text-[16px]">IFSC Code</p>
                <div className="bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] flex justify-between items-center">
                    <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        placeholder="Jane Doe"
                        name="ifsc_code"
                        id={"ifsccode" + index}
                        value={ifsc_code}
                        onChange={(e) => onValueUpdate(e.target, index)} />
                    <button class="flex-shrink-0 bg-blue-700    text-sm  text-white py-1 px-2 rounded" type="button" onClick={() => getBankDetails(index, document.getElementById("ifsccode" + index)?.value)}>
                        verify
                    </button>
                </div>
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Bank Name</p>
                <input
                    type="text"
                    placeholder="bank name"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    name="bank_name"
                    value={bank_name}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">Branch Name</p>
                <input
                    type="text"
                    placeholder="branch name"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    name="branch_name"
                    value={branch_name}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
            <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">MICR No</p>
                <input
                    type="text"
                    placeholder="micr no"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    name="micr_code"
                    value={micr_code}
                    onChange={(e) => onValueUpdate(e.target, index)}
                />
            </div>
        </div>
    })
}