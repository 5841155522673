import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { settingsVerificationEndPoint } from "../../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { setAddT, setNow } from "../../../redux/appconfigslice";
import axios from "axios";
import toast from "react-hot-toast";

export default function AuthenticatePass({ closePassModel }) {

    const { now } = useSelector((state) => state.appConfig);

    const dispatch = useDispatch();

    const [encryptPasswordValue, setEncryptPasswordValue] = useState(true);

    const schema = yup
        .object({
            secret: yup.string().required().min(10).max(10)
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema)
    })

    const verifyPass = async (data) => {
        try {

            const token = JSON.parse(localStorage.getItem('login'))?.token;

            var response = await axios.post(settingsVerificationEndPoint, data, {
                headers: {
                    "Authorization": `Token ${token}`
                }
            })

            if (response.data != null) {
                reset();
                dispatch(setAddT(false))
                dispatch(setNow(false))
            }   
        } catch(err) {
            toast.error('Access Denied')
        }
        
    }

    return <form onSubmit={handleSubmit(verifyPass)}>
        <div className={`my-[5px]`}>
            <p className="mb-[5px] text-[16px]">Secret <span className="text-red-500">*</span></p>
            <div className="flex border border-gray-300 rounded-lg items-center py-[7px] px-[10px]">
                <input
                    type={encryptPasswordValue ? "password" : "text"}
                    placeholder="secret"
                    className="sm:mb-[5px] w-full text-[17px] outline-none"
                    name="secret"
                    {...register('secret')}
                />
                <div onClick={() => setEncryptPasswordValue(true)} className={`${!encryptPasswordValue ? 'block' : 'hidden'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" /></svg>
                </div>
                <div onClick={() => setEncryptPasswordValue(false)} className={`${encryptPasswordValue ? 'block' : 'hidden'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="currentColor"><path d="m10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" /><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6l-12-12l.708-.708l12 12l-.708.708z" /></g></svg>
                </div>
            </div>
            <p className="text-red-500 font-[400] text-[13px]">{errors.secret?.message}</p>
        </div>
        <div className="flex items-center justify-between mt-[25px]">
            <button
                onClick={(e) => { e.preventDefault(); closePassModel(); }}
                className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
            >Close</button>
            <button
                className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >Verify</button>
        </div>
    </form>
}