import { useEffect, useRef, useState } from "react";
import Pagination from "../../../components/Pagination";
import { LoadingPage } from "../../../session/loadingpage";
import Particulars from "./widgets/particulars";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import toast from "react-hot-toast";
import Popup from "../../../components/Popup";
import { purificationRecieptEndPoint, purificationRecieptListEndPoint } from "../../../service/api";
import PurificationRecieptFilters from "./purificationrecieptfilters";
import RecieptForm from "./widgets/recieptform";

export default function PurificationReceipt() {

    const searchRef = useRef();

    const [recieptFormValues, setRecieptFormValues] = useState({
        received_date: new Date().toLocaleDateString('en-CA')
    });
    const [particularsList, setParticularsList] = useState([]);
    const [purificationRecieptDetails, setPurificationRecieptDetails] = useState({});
    const [detailsLoding, setDetailsLoading] = useState(false);

    const [selectedDetails, setSelectedDetails] = useState({});

    const [formMode, setFormMode] = useState('create');

    const [tableFilters, setTableFilters] = useState({
        from_date: null,
        to_date: null,
        vendor: null,
        search: "",
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        page: 1,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null
    })
    const [totalPages, setTotalPages] = useState(1);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [issuedwgt, setIssuedwgt] = useState();

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getPurificationRecieptList();
    }, [tableFilters])

    const getPurificationRecieptList = async () => {
        var response = await postAxiosWithToken({
            url: purificationRecieptListEndPoint,
            body: tableFilters
        })

        if (response != null) {
            setIsLoading(false)
            setParticularsList(response.data.list)
            setTotalPages(response.data.total_pages)
        }
    }

    const getPurificationRecieptDetails = async (id) => {
        setDetailsLoading(true)
        var response = await getAxios({
            url: purificationRecieptEndPoint + id + '/'
        })

        if (response != null) {
            let tempDetails = {
                purification_issue_details: response.data.purification_issue_details,
                putification_recipt_id: response.data.putification_recipt_id,
                branch: response.data.branch,
                received_date: response.data.received_date,
                received_category: response.data.received_category,
                issued_weight: response.data.issued_weight,
                issued_pure_weight: response.data.issued_pure_weight,
                received_pure_weight: response.data.received_pure_weight,
                purification_charges: response.data.purification_charges,
                amount_paid: response.data.amount_paid,
                touch: response.data.touch,
                melting_bullion_rate: response.data.melting_bullion_rate,
            }

            let issueDetails = {
                bag_number: response.data.bag_number,
                smith_name: response.data.smith_name,
                issued_category_name: response.data.issued_category_name,
                issued_date: response.data.issued_date,
                return_days: response.data.return_days,
                return_date: response.data.return_date,
                metal_name: response.data.metal_name,
                bullion_rate: response.data.bullion_rate,
            }
            setIssuedwgt(response.data.issued_pure_weight)
            setSelectedDetails(issueDetails)
            setRecieptFormValues(tempDetails)
            setFormMode('update')
            setDetailsLoading(false)
        }
    }

    const deleteMeltingReciept = async () => {
        var response = await deleteAxiosWithToken({
            url: purificationRecieptEndPoint + purificationRecieptDetails.id + "/"
        })

        if (response != null) {
            toast.success(response.message)
            closeDeleteModal();
            getPurificationRecieptList();
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <div className="mb-[50px]">
            <p className="font-[600] text-[20px] capitalize">{formMode} Purification Reciept</p>
            <div className="flex gap-3 items-start">
                <div className="relative w-1/4 bg-white p-5 border rounded-xl overflow-hidden">
                    <RecieptForm
                        issuedwgt={issuedwgt}
                        setIssuedwgt={setIssuedwgt}
                        formValues={recieptFormValues}
                        setFormValues={setRecieptFormValues}
                        formMode={formMode}
                        setFormMode={setFormMode}
                        currentItem={purificationRecieptDetails}
                        getData={getPurificationRecieptList}
                        selectedDetails={selectedDetails}
                        setSelectedDetails={setSelectedDetails}
                    />
                    {detailsLoding && <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full ">
                        <LoadingPage height="min-h-full" width="w-full" opacity="bg-opacity-[0.5]" />
                    </div>}
                </div>
                <div className="w-3/4 bg-white border rounded-xl overflow-hidden sticky top-[50px]">
                    <div className="p-3">
                        <PurificationRecieptFilters filters={tableFilters} setFilters={setTableFilters} searchRef={searchRef} />
                    </div>
                    <Particulars
                        particularsList={particularsList}
                        isLoading={isLoading}
                        setCurrentItem={setPurificationRecieptDetails}
                        setIsDeleteOpen={setIsDeleteOpen}
                        getDetails={getPurificationRecieptDetails}
                    />
                    <div className="p-3">
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div>
                </div>
            </div>
            <Popup title={'Delete Purification Reciept Details'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to cancel the {purificationRecieptDetails.putification_recipt_id} details?</p>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">No</button>
                        <button type="submit" onClick={() => { deleteMeltingReciept() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Yes</button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}