import { useEffect, useState } from "react";
import { centgroupListEndPoint, lotStoneDeleteEndPoint, rateTypeListEndPoint, stoneListEndPoint, taggingWeightTypeListEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios } from "../../../service/apiservice";
import { isValidForm } from "../../../utilities/formvalidation";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import Select from 'react-select';

export default function SchemeForm({ dataList = [], setDataList, formType, closeModal }) {

    const [stoneDropDown, setStoneDropDown] = useState([]);
    const [weightDropDown, setWeightDropDown] = useState([]);
    const [rateDropDown, setRateDropDown] = useState([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
        setIsLoading(null)
    }

    useEffect(() => {

        if (formType === 'create') {
            addValue()
        }
        getStoneDropDownData();
        getWeightTypeDropDownData();
        getRateTypeDropDownData();
    }, [])

    const getStoneDropDownData = async () => {
        var response = await getAxios({
            url: stoneListEndPoint
        })

        if (response !== null) {
            setStoneDropDown(response.data.list)
        }
    }

    const getWeightTypeDropDownData = async () => {
        var response = await getAxios({
            url: taggingWeightTypeListEndPoint
        })

        if (response !== null) {
            setWeightDropDown(response.data.list)
        }
    }

    const getRateTypeDropDownData = async () => {
        var response = await getAxios({
            url: rateTypeListEndPoint
        })

        if (response !== null) {
            setRateDropDown(response.data.list)
        }
    }

    const addValue = () => {
        let firstData = {
            scheme_type: "",
            scheme_no: "",
            scheme_balance: "",
            remarks: "",
            amount: "",
            reference_number: "",
        }
        setDataList([...dataList, firstData])
    }

    const removeValue = () => {
        let data = [...dataList];

        if (formType === 'update') {
            if (data[deleteIndex].id) {
                setIsLoading(deleteIndex)
                setIsDeleteOpen(true)
                deleteStone(data[deleteIndex].id)
            } else {
                setIsLoading(null)
                setIsDeleteOpen(false)
            }
        }
        data.splice(deleteIndex, 1);
        setDataList(data);
    }

    const onValueUpdate = (text, index) => {
        const { name, value, checked } = text

        if (name === 'include_stone_weight') {
            let data = [...dataList];
            data[index][name] = checked
            setDataList(data)
        } else {
            let data = [...dataList];
            data[index][name] = value
            setDataList(data)
        }

    }

    const deleteStone = async (id) => {

        var response = await deleteAxiosWithToken({
            url: lotStoneDeleteEndPoint + id + '/'
        })

        if (response !== null) {
            setIsLoading(null)
            setIsDeleteOpen(false)
            toast.success(response.message)
        }
    }

    return (
        <div className="w-full mt-[15px]">
            <div className="flex justify-between">
                <button className="border-none outline-none bg-primary-btn text-white rounded float-right mb-[10px] px-[10px] py-[7px]" onClick={(e) => { e.preventDefault(); addValue() }}>Add Scheme</button>



            </div>
            <div className="mt-3">
                <table className="w-full">


                    <tbody className="text-primary-grey">
                        {
                            dataList.map((item, index) => (
                                <tr key={'item'}>

                                    <td className=" py-[7px] ">
                                        <p className="mb-[5px] text-[16px]">Scheme Type</p>
                                        <select
                                            placeholder=""
                                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-[150px] p-[7px] text-[14px] outline-none"
                                            name="scheme_type"
                                            value={item.scheme_type}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        >
                                            <option value={""}>--select Scheme type--</option>
                                            {/* {
                                                        rateDropDown.map((option, index) => (
                                                            <option key={"rate" + index} value={option.id}>{option.type_name}</option>
                                                        ))
                                                    } */}
                                        </select>
                                    </td>
                                    <td className=" py-[7px] ">
                                        <p className="mb-[5px] text-[16px]">Scheme No</p>
                                        <input
                                            type="number"
                                            name="scheme_no"
                                            placeholder="Scheme No"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[5px] text-[14px] outline-none"
                                            value={item.scheme_no}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className=" py-[7px] ">
                                        <p className="mb-[5px] text-[16px]">Scheme Balance</p>
                                        <input
                                            type="number"
                                            name="scheme_balance"
                                            placeholder="Scheme Balance "
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[5px] text-[14px] outline-none"
                                            value={item.scheme_balance}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className=" py-[7px] ">
                                        <p className="mb-[5px] text-[16px]">Remark</p>
                                        <input
                                            type="text"
                                            name="remarks"
                                            placeholder="Remark"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[5px] text-[14px] outline-none"
                                            value={item.remarks}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className=" py-[7px]">
                                        <p className="mb-[5px] text-[16px]">Amount</p>
                                        <input
                                            type="number"
                                            name="amount"
                                            placeholder="Amount"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[5px] text-[14px] outline-none"
                                            value={item.amount}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className=" py-[7px] text-center">
                                        <p className="mb-[5px] text-[16px]">Reference</p>
                                        <input
                                            type="number"
                                            name="reference_number"
                                            placeholder="Reference"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-[100px] p-[5px] text-[14px] outline-none"
                                            value={item.reference_number}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>


                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="flex gap-4 justify-end mb-5">
                <div className="mt-5">
                    <p className="mb-1">Old Gold Amount</p>
                    <p className="bg-[#E2E8F0] p-1 rounded">₹</p>
                </div>
                <div className="mt-5">
                    <p className="mb-1">Old Silver Amount</p>
                    <p className="bg-[#E2E8F0] p-1 rounded">₹0.00</p>
                </div>
            </div>

            <div className="mt-[10px] flex gap-3 justify-end items-center">
                <button onClick={() => {

                    closeModal()
                }} className="border-[2px] outline-none px-[10px] py-[5px] w-fit rounded-lg hover:bg-primary-btn  hover:text-white">Reset</button>

                <button onClick={() => {
                    let tempDataList = []
                    for (var item of dataList) {
                        if (isValidForm(item)) {
                            tempDataList.push(item)
                        }
                    }
                    setDataList(tempDataList)
                    closeModal()
                }} className="border-[2px] outline-none px-[10px] py-[5px] w-fit rounded-lg border-secondary hover:bg-secondary hover:text-primary-grey">Save</button>
                {/* <button className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Save</button> */}
            </div>

            <div>
                <Popup title={'Delete Stone'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the stone details?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { removeValue() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
}