import { BrowserRouter, HashRouter, Link, useLocation, useNavigate } from "react-router-dom";
import Routers from "./routers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDark, setMenuOption } from "./redux/themeconfigslice";

export default function App() {


  const { isDark, sideOptions, menuOption } = useSelector((state) => state.themeConfig)

  const dispatch = useDispatch();

  useEffect(() => {
    let darkStorage = JSON.parse(localStorage.getItem('dark'))
    let layoutStorage = localStorage.getItem('layout')

    if (darkStorage !== null) {
      dispatch(setIsDark(darkStorage))
    }

    if (layoutStorage !== null) {
      dispatch(setMenuOption(layoutStorage))
    }
  }, [])

  useEffect(() => {
    if (isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDark]);

  

  
  return (
    <BrowserRouter>
      <Routers />
      {/* { sideOptions && <div className={`${isDark ? "bg-dark-bg" : "bg-white"} ${menuOption === 'right-sidebar' ? "left-0" : "right-0"} fixed bottom-[40px] p-[15px] border shadow-lg rounded-l-lg`}>

        <div onClick={() => {
          document.getElementById('layoutbar').className = `${isDark ? "bg-dark-bg" : "bg-white"} ${menuOption === 'right-sidebar' ? "left-0" : "right-0"} h-[100vh] overflow-y-scroll border top-0 z-[5] p-[15px] w-[225px] fixed block`
        }} className="flex flex-col items-center cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M8 20H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zm8-10h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2zm5 10v-6a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2z" /></svg>
          <p className="text-[13px]">Layout</p>
        </div>

          
        { JSON.parse(localStorage.getItem('authenticated')) && <div onClick={() => {localStorage.setItem('customer_form_mode', 'create')}}>
        <Link to={'/books/customer/customer-form'} className="flex flex-col items-center cursor-pointer mt-[20px] test-black">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64m448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64m32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64m-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112m76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2m-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4"/></svg>
          <p className="text-[13px]">Cusomter </p>
        </Link>
        </div>} */}

        {/* <div onClick={() => dispatch(setIsDark(!isDark))} className="flex flex-col items-center cursor-pointer mt-[20px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M19 5.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0Zm-2.147-1.854a.5.5 0 0 0-.707 0L13.5 6.293l-.647-.647a.5.5 0 1 0-.707.708l1 1a.5.5 0 0 0 .707 0l3-3a.5.5 0 0 0 0-.708ZM14.5 11c.17 0 .34-.008.507-.023c-.325.49-.73.962-1.211 1.417a.599.599 0 0 0-.145.213l-.026.081L13.31 14H6.689l-.313-1.311a.595.595 0 0 0-.171-.295c-1.39-1.312-2.133-2.77-2.2-4.355l-.002-.43C4.107 4.494 6.753 2 10 2c.085 0 .169.002.253.005A5.5 5.5 0 0 0 14.5 11Zm-1.427 4l-.384 1.605c-.184.771-.866 1.33-1.671 1.39L9.125 18c-.819 0-1.535-.516-1.777-1.262l-.037-.133L6.928 15h6.145Z" /></svg>
          <p className="text-[13px]">Mode</p>
        </div> */}
      {/* </div> } */}
    </BrowserRouter>
  );
}