import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { mainmenudropdownListEndPoint, menuEndPoint, menuGroupListEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function MenuForm({ getData, formMode, curMenu, setFormMode, setCurrentMenu }) {

    const [menuGroupDropdown, setMenuGroupDropdown] = useState([]);

    const schema = yup
        .object({
            menu_name: yup.string().required(),
            icon: yup.string(),
            menu_group: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            menu_name: curMenu.menu_name,
            menu_group: curMenu.menu_group,
            icon: curMenu.icon
        } : {
            menu_name: "",
            menu_group: "",
            icon: ""
        }
    })

    useEffect(() => {
        getMenuGroupDropdownData()
    },[])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getMenuGroupDropdownData = async () => {
        var response = await getAxios({
            url: mainmenudropdownListEndPoint
        })

        if (response !== null) {
            setMenuGroupDropdown(response.data.list)
        }
    }

    const onSubmit = () => {
        if (formMode === 'create') {
            createMenu(new FormData(document.getElementById("menuform")));
        } else {
            updateMenu(new FormData(document.getElementById("menuform")));
        }
    }

    const createMenu = async (body) => {
        var response = await postAxiosWithToken({
            url: menuEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateMenu = async (body) => {
        var response = await putAxiosWithToken({
            url: menuEndPoint + curMenu?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentMenu({
            menu_name: "",
            menu_group: "",
            icon: ""
        })
    }

    return (
        <>
           <div className="p-5 rounded-[10px] bg-menu-head-bg shadow">
                <p className="text-menu-head font-[600] text-[18px]">Add Menu</p>
                <form id="menuform" className="grid 2xl:grid-cols-5 xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[16px]">Menu Group</p>
                        <select
                            placeholder="menu group"
                            className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="menu_group"
                            {...register('menu_group')}
                        >
                            <option value={""}>--select group--</option>
                            {
                                menuGroupDropdown.map((option) => (
                                    <option key={option.id} value={option.id}>{option.main_menugroup_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.menu_group?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Menu</p>
                        <input
                            type="text"
                            placeholder="menu name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="menu_name"
                            id="forminit"
                            {...register('menu_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.menu_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[16px]">Icon</p>
                        <input
                            type="file"
                            placeholder="icon"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="icon"
                            {...register('icon')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.icon?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 xl:mt-[32px] sm:mt-[35px] xl:mt-0 2xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}