import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { createrangestockEndPoint, rangestockEndPoint, rangestockListEndPoint, rangestockStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { datetimetodate } from "../../../utilities/datetime";
import RangestockForm from "./rangestockform";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import RangestockFilters from "./rangestockfilter";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";

export default function Rangestock() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [rangestockList, setRangestockList] = useState([]);
    const [filteredRangestockList, setFilteredRangestockList] = useState([]);
    const [currentRangestock, setCurrentRangestock] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });
    const { pagination } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getRangestockListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    const getRangestockListData = async (body) => {
        var response = await postAxiosWithToken({
            url: rangestockListEndPoint,
            body: body
        })

        if (response !== null) {
            setRangestockList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: rangestockStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getRangestockListData()
            toast.success(response.message)
        }
    }

    const deleteMetal = async () => {
        var response = await deleteAxiosWithToken({
            url: createrangestockEndPoint + currentRangestock.id + "/"
        })

        if (response !== null) {
            getRangestockListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <>
            <div>
                <div>
                    <RangestockForm getData={getRangestockListData} formMode={formMode} setFormMode={setFormMode} currentrangestock={currentRangestock} setCurrentRangestock={setCurrentRangestock} />
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <RangestockFilters filter={tableFilters} setFilters={setTableFilters} searchRef={searchRef} getData={getRangestockListData} dataList={rangestockList} setFilteredData={setFilteredRangestockList} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Item Name</td>
                                <td className="p-[10px]">Range Value</td>
                                <td className="p-[10px]">From Weight</td>
                                <td className="p-[10px]">To Weight</td>
                                <td className="p-[10px]">Status</td>
                                <td className="p-[10px]">Created At</td>
                                <td className="p-[10px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    rangestockList?.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                        rangestockList?.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item?.item_name}</td>
                                                <td className="p-[10px]">{item?.range_value}</td>
                                                <td className="p-[10px]">{item?.from_weight}</td>
                                                <td className="p-[10px]">{item?.to_weight}</td>
                                                <td className="p-[10px]">
                                                    <div onClick={() => onStatusChange(item.id)}>
                                                        <SwitchButton enabled={item.is_active} />
                                                    </div>
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at)}</td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div className="cursor-pointer hover:scale-125" onClick={() => { navigate('view-range-stock/'+item.id) }}>
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.392 0 9.878 3.88 10.819 9c-.94 5.12-5.427 9-10.819 9c-5.392 0-9.878-3.88-10.818-9C2.122 6.88 6.608 3 12 3m0 16a9.005 9.005 0 0 0 8.778-7a9.005 9.005 0 0 0-17.555 0A9.005 9.005 0 0 0 12 19m0-2.5a4.5 4.5 0 1 1 0-9a4.5 4.5 0 0 1 0 9m0-2a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5" /></svg>
                                                        </div>
                                                        <div onClick={() => { setCurrentRangestock(item); setFormMode('update'); document.getElementById('forminit')?.focus() }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => { setCurrentRangestock(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentRangestock?.rangestock_name} rangestock?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteMetal() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}