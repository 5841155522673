import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { vendorwiseEndPoint } from '../../service/api';
import { postAxiosWithToken } from '../../service/apiservice';

const ApexChart2 = ( {tableFilters} ) => {
  

  const [purchasevssaleslist, setPurchasevsSaleslist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const seriesData = purchasevssaleslist;
  const Branch = {branch : tableFilters.branch}

  useEffect(() => {
    getPurchasevsSalesData(Branch)
  }, [tableFilters])

  const getPurchasevsSalesData = async (body) => {
    var response = await postAxiosWithToken({
      url: vendorwiseEndPoint,
      body: body
    })

    if (response !== null) {
      setPurchasevsSaleslist(response.data.list)
      setIsLoading(false)
    }
  }


  const optionsData = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        // endingShape: 'rounded',
        borderRadius: {
          topLeft: 10,
          topRight: 10
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
    },
    fill: {
      colors: ['#61A375', '#F1C837']
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val 
        }
      },
    },
    dataLabels: {
      style: {
        colors: ['#61A375', '#F1C837'],
        borderRadius: 10,
      }
    },
    markers: {
      colors: ['#61A375', '#F1C837']
    },
    colors: ['#61A375', '#F1C837'],
    responsive: [
      {
        breakpoint: 320,
        options: {
          chart: {
            height: 300
          }
        }
      }
    ],
    borderRadius: {
      topLeft: 20,
      topRight: 20,
    },
  };

  return (
    <div className='w-[100%]'>
      <div id="chart" className='w-[100%] chart-container'>
        <ReactApexChart options={optionsData} series={seriesData} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart2;
