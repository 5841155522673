import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { purchaseEndPoint, purchaseentryListEndPoint, userEndPoint, userListEndPoint, userStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import PurchaseFilter from "./purchaseFilter";

export default function PurchaseEntry() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [purchaseEntryList, setPurchaseEntryList] = useState([]);
    const [currentPurchase, setCurrentPurchase] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        purchase_type: "2",
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const { actions, pagination } = useSelector((state) => state.appConfig);


    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            localStorage.setItem('user_purchaseentry_mode', 'create');
            navigate('purchase-entry-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    useEffect(() => {
        getPurchaseListData(tableFilters)
    }, [tableFilters])

    const getPurchaseListData = async (body) => {
        var response = await postAxiosWithToken({
            url: purchaseentryListEndPoint,
            body: body
        })

        if (response !== null) {
            console.log(response.data.list)
            setPurchaseEntryList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const deletePurchase = async () => {
        var response = await deleteAxiosWithToken({
            url: purchaseEndPoint + currentPurchase.id + '/'
        })

        if (response !== null) {
            getPurchaseListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }



    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div>
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            if (actions.addPermit) {
                                localStorage.setItem('user_purchaseentry_mode', 'create'); navigate('old-purchase-form')
                            } else {
                                toast.error("You don't have permission for add")
                            }
                        }}
                        className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Purchase <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <PurchaseFilter filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">PO ID</td>
                                <td className="p-[10px] min-w-[150px]">Purchase type</td>
                                <td className="p-[10px] min-w-[150px]">Customer/Vendor Name</td>
                                <td className="p-[10px] min-w-[150px]">Item</td>
                                <td className="p-[10px] min-w-[150px]">Order Date</td>
                                <td className="p-[10px] min-w-[150px]">Branch</td>
                                <td className="p-[10px] min-w-[150px]">ADV. AMT</td>
                                <td className="p-[10px] min-w-[150px]">Total AMT</td>
                                <td className="p-[10px] min-w-[150px]">Pending AMT</td>
                                <td className="p-[10px] min-w-[150px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-[#fff]">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={11}>Loading...</td>
                                </tr> :
                                    purchaseEntryList.length === 0 ? <tr><td className="text-center border" colSpan={11}>No Data</td></tr> :
                                        purchaseEntryList?.map((item, index) => (

                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.id}</td>
                                                <td className="p-[10px]">{item.purchase_typename}</td>
                                                <td className="p-[10px]">{item.person_name}</td>
                                                <td className="p-[10px]">{item.person_name}</td>
                                                <td className="p-[10px]">{item.purchase_date}</td>
                                                <td className="p-[10px]">{item.branch_name}</td>
                                                <td className="p-[10px]">{item.advance_amount}</td>
                                                <td className="p-[10px]">{item.total_amount}</td>
                                                <td className="p-[10px]">{item.balance_amount}</td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => {
                                                            if (actions.editPermit) {
                                                                setCurrentPurchase(item);
                                                                localStorage.setItem('user_purchaseentry_mode', 'update'); localStorage.setItem('purchase_form_id', item.id.toString()); navigate('purchase-entry-form'); localStorage.setItem('update_purchase_type', tableFilters.purchase_type);
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentPurchase(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>




                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete User'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentPurchase?.email} user?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deletePurchase() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}