import React, { useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

const DatePicker1 = ({ value, handleValueChange }) => {

  return (
    <div>
      <Datepicker
        primaryColor={"#054EAD"}
        showShortcuts={true}
        showFooter={true}
        value={value}
        onChange={handleValueChange}
        popoverDirection="down"
      />
    </div>
  );
};

export default DatePicker1;
