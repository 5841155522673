

export default function StoneForm({
  closeModal,
  dataList = [],
  additionalData, }) {







  return (

    <div className="w-full mt-[15px]">

      <div className="w-full overflow-scroll">
        <table className="w-full">
           <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
            <tr>
              <td className="p-[10px] text-center">S.No</td>
              <td className="p-[10px] text-center">Stone Name</td>
              <td className="p-[10px] text-center">Stone Pieces</td>
              <td className="p-[10px] text-center">Stone Weight Type</td>
              <td className="p-[10px] text-center">Stone Weight</td>
              <td className="p-[10px] text-center">Stone Rate Type</td>
              <td className="p-[10px] text-center">Stone Rate</td>
            </tr>
          </thead>
          <tbody className="text-primary-grey">
            {dataList.map((item, index) => (
              <tr key={index}>
                <td className="border py-[7px] text-center">{index + 1}</td>
                <td className="border py-[7px] text-center">
                  <p className="text-primary-btn font-[400] text-[15px]">{item.stone_name || ''}</p>
                </td>
                <td className="border py-[7px] text-center">
                  <p className="text-primary-btn font-[400] text-[15px]">{item.stone_pieces || ''}</p>
                </td>
                <td className="border py-[7px] text-center">
                  <p className="text-primary-btn font-[400] text-[15px]">{item.stone_weight_type_name || ''}</p>
                </td>
                <td className="border py-[7px] text-center">
                  <p className="text-primary-btn font-[400] text-[15px]">{item.stone_weight || ''}</p>
                </td>
                <td className="border py-[7px] text-center">
                  <p className="text-primary-btn font-[400] text-[15px]">{item.stone_rate_type_name || ''}</p>
                </td>
                <td className="border py-[7px] text-center">
                  <p className="text-primary-btn font-[400] text-[15px]">{item.stone_rate || ''}</p>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-[10px] flex gap-3 justify-end items-center">
        <button onClick={() => {


          closeModal()
        }} className="border outline-none px-[10px] py-[5px] w-fit rounded-lg border-secondary hover:bg-secondary hover:text-white">Close</button>
        {/* <button className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Save</button> */}
      </div>
    </div>
  );
}