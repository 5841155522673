import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { calculationtypeDropdownEndPoint, counterListEndPoint, itemIdEndPoint, itemMasterEndPoint, itemUploadImgEndPoint, metalListEndPoint, purityListEndPoint, stocktypeDropdownEndPoint, weightTypeListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { fixedCalcId, packetStockType, pergramCalId, weightCalId } from "../../../constant";
import { toast } from "react-hot-toast";

export default function ItemmasterForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentItemmaster, setCurrentItemmaster] = useState({});

    const [metalDropdown, setMetalDropdown] = useState([]);
    const [purityDropdown, setPurityDropdown] = useState([]);
    const [stocktypeDropdown, setStockTypeDropdown] = useState([]);
    const [calculationtypeDropdown, setCalculationTypeDropdown] = useState([]);
    const [itemcounterDropdown, setItemCounterDropdown] = useState([]);
    const [itemid, setItemId] = useState('');
    const [itemimage, setItemImage] = useState([]);
    const [wighttype, setWightType] = useState([]);
    const [selectedCalc, setSelectedCalc] = useState('');
    const [selectedStockType, setSelectedStockType] = useState('');

    const [allowZeroWeight, setAllowZeroWeight] = useState(false);

    const [imageFile, setImageFile] = useState("")

    const schema = yup
        .object({
            item_id: yup.string(),
            metal: yup.string().required(),
            purity: yup.string().required(),
            hsn_code: yup.string().required(),
            huid_rate: yup.string().required(),
            stock_type: yup.string().required(),
            item_code: yup.string().required(),
            item_name: yup.string().required(),
            item_image_url: yup.string(),
            allow_zero_weight: yup.bool(),
            calculation_type: yup.string().required(),
            fixed_rate: parseInt(selectedCalc) === parseInt(fixedCalcId) ? yup.string().required() : yup.string(),
            per_gram_rate: parseInt(selectedCalc) === parseInt(pergramCalId) ? yup.string().required() : yup.string(),
            item_counter: yup.string().required(),
            wastage_calculation: parseInt(selectedCalc) === parseInt(weightCalId) ? yup.string().required() : yup.string(),
            wastage_percent: parseInt(selectedCalc) === parseInt(weightCalId) ? yup.string().required() : yup.string(),
            flat_wastage: parseInt(selectedCalc) === parseInt(weightCalId) ? yup.string().required() : yup.string(),
            making_charge_calculation: parseInt(selectedCalc) === parseInt(weightCalId) ? yup.string().required() : yup.string(),
            making_charge_gram: parseInt(selectedCalc) === parseInt(weightCalId) ? yup.string().required() : yup.string(),
            flat_making_charge: parseInt(selectedCalc) === parseInt(weightCalId) ? yup.string().required() : yup.string(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            item_id: currentItemmaster?.item_id,
            metal: currentItemmaster?.metal,
            purity: currentItemmaster?.purity,
            hsn_code: currentItemmaster?.hsn_code,
            huid_rate: currentItemmaster?.huid_rate,
            stock_type: currentItemmaster?.stock_type,
            item_code: currentItemmaster?.item_code,
            item_name: currentItemmaster?.item_name,
            item_image_url: currentItemmaster?.item_image_url,
            allow_zero_weight: currentItemmaster?.allow_zero_weight,
            calculation_type: currentItemmaster?.calculation_type,
            fixed_rate: currentItemmaster?.fixed_rate,
            per_gram_rate: currentItemmaster?.per_gram_rate,
            item_counter: currentItemmaster?.item_counter,
            wastage_calculation: currentItemmaster?.wastage_calculation,
            wastage_percent: currentItemmaster?.wastage_percent,
            flat_wastage: currentItemmaster?.flat_wastage,
            making_charge_calculation: currentItemmaster?.making_charge_calculation,
            making_charge_gram: currentItemmaster?.making_charge_gram,
            flat_making_charge: currentItemmaster?.flat_making_charge,

        } : {
            item_id: "",
            metal: "",
            purity: "",
            hsn_code: "",
            huid_rate: "",
            stock_type: "",
            item_code: "",
            item_name: "",
            item_image_url: "",
            allow_zero_weight: false,
            calculation_type: "",
            fixed_rate: "",
            per_gram_rate: "",
            item_counter: "",
            wastage_calculation: "",
            wastage_percent: "",
            flat_wastage: "",
            making_charge_calculation: "",
            making_charge_gram: "",
            flat_making_charge: "",
        }

    })

    const getIWightTypeData = async () => {
        var response = await getAxios({
            url: weightTypeListEndPoint

        })

        if (response !== null) {
            setWightType(response.data.list)
        }
    }

    const getItemIdData = async () => {
        var response = await getAxios({
            url: itemIdEndPoint

        })

        if (response !== null) {
            setItemId(response.item_id)
        }
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit')?.blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            reset()
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getMetalDropdownData();
        getStockTypeDropdownData();
        getCalculationTypeDropdownData();
        getItemCounterDropdownData();
        getIWightTypeData();

        setFormMode(localStorage.getItem('itemmaster_form_mode'))

        if (localStorage.getItem('itemmaster_form_mode') === 'update') {
            getItemmasterDetails()
        } else {
            getItemIdData();
        }
    }, [])

    const getItemmasterDetails = async () => {
        var response = await getAxios({
            url: itemMasterEndPoint + localStorage.getItem('itemmaster_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                item_id: response.data?.item_id,
                metal: response.data?.metal,
                purity: response.data?.purity,
                hsn_code: response.data?.hsn_code,
                huid_rate: response.data?.huid_rate,
                stock_type: response.data?.stock_type,
                item_code: response.data?.item_code,
                item_name: response.data?.item_name,
                item_image: response.data?.item_image,
                allow_zero_weight: response.data?.allow_zero_weight,
                calculation_type: response.data?.calculation_type,
                fixed_rate: response.data?.fixed_rate,
                per_gram_rate: response.data?.per_gram_rate,
                item_counter: response.data?.item_counter,
                wastage_calculation: response.data?.wastage_calculation,
                wastage_percent: response.data?.wastage_percent,
                flat_wastage: response.data?.flat_wastage,
                making_charge_calculation: response.data?.making_charge_calculation,
                making_charge_gram: response.data?.making_charge_gram,
                flat_making_charge: response.data?.flat_making_charge,
            }
            setItemId(response.data?.item_id)
            setSelectedCalc(response.data?.calculation_type)
            setCurrentItemmaster(tempDetails)
            getPurityDropdownData(response.data?.metal)
        }
    }

    const getMetalDropdownData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropdown(response.data.list)
        }
    }

    const getPurityDropdownData = async (id) => {
        var response = await getAxios({
            url: purityListEndPoint + id + "/",
        })

        if (response !== null) {
            setPurityDropdown(response.data.list)
        }
    }

    const getStockTypeDropdownData = async () => {
        var response = await getAxios({
            url: stocktypeDropdownEndPoint
        })

        if (response !== null) {
            setStockTypeDropdown(response.data.list)
        }
    }

    const getCalculationTypeDropdownData = async () => {
        var response = await getAxios({
            url: calculationtypeDropdownEndPoint

        })

        if (response !== null) {
            setCalculationTypeDropdown(response.data.list)
        }
    }

    const getItemCounterDropdownData = async () => {
        var response = await getAxios({
            url: counterListEndPoint

        })

        if (response !== null) {
            setItemCounterDropdown(response.data.list)
        }
    }

    const handleCheckboxChange = (e) => {
        e.preventDefault();
        setCurrentItemmaster({ ...currentItemmaster, allow_zero_weight: e.target.checked })
        setAllowZeroWeight(e.target.checked);
    };

    const onSubmit = async (data) => {
        if (localStorage.getItem('itemmaster_form_mode') === 'create') {
            data['item_id'] = itemid;

            if (imageFile !== "") {
                var imageResponse = await postAxiosWithToken({
                    url: itemUploadImgEndPoint,
                    body: new FormData(document.getElementById('imageupload'))
                })

                if (imageResponse !== null) {
                    data['item_image'] = imageResponse.item_image
                } else {
                    data['item_image'] = ''
                }
            } else {
                data['item_image'] = ''
            }

            createItemmaster(data)

        } else {

            if (currentItemmaster.item_image) {
                if (imageFile !== "") {
                    var tempImageSplit = currentItemmaster.item_image.split("/")

                    var imageResponse = await putAxiosWithToken({
                        url: itemUploadImgEndPoint + tempImageSplit[tempImageSplit.length - 1] + "/",
                        body: new FormData(document.getElementById('imageupload'))
                    })

                    if (imageResponse !== null) {
                        data['item_image'] = imageResponse.item_image_url
                    } else {
                        data['item_image'] = ''
                    }
                } else {
                    data['item_image'] = currentItemmaster.item_image
                }
            } else {
                if (imageFile !== "") {
                    var imageResponse = await postAxiosWithToken({
                        url: itemUploadImgEndPoint,
                        body: new FormData(document.getElementById('imageupload'))
                    })

                    if (imageResponse !== null) {
                        data['item_image'] = imageResponse.item_image_url
                    } else {
                        data['item_image'] = ''
                    }
                } else {
                    data['item_image'] = ''
                }
            }

            updateItemmaster(data)
        }
    }

    const createItemmaster = async (body) => {
        var response = await postAxiosWithToken({
            url: itemMasterEndPoint,
            body: body

        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/productmaster/itemmaster')
        }
    }

    const updateItemmaster = async (body) => {
        var response = await putAxiosWithToken({
            url: itemMasterEndPoint + localStorage.getItem('itemmaster_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('itemmaster_form_id')
            localStorage.setItem('itemmaster_form_mode', 'create')
            toast.success(response.message)
            navigate('/productmaster/itemmaster')
        }
    }

    const clearData = () => {
        reset()
        setCurrentItemmaster({
            item_id: "",
            metal: "",
            purity: "",
            hsn_code: "",
            stock_type: "",
            item_code: "",
            item_name: "",
            item_image_url: "",
            allow_zero_weight: "",
            calculation_type: "",
            fixed_rate: "",
            per_gram_rate: "",
            item_counter: "",
            wastage_calculation: "",
            wastage_percent: "",
            flat_wastage: "",
            making_charge_calculation: "",
            making_charge_gram: "",
            flat_making_charge: "",
        })
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Item</p>
                <form id="imageupload" hidden>
                    <input onChange={(e) => { e.preventDefault(); setImageFile(e.target.value.split('\\')[e.target.value.split('\\').length - 1]) }} type="file" name="image" id="itemimage" />
                </form>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Item Details</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Item Id <span className="text-red-500">*</span></p>
                                    <input
                                        type="text"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="item_id"
                                        value={itemid}
                                        {...register('item_id')}
                                        disabled />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors?.item_id?.message}</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Metal</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                        name="metal"
                                        {...register('metal')}
                                        onChange={(e) => { getPurityDropdownData(e.target.value) }}
                                    >
                                        <option value={""}>--select metal--</option>
                                        {
                                            metalDropdown?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.metal_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.metal?.message}</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Purity</p>
                                    <select
                                        placeholder="purity name"
                                        className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                        name="purity"
                                        {...register('purity')}
                                        value={currentItemmaster.purity}
                                        onChange={(e) => {
                                            setCurrentItemmaster((prevState) => ({
                                                ...prevState,
                                                purity: e.target.value
                                            }))
                                        }}
                                    >
                                        <option value={""}>--select Purity--</option>
                                        {
                                            purityDropdown?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.purity_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.purity?.message}</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">HSN Code <span className="text-red-500">*</span></p>
                                    <input
                                        type="text"
                                        placeholder="HSN Code"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="hsn_code"
                                        {...register('hsn_code')}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.hsn_code?.message}</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">HUID Rate <span className="text-red-500">*</span></p>
                                    <input
                                        type="text"
                                        placeholder="HUID Rate"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="huid_rate"
                                        {...register('huid_rate')}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.huid_rate?.message}</p>
                                </div>
                                <div className="my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Stock Type</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg p-[11px] text-[17px] outline-none"
                                        name="stock_type"
                                        {...register('stock_type')}
                                        onChange={(e) => setSelectedStockType(e.target.value)}
                                    >
                                        <option value={""}>--select Stock Type--</option>
                                        {
                                            stocktypeDropdown?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.stock_type_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.stock_type?.message}</p>
                                </div>


                                {/* </div> */}

                                {/* </div> */}
                                {/* <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg"> */}
                                {/* <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-5"> */}

                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Item Short Code <span className="text-red-500">*</span></p>
                                    <input
                                        type="text"
                                        placeholder="Item Short Code "
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="item_code"
                                        {...register('item_code')}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.item_code?.message}</p>
                                </div>
                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Item Name <span className="text-red-500">*</span></p>
                                    <input
                                        type="text"
                                        placeholder="Item Name "
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="item_name"
                                        {...register('item_name')}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.item_name?.message}</p>
                                </div>


                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[16px]">Item Counter</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg p-[11px] text-[17px] outline-none"
                                        name="item_counter"
                                        {...register('item_counter')}
                                    >
                                        <option value={""}>--select Counter--</option>
                                        {
                                            itemcounterDropdown?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.counter_name}({option.branch_name})</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.item_counter?.message}</p>
                                </div>
                                <div className="my-[5px] flex items-center gap-5 xl:mt-7">{console.log(allowZeroWeight)}
                                    <input
                                        type="checkbox"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                                        name="allow_zero_weight"
                                        {...register('allow_zero_weight')}
                                        checked={allowZeroWeight}
                                        onChange={handleCheckboxChange}
                                    />
                                    <p className="mb-[5px] text-[16px]">Allow Zero Weight</p>
                                    {/* <p className="text-red-500 font-[400] text-[13px]">{currentAccountheadErrors.diamond_seller}</p> */}
                                </div>
                            </div>
                            <div>
                                <div className="sm:my-[5px] ">

                                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input" value={itemimage} name="item_image_url">Item Image Upload</label>
                                    {
                                        formMode === 'update' ? <div className="flex items-center">
                                            <p className="text-[12px]">{imageFile.length <= 0 ? currentItemmaster.item_image : imageFile}</p>
                                            <label className="" htmlFor="itemimage" >
                                                <div className="border w-[50px] h-[45px] rounded-lg p-[10px] hover">Edit</div>
                                            </label>
                                        </div> :
                                            <label className="" htmlFor="itemimage" >
                                                <div>
                                                    <div className="border-[2px] border-dashed max-w-[130px] h-[150px] flex justify-center px-2 py-5">
                                                        {imageFile.length > 0 ? imageFile :
                                                            <div>
                                                                <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#B6B6B6" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm0-2h14V5H5zm1-2h12l-3.75-5l-3 4L9 13zm-1 2V5z" /></svg>
                                                                <p className="text-[#3F80CC] text-[12px] font-[400] px-[15px] py-1 bg-[#3f80cc61] rounded-md mt-6">Add Image</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div className="border w-full h-[45px] rounded-lg p-[10px]">{imageFile.length > 0 ? imageFile : "Upload"}
                                            </div> */}
                                            </label>
                                    }

                                    {/* <p className="text-red-500 font-[400] text-[13px]">{errors.item_image_url?.message}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="bg-menu-head-bg  border rounded-xl mt-5 p-[15px]">

                        <p className="font-[600] text-[18px] text-menu-head mb-4 capitalize">Charges Calculation</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="sm:my-[5px]">
                                    <p className="mb-[5px] text-[16px]">Calculation Type</p>
                                    <select
                                        placeholder="metal name"
                                        className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg p-[11px] text-[17px] outline-none"
                                        name="calculation_type"
                                        {...register('calculation_type')}
                                        value={selectedCalc}
                                        onChange={(e) => setSelectedCalc(e.target.value)}
                                    >
                                        <option value={""}>--select Calculation Type--</option>
                                        {
                                            calculationtypeDropdown?.map((option) => (
                                                selectedStockType.toString() === packetStockType.toString() ? 
                                                option.id.toString() === fixedCalcId.toString() && <option key={option.id} value={option.id}>{option.calculation_name}</option> : 
                                                <option key={option.id} value={option.id}>{option.calculation_name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.calculation_type?.message}</p>
                                </div>
                            </div>
                            <div></div>
                        </div>

                        {/* fixed rate st*/}
                        <div className={`${selectedCalc.toString() === fixedCalcId.toString() ? "block" : "hidden"} mt-5`}>
                            {/* <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Calculation Value</p> */}
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Fixed Rate Value<span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Fixed Rate Value"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="fixed_rate"
                                            {...register('fixed_rate')}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.fixed_rate?.message}</p>
                                    </div>
                                </div>
                                <div></div>

                            </div>
                        </div>

                        {/* fixed rate end*/}


                        {/* pergram rate st*/}
                        <div className={`${selectedCalc.toString() === pergramCalId.toString() && selectedStockType.toString() !== packetStockType.toString() ? "block" : "hidden"} mt-5`}>
                            {/* <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Per Gram Rate</p> */}
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Per Gram Rate<span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Per Gram Rate"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="per_gram_rate"
                                            {...register('per_gram_rate')}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.per_gram_rate?.message}</p>
                                    </div>
                                </div>
                                <div></div>

                            </div>
                        </div>

                        {/* pergram rate end*/}


                        {/* weight calculation st*/}
                        <div className={`${selectedCalc.toString() === weightCalId.toString() && selectedStockType.toString() !== packetStockType.toString() ? "block" : "hidden"} mt-5`}>
                            {/* <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Charges Calculation</p> */}
                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Wastage Calculation</p>
                                        <select
                                            placeholder="metal name"
                                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg p-[11px] text-[17px] outline-none"
                                            name="wastage_calculation"
                                            {...register('wastage_calculation')}
                                        >
                                            <option value={""}>--select metal--</option>
                                            {
                                                wighttype?.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.weight_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.wastage_calculation?.message}</p>
                                    </div>
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Wastage % <span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Wastage % "
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="wastage_percent"
                                            {...register('wastage_percent')}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.wastage_percent?.message}</p>
                                    </div>

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Flat Wastage (₹) <span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Flat Wastage (₹)"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="flat_wastage"
                                            {...register('flat_wastage')}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.flat_wastage?.message}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                <div className="col-span-3 grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Makeing Charges Calculations</p>
                                        <select
                                            placeholder="metal name"
                                            className="sm:mb-[5px] bg-white border w-full border-gray-300 rounded-lg p-[11px] text-[17px] outline-none"
                                            name="making_charge_calculation"
                                            {...register('making_charge_calculation')}
                                        >
                                            <option value={""}>--select metal--</option>
                                            {
                                                wighttype?.map((option) => (
                                                    <option key={option.id} value={option.id}>{option.weight_name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.making_charge_calculation?.message}</p>
                                    </div>

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">MC / gram(₹)<span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="MC / gram(₹)"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="making_charge_gram"
                                            {...register('making_charge_gram')}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.making_charge_gram?.message}</p>
                                    </div>

                                    <div className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">Flac MC (₹) <span className="text-red-500">*</span></p>
                                        <input
                                            type="text"
                                            placeholder="Flac MC (₹)"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name="flat_making_charge"
                                            {...register('flat_making_charge')}
                                        />
                                        <p className="text-red-500 font-[400] text-[13px]">{errors.flat_making_charge?.message}</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                        {/* weight calculation end*/}

                    </div>




                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}