import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { convertToDate } from '../../../utilities/datetime';
import { addDays } from 'date-fns/esm';
import { branchListEndPoint, counterListEndPoint } from '../../../service/api';
import { getAxios } from '../../../service/apiservice';

export default function CounterTargetFilters({ filters, setFilters, }) {

    const [branchList, setBranchList] = useState([]);
    const [counterList, setCounterList] = useState([]);

    useEffect(() => {
        getBranchDropdownData();
        getCounterDropDownData();
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint,
        });

        if (response !== null) {
            setBranchList(response.data.list);
        }
    };

    const getCounterDropDownData = async () => {
        var response = await getAxios({
            url: counterListEndPoint
        })

        if (response !== null) {
            setCounterList(response.data.list)
        }
    }

    const handleCounterFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            counter: value === "all" ? null : value
        }))
    }

    const handleBranchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            branch: value === "all" ? null : value
        }))
    }
    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full">
                <div className='sm:flex lg:gap-3 w-full '>
                    <div className=''>
                        <select
                            placeholder="counter"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                            name="counter"
                            value={filters.counter}
                            onChange={(e) => handleCounterFilter(e.target.value)}
                        >
                            <option value="all">All Counter</option>
                            {counterList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                    {option?.counter_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {localStorage.getItem("branch") === "true" &&
                        <div className=''>
                            <select
                                placeholder="metal_type"
                                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                                name="branch"
                                value={filters.branch}
                                onChange={(e) => handleBranchFilter(e.target.value)}
                            >
                                <option value="all">All Branch</option>
                                {branchList?.map((option) => (
                                    <option key={option?.id} value={option?.id}>
                                        {option?.branch_name}
                                    </option>
                                ))}
                            </select>
                        </div>}
                </div>
            </div>
        </>
    );
}