import { useEffect, useRef, useState } from "react";
import { branchListEndPoint, calculationtypeDropdownEndPoint, tagNumbertypeEndPoint, tagdetailsEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import Popup from "../../../components/Popup";
import StoneForm from "./stoneform";
import DiamondForm from "./diamondform";
import { json, useNavigate } from "react-router-dom";
import { fixedCalcId, pergramCalId, weightCalId } from "../../../constant";
import { datetimetodate } from "../../../utilities/datetime";
import { Printer } from "react-thermal-printer";
import Select from 'react-select';
import ReactToPrint from "react-to-print";
import Tagprint from "../../../components/Printouts/tagprint";


export default function TagdetailsForm() {

    let componentRef = useRef();

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [calculationtypeDropdown, setCalculationTypeDropdown] = useState([]);
    const [tagNumberDropdown, setTagNumberDropdown] = useState([]);

    const [wighttype, setWightType] = useState([]);
    const [selectedCalc, setSelectedCalc] = useState('');

    const [stonePopup, setStonePopup] = useState(false);
    const [diamondPopup, setDiamondPopup] = useState(false);
    const [removedStones, setRemovedStones] = useState([]);
    const [stoneList, setStoneList] = useState([]);
    const [diamondList, setDiamondList] = useState([]);

    const [selectedTagNumber, setSelectedTagNumber] = useState();
    const [additionalData, setAdditionalData] = useState({});

    console.log("additionalData", additionalData)

    const [options, setOptions] = useState([]);
    const [branchDropdown, setBranchDropDown] = useState([]);

    const [printView, setPrintView] = useState(false)


    useEffect(() => {

        getCalculationTypeDropdownData();

        if (localStorage.getItem('branch') == 'true') {
            getBranchDropdownData();
        } else {
            getTagNumberDropdownData(1)
        }
    }, [])


    const closeStoneModal = () => {
        setStonePopup(!stonePopup)

    }

    const closeDiamondModal = () => {
        setDiamondPopup(!diamondPopup)
    }


    function closePrintModal() {
        setPrintView(false)
    }



    const getCalculationTypeDropdownData = async () => {
        var response = await getAxios({
            url: calculationtypeDropdownEndPoint

        })

        if (response !== null) {
            setCalculationTypeDropdown(response.data.list)
        }
    }

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response !== null) {
            setBranchDropDown(response.data.list)
        }
    }

    const getTagNumberDropdownData = async (id) => {
        var response = await getAxios({
            url: tagNumbertypeEndPoint + id + '/'

        })

        if (response !== null) {

            setTagNumberDropdown(response.data.list)

            let tempList = [];
            for (var i of response.data.list) {
                var tempDict = {
                    value: i.tag_number,
                    label: i.tag_number
                };
                tempList.push(tempDict);
            }
            setOptions(tempList)
        }
    }


    const handleTagNumberChange = async (selectedTagNumber) => {
        setSelectedTagNumber(selectedTagNumber);
        datetimetodate(selectedTagNumber);

        try {
            const response = await postAxiosWithToken({
                url: tagdetailsEndPoint,
                body: {
                    tag_number: selectedTagNumber
                }
            });

            if (response !== null) {

                setAdditionalData(response.data);
                setStoneList(response.data.stone_details)
                setDiamondList(response.data.diamond_details)

            }

        } catch (error) {
            console.error('Error fetching additional data:', error);
        }

    };




    const [selectedTag, setSelectedTag] = useState()






    return (
        <>
            <div>
                <div className="">
                    <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize"> Tag details</p>
                </div>

                <div className="flex">
                    <div className={`flex items-center w-full justify-between  gap-4 sm:mt-0 xl:mt-[17px] px-[10px] mb-[25px] `}>
                        <div className="flex gap-[15px]">
                            {localStorage.getItem("branch") === "true" &&
                                <div className="">
                                    <p className="mb-[5px] text-[16px]">Branch Name</p>
                                    <select
                                        placeholder="branch name"
                                        className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[9px] text-[17px] outline-none"
                                        name="branch"
                                        // {...register('branch')}
                                        onChange={(e) => {
                                            if (e.target.value !== "") {
                                                getTagNumberDropdownData(e.target.value)
                                            } else {
                                                setOptions([])
                                            }
                                        }}
                                    >
                                        <option value={""} className="capitalize">--select branch name--</option>
                                        {
                                            branchDropdown.map((option) => (
                                                <option className="capitalize" key={option.id} value={option.id}>{option.branch_name}</option>
                                            ))
                                        }
                                    </select>
                                    {/* <p className="text-red-500 font-[400] text-[13px]">{errors.branch_name?.message}</p> */}
                                </div>}
                            <div className="sm:my-[5px]">
                                <p className="mb-[5px] text-[15px]">tag Number</p>

                                <Select
                                    options={options}
                                    isSearchable
                                    onChange={(e) => handleTagNumberChange(e.value)}
                                    className="w-[300px]"
                                />
                                <p className="text-red-500 font-[400] text-[13px]"></p>
                            </div>
                        </div>
                        <div className="flex gap-[10px]">
                            <button
                                onClick={() => {
                                    handleTagNumberChange(selectedTagNumber);
                                    setPrintView(true);
                                }}
                                className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >print</button>
                            <button
                                onClick={(e) => { e.preventDefault(); setStonePopup(!stonePopup) }}
                                className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >View Stone</button>
                            <button
                                onClick={(e) => { e.preventDefault(); setDiamondPopup(!diamondPopup) }}
                                className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                            >View Diamond</button>
                        </div>
                    </div>
                </div>
                <form id="tagdetailsform" >

                    <div className="grid xl:grid-cols-5 sm:grid-cols-3 gap-5 border shadow-lg p-[15px] rounded-lg mb-[10px] bg-[white]">

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Tag Date</p>
                            {additionalData.tagged_date ? (
                                <p className="text-primary-btn font-[400] text-[15px]">
                                    {datetimetodate(additionalData.tagged_date)}
                                </p>
                            ) : (
                                <p className="text-error"></p>
                            )}
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Lot Number</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.lot_number}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Entry Type</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.entry_type}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Tag Type</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.tag_type}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Stock Type</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.stock_type_name}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Ref Invoice Number</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.invoice_number}</p>
                        </div>



                    </div>


                </form>

                <form id="itemform">
                    <div className="sm:flex w-full gap-4">
                        <div className="w-full bg-white mb-[25px]">

                            <div className="border rounded-md shadow-md w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Item Name</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.item_name}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Sub Item Name</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.sub_item_name}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Assigned Counter</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.assigned_counter}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Pieces</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.pieces}</p>
                                </div>

                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Item Age</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.item_age}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Item Size</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.size}</p>
                                </div>

                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Tag Wt.</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.tag_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">cover wt.</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.cover_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Loop Wt.</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.loop_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Other wt.</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.other_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Gross wt.</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.gross_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Net Wt.</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.net_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Remaining Pieces</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.remaining_pieces}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Remaining Gross Weight</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.remaining_gross_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Remaining Net Weight</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.remaining_net_weight}</p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-[5px] text-[14px]">Is Billed</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">
                                        {additionalData.is_billed === false ? "No" : additionalData.is_billed === true ? "Yes" : ""}
                                    </p>
                                </div>
                                <div className="w-full ">
                                    <p className="mb-[5px] text-[14px]">Calculation Type</p>
                                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.calculation_type_name}</p>
                                </div>
                            </div>

                        </div>



                    </div>

                </form>

                {/* fixed_rate */}
                <div className={`${additionalData.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-[white]`}>

                    <div className="  w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Fixed Rate</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_fixed_rate}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_sale_value}</p>
                        </div>

                    </div>




                </div>

                <div className={`${additionalData.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-[white]`}>

                    <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Fixed Rate</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_fixed_rate}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_sale_value}</p>
                        </div>
                    </div>



                </div>
                {/* fixed_rate */}

                {/* wastage_calculation */}
                <div className={`${additionalData.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-[white]`}>

                    <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Wastage %</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_wastage_percent}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Flat Wastage</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_flat_wastage}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Mc/G</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_making_charge_gram}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]"> Min Flat Mc</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_flat_making_charge}</p>
                        </div>

                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_sale_value}</p>
                        </div>
                    </div>


                </div>
                {console.log(additionalData.calculation_type)}

                <div className={`${additionalData.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-[white]`}>

                    <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Wastage %</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_wastage_percent}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Flat Wastage</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_flat_wastage}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Mc/G</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_making_charge_gram}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]"> Max Flat Mc</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_flat_making_charge}</p>
                        </div>

                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_sale_value}</p>
                        </div>
                    </div>


                </div>
                {/* wastage_calculation */}




                {/* pergram */}
                <div className={`${additionalData.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-[white]`}>

                    <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Pergram Rate</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_pergram_rate}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Per Gram Weight Type</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.per_gram_weight_type_name}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_sale_value}</p>
                        </div>

                    </div>



                </div>

                <div className={`${additionalData.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg bg-[white]`}>

                    <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Pergram Rate</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_pergram_rate}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Per Gram Weight Type Name</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.per_gram_weight_type_name}</p>
                        </div>
                        <div className="w-full">
                            <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                            <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_sale_value}</p>
                        </div>


                    </div>



                </div>
                {/* pergram */}

            </div>

            <Popup
                title={`${formMode} Stone`}
                extraClassName="min-w-full"
                setIsOpen={setStonePopup}
                isOpen={stonePopup}
                closeModal={closeStoneModal}
            >
                <StoneForm
                    closeModal={closeStoneModal}
                    dataList={stoneList}
                    setDataList={setStoneList}
                    setRemovedStone={setRemovedStones}
                    additionalData={additionalData}
                />

            </Popup>

            <div className="w-full">
                <Popup title={`${formMode} Diamond`} extraClassName="min-w-full" setIsOpen={setDiamondPopup} isOpen={diamondPopup} closeModal={closeDiamondModal}>
                    <DiamondForm
                        closeModal={closeDiamondModal}
                        dataList={diamondList}
                        setDataList={setDiamondList} />
                </Popup>

                <Popup extraClassName="min-w-[70%] h-[600px] overflow-y-scroll" title={'Tag Details'} setIsOpen={setPrintView} isOpen={printView} closeModal={closePrintModal}>
                    <div className="text-end pr-5">
                        <ReactToPrint
                            trigger={() => <button id="Print" className="capitalize bg-secondary text-black w-[150px] rounded-lg p-[7px] h-fit font-[400] text-[12px] outline-none border-none">
                                Print (ctrl+p)
                            </button>}
                            content={() => componentRef}
                        />
                    </div>
                    <div className="">
                        <div className="mb-[25px]" ref={(el) => (componentRef = el)}>
                            <Tagprint
                                additionalData ={additionalData}

                            />
                        </div>
                    </div>

                </Popup >
            </div>


            
        </>
    );
}