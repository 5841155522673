import { itemMasterEndPoint, subitemEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { fixedCalcId, pergramCalId, weightCalId } from "../../../constant";

export default function ViewSubItemDetails() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('View');
    const [currentSubItemMaster, setCurrentSubItemmaster] = useState({});
    const [itemDetails, setItemDetails] = useState({});

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {

        getSubItemDetails()
    }, [])

    const getSubItemDetails = async () => {
        var response = await getAxios({
            url: subitemEndPoint + localStorage.getItem('subitem_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {

                metal: response.data.subitem_details.metal_name,
                purity: response.data.subitem_details.purity_name,
                item_id: response.data.subitem_details.item_details.item_id,
                item_code: response.data.subitem_details.item_details.item_code,
                item_name: response.data.subitem_details.item_details.item_name,
                hsn_code: response.data.subitem_details.item_details.hsn_code,
                stock_type: response.data.subitem_details.item_details.stock_type__stock_type_name,
                sub_item_code: response.data.subitem_details.sub_item_code,
                sub_item_name: response.data.subitem_details.sub_item_name,
                sub_item_id: response.data.subitem_details.sub_item_id,
                subitem_hsn_code: response.data.subitem_details.subitem_hsn_code,
                sub_item_counter: response.data.subitem_details.sub_item_counter,
                allow_zero_weight: response.data.subitem_details.allow_zero_weight,
                measurement_type: response.data.subitem_details.measurement_type,
                counter_name: response.data.subitem_details.sub_item_counter_name,
                measurement_type_name: response.data.subitem_details.measurement_type_name,
                sub_item_image: response.data.subitem_details.sub_item_image,
                calculation_type: response.data.subitem_details.calculation_type,
                calculation_type_name: response.data.subitem_details.calculation_type_name,
                fixed_rate: String(response.data.subitem_details.calculation_type) === String(fixedCalcId) ? response.data.calculation_details.fixed_rate : "",
                per_gram_rate: String(response.data.subitem_details.calculation_type) === String(pergramCalId) ? response.data.calculation_details.per_gram_rate : "",
                wastage_percent: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_percent : "",
                flat_wastage: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.flat_wastage : "",
                making_charge_gram: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_gram : "",
                flat_making_charge: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.flat_making_charge : "",
                wastage_calculation: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_calculation : "",
                making_charge_calculation: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_calculation : "",
                wastage_calculation_name: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.wastage_calculation_name : "",
                making_charge_calculation_name: String(response.data.subitem_details.calculation_type) === String(weightCalId) ? response.data.calculation_details.making_charge_calculation_name : "",

            }

            setCurrentSubItemmaster(tempDetails)

        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Sub Item Details</p>
                <form>


                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Sub Item Details</p>
                        <div className="grid min-[2000px]:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 min-[500px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Metal</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.metal}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Purity</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.purity}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Id</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.item_id}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Short Code</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.item_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.item_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Item HSN</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.hsn_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Stock Type</p>
                                <p className="text-primary-btn font-[400] text-[15px] hover:underline">{currentSubItemMaster.stock_type}</p>
                            </div>

                        </div>
                    </div>
                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg mt-5">
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Sub Item Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 min-[500px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Sub Item Name</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.sub_item_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Sub Item Code</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.sub_item_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Sub Item Id</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.sub_item_id}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Counter</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.counter_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Sub Item HSN</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.hsn_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Image</p>
                                <Link to={currentSubItemMaster.sub_item_image} target="_blank" className="text-primary-btn font-[400] text-[15px]">view</Link>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Measurement Type</p>
                                <p className="text-primary-btn font-[400] text-[15px] hover:underline">{currentSubItemMaster.measurement_type_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Allow Zero Weight</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.allow_zero_weight ? "Yes" : "No"}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Calculation Type</p>
                                <p className="text-primary-btn font-[400] text-[15px] hover:underline">{currentSubItemMaster.calculation_type_name}</p>
                            </div>

                        </div>
                    </div>

                    <div className={`${currentSubItemMaster?.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg  bg-menu-head-bg`}>
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Calculation Value</p>
                        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Fixed Value</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.fixed_rate}</p>
                            </div>

                        </div>
                    </div>

                    <div className={`${currentSubItemMaster?.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg  bg-menu-head-bg`}>
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Per Gram Rate</p>
                        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Per Gram Rate</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.per_gram_rate}</p>
                            </div>

                        </div>
                    </div>

                    <div className={`${currentSubItemMaster?.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"} border p-[15px] mt-[15px] rounded-xl shadow-lg  bg-menu-head-bg`}>
                        <p className="font-[600] text-[17px]  border-b mb-4 pb-2">Calculation Value</p>
                        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 min-[500px]:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Wastage Calculation</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.wastage_calculation_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Wastage %</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.wastage_percent}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Flat Wastage (g)</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.flat_wastage}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Makeing Charges Calculations</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.making_charge_calculation_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">MC / gram(₹)</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.making_charge_gram}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Flac MC (₹)</p>
                                <p className="text-primary-btn font-[400] text-[15px]">{currentSubItemMaster.flat_making_charge}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}