import { useEffect, useState } from "react";
import { branchListEndPoint, getOldGoldListEndPoint, metalListEndPoint, oldMetalCategoryListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { isValidForm } from "../../../utilities/formvalidation";

export default function FilterForm({ setParticularList, formValues, setFormValues }) {

    const [metalDropDown, setMetalDropDown] = useState([]);
    const [branchDropDown, setBranchDropDown] = useState([]);

    const [errors, setErrors] = useState({})

    useEffect(() => {
        getMetalListData()
        getBranchDropDownList()
    },[])

    const getMetalListData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropDown(response.data.list)
        }
    }


    const getBranchDropDownList = async () => {
        var response = await getAxios({
            url: branchListEndPoint
        })

        if (response != null) {
            setBranchDropDown(response.data.list)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var formData = new FormData(e.target);

        var err = {}

        for (var field of Object.entries(Object.fromEntries(formData.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isValidForm(Object.fromEntries(formData.entries()))) {
            getOldMetalList(formValues)
        }
    }

    const getOldMetalList = async (data) => {
        var response = await postAxiosWithToken({
            url: getOldGoldListEndPoint,
            body: data
        })

        if (response != null) {
            setParticularList(response.data.list)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }))

        setErrors((prevState) => ({
            ...prevState,
            [name]: value.length > 0 ? "" : `${name} field is required`
        }))
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-3 gap-x-5">
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">From Date <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="From Date"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="from_date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={formValues.from_date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">{errors.from_date}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">To Date <span className="text-red-500 ">*</span></p>
                    <input
                        type="date"
                        placeholder="To Date"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                        name="to_date"
                        value={formValues.to_date}
                        onChange={onValueUpdate}
                    />
                    <p className="text-red-500 font-[400] text-[13px]">{errors.to_date}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Metal <span className="text-red-500 ">*</span></p>
                    <select
                        placeholder="Purchase Category"
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                        name="metal"
                        value={formValues.metal}
                        onChange={onValueUpdate}
                    >
                        <option value={""}>--select metal-</option>
                        {
                                metalDropDown.map((option) => (
                                    <option key={option.id} value={option.id}>{option.metal_name}</option>
                                ))
                            }
                    </select>
                    <p className="text-red-500 font-[400] text-[13px]">{errors.metal}</p>
                </div>

                {
                    localStorage.getItem('branch') == 'true' && <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Branch <span className="text-red-500">*</span></p>
                        <select
                            placeholder="Branch"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] bg-white outline-none"
                            name="branch"
                            value={formValues.branch}
                            onChange={onValueUpdate}
                        >
                            <option value="">--select branch--</option>
                            {
                                branchDropDown.map((item, index) => (
                                    <option value={item.id} key={'branch' + index}>{item.branch_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.branch}</p>
                    </div>
                }

                <div className="mt-[20px] flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        onClick={(e) => { e.preventDefault(); }}
                        className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                    >reset</button>
                    <button
                        type="submit"
                        className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >search</button>
                </div>
            </div>
        </form>
    );
}