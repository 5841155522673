import { useEffect, useRef, useState } from "react";
import { postAxiosWithToken } from "../../../service/apiservice";
import { itemStockreportListEndPoint } from "../../../service/api";

import { useKeyPress } from "../../../hooks/useKeyPress";
import TagreportFilters from "./tagreportfilter";
import { useSelector } from "react-redux";
import { ExportToExcel } from "../../../components/ExportToExcel";
import Pagination from "../../../components/Pagination";
import ExcelViewer from "../../../components/ExcelViewer";

export default function Tagreport() {

    const searchRef = useRef();

    const [tagreportList, setTagreportList] = useState([]);
    const [filteredTagreportList, setFilteredTagreportList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [totalPages, setTotalPages] = useState(1);

    const [excelOpen, setExcelOpen] = useState(false);

    const [excelViewData, setExcelViewData] = useState([]);

    const { pagination } = useSelector((state) => state.appConfig);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const fileName = "Tag Report"

    function closeExcelModal() {
        setExcelOpen(false)
    }

    useEffect(() => {
        getTagreportListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    useEffect(() => {
        if (tagreportList.length > 0) {
            let firstData = Object.keys(tagreportList[0])
            let row = []
            for (var i of firstData) {
                row.push({
                    value: i
                })
            }
            setExcelViewData(row)
        }
    }, [tagreportList])

    const getTagreportListData = async (body) => {
        var response = await postAxiosWithToken({
            url: itemStockreportListEndPoint,
            body: body
        })

        if (response !== null) {
            setTagreportList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }


    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };



    return (
        <>
            <div>
                <div className="flex items-center justify-between">
                    <p className="text-[24px] font-[700]">
                        Tag Report
                    </p>
                    <div className="flex gap-3 items-center">
                        <ExcelViewer title={fileName} data={tagreportList} headers={excelViewData} enabled={excelOpen} setEnabled={setExcelOpen} closeModal={closeExcelModal} />
                        <ExportToExcel apiData={filteredTagreportList} fileName={fileName} />
                    </div>
                </div>
                {/* {
                    formMode === 'create' ? actions.addPermit ? <div>
                        <TagreportForm getData={getTagreportListData} formMode={formMode} setFormMode={setFormMode} currentTagreport={currentTagreport} setCurrentTagreport={setCurrentTagreport} />
                    </div> : '' : actions.editPermit ? <div>
                        <TagreportForm getData={getTagreportListData} formMode={formMode} setFormMode={setFormMode} currentTagreport={currentTagreport} setCurrentTagreport={setCurrentTagreport} />
                    </div> : ''
                } */}
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <TagreportFilters filters={tableFilters} setFilters={setTableFilters} searchRef={searchRef} getData={getTagreportListData} dataList={tagreportList} setFilteredData={setFilteredTagreportList} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Tag Number</td>
                                <td className="p-[10px] min-w-[150px]">Gross Wt</td>
                                <td className="p-[10px] min-w-[150px]">Net Wt</td>

                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-menu-head-bg">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={10}>Loading...</td>
                                </tr> :
                                    tagreportList.length === 0 ? <tr><td className="text-center border" colSpan={10}>No Data</td></tr> :
                                        tagreportList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.tag_number}</td>
                                                <td className="p-[10px]">{item.gross_weight}</td>
                                                <td className="p-[10px]">{item.net_weight}</td>

                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>

        </>
    );
}