import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fixedCalcId, pergramCalId, weightCalId } from "../../constant";
import { datetimetodate } from "../../utilities/datetime";

export default function Tagprint({ additionalData }) {

    const { todayGoldRate, todaySilverRate } = useSelector((state) => state.appConfig);

    const [particulars, setParticulars] = useState([]);
    const [oldGold, setOldGold] = useState([]);

    console.log(additionalData, "additionalData")



    return (
        <div className="   max-w-full">
           
           <p className="font-[700] text-[14px] text-center pt-5 px-5">Tag Details</p>
            <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 min-[500px]:grid-cols-2  p-[20px] mt-[15px]  gap-5">
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">tag date</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{datetimetodate(additionalData.tagged_date)}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Lot Number</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.lot_number}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Entry Type</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.entry_type}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Tag Type</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.tag_type}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Stock Type Name</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.stock_type_name}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Invoice Number</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.invoice_number}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Item Name</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.item_name}</p>
                </div><div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Sub Item Name</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.sub_item_name}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Assigned Counter</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.assigned_counter}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Pieces</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.pieces}</p>
                </div><div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Item Age</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.item_age}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Size</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.size}</p>
                </div>

                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Tag Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.tag_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Cover Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.cover_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Loop Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.loop_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Other Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.other_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Gross Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.gross_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Net Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.net_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Remaining Pieces</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.remaining_pieces}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Remaining Gross Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.remaining_gross_weight}</p>
                </div><div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Remaining Net Weight</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.remaining_net_weight}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Is Billed</p>
                    <p className="text-primary-btn font-[400] text-[15px]"> {additionalData.is_billed === false ? "No" : additionalData.is_billed === true ? "Yes" : ""}</p>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Calculation Type Name</p>
                    <p className="text-primary-btn font-[400] text-[15px]">{additionalData.calculation_type_name}</p>
                </div>


            </div>

            {/* fixed_rate */}
            <div className={`${additionalData.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px] `}>

                <div className="  w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Fixed Rate</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_fixed_rate}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_sale_value}</p>
                    </div>

                </div>




            </div>

            <div className={`${additionalData.calculation_type?.toString() === fixedCalcId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px] `}>

                <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Fixed Rate</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_fixed_rate}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_sale_value}</p>
                    </div>
                </div>



            </div>
            {/* fixed_rate */}

            {/* wastage_calculation */}
            <div className={`${additionalData.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px] `}>

                <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Wastage %</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_wastage_percent}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Flat Wastage</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_flat_wastage}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Mc/G</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_making_charge_gram}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]"> Min Flat Mc</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_flat_making_charge}</p>
                    </div>

                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_sale_value}</p>
                    </div>
                </div>


            </div>
            {console.log(additionalData.calculation_type)}

            <div className={`${additionalData.calculation_type?.toString() === weightCalId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px] `}>

                <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Wastage %</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_wastage_percent}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Flat Wastage</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_flat_wastage}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Mc/G</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_making_charge_gram}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]"> Max Flat Mc</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_flat_making_charge}</p>
                    </div>

                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_sale_value}</p>
                    </div>
                </div>


            </div>
            {/* wastage_calculation */}




            {/* pergram */}
            <div className={`${additionalData.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px] `}>

                <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Pergram Rate</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_pergram_rate}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Per Gram Weight Type</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.per_gram_weight_type_name}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Min Sale Value</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.min_sale_value}</p>
                    </div>

                </div>



            </div>

            <div className={`${additionalData.calculation_type?.toString() === pergramCalId?.toString() ? "block" : "hidden"}  p-[15px] mt-[15px] `}>

                <div className=" w-full grid xl:grid-cols-4 sm:grid-cols-3 gap-5 p-[15px]">
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Pergram Rate</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_pergram_rate}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Per Gram Weight Type Name</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.per_gram_weight_type_name}</p>
                    </div>
                    <div className="w-full">
                        <p className="mb-[5px] text-[14px]">Max Sale Value</p>
                        <p className="text-primary-btn font-[400] text-[15px]">{additionalData.max_sale_value}</p>
                    </div>


                </div>



            </div>
            {/* pergram */}

        </div>
    );
}