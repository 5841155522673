import { useEffect, useState } from 'react';
import { branchListEndPoint } from '../../../service/api';
import { getAxios } from '../../../service/apiservice';

export default function OrderdetailsFilters({ filters, setFilters, searchRef }) {

    const [branchList, setBranchList] = useState([]);

    useEffect(() => {
        getBranchDropdownData();
    }, [])

    const getBranchDropdownData = async () => {
        var response = await getAxios({
            url: branchListEndPoint,
        });

        if (response !== null) {
            setBranchList(response.data.list);
        }
    };

    const handleSearch = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            search: value
        }))
    }

    const handleBranchFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            branch: value === "all" ? null : value
        }))
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full">
                <div className='sm:flex justify-between lg:gap-3 w-full'>
                    <div class="flex gap-3 border rounded-xl px-[10px]">
                        <div className="flex items-center pl-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            autoComplete="off"
                            id="searchfilter"
                            ref={searchRef}
                            className="py-[10px] outline-none w-[230px]"
                            placeholder="Search User roles..."
                            value={filters.search}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>

                {localStorage.getItem("branch") === "true" &&
                    <div className=''>
                        <select
                            placeholder="metal_type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                            name="branch"
                            value={filters.branch}
                            onChange={(e) => handleBranchFilter(e.target.value)}
                        >
                            <option value="all">All</option>
                            {branchList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                    {option?.branch_name}
                                </option>
                            ))}
                        </select>
                    </div>}


            </div>
        </>
    );
}