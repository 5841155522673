import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../../service/apiservice";
import { stoneEndPoint, stoneListEndPoint, stoneStatusEndPoint } from "../../../../service/api";
import SwitchButton from "../../../../components/Switch";
import { datetimetodate } from "../../../../utilities/datetime";
import { useKeyPress } from "../../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import Popup from "../../../../components/Popup";
import StoneForm from "./stoneform";
import StoneFilters from "./stonefilter";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination";

export default function Stone({ tab }) {

    const searchRef = useRef();

    const [stoneList, setStoneList] = useState([]);
    const [filteredStoneList, setFilteredStoneList] = useState([]);
    const [currentStone, setCurrentStone] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const { actions, pagination } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getStoneListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f' && tab === 6) {
            event.preventDefault();
            if (document.getElementById('stonefilteroptions').className === 'my-[25px] block') {
                document.getElementById('stonefilteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('stonefilteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    const getStoneListData = async (body) => {
        var response = await postAxiosWithToken({
            url: stoneListEndPoint,
            body: body
        })

        if (response !== null) {
            setStoneList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: stoneStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getStoneListData()
            toast.success(response.message)
        }
    }

    const deleteStone = async () => {
        var response = await deleteAxiosWithToken({
            url: stoneEndPoint + currentStone.id + '/'
        })

        if (response !== null) {
            getStoneListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <>
            <div>
                {
                    formMode === 'create' ? actions.addPermit ? <div>
                        <StoneForm tab={tab} getData={getStoneListData} formMode={formMode} setFormMode={setFormMode} currentStone={currentStone} setCurrentStone={setCurrentStone} />
                    </div> : '' : actions.editPermit ? <div>
                        <StoneForm tab={tab} getData={getStoneListData} formMode={formMode} setFormMode={setFormMode} currentStone={currentStone} setCurrentStone={setCurrentStone} />
                    </div> : ''
                }
                <div id="stonefilteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <StoneFilters filter={tableFilters} setFilters={setTableFilters} searchRef={searchRef} getData={getStoneListData} dataList={stoneList} setFilteredData={setFilteredStoneList} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Stone Name</td>
                                <td className="p-[10px] min-w-[150px]">Stone Code</td>
                                <td className="p-[10px] min-w-[150px]">Reduce Weight</td>
                                <td className="p-[10px] min-w-[150px]">Status</td>
                                <td className="p-[10px] min-w-[150px]">Created At</td>
                                <td className="p-[10px] min-w-[150px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-menu-head-bg">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    stoneList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                        stoneList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize">{item.stone_name}</td>
                                                <td className="p-[10px] ">{item.stone_code}</td>
                                                <td className="p-[10px]">{item.reduce_weight ? 'Yes' : 'No'}</td>
                                                <td className="p-[10px]">
                                                    <div onClick={() => {
                                                        if (actions.editPermit) {
                                                            onStatusChange(item.id)
                                                        } else {
                                                            toast.error("You don't have permission for update the status")
                                                        }
                                                    }}>
                                                        <SwitchButton enabled={item.is_active} />
                                                    </div>
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at)}</td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => {
                                                            if (actions.editPermit) {
                                                                setCurrentStone(item);
                                                                setFormMode('update');
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentStone(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}

            </div>
            <div>
                <Popup title={'Delete Stone'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentStone?.stone_name} stone?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteStone() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}