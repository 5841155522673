import { useEffect, useState } from "react";
import Popup from "../../../components/Popup";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { getAxios } from "../../../service/apiservice";
import { metalListEndPoint, purityListEndPoint } from "../../../service/api";


export default function OldGoldParticularsForm({ oldGoldNo, formType, dataList, setDataList }) {

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [purityDropdown, setPurityDropdown] = useState([]);
    const [metalDropdown, setMetalDropdown] = useState([]);
    const [errors, setErrors] = useState({});


    

    function closeDeleteModal() {
        setIsDeleteOpen(false)
        setIsLoading(null)
    }

    useEffect(() => {
        if (formType === 'create') {
            addValue()
        }
        getMetalDropdownData()
    }, [])

    const onKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('additem').click()
        }
    };

    useKeyPress(['Enter'], onKeyPress);

    const addValue = () => {
        let firstData = {
            metal: "",
            gross_weight: "",
            metal_rate: "",
            dust_weight: "",
            net_weight: "",
            total: "",
            today_rate: "",
            old_rate: ""
        }
        setDataList([...dataList, firstData])
    }

    const removeValue = () => {
        let data = [...dataList];
        data.splice(deleteIndex, 1);
        setDataList(data);
        setIsDeleteOpen(false);
        setIsLoading(null)
        setDeleteIndex(null)
    }

    const getMetalDropdownData = async () => {
        var response = await getAxios({
            url: metalListEndPoint
        })

        if (response !== null) {
            setMetalDropdown(response.data.list)
        }
    }

    const getPurityDropdownData = async (id) => {
      
        var response = await getAxios({
            url: purityListEndPoint + id + "/",
        })

        if (response !== null) {
            setPurityDropdown(response.data.list)
        }
    }



    const onValueUpdate = (input, index) => {
        const { name, value } = input;

        let data = [...dataList];
        data[index][name] = value;
        
        if (name === 'metal') {
            for (var i of metalDropdown) {
                if (i.id == value) {
                    data[index]['today_rate'] = i.metal_rate;
                    data[index]['old_rate'] = i.old_metal_rate;
                }  
                  
                    setErrors((prevState) => ({
                        ...prevState,
                        [name]: value.length === 0 ? `${name} field is required` : ''
                    }));
                }
        }   else if (name === 'gross_weight') {
            if (data[index].metal_rate.length !== 0) {
                let oldRate = parseFloat(data[index].old_rate);

                let predictTotal = parseFloat(data[index].metal_rate) * parseFloat(value)
                
                let netWeight = parseFloat(predictTotal / oldRate)
                let dustWeight = parseFloat(value) - netWeight
                data[index]['net_weight'] = netWeight.toFixed(3)
                data[index]['dust_weight'] = parseFloat(dustWeight).toFixed(3)
                data[index]['total'] = (netWeight.toFixed(3) * oldRate).toFixed(2)
            }
        } else if (name === 'metal_rate') {
            let oldRate = parseFloat(data[index].old_rate);

            let predictTotal = parseFloat(value) * parseFloat(data[index].gross_weight)
            
            let netWeight = parseFloat(predictTotal / oldRate)
            let dustWeight = parseFloat(data[index].gross_weight) - netWeight
            data[index]['net_weight'] = netWeight.toFixed(3)
            data[index]['dust_weight'] = parseFloat(dustWeight).toFixed(3)
            data[index]['total'] = (netWeight.toFixed(3) * oldRate).toFixed(2)
        }
        setDataList(data);
    }
    return (
        <div>
            <div className="flex items-center justify-between ">
                <div>Old Gold Details ({oldGoldNo})</div>
                <button
                    id="additem"
                    onClick={(e) => { e.preventDefault(); addValue() }}
                    className="capitalize bg-primary-btn text-white w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >Add Item<span className="lowercase">(enter)</span></button>
            </div>
            <div className="w-full my-[10px] mb-[50px] overflow-scroll max-h-[185px]">
                <table className="w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                        <tr>
                            <td className="py-[10px] border text-center text-[10px]">S.No</td>
                            <td className="py-[10px] border text-center text-[10px]">Metal</td>
                            <td className="py-[10px] border text-center text-[10px]">Gross Weight</td>
                            <td className="py-[10px] border text-center text-[10px]">Metal Rate</td>
                            <td className="py-[10px] border text-center text-[10px]">Net Weight</td>
                            <td className="py-[10px] border text-center text-[10px]">Dust Weight</td>
                            <td className="py-[10px] border text-center text-[10px]">Total</td>
                            <td className="py-[10px] border text-center text-[10px]">Actions</td>
                        </tr>
                    </thead>
                    <tbody className="text-primary-grey">
                        {
                            dataList.map((item, index) => (
                                <tr>
                                    <td className="py-[5px] border text-center">{index + 1}</td>
                                    <td className="py-[5px] border text-center"><select
                                        placeholder="metal name"
                                        className="border outline-none w-[150px] bg-white"
                                        name="metal"
                                        value={item.metal}
                                        onChange={(e) => {
                                            onValueUpdate(e.target, index);
                                            getPurityDropdownData(e.target.value);
                                        }}
                                    >
                                        <option value={""}>--select metal--</option>
                                        {
                                            metalDropdown.map((option) => (
                                                <option key={option.id} value={option.id}>{option.metal_name}</option>
                                            ))
                                        }
                                    </select></td>
                                    <td className="py-[5px] border text-center"><input
                                        type="text"
                                        name="gross_weight"
                                        className="border outline-none w-[100px]"
                                        value={item.gross_weight}
                                        onChange={(e) => onValueUpdate(e.target, index)}
                                    /></td>
                                    
                                    <td className="py-[5px] border text-center w-[100px]">
                                        <input
                                            type="number"
                                            name="metal_rate"
                                            className="border outline-none w-[100px]"
                                            value={item.metal_rate}
                                            onChange={(e) => onValueUpdate(e.target, index)}
                                        />
                                    </td>
                                    <td className="py-[5px] border text-center w-[100px]">{item.dust_weight}</td>
                                    <td className="py-[5px] border text-center w-[100px]">{item.net_weight}</td>
                                    <td className="py-[5px] border text-center w-[100px]">{item.total}</td>
                                    <td className="py-[5px] border text-center">
                                        <div onClick={() => { setIsDeleteOpen(true); setDeleteIndex(index); setIsLoading(index); }} className={`${String(isLoading) !== String(index) ? 'block' : 'hidden'} cursor-pointer hover:scale-125 hover:text-red-600 w-fit mx-auto`}>
                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Popup title={'Delete item'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to delete the item?</p>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                        <button type="submit" onClick={() => { removeValue() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}