import { useDispatch, useSelector } from "react-redux";
import { setPagination } from "../../../redux/appconfigslice";
import { Switch } from "@headlessui/react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { salesReturnPolicyDaysUpdateEndPoint } from "../../../service/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function AppConfiguration() {

    const { pagination } = useSelector((state) => state.appConfig)
    const { isDark } = useSelector((state) => state.themeConfig)

    const [salesReturnDays, setSalesReturnDays] = useState(0)

    const dispatch = useDispatch();

    useEffect(() => {
        getReturnPolicyDays()
    },[])

    const handlePagination = (value) => {
        localStorage.setItem('pagination', JSON.stringify(value))
        dispatch(setPagination(value));
    }

    const updateReturnPolicyDays = async () => {
        var response = await postAxiosWithToken({
            url: salesReturnPolicyDaysUpdateEndPoint,
            body: {
                return_days: salesReturnDays
            }
        })
        if (response != null) {
            toast.success(response.message)
            getReturnPolicyDays()
        }
    }

    const getReturnPolicyDays = async (value) => {
        var response = await getAxios({
            url: salesReturnPolicyDaysUpdateEndPoint
        })

        if (response != null) {
            setSalesReturnDays(response.data)
        }
    }
    return (
        <div className="my-[50px] bg-body-secondary-bg rounded-lg p-[25px]">
            <div className="mb-[25px] border-b pb-[15px] w-full">Settings</div>
            <div className="flex items-center gap-[15px]">
                <p>Pagination</p>
                <Switch
                    checked={Boolean(pagination)}
                    onChange={handlePagination}
                    className={`${Boolean(pagination) ? 'bg-primary-btn' : isDark ? 'bg-primary-btn' : 'bg-table-head '}
                                                    relative inline-flex h-[18px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                    <span
                        aria-hidden="true"
                        className={`${Boolean(pagination) ? 'translate-x-4' : 'translate-x-0'} ${isDark ? 'bg-secondary' : 'bg-white'}
                                                        pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                </Switch>
            </div>

            <div className="my-[25px] flex gap-4 items-center">
                <p className="mb-[5px] text-[16px]">Sales Return Policy Days</p>
                <div className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-[200px] p-[10px] text-[17px] flex justify-between">
                <input
                    type="number"
                    min={0}
                    placeholder="Sales Return Policy Days"
                    className="outline-none w-[100px]"
                    name="return_days"
                    value={salesReturnDays}
                    onChange={(e) => setSalesReturnDays(e.target.value)}
                />
                <button className="text-primary-btn" onClick={(e) => {e.preventDefault(); updateReturnPolicyDays()}}>Apply</button>
                </div>
            </div>
        </div>
    );
}