import { useState } from "react";
import { useEffect } from "react";
import { useClickOutside } from "../hooks/useClickOutside";

export default function SearchDropDown({
    name,
    id,
    options = [],
    value,
    setValue
}) {

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayValue, setDisplayValue] = useState("");
    const [showOptions, setShowOptions] = useState(false);

    let dropDownRef = useClickOutside(() => {
        setShowOptions(false);
    })

    useEffect(() => {
        handleSearch(displayValue);
    }, [displayValue])

    useEffect(() => {
        setFilteredOptions(options);
        for (var i of options) {
            if (i.value == value) {
                setDisplayValue(i.label);
                break;
            }
        }
    },[options])

    const handleSearch = (value) => {
        setFilteredOptions(options.filter((item) => item.label.includes(value)));
    }

    const handleSelect = (value) => {
        setValue(value.value);
        setDisplayValue(value.label)
        setShowOptions(false);
    }

    return (
        <div ref={dropDownRef} className="relative">
            <div className="border-2 rounded-md px-1 py-0.5 text-[14px] font-[400] outline-none w-full flex items-center justify-between">
                <input
                    type="text"
                    name={name}
                    id={id}
                    value={displayValue}
                    onChange={(e) => setDisplayValue(e.target.value)}
                    className="outline-none w-full"
                    onClick={() => setShowOptions(!showOptions)}
                />
                <span className="i-octicon-chevron-down-12 w-5 h-5 text-navy"></span>
            </div>
            <input name={name} defaultValue={value} hidden />
            {(showOptions && options.length > 0) && <div className="border bg-white rounded-lg p-2 absolute z-[5] w-full">
                {
                    filteredOptions.map((item) => (
                        <div
                            className="p-1 cursor-pointer hover:bg-primary-btn hover:text-white rounded-md capitalize"
                            onClick={() => handleSelect(item)}
                            key={item.value}>
                            {item.label}
                        </div>
                    ))
                }
            </div>}
        </div>
    );
}