import React, { useEffect, useState } from 'react'
import { stockdetailsEndPoint } from '../../service/api'
import { getAxios } from '../../service/apiservice'

export default function Stockdetailspopup() {

  const [currentStockdetails, setCurrentStockdetails] = useState({});

  useEffect(() => {
    getStockDetails()
  }, [])

  const getStockDetails = async () => {
    var response = await getAxios({
      url: stockdetailsEndPoint + localStorage.getItem('stockdetails_form_id') + "/"
    })

    if (response !== null) {
      var tempDetails = {
        item_name: response.data?.item_name,
        stock_type_name: response.data?.stock_type_name,
        metal_name: response.data?.metal_name,
      }
      setCurrentStockdetails(tempDetails)
    }
  }
  return (
    <div>
      <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 min-[500px]:grid-cols-2  gap-5">
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Bill No</p>
            <p className="text-primary-btn font-[400] text-[15px]">{currentStockdetails.item_name}</p>
          </div>
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Customer Name</p>
            <p className="text-primary-btn font-[400] text-[15px]">{currentStockdetails.stock_type_name}</p>
          </div>
          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">Total Amount</p>
            <p className="text-primary-btn font-[400] text-[15px]">{currentStockdetails.metal_name}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
