import { useEffect, useState } from "react";
import MiscDetailsForm from "./miscdetailsform";
import Popup from "../../../components/Popup";
import CustomerForm from "./customerform";
import { autoGenerateMiscIssueIdEndPoint, createMiscIssueEndPoint, customerByMobileEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import ParticularsList from "./particularlist";
import { useNavigate } from "react-router-dom";
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";
import toast from "react-hot-toast";
import { isValid } from "date-fns";

export default function MiscIssue() {

    const navigate = useNavigate();

    const [billingDetails, setBillingDetails] = useState({
        issue_date: new Date().toLocaleDateString('en-CA'),
        branch: ""
    });
    const [errors, setErrors] = useState({});

    const [customerPopup, setCustomerPopup] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(false);

    const [particulars, setParticulars] = useState([]);
    const [totalWeight, setTotalWeight] = useState(0.0);
    const [particularsCalculation, setParticularsCalculation] = useState({});

    function closeCustomerModal() {
        setCustomerPopup(false);
    }

    useEffect(() => {
        generateMiscIssueId();
    }, [])

    useEffect(() => {
        let totalGrossWeight = 0.0;
        let totalPieces = 0;
        let totalNetWeight = 0.0;
        let totalAmount = 0.0;
        for (var i of particulars) {
            totalGrossWeight = totalGrossWeight + parseFloat(i.gross_weight || 0.0)
            totalPieces = totalPieces + parseInt(i.pieces || 0.0)
            totalNetWeight = totalNetWeight + parseFloat(i.net_weight || 0.0)
            totalAmount = totalAmount + parseFloat(i.total_amount || 0.0)
        }
        setTotalWeight(totalNetWeight);
        setParticularsCalculation((prevState) => ({
            ...prevState,
            gross_weight: totalGrossWeight,
            pieces: totalPieces,
            net_weight: totalNetWeight,
            total_amount: totalAmount
        }))
    }, [particulars])

    const generateMiscIssueId = async () => {
        var response = await getAxios({
            url: autoGenerateMiscIssueIdEndPoint
        })

        if (response != null) {
            setBillingDetails((prevState) => ({
                ...prevState,
                misc_issue_id: response.data.misc_issue_id
            }))
        }
    }

    const getCustomerDetails = async (mobile) => {

        if (mobile.length === 10) {
            setCustomerLoading(true)
            var response = await getAxios({
                url: customerByMobileEndPoint + mobile + '/'
            })

            if (response !== null) {
                setCustomerDetails(true)
                setCustomerLoading(false)
                setBillingDetails((prevState) => ({
                    ...prevState,
                    customer: response.data.id
                }))
            } else {
                setCustomerDetails(false)
                setBillingDetails((prevState) => ({
                    ...prevState,
                    customer: null
                }))
                setCustomerLoading(false)
            }
        }
    }

    const handleSubmit = () => {
        let orderDetailsForm = new FormData(document.getElementById('miscdetails'))

        var err = {}

        for (var field of Object.entries(Object.fromEntries(orderDetailsForm.entries()))) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        if (isOptionalValidForm(Object.fromEntries(orderDetailsForm.entries()), ['remarks'])) {
            
            var validItems = [];
            for (var i of particulars) {
                if (isValidForm(i)) {
                    validItems.push(i)
                }
            }
            
            if (validItems.length > 0) {
                let data = {...billingDetails, ...particularsCalculation};
                data['particulars'] = validItems
                createMiscIssue(data);
            } else {
                toast.error('No items added!')
            }
        }
    }

    const createMiscIssue = async (body) => {
        var response = await postAxiosWithToken({
            url: createMiscIssueEndPoint,
            body: body
        })

        if (response != null) {
            generateMiscIssueId();
            setParticulars([{
                tag_no: ""
            }]);
            setCustomerDetails(false);
            setCustomerLoading(false);
            setBillingDetails((prevState) => ({
                ...prevState,
                branch: "",
                customer_no: "",
                customer: "",
                giver_name: "",
                remarks: ""
            }));
            toast.success(response.message)
        }
    }

    return (
        <div>
            <MiscDetailsForm billingDetails={billingDetails} setBillingDetails={setBillingDetails} setCustomerPopup={setCustomerPopup} getCustomerDetails={getCustomerDetails} customerDetails={customerDetails} setCustomerDetails={setCustomerDetails} customerLoading={customerLoading} errors={errors} setErrors={setErrors} />

            <div className="flex gap-3 items-start mt-3 mb-[150px]">
                <ParticularsList items={particulars} setItems={setParticulars} particularsCalculation={particularsCalculation} />

                <div className="bg-gray-300 font-[500] p-3 rounded-lg w-[300px] h-fit sticky top-[50px]">
                    <div className="my-[5px]">
                        <p>Total Weight</p>
                        <p>{totalWeight} g</p>
                    </div>
                    <div className="my-[5px]">
                        <p>Bill Amount</p>
                        <p>₹ 0.00</p>
                    </div>
                    <div className="my-[5px]">
                        <p>Net Amount</p>
                        <p>₹ 0.00</p>
                    </div>
                </div>
            </div>

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end w-full p-5 gap-5 bg-white fixed bottom-[35px] left-0">
                <button
                    id="backnav"
                    onClick={(e) => { e.preventDefault(); navigate(-1) }}
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >close<span className="lowercase">(esc)</span></button>
                <button
                    onClick={(e) => { e.preventDefault(); handleSubmit() }}
                    className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >save<span className="lowercase">(ctrl+s)</span></button>
            </div>

            <Popup extraClassName="w-fit" isOpen={customerPopup} setIsOpen={setCustomerPopup} closeModal={closeCustomerModal} title={'Add Customer'}>
                <CustomerForm mobile={billingDetails.customer_no} branch={billingDetails.branch} closeModal={closeCustomerModal} setBillingDetails={setBillingDetails} getCustomerData={getCustomerDetails} />
            </Popup>
        </div>
    );
}